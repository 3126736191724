export const findExtremums = (pukts) => {

    let min = 8;
    let max = 0;

    pukts.forEach(punkt => {
        punkt.workingHours &&  punkt.workingHours.forEach(pun => {

            let key = Object.keys(pun)[0]
            if (  pun[key].from < min && pun[key].from > 0) {
                min =pun[key].from;
            }
            if (pun[key].to > max) {
                max = pun[key].to;
            }
        })
    })
    return {min, max}
}
