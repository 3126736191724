const {makeStyles} = require("@material-ui/core");
const useStyles = makeStyles((theme) => ({
    dialogContent: {
        width: '100%',
            height: '100%',
            minWidth: '300px',
            minHeight: '300px',
            padding: '8px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '@media (max-width: 900px)': {
            minWidth: 'auto',
        },
    },
    phoneConfirmationCloseButton: {
        position: 'absolute',
            top: '4px',
            right: '4px',
            zIndex: 100,
    },
    dialogAlertText: {
        color: theme.palette.error.main,
            maxWidth: '284px',
            paddingTop: '30px',
            textAlign: 'center',
    },
    dialogErrorText :{
        color: theme.palette.error.light,
            maxWidth: '284px',
            textAlign: 'center',
            alignSelf: 'center',
    },
    wrapper: {
        display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
    },
    phoneConfirmationButton: {
        marginTop: '20px',
    },
    confirmPhone: {
        margin: '4px',
            fontWeight: 600,
    },
    confirmPhoneNumber: {
        justifySelf: 'flex-start',
            margin: '4px',
    },
    wrapperCode: {
        display: 'grid',
            gridTemplateColumns: '1fr auto',
    },
    formControlLabel: {
        marginLeft: '0px',
    },
    assaysWrapper: {
        display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
    },
    listItem: {
        display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            padding: '10px',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            marginTop: '4px',
    ...theme.shape,
    },
    listItemTitle: {
        fontSize: '18px',
            letterSpacing: '1px',
            display: 'flex',
            alignItems: 'center',
    },
    listItemBottom: {
        display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '40px',
            padding: '12px 0',
    },
    listItemPrice: {
        color: 'rgba(0, 0, 0, 0.3)',
            display: 'flex',
            userSelect: 'none',
    },
    createClientWrapper: {
        height: '100%',
            display: 'grid',
            width: '100%',
            maxWidth: '100%',
    },
    bodyContainer: {
        display: 'flex',
            flexDirection: 'column',
            width: '100%',
    },
    birthdayContainer: {
        padding: '4px 0',
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: 'auto 1fr',
            gridGap: '5px',
    },
    formAnnotation: {
        fontSize: '11px',
            color: theme.palette.secondary.light,
    },
    actionContainer: {
        margin: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            '@media (max-width: 900px)': {
            margin: '5px',
        },
    },
    actionContainerButton: {
        maxHeight: '70px',
            '@media (max-width: 900px)': {
            margin: '5px',
        },
    },
    container: {
        width: '100%',
        padding: '10px',
        '@media (max-width: 1100px)': {
            padding: '6px 2px 2px 2px',
        },
    },
    autoComplete: {
        boxSizing: 'content-box',
        width: '100%',
        minWidth: '100%',
    },
}));

export default useStyles;
