import React, {useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import {getDoctorOrderedAssayPdf, getDoctorOrderSchedule} from "@cabinet-packages/api/api";
import {addAssayToOrderList, getCatalog, setDoctorOrderedAssays} from "@cabinet-packages/redux";
import {Loading, Scroll} from "../components";
import {Avatar} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import * as xlsx from "xlsx";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    listHeaderAssayName: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        textAlign: 'center',
        alignItems: 'center',
        position: 'relative',
        fontSize: '15px',
        paddingLeft: '8px',

        '@media (max-width: 1140px)': {
            fontSize: '12px',
        },
    },
    listHeaderRedDiscount: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '24px',
        fontWeight: '600',
        color: 'white',
        background: theme.palette.error.light,
        border: `2px solid ${theme.palette.error.main}`,
        borderRadius: '16px',
        width: '32px',
        height: '32px',
        position: 'absolute',
        top: 3,
        right: 0,
        '@media (max-width: 1140px)': {
            top: 0,
            zIndex: 100,
            width: '30px',
            height: '30px',
        },
    },
    listHeader: {
        display: 'flex',
        justifyContent: 'space-around',
        textAlign: 'center',
        alignItems: 'center',
        position: 'relative',
        fontSize: '15px',

        '@media (max-width: 1140px)': {
            fontSize: '12px',
        },
    },
    innerWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    dialogErrorText: {
        color: theme.palette.error.light,
        maxWidth: '284px',
        textAlign: 'center',
        alignSelf: 'center',
    },
    orderedAssaysWrapper: {
        width: '100%',
        height: 'auto',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridGap: 8,
        justifyItems: 'center',
        padding: '8px 0px',

        '@media (max-width: 1200px)': {
            gridTemplateColumns: '1fr 1fr',
        },
        '@media (max-width: 640px)': {
            gridTemplateColumns: '1fr',
        },
    },
    cardRoot: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        margin: '4px'
    },
    cardActions: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 'auto',
    },
    buttonPdfDownload: {
        marginTop: '4px',
        width: '100%',
    },
    phoneConfirmationButton: {
        width: '100%',
    },
    listHeadersContainer: {
        position:'sticky',
        zIndex:10,
        top:50,
        background: "#77B6E2",
        height: '35px'
    },
    rootTable: {
        width: '100%',
        maxWidth: '100%',
        minHeight: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 50px auto 1fr auto',

        '@media (max-width: 900px)': {
            gridTemplateRows: 'auto auto 1fr auto',
        },
    },
    stickyContainer:{
        position:'sticky',
        zIndex:10,
        top:0,
        width:'100%',
        background:'white'

    }
}));

const PartnerOrderedAssays = ({}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { activePatient } = useSelector(state => state.patients);
    const { doctorOrderedAssays } = useSelector(state => state.orderAssays);
    const { catalog, complexResearch, actions, takeId }  = useSelector(state => state.assaysCatalog);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingPdf, setLoadingPdf] = useState(false);
    const createAssaysData = (curAssay) => [...catalog, ...complexResearch, ...actions]
        .find(item => +item.id === +curAssay.idPacket && +item.idConf === +curAssay.idConf);

    const handleSetDoctorOrders = async (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        setLoading(true);

        if (error.length > 0) setError(false);

        try {
            const result = await getDoctorOrderSchedule(activePatient.id)
                .catch(onerror => setError("Вибачте, сталась помилка при завантаженні."));
            dispatch(setDoctorOrderedAssays(result.sort((a, b) => b.id - a.id)));
        } catch (e) {
            console.log(e)
        }
        setLoading(false);
    };

    const handleSendSameOrder = (e, assay) => {
        e.stopPropagation();
        e.preventDefault();

        assay.services.forEach(item => {
            const findAssay = createAssaysData(item);
            if (findAssay && findAssay.idParent !== takeId) {
                dispatch(addAssayToOrderList(
                    findAssay.id,
                    findAssay.discount
                        ? findAssay.price - findAssay.price * findAssay.discount / 100
                        : findAssay.price,
                    ()=>{},
                    findAssay.type,
                ));
            }
        });
        history.push('/order-manager');
    };

    const handleDownloadPdf = async (e, assay) => {
        e.stopPropagation();
        e.preventDefault();

        setLoadingPdf(prevState => ({...prevState, [assay.id]: true}));
        if (error.length > 0) setError('');
        try {
            const todayDate = new Date();
            const createAssays = (ids) => ids.map(currId => (
                [...catalog, ...complexResearch, ...actions]
                    .find(currAssay => +currAssay.id === +currId.idPacket && +currAssay.idConf === +currId.idConf)));
            const res = await getDoctorOrderedAssayPdf(
                assay.fullname,
                assay.idClient,
                assay.id,
                activePatient.id,
                todayDate.toISOString().slice(0, 10),
                createAssays(assay.services).reduce((acu, cur) => {
                    return acu + cur.price;
                }, 0),
                +assay.num,
                createAssays(assay.services).map(item => ({
                    idPacket: item.id,
                    idConf: item.idConf,
                    price: item.price,
                    packetName: item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join(''),
                    priceDiscount: item.discount ? item.price - item.price * item.discount / 100 : item.price,
                    discount: item.discount ? item.discount : 0,
                    amount: 1,
                    debt: 0,
                    durationDay: item.duration_day ? item.duration_day : 1,
                }))
            );
            const url = window.URL.createObjectURL(new Blob([res], {type: 'application/pdf'}));
            const link = document.createElement('a');
            link.href = url;
            link.download = `${assay.fullname}.pdf`;
            link.click();
            link.remove();
        } catch (e) {
            setError("Вибачте, сталась помилка при завантаженні PDF.")
        }

        setLoadingPdf(prevState => ({...prevState, [assay.id]: false}));
    };

    useEffect(() => {
        if (!doctorOrderedAssays) handleSetDoctorOrders();
        if (!catalog) dispatch(getCatalog());
    }, [doctorOrderedAssays, catalog, dispatch])


    const getRealPrice = (data) => {
        return data.services.reduce((acu, cur) => {
            return acu + cur.real_price
        }, 0);
    }

    const getAssayForExcel = (data) => {
        console.log(data, 'data')
        return data.services.reduce((acu, cur) => {
            return acu + cur.name + "-" + cur.real_price + "/" + cur.price + ";"
        }, "");
    }

    function download() {
        var result = []
        doctorOrderedAssays.forEach((element, idx) => {
            element.services.forEach((service) => {
                result.push(
                    {
                        "Номер": idx+1,
                        'Користувач': element.fullname,
                        'Створено': element.crDate.slice(0, 16),
                        'Дослідження': service.name,
                        'Базова ціна дослідження': service.real_price,
                        'Ціна дослідження': service.price,
                        'Загальна базова ціна замовлення': getRealPrice(element),
                        'Загальна ціна замовлення': element.price,
                    }
                )
            })

        });

        const worksheet = xlsx.utils.json_to_sheet(result)
        const workbook = {
            Sheets: {
                'Дані': worksheet
            },
            SheetNames: ['Дані']
        }
        const exelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'})
        const fileBlob = new Blob([exelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
        const dataTime = new Date()
        const url = window.URL.createObjectURL(new Blob([fileBlob], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
        const link = document.createElement('a');
        link.href = url;
        link.download = `Дані.${dataTime.getFullYear()}-${+dataTime.getMonth() + 1}-${dataTime.getDate()}.xlsx`;
        link.click();
        link.remove();
    }

    return (<div className={classes.root}>
        <div className={classes.innerWrapper}>
            {error.length !== 0 && <h5 className={classes.dialogErrorText}>{error}</h5>}
            {loading
                ? <Loading/>
                : <Scroll>
                    <div  className={classes.stickyContainer}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={download}
                            style={{marginBottom:16}}

                        >
                            Завантажити в ексель
                        </Button>
                    </div>


                    <div style={{width: '105rem',height:'100%'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} className={classes.listHeadersContainer}
                                  style={{borderBottom: '1px solid #C56085'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <div className={classes.listHeaderAssayName}>
                                            <p>
                                                Дані користувача
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <div className={classes.listHeaderAssayName}>
                                            <p>
                                                Замовленні дослідження
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div className={classes.listHeader}>
                                            <p>
                                                Ціна базова, грн
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <div className={classes.listHeader}>
                                            <p>
                                                Ціна, грн
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {doctorOrderedAssays && doctorOrderedAssays.map(assay => {
                                const fullname = assay.fullname ? (assay.fullname || ' ').split(' ') : [' ', ' '];
                                return (
                                    <Grid item xs={12} style={{borderBottom: '1px solid lightgrey'}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={2}>
                                                <div style={{display: 'flex'}}>
                                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                                        {fullname[0][0] + fullname[1][0]}
                                                    </Avatar>
                                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                                        <Typography variant='p'>
                                                           ПІБ: {assay.fullname}
                                                        </Typography>
                                                        <Typography variant='p'>
                                                            {`Створено: ${assay.crDate.slice(0, 16)}`}
                                                        </Typography>
                                                        {assay.dateValid && <Typography variant='p'>
                                                            {`Дійсне до: ${assay.dateValid.slice(0, 16)}`}
                                                        </Typography>}
                                                        {assay.dateVisit && <Typography variant='p'>
                                                            {`Прийшов: ${assay.dateVisit.slice(0, 16)}`}
                                                        </Typography>}
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <div>
                                                    {catalog && complexResearch && actions
                                                        ? assay.services.map((item, idx) => {
                                                            const orderedAssay = createAssaysData(item);

                                                            return (item
                                                                    ? <div
                                                                        key={item.idPacket}
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'space-between'
                                                                        }}
                                                                    >
                                                                        <div style={{display: 'flex'}}>
                                                                            <p><strong>{idx + 1}.</strong>
                                                                                {item.name}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>
                                                                                <strong>{item.real_price}</strong>/
                                                                                <span
                                                                                    style={{color: "red"}}>{item.price}</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    : <p key={item.idPacket}/>
                                                            )
                                                        })
                                                        : <Loading/>
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid item xs={2}>
                                                {catalog && complexResearch && actions &&
                                                <Typography align='center'>
                                                    {getRealPrice(assay)}
                                                </Typography>
                                                }
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography align='center' style={{color: "red"}}>
                                                    {assay.price}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <div className={classes.cardActions}>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        className={classes.phoneConfirmationButton}
                                                        onClick={e => handleSendSameOrder(e, assay)}
                                                    >
                                                        Повторити призначення
                                                    </Button>
                                                    {loadingPdf[assay.id]
                                                    && <Loading/>
                                                    }
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })
                            }

                        </Grid>
                    </div>

                </Scroll>}
        </div>
    </div>);
};

export default PartnerOrderedAssays;
