import {createAction, createReducer} from "@reduxjs/toolkit";
import React from "react";

export const SET_NEW_CATALOG = createAction('CATALOG: Load Catalog');

const initialState = {
    catalog: null,
};

export const productCatalogReducer = createReducer(initialState, {
    [SET_NEW_CATALOG]: (state, action) => {
        state.catalog = action.payload ? action.payload : [];
    }
  }
);
