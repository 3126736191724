import React, {useEffect, useState} from "react";
import useStyles from "../styles";
import {SwipeStyledButton} from "@cabinet-packages/components";
import {teal} from "@material-ui/core/colors";
import {useDispatch, useSelector} from "react-redux";
import {convertPrice} from "../../PatientTabs/helpers/convertPrice";
import {
    setNextStep,
    setPreviousStep
} from "@cabinet-packages/redux/moduleReducers/wizardStepperReducer/serviceMidleware";
import {createAssaysData} from "../../PatientTabs/helpers/createAssaysData";
import {Checkbox, FormControlLabel, Slide, Snackbar} from "@material-ui/core";
import {createOrderTempUser, setCreateOrder} from "@cabinet-packages/api/api";
import {setAssayLine, setOrderId} from "@cabinet-packages/redux/actions/actionsCreators";
import moment from 'moment';
import 'moment/locale/uk';
import {useHistory} from "react-router";

moment.locale('uk');

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

const Actions = ({useSnackbar}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const history = useHistory()
    const {steps, activeStep} = useSelector(state => state.WIZARD);
    const {amountOfItems, totalPrice, orderingList, promoCode, selectedPunkt, timeLine, time, patientTabsRef, orderId} = useSelector(state => state.orderAssays);
    const {themeMaterialUI} = useSelector(state => state.theme);
    const {catalog, complexResearch, actions, takeId}  = useSelector(state => state.assaysCatalog);
    const { activePatient } = useSelector(state => state.patients);
    const {isAdmin} = useSelector(state => state.cabinet);
    const [notSuitablePrice, setNotSuitablePrice] = useState(0);
    const [open, setOpen, snackBarText, setSnackbarText] = useSnackbar;
    const isMedis = process.env.REACT_APP_CURRENT_CLINIC === "MEDIS"

    const handleChange = (event) => {
        if (event.target.checked) {
            dispatch(setAssayLine({date: 'Ви не обрали час забору.'}));
            handleNextStep(event);
            setOpen(false);
        } else {
            dispatch(setAssayLine());
        }
    };

    const actionText = steps && steps.filter(item => item.showStep())[activeStep].actionText.split('*');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setSnackbarText('');
    };

    const isAllStepsComplete = () => steps
        && (+activeStep === steps.filter(item => item.showStep()).length - 1)
        && steps.filter(item => item.showStep())
            .reduce((acu, cur) => {
                if (acu) {
                    return cur.isCompleted();
                } else {
                    return acu;
                }
    }, true);

    const handleNextStep = async (e) => {
        e.persist();
        e.stopPropagation();
        e.preventDefault();

        if (isAllStepsComplete()) {
            //watch if all stets completed
            const timeForSend = timeLine ? moment().format('YYYY-MM-DD')+` 00:00:00.0000` : time.moment.format('YYYY-MM-DD HH:mm')+':00.0000';

            const services = createAssaysData(orderingList, catalog, complexResearch, actions, activePatient, takeId).map(item => {
                if (item.type === 'action') {
                    const product = [...catalog, ...complexResearch, ...actions].find(com => com.id === item.idGrTest[0]);

                    return {
                        ...product,
                        price: item.price,
                        opt: product.opt ? product.opt + 'notdisc' : 'notdisc',
                        discount: 0
                    };
                }

                return item;
            }).map(item => {

                if (promoCode && promoCode.type !== 'none') {
                    if (promoCode.type === 'all') {
                        if (item.idParent !== takeId) {
                            return ({
                                idPacket: item.id,
                                idConf: item.idConf,
                                price: item.price,
                                packetName: item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join(''),
                                priceDiscount: +item.price - (+item.price * (+promoCode.discount/100)),
                                discount: (+item.price * (+promoCode.discount/100)),
                                amount: 1,
                                debt: 0,
                                durationDay: item.duration_day ? item.duration_day : 1,
                            });
                        } else {
                            return ({
                                idPacket: item.id,
                                idConf: item.idConf,
                                price: item.price,
                                packetName: item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join(''),
                                priceDiscount: item.discount ? item.price - item.price * item.discount / 100 : item.price,
                                discount: item.discount ? item.price * item.discount / 100 : 0,
                                amount: 1,
                                debt: 0,
                                durationDay: item.duration_day ? item.duration_day : 1,
                            });
                        }
                    } else if (promoCode.type === 'specified') {
                        if (item.idParent !== takeId) {
                            const foundDiscount = promoCode.grtests.find(it => +it[0] === +item.id);

                            if (foundDiscount) {
                                return ({
                                    idPacket: item.id,
                                    idConf: item.idConf,
                                    price: item.price,
                                    packetName: item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join(''),
                                    priceDiscount: +foundDiscount[1] === 0 ? (+item.price - (+item.price)*(+foundDiscount[2]/100)) : (+item.price * (+foundDiscount[1]/100)),
                                    discount: +foundDiscount[1] === 0 ? (+item.price)*(+foundDiscount[2]/100) : (+item.price - (+foundDiscount[1])),
                                    amount: 1,
                                    debt: 0,
                                    durationDay: item.duration_day ? item.duration_day : 1,
                                });
                            } else {
                                return ({
                                    idPacket: item.id,
                                    idConf: item.idConf,
                                    price: item.price,
                                    packetName: item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join(''),
                                    priceDiscount: item.discount ? item.price - item.price * item.discount / 100 : item.price,
                                    discount: item.discount ? item.price * item.discount / 100 : 0,
                                    amount: 1,
                                    debt: 0,
                                    durationDay: item.duration_day ? item.duration_day : 1,
                                });
                            }
                        } else {
                            return ({
                                idPacket: item.id,
                                idConf: item.idConf,
                                price: item.price,
                                packetName: item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join(''),
                                priceDiscount: item.discount ? item.price - item.price * item.discount / 100 : item.price,
                                discount: item.discount ? item.price * item.discount / 100 : 0,
                                amount: 1,
                                debt: 0,
                                durationDay: item.duration_day ? item.duration_day : 1,
                            });
                        }
                    } else if (promoCode.type === 'notdisc') {
                        if (item.idParent !== takeId) {
                            const discount = item.opt && item.opt.includes(promoCode.type) ? item.discount : promoCode.discount;

                            return ({
                                idPacket: item.id,
                                idConf: item.idConf,
                                price: item.price,
                                packetName: item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join(''),
                                priceDiscount: +item.price - (+item.price * (+discount/100)),
                                discount: (+item.price * (+discount/100)),
                                amount: 1,
                                debt: 0,
                                durationDay: item.duration_day ? item.duration_day : 1,
                            });
                        } else {
                            return ({
                                idPacket: item.id,
                                idConf: item.idConf,
                                price: item.price,
                                packetName: item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join(''),
                                priceDiscount: item.discount ? item.price - item.price * item.discount / 100 : item.price,
                                discount: item.discount ? item.price * item.discount / 100 : 0,
                                amount: 1,
                                debt: 0,
                                durationDay: item.duration_day ? item.duration_day : 1,
                            });
                        }
                    }
                } else {
                    return ({
                        idPacket: item.id,
                        idConf: item.idConf,
                        price: item.price,
                        packetName: item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join(''),
                        priceDiscount: item.discount ? item.price - item.price * item.discount / 100 : item.price,
                        discount: item.discount ? item.price * item.discount / 100 : 0,
                        amount: 1,
                        debt: 0,
                        durationDay: item.duration_day ? item.duration_day : 1,
                    });
                }
            });

            if(isMedis) {
                try {
                    const result = activePatient.temp
                        ? await createOrderTempUser(
                            selectedPunkt.idPunkt,
                            timeForSend,
                            `${activePatient.lastname} ${activePatient.firstname} ${activePatient.fathername}`,
                            activePatient.id,
                            'orderId',
                            timeForSend,
                            services.reduce((acu, cur) => {
                                return acu + cur.price;
                            }, 0),
                            totalPrice,
                            'card',
                            promoCode ? promoCode.code : 'none',
                            services,
                            "reserved")
                        : await setCreateOrder(
                            selectedPunkt.idPunkt,
                            timeForSend,
                            `${activePatient.lastname} ${activePatient.firstname} ${activePatient.fathername}`,
                            activePatient.id,
                            'orderId',
                            timeForSend,
                            services.reduce((acu, cur) => {
                                return acu + cur.price;
                            }, 0),
                            totalPrice,
                            'card',
                            promoCode ? promoCode.code : 'none',
                            services,
                            "reserved");
                    if (result) {
                        dispatch(setOrderId(result));
                        history.push('/portmone-payment')
                    }
                } catch (e) {
                    console.error(e);
                }
                // CustomDialog(PortmoneDialog, {}, false, themeMaterialUI);
            }else{
                try {
                    const result = await setCreateOrder(
                        selectedPunkt.idPunkt,
                        timeForSend,
                        `${activePatient.lastname} ${activePatient.firstname} ${activePatient.fathername}`,
                        activePatient.id,
                        orderId,
                        timeForSend,
                        services.reduce((acu, cur) => {
                            return acu + cur.price;
                        }, 0),
                        totalPrice,
                        'card',
                        'none',
                        services,
                        isAdmin ? "reserved-infoline" : "reserved");

                    const json_string = {
                        "version": "3",
                        "public_key": "i84475320624",
                        "action": "pay",
                        "paytypes": "gpay, apay, card, liqpay, privat24, masterpass, invoice, qr",
                        "amount": totalPrice,
                        "order_id": result,
                        "currency": "UAH",
                        "description": "payment_from_cabinet_esculab",
                        language: 'uk',
                    }

                    const data = Buffer.from(JSON.stringify(json_string)).toString('base64')
                    const res = 'AYs0YPBxFrIFf3AK46Lctk2KJQnWqflI78ibbPr7' + data + 'AYs0YPBxFrIFf3AK46Lctk2KJQnWqflI78ibbPr7'
                    const crypto = require('crypto');
                    const sha = crypto.createHash('sha1');
                    sha.update(res);
                    const ret = sha.digest('base64');
                    window.location.href = `https://www.liqpay.ua/api/3/checkout?data=${data}&signature=${ret}`
                } catch (e) {
                    console.log(e)
                }
            }
        }

        const isCompleteStep = steps.filter(item => item.showStep())[activeStep].isCompleted()

        if (isCompleteStep) {
            dispatch(setNextStep());
        } else {
            setOpen(true);
            setSnackbarText(steps.filter(item => item.showStep())[activeStep].snackbarText());
            if (patientTabsRef && patientTabsRef.current) {
                patientTabsRef.current.scrollIntoView({behavior: "smooth"});
            }
        }
    };

    const handlePreviousStep = e => {
        e.persist();
        e.stopPropagation();
        e.preventDefault();

        dispatch(setPreviousStep());
    }

    useEffect(() => {
        if (catalog && complexResearch && actions && orderingList && activePatient) {
            const newCompleteOrderingListPrice = createAssaysData(orderingList, catalog, complexResearch, actions, activePatient, takeId)
                .reduce((acu, cur) => {
                    if (cur.notSuitable) {
                        return acu + cur.price;
                    } else {
                        return acu;
                    }
                },0);

            setNotSuitablePrice(newCompleteOrderingListPrice);
        }
    }, [catalog, complexResearch, actions, takeId, orderingList, activePatient]);


    return (<div className={cl.actionsWrapper}>
        <div className={cl.orderDataWrapperMobile}>
            <div className={cl.actionsAmountPriceWrapper}>
                <div className={cl.actionsNumberWrapper}>{amountOfItems}<sup/></div>
                <div className={cl.actionsTextWrapper}>Обраних<br/>аналізів</div>
            </div>
            <div className={cl.actionsAmountPriceWrapper}>
                {convertPrice(totalPrice, 0, cl.actionsNumberWrapper)}
                <div className={cl.actionsTextWrapper}>Сума у кошику<br/>+ забір біоматеріалу</div>
            </div>
        </div>
        <div className={cl.actions}>
            {activeStep !== 0
                ? <SwipeStyledButton
                    variant="outlined"
                    animationStart={'right'}
                    animationMaterialColor={'#E41E15'}
                    buttonMaterialColor={isMedis ? 'white' : '#0077C8'}
                    color={isMedis ? 'white' : '#0077C8'}
                    onClick={handlePreviousStep}
                >
                    НАЗАД
                </SwipeStyledButton>
                : <div style={{width: '220px'}}/>}
            <div className={cl.orderDataWrapper}>
                <div className={cl.actionsAmountPriceWrapper}>
                    <div className={cl.actionsNumberWrapper}>{amountOfItems}<sup/></div>
                    <div className={cl.actionsTextWrapper}>Обраних<br/>аналізів</div>
                </div>
                <div className={cl.actionsAmountPriceWrapper}>
                    {convertPrice(totalPrice - notSuitablePrice, 0, cl.actionsNumberWrapper)}
                    <div className={cl.actionsTextWrapper}>Сума у кошику<br/>+ забір біоматеріалу</div>
                </div>
                <div className={cl.actionsNextTextWrapper}>
                    <p className={cl.actionsNextText}>{actionText[0]}<br/>
                        {actionText[1]}</p>
                </div>
            </div>
            <SwipeStyledButton
                animationStart={'left'}
                animationMaterialColor={isMedis ? teal[500] : '#d91414'}
                buttonMaterialColor={isMedis ? '#ffc101': '#0077C8'}
                hoverTextColor={"white"}
                color={isMedis ? '#0E4E4E' : 'white'}
                onClick={handleNextStep}
                style={{border:'none'}}
            >
                {steps && steps.filter(item => item.showStep()).length - 1 === activeStep ? 'ОПЛАТИТИ' : 'ДАЛІ'}
            </SwipeStyledButton>
        </div>
        <Snackbar
            className={cl.snackbarWrapper}
            ContentProps={{
                classes: {
                    root: cl.snackbar,
                    message: cl.snackbar,
                }
            }}
            TransitionComponent={TransitionUp}
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
            }}
            message={<div className={cl.snackbarContentWrapper}>
                <div className={cl.snackbarContentHeader}>
                    Увага!
                </div>
                <div className={cl.snackbarContentText}>
                    {snackBarText && snackBarText}
                </div>
                {activeStep === 2 && <div className={cl.explanationItemWrapperCheckbox}>
                    <div className={cl.explanationItem}>
                        <FormControlLabel
                            control={<Checkbox
                                color="primary"
                                size="medium"
                                checked={!!timeLine}
                                onChange={handleChange}
                            />}
                            label={<p className={cl.checkboxLabel}>Я хочу пропустити крок з вибором дати та часу</p>}
                            labelPlacement="end"
                            className={cl.formControl}
                        />
                    </div>
                </div>}
            </div >}
        >
        </Snackbar>
    </div>);
};

export default Actions;
