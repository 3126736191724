import React from "react";
import useStyles from "../styles";
import {Step, StepConnector, StepLabel, Stepper, withStyles} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {setActiveStep} from "@cabinet-packages/redux/moduleReducers/wizardStepperReducer/serviceMidleware";
import {AccessKey} from "@cabinet-packages/helpers";
import medeaLogo from '../img/medea.png';

const StyledStepLabel = withStyles((theme) => ({
    root: {
        flexDirection: 'column-reverse',
        marginTop: 0,
    },
    iconContainer: {
        padding: 0,
        marginTop: theme.spacing(0.5),
    },
    label: {
        fontWeight: 600,
    },
    completed: {
        color: `${theme.palette.primary.main} !important`,
    },
    active: {
        color: `${theme.palette.secondary.main} !important`,
    },
}))(StepLabel);

const Header = ({useSnackbar}) => {
    const cl = useStyles();
    const {steps, activeStep} = useSelector(state => state.WIZARD);
    const dispatch = useDispatch();
    const [open, setOpen, snackBarText, setSnackbarText] = useSnackbar;
    const accessKey = AccessKey.get();

    const handleClickOnStep = (e, idx, label) => {
        e.stopPropagation();
        e.preventDefault();

        if (label.isCompleted() || idx === 0 || (idx > 0 && steps[idx - 1].isCompleted())) {
            dispatch(setActiveStep(idx));
        } else {
            setOpen(true);
            setSnackbarText(steps[steps.length - 1].snackbarText());
        }
    }

    return (<div className={cl.headerWrapper}>
        <div className={cl.stepperWrapper} key={'stepperWrapper'+accessKey}>
            {steps &&
            <Stepper activeStep={activeStep} className={cl.stepper} key={activeStep+accessKey}>
                {steps.filter(item => item.showStep()).map((label, index, arr) => {

                    return (
                        <Step key={label.name+index+accessKey+activeStep} completed={label.isCompleted()}
                              connector={<StepConnector
                                  classes={{
                                      line: cl.connectorLine,
                                      completed: cl.connectorCompleted,
                                      root: (index === 1 && !arr[0].isCompleted() || (index > 1 && arr[index - 2].isCompleted()))
                                          ? (index === 1)
                                              ? cl.connectorLineActive
                                              : ((index > 1) && arr[index -1].isCompleted())
                                                  ? cl.connectorCompleted
                                                  : cl.connectorLineActive
                                          : index > 0 && arr[index - 1].isCompleted() ? cl.connectorCompleted : cl.connectorLineRoot,
                                  }}/>}
                              onClick={(e) => handleClickOnStep(e, index, label)}>
                            <StyledStepLabel
                                style={{
                                    cursor: (label.isCompleted() || index === 0
                                        || (index > 0 && steps[index - 1].isCompleted()))
                                            ? 'pointer' : 'default'
                                }}
                                StepIconProps={{classes: {
                                    root: cl.stepIconRoot,
                                    active: cl.stepIconActive,
                                    completed:  cl.stepIconCompleted,
                                }}}><span className={cl.mediaLabel}>{label.name}</span></StyledStepLabel>
                        </Step>
                    );
                })}
            </Stepper>}
        </div>
        <div className={cl.logoHeaderWrapper}>
            <a href="https://medea.ua/" target="_blank"><img src={medeaLogo} alt="medea logo." className={cl.logoHeaderImg}/></a>
            <p className={cl.logoHeaderText}>Виклик медичної сестри на дім здійснюється партнерською організацією
                <a href="https://medea.ua/" target="_blank" className={cl.logoHeaderLink}> "Медея"</a>.
                При виклику медсестри на дім не забираємо ПЛР-тести на Covid-19.</p>
        </div>
    </div>);
};

export default Header;
