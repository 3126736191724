import React, {useCallback} from "react";
import {makeStyles} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import getCatalogTable from "../data/getCatalogTable";
import {setSelectedTags, setToggleChecked} from "@cabinet-packages/redux";
import {Loading} from "@cabinet-packages/components";
import {useHistory} from "react-router";

const normIndexLocalStorageKey = 'normsPage-indexOfNorm';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: 'max-content',
        minHeight: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: '0px !important'
    },
    analyzesContainer: {
        height: '200px',
        width: '100%',
        padding: '10px',
        textAlign: 'center',
        transition: '.8s',
        border: '2px solid rgb(117, 186, 184)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: 'white',

        '&:hover': {
            background: theme.palette.primary[50],
            cursor: 'pointer',
        },
    },
    imgAnalyzes: {
        width: '90px',
        margin: '0 auto',
    },
    mobileWrapperContainer: {
        marginTop: '10px',
        justifyItems: 'center',
        paddingBottom: '8px',

        '@media (min-width: 768px)': {
            display: 'grid',
            width: '100%',
            height: '100%',
            maxWidth: '900px',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gridGap: '5px',
            gridTemplateRows: '1fr 1fr 1fr 1fr',
            margin: '0 auto',
        },
        '@media (max-width: 768px)': {
            display: 'grid',
            gridTemplateColumns: 'minMax(150px, 1fr) minMax(150px, 1fr)',
            gridTemplateRows: 'repeat(4, minMax(150px, auto))',
            gridGap: '5px',
        },
    },
    titleWrapper: {
        '@media (max-width: 768px)': {
            height: 'max-content',
            paddingBottom: '10px',
        }
    },
    analyzesTitle: {
        marginBottom: '4px',
        '@media (max-width: 768px)': {
            fontSize: '14px'
        }
    },
    catalogSwitchControl: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '10px 0 0 0',
        position: 'sticky',
        top: 0,
        alignItems: 'baseline',

        '@media (max-width: 768px)': {
            padding: 0,
            paddingTop: '8px',
        },
        '@media (max-width: 900px)': {
            display: 'none',
        }
    },
    catalogSwitchControlLabel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: theme.palette.primary.dark,
    },
    catalogWrapper: {
        height: 'calc(100vh + 75px)',
    },
    error: {
        textAlign: 'center',
        height: '100vh',
        minHeight: '100%',
    },
    catalogTableWrapper: {
        height: 'max-content',
    },
}));

const CatalogCards = ({isComponentInShop}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {catalog, toggleChecked, takeId, complexId, tags} = useSelector(state => state.assaysCatalog);
    const catalogImages = getCatalogTable().map(item => item.img);
    const history = useHistory();

    const measuredRef = useCallback(node => {
        if (toggleChecked && node) {
            node.scrollIntoView({
                block: 'start',
            });
        }
    }, [toggleChecked]);

    const handleToggleChecked = () => {
        dispatch(setToggleChecked(!toggleChecked));
    };

    const handleTransitionToCatalog = () => {
        localStorage.setItem(normIndexLocalStorageKey, '0');
        handleToggleChecked();
    };

    const handleTransitionToPrepare = (event) => {
        event.stopPropagation();
        event.preventDefault();
        isComponentInShop
            ? history.push('/shop-how-to-prepare')
            : history.push('/how-to-prepare')
    };

    const handleTransitionToComplex = (event) => {
        event.stopPropagation();
        event.preventDefault();
        isComponentInShop
            ? history.push('/shop-complex')
            : history.push('/comprehensive-research')
    };

    // useEffect(() => {
    //     if (!catalog && takeId && complexId) dispatch(getCatalog());
    // }, [catalog, dispatch, takeId, complexId]);

    return (catalog && catalog !== 'error'
        ? <div className={classes.root}>
            <div className={classes.catalogTableWrapper}>
                    <div className={classes.mobileWrapperContainer}>
                        {tags && tags.map((item, idx) => (
                            <MediaCard
                                title={item.name}
                                key={item.id + 'tag'}
                                img={item.filename}
                                handleToggleChecked={handleTransitionToCatalog}
                                tag={item}
                            />))}
                        <div className={classes.analyzesContainer} onClick={handleTransitionToCatalog}>
                            <div className={classes.titleWrapper}>
                                <p className={classes.analyzesTitle}>Перелік всіх аналізів</p>
                            </div>
                            <img src={catalogImages[14]} className={classes.imgAnalyzes} alt="Card" width="100%"/>
                        </div>
                        {catalog && catalog.map((item, idx) => ({...item, idx})).filter(item => +item.idParent === 0).slice(0,14).map((item, idx) => (
                            <MediaCard
                                title={item.superGroupName ? item.superGroupName : item.name}
                                key={item.id}
                                img={catalogImages[idx]}
                                id={item.idx}
                                handleToggleChecked={handleToggleChecked}
                            />
                        ))}
                        <div className={classes.analyzesContainer} onClick={handleTransitionToComplex}>
                            <div className={classes.titleWrapper}>
                                <p className={classes.analyzesTitle}>Комплексні дослідження</p>
                            </div>
                            <img src={catalogImages[14]} className={classes.imgAnalyzes} alt="Card" width="100%"/>
                        </div>
                        <div className={classes.analyzesContainer} onClick={handleTransitionToPrepare}>
                            <div className={classes.titleWrapper}>
                                <p className={classes.analyzesTitle}>Як підготуватись?</p>
                            </div>
                            <img src={catalogImages[15]} className={classes.imgAnalyzes} alt="Card" width="100%"/>
                        </div>
                    </div>
                </div>
        </div>
        : catalog === 'error' ? <p className={classes.error}>Вибачте сталась помилка при завантаженні.</p>: <div className={classes.root}><Loading/></div>);
};

function MediaCard({title, img, id, handleToggleChecked, tag}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleTransitionToCatalogTableInfo = () => {
        if (!tag) {
            localStorage.setItem(normIndexLocalStorageKey, id);
        } else {
            dispatch(setSelectedTags([tag]));
        }
        handleToggleChecked();
    };
    return (
        <div className={classes.analyzesContainer} onClick={handleTransitionToCatalogTableInfo}>
            <div className={classes.titleWrapper}>
                <p className={classes.analyzesTitle}>{title}</p>
            </div>
            <img src={tag ? `https://mlis.medis.com.ua/modules/bi_tags/files/${img}` : img} className={classes.imgAnalyzes} alt="Card image." width="100%"/>
        </div>
    )
}

export default CatalogCards;
