import React, {useEffect, useRef, useState} from 'react';
import {makeStyles,} from '@material-ui/core';
import {getOrdersResultByOrderID, getOrdersResultForDoctor} from "@cabinet-packages/api";
import {Loading, ResultFlora, ResultHTML, ResultJSON, ResultStringAndDigital} from '@cabinet-packages/components';
import {useDispatch, useSelector} from "react-redux";
import {setResultsByOrderId} from "@cabinet-packages/redux";

const useStyles = makeStyles((theme) => ({
  assayTableContainer: {
    margin: '10px 0',
    width: '100%',
    height: '100%',
  },
  assayTableTitle: {
    marginBottom: '10px',
    fontWeight: '600',
  },
  assayNameContainer: {
    wordBreak: 'always',
  },
  tableCell: {
    width: '25%',
    border: '1px solid rgb(197, 197, 197)',
    padding: '0 2px',
    textAlign: 'center',
  },
  tableCellResult: {
    width: '25%',
    border: '1px solid rgb(197, 197, 197)',
    padding: '0 2px',
    textAlign: 'center',
  },
  tableCellHead: {
    width: '25%',
    border: '1px solid rgb(0, 0, 0)',
    padding: '0 2px',
    textAlign: 'center',
  },
  result: {
    fontSize: '1rem',
  },
  resultList: {
    listStylePosition: 'inside',
  },
  tableForInnerHTML: {
    maxWidth: '95vw',
  },
  table: {
    wordBreak: 'break-all',
    tableLayout: 'fixed',
    width: '100%',
  },
  assayTableTitleTime: {
    fontWeight: '500'
  },
}));

function AssayTable({ id, results, isDoctor, patientId, isExpanded }) {
  const classes = useStyles();
  const [ordersById, setOrdersById] = useState(results);
  const isMountedRef = useRef(null);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const { activePatientResults } = useSelector(state => state.patients);

  const handleGetOrdersById = async () => {
    const resultOrderId = isDoctor
        ? await getOrdersResultForDoctor(id, patientId).catch(error => error)
        : await getOrdersResultByOrderID(id, patientId).catch(error => error);

    if (resultOrderId instanceof Error) {
      setOrdersById([]);
      setError(true);
      return;
    }

    if (!isDoctor) dispatch(setResultsByOrderId(resultOrderId, id));

    if(isMountedRef.current) {
      setOrdersById(resultOrderId)
    }
  };

  useEffect(() => {
    isMountedRef.current = true;
    if (!ordersById && isExpanded) handleGetOrdersById();

    return () => isMountedRef.current = false;
  }, [ordersById, isExpanded]);

  return (
      ordersById
          ? ordersById.length > 0
            ? ordersById.reduce((acu, cur) => {
              const similarAssay = (cur.resulttype === "string"
                  || cur.resulttype === "digital"
                  || cur.resulttype === "none"
                  || cur.resulttype === 'covid')
                  && acu.find(item => item.idOrdertest === cur.idOrdertest);
              if (similarAssay) return [...acu.filter(item => item.id !== similarAssay.id), {...similarAssay, arrOfResults: [...similarAssay.arrOfResults, cur]}]

              return [...acu, {...cur, arrOfResults: [cur]}];
          }, []).map((assay) => {
            const notReady = activePatientResults && activePatientResults.find(item => item.idOrder === assay.idOrder && item.idGrTest === assay.idGrTest && +item.ready === 0);

            if (+assay.ready === 0) {

              return (<div className={classes.assayTableTitle} key={assay.id}>
                {`${assay.packet.length > 0 ? assay.packet : assay.test}`}
                <em className={classes.assayTableTitleTime}>
                  {` Результати ще не готові.`}
                </em>
              </div>)
            }

            if (notReady || +assay.ready === 0) {
              return (<div key={assay.id}/>);
            }

              return (
                  <div className={classes.assayTableContainer} key={assay.id}>
                    {assay.resulttype === "html" && <ResultHTML assay={assay}/>}
                    {(assay.resulttype === "flora" || assay.resulttype === "cytology") && <ResultFlora assay={assay}/>}
                    {assay.resulttype === "json" && <ResultJSON assay={assay}/>}
                    {(assay.resulttype === "string"
                        || assay.resulttype === "digital"
                        || assay.resulttype === "none"
                        || assay.resulttype === 'covid') && <ResultStringAndDigital assay={assay}/>}
                  </div>
              );
            })
            : error ? <div className={classes.assayTableContainer}>Вибачте, сталась помилка під час завантаження.</div> : <div className={classes.assayTableContainer}>Дослідження відсутні.</div>
          : <Loading/>
  )
}

export default AssayTable;
