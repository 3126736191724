import React from "react";

export const convertPrice = (price, discount, cl) => {
    const priceArr = discount > 0
        ? ((+price*(1-(discount/100))).toFixed(2)+'').split('.')
        : ((+price).toFixed(2)+'').split('.');

    if (priceArr && priceArr.length === 1) {
        priceArr.push('00')
    } else if (priceArr && priceArr.length === 2) {
        if (priceArr[1].length === 1) {
            priceArr[1] = priceArr[1]+'0';
        }
    }

    return (<p className={cl}>
        {priceArr[0]}<sup>{priceArr[1]}</sup>&#8372;
    </p>);
}