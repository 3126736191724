import React, {useEffect, useRef, useState} from 'react';
import {makeStyles, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery,} from '@material-ui/core';
import {Current, Loading, Scroll} from '@cabinet-packages/components';
import cn from 'classnames';
import {useSelector} from "react-redux";
import {getOrdersResult} from "@cabinet-packages/api";

const useStyles = makeStyles({
  rootContainer: {
    padding: '0px 5px 0 0',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr auto',
  },
  tableBody: {
    paddingLeft: '20px',
  },
  headCell: {
    background: 'white',
  },
  tableRowSelected: {
    background: 'rgb(244,244,244)',
  },
  nameCell: {
    position: 'absolute',
    zIndex: 3,
  },
  firstCell: {
    left: 0,
    position: 'sticky',
    background: 'white',
    textTransform: 'capitalize',
  },
  firstCellSelected: {
    left: 0,
    position: 'sticky',
    background: 'rgb(244,244,244)',
    textTransform: 'capitalize',
  },
  noAvailableData: {
    textAlign: 'center',
    padding: '20px',
  }
});

export default function PatientAssaysHistory() {
  const classes = useStyles();
  const [assays, setAssays] = useState(null);
  const {activePatient} = useSelector(state => state.patients);
  const [headerDates, setHeaderDates] = useState(null);
  const isDesktop = useMediaQuery('(min-width: 900px)');
  const isMountedRef = useRef(true);

  const handleAssayHistory = async () => {
    const assayHistory = await getOrdersResult(activePatient.id).catch(onerror => 'error');

    if(isMountedRef.current) setAssays(assayHistory);
  };

  useEffect(() => {
    isMountedRef.current = true;
    if (activePatient && activePatient.id && !assays) {
      handleAssayHistory();
    }
    if (assays && typeof assays !== "string" && assays.length && !headerDates && isMountedRef.current) {
      setHeaderDates(assays[0].dates.split(', '));
    }
    return () => isMountedRef.current = false;
  },[activePatient, assays, headerDates]);

  return (
    <div className={classes.rootContainer}>
      <Scroll>
        <Table stickyHeader padding={isDesktop ? 'normal' : 'checkbox'}>
          <TableHead>
            <TableRow>
              {assays && typeof assays !== "string" && assays.length !== 0 && <TableCell className={cn(classes.firstCell, classes.nameCell)}>Назва</TableCell>}
              {headerDates && headerDates.map(date => (
                <TableCell className={classes.headCell} key={date}>{date}</TableCell>))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              assays && typeof assays !== "string" && assays.map((assay, index) => (
                    <TableRow
                        className={cn({ [classes.tableRowSelected]: index % 2 === 0 })}
                        key={assay.alias}
                    >
                      <TableCell
                          className={cn(classes.firstCell, {
                            [classes.firstCellSelected]: index % 2 === 0,
                          })}
                      >
                        {assay.alias}
                      </TableCell>
                      {headerDates && headerDates.map(date => getValueOfAssay(assay, date))}
                    </TableRow>))
            }
          </TableBody>
        </Table>
        {!assays && <div className={classes.noAvailableData}>Завантаження історії досліджень, будь ласка зачекайте.<br/><br/><Loading/></div>}
        {assays && assays.length === 0
            ? <div className={classes.noAvailableData}>Історія досліджень відсутня.</div>
            : assays === 'error' && <div className={classes.noAvailableData}>Вибачте, сталась помилка під час завантаження.</div>}
      </Scroll>
      <Current/>
    </div>
  );
};

function getValueOfAssay(assay, date) {
  let value = <TableCell key={date}/>;
  const splitAssaysValue = assay.assayValues.split(', ');
  const foundValue = splitAssaysValue.find(value => value.includes(date));

  if (foundValue) {
    return (
        <TableCell key={date}>
          {foundValue.split('~')[1]}
        </TableCell>);
  }
  return value;
}
