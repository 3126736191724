import React, { useEffect, useState } from "react";
import useStyles from "../styles";
import TextField from "@material-ui/core/TextField";
import { Slide, Snackbar } from "@material-ui/core";
import {
  setAccessKeyForLogin,
  setClient,
  setListOfPatients,
  START_PHONE_SEND,
} from "@cabinet-packages/redux";
import {
  accept,
  acceptDoctor,
  authorise,
  getDoctor,
  getPatients,
} from "@cabinet-packages/api";
import { AccessKey } from "@cabinet-packages/helpers";
import { useDispatch } from "react-redux";
import MuiPhoneNumber from "material-ui-phone-number";
import { SwipeStyledButton } from "@cabinet-packages/components";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const ThirdStage = ({
  handleValue,
  handlePhone,
  isDoctor,
  uuid,
  isChangedState,
}) => {
  const cl = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = handleValue;
  const [phone, setPhone] = handlePhone;
  const [open, setOpen] = useState(false);
  const [snackBarText, setSnackbarText] = useState("");
  const [loading, setLoading] = useState(false);
  const [disableCode, setDisableCode] = useState(false);
  const isMedis = process.env.REACT_APP_CURRENT_CLINIC === "MEDIS";

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setSnackbarText("");
  };

  const onSendCode = async (uuidPhone, code) => {
    setLoading(true);

    try {
      const result = isDoctor
        ? await acceptDoctor(uuidPhone, code)
        : await accept(uuidPhone, code);
      //: await patientAuth('ACCEPT', uuid, code, phone)

      if ((result.data && result.data.token) || (isDoctor && result.data)) {
        AccessKey.set(isDoctor ? result.data : result.data.token);
        if (isDoctor || result.data.exist) {
          const patients = isDoctor ? await getDoctor() : await getPatients();

          dispatch(setListOfPatients(false, isDoctor, patients));

          const isUserChooseMainPatient = patients.reduce((acu, cur) => {
            if (cur.main) return cur.main;

            return acu;
          }, false);

          if (isUserChooseMainPatient || !isMedis) {
            dispatch(
              setClient(
                patients.find((patient) => patient.main === true) || patients[0]
              )
            );
            dispatch({
              type: START_PHONE_SEND,
              payload: {
                stage: 6,
              },
            });
          } else {
            dispatch({
              type: START_PHONE_SEND,
              payload: {
                stage: 8,
              },
            });
          }
        } else {
          localStorage.setItem("unregisteredCabinet", true);
          dispatch(setAccessKeyForLogin({ accessKey: result.data.token }));
          dispatch({
            type: START_PHONE_SEND,
            payload: {
              stage: 5,
            },
          });
        }
      } else {
        dispatch({
          type: START_PHONE_SEND,
          payload: {
            stage: 3,
            error: result,
          },
        });
        setValue("");
        setOpen(true);
        setSnackbarText(
          'Ви ввели невірний код. Натисніть "ПОВТОРНИЙ КОД" щоб спробувати ще раз.'
        );
        setDisableCode(true);
      }

      return !!result.data;
    } catch (e) {
      dispatch({
        type: START_PHONE_SEND,
        payload: {
          stage: 3,
          error: e && e.response && e.response.data,
        },
      });
      setValue("");
      setOpen(true);
      setSnackbarText(
        'Ви ввели невірний код. Натисніть "ПОВТОРНИЙ КОД" щоб спробувати ще раз.'
      );
      setDisableCode(true);
    }

    setLoading(false);
  };

  const handleConfirmSMS = async () => {
    localStorage.setItem("activePatientPhone", phone.replace(/[[()\s]/g, ""));
    await onSendCode(uuid, value);
  };

  const handlePressEnter = (e) => {
    if (e.key === "Enter" && !isChangedState) {
      handleConfirm(e, phone);
    }
  };

  const onSendPhone = async (uuidPhone, phone) => {
    dispatch({
      type: START_PHONE_SEND,
      payload: {
        stage: 2,
        error: null,
      },
    });

    const result = await authorise(uuid, phone).catch((onerror) => {
      if (onerror) {
        dispatch({
          type: START_PHONE_SEND,
          payload: {
            stage: 3,
            error: "errorWhenSendPhone",
          },
        });
      }
    });

    if (result) {
      dispatch({
        type: START_PHONE_SEND,
        payload: {
          stage: 3,
        },
      });
    }
  };

  const handleConfirm = (e, formData) => {
    if (!isChangedState) {
      onSendPhone(uuid, formData);
      setValue("");
    } else {
      setOpen(true);
      setSnackbarText("Введіть номер телефону, щоб отримати код.");
    }
  };

  useEffect(() => {
    if (value && value.length === 4) {
      handleConfirmSMS();
    }
  }, [value]);

  return (
    <div className={cl.codeEnterForm}>
      <div className={cl.wrapperCode}>
        <p className={cl.phoneLabel}>Номер телефону</p>
        <MuiPhoneNumber
          className={cl.muiPhoneNumber}
          defaultCountry="ua"
          countryCodeEditable={false}
          regions="europe"
          value={phone}
          onChange={(value) => setPhone(value)}
          variant="outlined"
          onKeyPress={handlePressEnter}
        />
        {!disableCode && <p className={cl.phoneLabel}>Код</p>}
        {!disableCode && (
          <TextField
            className={cl.muiPhoneNumber}
            variant="outlined"
            autoFocus={true}
            value={value}
            disabled={loading}
            inputProps={{
              maxLength: 4,
            }}
            onChange={(event) =>
              setValue(event.target.value.replace(/[^0-9]/g, ""))
            }
          />
        )}
      </div>
      <SwipeStyledButton
        className={cl.swipeStyledButton}
        animationStart={"left"}
        onClick={(e) => handleConfirm(e, phone)}
      >
        Повторний код
      </SwipeStyledButton>
      <Snackbar
        ContentProps={{
          classes: {
            root: cl.snackbar,
            message: cl.snackbar,
          },
        }}
        TransitionComponent={TransitionUp}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        message={
          <div className={cl.snackbarContentWrapper}>
            <div className={cl.snackbarContentHeader}>Увага!</div>
            <div className={cl.snackbarContentText}>
              {snackBarText && snackBarText}
            </div>
          </div>
        }
      ></Snackbar>
    </div>
  );
};

export default ThirdStage;
