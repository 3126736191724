import React, {useEffect, useRef, useState} from "react";
import {AutoSizer, List} from "react-virtualized";
import {getAssayRowHeight} from "@cabinet-packages/helpers";
import {
    Avatar,
    Button,
    ListItem as MListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Tooltip
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
    clearOrderedAssays,
    setAssayLine,
    setAssayTime,
    setListOfPatients,
    setOrderNote,
    setTurnBack
} from "@cabinet-packages/redux";
import {useAssaysFilter} from "@cabinet-packages/hooks";
import {useHistory} from "react-router";
import {
    AddNewPatientDialog,
    AddRemoveIconButtons,
    ConfirmPayment,
    CustomDialog,
    LineDialog,
    ListItem,
    Loading,
    Scroll
} from "@cabinet-packages/components";
import TextField from "@material-ui/core/TextField";
import GroupIcon from "@material-ui/icons/Group";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {setCreateOrder} from "@cabinet-packages/api/api";
import {setOrderId} from "@cabinet-packages/redux/actions/actionsCreators";
import AddIcon from "@material-ui/icons/Add";


const useStyles = makeStyles((theme) => ({
    rootScrollWrapper: {
        width: '100%',
        height: '100vh',
        minHeight: '100%',
    },
    root: {
        width: '100%',
        maxWidth: '900px',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '190px 100px 30px 1fr 47px 40px',
        overflow: 'auto',
        margin: '0 auto',
        height: '100%',
        paddingBottom: '24px',

        '@media (max-width: 900px)': {
            gridTemplateRows: '200px 66px 1fr 90px',
            height: 'calc(100vh - 48px)',
            paddingBottom: '0px',
        },
    },
    rootError: {
        width: '100%',
        maxWidth: '900px',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '190px 100px 30px 1fr 47px 40px',
        overflow: 'auto',
        margin: '0 auto',
        height: '100%',
        paddingBottom: '24px',

        '@media (max-width: 900px)': {
            gridTemplateRows: '200px 66px 1fr 90px 40px',
            height: 'calc(100vh - 48px)',
            paddingBottom: '0px',
        },
    },
    detailsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        padding: '5px',

        '@media (max-width: 900px)': {
            flexDirection: 'column',
            justifyContent: 'center',
            padding: ' 5px 0',
        },
    },
    detailsOrder: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: '5px',
        width: '50%',
        '@media (max-width: 900px)': {
            margin: '0',
            maxWidth: '100%',
            width: 'inherit',
        },
    },
    detailsItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        marginBottom: '2px',
        padding: '5px',
    },
    detailsItemHeader: {
        fontWeight: 500,
        color: theme.palette.primary.dark,
    },
    detailsItemText: {
        fontWeight: 500,
    },
    searchFieldContainer: {
        marginTop: '10px',
        padding: '0 10px 0 10px',
        '@media (max-width: 900px)': {
            display: 'none',
        },
    },
    searchField: {
        margin: 0,
    },
    list: {
        '&:focus': {
            outline: 'none',
        },
    },
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        padding: '10px 10px 0 10px',
    },
    listItemTitle: {
        fontSize: '18px',
        letterSpacing: '1px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    listItemBottom: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '40px',
        padding: '12px 0',
    },
    listItemPrice: {
        color: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        userSelect: 'none',
    },
    totalPriceContainer: {
        padding: '10px 10px',
        fontSize: '18px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    noAssays: {
        paddingTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        border: '1px solid rgba(0, 0, 0, 0.1)',
    },
    actionButton: {
        margin: '10px',
    },
    headerOfOrderedAssays: {
        padding: '5px',
        border: '1px solid rgba(0, 0, 0, 0.3)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        fontWeight: 500,
        color: theme.palette.primary.dark,
        '@media (max-width: 900px)': {
            display: 'none',
        },
    },
    bottomContainer: {
        background: 'white',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        position: 'relative',
        paddingBottom: '5px',
        minHeight: 47,
        '@media (max-width: 900px)': {
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
        },
    },
    buttonConfirmOrder: {
        maxWidth: 288,
        maxHeight: '40px',
        width: '100%',
        boxSizing: 'border-box',
        // margin: '0 auto',
        marginTop: '5px',
    },
    buttonLink: {
        padding: '0',
    },
    commentaryWrapper: {
        border: '1px solid rgba(0, 0, 0, 0.1)',
        width: '100%',
        padding: '10px',
        '@media (max-width: 900px)': {
            padding: '5px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        },
    },
    commentaryHeader: {
        fontWeight: 500,
        color: theme.palette.primary.dark,
        marginBottom: '5px',
        '@media (max-width: 900px)': {
            display: 'none',
        },
    },
    commentary: {
        width: '100%',
        height: '100%',
    },
    listWrapper: {
        listStyle: 'none',
        width: '100%',
    },

    buttonPay: {
        color: theme.palette.primary.dark,
        maxWidth: 288,
        width: '100%',
        boxSizing: 'border-box',
        margin: '0 auto',
        marginTop: '5px',
        display: 'flex',
        height: 35,
        borderRadius: 15,
        minWidth: 150,
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonWrapperBack: {
        display: "flex",
        justifyContent: "center",
    },
    error: {
        color: 'red',
        textAlign: 'center',
    },
}));

const ConfirmOrder = ({isComponentInShop, dataPickers}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {catalog, complexResearch, actions, takeId} = useSelector(state => state.assaysCatalog);
    const {activePatient, listOfPatients} = useSelector(state => state.patients);
    const {
        orderingList,
        amountOfItems,
        totalPrice,
        selectedPunkt,
        time,
        timeLine,
        note,
        orderId
    } = useSelector(state => state.orderAssays);
    const {themeMaterialUI} = useSelector(store => store.theme);
    const previousAmountOfItems = useRef();
    const [value, setValue] = useState('');
    const listRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [iframePay, setIframePay] = useState(true);
    const [loading, setLoading] = useState(false);
    const [price, setPrice] = useState(null);
    const [error, setError] = useState(false);
    const [dataTimeSuccess, setDataTimeSuccess ] =useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const createAssaysData = (ids) => ids.map(currId => (
        [...catalog, ...complexResearch, ...actions].find(currAssay => +currAssay.id === +currId.id && currAssay.type === currId.type && !currAssay.superGroupName)));

    const handleSendOrdering = (dataPickers) => {
        const handleConfirm = async () => {
            try {
                setLoading(true);
                setError(false);
                const timeForSend = timeLine ? `${timeLine} 00:00:00.0000` : `${time}:00.0000`;
                const services = createAssaysData(orderingList).map(item => ({
                    idPacket: item.id,
                    idConf: item.idConf,
                    price: item.price,
                    packetName: item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join(''),
                    priceDiscount: item.discount ? item.price - item.price * item.discount / 100 : item.price,
                    discount: item.discount ? item.price * item.discount / 100 : 0,
                    amount: 1,
                    debt: 0,
                    durationDay: item.duration_day ? item.duration_day : 1,
                })).filter(item => +item.price > 0);

                const result = await setCreateOrder(
                    selectedPunkt.idPunkt,
                    value,
                    `${activePatient.lastname} ${activePatient.firstname} ${activePatient.fathername}`,
                    activePatient.id,
                    orderId,
                    timeForSend,
                    services.reduce((acu, cur) => {
                        return acu + cur.price;
                    }, 0),
                    totalPrice,
                    'card',
                    'none',
                    services);

                const isSafari = typeof window !== 'undefined' ? /^((?!chrome|android).)*safari/i.test(navigator.userAgent) : false;

                const json_string = {
                    "version": "3",
                    "public_key": "i84475320624",
                    "action": "pay",
                    "paytypes": "gpay, apay, card, liqpay, privat24, masterpass, invoice, qr",
                    "amount": totalPrice,
                    "order_id": result,
                    "currency": "UAH",
                    "description": "payment_from_cabinet_esculab",
                    language: 'uk',
                }

                const data = Buffer.from(JSON.stringify(json_string)).toString('base64')
                const res = 'AYs0YPBxFrIFf3AK46Lctk2KJQnWqflI78ibbPr7' + data + 'AYs0YPBxFrIFf3AK46Lctk2KJQnWqflI78ibbPr7'
                const crypto = require('crypto');
                const sha = crypto.createHash('sha1');
                sha.update(res);
                const ret = sha.digest('base64');
                const urlIframe = `https://www.liqpay.ua/api/3/checkout?data=${data}&signature=${ret}`;

                if(isSafari){
                    location.href = urlIframe
                } else {
                    if (dataPickers) {
                        setPrice(totalPrice);
                        dispatch(setOrderId(result));
                        dispatch(clearOrderedAssays());
                        // if(result && orderId !== null){
                        //     setDataTimeSuccess(true)
                        // }
                    } else {
                        setPrice(totalPrice);
                        dispatch(setOrderId(result));
                        dispatch(clearOrderedAssays());
                        setIframePay(false)
                    }
                }
            } catch (e) {
                console.log(e.message)
                setLoading(false);
                setError(true);
            }
            setLoading(false);
        };
        CustomDialog(ConfirmPayment, {
            text: dataPickers ? '': `Вартість даного замовлення ${totalPrice} грн.`,
            textButton: dataPickers ? 'Записатися': 'Оплатити',
            dataPickers: dataPickers,
            onConfirm: handleConfirm,
        }, false, themeMaterialUI);
    };

    const handleInputValue = (value) => {
        setValue(value);
        dispatch(setOrderNote(value));
    };

    const handleClickOnIconButton = (path) => {
        history.push(path);
        dispatch(setTurnBack(true));
        dispatch(setAssayLine());
        dispatch(setAssayTime());
    };

    const handleReturnToPunktPick = () => {
        isComponentInShop ? history.push('/shop-order-collection-points') : history.push('/order-collection-points');
        dispatch(setAssayLine());
        dispatch(setAssayTime());
        if(dataPickers){
            window.top.location.href = '/'
        }
    };

    const handleTransitionToAdditionalOrder = () => {
        isComponentInShop
            ? takeId === 216 ? history.push('/shop') : history.push('/shop-catalog')
            : history.push('/catalog-table');
    };

    const {
        backToInitialState,
        filteredState,
    } = useAssaysFilter(createAssaysData(orderingList), true);

    function rowRenderer(props) {
        const {key, index, style} = props;
        const item = filteredState[index];

        const newStyles = {
            ...style,
            borderBottom: '1px solid' +
                ' rgba(0, 0, 0, 0.1)',
        };

        const handleTransitionToInfo = (event) => {
            event.stopPropagation();
            event.preventDefault();

            switch (item.type) {
                case "assay":
                    isComponentInShop
                        ? history.push(`/shop-assay-info/${item.id}`)
                        : history.push(`/assay-info/${item.id}`)
                    break;
                case "action":
                    isComponentInShop
                        ? history.push(`/shop-actions-details/${item.id}`)
                        : history.push(`/actions-details/${item.id}`)
                    break;
                case "complex":
                    isComponentInShop
                        ? history.push(`/shop-complex-info/${item.id}`)
                        : history.push(`/complex-info/${item.id}`)
                    break;
                default:
                    return;
            }
        };

        return (
            <div key={key} style={newStyles} className={classes.listItem}>
                {item && <div style={{textDecoration: !!item.price ? 'none' : 'line-through'}} className={classes.listItemTitle} onClick={event => handleTransitionToInfo(event)}>
                    {item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join('')}
                    {' '}
                    {item.type === 'action' && catalog.find(assay => assay.id === item.idGrTest)
                        ? catalog.find(assay => assay.id === item.idGrTest).name
                            ? catalog.find(assay => assay.id === item.idGrTest).name
                            : catalog.find(assay => assay.id === item.idGrTest).testNames.split(',')[0].split('\\\\').join('')
                        : ''}
                </div>}
                {!item.price && <span style={{textDecoration: 'none', fontSize: '12px', color: 'red'}}>Дане дослідження не доступне в цьому регіоні.</span>}
                <div className={classes.listItemBottom}>
                    <div className={classes.listItemPrice}>
                        {item.discount ? item.price - item.price * item.discount / 100 : item.price} грн
                    </div>
                    {+item.idParent !== takeId && <AddRemoveIconButtons item={item} isIncludedInOrderingList={true}/>}
                </div>
            </div>
        );
    }

    useEffect(() => {
        if (previousAmountOfItems.current !== amountOfItems) {
            backToInitialState();
            previousAmountOfItems.current = amountOfItems;
        }
        if (!value && note) setValue(note)
        dispatch(setListOfPatients());
    }, [amountOfItems, value, note, dispatch, listOfPatients, backToInitialState, iframePay]);

    const json_string = {
        "version": "3",
        "public_key": "i84475320624",
        "action": "pay",
        "paytypes": "gpay, apay, card, liqpay, privat24, masterpass, invoice, qr",
        "amount": price,
        "order_id": orderId,
        "currency": "UAH",
        "description": "payment_from_cabinet_esculab",
        language: 'uk',
    }

    const data = Buffer.from(JSON.stringify(json_string)).toString('base64')
    const res = 'AYs0YPBxFrIFf3AK46Lctk2KJQnWqflI78ibbPr7' + data + 'AYs0YPBxFrIFf3AK46Lctk2KJQnWqflI78ibbPr7'
    const crypto = require('crypto');
    const sha = crypto.createHash('sha1');
    sha.update(res);
    const ret = sha.digest('base64');
    const urlIframe = `https://www.liqpay.ua/api/3/checkout?data=${data}&signature=${ret}`;

    const backPay = () => {
        setIframePay(!iframePay)
    }
    useEffect(() => {
       if(orderId){
           setDataTimeSuccess(true)
       }
    }, [orderId]);

    return (<div className={classes.rootScrollWrapper}>
        {!iframePay
            ? orderId
                ? <>
                    <iframe
                        style={{
                            width: '100%',
                            height: 'calc(100% - 100px)',
                            overflow: "auto"
                        }}
                        src={urlIframe}
                        allowpaymentrequest="true"
                        allow="autoplay"
                    />
                </>
                : <Loading/>
            : <Scroll>
                <div className={error ? classes.rootError : classes.root}>
                    <div className={classes.detailsWrapper}>
                        <div className={classes.detailsOrder}>
                            <div className={classes.detailsItem}>
                                <div>
                                    <h4 className={classes.detailsItemHeader}>ПІБ пацієнта:</h4>
                                    <p className={classes.detailsItemText}>{activePatient && `${activePatient.lastname} ${activePatient.firstname} ${activePatient.fathername}`}</p>
                                </div>
                                <Tooltip
                                    title="Натисніть щоб змінити пацієнта."
                                    arrow
                                >
                                    <Button
                                        color="primary"
                                        className={classes.buttonLink}
                                        onClick={handleClick}
                                    >
                                        <GroupIcon color="inherit"/>
                                    </Button>
                                </Tooltip>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    {listOfPatients && typeof listOfPatients !== 'string' && listOfPatients.map(patient => (
                                        <MenuItem onClick={handleClose} key={patient.id}>
                                            <ul className={classes.listWrapper}>
                                                {activePatient &&
                                                <ListItem
                                                    patient={patient}
                                                    isSelected={activePatient.id === patient.id}
                                                      inConfirmOrder={true}
                                                />}
                                            </ul>
                                        </MenuItem>
                                    ))}
                                    <MenuItem onClick={() => {
                                        CustomDialog(AddNewPatientDialog,
                                            {}, false,
                                            themeMaterialUI)
                                    }}>
                                        <ul className={classes.listWrapper}>
                                            <MListItem divider button>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        className={classes.avatar}
                                                    >
                                                        <AddIcon/>
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={"Додати нового пацієнта."}
                                                />
                                            </MListItem>
                                        </ul>
                                    </MenuItem>
                                </Menu>
                            </div>
                            <div className={classes.detailsItem}>
                                <div>
                                    <h4 className={classes.detailsItemHeader}>Адреса пункту забору:</h4>
                                    <p className={classes.detailsItemText}>{selectedPunkt ? selectedPunkt.address : 'Виберіть пункт забору!'}</p>
                                </div>
                                <Tooltip
                                    title="Натисніть щоб змінити пункт забору."
                                    arrow
                                >
                                    <Button
                                        color="primary"
                                        className={classes.buttonLink}
                                        onClick={() => handleClickOnIconButton('/order-collection-points')}
                                    >
                                        <LocationOnIcon color="inherit"/>
                                    </Button>
                                </Tooltip>
                            </div>
                            <div className={classes.detailsItem}>
                                <div>
                                    <h4 className={classes.detailsItemHeader}>Запланований час забору:</h4>
                                    <p className={classes.detailsItemText}>{timeLine || time ? timeLine || time : 'Виберіть час прийому!'}</p>
                                </div>
                                <Tooltip
                                    title="Натисніть щоб змінити час забору."
                                    arrow
                                >
                                    <Button
                                        color="primary"
                                        className={classes.buttonLink}
                                        onClick={() => CustomDialog(LineDialog, {point: selectedPunkt, isComponentInShop, history, themeMaterialUI}, false, themeMaterialUI)}
                                    >
                                        <AccessTimeIcon color="inherit"/>
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                        {!dataPickers && <div className={classes.detailsOrder}>
                            <div className={classes.detailsItem}>
                                <h4 className={classes.detailsItemHeader}>Загальна вартість:</h4>
                                <p className={classes.detailsItemText}>{totalPrice} грн</p>
                            </div>
                        </div>}
                    </div>


                    <div className={classes.commentaryWrapper}>
                        <h4 className={classes.commentaryHeader}>Коментар до поточного замовлення:</h4>
                        <TextField
                            label="Залиште коментар тут"
                            multiline={true}
                            rows={1}
                            className={classes.commentary}
                            variant="outlined"
                            onChange={(event) => handleInputValue(event.target.value)}
                            value={value}
                        />
                    </div>

                    {/*!dataPickers &&*/}
                    {!dataPickers &&  <h4 className={classes.headerOfOrderedAssays}>Список замовлених досліджень:</h4>}
                    {amountOfItems > 0
                        ? (<div style={{
                            height: '100%',
                            width: 'calc(100% - 2px)',
                            border: '1px solid rgba(0, 0, 0, 0.1)',
                            // display : !dataPickers ? 'block' : 'none'
                        }}>
                            <AutoSizer
                                disableHeight={false}
                                disableWidth={false}
                                onResize={() => null}
                                style={{outline: 'none'}}
                            >
                                {({height, width}) => (
                                    <List
                                        width={width}
                                        height={height}
                                        ref={listRef}
                                        rowCount={filteredState.length}
                                        rowHeight={({index}) => getAssayRowHeight(index, width, filteredState)}
                                        rowRenderer={rowRenderer}
                                    />
                                )}
                            </AutoSizer>
                        </div>)
                        : (<>{!dataPickers && <div className={classes.noAssays}>
                            <Button
                                variant="outlined"
                                onClick={handleTransitionToAdditionalOrder}
                                className={classes.buttonConfirmOrder}
                            >
                                Замовити дослідження
                            </Button>
                        </div>}</>)}
                    <div className={classes.bottomContainer}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.buttonConfirmOrder}
                            onClick={handleReturnToPunktPick}
                        >
                            Повернутись до вибору пункту
                        </Button>
                        {loading
                            ? <Loading/>
                            :<Button
                            variant="outlined"
                            color="primary"
                            className={classes.buttonConfirmOrder}
                            disabled={!dataPickers && orderingList.length < 1}
                            onClick={() =>  handleSendOrdering(dataPickers)}
                        >

                                {dataPickers ? 'Записатися' : 'Оплатити замовлення'}
                        </Button>}
                    </div>
                    {error && <p className={classes.error}>Вибачте сталась помилка при завантаженні</p>}
                </div>
            </Scroll>}
    </div>);
};

export default ConfirmOrder;
