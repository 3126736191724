import React, {createContext, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Button, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles,} from '@material-ui/core';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import {GoogleMap, Marker, withGoogleMap, withScriptjs,} from 'react-google-maps';
import {
    CustomDialog,
    LineDialog,
    Loading,
    Scroll,
    SelectPartnerPatient,
    TextAlertDialog
} from '@cabinet-packages/components';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {addSelectedPunkt, getPunkts, setAssayTime,} from "@cabinet-packages/redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {createFilterOptions} from "@material-ui/lab";
import moment from "moment";

const useStyles = makeStyles({
    root: {
        display: 'grid',
        gridTemplateRows: '1fr 46px 70px 1fr 100px',
        gridTemplateColumns: '1fr',
        width: '100%',
        maxWidth: '1100px',
        maxHeight: '100%',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        '@media (max-width: 1100px)': {
            gridTemplateRows: '1fr 92px 64px 1fr 155px',
        },
    },
    searchButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: '5px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        '@media (max-width: 1100px)': {
            flexDirection: 'column',
        },
    },
    noConnection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    bottomContainer: {
        background: 'white',
        borderTop: '2px solid rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    totalPriceContainer: {
        padding: '5px',
        fontSize: '18px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '@media (max-width: 1100px)': {
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
        },
    },
    actionButton: {
        margin: '10px',
        '@media (max-width: 1100px)': {
            margin: '0 5px',
            fontSize: '12px',
            lineHeight: '24.5px',
        },
    },
    container: {
        width: '100%',
        padding: '10px',
        '@media (max-width: 1100px)': {
            padding: '6px 2px 2px 2px',
        },
    },
    showPunktsButton: {
        display: 'block',
        minWidth: '100%',
        fontWeight: 600,
        marginBottom: '7px',
        '@media (max-width: 1100px)': {
            display: 'none',
        },
    },
    autoComplete: {
        boxSizing: 'content-box',
        width: '100%',
        minWidth: '100%',
    },
    dialogTitle: {
        margin: '10px 0px',
    },
    DialogContentWrapper: {
        paddingLeft: 12
    },
    dialogButtonConfirmOrderPage: {
        background: '#0077c888',
        color: '#FFFFFF',
        border: '1px solid #1976D2',
        transition: '.5s',
        '&:hover': {
            background: '#FFFFFF',
            color: '#0077c888',
            border: '1px solid #1976D2',
        }
    },
    dialogButtonBack: {
        background: '#e4002b88',
        color: '#FFFFFF',
        border: '1px solid #DC004E',
        transition: '.5s',
        '&:hover': {
            background: '#FFFFFF',
            color: '#e4002b88',
            border: '1px solid #DC004E',
        }
    },
});

const MapContext = createContext(null);
const MapProvider = MapContext.Provider;
const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

function OrderCollectionPoints({isComponentInShop}) {
    const classes = useStyles();
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [isUserSelectPoint, setIsUserSelectPoint] = useState(false);
    const {totalPrice, amountOfItems, time, orderingList} = useSelector(state => state.orderAssays);
    const {catalog, complexResearch, actions, takeId} = useSelector(state => state.assaysCatalog);
    const {clientLocation, punkts} = useSelector(store => store.collectionPoints);
    const {themeMaterialUI} = useSelector(store => store.theme);
    const history = useHistory();
    const dispatch = useDispatch();
    const isMountedRef = useRef(null);
    const [open, setOpen] = useState(false);
    const filteredOptions = createFilterOptions({
        limit: 20
    });
    const googleMapStringURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${googleApiKey}&language=uk`;

    const isOrderedAssaysHasPunkts = useCallback(() => {
        const orderedAssays = orderingList.map(currId =>
            [...catalog, ...complexResearch, ...actions].find(currAssay => +currAssay.id === +currId.id && currAssay.type === currId.type)
        ).reduce((acu, cur) => {
            if (cur.idPunkts) {
                return [...acu, ...cur.idPunkts.map(idPunkt => punkts.find(punkt => +punkt.idPunkt === +idPunkt))];
            }
            return acu;
        }, []);

        return orderedAssays.length ? orderedAssays.filter(item => item) : false;
    }, [catalog, complexResearch, actions, orderingList, punkts]);

    const measuredRef = useCallback(node => {
        if (node && selectedPoint && node.textContent === selectedPoint.address) {
            node.scrollIntoView({
                behavior: 'smooth',
                block: isComponentInShop ? 'end' : 'start',
            });
        }
    }, [selectedPoint, isComponentInShop]);

    const handleClickOnPunkt = (point) => {
        dispatch(addSelectedPunkt(point));
        setSelectedPoint(point);
        setIsUserSelectPoint(true);
        if (time) dispatch(setAssayTime(null));
    };

    const getClosestPunkt = useCallback(() => {
        if (!clientLocation) CustomDialog(TextAlertDialog, {
            text: `Будь ласка, дозвольте використання геолокації на вашому пристрої.`,
            onConfirm: () => {
            },
        }, false, themeMaterialUI);
        if (punkts && clientLocation) setSelectedPoint(
            punkts
                    ? isOrderedAssaysHasPunkts()
                        ? isOrderedAssaysHasPunkts()[0]
                        : punkts[0]
                    : []
        );
    }, [clientLocation, punkts, isOrderedAssaysHasPunkts]);

    const handleTransitionToDatePick = async () => {

        history.push('/assay-scheduler-time');
    };

    const handleTransitionToLineRugPick = (point, isComponentInShop) => {
        CustomDialog(LineDialog, {point, isComponentInShop, history, themeMaterialUI, selectTime: takeId !== 14}, false, themeMaterialUI);
    }

    const handleReturnToOrderManager = () => {
        isComponentInShop ? history.push('/shop-order-manager') : history.push('/order-manager');
    };


    const handleSelectPatient = () => {
        dispatch(setAssayTime({date: moment().add(30,'days').format('YYYY-MM-DD HH:mm')}));
        CustomDialog(SelectPartnerPatient, {
            text: 'Увага! Аналізи, що входять в одне замовлення, потрібно здати під час одного відвідування відділення.',
            themeMaterialUI,
        }, false, themeMaterialUI);
    };

    const handleClose = (reason) => {
        setOpen(false);
        if (reason === 'blur') setIsUserSelectPoint(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        isMountedRef.current = true;
        dispatch(getPunkts())

        if (clientLocation && punkts && punkts.length > 0 && !isUserSelectPoint) getClosestPunkt();
        if (punkts && punkts.length > 0 && !selectedPoint && !isUserSelectPoint) {
            setSelectedPoint(
                punkts
                        ? isOrderedAssaysHasPunkts()
                            ? isOrderedAssaysHasPunkts()[0]
                            : punkts[0]
                        : []
            );
            dispatch(addSelectedPunkt(
                punkts
                    ? isOrderedAssaysHasPunkts()
                        ? isOrderedAssaysHasPunkts()[0]
                        : punkts[0]
                    : []
            ))
        }

        return () => isMountedRef.current = false;
    }, [clientLocation, punkts, selectedPoint, isUserSelectPoint, dispatch, getClosestPunkt, isOrderedAssaysHasPunkts]);

    return (
        <MapProvider value={{lat: selectedPoint ? +selectedPoint.lat : 0, lng: selectedPoint ? +selectedPoint.lng : 0}}>
            <div className={classes.root}>
                <div>
                    {navigator.onLine
                        ? <MapWrapper
                            googleMapURL={googleMapStringURL}
                            loadingElement={<div style={{height: `100%`}}/>}
                            containerElement={<div style={{height: `100%`}}/>}
                            mapElement={<div style={{height: `100%`}}/>}
                        />
                        : <div className={classes.noConnection}>Немає мережі</div>
                    }
                </div>
                <div className={classes.searchButtonContainer}>
                    <Button
                        variant="outlined"
                        onClick={() => getClosestPunkt()}
                        color="primary"
                        className={classes.closestPunktButton}
                    >
                        Знайти найближчий пункт
                    </Button>
                    <Button
                        onClick={open ? handleClose : handleOpen}
                        variant="outlined"
                        color="primary"
                    >
                        показати найближчі пункти
                    </Button>
                </div>
                <div className={classes.container}>
                    <Autocomplete
                        className={classes.autoComplete}
                        id="combo-box"
                        open={open}
                        onOpen={handleOpen}
                        onClose={(event, reason) => handleClose(reason)}
                        freeSolo
                        value={selectedPoint}
                        options={
                            punkts
                                ? isOrderedAssaysHasPunkts()
                                ? isOrderedAssaysHasPunkts()
                                : punkts
                                : []
                        }
                        filterOptions={filteredOptions}
                        getOptionLabel={(option) => option.address}
                        onChange={(event, value) => handleClickOnPunkt(value)}
                        style={{width: 300}}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Пошук по адресі"
                            variant="outlined"/>
                        }
                    />
                </div>
                {!punkts && <div><Loading/></div>}
                <Scroll>
                    {punkts && <List dense disablePadding>
                        {isOrderedAssaysHasPunkts()
                            ? isOrderedAssaysHasPunkts().map(point => {
                                return (
                                    <ListItem divider key={point.idPunkt} onClick={() => handleClickOnPunkt(point)}
                                              ref={measuredRef}>
                                        <ListItemIcon>
                                            <IconButton size="small"
                                                        color={selectedPoint && point.idPunkt === selectedPoint.idPunkt
                                                            ? 'primary'
                                                            : 'default'}
                                            >
                                                <GpsFixedIcon/>
                                            </IconButton>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={point.address}
                                        />
                                    </ListItem>
                                )
                            })
                            : punkts.map(point => {
                                return (
                                    <ListItem
                                        divider
                                        key={point.idPunkt}
                                        onClick={() => handleClickOnPunkt(point)}
                                        ref={measuredRef}
                                    >
                                        <ListItemIcon>
                                            <IconButton
                                                size="small"
                                                color={selectedPoint && point.idPunkt === selectedPoint.idPunkt
                                                    ? 'primary'
                                                    : 'default'
                                                }
                                            >
                                                <GpsFixedIcon/>
                                            </IconButton>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={point.address}
                                        />
                                    </ListItem>
                                )
                            })
                        }
                    </List>}
                </Scroll>
                <div className={classes.bottomContainer}>
                    <div className={classes.totalPriceContainer}>
                        Повна вартість: <strong>{totalPrice}</strong> грн
                    </div>
                    <div className={classes.actionContainer}>
                        <Button
                            className={classes.actionButton}
                            variant="outlined"
                            color="secondary"
                            onClick={handleReturnToOrderManager}
                            disabled={amountOfItems === 0}
                        >
                            Повернутись до вибраних досліджень
                        </Button>

                        <Button
                            className={classes.actionButton}
                            variant="outlined"
                            color="primary"
                            onClick={handleSelectPatient}
                            disabled={amountOfItems === 0}
                        >
                            Пацієнт прийде протягом 30 днів
                        </Button>

                        <Button                                          //comment this before pushing in patient cabinet
                            className={classes.actionButton}
                            variant="outlined"
                            color="primary"
                            onClick={handleTransitionToDatePick}
                            disabled={amountOfItems === 0}
                        >
                            Обрати дату та час візиту
                        </Button>
                    </div>
                </div>
            </div>
        </MapProvider>
    );
}

function Map() {
    const coordinates = useContext(MapContext);
    return (
        <GoogleMap
            defaultZoom={12}
            defaultCenter={coordinates}
            center={coordinates}
            language="uk"
        >
            <Marker position={coordinates}/>
        </GoogleMap>
    );
}

const MapWrapper = withScriptjs(withGoogleMap(Map));

export default OrderCollectionPoints;
