import React, {useEffect} from "react";
import {makeStyles, Snackbar, Tooltip, useMediaQuery} from "@material-ui/core";

import {FormattedMessage} from "react-intl";


const TooltipError = ({component, placement, open, setOpen, value, width}) => {
    const useStyles = makeStyles((theme) => ({
        snackbarRoot: {
            padding: 0,
            background: '#ffffff',
            borderRadius: '12px',
            minWidth: width ? width : 300,
            minHeight: 91.5,
            boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
            overflow: 'hidden',
            fontSize: 18,
            position: 'relative',
            width: '100%',
            '@media (max-width: 900px)': {
                minWidth: 288,
            }
        },
        popperRoot: {
            '@media (max-width: 900px)': {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            }
        },
        header: {
            background: '#0077C8',
            color: '#ffffff',
            fontFamily: "'Museo Sans Cyrl 700'",
        },
        snackbarContainer: {
            padding: '10px',
        },
        wrapperError: {
            fontFamily: "'Museo Sans Cyrl 500'",
            color: '#214572',
            display: 'grid',
            gridRowGap: 10,
        },
        snackbar: {
            zIndex: '9999999999999999999999999999',
            minHeight: 163,
        },
    }))
    const classes = useStyles()
    const isMobileVersion = useMediaQuery('(max-width: 900px)');


    const closeTooltip = (e) => {
        if (isMobileVersion) {
            return
        }
        setOpen(false)
    }

    useEffect(() => {
        if(value.length === 0 ){
            setOpen(false)
        }
    }, [value])

    return (
        <>
            <Tooltip
                classes={{
                    tooltip: classes.snackbarRoot,
                    popper: classes.popperRoot,
                }}
                placement={placement}
                open={!isMobileVersion && open}
                onClose={(e) => closeTooltip(e)}
                title={<div>
                    <div className={classes.header}>
                        <div className={classes.snackbarContainer}>
                            <FormattedMessage
                                id="feedback_snackbar_title.text"
                                defaultMessage={"Увага"}/>!
                        </div>
                    </div>
                    <div className={classes.snackbarContainer}>
                        <div className={classes.wrapperError}>
                            {value && value.length > 0 && value.filter(el => el.visible).map((el, i) => (
                                <div key={i}>{el.text}</div>
                            ))}
                        </div>
                    </div>
                </div>}
            >
                {component}
            </Tooltip>
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                open={isMobileVersion && open}
                autoHideDuration={3000}
                onClose={() => setOpen(false)}
                ContentProps={{
                    classes: {
                        root: classes.snackbarRoot,
                        message: classes.snackbarRoot,
                    }
                }}
                message={<div>
                    <div className={classes.header}>
                        <div className={classes.snackbarContainer}>
                            <FormattedMessage
                                id="feedback_snackbar_title.text"
                                defaultMessage={"Увага"}/>!
                        </div>
                    </div>
                    <div className={classes.snackbarContainer}>
                        <div className={classes.wrapperError}>
                            {value && value.length > 0 && value.filter(el => el.visible).map((el, i) => (
                                <div key={i}>{el.text}</div>
                            ))}
                        </div>
                    </div>
                </div>}
            />

        </>)

}

export default TooltipError
