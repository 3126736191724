import React, {useEffect, useRef, useState} from 'react';
import useStyles from "../styles";
import {ReactComponent as Btt_radio} from "@cabinet-packages/pages/PatientTabsMedis/assets/bttn_radio.svg";
import {useDispatch, useSelector} from "react-redux";
import InfoField from "./InfoField";
import {Menu, MenuItem, TextField} from "@material-ui/core";
import {selectActivePatient, setListOfPatients} from "@cabinet-packages/redux";

const OrderAndPatients = ({handleChangeNote, note}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const { activePatient, listOfPatients} = useSelector(state => state.patients);
    const { selectedPunkt, time, timeLine } = useSelector(state => state.orderAssays);
    const orderTime = time ? time.time : timeLine;
    const [openMenu, setOpenMenu] = useState(false);
    const ref = useRef(null);

    const handleClose = (e, patient) => {
        e.persist();

        dispatch(selectActivePatient(patient));
        setOpenMenu(false);
    }

    useEffect(() => {
        if (!listOfPatients) {
            dispatch(setListOfPatients());
        }
    }, [listOfPatients])


    return (<div className={cl.orderAndPatientsRoot}>
        <InfoField
            menuRef={ref}
            headingText={'Пацієнт'}
            InfoFieldIcon={Btt_radio}
            infoFieldText={`${activePatient.lastname} ${activePatient.firstname}`}
            changeStep={() => setOpenMenu(prevState => !prevState)}
        />
        <Menu
            id="simple-menu"
            anchorEl={ref.current}
            keepMounted
            open={openMenu}
        >
            {listOfPatients && listOfPatients.map((patient, idx) => (
                <MenuItem onClick={(e) => handleClose(e, patient)} key={patient.id+patient.firstname+idx}>
                    {`${patient.lastname} ${patient.firstname} ${patient.fathername}`}
                </MenuItem>))}
        </Menu>
        <br/>
        <InfoField
            headingText={'Адреса відділення'}
            InfoFieldIcon={Btt_radio}
            infoFieldText={selectedPunkt ? selectedPunkt.address : 'Ви не обрали пункт забору.'}
            changeStep={false}
        />
        <br/>
        <InfoField
            headingText={'Дата / Час'}
            InfoFieldIcon={Btt_radio}
            infoFieldText={orderTime ? orderTime : 'Ви не обрали час забору.'}
            changeStep={false}
        />
        <div className={cl.noteWrapper}>
            <p className={cl.infoFieldHeading}>Коментар</p>
            <TextField
                className={cl.textFieldNote}
                multiline
                variant="outlined"
                color="primary"
                onChange={handleChangeNote}
                value={note}
            />
        </div>
    </div>);
}

export default OrderAndPatients;
