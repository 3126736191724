import React from 'react';
import ResultFlora from './ResultFlora';
import ResultHTML from './ResultHTML';
import ResultJSON from './ResultJSON';
import ResultStringAndDigital from './ResultStringAndDigital';
import {TableCell} from "@material-ui/core";

const RenderNormArr = ({normArr, classes}) => {

    if (normArr.length > 4) {
        const halfLength = Math.floor(normArr.length/2)
        const firstCell = normArr.slice(0, halfLength)
        const secondCell = normArr.slice(halfLength)

        return <>
            <TableCell className={classes.tableCell}>
                {firstCell.map(n=><p key={n+Math.random()}>{n}</p>)}
            </TableCell>
            <TableCell className={classes.tableCell}>
                {secondCell.map(n=><p key={n+Math.random()}>{n}</p>)}
            </TableCell>
        </>
    }

    return <>
        {normArr.map(n=><p key={n+Math.random()}>{n}</p>)}
    </>
}

export {ResultFlora, ResultHTML, ResultJSON, ResultStringAndDigital, RenderNormArr};
