import React, {useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, makeStyles} from '@material-ui/core';
import {AutoSizer, List} from 'react-virtualized';
import {getAssayRowHeight} from '@cabinet-packages/helpers';
import {AddRemoveIconButtons, ConfirmDialog, CustomDialog, SelectPartnerPatient} from '@cabinet-packages/components';
import {useDispatch, useSelector} from 'react-redux';
import {clearOrderedAssays,} from '@cabinet-packages/redux';
import {useAssaysFilter} from '@cabinet-packages/hooks';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: '900px',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr 100px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        '@media (max-width: 900px)': {
            gridTemplateRows: '1fr 120px',
        },
    },
    textFieldContainer: {
        margin: '10px',
    },
    list: {
        '&:focus': {
            outline: 'none',
        },
    },
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        padding: '10px',
    },
    listItemTitle: {
        fontSize: '18px',
        letterSpacing: '1px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    listItemBottom: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '40px',
        padding: '12px 0',
    },
    listItemPrice: {
        color: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        userSelect: 'none',
    },
    bottomContainer: {
        background: 'white',
        borderTop: '2px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    totalPriceContainer: {
        padding: '5px',
        fontSize: '18px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        '@media (max-width: 900px)': {
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            marginBottom: '5px',
        },
    },
    noAssays: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    actionButton: {
        margin: '10px',
        '@media (max-width: 900px)': {
            margin: '5px 5px 0 5px',
        },
    },
}));

export default function OrderManager({isComponentInShop}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const {catalog, complexResearch, actions, takeId}  = useSelector(state => state.assaysCatalog);
    const { orderingList, totalPrice, amountOfItems } = useSelector(state => state.orderAssays);
    const {themeMaterialUI} = useSelector(store => store.theme);
    const previousAmountOfItems = useRef();

    const createAssaysData = (ids) => ids.map(currId => (
        [...catalog, ...complexResearch, ...actions].find(currAssay => +currAssay.id === +currId.id && currAssay.type === currId.type)));

    const {
        backToInitialState,
        filteredState,
    } = useAssaysFilter(createAssaysData(orderingList), true);

    const listRef = useRef();

    useEffect(() => {
        if (previousAmountOfItems.current !== amountOfItems) {
            backToInitialState();
            previousAmountOfItems.current = amountOfItems;
        }
    }, [amountOfItems, backToInitialState]);

    const handleClearAll = () => {
        const handleConfirm = () => {
            dispatch(clearOrderedAssays());
            isComponentInShop
                ? takeId === 14 ? history.push('/shop') : history.push('/shop-catalog')
                : takeId === 14 ? history.push('/catalog-table') : history.push('/catalog');
        };
        CustomDialog(ConfirmDialog, {
            text: 'Очистити корзину?',
            onConfirm: handleConfirm,
        }, false, themeMaterialUI);
    };

    const handleSelectPatient = () => {
        CustomDialog(SelectPartnerPatient, {
            text: 'Увага! Аналізи, що входять в одне замовлення, потрібно здати під час одного відвідування відділення.',
            themeMaterialUI,
        }, false, themeMaterialUI);
    };

    const handleTransitionToAdditionalOrder = () => {
        isComponentInShop
            ? takeId === 14 ? history.push('/shop') : history.push('/shop-catalog')
            : history.push('/catalog-table');
    };

    function rowRenderer(props) {
        const { key, index, style } = props;
        const item = filteredState[index];

        const newStyles = {
            ...style,
            borderBottom: '1px solid' +
                ' rgba(0, 0, 0, 0.1)',
        };

        const handleTransitionToInfo = (event) => {
            event.stopPropagation();
            event.preventDefault();

            switch (item.type) {
                case "assay":
                    isComponentInShop
                        ? history.push(`/shop-assay-info/${item.id}`)
                        : history.push(`/assay-info/${item.id}`)
                    break;
                case "action":
                    isComponentInShop
                        ? history.push(`/shop-actions-details/${item.id}`)
                        : history.push(`/actions-details/${item.id}`)
                    break;
                case "complex":
                    isComponentInShop
                        ? history.push(`/shop-complex-info/${item.id}`)
                        : history.push(`/complex-info/${item.id}`)
                    break;
                default:
                    return;
            }
        };

        return (
            <div key={key} style={newStyles} className={classes.listItem}>
                {item && <div className={classes.listItemTitle} onClick={event => handleTransitionToInfo(event)}>
                    {item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join('')}
                    {' '}
                    {item.type === 'action' && catalog.find(assay => assay.id === item.idGrTest)
                        ? catalog.find(assay => assay.id === item.idGrTest).name
                            ? catalog.find(assay => assay.id === item.idGrTest).name
                            : catalog.find(assay => assay.id === item.idGrTest).testNames.split(',')[0].split('\\\\').join('')
                        : ''}
                </div>}
                {item && <div className={classes.listItemBottom}>
                    <div className={classes.listItemPrice}>
                        {item.price} грн
                    </div>
                    {+item.idParent !== takeId && <AddRemoveIconButtons item={item} isIncludedInOrderingList={true}/>}
                </div>}
            </div>
        );
    }

    return (
        <div className={classes.root}>
            {amountOfItems > 0 ? (
                <div style={{
                    height: '100%',
                    width: '100%',
                    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                }}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                className={classes.list}
                                width={width}
                                height={height}
                                ref={listRef}
                                rowCount={filteredState.length}
                                rowHeight={({ index }) => getAssayRowHeight(index, width, filteredState)}
                                rowRenderer={rowRenderer}
                            />
                        )}
                    </AutoSizer>
                </div>
            ) : (
                <div className={classes.noAssays}>
                    <Button
                        className={classes.actionButton}
                        variant="outlined"
                        color="primary"
                        onClick={handleTransitionToAdditionalOrder}>
                        Замовити дослідження
                    </Button>
                </div>
            )}
            <div className={classes.bottomContainer}>
                <div className={classes.totalPriceContainer}>
                    Повна вартість: <strong>{totalPrice}</strong> грн
                </div>
                <div className={classes.actionContainer}>
                    <Button
                        className={classes.actionButton}
                        variant="outlined"
                        color="secondary"
                        onClick={handleClearAll}
                        disabled={amountOfItems === 0}
                    >
                        Відмінити все
                    </Button>
                    <Button
                        className={classes.actionButton}
                        variant="outlined"
                        color="primary"
                        onClick={(e) => history.push('/order-collection-points')}
                        disabled={amountOfItems === 0}
                    >
                        Обрати відділення
                    </Button>
                </div>
            </div>
        </div>
    );
};
