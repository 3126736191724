import React, {createContext, useRef, useState} from "react";
import {CircularProgress, Dialog, IconButton} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import useStyles from "./styles";
import SelectNurse from "./components/SelectNurse";
import {useSelector} from "react-redux";
import TestResultInput from "./components/TestResultInput";
import Button from "@material-ui/core/Button";
import {insertResult} from "@cabinet-packages/api/api";

export const InsertResultDialogContext = createContext(null);

const InsertResultDialog = ({handleClose, assay, handleInsertionOfResult}) => {
    const cl = useStyles();
    const {activeNurse} = useSelector(state => state.calculator)
    const [nurseId, setNurseId] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isResultInsert, setIsResultInsert] = useState(false);
    const nurseIdRef = useRef(null);
    const resultInputRef = useRef(null);
    const [testData, setTestData] = useState(assay
        && assay.test
        && assay.test.filter(item => item.editable).map(item => ({
            ...item,
            possibleResult: item.possibleResult ? item.possibleResult.split(';') : item.possibleResult,
            result: '',
        }))
    );

    const handleInsertResult = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (activeNurse) {
            try {
                setError(false);
                setLoading(true);

                const isResultsImported = testData.reduce((acu, cur) => {
                    if (cur.result.length) {
                        return [...acu, cur];
                    } else {
                        return acu;
                    }
                }, []);

                if (isResultsImported.length === testData.length) {
                    testData.forEach(async (item) => {
                        await insertResult(item.idResult, item.result, activeNurse);
                    });
                    setIsResultInsert(true);
                    setTimeout(handleInsertionOfResult, 250)
                    setTimeout(handleClose, 3000);
                } else {
                    setError('Внесіть результат дослідження, або виберіть із випадаючого списку.')
                }

                setNurseId('');

            } catch (e) {
                console.error(e);
                setError('Вибачте, виникла помилка при внесенні результату.');
            }
        } else {
            setError('Введіть дані медсестри, щоб внести результат.')
        }

        setLoading(false);
    };

    return (<Dialog open={true} onClose={handleClose}>
        <InsertResultDialogContext.Provider value={{
            useNurseId: [nurseId, setNurseId],
            nurseIdRef,
            assay,
            useTestData: [testData, setTestData],
            resultInputRef,
            handleInsertResult
        }}>
            <div className={cl.appBar}>
                <p className={cl.headingText}>Внесення результату</p>
                <IconButton onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>
            </div>
            <div className={cl.dataWrapper}>
                {isResultInsert
                    ? <h3 className={cl.insertResultSuccess}>Результат успішно внесено.</h3>
                    : <>
                        <div className={cl.rootInsertResult}>
                            <div className={cl.assaySelectorWrapper}>
                                <p className={cl.orderDataText}>Дослідження: <b>{assay.name}</b></p>
                                <SelectNurse/>
                            </div>
                            {testData && testData.map((test, idx) => (
                                <TestResultInput test={test} key={test.idResult+''+idx} idx={idx}/>
                            ))}
                        </div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleInsertResult}
                            disabled={loading}
                            className={cl.actionButton}
                        >
                            {loading ? <CircularProgress size="24px"/> : 'Внести результат'}
                        </Button>
                        {error && <p className={cl.error}>{error}</p>}
                    </>
                }
            </div>
        </InsertResultDialogContext.Provider>
    </Dialog>);
};

export default InsertResultDialog;
