import React, {useEffect, useState} from "react";
import useStyles from "../../styles";
import {TabContext} from "@material-ui/lab";
import {Tab, Tabs, withStyles} from "@material-ui/core";
import TabLabel from "./components/TabLabel";
import {useDispatch, useSelector} from "react-redux";
import {tabItemStyles, tabsStyles} from "./helpers/styledTabs";
import {setSelectedTags} from "@cabinet-packages/redux";
import {toggleComplexTransition} from "@cabinet-packages/redux/actions/actionsCreators";

const TabsWithStyles = withStyles(tabsStyles)(Tabs);
const TabWithStyles = withStyles(tabItemStyles)(Tab);

const TabsCatalog = ({useCatalogForView, useSelectedTag, usePrevState, handleValue}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const [catalogForView, setCatalogForView] = useCatalogForView;
    const [tab, setTab] = useState(null);
    const [selectedTag, setSelectedTag] = useSelectedTag;
    const {tags, catalog, complexResearch, complexTransition} = useSelector(state => state.assaysCatalog);
    const [prevStateOfCatalog, setPrevStateOfCatalog] = usePrevState;
    const [filterValue, handleChangeFilterValue] = handleValue;

    const handleChangeTab = (e, index) => {
        e.persist();
        e.preventDefault();
        e.stopPropagation();

        if (index !== tab) {
            setTab(index);
        } else {
            return;
        }

        if (index === 'Усі аналізи') {
            setCatalogForView(null);
            setCatalogForView([...catalog, ...complexResearch].filter(item => !item.superGroupName));
            setSelectedTag('Усі');
            dispatch(setSelectedTags([]));
        } else if (e.currentTarget.id.includes('tag')) {
            setSelectedTag(null)
            dispatch(setSelectedTags([tags.find(item => +item.id === +e.currentTarget.id.split(' ')[1])]));
            setCatalogForView(null);
            setCatalogForView([...catalog, ...complexResearch]);
        } else if (e.currentTarget.id.includes('superGroup')) {
            setSelectedTag(null);
            setCatalogForView(null);
            setCatalogForView([...catalog, ...complexResearch].filter(item => !item.superGroupName && +item.idParent === +e.currentTarget.id.split(' ')[1]));
            dispatch(setSelectedTags([]));
        }

        if (complexTransition) {
            dispatch(toggleComplexTransition());
        } else if (!complexTransition && e.currentTarget.id.includes('superGroup') && +e.currentTarget.id.split(' ')[1] === 77) {
            dispatch(toggleComplexTransition());
        }
        setPrevStateOfCatalog(null);

        if (filterValue) {
            handleChangeFilterValue({target: {value: ''}});
        }
    };

    useEffect(() => {
        if (!selectedTag && catalogForView && catalogForView.length > 0 && !tab) {
            setTab(catalogForView[0].idParent+'');
        } else if (selectedTag === 'Усі') {
            setTab('Усі аналізи')
        } else if (!tab || (selectedTag && selectedTag !== tab)) {
            setTab(selectedTag);
        } else if (!selectedTag && catalogForView && catalogForView.length > 0 && !tab && +catalogForView[0].idParent === 75) {
            setTab(catalogForView[0].idParent+'');
        }
    }, [selectedTag, catalogForView]);

    return (tab && <div className={cl.tabsCatalogWrapper}>
        <TabContext value={tab}>
            <TabsWithStyles
                value={tab}
                onChange={handleChangeTab}
                variant="scrollable"
                orientation="horizontal"
                scrollButtons='on'
            >
                <TabWithStyles
                    value={'Усі аналізи'}
                    wrapped={true}
                    label={<TabLabel isSelected={'Усі аналізи' === tab} txt={'Усі аналізи'} idx={15}/>}
                />
                {tags && tags.map((item, idx) => {
                        return (<TabWithStyles
                                key={item.id + 'tab' + idx}
                                value={item.name}
                                wrapped={true}
                                id={'tag ' + item.id}
                                label={<TabLabel isSelected={item.name === tab} txt={item.name} idx={idx+16}/>}
                        />);
                    })
                }
                {catalog && [...catalog, {idParent: 77, superGroupName: 'Комплексні дослідження', id: 77}].filter(item => item.superGroupName).map((item, idx) => {
                    return (<TabWithStyles
                        key={item.id + 'superGroup' + idx}
                        value={item.id+''}
                        id={'superGroup ' + item.id}
                        wrapped={true}
                        label={<TabLabel isSelected={item.superGroupName === tab} txt={item.superGroupName} idx={idx}/>}
                    />);
                })}
            </TabsWithStyles>
        </TabContext>
    </div>);
}

export default TabsCatalog;
