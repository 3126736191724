export default function getAssayRowHeight(element, width, isDesktop) {
  const divider = width < 300 ? 39 : 35;
  const widthOfLetter = isDesktop ? 9 : 4;
  let fillingsLength = 0;
  let heightOfElement =
    Math.ceil((element.name.length * widthOfLetter) / (width / 2 - 55)) * 23 +
    30;
  const heightOfNote = element.note
    ? (Math.ceil(
        (element.note.replace(/<[^>]*>?/gm, "").length * widthOfLetter) /
          (width / 2 - 55)
      ) *
        23) /
      (isDesktop ? 2 : 1.5)
    : 0;

  heightOfElement = heightOfElement + heightOfNote;

  if (element.type === "complex") {
    fillingsLength = [...element.filling, ...element.idGrTest].reduce(
      (acu, cur) => {
        if (cur.name?.length >= divider) {
          return acu + Math.ceil(cur.name.length / divider);
        } else {
          return acu + 1;
        }
      },
      0
    );
  }

  return (
    (heightOfElement < 53 ? 63 : heightOfElement) +
    (element.note && heightOfElement > (isDesktop ? 130 : 120) && element.note
      ? isDesktop
        ? 20
        : 0
      : element.note
      ? isDesktop
        ? 20
        : 15
      : 0) +
    (fillingsLength ? fillingsLength * (isDesktop ? 18 : 18) + 16 : 0)
  );
}
