import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  rootDialogTitle: {
    background: theme.palette.primary.dark,
    color: 'white',
    borderRadius: '10px 10px 0px 0px',
  },
  paperFullWidth: {
    borderRadius: '10px',
  },
}));

function ConfirmDialogMedis({
  onConfirm = () => null,
  onCancel = () => null,
  handleClose,
  text = 'Ви впевнені, що хочете це зробити?',
  helperText
}) {
  const cl = useStyles();
  const handleCancel = async () => {
    onCancel();
    handleClose();
  };
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };
  return (
    <Dialog
      open={true}
      onClose={handleClose}
      fullWidth
      classes={{paperFullWidth: cl.paperFullWidth}}
    >
      <DialogTitle classes={{root: cl.rootDialogTitle}}>
        Підтвердження
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
          {helperText && <><br/><Typography>{helperText}</Typography></>}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Ні
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          Так
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialogMedis;