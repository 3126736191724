import React from 'react';
import {format as formatDate} from 'date-fns';
import {useDispatch, useSelector} from 'react-redux';
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Table,
    TableFooter,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import {ToggleButton} from '@material-ui/lab';
import {toggleNotification} from '@cabinet-packages/redux';
import {NotificationIcon, Scroll} from '@cabinet-packages/components';
import {usePagination} from '@cabinet-packages/hooks';

const useStyles = makeStyles({
  root: {
    maxWidth: '900px',
    width: '100%',
    padding: '10px',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: '1fr',
    justifyContent: 'stretch',
  },
  listWrapper: {
    position: 'relative',
    width: '100%',
  },
  list: {
    padding: '10px',
    minWidth: '280px',
    width: '100%',
  },
  notificationButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '15px',
  },
});

function NotificationManager(props) {
  const dispatch = useDispatch();
  const notification = useSelector(state => state.user.notification);
  const messages = notification.messages;
  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination(0, 25);


  const handleToggleNotification = () => dispatch(toggleNotification());
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.notificationButtonContainer}>
        <ToggleButton
          value="check"
          selected={notification.isActive}
          size="small"
          onClick={handleToggleNotification}
        >
          {notification.isActive ? 'Вимкнути' : 'Увімкнути'} сповіщення
        </ToggleButton>
      </div>
      <Scroll>
        <List className={classes.list}>
          {messages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(message => (
            <ListItem key={message.id} divider>
              <ListItemAvatar>
                <Avatar>
                  <NotificationIcon type={message.type}/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={message.label}
                secondary={formatDate(message.date, `dd/MM/yyyy - HH:mm:ss`)}
              />
            </ListItem>
          ))}
        </List>
      </Scroll>
      <Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              count={messages.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="На сторінці"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
}

export default NotificationManager;
