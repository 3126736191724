const getWorkingHours = (scheduleStrH, scheduleStrZ) => {
    const formatArrH = scheduleStrH.split(' ');
    const formatArrZ = scheduleStrZ.split(' ');

    const weekdaysH = formatArrH[0].split('.:')[1].split('-');
    const saturdayH = formatArrH[1].split('.:')[1].split('-');
    let sundayH = formatArrH[2].split('.:')[1];

    if (sundayH.includes('-')) {
        sundayH = sundayH.split('-')
    } else {
        sundayH = [sundayH, '']
    }

    const weekdaysZ = formatArrZ[0].split('.:')[1] && formatArrZ[0].split('.:')[1].split('-');
    const saturdayZ = formatArrZ[0].split('.:')[1] && formatArrZ[1].split('.:')[1].split('-');
    let sundayZ = formatArrZ[2].split('.:')[1];

    if (sundayZ && sundayZ.includes('-')) {
        sundayZ = sundayZ.split('-')
    } else {
        sundayZ = ['', '']
    }

    return ({
        weekdaysH,
        saturdayH,
        sundayH,
        weekdaysZ,
        saturdayZ,
        sundayZ,
    });
}
export default getWorkingHours