import React, {useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputBase,
    InputLabel,
    makeStyles,
    Typography,
    useMediaQuery
} from "@material-ui/core";
import StarIcon from '@material-ui/icons/Star';
import {getDateLabelPattern} from "medis-cabinet/src/utils/InputValuePatterns";
import {setCreateFeedback} from "@cabinet-packages/api/api";
import {SwipeButton} from "medis_v2.0/src/components/Cabinet/SwipeStyledButton";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    inputRoot: {
        fontWeight: 600,
        padding: '10px',
        borderBottom: 'none',
        backgroundColor: '#f6f5f5',
    },
    describeInput: {
        padding: '10px',
        border: `1px solid #e8e8e8`,
        backgroundColor: "white",
    },
    inputLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '12px',
        fontWeight: 500,
        margin: '10px'
    },
    itemContainer: {
        backgroundColor: '#f6f5f5',
        margin: theme.spacing(1)
    },
    orderContainer: {
        height: '40px',
    },
    serviceContainer: {
        display: 'grid',
        gridTemplateColumns: '6fr 1.5fr',
        margin: '10px 0px',
        position: 'relative'
    },
    columnLabelsContainer: {
        height: '15px',
    },
    serviceLabelsContainer: {
        display: 'grid',
        gridTemplateColumns: '6fr 1.5fr',
        height: '15px',
    },
    columnLabel: {
        fontSize: '12px',
        fontWeight: 500,
        color: theme.palette.grey.text
    },
    orderDetailLabel: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 14
    },
    dialogContainer: {
        '& .MuiPaper-rounded': {
            borderRadius: '20px'
        },
    },
    dialogContent: {
        margin: '30px 30px 0px 30px',
        '@media(max-width: 900px)': {
            margin: '10px'
        }
    },
    codeBlock: {
        padding: '30px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        borderRadius: '20px',
        width: 'min-content',
        margin: 'auto'
    },
    dialogTitle: {
        position: 'relative',
        fontFamily: 'Open-sans',
        backgroundColor: theme.palette.primary.dark,
        textAlign: 'center',
        color: 'white',
    },
    dialogText: {
        fontWeight: '500',
        fontSize: '15px',
        //width: '40%',
        '@media(max-width: 600px': {
            width: '80%'
        },
        textAlign: 'center',
        marginTop: '30px'
    },
    closeIcon: {
        position: 'absolute',
        right: 5,
        top: 0,
        margin: 'auto'
    }
}));

const RespondVisitDialog = ({handleClose, result, mark}) => {
    const classes = useStyles()
    const mdUp = useMediaQuery(theme => theme.breakpoints.up('md'))
    const [textValue, setTextValue] = useState('')
    const handleInputValue = (value) => {
        setTextValue(value);
    };

    const handleSendFeedback = async () => {
        setCreateFeedback(result.idOrder, result.address, result.idClient, mark, textValue)
        handleClose()
    };

    return (
        <Dialog
            open={true}
            onClose={() => {
                handleClose()
            }}
            PaperProps={{
                style: { borderRadius: 10, maxWidth: '700px'}
            }}
        >
            <DialogTitle classes={{root: classes.dialogTitle}}>
                ДОДАТИ ВІДГУК НА ВІЗИТ
                <CloseIcon style={{position: 'absolute', right: 15, margin: 'auto', color: 'white'}} onClick={handleClose}/>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container direction={'row'} spacing={2}>
                    <Grid item xs={12} className={classes.itemContainer}>
                        {result && (
                            <>
                                <Grid container spacing={1}>
                                    <Grid item xs={mdUp ? 2 : 12}>
                                        <Typography className={classes.columnLabel}>
                                            ID
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={mdUp ? 5 : 12} style={{order: mdUp ? 0 : 2}}>
                                        <Typography className={classes.columnLabel}>
                                            Час здачі
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={mdUp ? 5 : 12} style={{order: mdUp ? 0 : 4}}>
                                        <Typography className={classes.columnLabel}>
                                            Адреса
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={mdUp ? 2 : 12} style={{order: mdUp ? 0 : 1}}>
                                        <Typography className={classes.orderDetailLabel} color={'textPrimary'} component={"div"}>
                                            {result.idOrder}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={mdUp ? 5 : 12} style={{order: mdUp ? 0 : 3}}>
                                        <Typography className={classes.orderDetailLabel} color={'textPrimary'} component={"div"}>
                                            {getDateLabelPattern(result.dt) + " " + result.dt.slice(10, result.dt.length)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={mdUp ? 5 : 12} style={{order: mdUp ? 0 : 5}}>
                                        <Typography className={classes.orderDetailLabel} color={'textPrimary'} component={"div"}>
                                            {result.address}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{display: 'flex', alignItems: 'center', columnGap: '5px'}}>
                            <InputLabel className={classes.inputLabel} style={{flexGrow: 1}}>Підскажіть що нам можна покращити</InputLabel>
                            <span>
                                <StarIcon color={"primary"} fontSize={"small"} />
                            </span>
                            <Typography className={classes.orderDetailLabel} style={{paddingRight: '10px'}}>
                                {mark}
                            </Typography>
                        </div>
                        <InputBase
                            variant="standard"
                            className={classes.describeInput}
                            inputProps={{
                                placeholder: "Напишіть Ваш відгук"
                            }}
                            style={{height: '100px'}}
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(event) => handleInputValue(event.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{display: 'flex', justifyContent: 'center', margin: '20px 0'}}>
                <SwipeButton onClick={handleSendFeedback} text={"ДОДАТИ"} />
            </DialogActions>
        </Dialog>
    )
}

export default RespondVisitDialog;