import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
    Typography,
} from '@material-ui/core';
import {SwipeStyledButton} from "../index";

const useStyles = makeStyles(theme => ({
  generalDialog: {
    borderRadius: '15px'
  },
  dialogHeader: {
    background: theme.palette.colors.main,
    color: 'white',
    textAlign: 'center',
    fontSize: '1.5rem'
  },
  dialogContent: {
    textAlign: 'center',
    color: theme.palette.colors.text,
    fontSize: '20px'
  },
  dialogActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

function ConfirmCabinetDialog({
  onConfirm = () => null,
  onCancel = () => null,
  handleClose,
  text = 'Ви впевнені, що хочете це зробити?',
  helperText
}) {
  const classes = useStyles()

  const handleCancel = () => {
    onCancel();
    handleClose();
  };
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };
  return (
    <Dialog
      open={true}
      onClose={handleClose}
      fullWidth
      classes={{paper: classes.generalDialog}}
    >
      <DialogTitle classes={{root: classes.dialogHeader}}>
        Підтвердження
      </DialogTitle>
      <DialogContent>
        <DialogContentText classes={{root: classes.dialogContent}}>
          {text}
          {helperText && <><br/><Typography variant="caption">{helperText}</Typography></>}
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{root: classes.dialogActions}}>
        <SwipeStyledButton
            animationStart={'right'}
            animationMaterialColor={'#ff2121'}
            buttonMaterialColor={'#ffffff'}
            hoverTextColor={'#ffffff'}
            border={"0px solid #ffffff"}
            onClick={handleCancel}
        >
          Ні
        </SwipeStyledButton>
        <SwipeStyledButton
            animationStart={'right'}
            onClick={handleConfirm}
        >
          Так
        </SwipeStyledButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmCabinetDialog;