import axios from "axios";
import {
  AccessKey,
  adoptDayRange,
  adoptWorkingHours,
} from "@cabinet-packages/helpers";
import { adoptMaterial } from "../helpers/adoptMaterial";
import { adoptWorkingHoursVdoma } from "@cabinet-packages/helpers/adoptWorkingTime";
import {handleAuthorization} from "@cabinet-packages/helpers/getSetAccessKey";

// const env = "/customerservice";
// const env = "/agentapi";
// const env = "";

const env = process.env.REACT_APP_API_ENV;

const apiPath = process.env.REACT_APP_API_PATH || "/api/customers/";
const apiCollection = process.env.REACT_APP_API_PATH || "/api/collection/";
const apiPathForDoctor = process.env.REACT_APP_API_PATH || "/api/doctors/";

const biokursApi = "/rest/api.php";
const isMedisPdf = process.env.REACT_APP_CURRENT_CLINIC === "MEDIS" || true;

const isMedis = process.env.REACT_APP_CURRENT_CLINIC === "MEDIS";

const checkIsReadyPDF = async (result) => {
  const accessKey = AccessKey.get();

  if (isMedisPdf) return result; ///////////hardcode for old pdf generate

  const status = await axios.post(
    env + `/api/results/getStatus`,
    { uuid: result.data.uuid },
    {
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    }
  );

  if (status.data.status === "READY") {
    return await axios.post(
      env + `/api/results/getFile`,
      { uuid: result.data.uuid },
      {
        headers: {
          Authorization: "Bearer " + accessKey,
        },
        responseType: "arraybuffer",
      }
    );
  } else {
    return await checkIsReadyPDF(result);
  }
};

export const authorise = async (uuidPhone, phone) => {
  const resNumber = phone.replace(/[[()\s]/g, "");
  const data = {
    phone: resNumber,
    uuid: uuidPhone,
  };

  return await axios.post(env + "/api/auth/authorise", data);
};

export const accept = async (uuidPhone, code) => {
  const data = {
    uuid: uuidPhone,
    code: code,
  };

  return await axios.post(env + "/api/auth/accept", data);
};

export const acceptAdmin = async (uuidPhone, code) => {
  const accessKey = AccessKey.get();

  const data = {
    uuid: uuidPhone,
    code: code,
  };

  return await axios.post(env + "/api/auth/acceptAdmin", data, {
    headers: {
      Authorization: "Bearer " + accessKey,
    },
  });
};

export const adminRedirect = async (token) => {
  const data = {
    token,
  };

  const result = await axios.post(env + "/api/auth/superAdminAccept", data);

  return result.data;
};

export const acceptDoctor = async (uuidPhone, code) => {
  const data = {
    uuid: uuidPhone,
    code: code,
  };

  return await axios.post(env + "/api/auth/acceptDoctor", data);
};

export const logout = async () => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");
  if (!accessKey) return;

  return await axios.get(env + "/api/auth/logout", {
    headers: {
      Authorization: "Bearer " + accessKey,
      AccessPhone: phone,
    },
  });
};

export const getPatientOrders = async (patientId) => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");

  if (!accessKey) return;

  const result = await axios.get(
    env + `/api/customers/getPatientOrders/${patientId}`,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    }
  );

  return result.data.reduce((acu, cur) => {
    if (acu.find((item) => item.idOrder === cur.idOrder)) {
      return [
        ...acu.map((item) => {
          if (item.idOrder === cur.idOrder) {
            if (typeof item.packet === "object") {
              return {
                ...item,
                packet: [...item.packet, cur.packet],
                ready: +item.ready === 0 ? cur.ready : item.ready,
              };
            }

            return {
              ...item,
              packet: [item.packet, cur.packet],
              ready: +item.ready === 0 ? cur.ready : item.ready,
            };
          }

          return item;
        }),
      ];
    }

    return [...acu, cur];
  }, [])
};

export const getPatientOrdersByPage = async (patientId, page) => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");

  if (!accessKey) return;

  const result = await axios.get(
    env + `/api/customers/patientOrdersLimit/${patientId}/${page}`,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    }
  );

  let reducedResults = result.data.reduce((acu, cur) => {
    if (acu.find((item) => item.idOrder === cur.idOrder)) {
      return [
        ...acu.map((item) => {
          if (item.idOrder === cur.idOrder) {
            if (typeof item.packet === "object") {
              return {
                ...item,
                packet: [...item.packet, cur.packet],
                ready: +item.ready === 0 ? cur.ready : item.ready,
              };
            }

            return {
              ...item,
              packet: [item.packet, cur.packet],
              ready: +item.ready === 0 ? cur.ready : item.ready,
            };
          }

          return item;
        }),
      ];
    }

    return [...acu, cur];
  }, []);

  return await Promise.all(
    reducedResults.map(async (item) => {
      return await getCanShowFeedback(item.idOrder).then((data) => {
        return { ...item, feedback: data };
      });
    })
  );
};

export const getOrdersResultByOrderID = async (orderID, patientID) => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");
  if (!accessKey) return;

  const result = await axios.get(
    env + `/api/customers/getOrdersResult/${orderID}/${patientID}`,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    }
  );

  return result.data.filter(item => !(item?.packet.includes('1516') && (item?.idtest === 406 || item?.idtest === 262)))
};

export const getOrdersResult = async (patientId) => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");

  if (!accessKey) return;

  const result = await axios.get(
    env + `/api/customers/getHistoryPatientByPatientId/${patientId}`,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    }
  );

  return result.data;
};

export const getHistoryByClientId = async (patientId) => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");

  if (!accessKey) return;

  const result = await axios.get(
    env + `/api/customers/getOrderHistory/${patientId}`,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    }
  );

  return result.data || []
};

export const getAllPunktsZabir = async () => {
  const accessKey = AccessKey.get();

  const result = await axios.get(env + apiPath + "getAllPunkts", {
    headers: {
      Authorization: "Bearer " + accessKey,
    },
  });
  return result.data;
};

export const getWebOrders = async (data) => {
  const accessKey = localStorage.getItem("accessKey");

  const result = await axios.post(env + apiCollection + "getWebOrders", data, {
    headers: {
      Authorization: "Bearer " + accessKey,
    },
  });
  return result.data;
};

export const search = async (data) => {
  const accessKey = localStorage.getItem("accessKey");

  const result = await axios.post(env + apiCollection + "search", data, {
    headers: {
      Authorization: "Bearer " + accessKey,
    },
  });
  return result.data;
};

export const getOrdersZabir = async (data) => {
  const accessKey = localStorage.getItem("accessKey");

  const result = await axios.post(env + apiCollection + "getOrders", data, {
    headers: {
      Authorization: "Bearer " + accessKey,
    },
  });
  return result.data;
};

export const getPunktsAPI = async (showKids) => {
  const accessKey = AccessKey.get();
  const accessPhone = localStorage.getItem("activePatientPhone");
  const projectName = process.env.REACT_APP_PROJECT_NAME;

  const result = await axios.get(env + apiPath + "getPunkts", {
    headers: {
      Authorization: "Bearer " + accessKey,
      AccessPhone: accessPhone,
    },
  });

  return result.data
    .sort((a, b) => {
      return a.address.localeCompare(b.address);
    })
    .reduce((acu, cur) => {
      let arrayHwork = [];
      let arrayZwork = [];
      let arrayRwork = [];
      let workingHoursCyrylic = {};
      let takingHoursCyrylic = {};
      let onLineHoursCyrylic = {};

      if (showKids && !cur.opt.includes("kids")) return acu;

      if (!cur.onsite && projectName !== "esculab_agent") return acu;

      if (cur && cur.zWork && cur.zWork.length > 0) {
        arrayZwork = cur.zWork
          .trim()
          .split(".:")
          .map((el) => {
            return el.trim().split(".").join("").split(" ");
          })
          .flat(1);

        arrayHwork = cur.hWork
          .trim()
          .split(".:")
          .map((el) => {
            return el.trim().split(".").join("").split(" ");
          })
          .flat(1);

        arrayRwork =
          cur.rwork && cur.rwork.length > 0
            ? cur.rwork
                .trim()
                .split(".:")
                .map((el) => {
                  return el.trim().split(".").join("").split(" ");
                })
                .flat(1)
            : null;

        workingHoursCyrylic = arrayHwork.reduce((acu, cur, idx, arr) => {
          if (idx % 2 === 1) return { ...acu, [arr[idx - 1]]: cur };
          return acu;
        }, {});

        takingHoursCyrylic = arrayZwork.reduce((acu, cur, idx, arr) => {
          if (idx % 2 === 1) return { ...acu, [arr[idx - 1]]: cur };
          return acu;
        }, {});

        onLineHoursCyrylic = arrayRwork
          ? arrayRwork.reduce((acu, cur, idx, arr) => {
              if (idx % 2 === 1) return { ...acu, [arr[idx - 1]]: cur };
              return acu;
            }, {})
          : null;
      }

      const workingHours = Object.keys(workingHoursCyrylic)
        .map((key) =>
          adoptDayRange(key).map((dayOfWeek) => ({
            [dayOfWeek]: adoptWorkingHours(workingHoursCyrylic[key]),
          }))
        )
        .flat(1);

      const takingHours = Object.keys(takingHoursCyrylic)
        .map((key) =>
          adoptDayRange(key).map((dayOfWeek) => ({
            [dayOfWeek]: adoptWorkingHours(takingHoursCyrylic[key]),
          }))
        )
        .flat(1);

      const onLineHours = onLineHoursCyrylic
        ? Object.keys(onLineHoursCyrylic)
            .map((key) =>
              adoptDayRange(key).map((dayOfWeek) => ({
                [dayOfWeek]: adoptWorkingHours(onLineHoursCyrylic[key]),
              }))
            )
            .flat(1)
        : null;

      return [
        ...acu,
        { ...cur, lng: cur.lon, workingHours, takingHours, onLineHours },
      ];
    }, []);
};

export const getPatients = async () => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");

  if (!accessKey || !phone) {
    AccessKey.remove();
    localStorage.removeItem("activePatientPhone");
    window.location.reload();
  }

  const data = {
    phone,
  };

  return await axios
    .post(env + "/api/customers/findPatient", data, {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    })
    .then((response) => {
      return response.data.map((patient) => ({
        ...patient,
        birthday: patient.birthday
          ? patient.birthday.slice(0, 10)
          : "Не внесено.",
      }));
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        handleAuthorization(true)

        localStorage.removeItem("activePatient");
        localStorage.removeItem("activePatientPhone");
        localStorage.removeItem("uuid");
        AccessKey.remove();
        window.location.reload();

        return "Unauthorized";
      }

      return "error";
    });
};

export const getPrice = async () => {
  const accessKey = AccessKey.get();

  const resultData = await axios.get(env + apiPath + "getPrice", {
    headers: {
      Authorization: "Bearer " + accessKey,
    },
  });

  if (resultData) {
    const arrayOfGroupedAssays = resultData.data.reduce((acu, cur) => {
      // if (+cur.idParent === 99) { //crunch for patient cabinet
      //   return acu;
      // }
      if (cur.childAnalyzes.length === 0) return acu;
      const findSuperGroup = acu.find(
        (item) => item.superGroupName === cur.superGroupName
      );

      if (findSuperGroup) {
        return [
          ...acu.filter((item) => item !== findSuperGroup),
          {
            ...findSuperGroup,
            idParent: 0,
            childAnalyzes: [
              ...findSuperGroup.childAnalyzes,
              ...cur.childAnalyzes,
            ],
          },
        ];
      }
      return [...acu, { ...cur, idParent: 0 }];
    }, []);

    return arrayOfGroupedAssays
      .sort((a, b) => a.name.localeCompare(b.name))
      .reduce((acu, cur) => {
        return [
          ...acu,
          {
            ...cur,
            childAnalyzes: [],
          },
          ...cur.childAnalyzes
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((assay) => ({
              ...assay,
              take: assay.take.length > 0 ? assay.take.split(",") : [],
              ...adoptMaterial(assay.filling, "material"),
              ...adoptMaterial(assay.filling, "name"),
            })),
        ];
      }, [])
      .map((item) => ({ ...item, type: "assay" }));
  }
};

export const getComplexOfAssays = async (complexId) => {
  const accessKey = AccessKey.get();
  const accessPhone = localStorage.getItem("activePatientPhone");
  const idreg = localStorage.getItem("idReg");

  const resultData = isMedis
    ? await axios.post(env + "/api/customers/getPriceByRegion", { idreg })
    : await axios.get(env + apiPath + "getPriceComplex", {
        headers: {
          Authorization: "Bearer " + accessKey,
          AccessPhone: accessPhone,
        },
      });

  return resultData.data
      .reduce((acu, cur) => {
        if (!isMedis) {
          return [...acu, cur]
        }
        if (cur.idParent === complexId) {
          return [...acu, ...cur.childAnalyzes];
        }

        return acu
      }, [])
      .map((complex) => ({
    ...complex,
    idGrTest: typeof complex.idGrTest === "string"
      ? JSON.parse(complex.idGrTest).map((item) => ({
          name: item["name\\"] || item["name"],
          id: +item["idgrtest\\"] ||  +item["idgrtest"],
          type: "assay",
        }))
      : [],
    take: complex.take && complex.take.split(","),
    type: "complex",
  }));
};

export const getActionsFromServer = async () => {
  const accessKey = AccessKey.get();
  const accessPhone = localStorage.getItem("activePatientPhone");

  const result = await axios.get(env + apiPath + "getPromotions", {
    headers: {
      Authorization: "Bearer " + accessKey,
      AccessPhone: accessPhone,
    },
  });

  return result.data.map((item) => ({
    ...item,
    type: "action",
    name: item.name && item.name.split("_").join(" "),
    title: item.title && item.title.split("_").join(" "),
  }));
};

export const getClientLocationAPI = async () => {
  try {
    const clientPosition = await new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    const { latitude, longitude } = clientPosition.coords;

    return { lat: latitude, lng: longitude };
  } catch (e) {
    return null;
  }
};

export const getOpenSchedule = async () => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");
  const result = await axios.get(env + apiPath + "getSchedule", {
    headers: {
      Authorization: "Bearer " + accessKey,
      AccessPhone: phone,
    },
  });

  return result.data.map((item) => ({
    ...item,
    dt: item.dt.split("\\").join(""),
  }));
};

export const setCreateClient = async (
  uuid,
  lastname,
  firstname,
  fathername,
  dt,
  sex,
  phone,
  email
) => {
  const data = {
    uuid,
    lastname,
    firstname,
    fathername,
    dt,
    sex,
    phone,
    email,
    role: 'main0' +
        '' +
        '' +
        '' +
        '' +
        '' +
        ''
  };

  const result = await axios.post(env + "/api/auth/createPatient", data);

  return result.data;
};

export const setRegisterChild = async (
  mainPatientID,
  lastname,
  firstname,
  fathername,
  dt,
  sex,
  email,
  role
) => {
  const phone = localStorage.getItem("activePatientPhone");
  const accessKey = AccessKey.get();

  const data = {
    ownerId: mainPatientID,
    id: mainPatientID,
    lastname,
    firstname,
    fathername,
    dt,
    sex,
    phone,
    email,
    patientRole: role,
  };

  const result = await axios.post(
    env + `/api/auth/registerChild/${mainPatientID}`,
    data,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    }
  );

  return result.data;
};

export const editPatientInfo = async (
  lastname,
  firstname,
  fathername,
  dt,
  sex,
  id,
  email,
  role = 'main',
  ownerId
) => {
  if (
    lastname?.length === 0 ||
    firstname?.length === 0 ||
    dt?.length === 0 ||
    sex?.length === 0 ||
    email?.length === 0
  ) {
    return;
  }

  const birthday = typeof dt === "string" ? dt : dt.toISOString().slice(0, 10);
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");

  const data = {
    lastname,
    firstname,
    fathername,
    dt: birthday,
    sex,
    id,
    ownerId,
    email,
    patientRole: role,
    role
  };

  const result = await axios.put(env + "/api/customers/editPatientData", data, {
    headers: {
      Authorization: "Bearer " + accessKey,
      AccessPhone: phone,
    },
  });

  return result.data;
};

export const getAssayInfo = async (idAssay) => {
  const insurance = process.env.REACT_APP_INSURANCE === "true";
  const result = await axios.get(
    !!env + insurance
      ? `/api/agent/getDetails/${idAssay}`
      : `/api/customers/getInfo/${idAssay}`
  );
  if (insurance) {
    return result.data.info;
  }
  return result.data;
};

export const getDetails = async (idAssay) => {
  const result = await axios.get(env + `/api/collection/getDetails/${idAssay}`);

  return result.data;
};

export const getQuickPage = async (hash) => {
  const result = await axios.get(env + `/api/customers/getQuickPage/${hash}`);
  return result.data;
};

export const postIdOrderPdv = async (idOrder, idClient) => {
  const accessKey = AccessKey.get();
  const accessPhone = localStorage.getItem("activePatientPhone");

  const projectConfig = isMedisPdf
    ? {
        headers: {
          Authorization: "Bearer " + accessKey,
          AccessPhone: accessPhone,
        },
        responseType: "arraybuffer",
      }
    : {
        headers: {
          Authorization: "Bearer " + accessKey,
          AccessPhone: accessPhone,
        },
      };

  if (!accessKey) return;

  const result = await axios.get(
    env + `/api/results/getResults/${idClient}/${idOrder}`,
    projectConfig
  );

  const pdf = await checkIsReadyPDF(result);

  return pdf.data;
};

export const getResultsMedis = async (idOrder, idClient) => {
  const accessKey = AccessKey.get();

  if (!accessKey) return;

  const projectConfig = {
    headers: {
      Authorization: "Bearer " + accessKey,
    },
    responseType: "arraybuffer",
  };

  const result = await axios.get(
    env + `/api/results/getResultsMedis/${idClient}/${idOrder}`,
    projectConfig
  );

  return result.data;
};

export const getResults = async (idOrder, idClient) => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");

  if (!accessKey) return;

  const projectConfig = {
    headers: {
      Authorization: "Bearer " + accessKey,
      AccessPhone: phone,
    },
    responseType: "arraybuffer",
  };

  const result = await axios.get(
      env + `/api/results/getResults/${idClient}/${idOrder}`,
      projectConfig
  );

  return result.data;
};

export const setExistingPatients = async (
  mainId,
  childId,
  phone,
  confirmedAgreement,
  confirmedCabinet,
  isNewPatient,
  newPatient
) => {
  const accessKey = AccessKey.get();
  const uuid = localStorage.getItem("uuid");

  const result = await axios.post(
    env + "/api/auth/registerExistingPatient",
    {
      mainId,
      childId,
      phone,
      confirmedAgreement,
      confirmedCabinet,
      uuid,
      isNewPatient,
      newPatient,
    },
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    }
  );

  return result.data;
};

export const getCanShowFeedback = async (orderId) => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");

  if (!accessKey && !orderId) return;

  const result = await axios.get(
    env + `/api/feedback/canShowFeedback/${orderId}`,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    }
  );

  return result.data;
};

export const setCreateFeedback = async (
  orderId,
  address,
  idClient,
  rating,
  message
) => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");

  if (!accessKey && !orderId && !rating && !message) return;

  const result = await axios.post(
    env + "/api/feedback/createFeedbackAssay",
    {
      orderId,
      address,
      idClient,
      dt: new Date().toISOString().slice(0, 10),
      rating,
      message,
    },
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    }
  );

  return result.data;
};

export const setCreateOrder = async (
  idPunkt,
  note,
  fullname,
  idClient,
  orderId,
  dt,
  price,
  priceDiscount,
  payment,
  promo,
  services,
  num = 'reserved'
) => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");

  if (!accessKey) return;

  const data = {
    idPunkt,
    note,
    fullname,
    idClient,
    dt,
    price,
    priceDiscount,
    services,
    num,
    promo,
    payment,
  };

  const result = await axios.post(env + "/api/customers/createOrder", data, {
    headers: {
      Authorization: "Bearer " + accessKey,
      AccessPhone: phone,
    },
  });

  return result.data;
};

export const getCheckPayment = async (orderId) => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");

  if (!accessKey && !orderId) return;

  const result = await axios.get(
    env + `/api/customers/checkPayment/${orderId}`,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    }
  );

  return result.data;
};

export const getAllOrdersByPhone = async (patientId) => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");

  if (!accessKey && !patientId) return;

  const result = process.env.REACT_APP_API_PATH
    ? await axios.get(env + apiPath + `getOrdersbyPhone/${patientId}`, {
        headers: {
          Authorization: "Bearer " + accessKey,
          AccessPhone: phone,
        },
      })
    : await axios.get(env + `/api/customers/getOrdersbyPhone/${patientId}`, {
        headers: {
          Authorization: "Bearer " + accessKey,
          AccessPhone: phone,
        },
      });

  return result.data;
};

export const getOrdersHistory = async (patientId) => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");

  if (!accessKey && !patientId) return;

  const result = process.env.REACT_APP_API_PATH
    ? await axios.get(env + apiPath + `getOrderSchedule/`, {
        headers: {
          Authorization: "Bearer " + accessKey,
          AccessPhone: phone,
        },
      })
    : await axios.get(env + `/api/customers/getOrderSchedule/${patientId}`, {
        headers: {
          Authorization: "Bearer " + accessKey,
          AccessPhone: phone,
        },
      });

  return result.data;
};

export const getFeedbacksForShop = async () => {
  const result = await axios.get(env + "/api/feedback/getFeedbacks");
  return result.data;
};

export const getDoctor = async () => {
  const accessKey = AccessKey.get();
  if (!accessKey) return;

  const phone = localStorage.getItem("activePatientPhone");

  const data = {
    phone,
  };

  return await axios
    .post(env + "/api/doctors/getDoctorInfo", data, {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response.status === 401) {
        AccessKey.remove()
        localStorage.removeItem('activePatient')
        localStorage.removeItem('activePatientPhone')
        return "Unauthorized";
      }

      return error;
    });
};

export const setExistingDoctors = async (
  ownerId,
  otherAccounts,
  phone,
  confirmedAgreement,
  confirmedCabinet
) => {
  const accessKey = AccessKey.get();
  const uuid = localStorage.getItem("uuid");

  const result = await axios.post(
    env + "/api/auth/registerDoctorExisting",
    {
      ownerId,
      otherAccounts,
      phone,
      confirmedAgreement,
      confirmedCabinet,
      uuid,
    },
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    }
  );

  return result.data;
};

export const editDoctorInfo = async (
  lastname,
  firstname,
  fathername,
  dt,
  sex,
  id
) => {
  if (
    lastname.length === 0 ||
    firstname.length === 0 ||
    dt.length === 0 ||
    sex.length === 0
  ) {
    return;
  }
  const phone = localStorage.getItem("activePatientPhone");

  const accessKey = AccessKey.get();

  const data = {
    lastname,
    firstname,
    fathername,
    dt,
    sex,
    id,
  };

  const result = await axios.put(env + "/api/doctors/editDoctorData", data, {
    headers: {
      Authorization: "Bearer " + accessKey,
      AccessPhone: phone,
    },
  });

  return result.data;
};

export const getPhrases = async () => {
  const result = await axios.get(env + "/api/customers/phrases");

  return result.data;
};

export const getOrdersResultByDate = async (doctorId, dateFrom, dateTo) => {
  const phone = localStorage.getItem("activePatientPhone");
  const accessKey = AccessKey.get();
  const data = {
    doctorId,
    dateFrom,
    dateTo,
  };

  try {
    const result = await axios.post(
      env + apiPathForDoctor + "getOrdersByDate",
      data,
      {
        headers: {
          Authorization: "Bearer " + accessKey,
          AccessPhone: phone,
        },
      }
    );

    return result.data;
  } catch (e) {
    if (e.response.status === 401) {
      localStorage.removeItem("accessKey");
      AccessKey.remove();
      window.location.reload();
      return [];
    } else {
      console.log(e);
    }
  }
};

export const getOrdersResultByFullName = async (doctorId, fullname) => {
  const phone = localStorage.getItem("activePatientPhone");
  const accessKey = AccessKey.get();
  const data = {
    doctorId,
    fullname,
  };

  const result = await axios.post(
    env + apiPathForDoctor + "getOrdersByFullname",
    data,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    }
  );

  return result.data;
};

export const getCheckForAuthorized = async () => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");

  if (!accessKey) return "Unauthorized";

  return await axios
    .get(env + "/api/customers/checkAuth", {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    })
    .then((response) => {
      if (response.data === "ADMIN") {
        localStorage.setItem("isAdmin", "true");
        return "ADMIN";
      }
      localStorage.removeItem("isAdmin");
      localStorage.removeItem("unregisteredCabinet");
      return "PATIENT";
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        localStorage.removeItem("activePatient");
        localStorage.removeItem("activePatientPhone");
        localStorage.removeItem("uuid");
        AccessKey.remove();
        window.location.reload();

        return "Unauthorized";
      }

      return error;
    });
};

export const getOrdersResultForDoctor = async (orderId, doctorId) => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");

  if (!accessKey) return;

  const data = {
    orderId,
    doctorId,
  };

  const result = await axios.post(
    env + apiPathForDoctor + "getOrdersResult",
    data,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    }
  );

  return result.data;
};

export const getPatientsForDoctorOrdering = async (phone) => {
  const accessKey = AccessKey.get();
  const Dphone = localStorage.getItem("activePatientPhone");

  if (!accessKey) return;

  const data = {
    phone,
  };

  const result = await axios.post(
    env + apiPathForDoctor + "getPatients",
    data,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: Dphone,
      },
    }
  );

  return result.data;
};

export const sendCodeToPatient = async (phone, uuid) => {
  const accessKey = AccessKey.get();
  const Dphone = localStorage.getItem("activePatientPhone");

  if (!accessKey) return;

  const data = {
    phone,
    uuid,
  };

  const result = await axios.post(env + apiPathForDoctor + "sendCode", data, {
    headers: {
      Authorization: "Bearer " + accessKey,
      AccessPhone: Dphone,
    },
  });

  return result.data;
};

export const confirmPatientCode = async (uuid, code) => {
  const accessKey = AccessKey.get();
  const Dphone = localStorage.getItem("activePatientPhone");

  if (!accessKey) return;

  const data = {
    uuid,
    code,
  };

  const result = await axios.post(env + apiPathForDoctor + "confirm", data, {
    headers: {
      Authorization: "Bearer " + accessKey,
      AccessPhone: Dphone,
    },
  });

  return result.data;
};

export const createOrderDoctors = async (num, idClient, idDoctor, services, idPunkt) => {
  const accessKey = AccessKey.get();
  const Dphone = localStorage.getItem("activePatientPhone");

  if (!accessKey) return;

  const data = {
    num: 'reserved',
    idPunkt,
    idClient,
    dt: new Date().toISOString().slice(0, 10) + " 07:00:00.0",
    payment: "cash",
    note: "",
    promo: 'none',
    idDoctor,
    services,
  };

  const result = await axios.post(
    env + "/api/doctors/createOrderDoctors",
    data,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: Dphone,
      },
    }
  );

  return result.data;
};

export const createOrderAgent = async (
  num,
  idClient,
  idDoctor,
  services,
  idPunkt,
  date,
  note,
  vials
) => {
  const accessKey = AccessKey.get();

  if (!accessKey) return;

  // const now = new Date();
  // const current = new Date(
  //     // now.getFullYear(), now.getMonth() + 1, now.getDate()
  // ).toISOString().slice(0, 10) + ' 00:00:00.0';
  const current = date
    ? date
    : new Date()
        // now.getFullYear(), now.getMonth() + 1, now.getDate()
        .toISOString()
        .slice(0, 10) + " 00:00:00.0";

  const data = {
    num,
    idPunkt,
    idClient,
    dt: current,
    agentInfo: {
      dateValid: current
    },
    payment: vials?.radio === 'false' ? "bank" : 'cash',
    note,
    idDoctor,
    services,
  };

  if (vials && (vials?.date || vials?.policy) ) {
    data.agentInfo = {
      vaucher: vials.policy,
      dateValid: vials.date,
      amount: 0,
      percent: 0,
    };
    if (vials.radio === "percent") {
      data.agentInfo.percent = +vials.num;
    } else if (vials.radio === "sum") {
      data.agentInfo.amount = +vials.num;
    }
  }

  const result = await axios.post(env + "/api/agent/createOrder", data, {
    headers: {
      Authorization: "Bearer " + accessKey,
    },
  });

  return result.data;
};

export const getOrderInsurance = async (data) => {
  const accessKey = AccessKey.get();

  if (!accessKey) return;

  try {
    const result = await axios.post(
      env + "/api/agent/getOrderInsurance",
      data,
      {
        headers: {
          Authorization: "Bearer " + accessKey,
        },
      }
    );

    return result.data;
  } catch (e) {
    if (e.response.status === 401) {
      localStorage.removeItem("accessKey");
      AccessKey.remove();
      window.location.reload();
      return [];
    } else {
      console.log(e);
    }
  }
};

export const cancelOrder = async (id) => {
  const accessKey = AccessKey.get();

  if (!accessKey) return;

  try {
    const result = await axios.get(env + `/api/agent/cancelOrder/${id}`, {
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    });

    return result.data;
  } catch (e) {
    if (e.response.status === 401) {
      localStorage.removeItem("accessKey");
      AccessKey.remove();
      window.location.reload();
      return [];
    } else {
      console.log(e);
    }
  }
};

export const setCreatePatientByDoctor = async (
  lastname,
  firstname,
  fathername,
  dt,
  sex,
  phone,
  email
) => {
  const accessKey = AccessKey.get();
  const Dphone = localStorage.getItem("activePatientPhone");

  const data = {
    lastname,
    firstname,
    fathername,
    dt,
    sex,
    phone,
    email,
  };

  const result = await axios.post(
    env + apiPathForDoctor + "createPatient",
    data,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: Dphone,
      },
    }
  );

  return result.data;
};

export const getDoctorOrderSchedule = async (idDoctor) => {
  const accessKey = AccessKey.get();
  const Dphone = localStorage.getItem("activePatientPhone");

  const result = process.env.REACT_APP_API_PATH
    ? await axios.get(env + apiPath + `getOrderSchedule`, {
        headers: { Authorization: "Bearer " + accessKey },
      })
    : await axios.get(env + `/api/doctors/getOrderSchedule/${idDoctor}`, {
        headers: {
          Authorization: "Bearer " + accessKey,
          AccessPhone: Dphone,
        },
      });

  return result.data;
};

export const getDoctorOrderedAssayPdf = async (
  fullname,
  idClient,
  orderId,
  idDoctor,
  dt,
  price,
  priceDiscount,
  services
) => {
  const accessKey = AccessKey.get();
  const Dphone = localStorage.getItem("activePatientPhone");

  const projectConfig = isMedisPdf
    ? {
        headers: {
          Authorization: "Bearer " + accessKey,
          AccessPhone: Dphone,
        },
        responseType: "arraybuffer",
      }
    : {
        headers: {
          Authorization: "Bearer " + accessKey,
          AccessPhone: Dphone,
        },
      };

  const data = {
    fullname,
    idClient,
    orderId,
    idDoctor,
    dt,
    price,
    priceDiscount,
    services,
  };

  if (!accessKey) return;
  const result = await axios.post(
    env + `/api/doctors/printDirection`,
    data,
    projectConfig
  );

  const pdf = await checkIsReadyPDF(result);

  return pdf.data;
};

export const getRegions = async () => {
  const result = await axios.get(env + "/api/customers/getRegions");

  return result.data;
};

export const getGoodInfo = async (goodId) => {
  const result = await axios.get(env + `/api/customers/getInfo/${goodId}`);

  return result.data;
};


export const getPriceForRegion = async (takeId, complexId, sortByAz = true) => {
  const idReg = localStorage.getItem("idReg");
  const resultData = idReg
    ? await axios.post(env + "/api/customers/getPriceByRegion", {
        idreg: idReg,
      })
    : idReg;

  if (resultData && takeId && complexId) {
    const arrayOfGroupedAssays = resultData.data.reduce((acu, cur) => {
      if (+cur.id === complexId) return acu;
      if (cur.childAnalyzes.length === 0) return acu;
      const findSuperGroup = acu.find(
        (item) => item.superGroupName === cur.superGroupName
      );

      if (findSuperGroup) {
        return [
          ...acu.filter((item) => item !== findSuperGroup),
          {
            ...findSuperGroup,
            idParent: 0,
            childAnalyzes: [
              ...findSuperGroup.childAnalyzes,
              ...cur.childAnalyzes,
            ],
          },
        ];
      }

      return [...acu, { ...cur, idParent: 0 }];
    }, []);

    return arrayOfGroupedAssays
      .sort((a, b) => sortByAz ? a.name.localeCompare(b.name) : true)
      .reduce((acu, cur) => {
        return [
          ...acu,
          {
            ...cur,
            childAnalyzes: [],
          },
            ...[...cur.childAnalyzes]
            .sort((a, b) => sortByAz ? a.name.localeCompare(b.name) : true)
            .map((assay) => ({
              ...assay,
              // filling : typeof assay.idGrTest === 'string' ? [...(Array.isArray(assay.filling) ? assay.filling : []), ...JSON.parse(assay.idGrTest)] : assay.filling,
              take: assay.take.length > 0 ? assay.take.split(",") : [],
              ...adoptMaterial(assay.filling, "material"),
              ...adoptMaterial(assay.filling, "name"),
              testNamesArr: assay?.testNames && assay.testNames.length
                  ? assay.testNames.split(`,\\`).map(i=>i.split(`\\`).join('')).filter(i=>i)
                  : []
            })),
        ];
      }, [])
    //     .map(item => {
    //   if (item.superGroupName) {
    //     return ({...item, type: 'assay', id: item.id === 14 || item.id === 75 ? item.id : +(item.id +''+666)});
    //   } else {
    //     return ({...item, type: 'assay', idParent: item.idParent === 14 || item.idParent === 75 ? item.idParent : +(item.idParent +''+666)});
    //   }
    // })
  }
};

export const getPriceForAdmin = async () => {
  const idReg = localStorage.getItem("idReg");
  const resultData = idReg
    ? await axios.post(env + "/api/customers/getPriceAdmin", { idreg: idReg })
    : idReg;

  if (resultData) {
    const arrayOfGroupedAssays = resultData.data.reduce((acu, cur) => {
      if (cur.childAnalyzes.length === 0) return acu;
      const findSuperGroup = acu.find(
        (item) => item.superGroupName === cur.superGroupName
      );

      if (findSuperGroup) {
        return [
          ...acu.filter((item) => item !== findSuperGroup),
          {
            ...findSuperGroup,
            idParent: 0,
            childAnalyzes: [
              ...findSuperGroup.childAnalyzes,
              ...cur.childAnalyzes,
            ],
          },
        ];
      }
      return [...acu, { ...cur, idParent: 0 }];
    }, []);

    let arrayOfGroupedAssaysAdmin = arrayOfGroupedAssays.map((item) => {
      if (item.id === 127) {
        let childs = item.childAnalyzes.map((child) => {
          return { ...child, idParent: 127 };
        });

        return { ...item, childAnalyzes: childs };
      }
      return item;
    });

    return arrayOfGroupedAssaysAdmin
      .sort((a, b) => a.name.localeCompare(b.name))
      .reduce((acu, cur) => {
        return [
          ...acu,
          {
            ...cur,
            childAnalyzes: [],
          },
          ...cur.childAnalyzes
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((assay) => ({
              ...assay,
              take: assay.take.length > 0 ? assay.take.split(",") : [],
              ...adoptMaterial(assay.filling, "material"),
              ...adoptMaterial(assay.filling, "name"),
            })),
        ];
      }, [])
      .map((item) => ({ ...item, type: "assay" }));
  }
};

export const getPromo = async (code) => {
  const data = {
    action: "promo",
    code,
    // idpunkt: '15',
  };
  const result = await axios.post(env + "/api/customers/getPromo", data);

  return result.data;
};

export const printDirection = async (data) => {
  const projectConfig = isMedisPdf ? { responseType: "arraybuffer" } : {};
  const result = await axios.post(
    env + "/api/doctors/printDirection",
    data,
    projectConfig
  );

  const pdf = await checkIsReadyPDF(result);

  return pdf.data;
};

export const getScheduleByDate = async (date = "2021-03-23") => {
  const data = {
    date,
  };

  const result = await axios.post(
    env + `/api/customers/getScheduleByDate`,
    data
  );

  return result.data;
};

export const getScheduleByIdPunkt = async (idPunkt) => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");
  const data = {
    idPunkt,
  };

  const result = await axios.post(
    env + apiPath + `getScheduleByIdPunkt`,
    data,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    }
  );

  return result.data;
};

export const getScheduleByAllPunkts = async () => {
  const result = await axios.get(env + `/api/customers/getScheduleByAllPunkts`);

  return result.data;
};

export const getPayment = async (orderId) => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");

  const projectConfig = isMedisPdf
    ? {
        headers: {
          Authorization: "Bearer " + accessKey,
          AccessPhone: phone,
        },
        responseType: "arraybuffer",
      }
    : {
        headers: {
          Authorization: "Bearer " + accessKey,
          AccessPhone: phone,
        },
      };

  if (!accessKey) return;

  const result = await axios.get(
    env + `/api/customers/getPayment/${orderId}`,
    projectConfig
  );

  const pdf = await checkIsReadyPDF(result);

  return pdf.data;
};

export const doctorLoginForBiokurs = async (login, pwd) => {
  const data = {
    action: "doctor_login",
    login,
    pwd,
  };
  const result = await axios.post(biokursApi, data);

  return result.data;
};
export const getDoctorOrders = async (dt_from, dt_to) => {
  const accessKey = AccessKey.get();
  if (!accessKey) return;

  const data = {
    action: "get_doctor_orders",
    access_key: accessKey,
    dt_from,
    dt_to,
  };

  const result = await axios.post(biokursApi, data);

  return result.data.orders;
};

export const searchDoctorOrders = async (search) => {
  const accessKey = AccessKey.get();
  if (!accessKey) return;

  const data = {
    action: "search_doctor_orders",
    access_key: accessKey,
    search,
  };

  const result = await axios.post(biokursApi, data);

  return typeof result.data === "string" ? [] : result.data.orders;
};

export const getDoctorResult = async (idorder) => {
  const accessKey = AccessKey.get();
  if (!accessKey) return;

  const data = {
    action: "get_doctor_result",
    access_key: accessKey,
    idorder,
  };

  const result = await axios.post(biokursApi, data, {
    responseType: "arraybuffer",
  });

  return result.data;
};

export const createQuickOrder = async (
  idPunkt,
  dt,
  payment,
  lastname,
  firstname,
  fathername,
  birthday,
  sex,
  phone,
  email,
  engTranslate,
  num,
  note = "",
  idAgent,
  canal
) => {
  const services = engTranslate;

  const data = {
    num: num.length >= 25 ? num.slice(0, 24) : num,
    idPunkt,
    dt,
    payment,
    note,
    services,
    idAgent,
    canal,
    registration: {
      lastname,
      firstname,
      fathername,
      dt: birthday,
      sex,
      phone,
      email,
    },
  };

  const result = await axios.post(
    env + `/api/customers/createQuickOrder`,
    data
  );

  return result.data;
};

export const sendRefund = async (
  lastname,
  name,
  fathername,
  orderId,
  phone,
  city,
  dt,
  info
) => {
  const accessKey = AccessKey.get();
  if (!accessKey) return;

  const data = {
    lastname,
    name,
    fathername,
    orderId,
    phone,
    city,
    dt,
    info,
  };

  return await axios.post(env + `/api/customers/refund`, data, {
    headers: {
      Authorization: "Bearer " + accessKey,
    },
  });
};

export const getUnregisteredPatient = async (phone) => {
  const accessKey = AccessKey.get();
  if (!accessKey) return;

  const data = {
    phone,
  };

  return await axios.post(env + `/api/customers/getUnregisteredPatient`, data, {
    headers: {
      Authorization: "Bearer " + accessKey,
      AccessPhone: phone,
    },
  });
};

export const addToCabinet = async (mainId, childId) => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");
  if (!accessKey) return;

  const data = {
    mainId,
    childId,
  };

  return await axios.post(env + `/api/customers/addToCabinet`, data, {
    headers: {
      Authorization: "Bearer " + accessKey,
      AccessPhone: phone,
    },
  });
};

export const validateOrder = async (dt, idOrder) => {
  const accessKey = AccessKey.get();
  if (!accessKey) return;
  const phone = localStorage.getItem("activePatientPhone");

  const data = {
    dt,
    idOrder,
  };

  return await axios.post(env + `/api/customers/validateOrder`, data, {
    headers: {
      Authorization: "Bearer " + accessKey,
      AccessPhone: phone,
    },
  });
};

export const getPriceCovid = async () => {
  const resultData = await axios.get(env + "/api/customers/getPriceCovid");
  return resultData.data;
};

export const getTags = async () => {
  const resultData = await axios.get(env + "/api/customers/tags");

  return resultData.data;
};

export const getPriceDetails = async (id) => {
  const resultData = await axios.get(
    env + `/api/customers/getPriceDetails/${id}`
  );

  return resultData.data;
};

export const createOrderVisit = async (
  idPunkt,
  note,
  fullname,
  idClient,
  orderId,
  dt,
  price,
  priceDiscount,
  payment,
  promo,
  services,
  num = "reserved"
) => {
  const phone = localStorage.getItem("activePatientPhone");
  const accessKey = AccessKey.get();

  if (!accessKey) return;

  const data = {
    idPunkt,
    note,
    fullname,
    idClient,
    dt,
    price,
    priceDiscount,
    services,
    num,
    promo,
    payment,
  };

  const result = await axios.post(
    env + "/api/customers/createOrderVisit",
    data,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    }
  );

  return result.data;
};

export const updateOrderScheduleDate = async (patientId, orderId, date) => {
  const phone = localStorage.getItem("activePatientPhone");
  const accessKey = AccessKey.get();

  if (!accessKey) return;

  const data = {
    patientId,
    date,
  };

  const result = await axios.post(
    env + `/api/customers/updateOrderScheduleDate/${orderId}`,
    data,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    }
  );

  return result.data;
};

export const updateOrderSchedulePunkt = async (patientId, orderId, punktId) => {
  const phone = localStorage.getItem("activePatientPhone");
  const accessKey = AccessKey.get();

  if (!accessKey) return;

  const data = {
    patientId,
    punktId,
  };

  const result = await axios.post(
    env + `/api/customers/updateOrderSchedulePunkt/${orderId}`,
    data,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        AccessPhone: phone,
      },
    }
  );

  return result.data;
};

export const authorisePartner = async (uuid, userName, password) => {
  const data = {
    uuid,
    userName,
    password,
  };

  return await axios.post(env + "/agent/auth/authorise", data);
};

export const acceptPartner = async (uuid, code) => {
  const data = {
    uuid,
    code,
  };

  return await axios.post(env + "/agent/auth/accept", data);
};

export const getPrice_v2 = async () => {
  const accessKey = AccessKey.get();

  return await axios.get(env + apiPath + "getPrice", {
    headers: {
      Authorization: "Bearer " + accessKey,
    },
  });
};

export const getAssay = async (orderId) => {
  const accessKey = AccessKey.get();

  return await axios.get(env + apiPath +
      // `getAssay/${orderId}`,
      `referral/assays/${orderId}`,
      {
    headers: {
      Authorization: "Bearer " + accessKey,
      AccessPhone: localStorage.getItem('activePatientPhone')

    },
  });
};

export const getLikedPromotions = async (ownerId) => {
  const accessKey = AccessKey.get();

  const result = await axios.get(
    env + apiPath + `getLikedPromotions/${ownerId}`,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    }
  );

  return result.data;
};

export const likePromotion = async (ownerId, promotionId, type) => {
  const accessKey = AccessKey.get();

  const data = {
    ownerId,
    likeId: promotionId,
    type: type ? type : "PROMOTION",
  };

  return await axios.post(env + apiPath + `likePromotion`, data, {
    headers: {
      Authorization: "Bearer " + accessKey,
    },
  });
};

export const deleteLikePromotion = async (ownerId, promotionId) => {
  const accessKey = AccessKey.get();

  const data = {
    ownerId,
    likeId: promotionId,
  };

  return await axios.post(env + apiPath + `deleteLikePromotion`, data, {
    headers: {
      Authorization: "Bearer " + accessKey,
    },
  });
};

export const authoriseClinic = async (userName, password) => {
  const data = {
    userName,
    password,
  };

  return await axios.post(env + "/clinic/auth/authorise", data);
};

export const getPriceWizard = async (id) => {
  const idPunkt =
    id ||
    localStorage.getItem("idPunkt") ||
    JSON.parse(localStorage.getItem("activeUser"))?.idPunkt;

  const data = {
    idPunkt: idPunkt,
    locale: "",
    region: true,
  };

  const resultData = await axios.post("/api/collection/getPrice", data);

  if (resultData) {
    const arrayOfGroupedAssays = resultData.data.reduce((acu, cur) => {
      if (cur.childAnalyzes.length === 0) return acu;
      const findSuperGroup = acu.find(
        (item) => item.superGroupName === cur.superGroupName
      );

      if (findSuperGroup) {
        return [
          ...acu.filter((item) => item !== findSuperGroup),
          {
            ...findSuperGroup,
            idParent: 0,
            childAnalyzes: [
              ...findSuperGroup.childAnalyzes,
              ...cur.childAnalyzes,
            ],
          },
        ];
      }
      return [...acu, { ...cur, idParent: 0 }];
    }, []);

    return arrayOfGroupedAssays
      .sort((a, b) => a.name.localeCompare(b.name))
      .reduce((acu, cur) => {
        return [
          ...acu,
          {
            ...cur,
            childAnalyzes: [],
          },
          ...cur.childAnalyzes
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((assay) => ({
              ...assay,
              take: assay.take.length > 0 ? assay.take.split(",") : [],
              ...adoptMaterial(assay.filling, "material"),
              ...adoptMaterial(assay.filling, "name"),
            })),
        ];
      }, [])
      .map((item) => ({ ...item, type: "assay" }));
  }
};

export const getPriceComplexWizard = async (id) => {
  const idPunkt =
    id ||
    localStorage.getItem("idPunkt") ||
    JSON.parse(localStorage.getItem("activeUser"))?.idPunkt;

  const data = {
    idPunkt: typeof idPunkt === "object" ? idPunkt[0] : idPunkt,
    locale: "",
    region: true,
  };

  const resultData = await axios.post("/api/collection/getPriceComplex", data);

  return resultData.data.map((complex) => ({
    ...complex,
    idGrTest: complex.idGrTest
      ? JSON.parse(complex.idGrTest).map((item) => ({
          name: item["name\\"],
          id: +item["idgrtest\\"],
          type: "assay",
        }))
      : [],
    take: complex.take && complex.take.split(","),
    type: "complex",
    filling: complex.filling || [],
  }));
};

export const authoriseWizard = async (uuid, userName, password) => {
  const data = {
    uuid,
    userName,
    password,
  };

  return await axios.post("/board/auth/authorise", data);
};

export const acceptWizard = async (uuid, code) => {
  const data = {
    uuid,
    code,
  };

  return await axios.post("/board/auth/accept", data);
};

export const patientAuth = async (method, uuid, code, phone) => {
  const accessKey = localStorage.getItem("accessKey");
  const resNumber = phone.replace(/[[()\s]/g, "");

  if (!accessKey) return;

  const data = {
    method,
    uuid,
    code,
    phone: resNumber,
  };

  return await axios.post("/api/cabinet/patientAuth", data, {
    headers: {
      Authorization: "Bearer " + accessKey,
    },
  });
};

export const checkPatient = async (method, uuid, code, phone, token) => {
  const accessKey = localStorage.getItem("accessKey");
  const resNumber = phone && phone.replace(/[[()\s]/g, "");

  if (!accessKey) return;

  const data = {
    method,
    uuid,
    code,
    phone: resNumber,
    token,
  };

  return await axios
    .post("/api/cabinet/checkPatient", data, {
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response.status === 401) return "Unauthorized";

      return "error";
    });
};

export const registerNew = async (
  uuid,
  lastname,
  firstname,
  fathername,
  dt,
  sex,
  phone,
  email
) => {
  const accessKey = localStorage.getItem("accessKey");

  if (!accessKey) return;

  const data = {
    uuid,
    lastname,
    firstname,
    fathername,
    dt,
    sex,
    phone,
    email,
  };

  return await axios
    .post("/api/cabinet/registerNew", data, {
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response.status === 401) return "Unauthorized";

      return "error";
    });
};

export const registerExisting = async (
  mainId,
  childId,
  phone,
  confirmedAgreement,
  confirmedCabinet,
  isNewPatient,
  newPatient,
  uuid
) => {
  const accessKey = localStorage.getItem("accessKey");
  const token = AccessKey.get();

  if (!accessKey) return;

  const data = {
    mainId,
    childId,
    phone,
    confirmedAgreement,
    confirmedCabinet,
    isNewPatient,
    newPatient,
    token,
    uuid,
  };

  return await axios
    .post("/api/cabinet/registerExisting", data, {
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response.status === 401) return "Unauthorized";

      return "error";
    });
};

export const editPatientData = async (
  lastname,
  firstname,
  fathername,
  dt,
  sex,
  id,
  uuid
) => {
  if (
    lastname.length === 0 ||
    firstname.length === 0 ||
    dt.length === 0 ||
    sex.length === 0
  ) {
    return;
  }

  const birthday = typeof dt === "string" ? dt : dt.toISOString().slice(0, 10);
  const accessKey = localStorage.getItem("accessKey");
  const token = AccessKey.get();

  const data = {
    lastname,
    firstname,
    fathername,
    dt: birthday,
    sex,
    id,
    uuid,
    token,
  };

  const result = await axios.post("/api/cabinet/editPatientData", data, {
    headers: {
      Authorization: "Bearer " + accessKey,
    },
  });

  return result.data;
};

export const registerChild = async (
  mainId,
  uuid,
  lastname,
  firstname,
  fathername,
  dt,
  sex,
  phone,
  email
) => {
  const accessKey = localStorage.getItem("accessKey");
  const token = AccessKey.get();

  if (!accessKey || !token) return;

  const data = {
    uuid,
    lastname,
    firstname,
    fathername,
    dt,
    sex,
    phone,
    email,
    token,
  };

  return await axios
    .post(`/api/cabinet/registerChild/${mainId}`, data, {
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response.status === 401) return "Unauthorized";

      return "error";
    });
};

export const createTubesWizard = async (fullOrderData) => {
  const accessKeyPatient = localStorage.getItem("accessKey");
  if (!accessKeyPatient) return;

  const orderSchedule = {
    ...fullOrderData,
    idOrder: fullOrderData.id,
  };

  const result = await axios.post(
    "/api/collection/getCreatedTubes",
    orderSchedule,
    { headers: { Authorization: "Bearer " + accessKeyPatient } }
  );

  return result.data;
};

export const checkAuthZabir = async () => {
  const accessKeyPatient = localStorage.getItem("accessKey");
  if (!accessKeyPatient) return;
  const result = await axios.get("/board/auth/me", {
    headers: { Authorization: "Bearer " + accessKeyPatient },
  });
  return result.data;
};

export const setCreateOrderWizard = async (
  idPunkt,
  note,
  fullname,
  idClient,
  orderId,
  dt,
  price,
  priceDiscount,
  payment,
  promo,
  services,
  num = "reserved",
  pdom
) => {
  const accessKey = localStorage.getItem("accessKey");

  if (!accessKey) return;

  const data = {
    idPunkt,
    note,
    fullname,
    idClient,
    dt,
    price,
    priceDiscount,
    services,
    num,
    promo,
    payment,
    pdom,
  };

  const result = await axios.post("/api/collection/createOrder", data, {
    headers: { Authorization: "Bearer " + accessKey },
  });

  return result.data;
};

export const createOrderWithTubes = async (
  idPunkt,
  id,
  note,
  doctor,
  idClient,
  services,
  pdom
) => {
  const accessKey = localStorage.getItem("accessKey");

  if (!accessKey) return;

  const data = {
    orderSchedule: {
      idPunkt,
      id,
      note,
      idDoctor: doctor,
      idClient,
      services,
    },
    pdom,
  };

  const result = await axios.post("/api/collection/createOrder", data, {
    headers: { Authorization: "Bearer " + accessKey },
  });

  return result.data;
};

export const saveEHealthToken = async (token, eHealthToken) => {
  const data = {
    token,
    eHealthToken,
  };
  return await axios.post("/api/mlis/ehealth/auth", data);
};

export const getResultSlider = async () => {
  const result = await axios.get(env + "/api/customers/getSlider");
  return result.data;
};

export const getResultArticles = async () => {
  const result = await axios.get(env + "/api/site/getBanner");
  return result.data;
};

export const addTempPatient = async (
  uuid,
  lastname,
  firstname,
  fathername,
  dt,
  sex,
  phone,
  email
) => {
  const accessKey = AccessKey.get();

  if (!accessKey) return;

  const data = {
    uuid,
    lastname,
    firstname,
    fathername,
    dt,
    sex,
    phone,
    email,
  };

  return await axios
    .post("/api/customers/addTempPatient", data, {
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response.status === 401) return "Unauthorized";

      return "error";
    });
};

export const verifyConfirm = async (code, uuid) => {
  const accessKey = AccessKey.get();

  if (!accessKey) return;

  const data = {
    uuid,
    code,
  };

  return await axios
    .post("/api/verify/confirm", data, {
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    })
    .then((response) => response.data);
};

export const verifySendCode = async (phone, uuid) => {
  const accessKey = AccessKey.get();
  const resNumber = phone.replace(/[[()\s]/g, "");

  if (!accessKey) return;

  const data = {
    uuid,
    phone: resNumber,
  };

  return await axios
    .post("/api/verify/sendCode", data, {
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    })
    .then((response) => response.data);
};

export const createOrderTempUser = async (
  idPunkt,
  note,
  fullname,
  idClient,
  orderId,
  dt,
  price,
  priceDiscount,
  payment,
  promo,
  services,
  num = "reserved"
) => {
  const accessKey = AccessKey.get();

  if (!accessKey) return;

  const data = {
    idPunkt,
    note,
    fullname,
    idClient,
    dt,
    price,
    priceDiscount,
    services,
    num,
    promo,
    payment,
  };

  const result = await axios.post(
    env + "/api/customers/createOrderTempUser",
    data,
    { headers: { Authorization: "Bearer " + accessKey } }
  );

  return result.data;
};

export const sendFormResult = async (firstname, lastname, phone, email, text) => {
  const data = {
    firstname,
    lastname,
    email,
    phone,
    comment: text,
  }

  return await axios.post("/api/site/mail/help", data);
}

export const sendContactRequest = async ({
  firstname,
  lastname,
  email,
  comment,
  phone,
  idUser,
  date,
}) => {
  const accessKey = AccessKey.get();
  const data = {
    firstname,
    lastname,
    fathername: "",
    email,
    comment,
    phone,
    idUser,
    date,
  };
  try {
    return await axios.post("/api/site/getHelp", data, {
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    });
  } catch (e) {
    console.log("sendFormResult error", e.message);
  }
};

export const getOrderData = async (id) => {
  const accessKey = localStorage.getItem("accessKey");

  if (!id) {
    console.log("No ID.", id);
    return;
  }

  const resultData = await axios.get(`/api/collection/getOrderData/${id}`, {
    headers: {
      Authorization: "Bearer " + accessKey,
    },
  });

  return resultData.data;
};

export const getAllPunktsAPI = async (showMorePunkts, showKids) => {
  const accessKey = AccessKey.get();

  const result = await axios.get(env + apiPath + "getAllPunkts", {
    headers: {
      Authorization: "Bearer " + accessKey,
    },
  });

  return result.data
    .sort((a, b) => {
      return a.address.localeCompare(b.address);
    })
    .reduce((acu, cur) => {
      let arrayHwork = [];
      let arrayZwork = [];
      let arrayRwork = [];
      let workingHoursCyrylic = {};
      let takingHoursCyrylic = {};
      let onLineHoursCyrylic = {};

      if (cur && cur.zWork && cur.zWork.length > 0) {
        arrayZwork = cur.zWork
          .trim()
          .split(".:")
          .map((el) => {
            return el.trim().split(".").join("").split(" ");
          })
          .flat(1);

        arrayHwork = cur.hWork
          .trim()
          .split(".:")
          .map((el) => {
            return el.trim().split(".").join("").split(" ");
          })
          .flat(1);

        arrayRwork =
          cur.rwork && cur.rwork.length > 0
            ? cur.rwork
                .trim()
                .split(".:")
                .map((el) => {
                  return el.trim().split(".").join("").split(" ");
                })
                .flat(1)
            : null;

        workingHoursCyrylic = arrayHwork.reduce((acu, cur, idx, arr) => {
          if (idx % 2 === 1) return { ...acu, [arr[idx - 1]]: cur };
          return acu;
        }, {});

        takingHoursCyrylic = arrayZwork.reduce((acu, cur, idx, arr) => {
          if (idx % 2 === 1) return { ...acu, [arr[idx - 1]]: cur };
          return acu;
        }, {});

        onLineHoursCyrylic = arrayRwork
          ? arrayRwork.reduce((acu, cur, idx, arr) => {
              if (idx % 2 === 1) return { ...acu, [arr[idx - 1]]: cur };
              return acu;
            }, {})
          : null;
      }

      const workingHours = Object.keys(workingHoursCyrylic)
        .map((key) =>
          adoptDayRange(key).map((dayOfWeek) => ({
            [dayOfWeek]: adoptWorkingHoursVdoma(workingHoursCyrylic[key]),
          }))
        )
        .flat(1);

      const takingHours = Object.keys(takingHoursCyrylic)
        .map((key) =>
          adoptDayRange(key).map((dayOfWeek) => ({
            [dayOfWeek]: adoptWorkingHoursVdoma(takingHoursCyrylic[key]),
          }))
        )
        .flat(1);

      const onLineHours = onLineHoursCyrylic
        ? Object.keys(onLineHoursCyrylic)
            .map((key) =>
              adoptDayRange(key).map((dayOfWeek) => ({
                [dayOfWeek]: adoptWorkingHoursVdoma(onLineHoursCyrylic[key]),
              }))
            )
            .flat(1)
        : null;

      return [
        ...acu,
        { ...cur, lng: cur.lon, workingHours, takingHours, onLineHours },
      ];
    }, [])
    .filter((punkt) =>
      showKids
        ? punkt.opt.includes("kids")
        : showMorePunkts
        ? punkt.idPunkt === 195 ||
          punkt.idPunkt === 196 ||
          punkt.idPunkt === 197 ||
          punkt.idPunkt === 198 ||
          punkt.idPunkt === 199 ||
          punkt.idPunkt === 200 ||
          punkt.idPunkt === 201 ||
          punkt.idPunkt === 204
        : punkt.idPunkt === 204
    );
};

export const getAgentsApi = async () => {
  const accessKey = localStorage.getItem("accessKey");
  try {
    const resultData = await axios.get("/api/collection/getAgents", {
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    });

    return resultData.data;
  } catch (e) {
    console.error(e);
  }
};

export const getPriceAgentsApi = async (services, agent) => {
  const accessKey = localStorage.getItem("accessKey");

  try {
    const data = {
      services: [...services],
      id: agent.id,
    };
    const resultData = await axios.post(
      env + "/api/collection/getAgentPrice",
      data,
      {
        headers: {
          Authorization: "Bearer " + accessKey,
        },
      }
    );

    if (resultData) {
      const arrayOfGroupedAssays = resultData.data.reduce((acu, cur) => {
        if (cur.childAnalyzes.length === 0) return acu;
        const findSuperGroup = acu.find(
          (item) => item.superGroupName === cur.superGroupName
        );

        if (findSuperGroup) {
          return [
            ...acu.filter((item) => item !== findSuperGroup),
            {
              ...findSuperGroup,
              idParent: 0,
              childAnalyzes: [
                ...findSuperGroup.childAnalyzes,
                ...cur.childAnalyzes,
              ],
            },
          ];
        }
        return [...acu, { ...cur, idParent: 0 }];
      }, []);

      return arrayOfGroupedAssays
        .sort((a, b) => a.name.localeCompare(b.name))
        .reduce((acu, cur) => {
          return [
            ...acu,
            {
              ...cur,
              childAnalyzes: [],
            },
            ...cur.childAnalyzes
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((assay) => ({
                ...assay,
                take: assay.take.length > 0 ? assay.take.split(",") : [],
                ...adoptMaterial(assay.filling, "material"),
                ...adoptMaterial(assay.filling, "name"),
              })),
          ];
        }, [])
        .map((item) => ({ ...item, type: "assay" }));
    }
  } catch (e) {
    console.error(e);
  }
};

export const sendDiayaRequest = async (data) => {
  try {
    const response = await axios.post("/sendDiayaRequest", data, {
      headers: {
        "Content-Length": 0,
        "Content-Type": "text/plain",
      },
      responseType: "text",
    });

    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const getOrder = async (data) => {
  try {
    const response = await axios.get(`/getOrder/${data}`);

    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const printOrderVials = async (vials, fullOrderData) => {
  if (!vials) {
    console.error("Vials array is empty.");
    return;
  }
  try {
    const response = await axios.post(`/local/printOrderVials`, {
      vials,
      fullOrderData,
    });

    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const getNurses = async () => {
  const accessKey = localStorage.getItem("accessKey");

  try {
    const response = await axios.get(`/api/collection/getNurses`, {
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    });

    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const updateTubes = async (tube) => {
  const accessKey = localStorage.getItem("accessKey");

  try {
    const response = await axios.post(`/api/collection/updateTube`, tube, {
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    });

    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const updateOrder = async (data) => {
  const accessKey = localStorage.getItem("accessKey");

  try {
    const response = await axios.post(`/api/collection/updateOrder`, data, {
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    });

    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const updateWebOrder = async (data) => {
  const accessKey = localStorage.getItem("accessKey");

  try {
    await axios.post(`/api/collection/updateWebOrder`, data, {
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    });
  } catch (e) {
    console.error(e);
  }
};

export const getReToken = async (hash) => {
  try {
    const response = await axios.get(`api/auth/getReToken/${hash}`);

    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const getOrderDataShort = async (id) => {
  const accessKey = localStorage.getItem("accessKey");

  if (!id) {
    console.error("No ID.", id);
    return;
  }

  const resultData = await axios.get(
    `/api/collection/getOrderDataShort/${id}`,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    }
  );

  return resultData.data;
};

export const insertResult = async (id, result, iduser) => {
  const accessKey = localStorage.getItem("accessKey");

  try {
    const response = await axios.post(
      `/api/collection/insertResult`,
      { id, result, iduser },
      {
        headers: {
          Authorization: "Bearer " + accessKey,
        },
      }
    );

    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const generateResult = async (idOrder) => {
  const accessKey = localStorage.getItem("accessKey");

  try {
    const response = await axios.get(
      `/api/collection/generateResult/${idOrder}`,
      {
        headers: {
          Authorization: "Bearer " + accessKey,
        },
        responseType: "arraybuffer",
      }
    );

    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const printResultPdf = async (id, hash) => {
  const accessKey = localStorage.getItem("accessKey");

  try {
    const response = await axios.post(`/local/printResultPdf`, {
      id,
      accessKey,
      hash,
    });

    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const printResultPdfOnResultPage = async (hash) => {
  console.log("hash is:", hash);
  const response = await axios.post(`/local/printResultPdf`, { hash });

  return response.data;
};

export const exchangeResult = async (code, uuid) => {
  const response = await axios.post(`api/auth/exchangeResult`, {
    auth: false,
    code,
    uuid,
  });

  return response.data;
};

export const localAuthorise = async (uuidPhone, phone) => {
  const resNumber = phone.replace(/[[()\s]/g, "");
  const data = {
    phone: resNumber,
    uuid: uuidPhone,
  };

  return await axios.post(env + "/local/authorise", data);
};

export const localExchangeResult = async (code, uuid) => {
  const response = await axios.post(`/local/exchangeResult`, {
    auth: false,
    code,
    uuid,
  });

  return response.data;
};

export const getOrderHistory = async (hash) => {
  const result = await axios.get(`/api/report/getOrderHistory/${hash}`);

  return result.data;
};

export const getResult = async (hash) => {
  const result = await axios.post(`/api/report/getResult/${hash}`);

  return result.data;
};

export const getInfo = async (orderId) => {
  const result = await axios.post(`/api/report/getInfo/${orderId}`);

  return result.data;
};

const header = () => {
  if (localStorage) {
    return {
      AccessPhone: localStorage.getItem("activePatientPhone"),
    };
  }
};

//////////////////////////////////////////////////////////site
export const getPatientsSite = async () => {
  const accessKey = AccessKey.get();
  const phone = localStorage.getItem("activePatientPhone");

  if (!accessKey || !phone) {
    AccessKey.remove();
    localStorage.removeItem("activePatientPhone");
    localStorage.removeItem("activePatient");
    window.location.reload();
  }

  const data = {
    phone,
  };

  return await axios
    .post("/api/customers/findPatientSite", data, {
      headers: {
        Authorization: "Bearer " + accessKey,
        ...header(),
      },
    })
    .then((response) => {
      return response.data.map((patient) => ({
        ...patient,
        birthday: patient.birthday
          ? patient.birthday.slice(0, 10)
          : "Не внесено.",
      }));
    })
    .catch((error) => {
      if (error?.response?.status === 401) return "Unauthorized";

      return "error";
    });
};

export const getCheckForAuthorizedSite = async () => {
  const accessKey = AccessKey.get();
  if (!accessKey) return "Unauthorized";

  return await axios
    .get("/api/customers/checkAuthSite", {
      headers: {
        Authorization: "Bearer " + accessKey,
        ...header(),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response.status === 401) return "Unauthorized";

      return error;
    });
};

export const setRegisterChildSite = async (
  mainPatientID,
  lastname,
  firstname,
  fathername,
  dt,
  sex,
  email
) => {
  const phone = localStorage.getItem("activePatientPhone");
  const accessKey = AccessKey.get();
  const data = {
    lastname,
    firstname,
    fathername,
    dt,
    sex,
    phone,
    email,
  };
  const result = await axios.post(
    `/api/auth/registerChild/${mainPatientID}`,
    data,
    {
      headers: {
        Authorization: "Bearer " + accessKey,
        ...header(),
      },
    }
  );

  return result.data;
};

export const setExistingPatientsSite = async (
  mainId,
  childId,
  phone,
  confirmedAgreement,
  confirmedCabinet
) => {
  const accessKey = AccessKey.get();
  const uuid = localStorage.getItem("uuid");

  try {
    const result = await axios.post(
      "/api/auth/registerExistingPatient",
      {
        mainId,
        childId,
        phone: `+380${phone}`,
        confirmedAgreement,
        confirmedCabinet,
        uuid,
      },
      { headers: { Authorization: "Bearer " + accessKey, ...header() } }
    );
    return result.data;
  } catch (e) {
    console.log("setExistingPatientsError", e.message);
  }
};

export const editPatientInfoSite = async (
  lastname,
  firstname,
  fathername,
  dt,
  sex,
  id
) => {
  if (
    lastname.length === 0 ||
    firstname.length === 0 ||
    dt.length === 0 ||
    sex.length === 0
  ) {
    return;
  }

  const birthday = typeof dt === "string" ? dt : dt.toISOString().slice(0, 10);
  const accessKey = AccessKey.get();

  const data = {
    lastname,
    firstname,
    fathername,
    dt: birthday,
    sex,
    id,
  };

  const result = await axios.put("/api/customers/editPatientData", data, {
    headers: {
      Authorization: "Bearer " + accessKey,
      ...header(),
    },
  });

  return result.data;
};

export const acceptSale = async (uuidPhone, code) => {
  const data = {
    uuid: uuidPhone,
    code: code,
  };

  return await axios.post(env + "/api/auth/acceptSales", data);
};

export const sendLiquepay = async (idorder, url) => {
  const accessKey = AccessKey.get();

  const result = await axios.post(`/api/agent/sendLiquepay/${idorder}`, url, {
    headers: {
      Authorization: "Bearer " + accessKey,
      "Content-Length": url.length,
      "Content-Type": "text/plain;charset=UTF-8",
      ...header(),
    },
  });
  return result.data;
};

export const getOrderSum = async (idorder) => {
  const result = await axios.get(`/api/agent/getOrderSum/${idorder}`);
  return result.data;
};
