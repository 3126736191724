import {createTheme} from '@material-ui/core';

const themeMaterialUI = createTheme({
  shape: {
    borderRadius: '25px',
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '25px',
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '0.875rem',
      }
    }
  },
  palette: {
    primary:  {
      main: '#0077c888',
      dark: '#1a4767',
      light: '#f7fcff',
      50: '#0077c8',
      100: '#7ccaff',
      item: '#0077c85e',
      cell: '#0077c899',
    },
    secondary: {
      main: '#e4002b88',
    },
    error: {
      main: '#A02929',
      light: '#e41e15',
    },
    header: {
      main: '#fafafa',
    },
    star: {
      color: '#ffb400',
    },
  },
});

export default themeMaterialUI;
