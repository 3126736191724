import {SET_MENU_BURGER} from "../actions/actionsTypes";

export const menuReducerInitialState = {
    menuBurger: false,
};

export default function menuReducer(state = menuReducerInitialState, {type, payload}) {
    switch (type) {
        case SET_MENU_BURGER:
            return {
                ...state,
                menuBurger:   payload.menuBurger
            };
        default:
            return state;
    }
}