import React from "react";
import useStyles from "../styles";
import CheckIcon from '../images/bttn_radio.svg';

const ComplexFillings = ({element}) => {
    const cl = useStyles();
    const fillings = element.type === 'complex' && [...element.filling, ...element.idGrTest];

    return (element.type === 'complex' && fillings.length && <div className={cl.complexFillingsWrapper}>
        {fillings.map((filling, idx) => (<div className={cl.warningExpandedWrapper} key={filling.name + idx + filling.id + element.id}>
            <img src={CheckIcon} alt="Icon." className={cl.warningExpandedIcon}/>
            <p
                className={cl.warningExpandedTextGreen}
            >
                {filling.name && filling.name}
            </p>
        </div>))}
    </div>);
};

export default ComplexFillings;
