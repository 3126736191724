import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

export default function useFilter(initialState, compareFunc, stopRender) {
  const [filterValue, setFilterValue] = useState('');
  const [filteredState, setFilteredState] = useState(initialState);
  const {selectedTags}  = useSelector(state => state.assaysCatalog);
  const [initialTags, setInitialTags] = useState([]);
  const takeId = process.env.REACT_APP_TAKE_ID;

  const handleChangeFilterValue = (event) => {
    const newValue = event.target.value;

    setFilterValue(() => {
      if (newValue && newValue.trim().length === 0) {
        setFilteredState(initialState && initialState.filter(item => {
          if (selectedTags && selectedTags.length > 0) {
            return !!selectedTags.map(item => item.name).find(tag => item.tag && item.tag.includes(tag))
          } else {
            return true;
          }
        }));
      } else {
        setFilteredState(initialState && initialState.filter(item => compareFunc(item, newValue && newValue.trim().toLowerCase())).sort((a, b) => {
          if (newValue && a && a.name && a.name.trim().toLowerCase().indexOf(newValue.trim().toLowerCase()) === 0) {
            return -1;
          } else if (newValue && b && b.name && b.name.trim().toLowerCase().indexOf(newValue.trim().toLowerCase()) === 0) {
            return 1;
          } else {
            if ((a.type === 'complex' && b.type === 'complex')) {
              const aNameNum = a.name.replace(/[^0-9]/g, '');
              const bNameNum = b.name.replace(/[^0-9]/g, '');

              if (!!aNameNum && !!bNameNum) {
                return aNameNum.localeCompare(bNameNum, 'uk', {numeric: true});
              }
            }

            if (((((a.filling && a.filling.length === 1) && (a.opt && !a.opt.includes('complex'))) || (+a.id === 589 || +a.id === 590 || +a.id === 1454)) //assays and crunch for 3AC & 3AC
                    && (!b.filling || ((b.filling && b.filling.length !== 1) && (b.opt && b.opt.includes('complex')))))
                || ((((b.filling && b.filling.length === 1) && (b.opt && !b.opt.includes('complex'))) || (+b.id === 589 || +b.id === 590 || +b.id === 1454))
                    && (!a.filling || ((a.filling && a.filling.length !== 1) && (a.opt && a.opt.includes('complex'))))) && +takeId !== 216) {
              if ((((a.filling && a.filling.length === 1) && (a.opt && !a.opt.includes('complex'))) || (+a.id === 589 || +a.id === 590 || +a.id === 1454))) {
                return -1;
              } else {
                return 1;
              }
            }

            return a.name.localeCompare(b.name);
          }
        }).filter(item => {
          if (selectedTags && selectedTags.length > 0) {
            return selectedTags.map(item => item.name).find(tag => item.tag && item.tag.includes(tag))
          } else {
            return true;
          }
        }));
      }
      return newValue;
    });
  };

  useEffect(() => {
    if (!stopRender) {
      setFilteredState(initialState ? initialState : []);
    }
  }, [initialState, stopRender]);

  useEffect(() => {
    if (+takeId === 216 ? initialState : initialState && initialTags.length !== selectedTags.length) { //project ? medis : esculab
      setInitialTags(selectedTags);
      setFilteredState(initialState.filter(item => {
        if (selectedTags && selectedTags.length > 0) {
          return selectedTags.map(item => item.name).find(tag => item.tag && item.tag.includes(tag))
        } else {
          return true;
        }
      }));
      handleChangeFilterValue({target: {value: filterValue}});
    }
  }, [selectedTags, initialState])

  const backToInitialState = () => {
    setFilteredState(initialState);
    setFilterValue('');
  };

  return {
    filterValue,
    handleChangeFilterValue,
    backToInitialState,
    filteredState,
  };
}
