import React from "react";
import {SET_ACTIVE_TAB, SET_IS_ADMIN} from "../actions/actionsTypes";

export const cabinetInitialState = {
    activeTab: window.location.pathname.includes('/patient-card') ? '/patient-card' : window.location.pathname.replace('/cabinet', ''),
    isAdmin: false
};

export function cabinetReducer(state = cabinetInitialState, { type, payload }) {
    switch (type) {
        case SET_ACTIVE_TAB:
           return {
               ...state,
               activeTab: payload.path
           };
        case SET_IS_ADMIN:
            return {
                ...state,
                isAdmin: payload.isAdmin
            };
        default:
            return state;
    }
}
