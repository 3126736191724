import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ReactDOM from "react-dom";
import { postIdOrderPdv } from "@cabinet-packages/api";
import { CustomDialog } from "./Dialogs";
import TextAlertDialog from "./TextAlertDialog";
import { Loading } from "../Shared";

const useStyles = makeStyles({
  DialogContent: {
    display: "flex",
    justifyContent: "center",
    columnGap: "10px",
    alignItems: "center",
    fontSize: 16,
    color: "#666",
    padding: 20,
  },
});

const LoadOrderResultDialog = ({
  open,
  activePatient,
  idOrder,
  idClient,
  themeMaterialUI,
  isCheck,
  order,
}) => {
  const cl = useStyles();
  const modalRoot = document.getElementById("exception-root");
  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(modalRoot);
  };
  const handleOrderResult = async () => {
    const res = await postIdOrderPdv(idOrder, idClient);
    const url = window.URL.createObjectURL(
      new Blob([res], { type: "application/pdf" })
    );

    function iOS() {
      return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    if (open || iOS()) {
      const link = document.createElement("a");
      link.href = url;
      link.download = activePatient
        ? `${
            activePatient && activePatient.lastname && activePatient.lastname
          }_${
            activePatient && activePatient.firstname && activePatient.firstname
          }_${
            activePatient &&
            activePatient.fathername &&
            activePatient.fathername
          }.pdf`
          : `${order.fullname.split(' ').join('_')}.pdf`

      link.click();
      link.remove();
    } else {
      window.open(url);
    }
    handleClose();
  };

  useEffect(() => {
    handleOrderResult().catch((err) => {
      if (err instanceof Error) handleClose();
      CustomDialog(
        TextAlertDialog,
        {
          text: "Вибачте, виникла помилка при завантаженні PDF. Спробуйте повторити пізніше.",
        },
        false,
        themeMaterialUI
      );
    });
  });

  return (
    <Dialog open={true}>
      <DialogContent className={cl.DialogContent}>
        <Loading /> завантаження...
      </DialogContent>
    </Dialog>
  );
};

export default LoadOrderResultDialog;
