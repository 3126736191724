import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    appBar: {
        height: theme.spacing(8),
        backgroundColor: theme.palette.primary[50],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
        alignItems: 'center',
    },
    headingText: {
        fontSize: '32px',
        color: theme.palette.getContrastText(theme.palette.primary[50]),
    },
    orderDataText: {
        textAlign: 'left',
        color: 'white'
    },
    root: {
        maxWidth: '1180px',
        margin: '0 auto',
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    newPatientBirthdayContainer: {
        paddingRight: theme.spacing(1),
        width: '100%',
        display: 'grid',
    },
    orderVialsWithImagesWrapper: {
        maxWidth: '1180px',
        margin: '0 auto',
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    orderVialsCards: {
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: "wrap"
    },
    error: {
        color: 'red'
    },
}));

export default useStyles;
