import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 'max-content',
        background: 'white',
        borderRadius: '10px',
    },
    paper: {
        background: 'transparent',
        borderRadius: '10px',

        '@media (min-width: 1180px)': {
            width: '580px'
        }
    },
    backdrop: {
        background: "rgba(255,255,255, 0.5)",
        backdropFilter: 'blur(1px)'
    },
    heading: {
        background: theme.palette.primary.dark,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(7.5),
        paddingLeft: theme.spacing(7.5),
        borderRadius: '10px 10px 0px 0px',
        alignItems: 'center',
        position:"sticky",
        top: 0,

        '@media (max-width: 1180px)': {
            padding: theme.spacing(2),
        }
    },
    headingText: {
        fontSize: '16px',
        fontWeight: 600,
        textTransform: 'uppercase',
        lineHeight: '21px',
        color: 'white',
        textAlign: 'center'
    },
    iconButton: {
        position: "absolute",
        top: 10,
        right: 10,
        color: 'white'
    },
    innerWrapper: {
        width: '100%',
        maxWidth: '580px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: theme.spacing(5),
    },
    iconAlert: {
        width: '92px',
        marginBottom: theme.spacing(2.5),

        '@media (max-width: 1180px)': {
            width: '60px',
        },
    },
    textAlert: {
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.grey.price,
        textAlign: 'center',
        width: '340px',
        margin: '0 auto',
        marginBottom: theme.spacing(2),

        '@media (max-width: 1180px)': {
            width: '170px',
            fontSize: '14px',
        },
    },
    autocomplete: {
        width: '280px',
        marginBottom: theme.spacing(3),

        '@media (max-width: 1180px)': {
            width: '170px',
        },
    },
    rootAutocomplete: {
        border: '2px solid #F0F0F0',
        borderRadius: '18px',
        height: '38px',
        transition: 'all .4s',

        '&&& $input': {
            transition: 'all .4s',
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: '61px',

            '@media (max-width: 1180px)': {
                paddingRight: '0px !important',
            }
        },
    },
    notchedOutline: {
        border: "none",
    },
    snackbar: {
        padding: 0,
        background: 'transparent',
        borderRadius: '10px',

        '@media (max-width: 1180px)': {
            width: '200px',
            boxShadow: 'none',
        }
    },
    snackbarContentWrapper: {
        width: '340px',
        height: 'max-content',
        minHeight: '170px',
        background: 'white',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        boxShadow: '2px 0px 10px 0px #70707088',

        '@media (max-width: 1180px)': {
            width: '200px',
        }
    },
    snackbarContentHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        height: '50px',
        width: '100%',
        fontSize: '20px',
        fontWeight: 600,
        color: 'white',
        background: theme.palette.primary.dark,
        borderRadius: '10px 10px 0px 0px',

        '@media (max-width: 1180px)': {
            fontSize: '14px',
        }
    },
    snackbarContentText: {
        width: '100%',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        marginTop: theme.spacing(2.5),
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.grey.price,

        '@media (max-width: 1180px)': {
            fontSize: '12px',
        }
    },
}));

export default useStyles;
