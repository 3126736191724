import {makeStyles} from "@material-ui/core";
import UnlockImg from "./img/icn_unlock.png";
import SyringeImg from "./img/icn_syringe.png";
import LockImg from "./img/icn_lock.png";
import UnlockLightImg from "./img/icn_lock_light.png";
import LockLightImg from "./img/icn_lock_light.png";
import SyringeLightImg from "./img/icn_syringe_light.png";
import radioDefImg from "./img/bttn_radio_def.png";
import radioHoverImg from "./img/bttn_radio_hover.png";

const useStyles = makeStyles((theme) => ({
    containerWrapper: {
        margin: '0px auto',
        maxWidth: 1180,
        paddingTop: '2.375rem',
        paddingBottom: '30px',
        width: '100%',

        '@media (min-width: 900px) and (max-width: 1200px)': {
            paddingLeft: 15,
            paddingRight: 15
        },

        '@media (max-width:900px)': {
            paddingBottom: '0px',
            paddingTop: '8px',
        },
    },
    containerWrapperFrame: {
        margin: '0px auto',
        paddingTop: '2.375rem',
        width: '100vw',
        backgroundImage: `white`,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',

        '@media (min-width: 900px) and (max-width: 1200px)': {
            paddingLeft: 15,
            paddingRight: 15
        },

        '@media (max-width:900px)': {
            paddingBottom: '0px',
            paddingTop: '8px',
        },
    },
    root: {
        display: 'grid',
        gridTemplateColumns: '285px 1fr',
        gridColumnGap: 20,
        '@media (min-width: 900px) and (max-width: 1100px)': {
            gridColumnGap: 15,
        },
        '@media (max-width:900px)': {
            gridTemplateColumns: '1fr',
        },
    },
    rootFrame: {
        display: 'grid',
        maxWidth: 1180,
        width: '100%',
        gridTemplateColumns: '24.1% 1fr',
        gridColumnGap: 20,
        '@media (min-width: 900px) and (max-width: 1100px)': {
            gridColumnGap: 15,
        },
        '@media (max-width:900px)': {
            gridTemplateColumns: '1fr',
        },
    },
    filterRootContainer: {
        '@media (max-width:900px)': {
            padding: '0px 17px',
            display: 'grid',
            gridTemplateColumns: '1fr 95px',
            gridColumnGap: 15,
        },
    },
    rootPoints: {
        width: '100%',
        // minHeight: '100%',
    },
    searchButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        // borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    },
    errorMessage: {
        textAlign: 'center',
    },
    noConnection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        width: '100%',
    },
    autoCompleteCity: {
        display: 'none',
        boxSizing: 'content-box',
        width: '100%',
        minWidth: '280px',
        padding: '0px !important',
        borderRadius: 18,
        '& div': {
            borderRadius: 18
        },
    },
    autoComplete: {
        boxSizing: 'content-box',
        width: '100%',
        minWidth: '460px',
        padding: '0px !important',
        backgroundColor: 'white',
        borderRadius: 18,
        '& div': {
            borderRadius: 18
        },
        '@media (max-width:900px)': {
            minWidth: '100%',
        },
    },
    autoCompleteRegion: {
        boxSizing: 'content-box',
        width: '100%',
        padding: '0px !important',
        backgroundColor: 'white',
        borderRadius: 18,
        '& div': {
            borderRadius: 18
        },
    },
    inputWrapper: {
        '@media (min-width: 900px)': {
            maxWidth: 300,
            display: 'none',
        },
        "& label": {
            top: -10,
            fontSize: 14,
            color: theme.palette.colors.text,
        },
        '& fieldset': {
            border: '2px solid #F0F0F0 ',
        },
        '& p': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    autoCompleteInput: {
        paddingTop: '8.5px !important',
        paddingBottom: '8.5px !important',
        paddingLeft: '25px !important',
        color: theme.palette.colors.text,
        fontSize: 14,
    },
    noPunkts: {
        textAlign: 'center',
    },
    actionButtonsContainer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '8px',

        '@media (max-width: 900px)': {
            flexDirection: 'column',
        },
    },
    mainPageButton: {
        '@media (max-width: 900px)': {
            marginTop: '8px',
        },
    },
    mapWrapper: {
        height: 446,
        borderRadius: '10px 10px 0px 0px',
        overflow: 'hidden',
        // '& .gmnoprint': {
        //     display: 'none',
        // },
        '& .gm-style-mtc': {
            display: 'none',
        },
        '@media (max-width: 900px)': {
            height: 350,
        },
    },
    imgSliderActive: {
        color: theme.palette.colors.text,
        background: theme.palette.colors.secondary,
        width: 26,
        height: 26,
        borderRadius: '50%',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 2,
        marginLeft: 2,
        '& span': {
            color: '#fff',
            paddingRight: 1,
        },
    },
    imgSliderMain: {
        color: '#ffffffff',
        background: theme.palette.colors.main,
        cursor: 'pointer',
        width: 26,
        height: 26,
        borderRadius: '50%',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 2,
        '& span': {
            color: '#fff',
            paddingRight: 1,
        },
    },
    imgSliderDefault: {
        color: theme.palette.colors.text,
        border: '2px solid #F0F0F0',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 26,
        height: 26,
        background: '#ffffff',
        borderRadius: '50%',
        marginRight: 2,
        marginLeft: 0,
        '& span': {
            color: theme.palette.colors.text,
            paddingRight: 1,
        },
    },
    stepperActive: {},
    stepperMain: {},
    stepperDefault: {},
    markerWrapper: {
        background: "transparent",
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    popper: {
        background: 'white',
        borderRadius: '10px',
        boxShadow: '0px 2px 10px 0px rgba(0,0,0,0.75)',
        padding: theme.spacing(2),
        zIndex: '2',
        width: 280,
    },
    markerImage: {
        cursor: "pointer",
        height: '59px',
        width: '34px'
    },
    popperAddress: {
        color: theme.palette.primary.dark,
        fontSize: '16px',
        fontWeight: 600
    },
    tableHead: {
        paddingTop: theme.spacing(1),
    },
    mainCell: {
        color: theme.palette.secondary.main,
        borderBottom: 'none',
        paddingBottom: 3,
        fontFamily: "'OpenSans-Regular'",
        fontWeight: 600,
    },
    headCell: {
        textAlign: 'center',
        borderBottom: 'none'
    },
    headCellUnlockImg: {
        width: 10,
        height: 12,
        background: `url(${UnlockImg})`,
        display: 'block',
        margin: 'auto',
    },
    headCellSyringeImg: {
        width: 10,
        height: 12,
        background: `url(${SyringeImg})`,
        display: 'block',
        margin: 'auto',
    },
    headCellLockImgImg: {
        width: 10,
        height: 12,
        background: `url(${LockImg})`,
        display: 'block',
        margin: 'auto',
    },
    table: {},
    cell: {
        borderBottom: 'none',
        fontWeight: 600
    },
    pointsSelectWrapper: {
        width: 280,
        height: 36,
        border: '2px solid #F0F0F0',
        fontFamily: "'OpenSans-Regular'",
        borderRadius: 18,
        fontSize: 14,
        color: theme.palette.colors.text,
        justifyContent: 'flex-start',
        paddingLeft: 27,
        '&:hover': {
            backgroundColor: '#ffffff',
        },
    },
    imgSelectPoint: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 25,
    },
    punkWrapperItem: {
        borderRadius: 10,
        padding: '15px 20px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        '&:hover': {
            boxShadow: '0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)',
        },
    },
    punkWrapperItemSelected: {
        borderRadius: 10,
        padding: '15px 20px',
        background: '#00B3A1',
        cursor: 'pointer',
        '& $punkWrapperTitle': {
            color: '#ffffff',
        },
        '& $cell': {
            color: '#ffffff',
        },
        '& $mainCell': {
            color: 'rgba(255,255,255,0.6)',
        },
        '& $headCellUnlockImg': {
            background: `url(${UnlockLightImg})`,
        },
        '& $headCellSyringeImg': {
            background: `url(${SyringeLightImg})`,
        },
        '& $headCellLockImgImg': {
            background: `url(${LockLightImg})`,
        },
    },
    punkWrapper: {
        display: 'none',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridColumnGap: 20,
        marginTop: 20,
    },
    punkWrapperTitle: {
        color: theme.palette.colors.text,
        fontSize: '0.95rem',
        marginBottom: 9,
    },
    punkWrapperItemContent: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        '& img': {
            display: 'block',
            height: 12,
        }
    },
    wrapperSearch: {
        display: 'flex',
        marginBottom: '26px',
        '@media (max-width:900px)': {
            padding: '0px 17px',
            marginBottom: '15px',
        },
    },
    wrapperStreetDesktop: {
        marginRight: 'auto',
        '@media (max-width:900px)': {
            display: 'none',
        },
    },
    wrapperSwitch: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: 20,
        '& div': {
            width: 36,
            height: 36,
            borderRadius: 7,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        '@media (max-width:900px)': {
            display: 'none',
        },
    },
    wrapperSwitchMobile: {

        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: 20,
        '& div': {
            width: 36,
            height: 36,
            borderRadius: 7,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        '@media (min-width:900px)': {
            display: 'none',
        },
    },

    collectionPointsWrapperMap: {
        height: 415,
        position: 'relative',
        overflow: 'auto',
        scrollbarWidth: 'none',
        '@media (max-width:900px)': {
            height: 'calc(100vh - 238px)',
            maxWidth: '100vw',
        },
        '&::-webkit-scrollbar': {
            width: 5,
        },

        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px #363D4D14',
            borderRadius: 10,
        },

        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.colors.main,
            borderRadius: 10,
        },
    },
    filterText: {
        color: theme.palette.colors.text,
        fontSize: '1.25rem',
    },
    accordionWrapperHeader: {
        paddingLeft: 15,
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '416px 38px 38px 38px 38px',
        // gridTemplateColumns: '51.13% 38px 38px 38px 38px',
        '@media (min-width: 900px) and (max-width: 1200px)': {
            gridTemplateColumns: '300px 38px 38px 38px 38px',
        },

        gridColumnGap: '1.5rem',
        '& p': {
            display: 'block',
            textAlign: 'center',
            marginBottom: 11,
        },
        '@media (max-width: 900px)': {
            display: 'none',
        }
    },
    accordionRoot: {
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        marginLeft: 2,
        // marginTop: 2,
        padding: '15px 20px 5px 20px',
        borderRadius: '10px 0px 0px 0px',
        background: '#ffffff',
    },
    filterWrapper: {
        height: '100%',
    },
    accordionWrapper: {
        marginTop: 42,
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        background: '#FFFFFF',
        padding: 25,
        borderRadius: 10,
        '@media (max-width: 900px)': {
            display: 'none',
        }
    },
    accordionWrapperTitle: {
        color: 'rgba(15,15,15,0.5)',
        fontSize: '0.75rem',
    },
    accordionItemWrapper: {
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
    },
    accordionItem: {
        padding: 0,
    },
    accordionItemTitle: {
        fontWeight: 500,
        color: '#363D4D',
    },
    accordionDetailsWrapper: {
        paddingLeft: 0,
        paddingRight: 0,
        display: 'block',
        '& div:last-child': {
            marginBottom: '0rem',
        },
    },
    checkboxWrapper: {
        gridTemplateColumns: '28px 1fr',
        display: 'grid',
        marginBottom: '1.438rem',
    },
    checkboxItem: {
        padding: 0,
        width: 20,
        height: 20,
        color: '#18243E',
        '& svg': {
            width: 20,
            height: 20,
        },
    },
    popperAddressWrapper: {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
        justifyContent: 'space-between',
        maxWidth: 231,
        marginBottom: 9,
    },
    popperMarkerCloseButton: {
        position: 'relative',
        padding: 7,
        left: 7,
    },
    SwipeStyledButton: {
        marginTop: 15,
    },
    inputWrapperRegion: {
        width: '100%',
        "& label": {
            top: -10,
            fontSize: 14,
            color: theme.palette.colors.text,
        },
        '& fieldset': {
            border: '2px solid #F0F0F0 ',
        },
        '& p': {
            // width: 280,
            display: 'flex',
            alignItems: 'center',
        },
    },
    autoCompleteRegionImgSelected: {
        background: `url(${radioDefImg})`,
        height: 15,
        width: 15,
        display: 'block',
    },
    autoCompleteRegionOpthion: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:hover': {
            '& $autoCompleteRegionImgSelected': {
                background: `url(${radioHoverImg})`,
            },
        }
    },
    inputWrapperRegionRoot: {
        minWidth: '285px',
        marginRight: '8px',
        '@media (max-width:900px)': {
            display: 'none',
        },
    },
    mobileCityAvtoComplete: {
        '&:focus-within': {
            width: '200%',
            position: 'relative',
            zIndex: 10,
        },
        '@media (min-width:900px)': {
            display: 'none',
        },
    },
    // mobileCityAvtoCompleteActive: {
    //         width: '200%',
    //         position: 'relative',
    //         zIndex: 10,
    //     '@media (min-width:900px)': {
    //         display: 'none',
    //     },
    // },


    wrapperMobileFilter: {
        display: 'grid',
        gridTemplateColumns: '1fr 1.7fr',
        gridColumnGap: 10,
        padding: '0px 17px 15px',
        '@media (max-width:350px)': {
            gridTemplateColumns: '1fr 1.5fr',
        },
    },
    mobileFilters: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    filterPopupMobile: {
        width: '100%',
        margin: '0px 8px',

        '@media (min-width:900px)': {
            display: 'none',
        },
    },
    selectFilterPopup: {
        width: '100%',
        // maxWidth: 326,
        background: '#ffffff',
        display: 'block',
        marginBottom: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 45,
        border: '2px solid #F0F0F0',
        borderRadius: 10,
        color: theme.palette.colors.dark,
        fontSize: '0.75rem',
        '@media (max-width:900px)': {
            marginBottom: 0,
        },
    },

    imgFilter: {
        position: 'relative',
        top: 2,
        right: 13,
    },
    selectFilterWrapper: {
        width: '100%',
        minHeight: 339,
        padding: '15px 25px 30px 25px',
        // minHeight: 365,
        // top: '200px !important',
        top: '188px !important',
        '& ul': {
            padding: 0,
        },
        '& $accordionDetailsWrapper': {
            padding: 0,
            marginTop: 27,
            // background: 'red',
        },
        '& $checkboxWrapper': {
            marginBottom: '1.688rem',
        },
    },
    accordionItemRoot: {
        minHeight: 'fit-content',
        height: 19,
    },
    accordionItemContent: {
        margin: '0px !important',
    },
    accordionItemExpanded: {
        margin: '0px !important',
        minHeight: 'fit-content !important',
        height: '19px !important',
    },
    mobileSelectPunkt: {
        marginTop: 17,
        color: theme.palette.colors.text,
        '@media (min-width:900px)': {
            display: 'none'
        },
    },
    endAdornmentInput: {
        height: 33,
        display: 'flex',
        alignItems: 'center',
    },
    showAllPunkts: {
        width: 'max-content',
        height: '100%'
    },
}));

export default useStyles;
