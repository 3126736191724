import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import {amber, teal} from "@material-ui/core/colors";
import {SwipeStyledButton} from "../index";

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.primary.dark,
        color: 'white',
        borderRadius: '10px 10px 0px 0px',
        textAlign: 'center'
    },
    rootDialog: {
        borderRadius: '10px',
        maxWidth: '400px',
    },
    rootDialogAction: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
}));

function TextAlertDialogMedis({
                             onConfirm = () => null,
                             handleClose,
                             text = 'Ви впевнені, що хочете це зробити?',
                         }) {
    const cl = useStyles();
    const handleConfirm = () => {
        onConfirm();
        handleClose();
    };
    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
            classes={{paper: cl.rootDialog}}
        >
            <DialogTitle
                classes={{root: cl.root}}
            >
                Попередження
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions classes={{root: cl.rootDialogAction}}>
                <SwipeStyledButton
                    animationStart={'left'}
                    animationMaterialColor={teal[500]}
                    buttonMaterialColor={amber[500]}
                    onClick={handleConfirm}
                >
                    Зрозуміло
                </SwipeStyledButton>
            </DialogActions>
        </Dialog>
    );
}

export default TextAlertDialogMedis;
