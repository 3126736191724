import React from 'react';
import {Button, withStyles} from "@material-ui/core";

const SwipeStyledButton = ({border, animationMaterialColor, animationStart, buttonMaterialColor, variant, hoverTextColor, color, width, height, ...props}) => {
    const isMedis = process.env.REACT_APP_CURRENT_CLINIC === "MEDIS"
    const outlined = variant === 'outlined';

    const ColorButton = withStyles((theme) => ({
        root: {
            fontSize:'1rem',
            fontWeight: 'bold',
            borderRadius: '25px',
            transition: 'all 0.3s',
            border: outlined ? `1px solid ${buttonMaterialColor}`: border ? border : isMedis ? "0px solid #0E4E4E" : "2px solid #0077C8",
            // color: outlined ? buttonMaterialColor : theme.palette.colors.text,
            color: outlined ? buttonMaterialColor : color ? color : isMedis ? '#0E4E4E' : '#0077C8',
            backgroundColor: !outlined && buttonMaterialColor,
            boxSizing: 'border-box',
            height: height || '45px',
            minWidth: width || '220px',

            '@media (max-width: 980px)': {
                minWidth: '128px',
                transition: 'all 0.3s',
                height: '35px',
                fontSize: '14px',
                border: border !== false ? border : outlined && `2px solid ${buttonMaterialColor}`,
            },

            '&:hover': {
                borderColor: outlined && animationMaterialColor,
                transition: outlined ? 'all 0.6s' : 'all 0.3s, color 0.1s',
                color: hoverTextColor ? hoverTextColor : animationMaterialColor ? theme.palette.getContrastText(animationMaterialColor) : theme.palette.primary.activeText,
                background: buttonMaterialColor ? !outlined && buttonMaterialColor : theme.palette.primary.hoverBackground
            },

            '&:before': {
                content: '""',
                position: 'absolute',
                zIndex: '0',
                transition: 'all 0.3s',
                width: '40px',
                height: outlined || border ? 'calc(100% + 2px)' : '100%',
                top: outlined || border ? -1 : 0,
                [animationStart]: outlined || border ? -1 : 0,
                borderRadius: '25px',
                background: 'transparent'
            },

            '&:hover:before': {
                width: outlined || border ? 'calc(100% + 2px)' : '100%',
                borderRadius: '25px',
                background: animationMaterialColor,
                transition: 'all 0.3s',
            },
            '&:hover span': {
                transition: 'all 0.3s',
                zIndex: '1',
                position: 'relative',
            },
            '& span:last-child': {
                display: 'none',
                transition: 'all 0.3s',
            }
        },
        label: {
            transition: 'all 0.3s',
        },

    }))(Button);

    return (<ColorButton  {...props}  />);
};

export default SwipeStyledButton;
