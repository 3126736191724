import {createAction, createReducer} from "@reduxjs/toolkit";
import React from "react";

export const SET_STEPS = createAction('WIZARD: set stepper');
export const SET_STEP_COMPLETE = createAction('WIZARD: set step is complete');
export const SET_STEP_NOT_COMPLETE = createAction('WIZARD: set step is not complete');
export const SET_ACTIVE_STEP = createAction('WIZARD: set active step');
export const SET_NEXT_STEP = createAction('WIZARD: set next step');
export const SET_PREVIOUS_STEP = createAction('WIZARD: set previous step');

const initialState = {
    steps: null,//initialSteps,
    activeStep: 0
};

export const wizardStepperReducer = createReducer(initialState, {
    [SET_STEPS]: (state, action) => {
        state.steps = action.payload ? action.payload : initialState.steps;
    },
    [SET_STEP_COMPLETE]: (state, action) => {
        state.steps = action.payload ? action.payload : initialState.steps;
    },
    [SET_STEP_NOT_COMPLETE]: (state, action) => {
        state.steps = action.payload ? action.payload : initialState.steps;
    },
    [SET_ACTIVE_STEP]: (state, action) => {
        state.activeStep = action.payload ? action.payload : initialState.activeStep;
    },
    [SET_NEXT_STEP]: (state, action) => {
        state.activeStep = action.payload ? action.payload : initialState.activeStep;
    },
    [SET_PREVIOUS_STEP]: (state, action) => {
        state.activeStep = action.payload ? action.payload : initialState.activeStep;
    },
  }
);
