import React, {useEffect, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, makeStyles,} from "@material-ui/core";
import iconArrow from '../img/icon-ios-arrow-down.png'
import iconArrowWhite from '../img/arrow-down-white.png'
import IconButton from "@material-ui/core/IconButton";
import info from '../img/icn_info.png'
import infoWhite from '../img/icn_info_white.png'
import {getWorkingHours} from "@cabinet-packages/helpers";
import {getClientLocation} from "@cabinet-packages/redux";
import {useDispatch} from "react-redux";
import UnlockImg from "../img/icn_unlock.png";
import SyringeImg from "../img/icn_syringe.png";
import LockImg from "../img/icn_lock.png";


const useStyles = makeStyles((theme) => ({
    accordionItemWrapper: {
        borderRadius: 10,
        cursor: 'pointer',
        // background: theme.palette.grey.accordion,
        background: '#F8F9F9',
        marginBottom: 10,
        boxShadow: 'none',
        '&::before': {
            display: 'none',
        }
    },
    accordionItemWrapperActive: {
        borderRadius: 10,
        cursor: 'pointer',
        background: theme.palette.colors.main,
        marginBottom: 10,
        boxShadow: 'none',
        '& $accordionItemTitle': {
            color: '#ffffff',
        },
        '& $accordionExpandedText': {
            color: '#ffffff',
            opacity: '60%',
        },
        '& $mainCell': {
            color: '#ffffff',
            opacity: '60%',
        },
        '& $cell': {
            color: '#ffffff',
        },
    },
    accordionItemTitle: {
        color: theme.palette.colors.text,
        fontWeight: 'bold',
        fontSize: '0.95rem',
    },
    accordionExpandedText: {
        fontSize: 14,
    },
    wrapperMobileText: {
        '@media (max-width: 900px)': {
            marginTop: -1,
            marginBottom: 4,
            '& $imgWrapper': {
              display: 'grid !important',
                gridTemplateColumns: '27px 1fr',
                '& $bodyHeaderImg': {
                   marginLeft: 0,
                   position: 'static',
                },
            },
        },
    },
    accordionItemDropImg: {
        width: 40,
        height: 40,
        // marginLeft: 'Calc(5rem + 1px)',
    },
    accordionItem: {
        height: 43,
        minHeight: '43px !important',
        paddingRight: '2.5rem',
        fontFamily: "'OpenSans-Regular'",
        '@media (max-width: 900px)': {
            paddingRight: '10px',
            '& $mainCell': {
                display: 'none',
            },
            '& $cell': {
                display: 'none',
            },
            '& $accordionWrapperHeader': {
                gridTemplateColumns: '1fr 38px',
            },
        }
    },
    mainCell: {
        fontWeight: 600,
        color: theme.palette.secondary.main,
        fontSize: 10,
        fontFamily: "'OpenSans-Regular'",
    },
    cell: {
        color: theme.palette.colors.text,
        fontSize: 14,
        fontWeight: 600,
        fontFamily: "'OpenSans-Regular'",
    },
    // accordionWrapperHeader
    accordionWrapperHeader: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '416px 38px 38px 38px 38px',
        gridColumnGap: '1.5rem',
        '@media (min-width: 900px) and (max-width: 1200px)': {
            gridTemplateColumns: '300px 38px 38px 38px 38px',
        },
        '& p': {
            display: 'block',
        },

    },
    bodyHeaderImg: {
        position: 'relative',
        top: 3,
        marginRight: 7,
        marginLeft: 14,
        display: 'block',
        height: 15,
        width: 15,
    },
    imgWrapper: {
        display: 'flex',
        maxWidth: 490,
        '@media (max-width: 900px)': {
            display: 'none !important',
        },
    },
    accordionDetailsWrapper: {
        display: 'block',
        maxWidth: 770,
        // '@media (min-width: 900px) and (max-width: 1100px)': {
        //     gridTemplateColumns: '46.13% 38px 38px 38px 38px',
        // },
        '@media (max-width: 900px)': {
            paddingTop: 0,
            '& $accordionWrapperHeader': {
                marginTop: '5px !important',
                gridTemplateColumns: '38px 38px 38px 38px 38px',
            },
        },
    },
    cellInfo: {
        marginTop: 10,
    },
    displayMobile: {
        '@media (max-width: 900px)': {
            display: 'none !important',
        },
    },
    wrapperMobile: {
        '@media (min-width: 900px)': {
            display: 'none',
        },
    },
}));

const AccordionPoints = ({punkt, selectPunkt, selectedItem, setSelectedItem}) => {
    const cl = useStyles()
    const [expanded, setExpanded] = useState(false);
    const dispatch = useDispatch();

    const punktWork = punkt && punkt.hWork && punkt.zWork && getWorkingHours(punkt.hWork, punkt.zWork);

    const selectedAccordion = () => {
        if (punkt.id === selectedItem) {
            setExpanded(false);
            setSelectedItem(false)
            dispatch(getClientLocation(null, true))
        } else {
            setSelectedItem(punkt.id);
            dispatch(getClientLocation(punkt));
            setExpanded(true);
        }
    }

    useEffect(() => {
        if (punkt.id !== selectedItem) {
            setExpanded(false);
        }
    }, [selectedItem]);

    return (
        <Accordion className={selectedItem === punkt.id ? cl.accordionItemWrapperActive : cl.accordionItemWrapper}
                   expanded={expanded} onClick={selectedAccordion}>
            <AccordionSummary
                className={cl.accordionItem}
                expandIcon={<IconButton className={cl.accordionItemDropImg}>{selectedItem === punkt.id ?
                    <img src={iconArrowWhite} alt="icon Arrow White"/> :
                    <img src={iconArrow} alt="icon Arrow"/>}</IconButton>}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <div className={cl.accordionWrapperHeader}>
                    <p className={cl.accordionItemTitle}>{punkt?.address}</p>
                    <p className={cl.mainCell}>Пн-Пт:</p>
                    <p className={cl.cell}> {punktWork && punktWork.weekdaysH[0]}</p>
                    <p className={cl.cell}>{punktWork && punktWork.weekdaysZ[1]}</p>
                    <p className={cl.cell}>{punktWork && punktWork.weekdaysH[1]}</p>
                </div>
            </AccordionSummary>

            <AccordionDetails className={cl.accordionDetailsWrapper}>



                <span className={cl.wrapperMobile}>

                  <div className={cl.wrapperMobileText}>
                      <div className={cl.imgWrapper}>{punkt.note && selectedItem === punkt.id ?
                          <img className={cl.bodyHeaderImg} src={infoWhite} alt="infoWhite"/> : punkt.note &&
                          <img className={cl.bodyHeaderImg} src={info} alt="info"/>}
                          <p className={cl.accordionExpandedText}>{punkt.note && punkt.note}</p>
                    </div>
                  </div>

                 <div className={cl.accordionWrapperHeader}>
                                                    <p className={cl.accordionItemTitle}></p>
                                                    <p className={cl.cell}><img src={UnlockImg} alt="Unlock Img"/>
                                                    </p>
                                                    <p className={cl.cell}><img src={SyringeImg}
                                                                                     alt="Syringe Img"/></p>
                                                    <p className={cl.cell}><img src={LockImg} alt="Lock Img"/></p>
                                                </div>

                <div className={cl.accordionWrapperHeader}>
                    <div className={cl.imgWrapper}>{punkt.note && selectedItem === punkt.id ?
                        <img className={cl.bodyHeaderImg} src={infoWhite} alt="infoWhite"/> : punkt.note &&
                        <img className={cl.bodyHeaderImg} src={info} alt="info"/>}
                        <p className={cl.accordionExpandedText}>{punkt.note && punkt.note}</p>
                    </div>
                    <p className={cl.mainCell}>Пн-Пт:</p>
                    <p className={cl.cell}> {punktWork && punktWork.weekdaysH[0]}</p>
                    <p className={cl.cell}>{punktWork && punktWork.weekdaysZ[1]}</p>
                    <p className={cl.cell}>{punktWork && punktWork.weekdaysH[1]}</p>
                </div>
                </span>


                <div className={cl.accordionWrapperHeader}>
                    <div className={cl.imgWrapper}>{punkt.note && selectedItem === punkt.id ?
                        <img className={cl.bodyHeaderImg} src={infoWhite} alt="infoWhite"/> : punkt.note &&
                        <img className={cl.bodyHeaderImg} src={info} alt="info"/>}
                        <p className={cl.accordionExpandedText}>{punkt.note && punkt.note}</p>
                    </div>
                    <p className={cl.mainCell}>Сб:</p>
                    <p className={cl.cell}>{punktWork && punktWork.saturdayH[0]}</p>
                    <p className={cl.cell}>{punktWork && punktWork.saturdayZ[1]}</p>
                    <p className={cl.cell}>{punktWork && punktWork.saturdayH[1]}</p>
                </div>
                <div className={cl.accordionWrapperHeader} style={{marginTop: 15}}>
                    <p className={cl.displayMobile}></p>
                    <p className={cl.mainCell}>Нд:</p>
                    <p className={cl.cell}>{punktWork && punktWork.sundayH[0]}</p>
                    <p className={cl.cell}>{punktWork && punktWork.sundayZ[1]}</p>
                    <p className={cl.cell}>{punktWork && punktWork && punktWork.sundayH[1]}</p>
                </div>

            </AccordionDetails>

        </Accordion>

    )
}

export default AccordionPoints;