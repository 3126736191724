import React from "react";
import useStyles from "../../../styles";
import getAssayIcons from "../../../images/getAssayIcons";
import {Typography, useMediaQuery} from "@material-ui/core";

const TabLabel = ({idx = 0, isSelected, txt }) => {
    const cl = useStyles();
    const Image = getAssayIcons[idx];
    const isDesktop = useMediaQuery('(min-width: 1180px)');

    const SuperGroupName = () => {
        const indexOfComplex = txt.indexOf('(');
        const indexOfDot = txt.indexOf(')');

        if (indexOfComplex < 0 || indexOfDot < 0) {
            return txt;
        }

        return (<>{txt.slice(0, indexOfComplex)}<span className={cl.superGroupNameAccent}>
                {txt.slice(indexOfComplex, indexOfDot+1)}
            </span>{txt.slice(indexOfDot+1)}</>);
    }

    return (<div className={cl.tabsLabelWrapper}>
        <Image className={cl.tabImage}/>
        <Typography
            component="p"
            variant="body2"
            className={isSelected ? cl.tabLabelNameSelected : cl.tabLabelName}
            color={isSelected ? 'primary' : "initial"}
        >
            {isDesktop
                ? SuperGroupName()
                : txt.length > 13 ? txt.slice(0, 14)+'...' : SuperGroupName()}
        </Typography>
    </div>);
};

export default TabLabel;
