import React, {useState} from "react";
import useStyles from "../styles";
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Pdf from "../pdf/Договір публічної оферти_МеДіС.pdf";
import {useDispatch, useSelector} from "react-redux";
import uaLocale from 'date-fns/locale/uk';
import DateFnsUtils from "@date-io/date-fns";
import {setClient, setListOfPatients, START_PHONE_SEND} from "@cabinet-packages/redux";
import {editPatientInfo, getPatients, setExistingPatients} from "@cabinet-packages/api/api";
import {Loading, SwipeStyledButton} from "../../../components";
import {useHistory} from "react-router";
import {AccessKey} from "@cabinet-packages/helpers";

const EighthStage = ({isDoctor, handleValueActivePatient, handleValueActivePatientFormControl, handleCheckboxState,
                         isChangedStateSignUp, phone, uuid}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const history = useHistory()
    const {listOfPatients} = useSelector(state => state.patients); //here
    const {takeId} = useSelector(state => state.assaysCatalog);
    const [valueActivePatient, setValueActivePatient] = handleValueActivePatient;
    const [valueActivePatientFormControl, setValueActivePatientFormControl] = handleValueActivePatientFormControl;
    const [checkboxState, setCheckboxState] = handleCheckboxState;
    const [isLoading, setIsLoading] = useState(false)

    const handleChangeActivePatient = (event) => {
        setValueActivePatientFormControl(+event.target.value);
        const foundPatient = listOfPatients.find(patient => +patient.id === +event.target.value);
        setValueActivePatient({...foundPatient, birthday: new Date(foundPatient.birthday)});
    };

    const updateStateActivePatient = (field, value) => {
        setValueActivePatient(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleChange = (event) => {
        setCheckboxState({ ...checkboxState, [event.target.name]: event.target.checked });
    };

    const handleSignIn = async () => {
        setIsLoading(true)
            // await checkPatient('CHECK', uuid, null, phone, AccessKey.get());
        const data = await setExistingPatients(
                valueActivePatient.id,
                listOfPatients.map(patient => patient.id).filter(id => +id !== +valueActivePatient.id),
                localStorage.getItem("unregisteredCabinet") ? localStorage.getItem("activePatientPhone") : phone.replace(/[[()\s]/g, ''),
                checkboxState.checkedA,
                checkboxState.checkedB,
                false,'');

        if  (data) {
            AccessKey.set(data?.token)
        }
        localStorage.removeItem("unregisteredCabinet")

        const birthday = typeof valueActivePatient.birthday === 'string'
            ? valueActivePatient.birthday
            : !isDoctor && new Date(valueActivePatient.birthday.toString()).toLocaleDateString().split('.').reverse().join('-');

        // await getPatients();

        // await editPatientInfo(
        //         valueActivePatient.lastname,
        //         valueActivePatient.firstname,
        //         valueActivePatient.fathername,
        //         birthday,
        //         valueActivePatient.sex,
        //         valueActivePatient.id,
        //         valueActivePatient.email
        //     );

        const updatePatients = await getPatients();
        dispatch(setListOfPatients(false, isDoctor, updatePatients));

        dispatch(setClient({...valueActivePatient, birthday}));

        dispatch({
            type: START_PHONE_SEND,
            payload: {
                stage: 6,
            },
        });

        localStorage.removeItem("unregisteredCabinet")
        history.push('/cabinet/patient-results')

        setIsLoading(false)
    };

    return (listOfPatients ? <div className={cl.createClientWrapper}>
            <div className={cl.selectActivePatientWrapper}>
                <div className={cl.selectActivePatient}>
                    {<FormControl component="div" className={cl.textField}>
                        <FormLabel component="legend" className={cl.selectActivePatientHeader}>
                            {isDoctor
                                ? 'Вибір даних лікаря'
                                : 'Вибір головного пацієнта особистого кабінету:'}
                        </FormLabel>
                        <RadioGroup
                            aria-label="activePatient"
                            name="activePatient1"
                            onChange={handleChangeActivePatient}
                            value={valueActivePatientFormControl}
                        >
                            {listOfPatients.map(patient => (
                                <FormControlLabel
                                    key={patient.id}
                                    value={patient.id}
                                    control={<Radio color={"primary"}/>}
                                    label={`${patient.lastname} ${patient.firstname} ${patient.fathername}`}
                                />))
                            }
                        </RadioGroup>
                    </FormControl>}
                </div>
            </div>
            {/*{valueActivePatient && <div className={cl.bodyContainer}>*/}
            {/*    <TextField*/}
            {/*        className={cl.textField}*/}
            {/*        variant="outlined"*/}
            {/*        value={valueActivePatient.lastname}*/}
            {/*        onChange={(event) => updateStateActivePatient('lastname', event.target.value)}*/}
            {/*        label="Прізвище*"*/}
            {/*    />*/}
            {/*    <TextField*/}
            {/*        className={cl.textField}*/}
            {/*        variant="outlined"*/}
            {/*        value={valueActivePatient.firstname}*/}
            {/*        onChange={(event) => updateStateActivePatient('firstname', event.target.value)}*/}
            {/*        label="Ім'я*"*/}
            {/*    />*/}
            {/*    <TextField*/}
            {/*        className={cl.textField}*/}
            {/*        variant="outlined"*/}
            {/*        value={valueActivePatient.fathername}*/}
            {/*        onChange={(event) => updateStateActivePatient('fathername', event.target.value)}*/}
            {/*        label="По-батькові*"*/}
            {/*    />*/}
            {/*    <div className={cl.birthdayContainer}>*/}
            {/*        <p style={{color: 'rgba(0, 0, 0, 0.54)'}}>Дата народження*</p>*/}
            {/*        {!isDoctor && <MuiPickersUtilsProvider utils={DateFnsUtils} locale={uaLocale}>*/}
            {/*            <KeyboardDatePicker*/}
            {/*                className={cl.textField}*/}
            {/*                inputVariant="outlined"*/}
            {/*                value={valueActivePatient.birthday}*/}
            {/*                onChange={(value) => updateStateActivePatient('birthday', value)}*/}
            {/*                invalidDateMessage="Неправильний формат дати."*/}
            {/*                maxDateMessage="Дата народження не може бути в майбутньому."*/}
            {/*                format="yyyy-MM-dd"*/}
            {/*                disableFuture*/}
            {/*                cancelLabel="Назад"*/}
            {/*                okLabel="Ок"*/}
            {/*                openTo="year"*/}
            {/*            />*/}
            {/*        </MuiPickersUtilsProvider>}*/}
            {/*    </div>*/}
            {/*    <FormControl component="fieldset" className={cl.textField}>*/}
            {/*        <FormLabel component="legend">Стать*</FormLabel>*/}
            {/*        <RadioGroup*/}
            {/*            aria-label="gender"*/}
            {/*            value={valueActivePatient.sex}*/}
            {/*            onChange={(event) => updateStateActivePatient('sex', event.target.value)}*/}
            {/*            className={cl.genderGroup}>*/}
            {/*            <FormControlLabel*/}
            {/*                value="F"*/}
            {/*                control={<Radio color="primary"/>}*/}
            {/*                label="Жіноча"*/}
            {/*            />*/}
            {/*            <FormControlLabel*/}
            {/*                value="M"*/}
            {/*                control={<Radio color="primary"/>}*/}
            {/*                label="Чоловіча"*/}
            {/*            />*/}
            {/*        </RadioGroup>*/}
            {/*    </FormControl>*/}
            {/*    <p className={cl.formAnnotation}>(*) Поля позначені зірочкою обов'язкові для*/}
            {/*        заповнення.</p>*/}
            {/*</div>}*/}
            <FormGroup className={cl.formCheckboxes}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxState.checkedA}
                            onChange={handleChange}
                            name="checkedA"
                            color="primary"
                        />
                    }
                    label={<p>
                        Я погоджуюсь на використання <a href={takeId === 14 ? Pdf : undefined} target="_blank">
                        персональних даних.
                    </a>
                    </p>
                    }
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxState.checkedB}
                            onChange={handleChange}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="Я погоджуюсь на створення персонального кабінету."
                />
            </FormGroup>
            {isLoading ? (
                <Loading/>
            ) : (
                <div className={cl.actionContainer}>
                    <SwipeStyledButton
                        className={cl.swipeStyledButton}
                        animationStart={'left'}
                        onClick={handleSignIn}
                    >
                        Підтвердити свої дані
                    </SwipeStyledButton>
                </div>
            )}
        </div>
        : <Loading />)
}

export default EighthStage;
