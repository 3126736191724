import React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {getWorkingHours} from "@cabinet-packages/helpers";

const TablePoints = ({cl,punkt}) => {
    const punktWork = punkt && punkt.rWork && punkt.zWork && getWorkingHours(punkt.hWork, punkt.zWork);

    return (
        <Table size="small" aria-label="a dense table" padding="none" className={cl.table}>
            <TableHead className={cl.tableHead}>
                <TableRow>
                    <TableCell style={{borderBottom: "none"}}/>
                    <TableCell className={cl.headCell}>
                        <span className={cl.headCellUnlockImg}></span>
                    </TableCell>
                    <TableCell className={cl.headCell}>
                        <span className={cl.headCellSyringeImg}></span>
                    </TableCell>
                    <TableCell className={cl.headCell}>
                        <span className={cl.headCellLockImgImg}></span>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell className={cl.mainCell}>Пн-Пт:</TableCell>
                    <TableCell className={cl.cell}>
                        {punktWork && punktWork.weekdaysH[0]}
                    </TableCell>
                    <TableCell className={cl.cell}>
                        {punktWork && punktWork.weekdaysZ[1]}
                    </TableCell>
                    <TableCell className={cl.cell}>
                        {punktWork && punktWork.weekdaysH[1]}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={cl.mainCell}>Сб:</TableCell>
                    <TableCell className={cl.cell}>
                        {punktWork && punktWork.saturdayH[0]}
                    </TableCell>
                    <TableCell className={cl.cell}>
                        {punktWork && punktWork.saturdayZ[1]}
                    </TableCell>
                    <TableCell className={cl.cell}>
                        {punktWork && punktWork.saturdayH[1]}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={cl.mainCell}>Нд:</TableCell>
                    <TableCell className={cl.cell} colSpan={punktWork && punktWork.sundayH[0] === 'Вихідний' ? 2 : 1}>
                        {punktWork && punktWork.sundayH[0]}
                    </TableCell>
                    {punktWork && punktWork.sundayH[0] !== 'Вихідний' && <TableCell className={cl.cell}>
                        {punktWork.sundayZ[1]}
                    </TableCell>}
                    <TableCell className={cl.cell}>
                        {punktWork && punktWork.sundayH[1]}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default TablePoints


