import React from "react";
import useStyles from "../styles";
import {FormattedMessage} from "react-intl";

const CatalogHeader = () => {
    const cl = useStyles();

    return (<div className={cl.catalogHeaderWrapper}>
        <p className={cl.catalogHeaderText}>
            <FormattedMessage id="formatted.message.catalogHeader.name.text"
                              defaultMessage='Назва дослідження'/>
        </p>
        <p className={cl.catalogHeaderText}>
            <FormattedMessage id="formatted.message.catalogHeader.duration.text"
                              defaultMessage='Термін'/>
            <br/>
            <span className={cl.catalogHeaderTextSmall}><FormattedMessage
                id="formatted.message.catalogHeader.workingDays.text"
                defaultMessage='(робочих днів)'/></span>
        </p>
        <p className={cl.catalogHeaderText}><FormattedMessage id="formatted.message.catalogHeader.workingDays.text"
                                                              defaultMessage='Дослідження у комплексі'/>
        </p>
        <p className={cl.catalogHeaderText}>
            <FormattedMessage id="formatted.message.catalogHeader.price.text"
                              defaultMessage='Ціна'/>
            <br/>
            <span className={cl.catalogHeaderTextSmall}>(<FormattedMessage
                id="formatted.message.catalogHeader.atPunkt.text"
                defaultMessage='у відділенні'/>)</span>
        </p>
        <p className={cl.catalogHeaderText}><FormattedMessage id="formatted.message.catalogHeader.priceOnline.text"
                                                              defaultMessage='Ціна онлайн'/></p>
    </div>);
};

export default CatalogHeader;