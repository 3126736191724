import React, {useState} from "react";
import useStyles from "../styles";
import {InputAdornment, TextField} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {Autocomplete} from "@material-ui/lab";
import EndAdornment from "./EndAdornment";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedTags} from "@cabinet-packages/redux";
import RegionPick from "./RegionPick";
import {FormattedMessage} from "react-intl";

const StyledAutocomplete = ({useSelectedTag, useCatalogForView, handleValue, usePrevState, setLoading,allFunc}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const {catalog, complexResearch} = useSelector(state => state.assaysCatalog);
    const [catalogForView, setCatalogForView] = useCatalogForView;
    const [filteredValue, handleChangeFilterValue] = handleValue;
    const [expand, setExpand] = useState(true);

    const handleExpand = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setExpand(prev => !prev);
    }

    const handleChangeValue = (e) => {
        setLoading(true);
        if (!catalogForView || useSelectedTag[0] !== 'Усі') {
            setCatalogForView(null);
            setCatalogForView([...catalog, ...complexResearch].filter(item => !item.superGroupName));
            useSelectedTag[1]('Усі');
            dispatch(setSelectedTags([]));
        }
        handleChangeFilterValue(e);
        setLoading(false)
    }

    return (<>
        <Autocomplete
            multiple
            freeSolo
            open={false}
            options={[]}
            inputValue={filteredValue}
            className={cl.rootAutocompleteBase}
            renderInput={(params) => {
                return (
                    <FormattedMessage id="formatted.message.styledAutocomplete.find.text"
                                      defaultMessage='Пошук'>
                        {placeholder => <TextField
                            {...params}
                            style={expand
                                ? {transition: 'all .5s ease-out', background: 'white', borderRadius: '18px',}
                                : {
                                    width: '38px',
                                    transition: 'all .5s ease-out',
                                    background: 'white',
                                    borderRadius: '18px',
                                }
                            }
                            placeholder={expand ? placeholder : ''}
                            disabled={!expand}
                            onClick={(e) => !expand && handleExpand(e)}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (<InputAdornment position="start"
                                                                 style={expand
                                                                     ? {paddingLeft: '8px'}
                                                                     : {cursor: 'pointer', opacity: 1, color: 'black'}}
                                                                 onClick={(e) => expand && handleExpand(e)}>
                                    <SearchIcon fontSize="small"/>
                                </InputAdornment>),
                                endAdornment: (
                                    <EndAdornment useSelectedTag={useSelectedTag} useCatalogForView={useCatalogForView}
                                                  handleValue={handleValue} usePrevState={usePrevState}
                                                  expand={expand}/>),
                                classes: {
                                    root: expand ? cl.rootAutocomplete : cl.rootAutocompleteNotExpanded,
                                    notchedOutline: cl.notchedOutline,
                                },
                            }}
                            variant="outlined"
                            // value={filteredValue}
                            onChange={handleChangeValue}
                        />}
                    </FormattedMessage>
                )
            }}
        />
        {allFunc &&
        <RegionPick setCatalogForView={setCatalogForView}/>
        }
    </>);
};

export default StyledAutocomplete;
