import React, {Fragment} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import {
  makeStyles,
  TableCell,
  TableRow,
  useMediaQuery,
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import DoneIcon from "@material-ui/icons/Done";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import Typography from "@material-ui/core/Typography";
import {RenderNormArr} from "./Results";

const useStyles = makeStyles((theme) => ({
  assayTableTitle: {
    marginBottom: "10px",
    fontWeight: "600",
    cursor: "pointer",
    transition: "color 0.5s",

    "&:hover": {
      transition: "color 0.5s",
      color: theme.palette.primary.main,
    },
  },
  assayTableTitleTime: {
    fontWeight: "500",
  },
  table: {
    wordBreak: "break-all",
    tableLayout: "fixed",
    width: "100%",
  },
  tableForInnerHTML: {
    maxWidth: "95vw",
  },
  tableCell: {
    width: "25%",
    border: "1px solid rgb(197, 197, 197)",
    padding: "0 2px",
    textAlign: "center",
    color: 'rgba(33, 69, 114, 0.87)',

  },
  tableCellHead: {
    width: "25%",
    border: "1px solid rgba(33, 69, 114, 0.87)",
    padding: "0 2px",
    textAlign: "center",
    color: 'rgba(33, 69, 114, 0.87)',
    fontWeight: 600,
  },
  columnLabel: {
    fontSize: "12px",
    color: 'rgba(33, 69, 114, 0.87)',
    fontWeight: 600,
    "@media(max-width: 900px)": {
      fontSize: "9px",
    },
  },
  researchDescription: {
    display: "flex",
    rowGap: "10px",
    padding: "10px 10px",
    backgroundColor: "#F8F9F9",
    margin: "10px 0px",
    width: "100%",
  },
  resultLabel: {
    fontSize: "14px",
    color: 'rgba(33, 69, 114, 0.87)',
    fontWeight: 600,
    background: theme.palette.primary.background
  },
}));

const ResultJSON = ({ assay, ...rest }) => {
  const classes = useStyles();
  const mdUp = useMediaQuery('@media (maxWidth: 950px)');

  const getAssayState = (state) => {
    if (state === "normal") {
      return <DoneIcon htmlColor="#6ED966" />;
    } else {
      return <PriorityHighIcon htmlColor="#FFDA33" />;
    }
  };

  const parsedResult =
    typeof assay.result === "string" &&
    assay.result.includes('{"') &&
    JSON.parse(assay.result);


  return (
    <Fragment {...rest}>
      <div className={classes.assayTableTitle}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 10px",
            }}
          >
            <Typography className={classes.columnLabel}>
              Назва дослідження
            </Typography>
            {+assay.ready === 0 ? <Typography variant="caption" color="error">
                  Результати ще не готові. Очікуйте сповіщення про готовність.
                </Typography>
                : <Typography className={classes.columnLabel}>
                  Результат готовий
                </Typography>}
          </div>
          <div className={classes.researchDescription}>
            <Typography
              style={mdUp ? { flex: "3 1 0" } : { flex: "2 1 0" }}
              className={classes.resultLabel}
              color={"textPrimary"}
            >
              {`${assay.packet.length > 0 ? assay.packet : assay.test}`}
            </Typography>
            <Typography
              style={mdUp ? {} : { flex: "2 1 0", textAlign: "end" }}
              className={classes.resultLabel}
              color={"textPrimary"}
              component={"div"}
            >
              <em className={classes.assayTableTitleTime}>
                {+assay.ready !== 0 && assay.utime.slice(0, 16).split("-").join(".")}
              </em>
            </Typography>
          </div>
        </div>
      </div>
      {typeof assay.result === "string" &&
      (assay.result.includes('[{"') || assay.result.includes('{"')) ? (
        JSON.parse(assay.result).antibioticograma ? (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCellHead}>
                    <strong>Мікроорганізм</strong>
                  </TableCell>
                  <TableCell className={classes.tableCellHead}>
                    <strong>Результат</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              {parsedResult.bacteria_found &&
                parsedResult.bacteria_found.map((item) => (
                  <TableBody key={item.bacteria.bacteriaId}>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        {item.bacteria.bacteriaName}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {item.concetration}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              <TableBody>
                <TableRow>
                  <TableCell colSpan="2" />
                </TableRow>
              </TableBody>
            </Table>
            <Table>
              {parsedResult.antibioticograma &&
                parsedResult.antibioticograma.map((item, idx) => {
                  return (
                    <React.Fragment key={item.bacteria.bacteriaId + " " + idx}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCellHead}>
                            <strong>Список антибіотиків</strong>
                          </TableCell>
                          <TableCell className={classes.tableCellHead}>
                            <strong>{`Чутливість ${item.bacteria.bacteriaName}`}</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item.antibioticList.map((antibiotic, index) => {
                          return (
                            <TableRow
                              key={antibiotic.antibioticId + " " + index}
                            >
                              <TableCell className={classes.tableCell}>
                                {antibiotic.antibioticName}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {antibiotic.antibioticResistance ===
                                  "SENSITIVE" && "Чутливий"}
                                {antibiotic.antibioticResistance ===
                                  "RESISTANT" && "Стійкий"}
                                {antibiotic.antibioticResistance ===
                                  "MODERATE" && "-"}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </React.Fragment>
                  );
                })}
              <TableBody>
                <TableRow>
                  <TableCell colSpan="2" />
                </TableRow>
              </TableBody>
            </Table>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCellHead}>
                    <strong>Коментарі</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    {parsedResult.comments.map((item, idx) => (
                      <p key={"comment" + idx}>{item}</p>
                    ))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        ) : (
          "Ваші результати знаходяться за посиланням"
        )
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellHead}>
                <strong>Вид дослідження</strong>
              </TableCell>
              <TableCell className={classes.tableCellHead}>
                <strong>Результат</strong>
              </TableCell>
              <TableCell className={classes.tableCellHead}>
                <strong>Референтні значення</strong>
              </TableCell>
              <TableCell className={classes.tableCellHead}>
                <strong>Стан</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assay.arrOfResults.map((itemAssay) => (
              <TableRow
                key={itemAssay.id}
                style={
                  parsedResult.state === "normal"
                    ? {}
                    : { backgroundColor: "#FFEAEA" }
                }
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}
                >
                  <div className={classes.assayNameContainer}>
                    {itemAssay.test}
                  </div>
                </TableCell>
                <TableCell className={classes.tableCellResult}>
                  <strong className={classes.result}>
                    {itemAssay.result + " "}
                  </strong>
                  {itemAssay.units && itemAssay.units !== "-" && (
                    <span
                      dangerouslySetInnerHTML={{ __html: itemAssay.units }}
                    />
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {itemAssay?.normArr && itemAssay?.normArr.length > 0
                      ? <RenderNormArr normArr={itemAssay.normArr} classes={classes}/>
                      : itemAssay.norm &&
                      itemAssay.norm !== "{NULL}" &&
                      itemAssay.norm
                          .split('"')
                          .join("")
                          .split("#%")
                          .join("")
                          .split("{")
                          .join("")
                          .split("}")
                          .map((norm, i) => {
                            if (norm.length !== 0) {
                              return (
                                  <React.Fragment key={`${norm} ${idx} ${i}`}>
                                    {norm.indexOf(",") === 0 ? (
                                        <span
                                            dangerouslySetInnerHTML={{
                                              __html: norm.slice(1) + " ",
                                            }}
                                        />
                                    ) : (
                                        <span
                                            dangerouslySetInnerHTML={{
                                              __html: norm + " ",
                                            }}
                                        />
                                    )}
                                    {itemAssay.units &&
                                        itemAssay.units !== "-" && (
                                            <span
                                                dangerouslySetInnerHTML={{
                                                  __html: itemAssay.units,
                                                }}
                                            />
                                        )}
                                  </React.Fragment>
                              );
                            } else return null;
                          })}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {getAssayState(itemAssay.state)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Fragment>
  );
};

export default ResultJSON;
