import store from "../store";
import {
    ADD_ASSAY_TIME,
    ADD_ASSAY_TIME_LINE,
    ADD_ASSAY_TO_ORDER_LIST,
    ADD_ASSAY_TO_ORDER_LIST_CALCULATOR,
    ADD_NEW_LIST_OF_PATIENTS,
    ADD_ORDER_NOTE,
    ADD_SELECTED_PUNKT,
    CATALOG_TABLE_ID,
    CLEAR_ORDERED_ASSAYS,
    CLEAR_ORDERED_ASSAYS_CALCULATOR,
    CLEAR_TAKES_FROM_BASKET,
    GO_TO_COLLECTION_POINTS,
    REMOVE_ASSAY_FROM_ORDER_LIST,
    REMOVE_ASSAY_FROM_ORDER_LIST_CALCULATOR,
    SELECT_ACTIVE_PATIENT,
    SELECT_ACTIVE_PATIENT_ORDERS,
    SELECT_ACTIVE_PATIENT_RESULTS,
    SET_ACCESS_KEY,
    SET_ACTION_IMG,
    SET_ACTIVE_NURSE,
    SET_ACTIVE_TAB,
    SET_ADDITIONAL_DATA,
    SET_ADDITIONAL_DATA_CALCULATOR,
    SET_ALL_COLLECTION_POINTS,
    SET_BARCODE,
    SET_BASKET,
    SET_BASKET_CALCULATOR,
    SET_CATALOG,
    SET_CATALOG_FILTERED_VALUE,
    SET_CLIENT_LOCATION,
    SET_COLLECTION_POINTS,
    SET_COMPLEX_ID,
    SET_COMPLEX_RESEARCH_TRANSITION,
    SET_CREATE_PATIENT,
    SET_DOCTOR_ORDERED_ASSAYS,
    SET_ERROR,
    SET_FIND_PARAM,
    SET_FULL_ORDER_DATA_CALCULATOR,
    SET_IS_ADMIN,
    SET_LIST_OF_PATIENTS,
    SET_LOADING,
    SET_MATERIAL_THEME,
    SET_MENU_BURGER,
    SET_NOT_ADDED_PATIENTS,
    SET_NURSES_CALCULATOR,
    SET_ORDER_ID,
    SET_ORDER_VIALS,
    SET_ORDERS,
    SET_PRODUCT_CATALOG,
    SET_PROMO_CODE,
    SET_REF_FOR_PATIENT_TABS,
    SET_REGION_CONFIRMATION,
    SET_REGIONS,
    SET_RESULTS_BY_ORDER_ID,
    SET_SCHEDULE,
    SET_SELECT_PUNKT,
    SET_SELECTED_ACTION,
    SET_SELECTED_AGENTS,
    SET_SELECTED_TAGS,
    SET_TAGS,
    SET_TAKE_ID,
    SET_TENTS,
    SET_TOGGLE_CHECKED,
    SET_TOGGLE_COMPLEX_TRANSITION,
    SET_TOGGLE_PERSONAL_DATA,
    SET_TOGGLE_PREPARATION_RULES,
    SET_TOGGLE_TAKE_IN_ORDER,
    SET_TOGGLE_USE_ACTION,
    SET_TURN_BACK,
    SET_WEB_ORDERS,
    START_PHONE_SEND,
    TOGGLE_AUTOMATIC_PRINT_BARCODES,
    TOGGLE_NOTIFICATION,
    UNSET_ERROR,
    UNSET_LOADING,
    UPDATE_ACTIVE_ORDER_DATE,
    UPDATE_ACTIVE_ORDER_PUNKT,
    UPDATE_ACTIVE_PATIENT,
    UPDATE_TUBES_ON_SERVER,
} from "./actionsTypes";
import {
    accept,
    acceptDoctor,
    authorise,
    getActionsFromServer,
    getClientLocationAPI,
    getComplexOfAssays,
    getDoctor,
    getOpenSchedule,
    getPatientOrders,
    getPatientOrdersByPage,
    getPatients,
    getPrice,
    getPunktsAPI,
    setCreateClient,
} from "@cabinet-packages/api";
import calculateDistanceByHaversineFormula from "../../helpers/calculateDistanceByHaversineFormula";
import CustomDialog from "../../components/Dialogs/CustomDialog";
import {TextAlertDialogCalculator} from "@cabinet-packages/components";
import PhoneEnterDialog from "../../components/Dialogs/PhoneEnterDialog";
import {AccessKey} from "@cabinet-packages/helpers";
import {
    getAllOrdersByPhone,
    getAllPunktsAPI,
    getAllPunktsZabir,
    getNurses,
    getOrdersHistory,
    getOrdersZabir,
    getPatientsSite,
    getPrice_v2,
    getPriceComplexWizard,
    getPriceForAdmin,
    getPriceForRegion,
    getPriceWizard,
    getRegions,
    getTags,
    getUnregisteredPatient,
    getWebOrders,
    updateOrderScheduleDate,
    updateOrderSchedulePunkt,
    updateTubes,
} from "@cabinet-packages/api/api";
import "moment/locale/uk";
import moment from "moment";
import {getNextBarcode} from "esculab-leopolis-jazz-fest/src/api/api";
import refactorProductCatalog from "@cabinet-packages/helpers/refactorProductCatalog";
import {setActiveStep} from "../moduleReducers/wizardStepperReducer/serviceMidleware";

moment.locale("uk");

export const loginClient =
    (themeMaterialUI, history, isDoctor, isQuickPurchase) => async (dispatch) => {
        CustomDialog(
            PhoneEnterDialog,
            {
                onSendPhone: async (uuidPhone, phone) => {
                    dispatch({
                        type: START_PHONE_SEND,
                        payload: {
                            stage: 2,
                            error: null,
                        },
                    });
                    const result = await authorise(uuidPhone, phone).catch((onerror) => {
                        if (onerror) {
                            dispatch({
                                type: START_PHONE_SEND,
                                payload: {
                                    stage: 1,
                                    error: "errorWhenSendPhone",
                                },
                            });
                        }
                    });
                    if (result) {
                        dispatch({
                            type: START_PHONE_SEND,
                            payload: {
                                stage: 3,
                            },
                        });
                    }
                },
                goBack: () => {
                    const {stage} = store.getState().patients;

                    if (stage !== 1) {
                        dispatch({
                            type: START_PHONE_SEND,
                            payload: {
                                stage: stage - 2,
                                error: null,
                            },
                        });
                    }
                },
                onSignUp: async (patient, uuid) => {
                    const {
                        lastname,
                        firstname,
                        fathername,
                        birthday,
                        sex,
                        phone,
                        email,
                    } = patient;
                    const birthdayDate =
                        typeof birthday === "string"
                            ? birthday
                            : moment(birthday).format("YYYY-MM-DD");
                    const result = await setCreateClient(
                        uuid,
                        lastname.trim(),
                        firstname.trim(),
                        fathername.trim(),
                        birthdayDate,
                        sex,
                        phone.replace(/[[()\s]/g, ""),
                        email
                    );
                    AccessKey.set(result.token);
                    if (result) {
                        dispatch({
                            type: START_PHONE_SEND,
                            payload: {
                                stage: 7,
                            },
                        });
                        dispatch(setListOfPatients(false, isDoctor));
                    }
                },
                historyPush: (path = "/") => {
                    if (history) history.push(path);
                },
                onSendCode: async (uuidPhone, code) => {
                    dispatch({
                        type: START_PHONE_SEND,
                        payload: {
                            stage: 4,
                        },
                    });

                    try {
                        const result = isDoctor
                            ? await acceptDoctor(uuidPhone, code)
                            : await accept(uuidPhone, code);

                        if (
                            (result.data && result.data.token) ||
                            (isDoctor && result.data)
                        ) {
                            AccessKey.set(isDoctor ? result.data : result.data.token);
                            if (isDoctor || result.data.exist) {
                                dispatch(setListOfPatients(false, isDoctor));
                                const patients = isDoctor
                                    ? await getDoctor()
                                    : await getPatients();

                                const isUserChooseMainPatient = patients.reduce((acu, cur) => {
                                    if (cur.main) return cur.main;

                                    return acu;
                                }, false);

                                if (isUserChooseMainPatient) {
                                    dispatch(
                                        setClient(patients.find((patient) => patient.main === true))
                                    );
                                    dispatch({
                                        type: START_PHONE_SEND,
                                        payload: {
                                            stage: 6,
                                        },
                                    });
                                } else {
                                    dispatch({
                                        type: START_PHONE_SEND,
                                        payload: {
                                            stage: 7,
                                        },
                                    });
                                }
                            } else {
                                dispatch(
                                    setAccessKeyForLogin({accessKey: result.data.token})
                                );
                                dispatch({
                                    type: START_PHONE_SEND,
                                    payload: {
                                        stage: 5,
                                    },
                                });
                            }
                        } else {
                            dispatch({
                                type: START_PHONE_SEND,
                                payload: {
                                    stage: 1,
                                    error: result,
                                },
                            });
                        }

                        return !!result.data;
                    } catch (e) {
                        dispatch({
                            type: START_PHONE_SEND,
                            payload: {
                                stage: 1,
                                error: e && e.response && e.response.data,
                            },
                        });
                    }
                },
                themeMaterialUI,
                isDoctor,
                isQuickPurchase,
            },
            false,
            themeMaterialUI
        );
    };

export const selectActivePatient = (patient) => async (dispatch) => {
    if (!patient) return;
    const {listOfPatients, activePatient} = store.getState().patients;
    const isTheSamePatient =
        activePatient !== null && +activePatient.id === +patient.id;
    if (isTheSamePatient) return;
    let foundPatient =
        listOfPatients &&
        listOfPatients.find((currPatient) => +currPatient.id === +patient.id);
    if (!foundPatient) foundPatient = patient;
    localStorage.setItem("activePatient", JSON.stringify(foundPatient));
    dispatch({
        type: SELECT_ACTIVE_PATIENT,
        payload: {
            foundPatient,
        },
    });
};

export const selectActivePatientResults = () => async (dispatch) => {
    const {activePatientResults, listOfPatients} = store.getState().patients;

    if (!activePatientResults && listOfPatients) {
        const results =
            typeof listOfPatients !== "string"
                ? await getPatientOrders(
                listOfPatients.find((patient) => patient.main)
                    ? listOfPatients.find((patient) => patient.main)?.id
                    : listOfPatients[0]?.id
                ).catch(() => "error")
                : "error";

        dispatch({
            type: SELECT_ACTIVE_PATIENT_RESULTS,
            payload: {
                results: results.filter(item => !item?.deleted),
            },
        });
    }
};

export const selectActivePatientResultsPage = () => async (dispatch) => {
    const {activePatientResults, activePatientResultsPage, activePatient} =
        store.getState().patients;
    if (activePatient) {
        let results =
            typeof activePatient !== "string"
                ? await getPatientOrdersByPage(
                activePatient.id,
                activePatientResultsPage
                ).catch(() => "error")
                : "error";

        if (activePatientResults) {
            results = activePatientResults.concat(results);
        }

        dispatch({
            type: SELECT_ACTIVE_PATIENT_RESULTS,
            payload: {
                results,
            },
        });
    }
};

export const setResultsByOrderId = (results, orderId) => async (dispatch) => {
    const activePatientResults = store.getState().patients.activePatientResults;

    dispatch({
        type: SET_RESULTS_BY_ORDER_ID,
        payload: {
            newActivePatientResults: activePatientResults.map((order) => {
                if (order.idOrder === orderId) {
                    return {
                        ...order,
                        results,
                    };
                } else {
                    return order;
                }
            }),
        },
    });
};

export const toggleNotification = () => ({
    type: TOGGLE_NOTIFICATION,
});

export const addAssayToOrderList =
    (id, assayPrice, callback, type) => (dispatch) => {
        const orderingList = store.getState().orderAssays.orderingList;
        const {catalog, actions, complexResearch} =
            store.getState().assaysCatalog;
        const catalogOfAllAssays = [...catalog, ...actions, ...complexResearch];
        const orders = [...orderingList, {id, type}];

        let newPrice = +assayPrice.toFixed(2);

        const newOrderingList = orders
            .map((currId) =>
                catalogOfAllAssays.find(
                    (currAssay) =>
                        +currAssay.id === +currId.id &&
                        currAssay.type === currId.type &&
                        !currAssay.superGroupName
                )
            )
            .reduce((acu, cur, idx, src) => {
                if (cur && cur.take && cur.take.length > 0) {
                    const arrayOfCollection = cur.take.reduce((acum, col) => {
                        const findCollection = catalogOfAllAssays.find(
                            (assay) => +assay.id === +col && !assay.superGroupName
                        );
                        if (
                            !acum.includes(findCollection) &&
                            !src.includes(findCollection) &&
                            findCollection
                        ) {
                            return [...acum, findCollection];
                        }

                        return acum;
                    }, []);

                    arrayOfCollection.map((coll) => {
                        newPrice += +coll.price;
                        return coll;
                    });

                    return [...acu, cur, ...arrayOfCollection];
                }
                return [...acu, cur];
            }, [])
            .map((item) => {
                if (item) return {id: item.id, type: item.type};
            });

        dispatch({
            type: ADD_ASSAY_TO_ORDER_LIST_CALCULATOR,
            payload: {
                assayPrice: newPrice,
                orderingList: newOrderingList,
            },
        });
        dispatch({
            type: ADD_ASSAY_TO_ORDER_LIST,
            payload: {
                assayPrice: newPrice,
                orderingList: newOrderingList,
            },
        });
        callback();
    };

export const removeAssayFromOrderList =
    (id, assayPrice, callback, type) => (dispatch) => {
        const orderingList = store.getState().orderAssays.orderingList;
        const {catalog, actions, complexResearch} =
            store.getState().assaysCatalog;
        const catalogOfAllAssays = [...catalog, ...actions, ...complexResearch];
        let newOrderingList = [];
        let newPrice = +assayPrice.toFixed(2);
        let foundItem = catalogOfAllAssays.find(
            (item) => +item.id === +id && item.type === type && !item.superGroupName
        )?.take;

        if (foundItem && foundItem.length > 0) {
            foundItem = foundItem.filter((item) => item !== "");
        }
        if (!foundItem || foundItem.length === 0) {
            newOrderingList =
                orderingList &&
                orderingList.filter(
                    (currId) => !(currId.id === id && currId.type === type)
                );
        } else {
            const orderedAssaysTake = orderingList
                .map((assay) =>
                    catalogOfAllAssays.find(
                        (item) =>
                            +item.id === +assay.id &&
                            item.type === assay.type &&
                            !item.superGroupName
                    )
                )
                .reduce((acu, cur) => {
                    if (cur.take) {
                        return [...acu, ...cur.take];
                    }

                    return acu;
                }, [])
                .filter((item) => item !== "");

            catalogOfAllAssays
                .find(
                    (item) =>
                        +item.id === +id && item.type === type && !item.superGroupName
                )
                .take.filter((item) => item !== "")
                .forEach((takeAssay) => {
                    const similarTake = orderedAssaysTake.filter(
                        (item) => +item === +takeAssay
                    );
                    if (similarTake.length === 1) {
                        newOrderingList = newOrderingList =
                            newOrderingList.length > 0
                                ? newOrderingList.filter(
                                (currId) =>
                                    !(
                                        (+currId.id === +id && currId.type === type) ||
                                        +currId.id === +takeAssay
                                    )
                                )
                                : orderingList.filter(
                                (currId) =>
                                    !(
                                        (+currId.id === +id && currId.type === type) ||
                                        +currId.id === +takeAssay
                                    )
                                );
                        similarTake.forEach((assay) => {
                            const price = catalogOfAllAssays.find(
                                (item) => +item.id === +assay && !item.superGroupName
                            )
                                ? catalogOfAllAssays.find(
                                    (item) => +item.id === +assay && !item.superGroupName
                                ).price
                                : 0;
                            newPrice += +price;
                        });
                    } else {
                        newOrderingList =
                            newOrderingList.length > 0
                                ? newOrderingList.filter(
                                (currId) => !(currId.id === id && currId.type === type)
                                )
                                : orderingList.filter(
                                (currId) => !(currId.id === id && currId.type === type)
                                );
                    }
                });
        }

        dispatch({
            type: REMOVE_ASSAY_FROM_ORDER_LIST_CALCULATOR,
            payload: {
                assayPrice: newPrice,
                orderingList: newOrderingList,
            },
        });
        dispatch({
            type: REMOVE_ASSAY_FROM_ORDER_LIST,
            payload: {
                assayPrice: newPrice,
                orderingList: newOrderingList,
            },
        });
        callback();
    };

export const addSelectedPunkt = (newSelectedPunkt) => (dispatch) => {
    dispatch({
        type: ADD_SELECTED_PUNKT,
        payload: {
            newSelectedPunkt,
        },
    });
};

export const setOrderPunkt = (orderId, punktId) => async (dispatch) => {
    const {activePatient} = store.getState().patients;

    await updateOrderSchedulePunkt(activePatient.id, orderId, punktId).then(
        () => {
            dispatch({
                type: UPDATE_ACTIVE_ORDER_PUNKT,
                payload: {
                    orderId,
                    punktId,
                },
            });
        }
    );
};

export const setOrderDate = (orderId, date) => async (dispatch) => {
    const {activePatient} = store.getState().patients;

    await updateOrderScheduleDate(activePatient.id, orderId, date).then(() => {
        dispatch({
            type: UPDATE_ACTIVE_ORDER_DATE,
            payload: {
                orderId,
                date,
            },
        });
    });
};

export const clearOrderedAssays = () => ({
    type: CLEAR_ORDERED_ASSAYS,
});

export const addPatient = (client) => async (dispatch) => {
    //const listOfPatients = store.getState().patients.listOfPatients;

    if (client) {
        dispatch({
            type: ADD_NEW_LIST_OF_PATIENTS,
            payload: {
                newPatient: client[0],
            },
        });
    }
};

export const setListOfPatients =
    (updatePatients, isDoctor, clients) => async (dispatch) => {
        const listOfPatients = store.getState().patients.listOfPatients;

        if (clients) {
            dispatch({
                type: SET_LIST_OF_PATIENTS,
                payload: {
                    newListOfPatients: [
                        clients &&
                        typeof clients !== "string" &&
                        clients.sort((a, b) => {
                            if (a.main) {
                                return -1;
                            } else if (b.main) {
                                return 1;
                            } else {
                                return 0;
                            }
                        }),
                    ],
                },
            });

            return;
        }

        if ((!listOfPatients || updatePatients) && !clients) {
            const newListOfPatients = isDoctor
                ? await getDoctor()
                : await getPatients();
            if (newListOfPatients) {
                dispatch({
                    type: SET_LIST_OF_PATIENTS,
                    payload: {
                        newListOfPatients:
                            newListOfPatients &&
                            typeof newListOfPatients !== "string" &&
                            Array.isArray(newListOfPatients) &&
                            newListOfPatients
                                ?.sort((a, b) => {
                                    if (a.main) {
                                        return -1;
                                    } else if (b.main) {
                                        return 1;
                                    } else {
                                        return 0;
                                    }
                                })
                                .map((item) => {
                                    return {
                                        ...item,
                                        patientRole: item.patientRole.toLowerCase(),
                                    };
                                }),
                    },
                });
            }
        }
    };

export const setListOfPatientsSite = (updatePatients) => async (dispatch) => {
    const listOfPatients = store.getState().patients.listOfPatients;
    if (!listOfPatients || updatePatients) {
        const newListOfPatients = await getPatientsSite();
        if (newListOfPatients) {
            dispatch({
                type: SET_LIST_OF_PATIENTS,
                payload: {
                    newListOfPatients,
                },
            });
        }
    }
};

export const setClient = (client, notUpdateLS) => async (dispatch) => {
    if (client && !notUpdateLS)
        localStorage.setItem("activePatient", JSON.stringify(client));

    dispatch({
        type: UPDATE_ACTIVE_PATIENT,
        payload: {
            activePatient: client,
        },
    });
};

export const setComplexId = (id) => async (dispatch) => {
    dispatch({
        type: SET_COMPLEX_ID,
        payload: {
            complexId: id,
        },
    });
};

export const setTakeId = (id) => async (dispatch) => {
    dispatch({
        type: SET_TAKE_ID,
        payload: {
            takeId: id,
        },
    });
};
export const getPromotions = () => async (dispatch) => {
    const actions = await getActionsFromServer().catch((onerror) => {
        console.error(onerror);
        return "error";
    });

    dispatch({
        type: SET_CATALOG,
        payload: {
            actions:
                typeof actions === "string"
                    ? actions
                    : actions
                        .filter((action) => action.active === "Y")
                        .map((action) => ({...action, name: action.title})),
        },
    });
};

export const getCatalog = (reload, setNewCatalog) => async (dispatch) => {
    const {catalog, takeId, complexId} = store.getState().assaysCatalog;
    const isAdmin = window.localStorage.getItem("isAdmin");
    const isPartner = !!process.env.REACT_APP_API_PATH;
    const isDoctor = localStorage.getItem("isDoctor");
    const idReg = localStorage.getItem("idReg");
    if (reload) {
        dispatch({
            type: SET_CATALOG,
            payload: {
                catalog: null,
                complex: null,
                actions: null,
            },
        });
    }

    if ((!catalog || reload || setNewCatalog) && takeId && complexId) {
        const catalogFromApi =
            !isDoctor && !isPartner && !isAdmin
                ? await getPriceForRegion(takeId, complexId).catch((onerror) => {
                    console.error(onerror);
                    return "error";
                })
                : isDoctor && !isPartner && !isAdmin
                ? await getPriceForRegion(takeId, complexId).catch((onerror) => {
                    console.error(onerror);
                    return "error";
                })
                : isAdmin && !isPartner
                    ? await getPriceForAdmin(takeId, complexId).catch((onerror) => {
                        console.error(onerror);
                        return "error";
                    })
                    : await getPrice(takeId, complexId).catch((onerror) => {
                        console.error(onerror);
                        return "error";
                    });

        const complex = await getComplexOfAssays(complexId).catch((onerror) => {
            console.error(onerror);
            return "error";
        });
        const actions = await getActionsFromServer().catch((onerror) => {
            console.error(onerror);
            return "error";
        });

        if (catalogFromApi && complex && actions) {
            dispatch({
                type: SET_CATALOG,
                payload: {
                    catalog:
                        catalogFromApi !== "error"
                            ? catalogFromApi
                                ?.filter(
                                    (item) =>
                                        (!item.notSale &&
                                            (item.opt ? item.opt.includes("public") : true)) ||
                                        isPartner ||
                                        isAdmin
                                )
                                ?.map((item) => {
                                    const foundedAction =
                                        !isPartner &&
                                        !isAdmin &&
                                        actions &&
                                        typeof actions !== 'string' &&
                                        actions?.find(
                                            (action) =>
                                                action.active === "Y" &&
                                                (+takeId === 216 || (action.idregs &&
                                                    action.idregs.length > 0 &&
                                                    action.idregs.includes(+idReg) &&
                                                    action.dtTo &&
                                                    moment().isBefore(
                                                        moment(action.dtTo, "YYYY-MM-DD")
                                                    ))) &&
                                                +action.idGrTest[0] === +item.id &&
                                                !isDoctor &&
                                                !isPartner
                                        );

                                    if (!item.superGroupName && foundedAction) {
                                        return {
                                            ...item,
                                            price: foundedAction.price,
                                            discount: 0,
                                        };
                                    } else {
                                        return item;
                                    }
                                })
                            : catalogFromApi,
                    complex:
                        complex &&
                        complex !== "error" &&
                        complex.map((item) => {
                            const foundedComplexInPrice = catalog
                            const foundedAction =
                                !isPartner &&
                                !isAdmin &&
                                Array.isArray(actions) &&
                                actions?.find(
                                    (action) =>
                                        action.active === "Y" &&
                                        (+takeId === 216 || (action.dtTo && moment().isBefore(moment(action.dtTo, "YYYY-MM-DD")) && action.idregs &&
                                            action.idregs.length > 0 &&
                                            action.idregs.includes(+idReg))) &&
                                        +action.idGrTest[0] === +item.id &&


                                        !isDoctor &&
                                        !isPartner
                                );

                            if (!item.superGroupName && foundedAction) {
                                return {
                                    ...item,
                                    price: foundedAction.price,
                                    discount: 0,
                                };
                            } else {
                                return item;
                            }
                        }).filter(
                            (item) =>
                                (!item.notSale &&
                                    (item.opt ? item.opt.includes("public") : true))),
                    actions:
                        typeof actions === "string"
                            ? actions
                            : +takeId === 216
                            ? actions
                                .filter((action) => action.active === "Y")
                                .map((action) => {
                                    const foundComplex = [...complex, ...catalogFromApi].find(com => +com.id === +action.idGrTest);

                                    return {...foundComplex, ...action, name: action.title, price: action.price};
                                })
                            : actions
                                .filter((action) => action.active === "Y")
                                .map((action) => ({...action, name: action.title})),
                },
            });
        }
        window.localStorage.removeItem("isAdmin");
    }
};
export const getPunktsZabir = () => async (dispatch) => {
    const result = await getAllPunktsZabir().catch((onerror) => "error");
    dispatch({
        type: SET_ALL_COLLECTION_POINTS,
        payload: {
            data: result,
        },
    });
};

export const loadWebOrders = (data) => async (dispatch) => {
    const result = await getWebOrders(data).catch((onerror) =>
        window.location.reload()
    );
    dispatch({
        type: SET_WEB_ORDERS,
        payload: result,
    });
};

export const loadOrders = (data) => async (dispatch) => {
    const result = await getOrdersZabir(data).catch((onerror) => "error");
    dispatch({
        type: SET_ORDERS,
        payload: result,
    });
};

export const getSelectRegion = () => async (dispatch) => {
    const takeId = process.env.REACT_APP_PROJECT_NAME === "MEDIS";
    const result = await getPunktsAPI().catch((onerror) => "error");
    const clientLocation = await getClientLocationAPI();
    if (clientLocation && typeof result !== "string") {
        const sortedArrayOfPunktsByDistance = result
            .map((point) => {
                return {
                    distance: calculateDistanceByHaversineFormula(
                        clientLocation.lat,
                        clientLocation.lng,
                        point.lat,
                        point.lng
                    ),
                    point,
                };
            })
            .sort((a, b) => a.distance - b.distance)
            .map((punkt) => punkt.point);

        dispatch({
            type: SET_COLLECTION_POINTS,
            payload: {
                data: takeId
                    ? sortedArrayOfPunktsByDistance
                    : sortedArrayOfPunktsByDistance,
            },
        });

        dispatch({
            type: SET_CLIENT_LOCATION,
            payload: {
                data: {
                    lat: clientLocation.lat,
                    lng: clientLocation.lng,
                },
            },
        });
    }
};

export const getPunkts = (reload) => async (dispatch) => {
    const {punkts} = store.getState().collectionPoints;
    const takeId = process.env.REACT_APP_PROJECT_NAME === "MEDIS";

    if (!punkts || reload) {
        const result = await getPunktsAPI().catch(() => "error");

        if (result) {
            dispatch({
                type: SET_COLLECTION_POINTS,
                payload: {
                    data: takeId && result && typeof result !== "string"
                        ? result.filter((item) => item.onsite && item.active)
                        : result && typeof result !== "string"
                            ? result.filter((item) => item.onsite && item.active)
                            : result,
                },
            });
        }
        const clientLocation = await getClientLocationAPI();
        if (clientLocation && typeof result !== "string") {
            const sortedArrayOfPunktsByDistance = result.filter((item) => item.onsite && item.active)
                .map((point) => {
                    return {
                        distance: calculateDistanceByHaversineFormula(
                            clientLocation.lat,
                            clientLocation.lng,
                            point.lat,
                            point.lng
                        ),
                        point,
                    };
                })
                .sort((a, b) => a.distance - b.distance)
                .map((punkt) => punkt.point);

            dispatch({
                type: SET_COLLECTION_POINTS,
                payload: {
                    data: takeId
                        ? sortedArrayOfPunktsByDistance.filter(
                            (item) => item.onsite && item.idPunkt !== 149
                        )
                        : sortedArrayOfPunktsByDistance,
                },
            });

            dispatch({
                type: SET_CLIENT_LOCATION,
                payload: {
                    data: {
                        lat: clientLocation.lat,
                        lng: clientLocation.lng,
                    },
                },
            });
        }
    }
};

export const getAllPunkts = (reload, allPunkts, showMorePunkts, showKids) => async (dispatch) => {
    const {punkts} = store.getState().collectionPoints;
    const takeId = process.env.REACT_APP_PROJECT_NAME === "MEDIS";

    if (!punkts || reload) {
        const result = allPunkts
            ? await getAllPunktsAPI(showMorePunkts, showKids).catch(() => "error")
            : await getPunktsAPI(showKids).catch((onerror) => "error");

        if (result) {
            dispatch({
                type: SET_COLLECTION_POINTS,
                payload: {
                    data:
                        takeId && result && typeof result !== "string"
                            ? result.filter((item) => item.idPunkt !== 149)
                            : result && typeof result !== "string"
                            ? result
                            : result,
                },
            });
        }
        const clientLocation = await getClientLocationAPI();
        if (clientLocation && typeof result !== "string") {
            const sortedArrayOfPunktsByDistance = result
                .map((point) => {
                    return {
                        distance: calculateDistanceByHaversineFormula(
                            clientLocation.lat,
                            clientLocation.lng,
                            point.lat,
                            point.lng
                        ),
                        point,
                    };
                })
                .sort((a, b) => a.distance - b.distance)
                .map((punkt) => punkt.point);

            dispatch({
                type: SET_COLLECTION_POINTS,
                payload: {
                    data: takeId
                        ? sortedArrayOfPunktsByDistance.filter(
                            (item) => item.idPunkt !== 149
                        )
                        : sortedArrayOfPunktsByDistance,
                },
            });

            dispatch({
                type: SET_CLIENT_LOCATION,
                payload: {
                    data: {
                        lat: clientLocation.lat,
                        lng: clientLocation.lng,
                    },
                },
            });
        }
    }
};

export const getClientLocation =
    (selectPunkt, returnPunkt) => async (dispatch) => {
        const {clientLocation} = store.getState().collectionPoints;

        if (returnPunkt) {
            dispatch({
                type: SET_SELECT_PUNKT,
                payload: {
                    selectPunkt: null,
                },
            });
            return dispatch({
                type: ADD_SELECTED_PUNKT,
                payload: {
                    newSelectedPunkt: null,
                },
            });
        }

        if (selectPunkt) {
            dispatch({
                type: SET_SELECT_PUNKT,
                payload: {
                    selectPunkt: selectPunkt,
                },
            });
            dispatch({
                type: ADD_SELECTED_PUNKT,
                payload: {
                    newSelectedPunkt: selectPunkt,
                },
            });
            dispatch(setAssayTime());
        } else {
            if (
                !clientLocation ||
                (clientLocation && (!clientLocation.lat || !clientLocation.lng))
            ) {
                const clientLatLon = await getClientLocationAPI();

                if (clientLatLon) {
                    dispatch({
                        type: SET_CLIENT_LOCATION,
                        payload: {
                            data: {
                                lat: clientLatLon.lat,
                                lng: clientLatLon.lng,
                            },
                        },
                    });
                }
            }
        }
    };

export const getSchedule = () => async (dispatch) => {
    const {schedule} = store.getState().scheduleOfOrders;
    if (!schedule) {
        const result = await getOpenSchedule();
        dispatch({
            type: SET_SCHEDULE,
            payload: {
                schedule: result,
            },
        });
    }
};

export const setAssayTime = (selectedTime, setObj) => (dispatch) => {
    if (!selectedTime) {
        dispatch({
            type: ADD_ASSAY_TIME,
            payload: {
                time: null,
            },
        });

        return;
    }
    const {date, from} = selectedTime;

    if (from) {
        dispatch({
            type: ADD_ASSAY_TIME,
            payload: {
                time: `${date} ${from}`,
            },
        });
    } else if (!setObj && date) {
        dispatch({
            type: ADD_ASSAY_TIME,
            payload: {
                time: `${date}`,
            },
        });
    } else if (setObj && date) {
        dispatch({
            type: ADD_ASSAY_TIME,
            payload: {
                time: date,
            },
        });
    }
};

export const setAssayLine = (selectedTime) => (dispatch) => {
    if (!selectedTime) {
        dispatch({
            type: ADD_ASSAY_TIME_LINE,
            payload: {
                timeLine: null,
            },
        });

        return;
    }
    const {date} = selectedTime;

    dispatch({
        type: ADD_ASSAY_TIME_LINE,
        payload: {
            timeLine: `${date}`,
        },
    });
};

export const setOrderNote = (note) => (dispatch) => {
    dispatch({
        type: ADD_ORDER_NOTE,
        payload: {
            note,
        },
    });
};

export const setTableId = (id) => (dispatch) => {
    dispatch({
        type: CATALOG_TABLE_ID,
        payload: {
            id,
        },
    });
};

export const setTurnBack = (value) => (dispatch) => {
    dispatch({
        type: SET_TURN_BACK,
        payload: {
            value,
        },
    });
};

const todayDate = new Date();
const initialCreatePatient = {
    firstname: "",
    lastname: "",
    fathername: "",
    dt: todayDate,
    birthday: todayDate,
    sex: "F",
    phone: "",
    email: "",
    regDate: todayDate,
};

export const setCreatePatient =
    (value = initialCreatePatient) =>
        (dispatch) => {
            dispatch({
                type: SET_CREATE_PATIENT,
                payload: {
                    value,
                },
            });
        };

export const setSelectedComplex = (complex) => (dispatch) => {
    dispatch({
        type: SET_COMPLEX_RESEARCH_TRANSITION,
        payload: {
            complex,
        },
    });
};

export const setToggleChecked = (toggleChecked) => (dispatch) => {
    dispatch({
        type: SET_TOGGLE_CHECKED,
        payload: {
            toggleChecked,
        },
    });
};

export const setSelectedAction = (selectedAction) => (dispatch) => {
    dispatch({
        type: SET_SELECTED_ACTION,
        payload: {
            selectedAction,
        },
    });
};

export const setAccessKeyForLogin = (accessKey) => (dispatch) => {
    dispatch({
        type: SET_ACCESS_KEY,
        payload: {
            accessKey,
        },
    });
};

export const setTheme = (theme) => (dispatch) => {
    dispatch({
        type: SET_MATERIAL_THEME,
        payload: {
            theme,
        },
    });
};

export const setActionImg = (imgUrl) => (dispatch) => {
    dispatch({
        type: SET_ACTION_IMG,
        payload: {
            imgUrl,
        },
    });
};

export const setOrderId = (orderId) => (dispatch) => {
    dispatch({
        type: SET_ORDER_ID,
        payload: {
            orderId,
        },
    });
};

export const selectPatientOrdersByPhone = (update) => async (dispatch) => {
    const {activePatientOrders, listOfPatients} = store.getState().patients;
    /*Не потрібно для ескулаб При потребі розкоментувати*/
    /* const {actions, catalog, complexResearch} = store.getState().assaysCatalog;
       const allPositions = [...actions, ...catalog, ...complexResearch];*/

    if (
        (!activePatientOrders || update)
        /*Не потрібно для ескулаб При потребі розкоментувати*/
        /* &&
         catalog &&
         actions &&
         complexResearch*/
    ) {
        const results = await getAllOrdersByPhone(
            listOfPatients.find((item) => item.main)?.id || listOfPatients[0].id
        ).catch(() => []);

        dispatch({
            type: SELECT_ACTIVE_PATIENT_ORDERS,
            payload: {
                results:
                    results.length === 0
                        ? []
                        : results.map((item) => {
                            return {
                                ...item,
                                /*Не потрібно для ескулаб При потребі розкоментувати*/
                                /* services: item.services.map((assay) => {
                                     const findAssay = allPositions.find(
                                         (itemFromAll) =>
                                             +itemFromAll.id === +assay.idPacket &&
                                             +itemFromAll.idConf === assay.idConf
                                     );

                                     if (findAssay) return findAssay;

                                     return assay;
                                 }),*/
                            };
                        }),
            },
        });
    }
};

export const selectActivePatientOrders = (update) => async (dispatch) => {
    const {activePatientOrders, activePatient} = store.getState().patients;
    const {actions, catalog, complexResearch} = store.getState().assaysCatalog;
    const allPositions = [...actions, ...catalog, ...complexResearch];

    if ((!activePatientOrders || update) &&
        catalog &&
        actions &&
        complexResearch
    ) {
        const results = await getOrdersHistory(activePatient.id).catch(() => []);

        dispatch({
            type: SELECT_ACTIVE_PATIENT_ORDERS,
            payload: {
                results:
                    results.length === 0
                        ? []
                        : results.map((item) => {
                            return {
                                ...item,
                                services: item.services.map((assay) => {
                                    const findAssay = allPositions.find(
                                        (itemFromAll) =>
                                            +itemFromAll.id === +assay.idPacket &&
                                            +itemFromAll.idConf === assay.idConf
                                    );

                                    if (findAssay) return findAssay;

                                    return assay;
                                }),
                            };
                        }),
            },
        });
    }
};

export const clearTakesFromBasket = () => (dispatch) => {
    const {actions, catalog, complexResearch, takeId} = store.getState().assaysCatalog;
    const {orderingList} = store.getState().orderAssays;
    const allPositions = [...actions, ...catalog, ...complexResearch];
    let takesPrice = 0;

    const newOrderingList = orderingList.reduce((acu, cur) => {
        const assay = allPositions.find(
            (item) => item.type === cur.type && +item.id === +cur.id
        );

        if (assay.idParent === takeId) {
            takesPrice += assay.price;
            return acu;
        } else {
            return [...acu, cur];
        }
    }, []);

    dispatch({
        type: CLEAR_TAKES_FROM_BASKET,
        payload: {
            newOrderingList,
            takesPrice,
        },
    });
};

export const setGoToCollectionPoints = (goToCollectionPoints) => (dispatch) => {
    dispatch({
        type: GO_TO_COLLECTION_POINTS,
        payload: {
            goToCollectionPoints,
        },
    });
};

export const setDoctorOrderedAssays = (orderedAssays) => (dispatch) => {
    const {doctorOrderedAssays} = store.getState().orderAssays;

    if (!doctorOrderedAssays && orderedAssays) {
        dispatch({
            type: SET_DOCTOR_ORDERED_ASSAYS,
            payload: {
                orderedAssays,
            },
        });
    } else if (orderedAssays === null) {
        dispatch({
            type: SET_DOCTOR_ORDERED_ASSAYS,
            payload: {
                orderedAssays,
            },
        });
    }
};

export const setRegions = () => async (dispatch) => {
    const {regions} = store.getState().collectionPoints;
    const result = await getRegions();
    if (!regions && result && result.length > 0) {
        dispatch({
            type: SET_REGIONS,
            payload: {
                data: result.sort((a, b) => a.name.localeCompare(b.name)),
            },
        });
    }
};

export const setRegionsVdoma = () => async (dispatch) => {
    const {regions} = store.getState().collectionPoints;
    const result = await getRegions();
    if (!regions && result && result.length > 0) {
        dispatch({
            type: SET_REGIONS,
            payload: {
                data: result
                    .filter((item) => +item.idReg === 2 || +item.idReg === 43)
                    .sort((a, b) => a.name.localeCompare(b.name)),
            },
        });
    }
};

export const setCatalogFilteredValue = (value) => (dispatch) => {
    dispatch({
        type: SET_CATALOG_FILTERED_VALUE,
        payload: {
            value,
        },
    });
};

export const setPromoCode = (value) => (dispatch) => {
    dispatch({
        type: SET_PROMO_CODE,
        payload: {
            value,
        },
    });
};

export const setRegionConfirmation = (value) => (dispatch) => {
    dispatch({
        type: SET_REGION_CONFIRMATION,
        payload: {
            value,
        },
    });
};

export const setNotAddedPatients = () => async (dispatch) => {
    const {listOfPatients} = store.getState().patients;

    const value = await getUnregisteredPatient(
        listOfPatients?.find((item) => item.main)?.phone
    );

    dispatch({
        type: SET_NOT_ADDED_PATIENTS,
        payload: {
            value: value.data,
        },
    });
};

export const setBasket = (value) => (dispatch) => {
    dispatch({
        type: SET_BASKET,
        payload: value,
    });
};

export const setTags = () => async (dispatch) => {
    const tags = await getTags();

    dispatch({
        type: SET_TAGS,
        payload: tags.sort((a, b) => a.id - b.id),
    });
};

export const setSelectedTags = (tags) => (dispatch) => {
    dispatch({
        type: SET_SELECTED_TAGS,
        payload: tags,
    });
};

export const setTents = (data) => (dispatch) => {
    dispatch({
        type: SET_TENTS,
        payload: data,
    });
};

export const setBarcode = (id, barcode) => async (dispatch) => {
    const code = id ? await getNextBarcode(id) : {data: barcode};

    dispatch({
        type: SET_BARCODE,
        payload: code.data,
    });
};

//testing new features
export const getProductCatalog = (reload) => async (dispatch) => {
    const {catalog} = store.getState().productCatalog;

    if (reload) {
        dispatch({
            type: SET_PRODUCT_CATALOG,
            payload: null,
        });
        return;
    }

    dispatch(setLoading("Завантажуємо список досліджень"));

    try {
        if (!catalog) {
            const {data} = await getPrice_v2();

            dispatch({
                type: SET_PRODUCT_CATALOG,
                payload: refactorProductCatalog(data),
            });
        }
    } catch (e) {
        console.error(e);
        dispatch(
            setError(
                "Вибачте, виникла помилка при завантаженні досліджень. Спробуйте оновити сторінку пізніше."
            )
        );
    }

    dispatch(unsetLoading());
};

export function setLoading(text) {
    return async (dispatch) => {
        dispatch({
            type: SET_LOADING,
            payload: text,
        });
    };
}

export function unsetLoading() {
    return async (dispatch) => {
        dispatch({
            type: UNSET_LOADING,
        });
    };
}

export function setError(text) {
    return async (dispatch) => {
        dispatch({
            type: SET_ERROR,
            payload: text,
        });
    };
}

export function unsetError() {
    return async (dispatch) => {
        dispatch({
            type: UNSET_ERROR,
        });
    };
}

export const getCatalogWizard = (reload) => async (dispatch) => {
    const {catalog, takeId, complexId} = store.getState().assaysCatalog;

    if (reload) {
        dispatch({
            type: SET_CATALOG,
            payload: {
                catalog: null,
                complex: null,
                actions: null,
            },
        });
    }

    if ((!catalog || reload) && takeId && complexId) {
        const catalogFromApi = await getPriceWizard().catch((onerror) => {
            console.error(onerror);
            return "error";
        });

        const complex = await getPriceComplexWizard().catch((onerror) => {
            console.error(onerror);
            return "error";
        });
        const actions = [];

        if (catalogFromApi && complex && actions) {
            dispatch({
                type: SET_CATALOG,
                payload: {
                    catalog:
                        catalogFromApi !== "error"
                            ? catalogFromApi.filter(
                            (item) =>
                                !item.notSale &&
                                (item.opt ? item.opt.includes("public") : true)
                            )
                            : catalogFromApi,
                    complex,
                    actions: actions,
                },
            });
        }
    }
};

export function setAdditionalData(data) {
    return async (dispatch) => {
        dispatch({
            type: SET_ADDITIONAL_DATA,
            payload: data,
        });
    };
}

export const setMenuBurger = (menuBurger) => async (dispatch) => {
    dispatch({
        type: SET_MENU_BURGER,
        payload: {menuBurger},
    });
};

export const togglePreparationRules = () => (dispatch) => {
    dispatch({
        type: SET_TOGGLE_PREPARATION_RULES,
    });
};

export const togglePersonalData = () => (dispatch) => {
    dispatch({
        type: SET_TOGGLE_PERSONAL_DATA,
    });
};

export const toggleComplexTransition = () => (dispatch) => {
    dispatch({
        type: SET_TOGGLE_COMPLEX_TRANSITION,
    });
};

export const toggleTakeInOrder = () => (dispatch) => {
    dispatch({
        type: SET_TOGGLE_TAKE_IN_ORDER,
    });
};

export function setPatientTabsRef(ref) {
    return async (dispatch) => {
        dispatch({
            type: SET_REF_FOR_PATIENT_TABS,
            payload: ref,
        });
    };
}

export const toggleUseAction = () => (dispatch) => {
    dispatch({
        type: SET_TOGGLE_USE_ACTION,
    });
};

export const addAssayToOrderListCalculator =
    (assay, assayPrice) => (dispatch) => {
        const orderingList = store.getState().calculator.orderingList || [];
        const {catalog, actions, complexResearch} =
            store.getState().assaysCatalog;
        const orders = [...orderingList, assay];

        let newPrice = +assayPrice.toFixed(2);

        const newOrderingList = orders.reduce((acu, cur) => {
            /*if (cur && cur.take && cur.take.length > 0) {
                 /!* const arrayOfCollection = cur.take.reduce((acum, col) => {
                      const findCollection = catalogOfAllAssays
                          .find(assay => +assay.id === +col && !assay.superGroupName);
                      if (!acum.find(item => +item.id === +findCollection.id)
                          && !src.find(item => +item.id === +findCollection.id) && findCollection) {
                          return [...acum, findCollection];
                      }
                      return acum;
                  }, []);*!/

                  arrayOfCollection.map(coll => {
                      newPrice += +coll.price;
                      return coll;
                  });

                  return [...acu, cur, ...arrayOfCollection];
              }*/
            return [...acu, cur];
        }, []);
        dispatch({
            type: ADD_ASSAY_TO_ORDER_LIST_CALCULATOR,
            payload: {
                assayPrice: newPrice,
                newOrderingList,
            },
        });
    };

export const removeAssayFromOrderListCalculator =
    (assay, assayPrice) => (dispatch) => {
        const orderingList = store.getState().calculator.orderingList;
        const {catalog, actions, complexResearch} =
            store.getState().assaysCatalog;
        let newOrderingList = [];
        let newPrice = +assayPrice.toFixed(2);
        let foundItem = assay.take;

        if (foundItem && foundItem.length > 0) {
            foundItem = foundItem.filter((item) => item !== "");
        }

        if (!foundItem || foundItem.length === 0) {
            newOrderingList = orderingList.filter(
                (currId) => !(currId.id === assay.id && currId.type === assay.type)
            );
        } else {
            const orderedAssaysTake = orderingList
                .reduce((acu, cur) => {
                    if (cur.take) {
                        return [...acu, ...cur.take];
                    }

                    return acu;
                }, [])
                .filter((item) => item !== "");

            assay.take
                .filter((item) => item !== "")
                .forEach((takeAssay) => {
                    const similarTake = orderedAssaysTake.filter(
                        (item) => +item === +takeAssay
                    );
                    if (similarTake.length === 1) {
                        newOrderingList = newOrderingList =
                            newOrderingList.length > 0
                                ? newOrderingList.filter(
                                (currId) =>
                                    !(
                                        (+currId.id === +assay.id &&
                                            currId.type === assay.type) ||
                                        +currId.id === +takeAssay
                                    )
                                )
                                : orderingList.filter(
                                (currId) =>
                                    !(
                                        (+currId.id === +assay.id &&
                                            currId.type === assay.type) ||
                                        +currId.id === +takeAssay
                                    )
                                );
                        similarTake.forEach(() => {
                            const price = 0;
                            /*catalogOfAllAssays.find(item => +item.id === +assay && !item.superGroupName)
                                      ? catalogOfAllAssays.find(item => +item.id === +assay && !item.superGroupName).price
                                      : 0*/

                            newPrice += +price;
                        });
                    } else {
                        newOrderingList =
                            newOrderingList.length > 0
                                ? newOrderingList.filter(
                                (currId) =>
                                    !(currId.id === assay.id && currId.type === assay.type)
                                )
                                : orderingList.filter(
                                (currId) =>
                                    !(currId.id === assay.id && currId.type === assay.type)
                                );
                    }
                });
        }

        dispatch({
            type: REMOVE_ASSAY_FROM_ORDER_LIST_CALCULATOR,
            payload: {
                assayPrice: newPrice,
                orderingList: newOrderingList,
            },
        });
        dispatch({
            type: REMOVE_ASSAY_FROM_ORDER_LIST,
            payload: {
                assayPrice: newPrice,
                orderingList: newOrderingList,
            },
        });
    };

export const clearOrderedAssaysCalculator = () => ({
    type: CLEAR_ORDERED_ASSAYS_CALCULATOR,
});

export const setBasketCalculator = (value) => (dispatch) => {
    dispatch({
        type: SET_BASKET_CALCULATOR,
        payload: value,
    });
};

export function setAdditionalDataCalculator(data) {
    return async (dispatch) => {
        dispatch({
            type: SET_ADDITIONAL_DATA_CALCULATOR,
            payload: data,
        });
    };
}

export function setFullOrderData(data) {
    return async (dispatch) => {
        dispatch({
            type: SET_FULL_ORDER_DATA_CALCULATOR,
            payload: data,
        });
    };
}

export function setFindParam(data) {
    return async (dispatch) => {
        dispatch({
            type: SET_FIND_PARAM,
            payload: data,
        });
    };
}

export const getNursesList = () => async (dispatch) => {
    const {nurses} = store.getState().calculator;

    if (!nurses) {
        const nursesList = await getNurses();

        if (nursesList) {
            dispatch({
                type: SET_NURSES_CALCULATOR,
                payload: nursesList,
            });
        }
    }
};

export const setActiveNurse = (nurseId) => (dispatch) => {
    dispatch({
        type: SET_ACTIVE_NURSE,
        payload: nurseId,
    });
};

export const setOrderVials = (vials) => (dispatch) => {
    dispatch({
        type: SET_ORDER_VIALS,
        payload: vials,
    });
};

export const updateOrder = () => (dispatch) => {
    const {vials} = store.getState().calculator;
    const {themeMaterialUI} = store.getState().theme;

    if (vials && vials.length) {
        vials.forEach(async (tube) => {
            await updateTubes({
                ...tube,
                idPers: tube.selectedNurseId || 0,
                dt: moment(tube.selectedDate).format("YYYY-MM-DD kk:mm"),
            });
        });

        dispatch({
            type: UPDATE_TUBES_ON_SERVER,
        });
        dispatch(setActiveStep(0));
        // CustomDialog(TextAlertDialogCalculator, {text: 'Замовлення успішно оновлено. Відскануйте наступне, щоб продовжити роботу.'}, false, themeMaterialUI);
    } else {
        CustomDialog(
            TextAlertDialogCalculator,
            {text: "Виникла помилка."},
            false,
            themeMaterialUI
        );
    }
};

export const updateTubesOnServer = () => () => {
    const {vials} = store.getState().calculator;
    const {themeMaterialUI} = store.getState().theme;

    if (vials && vials.length) {
        vials.forEach(async (tube) => {
            await updateTubes({
                ...tube,
                idPers: tube.selectedNurseId || 0,
                dt: moment(tube.selectedDate).format("YYYY-MM-DD kk:mm"),
            });
        });
    } else {
        CustomDialog(
            TextAlertDialogCalculator,
            {text: "Виникла помилка. Відсутні пробірки."},
            false,
            themeMaterialUI
        );
    }
};

export const toggleAutomaticPrintBarcodes = () => (dispatch) => {
    dispatch({
        type: TOGGLE_AUTOMATIC_PRINT_BARCODES,
    });
};

export const setSelectedAgents = (agent) => (dispatch) => {
    dispatch({
        type: SET_SELECTED_AGENTS,
        payload: agent,
    });
};

export const getCatalogVdoma = (reload, setNewCatalog) => async (dispatch) => {
    const {catalog, takeId, complexId} = store.getState().assaysCatalog;
    const isPartner = !!process.env.REACT_APP_API_PATH;
    const isDoctor = localStorage.getItem("isDoctor");
    if (reload) {
        dispatch({
            type: SET_CATALOG,
            payload: {
                catalog: null,
                complex: null,
                actions: null,
            },
        });
    }

    if ((!catalog || reload || setNewCatalog) && takeId && complexId) {
        const catalogFromApi =
            (+takeId === 216 || +takeId === 51) && !isDoctor && !isPartner
                ? await getPriceForRegion(takeId, complexId).catch((onerror) => {
                    console.error(onerror);
                    return "error";
                })
                : await getPrice(takeId, complexId).catch((onerror) => {
                    console.error(onerror);
                    return "error";
                });
        const complex = await getComplexOfAssays().catch((onerror) => {
            console.error(onerror);
            return "error";
        });
        const actions = await getActionsFromServer().catch((onerror) => {
            console.error(onerror);
            return "error";
        });

        if (catalogFromApi && complex && actions) {
            dispatch({
                type: SET_CATALOG,
                payload: {
                    catalog:
                        catalogFromApi !== "error"
                            ? catalogFromApi
                                .map((item) => {
                                    if (+item.id !== 125 && !item.superGroupName) {
                                        return {
                                            ...item,
                                            take: [125],
                                            discount: 0,
                                        };
                                    } else if (+item.id === 125 && !item.superGroupName) {
                                        return {
                                            ...item,
                                            take: [],
                                            discount: 0,
                                            opt: "{forman,forwoman,notdisc,public}",
                                        };
                                    } else {
                                        return item;
                                    }
                                })
                                .filter(
                                    (item) =>
                                        (!item.notSale &&
                                            (item.opt ? item.opt.includes("public") : true)) ||
                                        isPartner
                                )
                                .filter(
                                    (item) => +item.id !== 802290141 && +item.id !== 802289931
                                ) //covid packets crunch
                            : catalogFromApi,
                    complex: complex
                        .map((item) => ({...item, discount: 0}))
                        .map((item) => {
                            if (+item.id !== 125 && !item.superGroupName) {
                                return {
                                    ...item,
                                    take: [125],
                                    discount: 0,
                                };
                            } else if (+item.id === 125 && !item.superGroupName) {
                                return {
                                    ...item,
                                    take: [],
                                    discount: 0,
                                    opt: "{forman,forwoman,notdisc,public}",
                                };
                            } else {
                                return item;
                            }
                        }),
                    actions:
                        typeof actions === "string"
                            ? actions
                            : +takeId === 216
                            ? actions
                                .filter((item) => item.active === "Y")
                                .map((action) => {
                                    const foundComplex = [...complex, ...catalogFromApi].find(
                                        (com) => com.id === action.idGrTest
                                    );

                                    return {...foundComplex, ...action, name: action.title};
                                })
                            : actions
                                .filter((item) => item.active === "Y")
                                .map((action) => ({...action, name: action.title})),
                },
            });
        }
    }
};

export const setCabinetActiveTab = (path) => (dispatch) => {
    dispatch({
        type: SET_ACTIVE_TAB,
        payload: {
            path,
        },
    });
};

export const setIsAdmin = (isAdmin) => (dispatch) => {
    localStorage.setItem("isAdmin", "true");

    dispatch({
        type: SET_IS_ADMIN,
        payload: {
            isAdmin,
        },
    });
};
