export const isEnabledHour = ( punkt, dayNum, rangeId) => {
    const days = [ "sun", "mon", "tue", "wen", "ths", "fry", "sat" ];
    const scheduler = punkt.workingHours.find(schedDay => days[dayNum] in schedDay)
    if (scheduler) {
        if (scheduler[days[dayNum]].sceduler.indexOf(rangeId) >= 0) {

            return true;
        }
    }
}
