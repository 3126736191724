import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import logo from '../../assets/medis/medisLogoWithoutText.png';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
    },
    rootWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '50%',
        height: '100%',
        textAlign: 'center',
    },
    actionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',

        '@media (max-width: 900px)': {
            flexDirection: 'column',
        },
    },
    header: {
        color: theme.palette.error.light,
    },
}));

const PortmoneFailure = () => {
    const classes = useStyles();
    const hrefLocation = window.location.origin;


    const handleClickOnButton = (event, href) => {
        event.stopPropagation();
        event.preventDefault();
        window.top.location.href = `${hrefLocation}${href}`
    };

    return (
        <div className={classes.root}>
            <div className={classes.rootWrapper}>
                <h2 className={classes.header}>Операція відхилена.</h2>
                <img src={logo} alt="Logo medis." style={{width: '100px', margin: '0 auto'}}/>
                <p>
                    Спробуйте повторити оплату замовлення.
                </p>
                <div className={classes.actionWrapper}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={event => handleClickOnButton(event, '/shop-about')}
                    >
                        Повернутись на головну
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={event => handleClickOnButton(event, '/account-settings')}
                    >
                        Особистий кабінет
                    </Button>
                </div>
            </div>
        </div>)
};

export default PortmoneFailure;
