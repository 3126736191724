import React, {Fragment, useCallback, useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiPhoneNumber from "material-ui-phone-number";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Close} from "@material-ui/icons";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MuiThemeProvider,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import Loading from "../Shared/Loading";
import TextField from "@material-ui/core/TextField";
import {useDispatch, useSelector} from "react-redux";
import uuidV4 from 'uuid/v4';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
    setAccessKeyForLogin,
    setClient,
    setCreatePatient,
    setListOfPatients,
    START_PHONE_SEND
} from "@cabinet-packages/redux";
import uaLocale from 'date-fns/locale/uk';
import {AccessKey, isLogin} from "@cabinet-packages/helpers";
import {editDoctorInfo, editPatientInfo, setExistingDoctors, setExistingPatients} from "@cabinet-packages/api/api";
import Pdf from '../../../projects/medis/src/assets/pdf/Договір публічної оферти_МеДіС.pdf';

const useStyles = makeStyles((theme) => ({
    DialogContent: {
        height: '100%',
    },
    rootWrapper: {
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '1056px',
        height: '90%',
    },
    headerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '@media (max-width: 900px)': {
            flexDirection: 'row',
        },
    },
    confirmPhone: {
        margin: '12px 0px',
    },
    innerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        margin: '0 auto',
        padding: '10px 0',
    },
    phoneConfirmation: {
        height: '100%',
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    phoneConfirmationInput: {
        maxWidth: '300px',
        width: '100%',
    },
    phoneConfirmationButton: {
        marginTop: '20px',
    },
    phoneCancellationButton: {
        marginTop: '4px',
    },
    errorCodeTitle: {
        marginBottom: '10px',
        color: 'red'
    },
    resultTitleBlock: {
        display: 'flex',
        paddingBottom: '10px'
    },
    resultTitleBlockTitle: {
        padding: '20px 0',
        '@media (max-width: 900px)': {
            display: 'flex',
            alignItems: 'center',
        }
    },
    wrapperCode: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
    },
    wrapper: {
        display: 'flex',
    },
    confirmTitle: {
        paddingTop: '20px',
        color: theme.palette.primary.dark,
        fontSize: '18px',
    },
    listItem: {
        background: 'rgba(0, 0, 0, 0.05)',
    },
    phoneConfirmationCloseButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    createClientWrapper: {
        height: 'max-content',
        display: 'grid',
        width: '100%',
        maxWidth: '100%',
    },
    bodyContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    selectActivePatientWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '24px',
    },
    selectActivePatient: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        background: 'rgba(0, 0, 0, 0.05)',
        marginBottom: '2px',
        padding: '5px',
    },
    selectActivePatientHeader: {
        padding: '4px',
        fontWeight: 500,
        color: theme.palette.primary.dark,
    },
    selectActivePatientText: {
        fontWeight: 500,
    },
    selectActivePatientButtonLink: {
        padding: '0',
    },
    selectActivePatientListWrapper: {
        listStyle: 'none',
        width: '100%',
    },
    actionContainer: {
        margin: '10px',
        display: 'flex',
        justifyContent: 'center',
        '@media (max-width: 900px)': {
            margin: '5px',
        },
    },
    birthdayContainer: {
        padding: '4px 0',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 1fr',
        gridGap: '5px',
    },
    actionContainerButton: {
        maxHeight: '70px',
        '@media (max-width: 900px)': {
            margin: '5px',
        },
    },
    formAnnotation: {
        fontSize: '11px',
        color: theme.palette.secondary.light,
    },
    formCheckboxes: {
        height: '120px',
        '@media (max-width: 900px)': {
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
    },
}));

const PhoneEnterDialog = ({onSendPhone, onSendCode, handleClose, goBack, onSignUp, themeMaterialUI, isDoctor, historyPush}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isChangedState, setIsChangedState] = useState(true);
    const [disableConfirmSMS, setDisableConfirmSMS] = useState(true);
    const [phone, setPhone] = useState('');
    const [value, setValue] = useState('');
    const [uuid] = useState(uuidV4());
    const {stage, error, createPatient, activePatient, accessKeyForLogin, listOfPatients} = useSelector(state => state.patients); //here
    const {takeId} = useSelector(state => state.assaysCatalog);
    const [state, setState] = useState(null);
    const [isSetPhone, setIsSetPhone] = useState(true);
    const [isChangedStateSignUp, setIsChangedStateSignUp] = useState(true);
    const [isSetActivePatient, setIsSetActivePatient] = useState(false);
    const [checkboxState, setCheckboxState] = useState({
        checkedA: false,
        checkedB: false,
    });
    const [valueActivePatient, setValueActivePatient] = useState(null);
    const [valueActivePatientFormControl, setValueActivePatientFormControl] = useState(null);
    const [checked, setChecked] = useState([]);
    const [isUserCheckedPatients, setIsUserCheckedPatients] = useState(false);
    const [isChangedStateOnActivePatient, setIsChangedStateOnActivePatient] = useState(true);
    const [loadingForLogin, setLoadingForLogin] = useState(false);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (!isUserCheckedPatients) setIsUserCheckedPatients(true);

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleChangeActivePatient = (event) => {
        setValueActivePatientFormControl(+event.target.value);
        const foundPatient = checked.find(patient => +patient.id === +event.target.value);
        setValueActivePatient({...foundPatient, birthday: new Date(foundPatient.birthday)});
    };

    const handleChange = (event) => {
        setCheckboxState({ ...checkboxState, [event.target.name]: event.target.checked });
    };
    
    const updateState = useCallback((field, value) => {
        setState(prev => ({
            ...prev,
            [field]: value,
        }));
        dispatch(setCreatePatient(state));
    },[dispatch, state]);

    const updateStateActivePatient = useCallback((field, value) => {
        setIsChangedStateOnActivePatient(false);
        setValueActivePatient(prev => ({
            ...prev,
            [field]: value,
        }));
    },[dispatch, state]);

    const handleCancel = () => {
        if (accessKeyForLogin) dispatch(setAccessKeyForLogin(null));
        AccessKey.remove();
        handleClose();
        dispatch({
            type: START_PHONE_SEND,
            payload: {
                stage : 1,
            },
        });
    };

    const handleCloseAfterEnter = useCallback(() => {
        if (stage === 6) {
            dispatch({
                type: START_PHONE_SEND,
                payload: {
                    stage : 1,
                },
            });
            handleClose()

            historyPush()
            window.location.reload()
        }
    },[stage, handleClose]);

    const handleConfirm = (e, formData) => {
        onSendPhone(uuid, formData);
        setValue('');
    };

    const handleConfirmSMS = async () => {
        localStorage.setItem("activePatientPhone", phone.replace(/[[()\s]/g, ''));
        const waiting = await onSendCode(uuid, value);
        if (!waiting) {
            setPhone('');
        }
    };

    const backAuth = () => {
       goBack();
    };

    const handleSignUp = () => {
        onSignUp(state, uuid);
    };

    const handleSignIn = async () => {
        setLoadingForLogin(true);
        try {
            dispatch(setListOfPatients(false, isDoctor));
            const result = isDoctor
                ? await setExistingDoctors(valueActivePatient.id,
                    checked.map(patient => patient.id).filter(id => +id !== +valueActivePatient.id),
                    phone.replace(/[[()\s]/g, ''),
                    checkboxState.checkedA, checkboxState.checkedB)
                : await setExistingPatients(
                    valueActivePatient.id,
                    checked.map(patient => patient.id).filter(id => +id !== +valueActivePatient.id),
                    phone.replace(/[[()\s]/g, ''),
                    checkboxState.checkedA,
                    checkboxState.checkedB,
                    false,
                    '');
            AccessKey.set(isDoctor ? result : result?.token)
            const birthday = typeof valueActivePatient.birthday === 'string'
                ? valueActivePatient.birthday
                : !isDoctor && new Date(valueActivePatient.birthday.toString()).toLocaleDateString().split('.').reverse().join('-');
            isDoctor
                ? await editDoctorInfo(valueActivePatient.lastname,
                    valueActivePatient.firstname,
                    valueActivePatient.fathername,
                    '2000-12-12',
                    valueActivePatient.sex,
                    valueActivePatient.id)
                : await editPatientInfo(valueActivePatient.lastname,
                    valueActivePatient.firstname,
                    valueActivePatient.fathername,
                    birthday,
                    valueActivePatient.sex,
                    valueActivePatient.id);
            dispatch(setClient({...valueActivePatient, birthday}));
            dispatch({
                type: START_PHONE_SEND,
                payload: {
                    stage: 6,
                },
            });
        } catch (e) {
            console.error(e);
            setLoadingForLogin(false);
        }
    };

    const handleClickForChooseActualPatients = () => {
        dispatch({
            type: START_PHONE_SEND,
            payload: {
                stage : 8,
            },
        })
    };

    useEffect(() => {
        if (uuid !== localStorage.getItem('uuid')) {
            localStorage.setItem('uuid', `${uuid}`);
        }
        if (state) {
            const {firstname, lastname, birthday} = state;
            if (firstname && lastname && birthday && firstname.length && lastname.length && (isDoctor || birthday && !isNaN(birthday.valueOf()))) {
                setIsChangedStateSignUp(false)
            } else {
                setIsChangedStateSignUp(true)
            }
        }
        if (!valueActivePatientFormControl && checked.length > 0) {
            setValueActivePatientFormControl(checked[0].id);
        }
        if (valueActivePatient) {
            const {firstname, lastname, birthday} = valueActivePatient;

            if (firstname?.length && lastname?.length && (isDoctor || birthday && !isNaN(birthday.valueOf()))) {
                setIsChangedStateSignUp(false)
            } else {
                setIsChangedStateSignUp(true)
            }
        }
        if (phone && +phone.length === 19) {
            setIsChangedState(false)
        } else {
            setIsChangedState(true)
        }
        if (value && +value.length === 4 && value === value.replace(/\D/g, '').trim()) {
            setDisableConfirmSMS(false)
        } else {
            setDisableConfirmSMS(true)
        }
        if (activePatient && !isSetActivePatient) {
            dispatch(setCreatePatient(activePatient));
            setState(activePatient);
            setIsSetActivePatient(true);
        }
        if (!activePatient && !createPatient) dispatch(setCreatePatient());
        if (!state && createPatient) setState({
            ...createPatient,
            dt: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
            birthday: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        });
        if (phone && stage === 5 && isSetPhone) {
            updateState('phone', phone);
            setIsSetPhone(false);
        }
        if (checked && checked.length > 0 && !valueActivePatient) {
            setValueActivePatient({...checked[0], birthday: new Date(checked[0].birthday)});
        }
        if (stage && stage === 7 && listOfPatients && checked.length === 0 && !isUserCheckedPatients) {
            setChecked(listOfPatients);
        }
    }, [phone, value, dispatch, createPatient, isSetPhone, error, stage, state, updateState,
        valueActivePatient, checked, isChangedStateSignUp, isChangedStateOnActivePatient, isUserCheckedPatients,
        listOfPatients]);

    useEffect(() => {
        handleCloseAfterEnter();
    }, [handleCloseAfterEnter]);

    if (stage === 2 || stage === 4) {
        return <Dialog
            open={true}>
            <DialogContent className={classes.DialogContent}>
                <div style={{marginBottom: '10px'}}>
                    <Loading/>
                </div>
            </DialogContent>
        </Dialog>
    }

    return (
        <MuiThemeProvider theme={themeMaterialUI}>
        <Dialog open={!(stage === 1 && isLogin())}>
            <DialogContent className={classes.DialogContent}>
                <div className={classes.phoneConfirmation}>
                    {error && (!stage || stage === 1) && <>{error === 'errorWhenSendPhone'
                        ? <div className={classes.errorCodeTitle}><h4>Повторіть спробу.</h4></div>
                        : error === 'User not found'
                            ? <div className={classes.errorCodeTitle}><h4>Користувача не знайдено.</h4></div>
                            : <div className={classes.errorCodeTitle}><h4>Код введено неправильно.</h4></div>}</>}
                    {((stage && stage === 1) || !stage) &&
                    <Fragment>
                        <div className={classes.wrapper}>
                            <MuiPhoneNumber
                                autoFocus={true}
                                defaultCountry='ua'
                                countryCodeEditable={false}
                                regions='europe'
                                label="Номер телефону*"
                                value={phone}
                                onChange={(value) => setPhone(value)}
                            />
                            <IconButton onClick={handleCancel} className={classes.phoneConfirmationCloseButton}><Close/></IconButton>
                        </div>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.phoneConfirmationButton}
                            onClick={(e) => handleConfirm(e, phone)}
                            disabled={isChangedState}
                        >
                            Отримати код
                        </Button>
                        {takeId === 14 && <Button
                            variant="contained"
                            color="secondary"
                            className={classes.phoneCancellationButton}
                            onClick={handleCancel}
                        >
                            Скасувати
                        </Button>}
                    </Fragment>}

                    {stage && stage === 3 &&
                    <Fragment>
                        <h2 className={classes.confirmTitle}>Код відправлено на номер<sup>*</sup></h2>
                        <IconButton onClick={handleCancel} className={classes.phoneConfirmationCloseButton}><Close/></IconButton>
                        <p className={classes.confirmPhone}>{phone}</p>
                        <div className={classes.wrapperCode}>
                            <TextField
                                label="Ведіть код"
                                variant="outlined"
                                autoFocus={true}
                                value={value}
                                inputProps={{
                                    maxLength: 4,
                                }}
                                onChange={(event) => setValue(event.target.value.replace(/[^0-9]/g, ''))}
                            />
                        </div>
                        <Typography variant="caption">* - код надійде у Viber або SMS-повідомленням.</Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.phoneConfirmationButton}
                            onClick={handleConfirmSMS}
                            disabled={disableConfirmSMS}>
                            Відправити код
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.phoneConfirmationButton}
                            onClick={backAuth}
                        >
                            Повернутися назад
                        </Button>

                    </Fragment>}
                    {stage && state && stage === 5 &&
                    <div className={classes.createClientWrapper}>
                        <IconButton onClick={handleCancel} className={classes.phoneConfirmationCloseButton}><Close/></IconButton>
                        <div className={classes.bodyContainer}>
                            <TextField
                                value={state.lastname}
                                onChange={(event) => updateState('lastname', event.target.value)}
                                label="Прізвище*"
                            />
                            <TextField
                                value={state.firstname}
                                onChange={(event) => updateState('firstname', event.target.value)}
                                label="Ім'я*"
                            />
                            <TextField
                                value={state.fathername}
                                onChange={(event) => updateState('fathername', event.target.value)}
                                label="По-батькові"
                            />
                            {isDoctor
                                ? <div/>
                                : <div className={classes.birthdayContainer}>
                                        <p style={{color: 'rgba(0, 0, 0, 0.54)'}}>Дата народження*</p>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={uaLocale}>
                                            <KeyboardDatePicker
                                                value={state.birthday}
                                                onChange={(value) => {
                                                    updateState('birthday', value)
                                                }}
                                                invalidDateMessage="Неправильний формат дати."
                                                maxDateMessage="Дата народження не може бути в майбутньому."
                                                format="dd-MM-yyyy"
                                                disableFuture
                                                // maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
                                                cancelLabel="Назад"
                                                okLabel="Ок"
                                                openTo="year"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                            }
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Стать*</FormLabel>
                                <RadioGroup
                                    value={state.sex}
                                    onChange={(event) => updateState('sex', event.target.value)}
                                    className={classes.genderGroup}>
                                    <FormControlLabel
                                        value="F"
                                        control={<Radio color="primary"/>}
                                        label="Жіноча"
                                    />
                                    <FormControlLabel
                                        value="M"
                                        control={<Radio color="primary"/>}
                                        label="Чоловіча"
                                    />
                                </RadioGroup>
                            </FormControl>
                            <TextField
                                value={state.email}
                                onChange={(event) => updateState('email', event.target.value)}
                                label="Електронна пошта"
                                type="email"
                            />
                            <p className={classes.formAnnotation}>(*) Поля позначені зірочкою обов'язкові для
                                заповнення.</p>
                        </div>
                        <div className={classes.actionContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSignUp}
                                className={classes.actionContainerButton}
                                disabled={
                                    isChangedStateSignUp
                                }
                            >
                                Додати свої дані
                            </Button>
                        </div>
                    </div>}
                    {stage && stage === 7 && listOfPatients ?
                        <div className={classes.createClientWrapper}>
                            <h2 className={classes.confirmTitle}>
                                {isDoctor
                                    ? 'Виберіть лікаря для актуалізації даних:'
                                    : 'Виберіть пацієнтів, що є актуальними для показу в особистому кабінеті:'}
                            </h2>
                            <IconButton onClick={handleCancel} className={classes.phoneConfirmationCloseButton}><Close/></IconButton>
                            <List className={classes.list} dense component="div" role="list">
                                {listOfPatients && listOfPatients.map((value) => {
                                    const labelId = `transfer-list-all-item-${value.id}-label`;

                                    return (
                                        <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)} className={classes.listItem}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={checked.indexOf(value) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={`${value.lastname} ${value.firstname} ${value.fathername}`} />
                                        </ListItem>
                                    );
                                })}
                                <ListItem />
                            </List>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClickForChooseActualPatients}
                                className={classes.actionContainerButton}
                                disabled={checked.length === 0}
                            >
                                Підтвердити вибір
                            </Button>
                        </div>
                        : stage && stage === 7 && <Loading />
                    }
                    {stage && state && stage === 8 &&
                    <div className={classes.createClientWrapper}>
                        <IconButton onClick={handleCancel} className={classes.phoneConfirmationCloseButton}><Close/></IconButton>
                        <div className={classes.selectActivePatientWrapper}>
                            <div className={classes.selectActivePatient}>
                                {checked && <FormControl component="div">
                                    <FormLabel component="legend" className={classes.selectActivePatientHeader}>
                                        {isDoctor
                                            ? 'Вибір даних лікаря'
                                            : 'Вибір головного пацієнта особистого кабінету:'}
                                    </FormLabel>
                                    <RadioGroup
                                        aria-label="activePatient"
                                        name="activePatient1"
                                        onChange={handleChangeActivePatient}
                                        value={valueActivePatientFormControl}
                                    >
                                        {checked.map(patient => (
                                            <FormControlLabel
                                                key={patient.id}
                                                value={patient.id}
                                                control={<Radio color={"primary"}/>}
                                                label={`${patient.lastname} ${patient.firstname} ${patient.fathername}`}
                                            />))
                                        }
                                    </RadioGroup>
                                </FormControl>}
                            </div>
                        </div>
                        {valueActivePatient && <div className={classes.bodyContainer}>
                            <TextField
                                value={valueActivePatient.lastname}
                                onChange={(event) => updateStateActivePatient('lastname', event.target.value)}
                                label="Прізвище*"
                            />
                            <TextField
                                value={valueActivePatient.firstname}
                                onChange={(event) => updateStateActivePatient('firstname', event.target.value)}
                                label="Ім'я*"
                            />
                            <TextField
                                value={valueActivePatient.fathername}
                                onChange={(event) => updateStateActivePatient('fathername', event.target.value)}
                                label="По-батькові"
                            />
                            <div className={classes.birthdayContainer}>
                                {!isDoctor && <p style={{color: 'rgba(0, 0, 0, 0.54)'}}>Дата народження*</p>}
                                {!isDoctor && <MuiPickersUtilsProvider utils={DateFnsUtils} locale={uaLocale}>
                                    <KeyboardDatePicker
                                        value={valueActivePatient.birthday}
                                        onChange={(value) => updateStateActivePatient('birthday', value)}
                                        invalidDateMessage="Неправильний формат дати."
                                        // maxDateMessage="Дата народження не може бути в майбутньому."
                                        // maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
                                        format="dd-MM-yyyy"
                                        disableFuture
                                        cancelLabel="Назад"
                                        okLabel="Ок"
                                        openTo="year"
                                    />
                                </MuiPickersUtilsProvider>}
                            </div>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Стать*</FormLabel>
                                <RadioGroup
                                    aria-label="gender"
                                    value={valueActivePatient.sex}
                                    onChange={(event) => updateStateActivePatient('sex', event.target.value)}
                                    className={classes.genderGroup}>
                                    <FormControlLabel
                                        value="F"
                                        control={<Radio color="primary"/>}
                                        label="Жіноча"
                                    />
                                    <FormControlLabel
                                        value="M"
                                        control={<Radio color="primary"/>}
                                        label="Чоловіча"
                                    />
                                </RadioGroup>
                            </FormControl>
                            <p className={classes.formAnnotation}>(*) Поля позначені зірочкою обов'язкові для
                                заповнення.</p>
                        </div>}
                        <FormGroup className={classes.formCheckboxes}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkboxState.checkedA}
                                        onChange={handleChange}
                                        name="checkedA"
                                        color="primary"
                                    />
                                }
                                label={<p>
                                        Я погоджуюсь на використання <a href={takeId === 14 ? Pdf : undefined} target="_blank">
                                            персональних даних.
                                    </a>
                                    </p>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkboxState.checkedB}
                                        onChange={handleChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Я погоджуюсь на створення персонального кабінету."
                            />
                        </FormGroup>
                        <div className={classes.actionContainer}>
                            {loadingForLogin 
                                ? <Loading/>
                                : <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSignIn}
                                    className={classes.actionContainerButton}
                                    disabled={isChangedStateSignUp || !checkboxState.checkedA || !checkboxState.checkedB}
                                >
                                    Підтвердити свої дані
                                </Button>}
                        </div>
                    </div>}
                </div>
            </DialogContent>
        </Dialog>
        </MuiThemeProvider>
    );
};

export default PhoneEnterDialog;
