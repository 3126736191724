import React from "react";
import {Tooltip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 'max-content',
        borderRadius: '10px',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        boxShadow: '0px -2px 10px 0px #363D4D88',
    },
    heading: {
        width: '100%',
        textAlign: "center",
        backgroundColor: theme.palette.colors.background,
        borderRadius: '10px 10px 0px 0px',
        color: 'white',
        fontSize: '20px',
        padding: theme.spacing(2),
    },
    text: {
        marginTop: theme.spacing(2),
        color: theme.palette.grey.price,
        fontSize: '16px',
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        paddingBottom: theme.spacing(2),
        textAlign: 'center',
        fontWeight: 600,
        lineHeight: 1.43,
    },
}));

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.white,
    },
    tooltip: {
        backgroundColor: 'transparent',
        height: 'max-content',
        padding: 0
    },
}));

const TooltipMedis = ({children, heading, text, open}) => {
    const classes = useStylesBootstrap();
    const cl = useStyles();

    return (open
        ? <Tooltip arrow classes={classes} placement="top"
                        title={<div className={cl.root}>
                                <div className={cl.heading}>
                                    {heading}
                                </div>
                                <p className={cl.text}>
                                    {text}
                                </p>
                        </div>}
        >
            {children}
        </Tooltip>
        : (<>{children}</>));
};

export default TooltipMedis;
