import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    rootPrintStickers: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: theme.spacing(1),
    },
    printStickersHeader: {
        width: '100%',
        height: '37px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    printStickersField: {
        margin: '8px 0px'
    },
    error: {
        color: 'red',
        textAlign: "center",
        margin: theme.spacing(1)
    },
}));

export default useStyles;
