import React, {useState} from "react";
import useStyles from "../styles";
import TextField from "@material-ui/core/TextField";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Slide, Snackbar} from "@material-ui/core";
import uaLocale from 'date-fns/locale/uk';
import DateFnsUtils from "@date-io/date-fns";
import {setListOfPatients, START_PHONE_SEND} from "@cabinet-packages/redux";
import {useDispatch} from "react-redux";
import {getPatients, setCreateClient} from "@cabinet-packages/api";
import {AccessKey} from "@cabinet-packages/helpers";
import moment from "moment";
import {SwipeStyledButton} from "@cabinet-packages/components";

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

const FifthStage = ({isDoctor, state, isChangedStateSignUp, uuid, updateState}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [snackBarText, setSnackbarText] = useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackbarText('');
    };

    const onSignUp = async (patient, uuid) => {
        if (!isChangedStateSignUp) {
            const {lastname, firstname, fathername, birthday, sex, phone, email} = patient;
            const birthdayDate = typeof birthday === 'string' ? birthday : moment(birthday).format('YYYY-MM-DD');
            const result = await setCreateClient(uuid, lastname.trim(), firstname.trim(), fathername.trim(), birthdayDate, sex,
                localStorage.getItem("unregisteredCabinet") ? localStorage.getItem('activePatientPhone'):  phone.replace(/[[()\s]/g, ''), email);
            // await registerNew(uuid, lastname.trim(), firstname.trim(), fathername.trim(), birthdayDate, sex, phone.replace(/[[()\s]/g, ''), email);
            AccessKey.set(result.token);
            if (result) {
                dispatch({
                    type: START_PHONE_SEND,
                    payload: {
                        stage: 8,
                    },
                });
                const patients = await getPatients();
                // await checkPatient('CHECK', uuid, null, phone, AccessKey.get());

                dispatch(setListOfPatients(false, isDoctor, patients));
            }
        } else {
            setOpen(true);
            setSnackbarText('Введіть свої дані, щоб продовжити реєстрацію.');
        }
    }

    return (
        <div className={cl.createClientWrapper}>
            <div className={cl.bodyContainer}>
                <TextField
                    className={cl.textField}
                    value={state.lastname}
                    variant="outlined"
                    onChange={(event) => updateState('lastname', event.target.value)}
                    label="Прізвище*"
                />
                <TextField
                    className={cl.textField}
                    value={state.firstname}
                    variant="outlined"
                    onChange={(event) => updateState('firstname', event.target.value)}
                    label="Ім'я*"
                />
                <TextField
                    className={cl.textField}
                    value={state.fathername}
                    variant="outlined"
                    onChange={(event) => updateState('fathername', event.target.value)}
                    label="По-батькові*"
                />
                {isDoctor
                    ? <div/>
                    : <div className={cl.birthdayContainer}>
                        <p style={{color: 'rgba(0, 0, 0, 0.54)'}}>Дата народження*</p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={uaLocale}>
                            <KeyboardDatePicker
                                className={cl.textField}
                                inputVariant="outlined"
                                value={state.birthday}
                                onChange={(value) => {
                                    updateState('birthday', value)
                                }}
                                invalidDateMessage="Неправильний формат дати."
                                maxDateMessage="Дата народження не може бути в майбутньому."
                                format="yyyy-MM-dd"
                                disableFuture
                                // maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
                                cancelLabel="Назад"
                                okLabel="Ок"
                                openTo="year"
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                }
                <FormControl component="fieldset" className={cl.textField}>
                    <FormLabel component="legend">Стать*</FormLabel>
                    <RadioGroup
                        value={state.sex}
                        onChange={(event) => updateState('sex', event.target.value)}
                        className={cl.genderGroup}>
                        <FormControlLabel
                            value="F"
                            control={<Radio color="primary"/>}
                            label="Жіноча"
                        />
                        <FormControlLabel
                            value="M"
                            control={<Radio color="primary"/>}
                            label="Чоловіча"
                        />
                    </RadioGroup>
                </FormControl>
                <TextField
                    className={cl.textField}
                    variant="outlined"
                    value={state.email}
                    onChange={(event) => updateState('email', event.target.value)}
                    label="Електронна пошта*"
                    type="email"
                />
                <p className={cl.formAnnotation}>(*) Поля позначені зірочкою обов'язкові для
                    заповнення.</p>
            </div>
            <div className={cl.actionContainer}>
                <SwipeStyledButton
                    className={cl.swipeStyledButton}
                    animationStart={'left'}
                    onClick={() => onSignUp(state, uuid)}
                >
                    Додати свої дані
                </SwipeStyledButton>
                <Snackbar
                    ContentProps={{
                        classes: {
                            root: cl.snackbar,
                            message: cl.snackbar
                        }
                    }}
                    TransitionComponent={TransitionUp}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    message={<div className={cl.snackbarContentWrapper}>
                        <div className={cl.snackbarContentHeader}>
                            Увага!
                        </div>
                        <div className={cl.snackbarContentText}>
                            {snackBarText && snackBarText}
                        </div>
                    </div >}
                >
                </Snackbar>
            </div>
        </div>
    );
};

export default FifthStage;
