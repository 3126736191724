import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import QRCode from 'qrcode.react';
import {SwipeStyledButton} from "../index";

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    '@media(max-width: 900px)': {
      width: '100%',
      height: 'min-content'
    }
  },
  dialogContent: {
    width: 'min-content',
    margin: '30px',
    '@media(max-width: 900px)': {
      margin: '10px auto'
    }
  },
  codeBlock: {
    padding: '30px',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    borderRadius: '20px',
    width: 'min-content',
    margin: 'auto'
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.dark,
    textAlign: 'center',
    color: 'white',
  },
  dialogText: {
    fontWeight: '500',
    fontSize: '15px',
    //width: '40%',
    '@media(max-width: 900px)': {
        fontSize: '17px'
    },
    textAlign: 'center',
    marginTop: '30px'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0'
  }
}));

export default function QRCodeDialog({ handleClose, QRCodeValue }) {
  const classes = useStyles();
  const mdUp = useMediaQuery((theme => theme.breakpoints.up('md')))

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      classes={{paper: classes.dialogContainer}}
      PaperProps={{
        style: { borderRadius: 10}
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        ВАШ QR Code
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.codeBlock}>
          <QRCode
              renderAs="svg"
              size={mdUp ? 300 : 200}
              value={QRCodeValue}
          />
        </div>
        <Typography className={classes.dialogText}>
          Покажіть його на рецепції, щоб ми отримали інформацію про вас
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <SwipeStyledButton
            animationStart={'right'}
            animationMaterialColor={'#ff2121'}
            buttonMaterialColor={'#ffffff'}
            hoverTextColor={'#ffffff'}
            border={"1px solid #0E4E4E"}
            onClick={() => handleClose()}
        >
          СКАСУВАТИ
        </SwipeStyledButton>
      </DialogActions>
    </Dialog>
  );
}