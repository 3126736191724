export const isDateInPresentWeek = (presentWeekDate, curScheduledDate, isUserSelectDay) => {
    const scheduledDate = curScheduledDate.split('.');
    let correctDate = new Date([scheduledDate[2].split(' ')[0], scheduledDate[1], scheduledDate[0]].join('-')).toISOString().slice(0, 10);
    let curr = new Date(presentWeekDate);
    let week = [];


    for (let i = 0; i <= 6; i++) {
        let day = null;
        if (isUserSelectDay) {
            day = new Date (curr);
            day.setDate(day.getDate() + 1 + i);
        } else {
            day = new Date (curr);
            day.setDate(day.getDate() + i);
        }
        week.push(day.toISOString().slice(0, 10));
    }

    return !!week.includes(correctDate);
};

export const currentWeek = (presentWeekDate, isUserSelectDay) => {
    let curr = new Date(presentWeekDate);
    let week = [];

    for (let i = 0; i <= 6; i++) {
        let day = null;
        if (isUserSelectDay) {
            day = new Date (curr);
            day.setDate(day.getDate() + 1 + i);
        } else {
            day = new Date (curr);
            day.setDate(day.getDate() + i);
        }
        week.push(day.toISOString().slice(0, 10));
    }

    return week;
};
