import React from "react";
import {makeStyles} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        '@media (max-width: 900px)': {
            overflow: 'auto',
        },
    },
    wrapper: {
        fontSize: '1.125rem',
        color: '#214572',
        margin: '2.75rem 7.1% 205px 7.1%',
        '& ul': {
            paddingLeft: 18,
        },
        '@media (min-width: 1200px) and (max-width: 1500px)': {
            marginLeft: '0 6.3%',
            marginRight: '0 6.3%',
        },
        '@media (min-width: 900px) and (max-width: 1100px)': {
            marginLeft: '0 3.812333333%',
            marginRight: '0 3.812333333%',
        },
        '@media (max-width: 900px)': {
            margin: '52px 0px 48px 18px',
            paddingRight: 18,
            height: '100vh',
        },
    },
    titleMain: {
        fontSize: '1.875rem',
        color: '#0077C8',
        'font-family': "'Museo Sans Cyrl 900'",
        marginBottom: '1.875rem',
        '@media (max-width: 900px)': {
            fontSize: ' 1.438rem',
            marginBottom: 20,
        },
    },
    mainText: {
        'font-family': "'Museo Sans Cyrl 700'",
    },
    title: {
        color: '#0077C8',
        'font-family': "'Museo Sans Cyrl 700'",
        fontSize: '1.563rem',
        marginTop: 45,
        marginBottom: 30,
        '@media (max-width: 900px)': {
            marginTop: 45,
            marginBottom: 20,
            fontSize: '1.25rem',
        },
    }
}))

const PublicOffer = () => {
    const classes = useStyles()
    return (
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <p className={classes.titleMain}>Публічний договір (оферта)</p>
                    <p className={classes.title}>Публічний договір про надання медичних послуг <br/> (публічна оферта)
                    </p>

                    <p><span className={classes.mainText}>Приватне підприємство «Перша соціальна медична лабораторія «Ескулаб»,</span> надалі
                        Виконавець, з однієї сторони, та фізична особа (представник фізичної особи) далі за текстом
                        Замовник (Пацієнт), яка замовляє платні медичні дослідження (послуги з лабораторної
                        діагностики) далі за текстом – Послуги за допомогою веб-сайту розміщеного в мережі
                        інтернет за адресою: <a href="https://esculab.com/">www.esculab.com </a>та здійснила акцепт (прийняття) умов даного
                        Договору з другої сторони, що надалі іменуються як Сторони, уклали даний Договір про
                        наступне:
                    </p>

                    <p className={classes.title}>1.Визначення понять</p>

                    <p><span className={classes.mainText}>Акцепт</span> – повне, безумовне та беззастережне прийняття
                        Замовником
                        умов публічної оферти даного Договору.
                        Акцепт здійснюється шляхом: оформлення замовлення на офіційному веб- сайті Виконавця за
                        інтернет-адресою: www.esculab.com, подальшого надання згоди на обробку персональних даних,
                        підтвердження
                        погодження з умовами цього Договору, ознайомлення з Правилами підготовки пацієнта до забору
                        біологічного
                        матеріалу та проведення оплати медичних послуг.</p>

                    <p><span className={classes.mainText}> Виконавець</span> – Приватне підприємство «Перша соціальна медична лабораторія «Ескулаб», створене у відповідності із
                        законодавством України, зареєстроване за адресою: 79069, м.Львів, вул. Шевченка, 313, код у
                        ЄДРПОУ:
                        36544827.</p>

                    <p><span className={classes.mainText}> Веб-сайт Виконавця</span> – веб-сторінка в мережі Інтернет,
                        яка
                        знаходиться за веб-адресою: www.esculab.com та
                        яка є офіційним джерелом інформування Замовників про Виконавця та про асортимент послуг, що ним
                        надаються.</p>

                    <p><span className={classes.mainText}> Замовник</span> – фізична особа (пацієнт), або її
                        представник,
                        які
                        володіють повною цивільною дієздатністю, та
                        розмістили на веб-сайті Виконавця за адресою: www.esculab.com, замовлення про надання Послуг. У
                        випадку,
                        якщо пацієнтом є особа, яка володіє неповною, частковою дієздатність, або дієздатність якої
                        обмежена,
                        замовлення послуги може бути здійснене лише особою, яка відповідно до закону, або договору має
                        повноваження представляти інтереси пацієнта.</p>

                    <p><span className={classes.mainText}>  Замовлення</span> – здійснений за допомогою веб-сайту
                        Виконавця
                        за
                        адресою: www.esculab.com запит Замовника на
                        отримання послуг Виконавця із зазначенням переліку послуг, які бажає отримати Замовник.</p>

                    <p><span className={classes.mainText}> Пацієнт</span> – фізична особа, якій Виконавцем відповідно до
                        Замовлення безпосередньо надаються Послуги.</p>

                    <p><span className={classes.mainText}>Послуга</span> – медична послуга, що надається Виконавцем
                        відповідно
                        до запиту, здійсненого за допомогою
                        веб-сайту Виконавця за адресою: www.esculab.com та включає в себе забір біологічного матеріалу
                        Пацієнта,
                        проведення лабораторного дослідження біологічного матеріалу Пацієнта та надання Пацієнту
                        результату
                        такого медичного дослідження.</p>

                    <p><span className={classes.mainText}>Публічна оферта</span> – пропозиція Виконавця (викладена на
                        веб-сайті
                        Виконавця за адресою: www.esculab.com ),
                        адресована необмеженому колу фізичних осіб, укласти даний Договір на визначених умовах.</p>

                    <p className={classes.title}> 2. Загальні положення</p>

                    <p> 2.1. Цей Договір є публічним договором, укладеним у відповідності до ст.633, ст. 634. ст.641
                        Цивільного
                        кодексу України, який вважається укладеним між Виконавцем з однієї сторони та Замовником з
                        іншої, з
                        моменту Акцепту останнім всіх без винятку умов та положень даного Договору.</p>

                    <p> 2.2. Для того щоб укласти Договір Замовник повинен здійснити акцепт, тобто надати свою згоду на
                        укладання договору на умовах, викладених в оферті. Безумовним підтвердженням прийняття умов
                        даного
                        Договору (акцепту) є здійснення Замовником дій щодо оформлення замовлення, підтвердження та
                        оплати
                        Послуг, що включені до Замовлення. З цього моменту даний Договір вважається укладеним без його
                        підписання.</p>

                    <p> 2.3. Умови цього Договору є однаковими та обов’язковими для всіх Замовників, які оформили
                        замовлення
                        на
                        отримання Послуг та здійснили оплату за допомогою на веб-сайту Виконавця за адресою:
                        www.esculab.com.</p>

                    <p className={classes.title}> 3. Предмет Договору</p>

                    <p> 3.1. В порядку та на умовах, визначених цим Договором, Виконавець зобов’язується відповідно до
                        замовлення Замовника, оформленого за допомогою веб-сайту Виконавця за адресою: www.esculab.com
                        надати
                        Замовнику оплачені ним Послуги, а Замовник зобов’язується прийняти вказані Послуги.</p>

                    <p> 3.2. Послуги надаються Виконавцем згідно діючих цін Виконавця, в установлені строки та порядку,
                        визначеному цим Договором, іншими внутрішніми стандартами, інструкціями та положеннями
                        Виконавця,
                        нормативно-правовими актами України.</p>

                    <p className={classes.title}> 4. Права та обов’язки сторін</p>

                    <p className={classes.mainText}>4.1. Замовник має право:</p>
                    <p> 4.1.2. Отримувати своєчасно Послуги належної якості.</p>
                    <p> 4.1.3. Отримувати необхідну та достовірну інформацію про Виконавця, його діяльність, обсяг
                        послуг,
                        що
                        ним надаються.</p>
                    <p> 4.1.4. Звертатись до Виконавця із пропозиціями щодо покращення процесу надання Послуг.</p>
                    <p> 4.1.5. Самостійно та на власний розсуд формувати перелік та обсяг послуг, які входять до
                        Замовлення.</p>
                    <p> 4.1.6. На скасування (анулювання) Замовлення у порядку, встановленому в п.п. 5.6, 5.7
                        Договору.</p>
                    <p> 4.1.7. На обслуговування в будь-якому пункті забору біологічного матеріалу, що знаходиться в
                        регіоні
                        (області України), обраному Замовником при оформленні замовлення.</p>

                    <p className={classes.mainText}> 4.2. Замовник зобов’язаний:</p>
                    <p> 4.2.1. Надавати Виконавцю достовірні особисті дані та іншу інформацію, необхідну для виконання
                        умов
                        даного Договору. У випадку, якщо Замовник діє в інтересах Пацієнта, Замовник зобов’язаний
                        надавати
                        повні
                        та достовірні відомості про Пацієнта.</p>
                    <p> 4.2.2. Забезпечити можливість проведення маніпуляції з забору біологічного матеріалу
                        працівниками
                        Виконавця біологічного матеріалу Пацієнта в приміщенні пункту протягом 30- ти календарних днів з
                        моменту
                        оформлення Замовлення на Послуги за допомогою веб-сайту. В разі невиконання Замовником даного
                        зобов’язання Виконавець має право анулювати його замовлення, при цьому, кошти оплачені за
                        Послугу не
                        повертається Замовнику.</p>
                    <p> 4.2.3. Належним чином виконувати умови Договору та інформувати Виконавця про всі обставини, що
                        перешкоджають такому виконанню.</p>
                    <p> 4.2.4. Надати в письмовій формі Виконавцю перед забором біологічного матеріалу Інформовану
                        добровільну
                        згоду на надання медичних послуг та обробку персональних даних (відповідно до Закону України
                        “Про
                        захист
                        персональних даних”).</p>
                    <p> 4.2.5. Ознайомитись з Правилами підготовки пацієнта до забору біологічного матеріалу, розміщених
                        на
                        сайті Виконавця, та чітко дотримуватись їх вимог. При цьому, Замовник усвідомлює, що
                        недотримання
                        ним
                        вимог цих Правил може вплинути на достовірність результатів лабораторних досліджень його
                        біологічного
                        матеріалу.</p>
                    <p> 4.2.6. Надати працівникам пункту забору біологічного матеріалу відповідний код, отриманий за
                        допомогою
                        SMS-повідомлення, який підтверджує факт прийняття замовлення до виконання та проведення оплати
                        за
                        послугу.</p>
                    <p> 4.2.7. Надати працівникам пункту забору біологічного матеріалу нотаріально посвідчену
                        довіреність на
                        представлення інтересів Пацієнта щодо отримання результатів досліджень Пацієнта, якщо результати
                        дослідження буде отримувати уповноважена Пацієнтом особа.</p>
                    <p>4.2.8. Надати працівникам пункту забору біологічного матеріалу Свідоцтво про народження Пацієнта
                        та
                        пред’явити документ, що посвідчує особу законного представника, у випадку отримання результатів
                        досліджень Пацієнта, що не досяг повноліття, його законним представником.</p>

                    <p className={classes.mainText}> 4.3. Виконавець має право:</p>
                    <p> 4.3.1. Змінювати перелік, обсяг та ціну Послуг шляхом розміщення інформації на веб-сайті
                        Виконавця
                        за
                        адресою: www.esculab.com та інформувати про це Замовника. У випадку зміни ціни раніше внесена
                        Замовником
                        оплата за новими цінами не перераховується.</p>
                    <p> 4.3.2. Залучати при необхідності для надання Послуг Пацієнту третіх осіб (медичних фахівців,
                        установ,
                        закладів).</p>
                    <p> 4.3.3. Здійснювати акційні заходи з наданням знижок та пільг на Послуги.</p>
                    <p> 4.3.4. Розкривати відомості про Замовника виключно у випадках, передбачених законодавством
                        України.</p>
                    <p> 4.3.5. В односторонньому порядку розірвати цей Договір у випадку відмови пацієнта в наданні
                        письмової
                        інформованої добровільної згоди на проведення медичного втручання та згоди на обробку
                        персональних
                        даних
                        та в разі некоректної, протиправної поведінки (такої що суперечить загальновстановленим правилам
                        поведінки в громадських місцях) в пункті забору біологічного матеріалу Виконавця. При цьому,
                        сума
                        коштів, що була сплачена Замовником за Послугу, поверненню не підлягає.</p>
                    <p> 4.3.6. Призупинити (тимчасово або повністю) надання Послуг Замовнику без повернення грошових
                        коштів
                        і
                        вимагати письмових пояснень від Замовника в випадках порушення Замовником обов'язків
                        передбачених
                        пунктом 4.2 Договору, а також, якщо Виконавець вважає що будь-які дії, вчинені Замовником
                        завдають
                        або
                        можуть завдати шкоди Виконавцю, іншим Замовникам тощо.</p>

                    <p className={classes.mainText}> 4.4. Виконавець зобов’язується:</p>
                    <p> 4.1. Надавати Послуги належної якості в строки, передбачені цим Договором, з дотриманням вимог,
                        визначених законодавством України.</p>
                    <p> 4.2. Дотримуватись вимог та положень цього Договору.</p>
                    <p> 4.3. З метою належного рівня надання Послуг забезпечити участь висококваліфікованого медичного
                        персоналу
                        у процесі надання Послуги.</p>
                    <p> 4.4. Використовувати методи діагностики та медичні технології, дозволені до застосування в
                        установленому
                        чинним законодавством України порядку.</p>
                    <p> 4.5. Забезпечити Замовника інформацією щодо предмету договору, місця надання Послуг, режиму
                        роботи
                        пунктів забору біологічного матеріалу Виконавця, умов надання та отримання Послуг та інших
                        питань,
                        пов’язаних із виконанням умов даного Договору.</p>
                    <p> 4.6. Забезпечити режим конфіденційності щодо результатів лабораторних досліджень біологічного
                        матеріалу
                        Замовника та вимог законодавства про лікарську таємницю.</p>
                    <p> 4.7. Інформувати Замовника у випадку неможливості надання Послуг за Замовленням повністю або
                        частково.</p>

                    <p className={classes.title}> 5. Вартість послуг та порядок оплати</p>

                    <p> 5.1. Вартість Послуг за даним Договором визначається відповідно до розцінок, розміщених на
                        веб-сайті
                        Виконавця за адресою: www.esculab.com (розділ: «Ціни»). Ціни на Послуги вказуються в
                        національній
                        валюті
                        України.</p>
                    <p> 5.2. Оплата Послуг здійснюється Замовником шляхом безготівкового платежу на банківський рахунок
                        Виконавця під час оформлення Замовлення, способом запропонованим Виконавцем на веб-сайті
                        Виконавця
                        за
                        адресою: www.esculab.com.</p>
                    <p> 5.3. Всі Послуги, що містяться в Замовленні, на момент їх безпосереднього надання Замовнику
                        мають
                        бути
                        оплачені в повному обсязі.</p>
                    <p> 5.4. Виконавець має право в будь-який час в односторонньому порядку змінювати ціни на Послуги.
                        Датою
                        вступу в силу нових цін є дата їх публікації на веб-сайті Виконавця за адресою: www.esculab.com.
                        У
                        випадку зміни ціни раніше внесена Замовником оплата за новими цінами не перераховується.</p>
                    <p> 5.5. Замовник самостійно несе відповідальність за правильність здійснених ним платежів.</p>
                    <p> 5.6. У випадку відмови (анулювання) Замовником свого Замовлення протягом строку дії Замовлення,
                        вартість
                        оплачених послуг, що не були надані частково або повністю, можуть бути повернуті Замовнику за
                        його
                        письмовою заявою у формі, в якій було здійснено оплату таких Послуг, протягом 30 робочих днів з
                        моменту
                        прийняття рішення Виконавцем про повернення Замовнику вартості неотриманих (ненаданих)
                        Послуг.</p>
                    <p> 5.7. Заява на повернення грошових коштів може бути оформлена Замовником в паперовій формі в
                        будь-якому
                        пункті забору біологічного матеріалу регіону (області України), обраного Замовником при
                        оформленні
                        замовлення.</p>

                    <p className={classes.title}> 6. Порядок та строки надання послуг</p>

                    <p> 6.1. З метою отримання Послуг Замовник розміщує Замовлення на веб-сайті Виконавця, який
                        знаходиться
                        в
                        мережі Інтернет за адресою: www.esculab.com, наступним чином: обирає регіон (область України), в
                        якому
                        бажає отримати Послугу, обирає перелік необхідних Послуг, за допомогою встановленої форми
                        оформлює
                        Замовлення та вносить необхідні дані, ознайомлюється та погоджується з умовами цього Договору, з
                        Правилами підготовки пацієнта до забору біологічного матеріалу, надає Виконавцю свою згоду на
                        обробку та
                        використання своїх персональних даних та інформовану добровільну згоду на проведення медичного
                        втручання, проводить оплату у спосіб, визначений на веб-сайті Виконавця. При цьому, Сторони
                        дійшли
                        згоди
                        про те, що оформлення Замовником вказаного Замовлення, його підтвердження та оплата Послуг
                        трактується,
                        як факт достатнього і повного ознайомлення та погодження Замовника з Послугою, ціною та строками
                        її
                        виконання, Правилами підготовки пацієнта до забору біологічного матеріалу, інформацією про
                        наявність
                        (відсутність) знижок, тощо.</p>
                    <p> 6.2. При оформленні Замовником Замовлення на Послуги розповсюджується лише один вид знижки.
                        Знижки
                        на
                        послуги не сумуються.</p>
                    <p> 6.3. Вартість та обсяг Замовлення є остаточними та не підлягають зміні після підтвердження
                        Замовлення.</p>
                    <p> 6.4. Після надходження Замовлення, Виконавець забезпечує обробку Замовлення та направлення
                        Замовнику
                        SMS-повідомлення на номер мобільного телефону, вказаний Замовником, і\або на адресу електронної
                        пошти із
                        підтвердженням оплати та прийняття Замовлення до виконання, а також індивідуальний код, який
                        Замовник
                        зобов’язаний надати працівникам Виконавця перед забором біологічного матеріалу.</p>
                    <p> 6.5. Забір матеріалу Пацієнта для надання Послуг за Замовленням проводиться Виконавцем не раніше
                        наступного дня від дати оформлення Замовлення Замовником на сайті Виконавця за адресою:
                        www.esculab.com.</p>
                    <p> 6.6. Замовник має право на відмову (анулювання) свого Замовлення протягом строку дії Замовлення
                        (30-ти
                        календарних днів з дати його оформлення) відповідно до п.п.5.6, 5.7 цього Договору. Цей строк
                        може
                        бути
                        продовжений на розсуд Виконавця. Дана процедура передбачає анулювання Замовлення без можливості
                        подальшого відновлення.</p>
                    <p> 6.7. Анулювання Замовлення не вважається обмеженням права Замовника на оформлення нового
                        Замовлення
                        на
                        Послуги.</p>
                    <p> 6.8. Строк надання Послуг, визначений на веб-сайті Виконавця за адресою: www.esculab.com та
                        відраховується з дня, наступного за днем забору біологічного матеріалу Замовника в пункті забору
                        біологічного матеріалу Виконавця.</p>
                    <p> 6.9. Виконавець має право в односторонньому порядку змінювати строки надання Послуг з
                        технологічних
                        причин, попередньо попередивши про зміни Пацієнта, якщо Замовлення вже було оформлене та
                        оплачене.</p>
                    <p> 6.10. У разі оформлення Замовлення на сайті Виконавця послуга «Виклик медсестри додому» не
                        надається.</p>
                    <p> 6.11. Результат Послуги (лабораторних досліджень біологічного матеріалу Замовника) надається
                        Замовнику в
                        порядку та спосіб, визначених на веб-сайті Виконавця.</p>

                    <p className={classes.title}> 7. Конфіденційність</p>

                    <p> 7.1. Замовник зобов’язується дотримувати сувору конфіденційність відносно усієї інформації,
                        отриманої
                        від Виконавця, і вживати всіх можливих заходів для попередження несанкціонованого використання
                        або
                        розкриття такої інформації згідно даного Договору.</p>
                    <p> 7.2. Сторони не несуть відповідальності за порушення конфіденційності, яке відбулося:</p>
                    <p> - внаслідок форс-мажорних обставин;</p>
                    <p> - внаслідок порушення конфіденційності за вимогою державних органів згідно чинного
                        законодавства.</p>

                    <p className={classes.title}> 8. Відповідальність сторін</p>

                    <p> 8.1. Сторони даного Договору несуть відповідальність за дотримання умов Договору згідно вимог
                        діючого
                        законодавства України.</p>
                    <p> 8.2. Виконавець не несе відповідальності за:</p>
                    <p> - прямі або непрямі збитки, втрачену вигоду або моральну шкоду Замовника, пов’язані із
                        використанням
                        або
                        неможливістю користуватися Послугою;</p>
                    <p> - за якість Послуг у разі недотримання Пацієнтом вимог Правилами підготовки пацієнта до забору
                        біологічного матеріалу.</p>
                    <p> 8.3. У разі неможливості вирішення спору шляхом переговорів та в досудовому порядку, спір може
                        бути
                        передано для вирішення у судовому порядку відповідно до встановленої підсудності та
                        юрисдикції.</p>
                    <p> 8.4. Розмір відповідальності, не врегульований даним Договором, регулюється діючим
                        законодавством
                        України.</p>
                    <p> 8.5. Жодна зі Сторін не несе відповідальність за невиконання чи неналежне виконання своїх
                        зобов’язань по
                        цьому Договору, якщо це невиконання чи неналежне виконання зумовлені дією обставин непереборної
                        сили, що
                        знаходились поза її контролем (форс-мажорних обставин), які підтверджені у встановленому законом
                        порядку.</p>

                    <p className={classes.title}> 9. Момент вступу в силу Договору. Термін дії. Порядок зміни та
                        розірвання.</p>

                    <p> 9.1. Договір набуває чинності з моменту прийняття Оферти і здійснення Замовником оплати Послуг
                        та
                        діє до
                        моменту виконання Сторонами своїх зобов’язань за Договором.</p>
                    <p> 9.2. Сторони мають право розірвати даний Договір в односторонньому порядку, у випадку не
                        виконання
                        однією зі Сторін умов даного Договору та у випадках, передбачених даним Договором і чинним
                        законодавством України.</p>
                    <p> 9.3. Внесення змін (доповнень) у даний Договір проводиться Виконавцем в односторонньому порядку.
                        Усі
                        зміни (доповнення), внесені Виконавцем у даний Договір, набувають чинності й стають
                        обов’язковими
                        для
                        Замовника з моменту їхньої публікації на веб-сайті Виконавця за адресою: www.esculab.com. У
                        випадку
                        незгоди Замовника зі змінами, внесеними в даний Договір, Замовник має право розірвати його у
                        порядку,
                        встановленому даним Договором.</p>
                    <p> 9.4. Визнання будь-якого положення або пункту даного Договору чи додатків до нього не дійсним,
                        не
                        впливає на дійсність решти положень і умов Договору.</p>
                    <p> 9.5. Всі додатки, зміни й доповнення до даного Договору є його невід’ємною частиною.</p>

                    <p className={classes.title}> 10. Прикінцеві положення</p>

                    <p> 10.1. Сторони гарантують, що кожна зі Сторін володіє необхідною дієздатністю, всіма правами і
                        повноваженнями, необхідними і достатніми для укладання та виконання даного Договору відповідно
                        до
                        його
                        умов.</p>
                    <p> 10.2. Виконавець підтверджує, що має всі необхідні дозволи на здійснення господарської
                        діяльності з
                        медичної практики, пов’язаної з виконанням цього Договору, а також гарантує, що має право на
                        проведення
                        клінічних лабораторних досліджень без будь-яких обмежень, відповідно до вимог чинного
                        законодавства
                        України, і несе відповідальність в разі порушення прав Пацієнта в процесі виконання Договору і
                        реалізації Послуг.</p>
                    <p> 10.3. Виконавець здійснює свою діяльність керуючись Законом України «Основи законодавства
                        України
                        про
                        охорону здоров’я» від 19.11.1992 р № 2801–XII, Законом України</p>
                    <p> «Про захист прав споживачів» від 12.05.1991 р № 1023–XII, Законом України «Про захист
                        персональних
                        даних» від 01.06.2010 р № 2297–VI, Цивільним кодексом України від 16.01.2003 № 435-IV, іншими
                        нормативно-правовими актами, що розповсюджуються на діяльність медичних закладів.</p>

                    <p className={classes.title}> 11. Реквізити Виконавця</p>

                    <p>Приватне підприємство</p>
                    <p>Перша соціальна медична</p>
                   <p> лабораторія «Ескулаб»</p>
                   <p> м. Львів, вул. Шевченка, 313</p>
                   <p> Код ЄДРПОУ 36544827</p>
                   <p> ІПН 365448213073</p>
                   <p> п/р 26009053816593 в</p>
                   <p> ПАТ КБ «ПРИВАТБАНК»</p>
                    <p>м.Львів, МФО 325321</p>
                   <p> Тел/факс. (032) 239-57-32</p>



                    <div style={{marginTop: 100}}>
                        <p>Додаток № 1 до Публічного договору про</p>
                        <p>надання медичних послуг (публічної оферти)</p>
                    </div>

                    <p className={classes.title}>ІНФОРМОВАНА ДОБРОВІЛЬНА ЗГОДА ПАЦІЄНТА <br/>
                        НА НАДАННЯ МЕДИЧНИХ ПОСЛУГ ТА ОБРОБКУ ПЕРСОНАЛЬНИХ ДАНИХ</p>

                    <p> Шляхом проставлення мною відміток в полях: <span className={classes.mainText}>«Я даю згоду на обробку персональних даних» та «Я згоден з
                умовами публічного договору»</span> в формі підтвердження Замовлення онлайн на веб-сайті Виконавця:
                        www.esculab.com, я надаю приватному підприємству «Перша соціальна медична лабораторія «Ескулаб»,
                        згоду на обробку персональних даних відповідно до Закону України «Про захист персональних даних»
                        від
                        01
                        червня 2010 року № 2297-VІ та інших нормативно-правових актів України, які стосуються захисту
                        персональних даних, в базі персональних даних лабораторної інформаційної системи, власником якої
                        є
                        Лабораторія. Обсяг персональних даних, згоду на обробку яких я надаю Лабораторії, обмежується
                        даними, що
                        були зазначені в формі оформлення Замовлення он- лайн на сайті www.esculab.com, результатами
                        лабораторних досліджень наданого біологічного матеріалу, а також даними, отриманими в результаті
                        роботи
                        системи відеоспостереження, встановленої в пунктах забору біологічного матеріалу.</p>

                    <p> Метою обробки наданих мною персональних даних є виконання Лабораторією лабораторних досліджень
                        наданого
                        біологічного матеріалу та надання мені або вказаним мною особам можливості доступу до
                        персональних
                        даних, зокрема результатів лабораторних досліджень, забезпечення режиму безпеки пацієнтів
                        (відвідувачів)
                        в пунктах забору біоматеріалу (далі – «Мета обробки»).</p>

                    <p><span className={classes.mainText}> Мені було роз’яснено порядок доступу до наданих мною персональних даних,</span> право
                        внесення в них змін чи
                        припинення обробки таких персональних даних.</p>

                    <p><span className={classes.mainText}>Я погоджуюсь,</span> що Лабораторія має право без моєї
                        додаткової
                        згоди на передачу (поширення) персональних
                        даних третім особам. До таких третіх осіб належать органи державної влади та місцевого
                        самоврядування,
                        інші особи, що мають право отримувати дану інформацію у відповідності до вимог чинного
                        законодавства,
                        адвокати, контрагенти, з якими Лабораторією були укладені угоди про надання медичних послуг,
                        лікуючі
                        лікарі, або лікарі, які видали скерування на проведення дослідження біологічного матеріалу, а
                        також
                        особи, які розмістили замовлення послуг на офіційному веб-сайті Лабораторії
                        http://www.esculab.com/
                        або
                        вказані як родичі в особистому кабінеті (далі – «Призначені мною особи»).</p>

                    <p><span className={classes.mainText}>Мені відомо,</span> що Володілець обробляє надані мною
                        персональні
                        дані в порядку, встановленому законодавством
                        для конкретних і законних цілей, зазначених в цій інформованій згоді, згідно визначеної Мети
                        обробки
                        і
                        Володілець та/або треті особи взяли на себе зобов’язання забезпечити захист наданих мною
                        персональних
                        даних від їх незаконної обробки, а також від незаконного доступу до них. Володілець та/або треті
                        особи
                        зобов’язуються вважати всю інформацію, що міститься в базі персональних даних лабораторної
                        інформаційної
                        системи, як конфіденційну.</p>

                    <p><span
                        className={classes.mainText}> Я надаю згоду на обробку наданих мною персональних даних,</span> зазначених
                        в замовленні, у будь-яких
                        комп’ютерних та/або інформаційних системах, які використовуються на законних підставах
                        суб’єктом, що
                        здійснює обробку персональних даних, даних, отриманих в результаті роботи системи
                        відеоспостереження,
                        встановленої в пунктах забору біологічного матеріалу, а також згоду на транскордонну передачу
                        персональних даних до іноземної держави для здійснення діяльності відповідно до зазначеної Мети
                        обробки.</p>

                    <p><span className={classes.mainText}> Я погоджуюсь,</span> що у випадку оформлення призначеною мною
                        особою
                        попереднього замовлення на надання послуг
                        Лабораторії у спеціальних комп’ютерних та/або інформаційних системах, встановлених на будь-яких,
                        в
                        тому
                        числі і портативних, носіях, я ознайомлений(-на) з переліком послуг та бажаю отримати саме такі
                        послуги
                        Лабораторії та підтверджую, що призначена мною особа діяла від мого імені та в моїх інтересах, а
                        факт 8
                        оплати відповідного замовлення спричиняє зобов’язання Лабораторії виконати у повному обсязі
                        обрані в
                        такій спеціальній комп’ютерній та/або інформаційній системі послуги, незалежно від того, чи
                        самостійно я
                        обрав(-ла) такі послуги, чи за допомогою призначеної мною особи, відповідно до Мети обробки. Я
                        надаю
                        згоду Лабораторії на надсилання отриманих Лабораторією результатів досліджень згідно з цим
                        замовленням
                        саме на ту електронну адресу, що була зазначена при оформленні попереднього замовлення
                        призначеною
                        мною
                        особою. Відповідальність за коректність електронної адреси та конфіденційність інформації після
                        її
                        отримання несе призначена мною особа.</p>

                    <p> Залишаючи номер мобільного телефону,<span className={classes.mainText}> я надаю згоду на отримання текстових та/або мультимедійних
                повідомлень,</span> в тому числі за допомогою програм, мобільних додатків, месенджерів (Viber, WhatsApp
                        та
                        інших), про готовність результатів клінічної лабораторної діагностики, а також повідомлень з
                        рекламною
                        та іншою інформацією Лабораторії. Я розумію, що доставка текстових та/або мультимедійних
                        повідомлень
                        залежить від операторів мобільного зв’язку та постачальників послуг зв’язку та не є
                        гарантованою.
                        Лабораторія не несе відповідальності за отримання мною текстового та/або мультимедійного
                        повідомлення.</p>

                    <p> <span className={classes.mainText}> Я погоджуюсь, що Лабораторія не може гарантувати та не несе відповідальності за доставку результатів
                клінічної лабораторної діагностики електронною поштою,</span> оскільки доставка електронного листа
                        залежить від
                        налаштувань поштових серверів, антивірусних систем та спам-фільтрів моєї поштової скриньки чи
                        відповідного програмного забезпечення.</p>

                    <p><span className={classes.mainText}>Даю згоду на знімання (фіксацію) моєї особи</span> на
                        відео-носій
                        під
                        час мого перебування в приміщеннях
                        пунктів забору біологічного матеріалу Лабораторії, в яких здійснюється відеоспостереження, однак
                        без
                        розповсюдження мого зображення, окрім як на вимогу відповідних органів державної влади в
                        порядку,
                        визначеному законом.</p>

                    <p className={classes.mainText}>Інформація про медичні маніпуляції та можливі їх наслідки. Я
                        погоджуюсь,
                        що:</p>

                    <p> 1. Ознайомлений(-а) з характером кожної процедури, позначеної в бланку скерування та бланку
                        замовлення,
                        ризиками медичних маніпуляцій під час їх проведення, правом вимагати припинення процедури та
                        надаю
                        цю
                        інформовану згоду на медичні маніпуляції відповідно до Закону України «Основи законодавства
                        України
                        про
                        охорону здоров’я»
                        №2801-ХІІ від 19 листопада 1992 року.</p>

                    <p> 2. Мені відомо про основні преаналітичні фактори та я ознайомлений(-а) з Правилами підготовки
                        пацієнта
                        до забору біологічного матеріалу, недотримання яких може вплинути на результати досліджень.</p>

                    <p> 3. Мені відомо, що основним способом забору венозної крові для проведення лабораторного
                        дослідження
                        є
                        венепункція. Дана процедура може бути травматичною і для того, щоб уникнути утворення гематоми,
                        рекомендовано притиснути місце проколу на 10-15 хв. та зігнути руку в ліктьовому суглобі.</p>

                    <p> 4. Мені відомо, що забір матеріалу з урогенітального тракту в жінок та в чоловіків може бути
                        травматичним і наслідками можуть бути неприємні та болісні відчуття чи крововиділення під час
                        сечовипускання протягом доби.</p>

                    <p> 5. Мені відомо, що у випадку замовлення послуги «Мікробіологічне дослідження біологічного
                        матеріалу
                        та
                        визначення чутливості виділених організмів до антибіотиків», антибіотикограма проводиться тільки
                        тоді,
                        коли було виявлено патогенну або умовнопатогенну флору в кількості, що перевищує норму.</p>

                    <p> 6. Мені відомо, що результати лабораторних досліджень залежать від тест-систем та аналізаторів,
                        за
                        допомогою яких проводяться дослідження.</p>

                    <p> 7. Мені відомо, що результати лабораторних досліджень не є достатньою підставою для постановки
                        діагнозу.
                        Інтерпретація результатів та постановка діагнозу виконується тільки лікарем.</p>

                    <p> 8. Перевірив(-ла) коректність послуг, що зазначені у бланку замовлення та переконався(-лась), що
                        послуги
                        зазначені вірно, тому, дані послуги вважаються замовленими належним чином та підлягають
                        виконанню
                        Лабораторією.</p>

                    <p> 9. Повідомив(-ла) медичного працівника про алергічні прояви або індивідуальну непереносимість
                        спирту
                        та
                        медикаментів.</p>

                    <p> 10. Зрозумів(-ла) мету та характер медичного втручання. У випадку виникнення ускладнень
                        (запаморочення,
                        втрата свідомості тощо) під час медичних маніпуляцій, я заздалегідь надаю згоду на застосування
                        всіх
                        необхідних та можливих засобів з метою їх усунення.</p>

                    <p> 11. Результати лабораторних досліджень надаються Українською мовою, окрім певних винятків.</p>

                    <p> 12. Мав(-ла) можливість задавати будь-які питання стосовно медичних послуг, які надаються
                        Лабораторією
                        та отримав(-ла) на них відповіді.</p>

                    <p> 13. Внесення мною додаткових змін в дану інформовану згоду не допускається.</p>

                    <p> Цією інформованою згодою, я надаю згоду на проведення медичного втручання та опрацювання
                        персональних
                        даних у випадку кожного наступного звернення до Лабораторії. Мені відомо, що дана інформована
                        згода
                        після її підписання зберігається в електронній формі в архіві Лабораторії та я підтверджую, що
                        копія
                        цієї інформованої згоди визнається достатнім доказом факту наявності згоди на медичне втручання
                        та
                        згоди
                        на обробку зазначених у ній та замовленні даних. Я бажаю отримувати повідомлення та електронні
                        листи
                        від
                        Лабораторії за номером мобільного телефону та адресою електронної пошти, що були зазначені в
                        формі
                        Замовлення он-лайн на сайті Виконавця: www.esculab.com. Даною інформованою згоди я підтверджую,
                        що є
                        законним представником Пацієнта* та володію необхідною правоздатністю та дієздатністю для
                        надання
                        згоди
                        на медичне втручання щодо Пацієнта, обробку його персональних даних та вчинення правочину про
                        надання
                        медичних послуг.</p>

                    <p> Текст даної інформованої згоди мною прочитано і проставлянням відміток в полях «Я даю згоду на
                        обробку
                        персональних даних» та «Я згоден з умовами публічного договору» в формі підтвердження Замовлення
                        он-лайн
                        на сайті www.esculab.com, я засвідчую, що згоден із усіма пунктами цього документу, положення
                        якого
                        мені
                        роз’яснені і зрозумілі, а також підтверджую достовірність інформації, вказаної мною у
                        замовленні.</p>

                    <p> *Законні представники - батьки (усиновлювачі), опікуни, піклувальники або інші законні
                        представники
                        для
                        осіб, які не досягли вісімнадцятирічного віку, осіб, які визнані судом обмежено дієздатними або
                        недієздатним.</p>


                </div>
            </div>


    )
}

export default PublicOffer

