import React, {useEffect, useState} from "react";
import {Accordion, AccordionSummary, Avatar, Grid, makeStyles, TextField} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import uaLocale from 'date-fns/locale/uk';
import {getOrdersResultByDate, getOrdersResultByFullName} from "@cabinet-packages/api/api";
import {useDispatch, useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import {ButtonWithLoader, CustomDialog, Loading, LoadOrderResultDialog, Scroll} from "@cabinet-packages/components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AssayTable from "@cabinet-packages/pages/AssayTable";
import Switch from "@material-ui/core/Switch";
import {setToggleChecked} from "@cabinet-packages/redux";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
    },
    innerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: '4px',
        maxWidth: '700px',
    },
    datePickersWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',

        "@media max-width(900px)": {
            flexDirection: 'column',
            justifyContent: 'center',
        }
    },
    datePicker: {
        margin: '4px',
    },
    noResults: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '8px',
        color: theme.palette.error.main,
    },
    switchControl: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '10px 0 0 0',
        position: 'sticky',
        top: 0,
        '@media (max-width: 768px)': {
            padding: 0,
            paddingTop: '8px',
        }
    },
    switchControlLabel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: theme.palette.primary.dark,
        fontSize: '18px',
        fontWeight: 600,
    },
    textFieldWrapper: {
        width: '100%',
        padding: '8px',
    },
    resultsContainer: {
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '10px',
        width: '100%',
        display: 'flex',

        flexDirection: 'column',
        overflow: 'auto',
        '@media (max-width:900px)': {
            padding: '2px',
        },
    },
    avatarWrapper: {
        display: 'none',

        '@media (max-width: 900px)': {
            display: 'block',
        },
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    buttonWithLoader: {
        minWidth: '110px',
    },
    buttonWithOpen: {
        minWidth: '75px',
        marginLeft: '4px',
    },
    tablesContainer: {
        margin: '20px 0',
        boxShadow: '0 0 1px 1px rgba(0, 0, 0, 0.1)',
        zIndex: '100',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 6px',
    },
    loadingWrapper: {
        padding: '16px',
    },
    actionButton: {
        padding: '8px',
    },
}));

const SearchPatientResults = () => {
    const classes = useStyles();
    const [selectedDateFrom, setSelectedDateFrom] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
    const [selectedDateTo, setSelectedDateTo] = useState(new Date());
    const { activePatient } = useSelector(state => state.patients);
    const [foundPatientsResults, setFoundPatientsResults] = useState(null);
    const dispatch = useDispatch();
    const {toggleChecked} = useSelector(state => state.assaysCatalog);
    const [value, setValue] = useState('');
    const [expanded, setExpanded] = useState(false);
    const {themeMaterialUI} = useSelector(store => store.theme);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleToggleChecked = () => {
        dispatch(setToggleChecked(!toggleChecked));
    };

    const handleDateChangeFrom = (date) => {
        setSelectedDateFrom(date);
    };

    const handleDateChangeTo = (date) => {
        setSelectedDateTo(date);
    };

    const handleSearchByDate = async (event) => {
        event.stopPropagation();
        event.preventDefault();

        const dateFrom = selectedDateFrom.toISOString().slice(0,10);
        const dateTo = selectedDateTo.toISOString().slice(0,10);
        setFoundPatientsResults(false);

        const result = await getOrdersResultByDate(activePatient.id, dateFrom, dateTo).catch(onerror => {
            console.error(onerror);
            return "error"
        });
        setFoundPatientsResults(result.reduce((acu, cur) => {
            if (+cur.ready === 0 && cur.idOrder < 2000000) return acu;
            if (acu.find(item => item.idOrder === cur.idOrder)) return acu;

            return [...acu, cur];
        }, []));
    };

    const handleSearchByFullName = async (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (value.length === 0) return;
        setFoundPatientsResults(false);

        const result = await getOrdersResultByFullName(activePatient.id, value).catch(onerror => "error");

        setFoundPatientsResults(result.reduce((acu, cur) => {
            if (+cur.ready === 0 && cur.idOrder < 2000000) return acu;
            if (acu.find(item => item.idOrder === cur.idOrder)) return acu;

            return [...acu, cur];
        }, []));
    };

    const sendOrder = (event, idOrder, idClient, open, patient) => {
        event.preventDefault();
        event.stopPropagation();

        CustomDialog(LoadOrderResultDialog,
            {
                open,
                activePatient: {
                    firstname: patient[1],
                    lastname: patient[0],
                    fathername: patient[2]
                },
                idOrder,
                idClient,
                themeMaterialUI
            },
            false,
            themeMaterialUI);
    }

    function handleClick(event, bool, idOrder) {
        event.stopPropagation();
        event.preventDefault();

        setExpanded(prevState => ({...prevState, [idOrder]: bool}));
    }

    useEffect(() => {
        if (foundPatientsResults && typeof foundPatientsResults !== "string" && foundPatientsResults.length > 0) setExpanded(foundPatientsResults.reduce((acu, cur, idx) => {
            return {...acu, [cur.idOrder]: false};
        }, {}))
    }, [foundPatientsResults])

    return (<div className={classes.root}>
        <div className={classes.innerWrapper}>
            <div className={classes.switchControl}>
                <label htmlFor="toggleChecked" className={classes.switchControlLabel}>
                    Результати за певний період
                </label>
                <Switch id="toggleChecked" color="primary" checked={toggleChecked} onChange={handleToggleChecked}/>
                <label htmlFor="toggleChecked" className={classes.switchControlLabel}>
                    Результати за ПІБ пацієнта
                </label>
            </div>
            {!toggleChecked
                ? <MuiPickersUtilsProvider locale={uaLocale} utils={DateFnsUtils}>
                    <div className={classes.datePickersWrapper}>
                        <Grid
                            container
                            justify="space-around"
                            className={classes.datePicker}
                        >
                            <KeyboardDatePicker
                                disableFuture
                                variant="inline"
                                format="yyyy-MM-dd"
                                margin="normal"
                                id="date-picker-inline-from"
                                label="Вибір дати ВІД:"
                                value={selectedDateFrom}
                                onChange={handleDateChangeFrom}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                        <Grid
                            container
                            justify="space-around"
                            className={classes.datePicker}
                        >
                            <KeyboardDatePicker
                                disableFuture
                                variant="inline"
                                format="yyyy-MM-dd"
                                margin="normal"
                                id="date-picker-inline-to"
                                label="Вибір дати ДО:"
                                value={selectedDateTo}
                                onChange={handleDateChangeTo}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                    </div>
                </MuiPickersUtilsProvider>
                : <div className={classes.textFieldWrapper}>
                    <TextField
                        id="outlined-helperText"
                        label="Введіть ПІБ пацієнта"
                        variant="outlined"
                        value={value}
                        onChange={handleChange}
                        style={{width: '100%'}}
                    />
                </div>}
            <Button
                onClick={event => toggleChecked
                    ? handleSearchByFullName(event)
                    : handleSearchByDate(event)
                }
                variant="outlined"
                color="primary"
                disabled={toggleChecked && value.length === 0}
                className={classes.actionButton}
            >
                Шукати результати
            </Button>
            {foundPatientsResults === false && <div className={classes.loadingWrapper}><Loading/></div>}
            {foundPatientsResults && foundPatientsResults.length === 0 && <div className={classes.noResults}><h3>Пошук не дав результатів.</h3></div>}
            {foundPatientsResults && typeof foundPatientsResults === "string" && <div className={classes.noResults}><h3>Вибачте, сталась помилка під час завантаження.</h3></div>}
        </div>
        {foundPatientsResults && typeof foundPatientsResults !== "string" && foundPatientsResults.length > 0 && expanded && <Scroll>
            <div className={classes.resultsContainer}>
                {foundPatientsResults.map((result) => {
                    const patient = result.fullname.split(' ');

                    return (+result.ready !== 0
                        ? <Accordion
                            square
                            key={result.idOrder}
                            onChange={(event, bool) => handleClick(event, bool, result.idOrder)}
                            expanded={expanded[result.idOrder]}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <div className={classes.summaryContainer}>
                                    <div>
                                        <div className={classes.avatarWrapper}>
                                            <Avatar className={classes.avatar}>{`${patient[1][0]} ${patient[0][0]}`}</Avatar>
                                        </div>
                                        Час здачі: {result.dt.slice(0, 16)}
                                        <div><strong>{`Пацієнт: ${patient[1]} ${patient[0]} ${patient[2]}`}</strong></div>
                                    </div>
                                    <div>ID: {result.idOrder}</div>
                                <ButtonWithLoader
                                    onClick={(event) => sendOrder(event, result.idOrder, result.idClient, true, patient)}
                                    loading={false}
                                    variant="contained"
                                    size="small"

                                    color="primary"
                                    className={classes.buttonWithLoader}
                                >
                                    Завантажити
                                </ButtonWithLoader>

                                    <ButtonWithLoader
                                        onClick={(event) => sendOrder(event, result.idOrder, result.idClient, false, patient)}
                                        loading={false}
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        className={classes.buttonWithOpen}
                                    >
                                        Відкрити
                                    </ButtonWithLoader>

                                </div>
                            </AccordionSummary>
                            <div className={classes.tablesContainer}>
                                {<AssayTable id={result.idOrder} patientId={activePatient.id} isDoctor={true} isExpanded={expanded[result.idOrder]}/>}
                            </div>
                        </Accordion>
                        : <Accordion
                                square
                                key={result.idOrder}
                                expanded={false}
                            >
                                <AccordionSummary>
                                    <div className={classes.summaryContainer}>
                                        <div>
                                            <div className={classes.avatarWrapper}>
                                                <Avatar className={classes.avatar}>{`${patient[1][0]} ${patient[0][0]}`}</Avatar>
                                            </div>
                                            Час здачі: {result.dt.slice(0, 16)}
                                            <div><strong>{`Пацієнт: ${patient[1]} ${patient[0]} ${patient[2]}`}</strong></div>
                                        </div>
                                        <div>ID: {result.idOrder}</div>
                                        <div><strong>Результати по даному замовленню ще не готові.</strong></div>
                                    </div>
                                </AccordionSummary>
                            </Accordion>
                    )
                })}
            </div>
        </Scroll>}
    </div>)
};

export default SearchPatientResults;
