import React from "react";
import useStyles from "../styles";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedTags} from "@cabinet-packages/redux";
import {toggleComplexTransition} from "@cabinet-packages/redux/actions/actionsCreators";
import {ReactComponent as TestTube} from '../images/test-tube.svg';

const CardForSuperGroup = ({superGroup, idx, setCatalogForView, setSelectedTag, isAll, usePrevState}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const SvgImg = TestTube;
    const {catalog, complexResearch, complexTransition}  = useSelector(state => state.assaysCatalog);
    const [prevStateOfCatalog, setPrevStateOfCatalog] = usePrevState;

    const SuperGroupName = () => {
        const indexOfComplex = superGroup.superGroupName.indexOf('(');
        const indexOfDot = superGroup.superGroupName.indexOf(')');

        if (indexOfComplex < 0 || indexOfDot < 0) {
            return <p className={cl.superGroupCardName}>{superGroup.superGroupName}</p>
        }

        return (<p className={cl.superGroupCardName}>
            {superGroup.superGroupName.slice(0, indexOfComplex)}<span className={cl.superGroupNameAccent}>
                {superGroup.superGroupName.slice(indexOfComplex, indexOfDot+1)}
            </span>{superGroup.superGroupName.slice(indexOfDot+1)}
        </p>);
    }

    const handleClick = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (superGroup.isTag) {
            setSelectedTag(superGroup.superGroupName);
            dispatch(setSelectedTags([superGroup]));
            setCatalogForView([...catalog, ...complexResearch].filter(item => !item.superGroupName));
        } else if (isAll) {
            setSelectedTag('Усі');
            dispatch(setSelectedTags([]));
            setCatalogForView([...catalog, ...complexResearch].filter(item => !item.superGroupName));
        } else {
            setCatalogForView([...catalog, ...complexResearch].filter(item => !item.superGroupName && +item.idParent === superGroup.id));
        }

        if (complexTransition) {
            dispatch(toggleComplexTransition());
        } else if (!complexTransition && !superGroup.isTag && superGroup.idParent === 77) {
            dispatch(toggleComplexTransition());
        }

        setPrevStateOfCatalog(null);
    };

    return (<div
        className={cl.cardForSuperGroup}
        onClick={handleClick}
    >
        <SvgImg className={cl.svgImg}/>
        <SuperGroupName/>
    </div>);
};

export default CardForSuperGroup;
