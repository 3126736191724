import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    DialogContent: {
        height: 'max-content',
        minHeight: '100%',
        maxWidth: '1180px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: theme.spacing(1),

        '@media (max-width: 900px)': {
            paddingTop: theme.spacing(2)
        },
    },
    rootWrapper: {
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '1056px',
        height: '90%',
    },
    headerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        '@media (max-width: 900px)': {
            flexDirection: 'row',
        },
    },
    confirmPhone: {
        margin: '12px 0px',
    },
    innerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        margin: '0 auto',
        padding: '10px 0',
    },
    phoneConfirmation: {
        height: 'max-content',
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    phoneConfirmationInput: {
        maxWidth: '300px',
        width: '100%',
    },
    phoneConfirmationButton: {
        margin: '20px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    patientTabsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        padding: '4px'
    },
    phoneCancellationButton: {
        marginTop: '4px',
    },
    errorCodeTitle: {
        marginBottom: '10px',
        color: 'red'
    },
    resultTitleBlock: {
        display: 'flex',
        paddingBottom: '10px'
    },
    resultTitleBlockTitle: {
        padding: '20px 0',
        '@media (max-width: 900px)': {
            display: 'flex',
            alignItems: 'center',
        }
    },
    wrapperCode: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
    },
    wrapper: {
        display: 'flex',
    },
    phoneEnterForm: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        maxWidth: '500px',
        margin: '0 auto',
        padding: theme.spacing(5),
        paddingTop: theme.spacing(7),
        boxShadow: '0px 0px 10px -2px #363D4D',
        position: "relative",
        borderRadius: theme.spacing(1),

        '&::before': {
            content: '"ВВЕДІТЬ НОМЕР ТЕЛЕФОНУ"',
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(3),
            backgroundColor: theme.palette.colors.background,
            color: 'white',
            fontWeight: 600,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            borderRadius: theme.spacing(1),
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        },
    },
    codeEnterForm: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        maxWidth: '500px',
        margin: '0 auto',
        padding: theme.spacing(5),
        paddingTop: theme.spacing(6),
        boxShadow: '0px 0px 10px -2px #363D4D',
        position: "relative",
        borderRadius: theme.spacing(1),

        '&::before': {
            content: '"ВВЕДІТЬ ОТРИМАНИЙ КОД"',
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(3),
            backgroundColor: theme.palette.green.dark,
            color: 'white',
            fontWeight: 600,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            borderRadius: theme.spacing(1),
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        },
    },
    confirmTitle: {
        paddingTop: '20px',
        color: theme.palette.primary.dark,
        fontSize: '18px',
    },
    listItem: {
        background: 'rgba(0, 0, 0, 0.05)',
    },
    phoneConfirmationCloseButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    createClientWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        maxWidth: '500px',
        margin: '0 auto',
        padding: theme.spacing(5),
        paddingTop: theme.spacing(6),
        boxShadow: '0px 0px 10px -2px #363D4D',
        position: "relative",
        borderRadius: theme.spacing(1),

        '&::before': {
            content: '"ДАНІ ПАЦІЄНТА"',
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(3),
            backgroundColor: theme.palette.green.dark,
            color: 'white',
            fontWeight: 600,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            borderRadius: theme.spacing(1),
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        },
    },
    bodyContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    selectActivePatientWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '24px',
    },
    selectActivePatient: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        background: 'rgba(0, 0, 0, 0.05)',
        marginBottom: '2px',
        padding: '5px',
    },
    selectActivePatientHeader: {
        padding: '4px',
        fontWeight: 500,
        color: theme.palette.primary.dark,
    },
    selectActivePatientText: {
        fontWeight: 500,
    },
    selectActivePatientButtonLink: {
        padding: '0',
    },
    selectActivePatientListWrapper: {
        listStyle: 'none',
        width: '100%',
    },
    actionContainer: {
        margin: '10px',
        display: 'flex',
        justifyContent: 'center',
        '@media (max-width: 900px)': {
            margin: '5px',
        },
    },
    birthdayContainer: {
        padding: '4px 0',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 1fr',
        gridGap: '5px',
    },
    actionContainerButton: {
        maxHeight: '70px',
        '@media (max-width: 900px)': {
            margin: '5px',
        },
    },
    formAnnotation: {
        fontSize: '11px',
        color: theme.palette.secondary.light,
    },
    formCheckboxes: {
        height: '120px',
        '@media (max-width: 900px)': {
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
    },
    catalogSwitchControl: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: theme.spacing(2),
        alignItems: 'baseline',

        '@media (max-width: 768px)': {
            padding: 0,
        },
        '@media (max-width: 900px)': {
            display: 'none',
        }
    },
    catalogSwitchControlLabel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: theme.palette.primary.dark,
        whiteSpace: 'nowrap',
    },
    newPatientWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: '0 auto',
        padding: theme.spacing(5),
        paddingTop: theme.spacing(6),
        boxShadow: '0px 0px 10px -2px #363D4D',
        position: "relative",
        borderRadius: theme.spacing(1),

        '&::before': {
            content: '"ВВЕДІТЬ ДАНІ ПАЦІЄНТА"',
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(3),
            backgroundColor: theme.palette.green.dark,
            color: 'white',
            fontWeight: 600,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            borderRadius: theme.spacing(1),
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        },
    },
    newPatientFieldsGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    newPatientField: {
        paddingRight: theme.spacing(1),
        width: '50%'
    },
    newPatientBirthdayGender: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        width: '50%',
    },
    newPatientBirthdayContainer: {
        paddingRight: theme.spacing(1),
        width: '100%',
        display: 'grid',
    },
    newPatientFormComtrol: {
        width: 'max-content',
        justifySelf: 'center'
    },
    newPatientActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    error: {
        color: "red"
    },
    authorizeWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        maxWidth: '500px',
        margin: '0 auto',
        padding: theme.spacing(5),
        paddingTop: theme.spacing(6),
        boxShadow: '0px 0px 10px -2px #363D4D',
        position: "relative",
        borderRadius: theme.spacing(1),
        marginTop: theme.spacing(5),

        '&::before': {
            content: '"ВІТАЄМО!"',
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(3),
            backgroundColor: theme.palette.green.dark,
            color: 'white',
            fontWeight: 600,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            borderRadius: theme.spacing(1),
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        },
    },
    diaAuthorization: {
        maxWidth: '600px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: '8px auto',
    },
    diaAuthorizationPatientView: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        maxWidth: '600px',
        margin: '8px auto',
        padding: theme.spacing(5),
        paddingTop: theme.spacing(7),
        boxShadow: '0px 0px 10px -2px #363D4D',
        position: "relative",
        borderRadius: theme.spacing(1),

        '&::before': {
            content: '"АРТОРИЗАЦІЯ ДІЯ"',
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(3),
            backgroundColor: theme.palette.green.dark,
            color: 'white',
            fontWeight: 600,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            borderRadius: theme.spacing(1),
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        },
    },
    diaPatientView: {
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: theme.spacing(1),
    },
    diaPatientsOur: {
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    diaOurPatientView: {
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: theme.spacing(1),
        margin: theme.spacing(1),
    },
    avatar: {
        backgroundColor: theme.palette.primary[100],
    },
}));

export default useStyles;
