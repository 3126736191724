import React from 'react';
import {IconButton, makeStyles} from "@material-ui/core";
import footerLogo from '../../assets/images/logoFooter.png'
import {Email, Facebook, Instagram, YouTube} from "@material-ui/icons";

const useStyles = makeStyles({
    footer: {
        width: '100%',
        bottom: 0,
        left: 0,
        background: 'rgb(121, 122, 122)',
        height: '75px',
        color: '#FCFCFC',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'relative',

        '@media (max-width: 900px)': {
            height: '100px',
        },
    },
    footerInnerWrapper: {
        width: '100%',
        maxWidth: '1056px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    footerLogoOuterWrapper: {
        width: '200px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        '@media (max-width: 900px)': {
            width: '100px',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },
    footerLogoWrapper : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        paddingLeft: '5px',
        '@media (max-width: 900px)': {
            paddingLeft: 0,
            height: '100%',
        },
    },
    footerLogo : {
        width: '75px',
    },
    footerLogoText: {
        fontSize: '.5em',
        textAlign: 'center',
    },
    footerPhoneWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
    },
    footerPhoneText: {
        fontSize: '.7em',
        textAlign: 'center',
    },
    footerPhone: {
        padding: '0 12px',
        borderRadius: '15px',
        '@media (max-width: 900px)': {
            fontSize: '.9em'
        },
    },
    footerSocialMediaWrapper: {
        width: '200px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 900px)': {
            flexDirection: 'column',
            justifyContent: 'space-around',
            width: '100px',
        },
    },
    footerSocialMediaItems: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    poweredBy: {
        position: 'absolute',
        bottom: 0,
        right: '4px',
        fontSize: '8px',
    },
});

const Footer = () => {
    const classes = useStyles();

    return (
        <div className={classes.footer}>
            <div className={classes.footerInnerWrapper}>
                <div className={classes.footerLogoOuterWrapper}>
                    <div className={classes.footerLogoWrapper}>
                        <img className={classes.footerLogo} src={footerLogo} alt="Esculab logo."/>
                        <p className={classes.footerLogoText}>© 2009-2020 <br/> ПСМЛ "Ескулаб"</p>
                    </div>
                </div>
                <div className={classes.footerPhoneWrapper}>
                    <p className={classes.footerPhoneText}>Інформаційна лінія</p>
                    <IconButton
                        color="inherit"
                        href="tel:0800503680"
                        className={classes.footerPhone}
                    >
                        0 800 50 36 80
                    </IconButton>
                </div>
                <div className={classes.footerSocialMediaWrapper}>
                    <div className={classes.footerSocialMediaItems}>
                        <IconButton
                            color="inherit"
                            href="https://www.facebook.com/analizyesculab/"
                        >
                            <Facebook/>
                        </IconButton>
                        <IconButton
                            color="inherit"
                            href="https://www.instagram.com/esculab.official/"

                        >
                            <Instagram/>
                        </IconButton>
                    </div>
                    <div className={classes.footerSocialMediaItems}>
                        <IconButton
                            color="inherit"
                            href="https://www.youtube.com/channel/UCS1dFXhrE4QoWJPPTL6CpuQ?view_as=subscriber"
                        >
                            <YouTube/>
                        </IconButton>
                        <IconButton
                            color="inherit"
                            href="mailto:support@esculab.com"
                        >
                            <Email/>
                        </IconButton>
                    </div>
                </div>
            </div>
            <p className={classes.poweredBy}>© powered by MZ-group</p>
        </div>
    )
}
export default Footer;
