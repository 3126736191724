import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {CircularProgress} from '@material-ui/core';
import store from "@cabinet-packages/redux/store";

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      },
    image: {
        width: '40px',
        height: '40px',
        margin: '0 auto',
    },
});

const Loading = () => {
    const {takeId}  = store.getState().assaysCatalog;
    const classes = useStyles();

    return (
    <div className={classes.root}>
        <CircularProgress/>
    </div>
)};

export default Loading;