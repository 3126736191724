import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useMediaQuery} from '@material-ui/core';
import cn from 'classnames';
import {MainNavigation} from '@cabinet-packages/components';
import routes from '../settings/routes';
import {AccountSettings} from "@cabinet-packages/pages";

function PageController() {
  const isDesktopVersion = useMediaQuery('(min-width: 900px)');
  const containerClassName = cn({
    'PageManager__Container': true,
    'PageManager__Container--Desktop': isDesktopVersion,
    'PageManager__Container--Mobile': !isDesktopVersion,
  });

  return (
    <div className={containerClassName}>
      {isDesktopVersion && <MainNavigation routes={routes}/>}
      <div className="PageManager__ContentContainer">
        <Switch>
          {!isDesktopVersion && <Route exact path="/main-navigation" component={() => (<MainNavigation routes={routes}/>)}/>}
          {isDesktopVersion && <Route exact path="/main-navigation">
            <Redirect to={'/account-settings'}/>
          </Route>}
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={<route.component/>}
            />
          ))}
          <Route component={AccountSettings}/>
        </Switch>
      </div>
    </div>
  );
}

export default PageController;
