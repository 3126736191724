import React, {useCallback, useEffect, useState} from 'react';
import useStyles from './styles';
import {useDispatch, useSelector} from "react-redux";
import SuperGroupCards from "./components/SuperGroupCards";
import SearchHeader from "./components/SearchHeader";
import AutoSizerCatalog from "./components/AutoSizerCatalog";
import {getPunkts, setSelectedTags} from "@cabinet-packages/redux";
import {useAssaysFilter} from "@cabinet-packages/hooks";
import {Loading} from "../../components";
import {getCatalogWizard} from "@cabinet-packages/redux/actions/actionsCreators";

const ProductCatalogEsculab = ({allFunc}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const {complexTransition, catalog, complexResearch, takeId, complexId} = useSelector(state => state.assaysCatalog);
    const [catalogForView, setCatalogForView] = useState(null);
    const [selectedTag, setSelectedTag] = useState(null);
    const [loading, setLoading] = useState(false);
    const [prevStateOfCatalog, setPrevStateOfCatalog] = useState();
    const idPunkt = localStorage.getItem('idPunkt');

    const getCatalogCallback = useCallback(async () => {
        if (!catalog){
            setLoading(true);
            await dispatch(getCatalogWizard());
            setLoading(false);
        }
    }, [takeId, complexId]);

    const {
        filteredState,
        filterValue,
        handleChangeFilterValue,
    } = useAssaysFilter(catalogForView);

    useEffect(() => {
        getCatalogCallback();
    }, [getCatalogCallback]);

    useEffect(() => {
        if (complexTransition && catalog && complexResearch) {
            setSelectedTag(null);
            dispatch(setSelectedTags([]));
            setCatalogForView([{superGroupName: 'Комплексні дослідження', idParent: 77, id: 77}, ...complexResearch].filter(item => !item.superGroupName));
        } else if (catalog && complexResearch && catalogForView && +catalogForView[0].idParent === 77) {
            setCatalogForView(null);
            setSelectedTag(null);
            dispatch(setSelectedTags([]));
        }
    }, [complexTransition]);

    useEffect(() => {
        if (!idPunkt) {
            localStorage.setItem('idPunkt', '476');
        }
    }, []);


    useEffect(() => {
        dispatch(getPunkts());
    }, []);

    return (<div className={cl.root}>
        {!loading && <SearchHeader useCatalogForView={[catalogForView, setCatalogForView]}
                                              useSelectedTag={[selectedTag, setSelectedTag]} setLoading={setLoading}
                                              usePrevState={[prevStateOfCatalog, setPrevStateOfCatalog]}
                                              handleValue={[filterValue, handleChangeFilterValue]} allFunc={allFunc}
        />}
        {loading
            ? <Loading/>
            : catalogForView
                ? <AutoSizerCatalog useCatalogForView={[filteredState, setCatalogForView]}
                                    useSelectedTag={[selectedTag, setSelectedTag]}
                                    usePrevState={[prevStateOfCatalog, setPrevStateOfCatalog]}
                                    handleValue={[filterValue, handleChangeFilterValue]}
                                    activeScrollIndex={true}
                />
                : <SuperGroupCards setCatalogForView={setCatalogForView} setSelectedTag={setSelectedTag}
                                   usePrevState={[prevStateOfCatalog, setPrevStateOfCatalog]}/>
        }
    </div>);
};

export default ProductCatalogEsculab;
