import React, {useEffect, useState} from "react";
import useStyles from "./styles";
import {Dialog, Slide, Snackbar, TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {getCatalog} from "@cabinet-packages/redux";
import IconAlert from './img/icn_allert.svg';
import {setRegionsVdoma} from "@cabinet-packages/redux/actions/actionsCreators";
import {Autocomplete} from "@material-ui/lab";
import {Loading, SwipeStyledButton} from "../../index";
import {amber, teal} from "@material-ui/core/colors";

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

const ClientRegionDialogVdoma = ({handleClose}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const idReg = localStorage.getItem('idReg');
    const {regions} = useSelector(state => state.collectionPoints);
    const [value, setValue] = useState(null);
    const [open, setOpen] = useState(false);
    const [snackBarText, setSnackbarText] = useState(null);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setSnackbarText('');
    };

    const handleChangeRegion = (e, value) => {
        e.persist();

        if (value && +value.idReg === 42) {
            const link = document.createElement('a');
            link.href = 'https://vdoma-wizard.medis.com.ua/';
            link.click();
            link.remove();
        } else {
            setValue(value);
        }
    };

    const handleConfirm = (e) => {
        e.persist();

        if (value) {
            if (+value.idReg !== +idReg) {
                localStorage.setItem('idReg', value.idReg + '');
                dispatch(getCatalog(true));
            }

            handleClose(e);
        } else {
            setOpen(true);
            setSnackbarText('Щоб підтвердити, оберіть місто з випадаючого списку.')
        }
    };

    useEffect(() => {
        if (!regions) {
            dispatch(setRegionsVdoma());
        } else if (regions && !value) {
            const foundedRegion = regions.find((item, idx) => idReg ? +item.idReg === +idReg : idx === 0);
            setValue(foundedRegion || regions[0]);
        }
    }, [regions]);

    return (<Dialog open={true} classes={{paper: cl.paper}}>
        <div className={cl.root}>
            <div className={cl.heading}>
                <h1 className={cl.headingText}>
                    УВАГА!
                </h1>
            </div>
            <div className={cl.innerWrapper}>
                <img src={IconAlert} alt="Alert icon." className={cl.iconAlert}/>
                <p className={cl.textAlert}>
                    У якому місті ви хочете викликати медичну сестру?
                </p>
                {regions
                    ? <Autocomplete
                        className={cl.autocomplete}
                        options={
                            [...regions, {
                                idParent: 5,
                                idReg: 42,
                                lat: 49.553516,
                                lng: 25.594767,
                                name: "Тернопіль*",
                                namer: "",
                                note: "",
                            }]
                        }
                        value={value}
                        onChange={handleChangeRegion}
                        noOptionsText={'Міста не знайдено.'}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" InputProps={{
                                ...params.InputProps,
                                classes: {
                                    root: cl.rootAutocomplete,
                                    notchedOutline: cl.notchedOutline,
                            }}}/>
                        )}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(item) => item.idReg === value.idReg}
                    />
                    : <Loading/>
                }
                <SwipeStyledButton
                    animationStart={'left'}
                    animationMaterialColor={teal[500]}
                    buttonMaterialColor={amber[500]}
                    onClick={handleConfirm}
                >
                    ПІДТВЕРДИТИ
                </SwipeStyledButton>
            </div>
            <Snackbar
                className={cl.snackbar}
                ContentProps={{
                    classes: {
                        root: cl.snackbar,
                        message: cl.snackbar,
                    }
                }}
                TransitionComponent={TransitionUp}
                open={open}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                message={<div className={cl.snackbarContentWrapper}>
                    <div className={cl.snackbarContentHeader}>
                        Увага!
                    </div>
                    <div className={cl.snackbarContentText}>
                        {snackBarText && snackBarText}
                    </div>
                </div >}
            >
            </Snackbar>
        </div>
    </Dialog>)
};

export default ClientRegionDialogVdoma;
