import React from "react";
import {useHistory} from "react-router-dom";
import logo from "../../assets/images/logo.png";
import {makeStyles, Tooltip} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {loginClient} from "@cabinet-packages/redux";
import {AccessKey} from "@cabinet-packages/helpers";
import themeMaterialUI from "../../settings/themeMaterialUI";

const useStyles = makeStyles(() => ({
        header: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            borderRadius: '0 0 25px 25px',
            '@media (max-width: 950px)': {
                flexDirection: 'row',
                justifyContent: 'center',
                paddingRight: '0px',
                width: '100%',
            },
        },
        headerWrapper: {
            display: 'flex',
            position: 'relative',
            flexDirection: 'row',
            justifyContent: 'center',
            height: '82px',
            '@media (max-width: 950px)': {
                padding: '5px',
                width: '100%',
                height: '100%',
            },
        },
        headerLogoWrapper: {
            '@media (max-width: 950px)': {
                width: '100%',
                marginLeft: '5px',
            },
        },
        headerLogoButton: {
            padding: 0,
            cursor: 'pointer',
        },
        headerLogo: {
            maxWidth: '144px',
            '@media (max-width: 950px)': {
                width: '60%',
            },
        },
}));

const Header = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { activePatient } = useSelector(state => state.patients);
    const accessKeyFromCookies = AccessKey.get();

    const handleClickOnAccount = () => {
        activePatient && accessKeyFromCookies
            ? history.push('/account-settings')
            : dispatch(loginClient(themeMaterialUI, history))
    };

    return (
        <div className={classes.header}>
            <div className={classes.headerWrapper}>
                <div className={classes.headerLogoWrapper}>
                    <div
                        className={classes.headerLogoButton}
                        onClick={handleClickOnAccount}
                    >
                        <Tooltip
                            title={accessKeyFromCookies && activePatient
                                ? "Натисніть щоб перейти в особистий кабінет."
                                : "Натисніть щоб виконати ВХІД / РЕЄСТРАЦІЮ."
                            }
                            arrow
                        >
                            <img
                                src={logo}
                                className={classes.headerLogo}
                                alt="Esculab logo"
                            />
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
