import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router";
import Header from "../components/Shop/Header";
import Footer from "../components/Shop/Footer";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    innerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '100vh',
        margin: '0 auto',
        maxWidth: '1056px',
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        maxWidth: '300px',
        maxHeight: '300px',
        margin: '0 auto',
    },
    contentText: {
        color: theme.palette.primary.dark,
        textAlign: 'center',
    },
    linkButton: {
        maxWidth: '300px',
        background: theme.palette.header.main,
        margin: '25px auto'
    },
}));

const PageNotFound = () => {
    const classes = useStyles();
    const history = useHistory();

    const handleTransition = (event) => {
        event.stopPropagation();
        event.preventDefault();

        history.push('/account-settings')
    };

    return (
        <div className={classes.root}>
            <div className={classes.innerWrapper}>
                <Header/>
                <div className={classes.contentWrapper}>
                    <div className={classes.contentText}>
                        <h1>404</h1>
                        <p>Сторінку не знайдено...</p>
                    </div>
                </div>
                <Button
                    variant="outlined"
                    color="default"
                    onClick={(event) => handleTransition(event)}
                    className={classes.linkButton}
                >
                    Повернутися до кабінету
                </Button>
            </div>
            <Footer/>
        </div>
    );
};

export default PageNotFound;
