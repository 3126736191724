import React from "react";
import useStyles from "../styles";
import {IconButton} from "@material-ui/core";

const InfoField = ({headingText, infoFieldText, InfoFieldIcon, changeStep, menuRef}) => {
    const cl = useStyles();

    const handleClick = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (changeStep) {
            changeStep();
        }
    };

    return (<div className={cl.infoFieldWrapper}>
        <p className={cl.infoFieldHeading}>{headingText && headingText}</p>
        <div className={cl.infoField} ref={menuRef} onClick={handleClick}>
            <p className={cl.infoFieldText}>{infoFieldText && infoFieldText}</p>
            {changeStep
                ? InfoFieldIcon && <IconButton size="small">
                    <InfoFieldIcon color="disabled" fontSize="small" />
                </IconButton>
                : InfoFieldIcon && <InfoFieldIcon color="disabled" fontSize="small"/>}
        </div>
    </div>);
};

export default InfoField;
