import React, {useContext, useEffect, useState} from "react";
import {FormControl, FormControlLabel, makeStyles, Radio, RadioGroup} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {setClient, setListOfPatientsSite} from "../../../../redux/actions/actionsCreators";
import {UserContext} from "../index";
import iconProfile from './../img/iconProfile.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
        // position: 'relative',
        // marginTop: 12,
        // bottom: 25,
        '& .MuiFormControl-root': {
            width: '100%',
            // position: 'relative',
            // overflowX: 'hidden',
        },
    },
    titleMain: {
        fontFamily: "'Museo Sans Cyrl 500'",
        color: theme.palette.primary.mainTitle,
        fontSize: '2.188rem',
        marginTop: 71,
        marginBottom: 32,
        '@media (max-width: 900px)': {
            fontSize: '1.25rem',
        }
    },
    text: {
        color: '#007AC2',
        fontSize: '1.25rem',
        margin: 0,
        'font-family': "'Museo Sans Cyrl 500'",
        '@media (max-width: 900px)': {
            fontSize: '16.5px',
        },
        '& a': {
            color: '#007AC2',
        }
    },
    rootListOfPatients: {
        // marginTop: 30,
        // marginBottom: 50,
    },
    wrapperListOfPatients: {
        width: 'Calc(100% + 184px)',
        position: 'relative',
        left: -103,
        maxHeight: 210,
        overflow: 'hidden',
        overflowY: 'auto',
        '@media (max-width: 900px)': {
            width: 'Calc(100% + 43px)',
            left: -17,
        },
        '& .MuiFormControlLabel-root': {
            display: 'flex',
            width: '100%',
            paddingLeft: 93,
            paddingRight: 103,
            paddingTop: 17,
            paddingBottom: 11,
            position: 'relative',
            left: 11,
            '@media (max-width: 900px)': {
                paddingLeft: 17,
                paddingRight: 26,
                position: 'relative',
            },
            '&:hover': {
                background: '#E9F6FF',
                color: '#0077C8',
            }
        },
        '& .MuiIconButton-colorPrimary': {
            color: '#0077C8',
        },
    },
    rootProfile: {
        display: 'grid',
        gridTemplateColumns: '30px 1fr',
        gridColumnGap: '25px',
        cursor: 'pointer',
        marginLeft: 15,
    },
    rootAddPatients: {
        paddingLeft: 42,
    },
    wrapperImgProfile: {
        display: 'flex',
        '& img': {
            width: '35px',
        },
    },
    titleProfile: {
        margin: 0,
        'font-family': "'Museo Sans Cyrl 700'",
        fontSize: '1.25rem',
        display: 'flex',
        alignItems: 'center',
        color: '#214572',
        textTransform: 'capitalize',
        // '@media (max-width: 900px)': {
        //     fontSize: 15,
        // },
    },

}))


const SelectedPatients = () => {
    const cl = useStyles()
    const {activePatient, listOfPatients} = useSelector(state => state.patients);
    const dispatch = useDispatch()
    const [gender, setGender] = useState(null);
    const [patients, setPatients] = useState(null);
    const context = useContext(UserContext);



    useEffect(() => {
        dispatch(setListOfPatientsSite());
    }, [activePatient])

    useEffect(() => {
        if (listOfPatients && listOfPatients.length > 0) {
            setPatients(listOfPatients[0])

        }
    }, [listOfPatients])

    useEffect(() => {
        if (listOfPatients && listOfPatients.length > 0 && activePatient) {
            const findActivePatients = listOfPatients.find(el => el.id === activePatient.id)
            if (findActivePatients) {
                setGender(findActivePatients.id)
            }
        }
    }, [listOfPatients, activePatient])


    const handleActivePatients = (event) => {
        const findPatients = listOfPatients.find(el => el.id === +event.target.value)
        handleActivePatient('iAgreeChecked', false)
        if (listOfPatients?.length > 0) {
            dispatch(setClient(findPatients))
            dispatch(setListOfPatientsSite())
        }
    }

    const handleActivePatient = (name, patientValue) => {
        context.values.setValues(prev => ({
            ...prev,
            [name]: patientValue,
        }));
    }




    return (

            <div className={cl.root}>
                 <p className={cl.text}>Для внесення змін у Ваші персональні дані зателефонуйте на Інформаційну лінію лабораторії
                   <br/> <a href="tel:0800503680">0 800 503 680</a>
                </p>


                {listOfPatients && gender &&
                    <div className={cl.rootListOfPatients}>
                        <FormControl component="fieldset">
                            <RadioGroup
                                value={gender}
                                onChange={(event) => handleActivePatients(event)}
                            >
                                <div className={cl.wrapperListOfPatients}>
                                    {patients &&
                                        <FormControlLabel
                                            value={patients.id}
                                            control={<Radio color="primary"/>}
                                            label={<div className={cl.rootProfile}>
                                        <span className={cl.wrapperImgProfile}>
                                            <img src={iconProfile} alt="iconProfile"/>
                                        </span>
                                                <p className={cl.titleProfile}>{patients?.lastname} {patients?.firstname} {patients?.fathername}</p>
                                            </div>}
                                        />}
                                </div>

                            </RadioGroup>
                        </FormControl>
                    </div>
                }
            </div>

    )
}

export default SelectedPatients