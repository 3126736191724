import React from 'react';
import CustomDialog from "../components/Dialogs/CustomDialog";
import ConfirmDialog from "../components/Dialogs/ConfirmDialog";
import {setClient, START_PHONE_SEND} from "@cabinet-packages/redux";
import {logout} from "../api/api";
import {AccessKey} from "./getSetAccessKey";

export const handleClickOnLogOut = (event, history, dispatch, themeMaterialUI, setHistoryPushWhenLogOut) => {
    if (event) {
        event.stopPropagation();
        event.preventDefault();
    }
    CustomDialog(ConfirmDialog,
        {text: 'Ви впевнені, що хочете вийти?',
            onConfirm: async () => {
                try {
                    logout();
                } catch (e) {
                    
                }
                localStorage.removeItem('activePatient');
                localStorage.removeItem('accessKey');
                localStorage.removeItem('accessToken');
                localStorage.removeItem('activePatientPhone');
                localStorage.removeItem('uuid');
                AccessKey.remove();
                window.location.reload();
                dispatch(setClient(null));
                dispatch({
                    type: START_PHONE_SEND,
                    payload: {
                        stage : 1,
                    },
                });
                setHistoryPushWhenLogOut
                    ? setHistoryPushWhenLogOut(true)
                    : history.push('/');
            }}, false,
        themeMaterialUI);
};
