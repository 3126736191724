export const adoptMaterial = (filling, fieldName) => {
    try {

        return {
            [fieldName === 'name' ? 'test' : fieldName]: filling.reduce((total, cur) => {

                return +total.indexOf(cur[fieldName]) === -1 ? [...total, cur[fieldName]] : [...total]
            }, [])
        };
    } catch (e) {
        // console.error(e);
    }

    return {[fieldName === 'name' ? 'test' : fieldName]: []};
};
