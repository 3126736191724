import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {Loading, Scroll} from "@cabinet-packages/components";
import {getCanShowFeedback, setCreateFeedback} from "@cabinet-packages/api/api";
import {useDispatch, useSelector} from "react-redux";
import {selectActivePatientResults} from "@cabinet-packages/redux";
import {Star} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '900px',
        display: 'grid',
        gridTemplateColumns: '1fr',
    },
    feedbackContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    feedbackRating: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        margin: '5px',
    },
    feedbackRatingHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '10px',
        '@media (max-width: 700px)': {
            flexDirection: 'column',
        },
    },
    feedbackRatingHeaderOrderInfo: {
        paddingLeft: '25px',
    },
    feedbackStarsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        color: theme.palette.primary.dark,
        width: '300px',

        '@media (max-width: 700px)': {
            paddingTop: '20px',
        },
    },
    feedbackStars: {
        display: 'flex',
        alignItems: 'center',
        width: '260px',
    },
    feedbackStarDisabled: {
        color: theme?.palette?.star?.color,
    },
    commentary: {
        padding: '5px',
        width: '100%',
    },
    sendFeedbackWrapper: {
        padding: '5px',
        width: '100%',
    },
    sendFeedbackButton: {
        width: '100%',
    },
    orderInfoText: {
        color: theme.palette.primary.dark,
        fontWeight: 600,
    },
    noFeedbackText: {
        textAlign: 'center',
        padding: '10px',
        width: '100%',
        color: theme.palette.primary.dark,
    },
    feedbackRatingBox: {
        whiteSpace: 'nowrap',
        marginLeft: '10px',
    },
    feedbackRatingStarWrapper: {
        width: '24px',
        height: '24px',
        position: 'relative',
    },
    feedbackRatingStarValue: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: theme.palette.primary.dark,
        fontSize: '8px',
        fontWeight: 600,
        zIndex: 1,
    },
}));

const FeedbackOfAssays = () => {
    const classes = useStyles();
    const { activePatientResults } = useSelector(state => state.patients);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(selectActivePatientResults());
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.feedbackContainer}>
                <Scroll>
                    {activePatientResults === null
                        ? <Loading/>
                        : activePatientResults.length > 0
                            ? activePatientResults.map(order => (<FeedbackCart order={order} key={order.idOrder}/>))
                            : <div className={classes.noFeedbackText}>У вас немає виконаних досліджень, щоб залишити відгук.</div>
                    }
                </Scroll>
            </div>
        </div>
    );
};

const FeedbackCart = ({order}) => {
    const classes = useStyles();
    const [value, setValue] = useState(4);
    const [hover, setHover] = useState(-1);
    const [textValue, setTextValue] = useState('');
    const [canUserShowFeedback, setCanUserShowFeedback] = useState(null);
    const { takeId } = useSelector(state => state.assaysCatalog);

    const handleInputValue = (value) => {
        setTextValue(value);
    };

    const handleSendFeedback = async () => {
        await setCreateFeedback(order.idOrder, value, textValue);
        setCanUserShowFeedback(value);
    };

    const labels = {
        1: 'Вкрай незадоволений',
        2: 'Незадоволений',
        3: 'Не зовсім задоволений',
        4: 'Задоволений',
        5: 'Повністю задоволений',
    };

    useEffect(() => {
        getCanShowFeedback(order.idOrder).then(data => {
            setCanUserShowFeedback(data)
        });
    }, []);

    return (<div className={classes.feedbackRating}>
        <div className={classes.feedbackRatingHeader}>
            <div className={classes.feedbackRatingHeaderOrderInfo}>
                <p>ID замовлення: <span className={classes.orderInfoText}>{order.idOrder}</span></p>
                <p>Адреса пункту: <span className={classes.orderInfoText}>{order.address}</span></p>
                <p>Час замовлення: <span className={classes.orderInfoText}>{order.dt}</span></p>
            </div>
            <div className={classes.feedbackStarsContainer}>
                {canUserShowFeedback === 0
                    ? <><p>Чи задоволені Ви візитом у {takeId === 216 ? 'МеДіС' : 'Ескулаб'}?</p>
                            <div className={classes.feedbackStars}>
                                <Rating
                                    name="hover-feedback"
                                    value={value}
                                    precision={1}
                                    emptyIcon={<StarBorderIcon fontSize="inherit"/>}
                                    IconContainerComponent={(props) => {
                                        const { value, ...other } = props;

                                        return (
                                            <div
                                                className={classes.feedbackRatingStarWrapper}
                                                onClick={event => setValue(value)}
                                            >
                                                <span className={classes.feedbackRatingStarValue}>{value}</span>
                                                <span {...other}/>
                                            </div>
                                        );
                                    }}
                                    onChangeActive={(event, newHover) => {
                                        setHover(newHover);
                                    }}
                                />
                                <Box ml={3} className={classes.feedbackRatingBox}>{labels[hover !== -1 ? hover : value]}</Box>
                            </div> </>
                    : canUserShowFeedback !== null && <>
                        <p>Ваша попередня оцінка:</p>
                        <div className={classes.feedbackStars}>
                            <Rating
                                name="hover-feedback"
                                value={canUserShowFeedback}
                                disabled={true}
                                precision={1}
                                emptyIcon={<StarBorderIcon
                                    fontSize="inherit"
                                />}
                                icon={<Star
                                    fontSize="inherit"
                                    className={classes.feedbackStarDisabled}
                                />}
                                IconContainerComponent={(props) => {
                                    const { value, ...other } = props;

                                    return (
                                        <div className={classes.feedbackRatingStarWrapper}>
                                            <span className={classes.feedbackRatingStarValue}>{value}</span>
                                            <span {...other}/>
                                        </div>
                                    );
                                }}
                            />
                            <Box ml={3} className={classes.feedbackRatingBox}>{labels[canUserShowFeedback]}</Box>
                        </div>
                    </>}
            </div>
        </div>

        {canUserShowFeedback === 0
            ? <div>
                <TextField
                    label={value > 3 ? "Напишіть свій відгук тут" : "Уточніть, будь ласка, що саме Вам не сподобалось?"}
                    multiline={true}
                    rows={4}
                    className={classes.commentary}
                    variant="outlined"
                    onChange={(event) => handleInputValue(event.target.value)}
                    value={textValue}
                />
                <div className={classes.sendFeedbackWrapper}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.sendFeedbackButton}
                        disabled={value === null || value < 4 && textValue.length < 5}
                        onClick={() => handleSendFeedback()}
                    >
                        Відправити відгук
                    </Button>
                </div>
            </div>
            : canUserShowFeedback === null
                ? <div className={classes.noFeedbackText}><Loading/></div>
                : <div className={classes.noFeedbackText}>Для цього замовлення більше не можна залишити відгук.</div>}
    </div>);
}

export default FeedbackOfAssays;
