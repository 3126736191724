import React, {useCallback, useEffect, useState} from 'react';
import {Button, FormControl, makeStyles, MenuItem, Select, Typography} from "@material-ui/core";
import {getOpenSchedule,} from "@cabinet-packages/api/api";
import {CustomDialog, SelectPartnerPatient} from "@cabinet-packages/components";
import moment from 'moment';
import 'moment/locale/uk';
import {useDispatch, useSelector} from "react-redux";
import {getPunkts, setAssayTime} from "@cabinet-packages/redux";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        color: '#E5E5E5',
    },
    innerWrapper: {
        width: '100%',
        maxWidth: '1280px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: '0 auto',
        position: 'relative',

    },
    loadingWrapper: {
        width: '100vw',
        height: '100vh',
    },
    header: {
        width: '100%',
        minHeight: '180px',
        padding: '25px 48px',
        background: '#E9F6FF',

        '@media (max-width: 950px)': {
            padding: '14px 20px',
            minHeight: '200px',
        },
    },
    headerText: {
        color: '#0077c8',

        '@media (max-width: 950px)': {
            fontSize: '20px',
        },
    },
    dashedBorderBottom: {
        width: '100%',
        height: '0.5px',
        marginTop: '5px',
        borderBottom: '0.5px dashed #0077c880',
    },
    downHeader: {
        marginTop: '10px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    price: {
        fontSize: '30px',
        color: '#ff0000',
        marginLeft: '10px',
        paddingRight: '25px',
        borderRight: '0.5px dashed #0077c880',
        marginRight: '25px',

        '@media (max-width: 950px)': {
            fontSize: '20px',
        },
    },
    termsText: {
        fontSize: '20px',
        color: '#0077c8',
        margin: '0px 15px',

        '@media (max-width: 950px)': {
            display: 'none'
        },
    },
    termsValue: {
        color: '#0077c8',
        fontSize: '22px',

        '@media (max-width: 950px)': {
            fontSize: '20px',
            paddingLeft: '16px'
        },
    },
    selectPunktWrapper: {
        width: '100%',
        height: '400px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',

        '@media (max-width: 950px)': {
            height: 'max-content'
        }
    },
    selectPunktList: {
        width: '38%',
        height: '100%',
        padding: '22px 4px 0px 48px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        '@media (max-width: 950px)': {
            padding: '19px 4px 0px 20px',
            width: '100%',
            justifyContent: 'flex-start',
            height: 'max-content'
        },
    },
    mapWrapper: {
        height: '100%',
        width: '65%',

        '@media (max-width: 950px)': {
            display: 'none',
        },
    },
    selectPunktListHeader: {
        fontWeight: '600',
        fontSize: '26px',
        color: '#0077c8',

        '@media (max-width: 950px)': {
            fontSize: '18px',
            marginBottom: '16px'
        },
    },
    selectPunktListHeaderDate: {
        fontWeight: '600',
        fontSize: '26px',
        color: '#0077c8',
        padding: '30px 0px 0px 48px',

        '@media (max-width: 950px)': {
            fontSize: '18px',
            padding: '20px 20px 0px 20px',
        },
    },
    PravkyListHeader: {
        fontWeight: '600',
        fontSize: '26px',
        color: '#0077c8',
        margin: '0 16px',
        height: '42px',
        display: 'flex',
        alignItems: 'center',

        '@media (max-width: 950px)': {
            fontSize: '18px',
        },
    },
    PravkyChechboxWrapper: {
        height: '42px'
    },
    PravkyChechboxWrapperEng: {
        height: '42px',
        marginBottom: '8px',

        '@media (max-width: 950px)': {
            width: '100%'
        },
    },
    radio: {
        fontSize: '14px',
        color: '#0077c8',
    },
    getClosestPunktButton: {
        maxWidth: '265px',

        '@media (max-width: 950px)': {
            display: 'none',
        },
    },
    personalDataWrapper: {
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '30px 4px 0px 0px',

        '@media (max-width: 950px)': {
            flexDirection: 'column',
            padding: '20px',
        },
    },
    dateNameEmail: {
        width: 'calc(38% - 48px)',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginLeft: '48px',

        '@media (max-width: 950px)': {
            width: '100%',
            marginLeft: '0px',
        },
    },
    svgImage: {
        width: '20px',
        height: '20px',

        '@media (max-width: 950px)': {
            width: '16px',
            height: '16px',
        },
    },
    selectTakeDate: {
        width: '100%',
        maxWidth: '400px',
        margin: 'o auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '24px',

        '@media (max-width: 950px)': {
            marginTop: '0px',
        },
    },
    dropdownWrapper: {
        width: '30%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dropdownLabel: {
        textAlign: 'center',
        fontSize: '16px',
        color: '#0077c8',
        marginBottom: '6px',
    },
    formControl: {
        width: 90,
    },
    formControlDateSelect: {
        '&:focus': {
            backgroundColor: 'none',
        },
    },
    nameEmailWrapper: {
        marginTop: '8px',

        '@media (max-width: 950px)': {
            display: 'none',
        },
    },
    nameEmailWrapperMobile: {
        marginTop: '8px',
        display: 'none',

        '@media (max-width: 950px)': {
            display: 'block',
        },
    },
    redStar: {
        color: 'red'
    },
    textFieldLabel: {
        fontSize: '16px',
        color: '#214572',
    },
    textFieldLabelGender: {
        fontSize: '14px',
        color: '#214572',
    },
    textField: {
        width: '100%',
        maxWidth: '256px',
        marginTop: '40px',

        '@media (max-width: 950px)': {
            marginTop: '8px',
            maxWidth: '100%',
        },
    },
    firstnamePhone: {
        width: '38%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        '@media (max-width: 950px)': {
            paddingTop: '0px',
            width: '100%',
            display: 'none',
        },
    },
    PravkyWrapper: {
        height: '97px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        paddingBottom: '8px',

        '@media (max-width: 950px)': {
            display: 'none',
        },
    },
    PravkyMobile: {
        display: 'none',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        paddingBottom: '8px',

        '@media (max-width: 950px)': {
            display: 'flex',
        },
    },
    Pravky: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',

        '@media (max-width: 950px)': {
            flexDirection: 'column',
        },
    },
    fathernameGender: {
        width: '30%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '47px',

        '@media (max-width: 950px)': {
            paddingTop: '0px',
            width: '100%',
            display: 'none',
        },
    },
    genderGroup: {
        marginTop: '30px',
    },
    paymentTypeGroup: {
        marginTop: '30px',

        '@media (max-width: 950px)': {
            marginTop: '0px',
        },
    },
    personalDataWithCheckboxes: {
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '45px 4px 0px 0px',

        '@media (max-width: 950px)': {
            padding: '8px 4px 0px 0px',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
    },
    birthdayWrapper: {
        width: 'calc(38% - 48px)',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginLeft: '48px',

        '@media (max-width: 950px)': {
            width: '100%',
            marginLeft: '0px',
            padding: '0px 20px 0px 20px',
        },
    },
    birthdayText: {
        fontSize: '18px',
        color: '#0077c8',

        '@media (max-width: 950px)': {
            marginBottom: '8px',
        },
    },
    formCheckboxes: {
        marginTop: '40px',

        '@media (max-width: 950px)': {
            marginTop: '30px',
        },
    },
    formCheckboxesTermsOfUse: {
        marginTop: '40px',

        '@media (max-width: 950px)': {
            marginTop: '0px',
        },
    },
    checkboxLabel: {
        color: '#0077c880',
        fontSize: '14px',

        '@media (max-width: 950px)': {
            fontSize: '10px',
        },
    },
    checkboxLabelPravky: {
        color: '#0077c8',
        fontSize: '14px',

        '@media (max-width: 950px)': {
            fontSize: '10px',
        },
    },
    checkboxLabelPravkyEng: {
        color: '#0077c8',
        fontSize: '14px',

        '@media (max-width: 950px)': {
        },
    },
    checkboxLabelPravkyDontKnow: {
        color: '#0077c8',
        fontSize: '14px',
    },
    checkboxLabelHref: {
        textDecoration: 'none',
        color: '#0077c8',
    },
    termsOfUse: {
        width: '65%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '112px',

        '@media (max-width: 950px)': {
            width: '100%',
            padding: '0px 20px 20px 20px',
        },
    },
    paymentWrapper: {
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        padding: '45px 4px 0px 0px',

        '@media (max-width: 950px)': {
            width: '100%',
            padding: '0px 20px 20px 20px',
            flexDirection: 'column',
        },
    },
    paymentActions: {
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '32px 8px 8px 8px',
        maxWidth: '500px',

        '@media (max-width: 950px)': {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: '100%',
        },
    },
    liqpayText: {
        color: '#b8c1a1',
    },
    actionPaymentButton: {
        width: 250,
        height: 70,
        borderRadius: '30px',
        margin: '40px 0px 40px 0px',
        fontSize: '20px',

        '@media (max-width: 950px)': {
            width: 200,
            height: 50,
            fontSize: '18px',
            margin: '20px auto 20px auto',
        },
    },
    confirmPhoneWrapper: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    error: {
        color: 'red',
    },
    paymentCash: {
        color: theme.palette.primary.main,
        textAlign: 'center',
    },
    preparationRules: {
        width: '100%',
        height: '100%',
        padding: '0px 4px 40px 48px',
        marginBottom: '40px',

        '@media (max-width: 950px)': {
            padding: '0px 20px',
        }
    },
}));

moment.locale('uk');

const monthsArray = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];
const daysEngArray = ['mon', 'tue', 'wen', 'ths', 'fry', 'sat', 'sun'];
const birthdayYearArray = Array.from({length: 100}, (_, i) => i + 1921 + 1);

const AssaySchedulerTimePartner = ({isComponentInShop, dataPickers}) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const {themeMaterialUI} = useSelector(store => store.theme);
    const {clientLocation, punkts} = useSelector(store => store.collectionPoints);
    const [scheduleForPunkts, setScheduleForPunkts] = useState(null);
    const { orderingList, totalPrice, amountOfItems, selectedPunkt} = useSelector(state => state.orderAssays);
    const [scheduleForSelectedPunkt, setScheduleForSelectedPunkt] = useState(null);
    const [scheduleForSelectedPunktOnCurrentDay, setScheduleForSelectedPunktOnCurrentDay] = useState(null);
    const [selectedPoint, setSelectedPoint] = useState(selectedPunkt);
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [hour, setHour] = useState('');
    const [daysArray, setDaysArray] = useState(null);
    const [timeArray, setTimeArray] = useState(null);
    const currentYearNumber = moment().year();
    const currentMonthNumber = moment().month();
    const currentDayNumber = moment().date();

    const [checkboxState, setCheckboxState] = useState({
        checkedA: false,
        checkedB: false,
        checkedC: false,
        checkedD: false,
        checkedE: false,
    });

    const handleSelectPatient = () => {
        const date = moment().month(month).date(+day).format('YYYY-MM-DD') + ' ' + hour;

        dispatch(setAssayTime({date}))
        CustomDialog(SelectPartnerPatient, {
            text: 'Увага! Аналізи, що входять в одне замовлення, потрібно здати під час одного відвідування відділення.',
            themeMaterialUI,
        }, false, themeMaterialUI);
    };

    const handleChangeMonth = (event) => {
        setMonth(event.target.value);
        setDaysArray(Array.from({length: moment().month(event.target.value).endOf('month').date()}, (_, i) => i + 1));
        setDay('');
        setHour('');
        setTimeArray(null);
        setScheduleForSelectedPunktOnCurrentDay(null)
    };

    const handleChangeDay = (event) => {
        setDay(event.target.value);
        setHour('');
        const weekday = moment().month(month).date(event.target.value).weekday();

        setTimeArray(
            selectedPoint.onLineHours
                ? selectedPoint.onLineHours[weekday][daysEngArray[weekday]].sceduler
                : selectedPoint.takingHours[weekday][daysEngArray[weekday]].sceduler
        );
        setScheduleForSelectedPunktOnCurrentDay(scheduleForSelectedPunkt.filter(item => {
            const assayDt = moment(item.dt, 'DD.MM.YYYY hh:mm');
            const selectedMonth = moment().month(month);

            return assayDt.month() === selectedMonth.month()
                && assayDt.year() >= selectedMonth.year()
                && +assayDt.date() === +event.target.value;
        }));
    };

    const handleChangeHour = (event) => {
        setHour(event.target.value);
    };


    const getScheduleForAllPunkts = useCallback(async () => {
        if (!scheduleForPunkts && selectedPoint) {
            setScheduleForPunkts(await getOpenSchedule())
        }
    }, [scheduleForPunkts, selectedPoint]);

    useEffect(() => {
        getScheduleForAllPunkts();
    }, [getScheduleForAllPunkts])

    useEffect(() => {
        dispatch(getPunkts());

        if (scheduleForPunkts && !scheduleForSelectedPunkt && selectedPoint) {
            setScheduleForSelectedPunkt(scheduleForPunkts.filter(item => +item.idPunkt === +selectedPoint.idPunkt))
        }

    }, [clientLocation, punkts, selectedPoint, dispatch, scheduleForPunkts, scheduleForSelectedPunkt]);

    return (<div className={classes.root}>
        <Typography variant="h6" style={{color: 'black', padding: '8px'}}>
            {selectedPoint && selectedPoint.address && selectedPoint.address}
        </Typography>
        <div className={classes.selectTakeDate}>
            <div className={classes.dropdownWrapper}>
                <p className={classes.dropdownLabel}>Місяць</p>
                <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    size="small"
                    disabled={checkboxState.checkedD}
                    color="primary"
                >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={month}
                        onChange={handleChangeMonth}
                        className={classes.formControlDateSelect}
                        disabled={!selectedPoint}
                    >
                        {
                            monthsArray.map((month, idx) => {

                                return (
                                    <MenuItem
                                        key={idx + month}
                                        value={month}
                                        disabled={idx < currentMonthNumber}
                                    >
                                        {month}
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
            </div>
            <div className={classes.dropdownWrapper}>
                <p className={classes.dropdownLabel}>День</p>
                <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    size="small"
                    disabled={!daysArray || checkboxState.checkedD}
                >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={day}
                        onChange={handleChangeDay}
                        className={classes.formControlDateSelect}
                        disabled={!selectedPoint}
                    >
                        {
                            daysArray && daysArray.map((day, idx) => {

                                return (
                                    <MenuItem
                                        key={day + 'day' + idx}
                                        value={day}
                                        disabled={
                                            (+currentMonthNumber === +moment().month(month).month()
                                                && day < moment().month(month).date())
                                            || moment().month(month).date(day).weekday() === 6
                                        }
                                    >
                                        {day}
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
            </div>
            <div className={classes.dropdownWrapper}>
                <p className={classes.dropdownLabel}>Година</p>
                <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    size="small"
                    disabled={day.length === 0 || checkboxState.checkedD}
                >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={hour}
                        onChange={handleChangeHour}
                        className={classes.formControlDateSelect}
                        disabled={!selectedPoint}
                    >
                        {
                            timeArray && timeArray.map((time, idx) => {
                                const splitTime = time.split(':');

                                const isTimeInCurrentDay = +currentMonthNumber === +moment().month(month).month()
                                    && +currentDayNumber === moment().month(month).date(+day).date()
                                    && moment().hour() >= +splitTime[0]

                                const isDisabled = scheduleForSelectedPunktOnCurrentDay
                                    && scheduleForSelectedPunktOnCurrentDay.length > 0
                                    && scheduleForSelectedPunktOnCurrentDay.filter(item => {

                                        return +moment(item.dt, 'DD.MM.YYYY hh:mm').hour() === +splitTime[0]
                                            && +moment(item.dt, 'DD.MM.YYYY hh:mm').minute() === +splitTime[1]
                                    })

                                return (
                                    <MenuItem
                                        key={time + idx}
                                        value={time}
                                        disabled={
                                            (isDisabled && isDisabled.length >= selectedPoint.patientCount)
                                            || isTimeInCurrentDay
                                        }
                                    >
                                        {time}
                                    </MenuItem>);
                            })
                        }
                    </Select>
                </FormControl>
            </div>
        </div>
        <div className={classes.paymentActions}>
            <Button
                color="primary"
                variant="outlined"
                onClick={() => history.push('/order-collection-points')}
                style={{margin: '8px'}}

            >
                Повернутись назад
            </Button>
            <Button
                color="primary"
                variant="contained"
                onClick={handleSelectPatient}
                style={{margin: '8px'}}
                disabled={ !month || !day || !hour }
            >
                Підтвердити пацієнта
            </Button>
        </div>
    </div>)
};

export default AssaySchedulerTimePartner;
