import {getTableHours} from "./getTableHours";

const days = [ "sun", "mon", "tue", "wen", "ths", "fry", "sat" ];
const daysCyr = ["нд", "пн", "вт", "ср", "чт", "пт", "сб" ];

const adoptDay = (day) => {
    const index = daysCyr.indexOf(day);

    return days[index];
};

const applyREGEX = (dayName) => {
    return dayName.replace(".", '').replace(":", '').toLowerCase();
};

export const adoptDayRange = (key) => {
    const dayIn =  key.split("-");
    const index1 = daysCyr.indexOf(applyREGEX(dayIn[0]));
    if (dayIn.length < 2) {

        return [adoptDay(applyREGEX(dayIn[0]))];
    }
    const index2 = daysCyr.indexOf(applyREGEX(dayIn[1]));

    return daysCyr.filter((dayCyr, index) => index >= index1 && index <=index2).map((day) => adoptDay(day))
};

export const adoptWorkingHours = (key) => {
    const hours =  key.split("-");
    if (hours.length < 2) {

        return {from: 0, to : 0, sceduler : []};
    }
    const from = hours[0].split(":")[0];
    const to = hours[1].split(":")[0];
    const minutesFrom = hours[0].split(":")[1];
    const minutesTo = hours[1].split(":")[1];

    return {from : parseInt(from), to : parseInt(to), sceduler : getTableHours(from, to, minutesFrom, minutesTo)};
}

export const adoptWorkingHoursVdoma = (key) => {
    const hours =  key.split("-");
    if (hours.length < 2) {

        return {from: 0, to : 0, sceduler : []};
    }
    const from = hours[0].split(":")[0];
    const to = hours[1].split(":")[0];
    const minutesFrom = hours[0].split(":")[1];
    const minutesTo = hours[1].split(":")[1];

    return {from : parseInt(from), to : parseInt(to), sceduler : getTableHours(from, to, minutesFrom, minutesTo)};
}
