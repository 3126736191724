import React from 'react';
import {Dialog, DialogContent,} from '@material-ui/core';
import {AddNewPatient} from "@cabinet-packages/pages";
import Button from "@material-ui/core/Button";

function AddNewPatientDialog({onConfirm = () => null, onCancel = () => null, handleClose}) {

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
        >
            <DialogContent style={{display: "flex", flexDirection: 'column', alignItems: "center"}}>
              <AddNewPatient isInDialog={true}/>
              <Button
                  onClick={handleClose}
                  color="primary"
                  variant="contained"
              >
                  закрити діалог
              </Button>
            </DialogContent>
        </Dialog>
    );
}

export default AddNewPatientDialog;
