import React from 'react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import {MainNavigation} from "@cabinet-packages/components";
import {
    ActionsDetails,
    AssayInfo,
    CatalogEsculab,
    CatalogTable,
    CollectionPoints,
    ComplexDetails,
    OrdersHistory,
    PatientCard,
    ResearchStatus,
} from '@cabinet-packages/pages';
import UpdateIcon from "@material-ui/icons/Update";
import OrderManager from "../pages/OrderManager";
import AppsIcon from "@material-ui/icons/Apps";
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import Authentication from "../pages/Authentication";
import OrderCollectionPoints from "../pages/OrderCollectionPoints";
import PartnerOrderedAssays from "@cabinet-packages/pages/PartnerOrderedAssays";
import AssaySchedulerTimePartner from "@cabinet-packages/components/scduler/AssaySchedulerTimePartner";
import SearchPatientResults from "../pages/SearchPatientResults";
import {FindInPageTwoTone} from "@material-ui/icons";
import ComprehensiveResearchCards from "esculab-patient-cabinet/src/pages/ComprehensiveResearchCards";
import FormatLineSpacingIcon from "@material-ui/icons/FormatLineSpacing";
import LiqPayPage from "../pages/LiqPayPage";

const routes = [
    {
        path: '/search-patient-results',
        component: () => <SearchPatientResults/>,
        label: 'Пошук результатів',
        showInNavigation: true,
        underAuth : true,
        icon: <FindInPageTwoTone color="inherit" fontSize="inherit"/>,
    },
    {
        path: '/patient-orders-history',
        component: () => <OrdersHistory/>,
        label: 'Замовлені дослідження',
        underAuth : true,
        showInNavigation: false,
        icon: <UpdateIcon color="inherit" fontSize="inherit"/>,
    },
    {
        path: '/collection-points',
        component: () => <CollectionPoints/>,
        label: 'Пункти забору',
        showInNavigation: true,
        underAuth : true,
        icon: <LocationOnIcon color="inherit" fontSize="inherit"/>,
    },
    {
        path: '/order-collection-points',
        component: () => <OrderCollectionPoints/>,
        label: 'Вибір пункту забору',
        showInNavigation: false,
        underAuth : true,
        icon: <LocationOnIcon color="inherit" fontSize="inherit"/>,
    },
    {
        path: '/research-status',
        underAuth : true,
        component: () => <ResearchStatus/>,
        showInNavigation: false,
        icon: <PlaylistAddIcon color="inherit" fontSize="inherit"/>,
    },
    {
        path: '/main-navigation',
        component: () => <MainNavigation/>,
        label: 'Головне меню',
        showInNavigation: false,
        underAuth : true,
    },
    {
        path: '/actions-details',
        component: () => <ActionsDetails/>,
        label: 'Деталі акції',
        underAuth : true,
        showInNavigation: false,
    },
    {
        path: '/complex-info',
        component: () => <ComplexDetails/>,
        label: 'Деталі комплексу',
        underAuth : true,
        showInNavigation: false,
    },
    {
        path: '/catalog-table',
        component: () => <CatalogTable/>,
        label: 'Панелі',
        underAuth : true,
        showInNavigation: false,
        icon: <AppsIcon color="inherit" fontSize="inherit"/>,
    },
    {
        path: '/catalog',
        component: () => <CatalogEsculab/>,
        underAuth : true,
        label: 'Перелік досліджень',
        showInNavigation: true,
        icon: <PlaylistAddIcon color="inherit" fontSize="inherit"/>,
    },
    {
        path: '/patient-card',
        component: () => <PatientCard/>,
        underAuth : true,
        label: 'Карта пацієнта',
        showInNavigation: false,
    },
    // {
    //     path: '/account-settings',
    //     component: () => <AccountSettings/>,
    //     underAuth : true,
    //     label: 'Особистий кабінет лікаря',
    //     showInNavigation: false,
    // },
    // {
    //     path: '/add-new-patient',
    //     component: () => <AddNewPatient/>,
    //     underAuth : true,
    //     label: 'Додавання нового пацієнта',
    //     showInNavigation: false,
    // },
    // {
    //     path: '/notification-manager',
    //     component: () => <NotificationManager/>,
    //     label: 'Сповіщення',
    //     underAuth : true,
    //     showInNavigation: false,
    // },
    {
        path: '/assay-info',
        component: () => <AssayInfo/>,
        underAuth : true,
        label: 'Інформація про дослідження',
        showInNavigation: false,
    },
    // {
    //     path: '/auth',
    //     component: () => <AuthPage/>,
    //     underAuth : false,
    //     hasOwnPage : true,
    //     showInNavigation: false,
    // },
    {
        path: '/auth',
        component: () => <Authentication/>,
        underAuth : false,
        hasOwnPage : true,
        showInNavigation: false,
    },
    {
        path: '/order-manager',
        underAuth : true,
        component: () => <OrderManager/>,
        label: 'Керування замовленнями',
        showInNavigation: false,
    },
    {
        path: '/doctor-ordered-assays',
        component: () => <PartnerOrderedAssays/>,
        underAuth : true,
        label: 'Призначені дослідження',
        showInNavigation: true,
        icon: <LibraryBooksOutlinedIcon color="inherit" fontSize="inherit"/>,
    },
    {
        path: '/assay-scheduler-time',
        component: () => <AssaySchedulerTimePartner/>,
        label: 'Вибір часу',
        showInNavigation: false,
        underAuth : true,
    },
    {
        path: '/comprehensive-research',
        component: () => <ComprehensiveResearchCards/>,
        label: 'Комплексні дослідження',
        underAuth : true,
        showInNavigation: true,
        icon: <FormatLineSpacingIcon color="inherit" fontSize="inherit"/>,
    },
    {
        path: '/liqPayPage/:id',
        component: () => <LiqPayPage/>,
        label: 'Оплата',
        underAuth : false,
        showInNavigation: false,
    }
];

export default routes;
