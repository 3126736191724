import {
    addAssayToOrderList,
    addPatient,
    addSelectedPunkt,
    clearOrderedAssays,
    clearTakesFromBasket,
    getCatalog,
    getClientLocation,
    getPunkts,
    getSchedule,
    getSelectRegion,
    loginClient,
    removeAssayFromOrderList,
    selectActivePatient,
    selectActivePatientResults,
    selectActivePatientResultsPage,
    setAccessKeyForLogin,
    setAssayLine,
    setAssayTime,
    setBarcode,
    setBasket,
    setCabinetActiveTab,
    setCatalogFilteredValue,
    setClient,
    setComplexId,
    setCreatePatient,
    setDoctorOrderedAssays,
    setIsAdmin,
    setListOfPatients,
    setOrderDate,
    setOrderNote,
    setOrderPunkt,
    setPromoCode,
    setRegionConfirmation,
    setResultsByOrderId,
    setSelectedAction,
    setSelectedComplex,
    setSelectedTags,
    setTableId,
    setTags,
    setTents,
    setTheme,
    setToggleChecked,
    setTurnBack,
    toggleNotification
} from "./actions/actionsCreators";
import {
    ADD_ASSAY_TIME,
    ADD_ASSAY_TIME_LINE,
    ADD_ASSAY_TO_ORDER_LIST,
    ADD_NEW_LIST_OF_PATIENTS,
    ADD_ORDER_NOTE,
    ADD_SELECTED_PUNKT,
    CATALOG_TABLE_ID,
    CLEAR_ORDERED_ASSAYS,
    CLEAR_TAKES_FROM_BASKET,
    GO_TO_COLLECTION_POINTS,
    REMOVE_ASSAY_FROM_ORDER_LIST,
    SELECT_ACTIVE_PATIENT,
    SELECT_ACTIVE_PATIENT_RESULTS,
    SET_ACCESS_KEY,
    SET_ACTIVE_TAB,
    SET_CATALOG,
    SET_CATALOG_FILTERED_VALUE,
    SET_CLIENT_LOCATION,
    SET_COLLECTION_POINTS,
    SET_COMPLEX_ID,
    SET_COMPLEX_RESEARCH_TRANSITION,
    SET_CREATE_PATIENT,
    SET_DOCTOR_ORDERED_ASSAYS,
    SET_IS_ADMIN,
    SET_LIST_OF_PATIENTS,
    SET_MATERIAL_THEME,
    SET_PROMO_CODE,
    SET_REGION_CONFIRMATION,
    SET_SCHEDULE,
    SET_SELECTED_ACTION,
    SET_TOGGLE_CHECKED,
    SET_TURN_BACK,
    START_PHONE_SEND,
    TOGGLE_NOTIFICATION,
    UPDATE_ACTIVE_ORDER_DATE,
    UPDATE_ACTIVE_ORDER_PUNKT,
    UPDATE_ACTIVE_PATIENT,
} from "./actions/actionsTypes";
import assaysCatalogReducer from "./reducers/assaysCatalogReducer";
import collectionPointsReducer from "./reducers/collectionPointsReducer";
import orderAssaysReducer from "./reducers/orderAssaysReducer";
import patientsReducer from "./reducers/patientsReducer";
import scheduleReducer from "./reducers/scheduleReducer";
import userReducer from "./reducers/userReducer";
import store from "./store";

export {
    loginClient,
    selectActivePatient,
    selectActivePatientResults,
    selectActivePatientResultsPage,
    toggleNotification,
    addAssayToOrderList,
    removeAssayFromOrderList,
    addSelectedPunkt,
    clearOrderedAssays,
    setListOfPatients,
    setClient,
    getCatalog,
    getPunkts,
    getSelectRegion,
    getSchedule,
    setAssayTime,
    setAssayLine,
    setOrderNote,
    setTableId,
    setTurnBack,
    setCreatePatient,
    setSelectedComplex,
    setToggleChecked,
    setSelectedAction,
    setResultsByOrderId,
    clearTakesFromBasket,
    setDoctorOrderedAssays,
    setCatalogFilteredValue,
    getClientLocation,
    setPromoCode,
    setRegionConfirmation,
    addPatient,
    setBasket,
    setTags,
    setSelectedTags,
    setOrderPunkt,
    setOrderDate,
    SELECT_ACTIVE_PATIENT,
    SELECT_ACTIVE_PATIENT_RESULTS,
    SET_LIST_OF_PATIENTS,
    UPDATE_ACTIVE_PATIENT,
    TOGGLE_NOTIFICATION,
    ADD_ASSAY_TO_ORDER_LIST,
    REMOVE_ASSAY_FROM_ORDER_LIST,
    CLEAR_ORDERED_ASSAYS,
    SET_COLLECTION_POINTS,
    ADD_SELECTED_PUNKT,
    SET_CATALOG,
    SET_TOGGLE_CHECKED,
    SET_CLIENT_LOCATION,
    SET_SCHEDULE,
    ADD_ASSAY_TIME,
    ADD_ASSAY_TIME_LINE,
    ADD_ORDER_NOTE,
    CATALOG_TABLE_ID,
    SET_TURN_BACK,
    SET_CREATE_PATIENT,
    SET_COMPLEX_RESEARCH_TRANSITION,
    SET_SELECTED_ACTION,
    START_PHONE_SEND,
    SET_ACCESS_KEY,
    SET_MATERIAL_THEME,
    SET_COMPLEX_ID,
    CLEAR_TAKES_FROM_BASKET,
    GO_TO_COLLECTION_POINTS,
    SET_DOCTOR_ORDERED_ASSAYS,
    SET_CATALOG_FILTERED_VALUE,
    SET_PROMO_CODE,
    SET_REGION_CONFIRMATION,
    ADD_NEW_LIST_OF_PATIENTS,
    SET_ACTIVE_TAB,
    SET_IS_ADMIN,
    UPDATE_ACTIVE_ORDER_PUNKT,
    UPDATE_ACTIVE_ORDER_DATE,
    assaysCatalogReducer,
    collectionPointsReducer,
    orderAssaysReducer,
    patientsReducer,
    scheduleReducer,
    userReducer,
    setAccessKeyForLogin,
    store,
    setComplexId,
    setTheme,
    setTents,
    setBarcode,
    setCabinetActiveTab,
    setIsAdmin
};
