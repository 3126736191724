import React, {useEffect, useState} from 'react';
import {Button, makeStyles, TextField,} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import {getCatalog, setSelectedComplex,} from "@cabinet-packages/redux";
import {useHistory} from "react-router";
import {Loading, MediaCard, Scroll} from "@cabinet-packages/components";
import {useFilter} from "@cabinet-packages/hooks";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        overflow: 'auto',
    },
    rootWrapper: {
        justifyItems: 'center',
        padding: '10px',
        width: '100%',
        margin: '0 auto',
        display: 'grid',
        gridGap: 10,
        gridTemplateColumns: '1fr 1fr 1fr',
        '@media (max-width:1200px)': {
            justifyItems: 'center',
            gridTemplateColumns: '1fr 1fr',
            width: '100%',
        },
        '@media (max-width:700px)': {
            gridTemplateColumns: '1fr',
        },
    },
    orderButtonContainer: {
        padding: '5px 0',
        '@media (max-width: 1040px)': {
            padding: '5px',
        },
    },
    orderButton: {
        width: '100%',
    },
    card: {
        boxShadow: '0 0 1px 1px rgba(0, 0, 0, 0.1)',
        paddingBottom: '10px',
        display: 'grid',
        gridGap: '10px',
        gridTemplateRows: '1fr auto 40px',
    },
    img: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
    labelsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    label: {
        padding: '5px',
    },
    labelPrice: {
        padding: '5px',
        color: theme.palette.grey.A200,
        fontWeight: 500,
        whiteSpace: 'nowrap',
    },
    moreButtonContainer: {
        padding: '5px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    containerTable: {
        width: '100%',
        padding: '8px',
    },
    filterHeader: {
        marginLeft: '1px',
        marginBottom: '10px',
        marginRight: 0,
    },
    noComplexAssays: {
        textAlign: 'center',
        color: theme.palette.primary.dark,
    },
}));

function ComprehensiveResearchCards({isComponentInShop}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { complexResearch, takeId, complexId } = useSelector(state => state.assaysCatalog);
    const { amountOfItems, selectedComplex } = useSelector(state => state.orderAssays);
    const [isUserTypeInSearch, setIsUserTypeInSearch] = useState(false);
    const {
        filterValue,
        filteredState,
        handleChangeFilterValue,
    } = useFilter(complexResearch, filterComplex)

    function filterComplex(item, value) {
        if ((item.name && item.name.toLowerCase().includes(value?.trim()?.toLowerCase())) || (item.testNames && item?.testNames?.includes(value?.trim()))) {
            return true;
        } else return item.filling && !!item.filling.some(element => (element.test && element.test.toLowerCase().includes(value?.trim().toLowerCase())));
    }

    const handleSearchType = (event) => {
        handleChangeFilterValue(event);
        if (!isUserTypeInSearch) setIsUserTypeInSearch(true)
        if (selectedComplex) dispatch(setSelectedComplex(null))
    };

    const handleTransitionToOrderManager = (event) => {
        event.stopPropagation();
        event.preventDefault();
        isComponentInShop ? history.push('/shop-order-manager') : history.push('/order-manager');
    };

    useEffect(() => {
        if (selectedComplex && selectedComplex.assayName !== filterValue && !isUserTypeInSearch) {
            handleChangeFilterValue({target: {value: selectedComplex.assayName}});
        }

        return () => {
            if (selectedComplex) dispatch(setSelectedComplex(null));
        }
    }, [dispatch, filterValue, handleChangeFilterValue, isUserTypeInSearch, selectedComplex]);

    useEffect(() => {
        if (takeId && !complexResearch && complexId) dispatch(getCatalog());
    }, [complexResearch, takeId, complexId])

    return (
        <div className={classes.root}>
            <div className={classes.containerTable}>
                {filteredState && complexResearch && <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.filterHeader}
                    value={filterValue}
                    onChange={(event) => handleSearchType(event)}
                />}
            </div>
            <Scroll>
                <div className={classes.rootWrapper}>
                    {complexResearch && filteredState
                        ? complexResearch.length === 0
                            ? <><div/><div className={classes.noComplexAssays}>Вибачте, комплексних досліджень немає.</div></>
                            : filteredState.length > 0 && typeof filteredState === "object"
                                ? filteredState.filter(item => !item.notSale).map((item) => (<MediaCard item={item} key={item.id} isComponentInShop={isComponentInShop}/>))
                                : <><div/><div className={classes.noComplexAssays}>Вибачте, комплексних досліджень не знайдено.</div></>
                        : <><div/><Loading/></>
                    }
                </div>
            </Scroll>
            <div className={classes.orderButtonContainer}>
                <Button
                    className={classes.orderButton}
                    variant="outlined"
                    color="primary"
                    disabled={amountOfItems < 1}
                    onClick={handleTransitionToOrderManager}>
                    Перейти до замовлення
                </Button>
            </div>
        </div>
    );
}

export default ComprehensiveResearchCards;
