import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {CircularProgress} from '@material-ui/core';
import LoadingMedis from "../../assets/loading.svg"

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      },
    image: {
        width: '40px',
        height: '40px',
        margin: '0 auto',
    },
});

const Loading = () => {
    const classes = useStyles();
    const isMedis = process.env.REACT_APP_CURRENT_CLINIC === "MEDIS"

    return (
    <div className={classes.root}>
        {isMedis
            ? <img src={LoadingMedis} alt="Test tube." className={classes.image}/>
            : <CircularProgress/>}
    </div>
)};

export default Loading;