import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {LinearProgress} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {unsetLoading} from "@cabinet-packages/redux/actions/actionsCreators";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'rgba(0,0,0,0.1)',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    linearProgress: {
        width: '100%'
    },
}));

const LoadingIndicatorDialog = () => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const {loading, loadingText} = useSelector(state => state.indicators);

    const onClose = (e) => {
        e.persist();

        dispatch(unsetLoading());
    }
    return (loading && <div className={cl.root} onClick={onClose}>
        {loadingText && <p>{loadingText}</p>}
        <LinearProgress className={cl.linearProgress} color="primary"/>
    </div>);
};

export default LoadingIndicatorDialog;
