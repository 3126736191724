import {ReactComponent as TestTube} from '../images/test-tube.svg';

const getAssayIcons = [
        TestTube,
        TestTube,
        TestTube,
        TestTube,
        TestTube,
        TestTube,
        TestTube,
        TestTube,
        TestTube,
        TestTube,
        TestTube,
        TestTube,
        TestTube,
        TestTube,
        TestTube,
        TestTube,
        TestTube,
        TestTube,
        TestTube,
        TestTube,
];

export default getAssayIcons;
