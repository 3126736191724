import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import {makeStyles, Tooltip} from "@material-ui/core";
import React, {useState} from "react";
import {CustomDialog, TextAlertDialog} from "../Dialogs";
import {addAssayToOrderList, clearOrderedAssays, removeAssayFromOrderList} from "@cabinet-packages/redux";
import {useDispatch, useSelector} from "react-redux";
import {setGoToCollectionPoints} from "@cabinet-packages/redux/actions/actionsCreators";
import Button from "@material-ui/core/Button";
import {AddImage, RemoveImage, StartImage} from "@cabinet-packages/components";

const useStyles = makeStyles(theme => ({
    basketButtonImg: {
        height: '30px',
        paddingLeft: '4px',
    },
    basketButton: {
        background: '#2cb8ad94',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '&:hover p': {
            color: 'black'
        }
    },
    basketButtonText: {
        color: 'white',
    },
    medisIconButton: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

const AddRemoveIconButtons = ({item, isIncludedInOrderingList, actionInOrderList, handleChecked, assayInfo, isInBasket, ceil}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {themeMaterialUI} = useSelector(state => state.theme);
    const {takeId, catalog, complexResearch, actions}  = useSelector(state => state.assaysCatalog);
    const fullCatalog = catalog && complexResearch && actions && [...catalog, ...complexResearch, ...actions];
    const { orderingList, goToCollectionPoints } = useSelector(state => state.orderAssays);
    const isDoctor = localStorage.getItem('isDoctor') || process.env.REACT_APP_PROJECT_NAME === 'CALCULATOR';
    const [showStartImg, setShowStartImg] = useState(true);
    const handleAdd = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (takeId === 14) {
            window?.dataLayer?.push({
                event: 'add_to_cart',
                eventProps: {
                    items: [{
                        id: item.id,
                        name: item.name,
                        variant: item.type,
                        price: item.discount
                            ? ceil ? Math.ceil(item.price - item.price * item.discount / 100) : item.price - item.price * item.discount / 100
                            : item.price,
                        quantity: 1,
                    }],
                },
                coupon: '',
            });
        }

        if (goToCollectionPoints) {
            const prevOrderingList = orderingList;

            dispatch(clearOrderedAssays());
            prevOrderingList.forEach(assay => {
                const findAssay = fullCatalog.find(item => item.type === assay.type && +item.id === +assay.id && !item.superGroupName);

                dispatch(addAssayToOrderList(findAssay.id,
                    findAssay.discount && !isDoctor
                        ? ceil ? Math.ceil(findAssay.price - findAssay.price * findAssay.discount / 100) : findAssay.price - findAssay.price * findAssay.discount / 100
                        : findAssay.price,
                    ()=>{},
                    findAssay.type,
                ));
            });

            dispatch(setGoToCollectionPoints(false));
        }

        if (actionInOrderList && actionInOrderList.length > 0) {
            CustomDialog(TextAlertDialog,{
                text: 'Акційні пропозиції можна замовити тільки в певних пунктах. ' +
                    'Замовте вибрану акцію, та повторіть процедуру для наступної.'},false, themeMaterialUI);
            return;
        }

        dispatch(addAssayToOrderList(
            item.id,
            item.discount && !isDoctor
                ? ceil ? Math.ceil(item.price - item.price * item.discount / 100) : item.price - item.price * item.discount / 100
                : item.price,
            () => {},
            item.type,
        ));
    };

    const handleRemove = (event) => {

        event.stopPropagation();
        event.preventDefault();

        if (handleChecked) handleChecked({target: {checked: false, name: 'checkedA'}});

        if (goToCollectionPoints) {
            const prevOrderingList = orderingList;

            dispatch(clearOrderedAssays());
            prevOrderingList.forEach(assay => {
                const findAssay = fullCatalog.find(item => item.type === assay.type && +item.id === +assay.id && !item.superGroupName);

                dispatch(addAssayToOrderList(findAssay.id,
                    findAssay.discount && !isDoctor
                        ? ceil ? Math.ceil(findAssay.price - findAssay.price * findAssay.discount / 100) : findAssay.price - findAssay.price * findAssay.discount / 100
                        : findAssay.price,
                    ()=>{},
                    findAssay.type,
                ));
            })
            dispatch(setGoToCollectionPoints(false));
        }

        dispatch(removeAssayFromOrderList(
            item.id,
            item.discount && !isDoctor
                ? ceil ? Math.ceil(item.price - item.price * item.discount / 100) : item.price - item.price * item.discount / 100
                : item.price,
            () => {},
            item.type,
        ));
        if (handleChecked) handleChecked({target: {checked: true, name: 'checkedA'}});

        if (isInBasket) {
            window?.dataLayer?.push({
                event: 'remove_from_cart',
                eventProps: {
                    items: [{
                        id: item.id,
                        name: item.name,
                        variant: item.type,
                        price: item.discount
                            ? ceil ? Math.ceil(item.price - item.price * item.discount / 100) : item.price - item.price * item.discount / 100
                            : item.price,
                        quantity: 1,
                    }],
                },
                coupon: '',
            });
        }
    };

    return (item && item.price > 0 && <>{assayInfo && takeId === 14
        ? <Tooltip
            title={isIncludedInOrderingList ? "Натисніть щоб прибрати iз замовлення." : "Натисніть щоб добавити до замовлення."}
            arrow>
            {isIncludedInOrderingList
                ? (
                    <Button
                        variant="contained"
                        className={classes.basketButton}
                        color="inherit"
                        onClick={(event) => handleRemove(event)}
                    >
                        <p className={classes.basketButtonText}>
                            прибрати з кошика
                        </p>
                        <AddImage/>
                    </Button>
                )
                : (
                    <Button
                        variant="contained"
                        className={classes.basketButton}
                        color="inherit"
                        onClick={(event) => {
                            handleAdd(event);
                            if (showStartImg) setShowStartImg(false);
                        }}
                    >
                        <p className={classes.basketButtonText}>
                            покласти у кошик
                        </p>
                        {showStartImg
                            ? <StartImage/>
                            : <RemoveImage/>}
                    </Button>
                )}
        </Tooltip>
        : <Tooltip
            title={isIncludedInOrderingList ? "Натисніть щоб прибрати iз замовлення." : "Натисніть щоб добавити до замовлення."}
            arrow>
            {takeId === 14
                ? isIncludedInOrderingList ? (
                    <IconButton
                        onClick={(event) => handleRemove(event)}
                        classes={{
                            root: classes.medisIconButton
                        }}
                    >
                        <AddImage/>
                    </IconButton>
                ) : (
                    <IconButton
                        classes={{
                            root: classes.medisIconButton
                        }}
                        onClick={(event) => {
                            handleAdd(event);
                            if (showStartImg) setShowStartImg(false);
                        }}
                    >
                        {showStartImg
                            ? <StartImage/>
                            : <RemoveImage/>}
                    </IconButton>
                )
                : isIncludedInOrderingList ? (
                    <IconButton
                        disabled={+item.idParent === +takeId}
                        onClick={(event) => handleRemove(event)}
                    >
                        <RemoveIcon/>
                    </IconButton>
                ) : (
                    <IconButton
                        onClick={(event) => handleAdd(event)}
                    >
                        <AddIcon/>
                    </IconButton>
                )
            }
        </Tooltip>
}</>)
}

export default AddRemoveIconButtons;
