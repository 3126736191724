import React, {useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, withStyles,} from '@material-ui/core';
import {Close} from "@material-ui/icons";
import useStyles from "./styles";
import {PhoneEnterPageMedis} from "@cabinet-packages/pages";
import {useDispatch, useSelector} from "react-redux";
import {handleAuthorization} from "@cabinet-packages/helpers/getSetAccessKey";
import {setClient, setCreatePatient} from "@cabinet-packages/redux";
import {SwipeStyledButton} from "../../index";
import OrderAndPatients from "./components/OrderAndPatients";
import {createOrderVisit} from "@cabinet-packages/api";
import moment from "moment";
import {useHistory} from "react-router";

const StyledDialog = withStyles(theme => ({
    paper: {
        borderRadius: '10px',
        width: '700px',

        '@media (max-width: 1180px)': {
            marginLeft: 0,
            marginRight: 0
        },
    },
}))(Dialog);

const StyledDialogTitle = withStyles(theme => ({
    root: {
        background: theme.palette.primary.dark,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
        padding: 0,
        position: "relative",
    },
}))(DialogTitle);

const StyledDialogContent = withStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',

        '@media (max-width: 1180px)': {
            padding: 0
        },
    },
}))(DialogContent);

const AuthDialog = ({ handleClose}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const {activePatient} = useSelector(state => state.patients);
    const { selectedPunkt, time, timeLine } = useSelector(state => state.orderAssays);
    const [note, setNote] = useState('');
    const [orderNumber, setOrderNumber] = useState(null);
    const history = useHistory();

    const handleChangeNote = (e) => {
        e.persist();

        setNote(e.target.value);
    }

    const handleCloseWithAuth = () => {
        setOrderNumber('')
        handleClose();
        handleAuthorization(true);
        dispatch(setCreatePatient());
        dispatch(setClient(null));
    }

    const handleConfirm = async (e) => {
        e.persist();
        const momentFormatTime = moment(`${time.selectedDay.dayNum} ${time.selectedMonth} ${time.selectedYear} ${time.selectedTime.time}`, 'DD MMMM YYYY hh:mm')
            .format('YYYY-MM-DD HH:mm');

        try {
            const orderId = await createOrderVisit(
                selectedPunkt.idPunkt,
                momentFormatTime + ' kids ' + note,
                `${activePatient.lastname} ${activePatient.firstname} ${activePatient.fathername}`,
                activePatient.id,
                orderNumber,
                momentFormatTime+':00.0000',
                0,
                0,
                'cash',
                'none',
                [],
            );

            setOrderNumber(orderId);
            handleAuthorization(true);
            dispatch(setCreatePatient());
            dispatch(setClient(null));
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        if (orderNumber) {
            setTimeout(() => {
                handleCloseWithAuth();
                window.location = '/kids';
            }, 5000);
        }
    },[orderNumber])

    return (<StyledDialog open={true}>
        <StyledDialogTitle disableTypography>
            {activePatient && <span className={cl.headingText}>Підтвердження запису</span>}
            {orderNumber && <span className={cl.headingText}>Запис успішний</span>}
            <IconButton onClick={handleCloseWithAuth} className={cl.closeButton}>
                <Close htmlColor="#FFFFFF80"/>
            </IconButton>
        </StyledDialogTitle>
        <StyledDialogContent>
            {orderNumber
                ? <h3 className={cl.confirmOrderHeading}>Ваше замовлення №<b>{orderNumber}</b> успішно створене.</h3>
                : <>{activePatient
                    ? <OrderAndPatients handleChangeNote={handleChangeNote} note={note}/>
                    : <PhoneEnterPageMedis/>}</>
            }
        </StyledDialogContent>
        <DialogActions className={cl.dialogAction}>
            {activePatient && !orderNumber
                ? <>
                    <SwipeStyledButton
                        variant="outlined"
                        animationStart="right"
                        animationMaterialColor="#ff0000"
                        buttonMaterialColor="#0E4E4E"
                        onClick={handleCloseWithAuth}
                        width="100px"
                    >
                        Скасувати
                    </SwipeStyledButton>
                    <SwipeStyledButton
                        animationStart="left"
                        animationMaterialColor="#00B3A1"
                        buttonMaterialColor="#FFC101"
                        onClick={handleConfirm}
                    >
                        Підтвердити запис
                    </SwipeStyledButton>
                </>
                :<div/>
            }
        </DialogActions>
    </StyledDialog>);
};

export default AuthDialog;
