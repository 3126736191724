import React, {useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Tooltip,
    useMediaQuery,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import cn from 'classnames';
import {MZgroup} from "../index";

const useStyles = makeStyles((theme) => ({
  menuItemDesktop: {
    background: 'inherit',
  },
  menuItemDesktopFull: {
    width: '100%',
  },
  menuButtonContainer: {
    textAlign: 'left',
  },
  menuItemDesktopIcon: {
    fontSize: '24px',
    padding: '0',
    cursor: 'pointer',
    minWidth: '0',
  },
  divider: {
    margin: '5px 0 10px 0',
  },
  menuItemDesktopText: {
    paddingLeft: '15px',
    margin: '0',
  },
  mobileContainer: {
    width: '100%',
    maxWidth: '100%',
  },
  scrollWrapper: {
    position: 'absolute',
    maxHeight: '100%',
    width: '100%',
  },
  scrollContainer: {
    position: 'relative',
    maxHeight: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  MainNavigationMenuListContainer: {
    background: theme.palette.header.main,
    padding: '8px 0',
    height: '100%',

    '@media (max-width: 899px)': {
      display: 'grid',
      padding: '5px',
      gridGap: '15px',
      gridTemplateColumns: 'minMax(150px, 1fr) minMax(150px, 1fr) minMax(150px, 1fr)',
      gridTemplateRows: 'repeat(3, minMax(150px, auto))',
    },

    '@media (max-width: 600px)': {
      gridGap: '10px',
      gridTemplateColumns: 'minMax(150px, 1fr) minMax(150px, 1fr)',
      gridTemplateRows: 'repeat(4, minMax(150px, auto))',
    },
  },
  MainNavigationMenuItemContainer: {
    cursor: 'pointer',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gridGap: '5px',

    '@media (max-width: 899px)': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
  },
  MainNavigationMenuItemIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    color: theme.palette.primary.main,

    '@media (max-width: 899px)': {
      fontSize: '68px',
    },
  },
  MainNavigationMenuItemLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',

    '@media (max-width: 899px)': {
      justifyContent: 'center',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 600,
      color: '#666666',
    },
  },
}));

export default function MainNavigation({routes}) {
  const [isFullNavigation, setIsFullNavigation] = useState(true);
  const isDesktopVersion = useMediaQuery('(min-width: 900px)');
  const classes = useStyles();
  const navigationRoutes = routes && routes.filter(route => route.showInNavigation);

  if (isDesktopVersion) {
    return (
      <div className={classes.MainNavigationMenuListContainer}>
        <List component="nav" dense disablePadding>
          <ListItem>
            <Tooltip
                arrow
                title={isFullNavigation ? "Натисніть, щоб приховати опис." : "Натисніть, щоб розкрити опис меню."}
                placement="right"
            >
            <ListItemIcon
              className={classes.menuItemDesktopIcon}
              onClick={() => setIsFullNavigation(prev => !prev)}
            >
              <MenuIcon fontSize="inherit"/>
            </ListItemIcon>
            </Tooltip>
          </ListItem>
          <Divider className={classes.divider}/>
          {navigationRoutes && navigationRoutes.map((route, index) => (
            <MenuItemDesktop
              route={route}
              classes={classes}
              isFullNavigation={isFullNavigation}
              key={index}/>
          ))}
        </List>
        <MZgroup left={true}/>
      </div>
    );
  }

  return (
    <div className={classes.mobileContainer}>
      <div className={classes.scrollWrapper}>
        <div className={classes.scrollContainer}>
          <div className={classes.MainNavigationMenuListContainer}>
            {navigationRoutes && navigationRoutes.filter(item => !item.notShowInMobile).map((route, index) => (
              <MenuItemMobile route={route} key={index}/>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function MenuItemDesktop({ route, isFullNavigation, classes }) {
  const location = useLocation();
  return (
    <NavLink
      to={route.path}
      onClick={(event) => {if (location.pathname.endsWith(route.path)) event.preventDefault()}}
      style={{
        color: 'black',
        textDecoration: 'none',
      }}
      activeStyle={{
        background: 'rgba(0, 0, 0, 0.1)',
      }}
    >
      <Tooltip
          arrow
          title={route.label}
          placement="right"
          disableFocusListener={isFullNavigation}
          disableHoverListener={isFullNavigation}
          disableTouchListener={isFullNavigation}
      >
        <ListItem button className={cn({
          [classes.menuItemDesktop]: true,
          [classes.menuItemDesktopFull]: isFullNavigation,
        })}>
          <ListItemIcon className={classes.menuItemDesktopIcon}>{route.icon}</ListItemIcon>
          {isFullNavigation && <ListItemText className={classes.menuItemDesktopText} primary={route.label}/>}
        </ListItem>
      </Tooltip>
    </NavLink>
  );
}

function MenuItemMobile({ route }) {
  const classes = useStyles();
  return (
    <NavLink
      to={route.path}
      style={{
        color: 'black',
        textDecoration: 'none',
      }}
    >
      <div className={classes.MainNavigationMenuItemContainer}>
        <div className={classes.MainNavigationMenuItemIcon}>
          {route.icon}
        </div>
        <div className={classes.MainNavigationMenuItemLabel}>
          {route.label}
        </div>
      </div>
    </NavLink>
  );
}
