import React, {useContext, useEffect, useState} from "react";
import {makeStyles, NoSsr, TextField} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {UserContext} from "../index";


const useStyles = makeStyles((theme) => ({
    textField: {
        width: '100%',
        marginBottom: 20,
        '& .MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'double',
        },
        '& input': {
            paddingTop: 10.5,
            paddingBottom: 10.5,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#C9E9FF',
        },
        '@media (min-width: 900px)': {
            maxWidth: '230px',
        },
        '@media (max-width: 900px)': {
            marginBottom: 20,
        },
        '@media (min-width: 900px) and (max-width: 1100px)': {
            maxWidth: '180px',
        },
        // '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        //     borderColor: '#C9E9FF',
        // },
    },
    star: {
        color: 'red'
    },
    label: {
        marginBottom: 8,
        marginTop: 0,
        fontSize: '1.125rem',
        color: '#0077C8',
        '& span': {
            fontFamily: "'Museo Sans Cyrl 500' !important",
        },
        '@media (max-width: 900px)': {
            fontSize: '0.938rem',
            marginBottom: 10,
        }
    }
}))

const InputAuthorization = ({label, type, value, required}) => {
    const cl = useStyles()
    const context = useContext(UserContext);
    const [error, setError] = useState(false)
    const handleValue = (val, error) => {
        if (error) {
            return context.values.setValues(
                prev => ({
                    ...prev,
                    ['error']: val,
                })
            )
        }
        context.values.setValues(
            prev => ({
                ...prev,
                [type]: val,
            })
        )
    }


    const handleChangePhone = (event) => {
        const onlyNums = event.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            handleValue(onlyNums)
          //  setValue(onlyNums);
        } else if (onlyNums.length === 10) {
            const number = onlyNums.replace(
                /(\d{3})(\d{3})(\d{4})/,
                '($1) $2-$3'
            );
            handleValue(onlyNums)
            //setValue(number);
        }
    };


    const handleChangeName = (e) => {
        const value = e.target.value;
        if (type) {
            if (type === 'phone') {
                return handleChangePhone(value)
            }
            handleValue(value)
        }
      //  setValue(e.target.value)
    }


    const findError = (email) => {
        if (email) {
            if (value.length > 0) {
                if ((!value.includes('@') || !value.includes('.'))&& context.values.values.editPatient) {
                    handleValue(true, true)
                    return setError(true)
                }
            }
        } else {
            if (value.length < 10 && value.length > 1) {
                handleValue(true, true)
                return setError(true)
            }
        }
        handleValue(false, true)
        return setError(false)
    }

    useEffect(() => {
        if (type && type === 'phone') {
            findError()
        }
        if (type && type === 'email') {
            findError('true')
        }
    }, [type, value])






    return (
        <NoSsr>
            <div>
                {type && error && type === 'email' ? <>
                    <p className={cl.label} style={{color: '#FF0000'}}><FormattedMessage
                        id="shopping_cart_bad_email.link"
                        defaultMessage={"Введено не коректний E-mail"}/></p>
                </> : <>

                    {(type === 'firstNameEng' || type === 'lastNameEng') ?
                        <p className={cl.label} style={{color: '#FF0000'}}
                        >{label}</p>
                        : <p className={cl.label}
                        >{label}{!required && <span
                            className={cl.star}> *</span>}</p>
                    }
                </>}
                <TextField
                    InputLabelProps={{
                        shrink: false,
                    }}
                    variant="outlined"
                    className={cl.textField}
                    value={value}
                    onChange={handleChangeName}
                    error={error}
                    disabled={context.values.values.handlePatient && !context.values.values.editPatient }
                />
            </div>
        </NoSsr>
    )
}

export default InputAuthorization