import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 auto',
        maxWidth: 1180,
        padding: 8,
    },
    rootTitle: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: '2rem',
    },
    rootTitleAgent: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: '1.25rem',
        minHeight: '38px',
    },
    wrapperAgentText: {
        height: 43,
        display: 'flex',
        alignItems: 'center',
    },
    rootIconWrapper: {
        cursor: 'pointer',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 8,
        marginTop: 2,
    },
    wrapperSum: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapperSumText: {
        marginRight: 16,
    },
    actionsNumberWrapper: {
        fontSize: '30px',
        '@media (max-width: 1180px)': {
            fontSize: '25px',
        }
    },
    wrapperPayTitle: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 2fr',
        gridColumnGap: 18,
    },
    changeTitle: {
        marginTop: 2,
    },

    rootFullOrderView: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'max-content',
        maxWidth: "600px",
        textAlign: 'left',
        marginTop: '8px',
        border: `1px solid #31ff39`,
        borderRadius: '8px',
        padding: '8px',
        backgroundColor: 'rgba(49,255,57,0.1)',
        marginBottom: 15,
    },
    orderDataText: {
        textAlign: 'left',
        width: '70%'
    },
    rootCheckboxWrapper: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    checkbox: {
        '& span': {
            color: theme.palette.primary.main,
            fontSize: 14,
            paddingTop: 0,
            fontFamily: "'Roboto'",
        },
    },
    checkboxLabelWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            marginLeft: 5,
        },
        // '& svg': {
        //     color: theme.palette.primary.main,
        // },
    },
    customerImg: {
        transform: 'rotate(180deg)',
    },

    wrapperFormControl: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 2fr',
        gridColumnGap: 20,
        margin: '4px',
        height: '56px'
    },
    wrapperFormControlBank: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        gridColumnGap: 20,
        margin: '4px',
        height: '56px'
    },
    wrapperFormControlPrive: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-evenly',
    },
    textField: {

    },
}));

export default useStyles;
