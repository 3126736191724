import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    rootInsertResult: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    appBar: {
        height: theme.spacing(6),
        backgroundColor: theme.palette.primary[50],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        alignItems: 'center',
    },
    headingText: {
        fontSize: '18px',
        color: theme.palette.getContrastText(theme.palette.primary[50]),
    },
    orderDataText: {
        textAlign: 'left',
    },
    assaySelectorWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    searchWrapper: {
        width: '100%',
        maxWidth: '280px',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(1),
    },
    searchOrderButton: {
        marginTop: theme.spacing(1),
        height: '40px',
        width: '200px'
    },
    formControl: {
        width: '100%',
        maxWidth: '210px',
    },
    error: {
        color: "red",
        padding: theme.spacing(2),
    },
    actionButton: {
        width: '100%',
        maxWidth: '210px',
        margin: '16px auto',
    },
    dataWrapper: {
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    insertResultSuccess: {
        color: theme.palette.primary.main,
        padding: theme.spacing(3)
    },
}));

export default useStyles;
