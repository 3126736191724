import React, {useCallback, useEffect, useState} from "react";
import useStyles from "../styles";
import {
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Radio,
    RadioGroup,
    Slide,
    Snackbar,
    Switch,
    TextField,
    withStyles
} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MuiPhoneNumber from "material-ui-phone-number";
import Pdf from "medis/src/assets/pdf/Договір публічної оферти_МеДіС.pdf";
import {addPatient} from "@cabinet-packages/redux";
import uaLocale from 'date-fns/locale/uk';
import DateFnsUtils from "@date-io/date-fns";
import {useDispatch, useSelector} from "react-redux";
import {setRegisterChild} from "@cabinet-packages/api";
import moment from 'moment';
import 'moment/locale/uk';
import {addTempPatient, verifyConfirm, verifySendCode} from "@cabinet-packages/api/api";
import {SwipeStyledButton} from "../../../index";
import {amber, teal} from "@material-ui/core/colors";
import CheckboxLabel from "@cabinet-packages/pages/PatientTabsMedis/components/CheckboxLabel";
import uuidV4 from 'uuid/v4';

moment.locale('uk');

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,

            '& + $track': {
                backgroundColor: theme.palette.grey[50],
            },
        },
    },
    thumb: {
        margin: '1px',
        width: 22,
        height: 22,
        color: theme.palette.primary.main,
    },
    track: {
        borderRadius: 26 / 2,
        border: `2px solid ${theme.palette.green.dark}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 0.5,
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const StyledFormControlLabel = withStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(1),
        alignItems: 'flex-start'
    },
    label: {
        marginTop: theme.spacing(1.5)
    },
}))(FormControlLabel);

const DialogForm = ({patientDataState,handleClose}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const [uuid, setUuid] = useState(uuidV4());
    const [patientData, setPatientData] = patientDataState;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [phone, setPhone] = useState('');
    const [code, setCode] = useState('');
    const {listOfPatients} = useSelector(state => state.patients);
    const [open, setOpen] = useState(false);
    const [snackBarText, setSnackbarText] = useState('');
    const [checked, setChecked] = useState(false);
    const [state, setState] = useState({checkedA: false});
    const [openCode, setOpenCode] = useState(false);

    const handleChange = (event) => {
        setState(prev => ({ ...prev, [event.target.name]: event.target.checked }));
    };

    const toggleChecked = () => {
        setChecked((prev) => !prev);
    };

    const updateState = (field, value) => {
        setPatientData(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setSnackbarText('');
    };

    const registerNewPatient = async () => {
        if (!loading && patientData.lastname && patientData.firstname && patientData.fathername && patientData.birthday
            && patientData.sex && patientData.email && patientData.email.includes('@')
            && patientData.email.includes('.')) {
            setLoading(true);
            setError(false);

            try {
                const mainPatientId = listOfPatients.find(patient => patient.main).id;
                const birthdayDate = moment(patientData.birthday).format('YYYY-MM-DD')

                const patient = await setRegisterChild(
                    mainPatientId,
                    patientData.lastname,
                    patientData.firstname,
                    patientData.fathername,
                    birthdayDate,
                    patientData.sex,
                    patientData.email,
                ).catch((error) => {
                    setError(true);
                    console.error(error);
                })

                dispatch(addPatient(patient));
                setPatientData({sex: 'F', birthday: '2000-12-12'});
                handleClose();
            } catch (e) {
                console.error(e);
                setError('Вибачте, сталась помилка при створенні пацієнта.');
            }
            setLoading(false);
        } else {
            setOpen(true);
            setSnackbarText('Введіть коректні дані для реєстрації нового пацієнта.');
        }
    };

    const registerTemporaryPatient = async () => {
        try {
            const tempPatient = await addTempPatient(uuid, patientData.lastname, patientData.firstname,
                patientData.fathername, patientData.dt, patientData.sex, phone, patientData.email);

            if (tempPatient && tempPatient[0]) {
                dispatch(addPatient(tempPatient.map(patient => ({...patient, temp: true}))));

                setOpen(true);
                setSnackbarText('Тимчасового пацієнта успішно додано.');
                setPatientData({sex: 'F', birthday: '2000-12-12'});
                setPhone('');
                setCode('');
                setOpenCode(false);
                setTimeout(handleClose, 3000);
            }
        } catch (e) {
            setError('Вибачте, сталась помилка при створенні тимчасового пацієнта.');
            setOpen(true);
            setSnackbarText('Вибачте, сталась помилка при створенні тимчасового пацієнта.');
            console.error(e);
        }
    };

    const sendCode = async () => {
        if (!loading && patientData.lastname && patientData.firstname && patientData.fathername && patientData.birthday
            && patientData.sex && patientData.email && patientData.email.includes('@')
            && patientData.email.includes('.') && phone.length === 19 && state.checkedA) {
            setError(false);

            try {
                const sendPhone = await verifySendCode(phone, uuid);

                if (sendPhone) {
                    setUuid(sendPhone.token);
                    setOpenCode(true);
                    setCode('');
                }
            } catch (e) {
                setError('Вибачте, сталась помилка при надсиланні коду.');
                setOpen(true);
                setSnackbarText('Вибачте, сталась помилка при надсиланні коду.');
                console.error(e);
            }
        } else {
            setOpen(true);
            setSnackbarText('Введіть коректні дані для реєстрації нового пацієнта та підтвердьте згоду на ' +
                'використання персональних даних.');
        }
    };

    const handleClickOnAction = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (checked) {
            sendCode();
        } else {
            registerNewPatient();
        }
    };

    const handleConfirmCode = useCallback(async () => {
        if (code && code.length === 4) {
            setLoading(true);
            try {
                const codeConfirm = await verifyConfirm(code, uuid);

                if (codeConfirm) {
                    await registerTemporaryPatient();
                }
            } catch (e) {
                setError('Код введено неправильно.');
                setOpen(true);
                setSnackbarText('Код введено неправильно.');
                console.error(e);
            }
            setLoading(false);
        }
    }, [code]);

    useEffect(() => {
        handleConfirmCode();
    }, [handleConfirmCode]);

    return (<>
        <div className={cl.newPatientWrapper}>
            <div className={cl.catalogSwitchControl}>
                <label htmlFor="toggleChecked" className={cl.catalogSwitchControlLabel}
                       style={checked ? {transition: '.3s all'} : {color: '#00B3A1', transition: '.3s all'}}>
                    НОВИЙ ПАЦІЄНТ
                </label>
                <IOSSwitch id="toggleChecked" color="primary" checked={checked} onChange={toggleChecked} disabled={openCode}/>
                <label htmlFor="toggleChecked" className={cl.catalogSwitchControlLabel}
                       style={checked ? {color: '#00B3A1', transition: '.3s all'} : {transition: '.3s all'}}>
                    ТИМЧАСОВИЙ ПАЦІЄНТ
                </label>
            </div>
            <div className={cl.newPatientFieldsGroup}>
                <div>
                    <p className={cl.fieldLabel}>Ім'я</p>
                    <TextField
                        autoFocus={true}
                        variant="outlined"
                        InputProps={{
                            classes: {
                                root: cl.rootAutocomplete,
                                notchedOutline: cl.notchedOutline,
                            }}}
                        value={patientData?.firstname || ''}
                        onChange={(event) => updateState('firstname', event.target.value)}
                        placeholder="Введіть Ім'я"
                        disabled={openCode}
                    />
                </div>
                <div>
                    <p className={cl.fieldLabel}>Прізвище</p>
                    <TextField
                        variant="outlined"
                        InputProps={{
                            classes: {
                                root: cl.rootAutocomplete,
                                notchedOutline: cl.notchedOutline,
                            }}}
                        value={patientData?.lastname || ''}
                        onChange={(event) => updateState('lastname', event.target.value)}
                        placeholder="Введіть Прізвище"
                        disabled={openCode}
                    />
                </div>
            </div>
            <div className={cl.newPatientFieldsGroup}>
                <div>
                    <p className={cl.fieldLabel}>По-батькові</p>
                    <TextField
                        variant="outlined"
                        InputProps={{
                            classes: {
                                root: cl.rootAutocomplete,
                                notchedOutline: cl.notchedOutline,
                            }}}
                        value={patientData?.fathername || ''}
                        onChange={(event) => updateState('fathername', event.target.value)}
                        placeholder="Введіть По-батькові"
                        disabled={openCode}
                    />
                </div>
                <div className={cl.newPatientBirthdayGender}>
                    <div className={cl.newPatientBirthdayContainer}>
                        <p className={cl.fieldLabel}>Дата народження</p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={uaLocale}>
                            <KeyboardDatePicker
                                value={patientData?.birthday}
                                inputVariant="outlined"
                                InputProps={{
                                    classes: {
                                        root: cl.rootAutocompleteDate,
                                        notchedOutline: cl.notchedOutline,
                                    }}}
                                onChange={(date, value) => {
                                    updateState('birthday', value)
                                }}
                                inputValue={patientData?.birthday}
                                invalidDateMessage="Неправильний формат дати."
                                maxDateMessage="Дата народження не може бути в майбутньому."
                                format="yyyy-MM-dd"
                                disableFuture
                                // maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
                                cancelLabel="Назад"
                                okLabel="Ок"
                                openTo="year"
                                disabled={openCode}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <FormControl component="fieldset" className={cl.newPatientFormComtrol}>
                        <FormLabel component="legend" className={cl.fieldLabel}>Стать</FormLabel>
                        <RadioGroup
                            row
                            value={patientData?.sex}
                            onChange={(event) => updateState('sex', event.target.value)}
                            className={cl.genderGroup}
                            disabled={openCode}
                        >
                            <FormControlLabel
                                value="F"
                                control={<Radio color="primary" size="small"/>}
                                label="Ж"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="M"
                                control={<Radio color="primary" size="small"/>}
                                label="Ч"
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            <div className={cl.newPatientFieldsGroup}>
                <div>
                    <p className={cl.fieldLabel}>Електронна пошта</p>
                    <TextField
                        variant="outlined"
                        InputProps={{
                            classes: {
                                root: cl.rootAutocomplete,
                                notchedOutline: cl.notchedOutline,
                            }}}
                        value={patientData?.email || ''}
                        onChange={(event) => updateState('email', event.target.value)}
                        placeholder="Введіть електронну пошту"
                        type="email"
                        disabled={openCode}
                    />
                </div>
                {checked && <div>
                    <p className={cl.fieldLabel}>Контактний телефон</p>
                    <MuiPhoneNumber
                        className={cl.muiPhoneNumber}
                        defaultCountry='ua'
                        countryCodeEditable={false}
                        regions='europe'
                        value={phone}
                        onChange={(value) => setPhone(value)}
                        variant="outlined"
                        disabled={openCode}
                    />
                </div>}
            </div>
            {checked && <div className={cl.temporaryPatientInfo}>
                <div className={cl.orderTermsSmallBox}>
                    <FormGroup>
                        <StyledFormControlLabel control={<Checkbox checked={state.checkedA} onChange={handleChange}
                                                                   name="checkedA" color="primary" disabled={openCode}/>}
                                                label={<CheckboxLabel text={'Я погоджуюсь на використання'}
                                                                      redText={'персональних даних'}
                                                                      link={Pdf}/>}
                        />
                    </FormGroup>
                </div>
                {openCode
                    ? <div>
                        <p className={cl.fieldLabel}>Код</p>
                        <TextField
                            className={cl.muiPhoneNumber}
                            placeholder="Введіть код"
                            variant="outlined"
                            error={error === 'Код введено неправильно.'}
                            disabled={error === 'Код введено неправильно.' || loading}
                            autoFocus={true}
                            value={code}
                            inputProps={{
                                maxLength: 4,
                            }}
                            onChange={(event) => setCode(event.target.value.replace(/[^0-9]/g, ''))}
                        />
                    </div>
                    : <p className={cl.phoneDescriptionText}>
                        Верифікаційний код буде надісланий Вам у вигляді SMS повідомлення або у Viber
                    </p>}
            </div>}
            <div className={cl.newPatientActions}>
                <SwipeStyledButton
                    animationStart={'left'}
                    animationMaterialColor={teal[500]}
                    buttonMaterialColor={amber[500]}
                    variant="contained"
                    onClick={handleClickOnAction}
                    disabled={loading}
                >
                    {checked
                        ? openCode ? 'Повторний код' : 'Зареєструвати'
                        : 'Добавити пацієнта'}
                </SwipeStyledButton>
                {loading && <CircularProgress size={24} className={cl.buttonProgress}/>}
            </div>
            {error && <p className={cl.error}>{error}</p>}
        </div>
        <Snackbar
            ContentProps={{
                classes: {
                    root: cl.snackbar,
                    message: cl.snackbar
                }
            }}
            TransitionComponent={TransitionUp}
            open={open}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            message={<div className={cl.snackbarContentWrapper}>
                <div className={cl.snackbarContentHeader}>
                    Увага!
                </div>
                <div className={cl.snackbarContentText}>
                    {snackBarText && snackBarText}
                </div>
            </div>}
        >
        </Snackbar>
    </>);
}

export default DialogForm;
