import React, {useCallback, useEffect, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {addPatient, setListOfPatients} from "@cabinet-packages/redux";
import DateFnsUtils from "@date-io/date-fns";
import uaLocale from 'date-fns/locale/uk';
import {useHistory} from "react-router";
import {getCheckForAuthorized, setRegisterChild} from "@cabinet-packages/api";
import moment from 'moment';
import 'moment/locale/uk';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        minHeight: 'max-content',
        padding: '0 8px 8px 8px',
    },
    bodyContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 100px)',

        '@media (max-width: 1040px)': {
            padding: '5px',
            width: '100%',
        },
    },
    birthdayContainer: {
        padding: '4px 0',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 1fr',
        gridGap: '5px',
    },
    formAnnotation: {
        fontSize: '11px',
        color: theme.palette.secondary.light,
    },
    actionButtonContainer: {
        padding: '5px 0',

        '@media (max-width: 1040px)': {
            padding: '5px',
        },
    },
    actionButton: {
        width: '100%',
    },
    addNewPatientMessage: {
        textAlign: 'center',
        fontSize: '18px',
        fontWeight: 600
    },
}))

moment.locale('uk');

const AddNewPatient = ({isInDialog}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {listOfPatients} = useSelector(state => state.patients);
    const todayDate = new Date();
    const initialPatient = {
        firstname: '',
        lastname: '',
        fathername: '',
        dt: todayDate,
        sex: 'F',
        phone: '',
        email: '',
        regDate: todayDate,
    };
    const [valueActivePatient, setValueActivePatient] = useState(initialPatient);
    const [isUserSendData, setIsUserSendData] = useState(false);
    const [error, setError] = useState(false);

    const handleClickOnAction = async (event) => {
        event.stopPropagation();
        event.preventDefault();

        const mainPatientId = listOfPatients.find(patient => patient.main).id;
        const birthdayDate = moment(valueActivePatient.dt).format('YYYY-MM-DD')

        setIsUserSendData(true);

        const patient = await setRegisterChild(
            mainPatientId,
            valueActivePatient.lastname,
            valueActivePatient.firstname,
            valueActivePatient.fathername,
            birthdayDate,
            valueActivePatient.sex,
            valueActivePatient.email
        ).catch(error => setError(true))
        await getCheckForAuthorized();

        dispatch(addPatient(patient));
    };

    const updateStateActivePatient = useCallback((field, value) => {
        setValueActivePatient(prev => ({
            ...prev,
            [field]: value,
        }));
    },[dispatch]);

    useEffect(() => {
        if (!listOfPatients) dispatch(setListOfPatients());
    }, [dispatch, listOfPatients]);

    return (<div className={classes.root}>
        {valueActivePatient &&
            <div className={classes.bodyContainer}>
                {isUserSendData
                    ? <>
                        {isInDialog
                            ? <p className={classes.addNewPatientMessage}>{error ? 'Вибачте, виникла помилка.' : 'Нового пацієнта додано.'}</p>
                            : <p className={classes.addNewPatientMessage}>Нового пацієнта додано, він зявиться у вашому
                            кабінеті.</p>}
                        <div className={classes.actionButtonContainer}>
                            {!isInDialog && <Button
                                className={classes.actionButton}
                                variant="outlined"
                                color="primary"
                                onClick={() => history.push('/account-settings')}
                            >
                                Перейти до списку пацієнтів
                            </Button>}
                        </div>
                    </>
                    : <>
                        <TextField
                            value={valueActivePatient.lastname}
                            onChange={(event) =>
                                updateStateActivePatient('lastname', event.target.value)}
                            label="Прізвище*"
                        />
                        <TextField
                            value={valueActivePatient.firstname}
                            onChange={(event) =>
                                updateStateActivePatient('firstname', event.target.value)}
                            label="Ім'я*"
                        />
                        <TextField
                            value={valueActivePatient.fathername}
                            onChange={(event) =>
                                updateStateActivePatient('fathername', event.target.value)}
                            label="По-батькові*"
                        />
                        <div className={classes.birthdayContainer}>
                            <p style={{color: 'rgba(0, 0, 0, 0.54)'}}>Дата народження</p>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={uaLocale}>
                                <KeyboardDatePicker
                                    value={valueActivePatient.dt}
                                    onChange={(value) => updateStateActivePatient('dt', value)}
                                    invalidDateMessage="Неправильний формат дати."
                                    maxDateMessage="Дата народження не може бути в майбутньому."
                                    format="yyyy-MM-dd"
                                    disableFuture
                                    cancelLabel="Назад"
                                    okLabel="Ок"
                                    openTo="year"
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Стать*</FormLabel>
                            <RadioGroup
                                aria-label="gender"
                                value={valueActivePatient.sex}
                                onChange={(event) => updateStateActivePatient('sex', event.target.value)}
                                className={classes.genderGroup}
                            >
                                <FormControlLabel
                                    value="F"
                                    control={<Radio color="primary"/>}
                                    label="Жіноча"
                                />
                                <FormControlLabel
                                    value="M"
                                    control={<Radio color="primary"/>}
                                    label="Чоловіча"
                                />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            value={valueActivePatient.email}
                            onChange={(event) => updateStateActivePatient('email', event.target.value)}
                            label="Електронна пошта*"
                            type="email"
                        />
                        <p className={classes.formAnnotation}>(*) Поля позначені зірочкою обовязкові для заповнення.</p>
                        <div className={classes.actionButtonContainer}>
                            <Button
                                className={classes.actionButton}
                                variant="outlined"
                                color="primary"
                                disabled={
                                    valueActivePatient.firstname.length === 0
                                    || valueActivePatient.lastname.length === 0
                                    || valueActivePatient.email.length === 0
                                    || !valueActivePatient.email.includes('@')
                                    || !valueActivePatient.email.includes('.')
                                    || valueActivePatient.fathername.length === 0
                                }
                                onClick={(event) => {
                                    handleClickOnAction(event);
                                }}
                            >
                                Добавити нового пацієнта
                            </Button>
                        </div>
                    </>
                }
            </div>
        }
    </div>);
};

export default AddNewPatient;
