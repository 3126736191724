import React from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  assayTableTitle: {
    marginBottom: "10px",
    fontWeight: "600",
    cursor: "pointer",
    transition: "color 0.5s",

    "&:hover": {
      transition: "color 0.5s",
      color: theme.palette.primary.main,
    },
  },
  assayTableTitleTime: {
    fontWeight: "500",
  },
  table: {
    wordBreak: "break-all",
    tableLayout: "fixed",
    width: "100%",
  },
  tableForInnerHTML: {
    maxWidth: "95vw",
  },
  tableCell: {
    width: "25%",
    border: "1px solid rgb(197, 197, 197)",
    padding: "0 2px",
    textAlign: "center",
    color: 'rgba(33, 69, 114, 0.87)',
  },
  tableCellHead: {
    width: "25%",
    border: "1px solid rgba(33, 69, 114, 0.87)",
    padding: "0 2px",
    textAlign: "center",
    color: 'rgba(33, 69, 114, 0.87)',
    fontWeight: 600,
  },
  columnLabel: {
    fontSize: "12px",
    color: 'rgba(33, 69, 114, 0.87)',
    fontWeight: 600,

    "@media(max-width: 900px)": {
      fontSize: "9px",
    },
  },
  researchDescription: {
    display: "flex",
    rowGap: "10px",
    padding: "10px 10px",
    backgroundColor: "#F8F9F9",
    margin: "10px 0px",
    width: "100%",
  },
  resultLabel: {
    fontSize: "14px",
    color: 'rgba(33, 69, 114, 0.87)',
    fontWeight: 600,
    background: theme.palette.primary.background
  },
}));

const ResultFlora = ({ assay }) => {
  const classes = useStyles();
  const mdUp = useMediaQuery('@media (maxWidth: 950px)');
  const parsedResult =
    typeof assay.result === "string" && assay.result.includes('[{"')
      ? JSON.parse(assay.result)
      : assay.result;
  const nazocytogramGroups = assay.test === "Назоцитограма"
      && (parsedResult && (new Set(parsedResult?.map(item => item.group_name)).size > 1))
  const groupNames = parsedResult && [...(new Set(parsedResult?.map(item => item.group_name)))]

  return (
    <>
      {(assay.resultlighting !== 0 && assay.norm !== "{NULL}") ||
        ((assay.resulttmlt.length > 0 || assay.result.length > 0) && (
          <div className={classes.assayTableTitle}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 10px",
                }}
              >
                <Typography className={classes.columnLabel}>
                  Назва дослідження
                </Typography>
                {+assay.ready === 0 ? <Typography variant="caption" color="error">
                  Результати ще не готові. Очікуйте сповіщення про готовність.
                </Typography>
                : <Typography className={classes.columnLabel}>
                  Результат готовий
                </Typography>}
              </div>
              <div className={classes.researchDescription}>
                <Typography
                  style={mdUp ? { flex: "3 1 0" } : { flex: "2 1 0" }}
                  className={classes.resultLabel}
                  color={"textPrimary"}
                >
                  {`${assay.packet.length > 0 ? assay.packet : assay.test}`}
                </Typography>
                <Typography
                  style={mdUp ? {} : { flex: "2 1 0", textAlign: "end" }}
                  className={classes.resultLabel}
                  color={"textPrimary"}
                  component={"div"}
                >
                  <em className={classes.assayTableTitleTime}>
                    {+assay.ready !== 0 && assay.utime.slice(0, 16).split("-").join(".")}
                  </em>
                </Typography>
              </div>
            </div>
          </div>
        ))}
      {assay.test !== "Назоцитограма" &&
        assay.result &&
        assay.result.includes('[{"') && (
          <Table className={classes.table} size="small" padding="none">
            {assay.resulttype === "flora" &&
              assay.test !== "Назоцитограма" &&
              !(
                assay.packet.includes("для чол") ||
                assay.test.includes("для чол")
              ) && (
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCellHead}>
                      <strong>Вид дослідження</strong>
                    </TableCell>
                    {parsedResult.find(
                      (item) => item.group_name === "URETRA"
                    ) && (
                      <TableCell className={classes.tableCellHead}>
                        <strong>URETRA</strong>
                      </TableCell>
                    )}
                    {parsedResult.find(
                      (item) => item.group_name === "VAGINA"
                    ) && (
                      <TableCell className={classes.tableCellHead}>
                        <strong>VAGINA</strong>
                      </TableCell>
                    )}
                    {parsedResult.find(
                      (item) => item.group_name === "CERVIX"
                    ) && (
                      <TableCell className={classes.tableCellHead}>
                        <strong>CERVIX</strong>
                      </TableCell>
                    )}
                    <TableCell className={classes.tableCellHead}>
                      <strong>Референтні значення</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}
            {assay.resulttype === "flora" &&
              assay.test !== "Назоцитограма" &&
              (assay.packet.includes("для чол") ||
                assay.test.includes("для чол")) && (
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCellHead}>
                      <strong>Вид дослідження</strong>
                    </TableCell>
                    <TableCell className={classes.tableCellHead}>
                      <strong>URETRA</strong>
                    </TableCell>
                    <TableCell className={classes.tableCellHead}>
                      <strong>Референтні значення</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}
            <TableBody>
              {assay.result &&
                JSON.parse(assay.result)
                  .reduce((acu, cur) => {
                    if (acu.find((item) => item.parametr === cur.parametr)) {
                      return [
                        ...acu.filter((item) => item.parametr !== cur.parametr),
                        {
                          ...acu.find((item) => item.parametr === cur.parametr),
                          [cur.group_name]: cur,
                        },
                      ];
                    } else {
                      return [
                        ...acu,
                        {
                          [cur.group_name]: cur,
                          parametr: cur.parametr,
                          id: cur.id,
                        },
                      ];
                    }
                  }, [])
                  .map((flora, idx) => {
                    return flora.null ? (
                      <TableRow
                        key={flora.id + idx}
                        style={
                          flora.state === "normal"
                            ? {}
                            : { backgroundColor: "#FFEAEA" }
                        }
                      >
                        <TableCell className={classes.tableCell}>
                          {flora.parametr}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {flora.null.has_manual_input &&
                          flora.null.manual_answer
                            ? flora.null.possible_result.length > 0
                              ? flora.null.possible_result.map((resultItem) =>
                                  resultItem.item_values
                                    .filter((val) => val.checked === true)
                                    .map((check, idx) => (
                                      <p key={check.variant + idx}>
                                        {idx === 0 && flora.null.manual_answer}
                                        {" " +
                                          resultItem.item_name +
                                          " " +
                                          check.variant}
                                      </p>
                                    ))
                                )
                              : flora.null.manual_answer
                            : flora.null.possible_result.length > 0
                            ? flora.null.possible_result.map((resultItem) =>
                                resultItem.item_values
                                  .filter((val) => val.checked)
                                  .map((check, idx) => (
                                    <p key={check.variant + idx}>
                                      {resultItem.item_name +
                                        " - " +
                                        check.variant}
                                      ;
                                    </p>
                                  ))
                              )
                            : ""}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow key={flora.id + idx}>
                        <TableCell className={classes.tableCell}>
                          {flora.parametr}
                        </TableCell>
                        {flora.URETRA && (
                          <TableCell className={classes.tableCell}>
                            {flora.URETRA &&
                            flora.URETRA.has_manual_input &&
                            flora.URETRA.manual_answer
                              ? flora.URETRA.possible_result.length > 0
                                ? flora.URETRA.possible_result[0].item_values
                                    .filter((val) => val.checked === true)
                                    .map((check, idx) => (
                                      <p key={check.variant + idx}>
                                        {idx === 0 &&
                                          flora.URETRA.manual_answer}
                                        {" " + check.variant}
                                      </p>
                                    ))
                                : flora.URETRA.manual_answer
                              : flora.URETRA.possible_result.length > 0
                              ? flora.URETRA.possible_result[0].item_values
                                  .filter((val) => val.checked)
                                  .map((check, idx) => (
                                    <p key={check.variant + idx}>
                                      {check.variant}
                                    </p>
                                  ))
                              : ""}
                          </TableCell>
                        )}
                        {flora.VAGINA && (
                          <TableCell className={classes.tableCell}>
                            {flora.VAGINA &&
                            flora.VAGINA.has_manual_input &&
                            flora.VAGINA.manual_answer
                              ? flora.VAGINA.possible_result.length > 0
                                ? flora.VAGINA.possible_result[0].item_values
                                    .filter((val) => val.checked === true)
                                    .map((check, idx) => (
                                      <p key={check.variant + idx}>
                                        {idx === 0 &&
                                          flora.VAGINA.manual_answer}
                                        {" " + check.variant}
                                      </p>
                                    ))
                                : flora.VAGINA.manual_answer
                              : flora.VAGINA.possible_result.length > 0
                              ? flora.VAGINA.possible_result[0].item_values
                                  .filter((val) => val.checked)
                                  .map((check, idx) => (
                                    <p key={check.variant + idx}>
                                      {check.variant}
                                    </p>
                                  ))
                              : ""}
                          </TableCell>
                        )}
                        {flora.CERVIX && (
                          <TableCell className={classes.tableCell}>
                            {flora.CERVIX &&
                            flora.CERVIX.has_manual_input &&
                            flora.CERVIX.manual_answer
                              ? flora.CERVIX.possible_result.length > 0
                                ? flora.CERVIX.possible_result[0].item_values
                                    .filter((val) => val.checked === true)
                                    .map((check, idx) => (
                                      <p key={check.variant + idx}>
                                        {idx === 0 &&
                                          flora.CERVIX.manual_answer}
                                        {" " + check.variant}
                                      </p>
                                    ))
                                : flora.CERVIX.manual_answer
                              : flora.CERVIX &&
                                flora.CERVIX.possible_result &&
                                flora.CERVIX.possible_result.length > 0
                              ? flora.CERVIX.possible_result[0].item_values
                                  .filter((val) => val.checked)
                                  .map((check, idx) => (
                                    <p key={check.variant + idx}>
                                      {check.variant}
                                    </p>
                                  ))
                              : ""}
                          </TableCell>
                        )}
                        <TableCell className={classes.tableCell}>
                          {flora.URETRA && flora.URETRA.referent_value}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        )}
      {assay.test !== "Назоцитограма" &&
        assay.result &&
        !assay.result.includes('[{"') &&
        typeof assay.result === "object" && (
          <Table className={classes.table} size="small" padding="none">
            {assay.resulttype === "flora" &&
              assay.test !== "Назоцитограма" &&
              assay.resulttmlt.length === 0 && (
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCellHead}>
                      <strong>Вид дослідження</strong>
                    </TableCell>
                    <TableCell className={classes.tableCellHead}>
                      <strong>URETRA</strong>
                    </TableCell>
                    <TableCell className={classes.tableCellHead}>
                      <strong>VAGINA</strong>
                    </TableCell>
                    <TableCell className={classes.tableCellHead}>
                      <strong>CERVIX</strong>
                    </TableCell>
                    <TableCell className={classes.tableCellHead}>
                      <strong>Референтні значення</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}
            {assay.resulttype === "flora" &&
              assay.test !== "Назоцитограма" &&
              assay.resulttmlt.length !== 0 && (
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCellHead}>
                      <strong>Вид дослідження</strong>
                    </TableCell>
                    <TableCell className={classes.tableCellHead}>
                      <strong>URETRA</strong>
                    </TableCell>
                    <TableCell className={classes.tableCellHead}>
                      <strong>Референтні значення</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}
            <TableBody>
              {assay.result &&
                assay.result
                  .reduce((acu, cur) => {
                    if (acu.find((item) => item.parametr === cur.parametr)) {
                      return [
                        ...acu.filter((item) => item.parametr !== cur.parametr),
                        {
                          ...acu.find((item) => item.parametr === cur.parametr),
                          [cur.group_name]: cur,
                        },
                      ];
                    } else {
                      return [
                        ...acu,
                        {
                          [cur.group_name]: cur,
                          parametr: cur.parametr,
                          id: cur.id,
                        },
                      ];
                    }
                  }, [])
                  .map((flora, idx) => {
                    return flora.null ? (
                      <TableRow key={flora.id + idx}>
                        <TableCell className={classes.tableCell}>
                          {flora.parametr}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {flora.null.has_manual_input &&
                          flora.null.manual_answer
                            ? flora.null.possible_result.length > 0
                              ? flora.null.possible_result.map((resultItem) =>
                                  resultItem.item_values
                                    .filter((val) => val.checked === true)
                                    .map((check, idx) => (
                                      <p key={check.variant + idx}>
                                        {idx === 0 && flora.null.manual_answer}
                                        {" " +
                                          resultItem.item_name +
                                          " " +
                                          check.variant}
                                      </p>
                                    ))
                                )
                              : flora.null.manual_answer
                            : flora.null.possible_result.length > 0
                            ? flora.null.possible_result.map((resultItem) =>
                                resultItem.item_values
                                  .filter((val) => val.checked)
                                  .map((check, idx) => (
                                    <p key={check.variant + idx}>
                                      {resultItem.item_name +
                                        " - " +
                                        check.variant}
                                      ;
                                    </p>
                                  ))
                              )
                            : ""}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow key={flora.id + idx}>
                        <TableCell className={classes.tableCell}>
                          {flora.parametr}
                        </TableCell>
                        {flora.URETRA && (
                          <TableCell className={classes.tableCell}>
                            {flora.URETRA &&
                            flora.URETRA.has_manual_input &&
                            flora.URETRA.manual_answer
                              ? flora.URETRA.possible_result.length > 0
                                ? flora.URETRA.possible_result[0].item_values
                                    .filter((val) => val.checked === true)
                                    .map((check, idx) => (
                                      <p key={check.variant + idx}>
                                        {idx === 0 &&
                                          flora.URETRA.manual_answer}
                                        {" " + check.variant}
                                      </p>
                                    ))
                                : flora.URETRA.manual_answer
                              : flora.URETRA.possible_result.length > 0
                              ? flora.URETRA.possible_result[0].item_values
                                  .filter((val) => val.checked)
                                  .map((check, idx) => (
                                    <p key={check.variant + idx}>
                                      {check.variant}
                                    </p>
                                  ))
                              : ""}
                          </TableCell>
                        )}
                        {flora.VAGINA && (
                          <TableCell className={classes.tableCell}>
                            {flora.VAGINA &&
                            flora.VAGINA.has_manual_input &&
                            flora.VAGINA.manual_answer
                              ? flora.VAGINA.possible_result.length > 0
                                ? flora.VAGINA.possible_result[0].item_values
                                    .filter((val) => val.checked === true)
                                    .map((check, idx) => (
                                      <p key={check.variant + idx}>
                                        {idx === 0 &&
                                          flora.VAGINA.manual_answer}
                                        {" " + check.variant}
                                      </p>
                                    ))
                                : flora.VAGINA.manual_answer
                              : flora.VAGINA.possible_result.length > 0
                              ? flora.VAGINA.possible_result[0].item_values
                                  .filter((val) => val.checked)
                                  .map((check, idx) => (
                                    <p key={check.variant + idx}>
                                      {check.variant}
                                    </p>
                                  ))
                              : ""}
                          </TableCell>
                        )}
                        {flora.CERVIX && (
                          <TableCell className={classes.tableCell}>
                            {flora.CERVIX &&
                            flora.CERVIX.has_manual_input &&
                            flora.CERVIX.manual_answer
                              ? flora.CERVIX.possible_result.length > 0
                                ? flora.CERVIX.possible_result[0].item_values
                                    .filter((val) => val.checked === true)
                                    .map((check, idx) => (
                                      <p key={check.variant + idx}>
                                        {idx === 0 &&
                                          flora.CERVIX.manual_answer}
                                        {" " + check.variant}
                                      </p>
                                    ))
                                : flora.CERVIX.manual_answer
                              : flora.CERVIX &&
                                flora.CERVIX.possible_result &&
                                flora.CERVIX.possible_result.length > 0
                              ? flora.CERVIX.possible_result[0].item_values
                                  .filter((val) => val.checked)
                                  .map((check, idx) => (
                                    <p key={check.variant + idx}>
                                      {check.variant}
                                    </p>
                                  ))
                              : ""}
                          </TableCell>
                        )}
                        <TableCell className={classes.tableCell}>
                          {flora.URETRA.referent_value}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        )}
      {assay.test === "Назоцитограма" && (
        <Table className={classes.table} size="small" padding="none">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellHead}>
                <strong>Вид дослідження</strong>
              </TableCell>
              {nazocytogramGroups
                  ? <>
                    {groupNames.map((groupName, idx) => <TableCell className={classes.tableCellHead} key={groupName+idx}>
                      <strong>{groupName}</strong>
                    </TableCell>)}
                    <TableCell className={classes.tableCellHead}>
                      <strong>Референтні значення</strong>
                    </TableCell>
                  </>
                  : <TableCell className={classes.tableCellHead}>
                    <strong>Результат</strong>
                </TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {assay.result &&
              parsedResult &&
              !nazocytogramGroups &&
              parsedResult.map((item) => {

                return (
                  <TableRow key={item.id}>
                    <TableCell className={classes.tableCell}>
                      {item.parametr}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {item.has_manual_input && item.manual_answer}
                      {item.possible_result.map((posRes, idx) => {
                        return (
                          <Table key={item.id + " " + idx}>
                            <TableBody>
                              <TableRow>
                                {posRes.item_name !== "-" && (
                                  <TableCell className={classes.tableCell}>
                                    {posRes.item_name}
                                  </TableCell>
                                )}
                                {posRes.item_values
                                  .filter(
                                    (filteredItem) => filteredItem.checked
                                  )
                                  .map((itemVal, index) => {
                                    return (
                                      <TableCell
                                        key={item.id + " " + idx + "" + index}
                                        className={classes.tableCell}
                                      >
                                        {itemVal.variant + " "}
                                      </TableCell>
                                    );
                                  })}
                              </TableRow>
                            </TableBody>
                          </Table>
                        );
                      })}
                    </TableCell>
                  </TableRow>
                );
              })}
            {assay.result &&
                parsedResult &&
                nazocytogramGroups &&
                parsedResult.reduce((acu, cur) => {
                    const includedItem = acu.find(item => item.parametr === cur.parametr)
                    if (includedItem) {

                      return [...acu.filter(item => item.parametr !== cur.parametr), {
                        ...includedItem,
                        groupResult: [...includedItem.groupResult, cur]
                      }]
                    }

                    return [...acu, {
                      ...cur,
                      groupResult: [cur]
                    }]
                  }, []).map((resultGroup) => {


                      return (<TableRow key={resultGroup.id}>
                            <TableCell className={classes.tableCell}>
                              {resultGroup.parametr}
                            </TableCell>
                            {groupNames.map(name => {
                              const item = resultGroup.groupResult.find(it => it.group_name === name)

                              return <TableCell className={classes.tableCell} key={name+resultGroup.id}>
                                {item.manual_answer && item.manual_answer}
                                {!item.manual_answer && item.possible_result.map((posRes, idx) => {

                                  return (
                                      <Table key={item.id + " " + idx}>
                                        <TableBody>
                                          <TableRow>
                                            {posRes.item_name !== "-" && (
                                                <TableCell className={classes.tableCell}>
                                                  {posRes.item_name}
                                                </TableCell>
                                            )}
                                            {posRes.item_values
                                                .filter(
                                                    (filteredItem) => filteredItem.checked
                                                )
                                                .map((itemVal, index) => {
                                                  return (
                                                      <TableCell
                                                          key={item.id + " " + idx + "" + index}
                                                          className={classes.tableCell}
                                                      >
                                                        {itemVal.variant + " "}
                                                      </TableCell>
                                                  );
                                                })}
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                  );
                                })}
                              </TableCell>
                            })}
                            <TableCell className={classes.tableCell}>
                              {resultGroup.referent_value}
                            </TableCell>
                          </TableRow>
                      );
                  })
                }
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default ResultFlora;
