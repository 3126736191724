import React from 'react';
import {Button, makeStyles, TextField,} from '@material-ui/core';

const useStyles = makeStyles({
  rootContainer: {
    padding: '10px 10px 20px 10px',
    width: '100%',
    maxWidth: '900px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  bodyContainer: {
    width: '100%',
    display: 'flex',
    flexFlow: 'nowrap column',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitBtn: {
    marginTop: 10,
  },
});

function Support() {
  const classes = useStyles();
  return (
    <div className={classes.rootContainer}>
        <div className={classes.bodyContainer}>
          <TextField label="Ваша електрона пошта"/>
          <TextField label="Тема"/>
          <TextField multiline label="Опис" rows={6}/>
          <Button className={classes.submitBtn} variant="outlined">Відправити</Button>
        </div>
    </div>
  );
}

export default Support;
