import React, {createContext, useContext, useState} from 'react';

const defaultAppState  = {
    client: [null, () => {
    }],
    orders: [null, () => {
    }],
};

export const AppStateContext = createContext(defaultAppState);

export const AppStateProvider = ({children}) => {
    const [client, setClient] = useState(null);
    const [orders, setOrders] = useState(null);

    const value = {
        client,
        orders,
        setOrders,
        setClient
    };

    return (
        <AppStateContext.Provider value={value}>
            {children}
        </AppStateContext.Provider>
    );
};

export const useAppState = () => useContext(AppStateContext);
