import React, {useEffect} from 'react';
import 'typeface-roboto';
import './main.css';
import {MenuAppBar} from '@cabinet-packages/components';
import PageController from './pages/PageController';
import 'react-virtualized/styles.css';
import {useDispatch, useSelector} from "react-redux";
import {setClient, setListOfPatients} from "@cabinet-packages/redux";
import {useAppState} from "@cabinet-packages/hooks";
import {MuiThemeProvider} from "@material-ui/core";
import themeMaterialUI from "./settings/themeMaterialUI";
import logo from '../src/assets/images/logo.png';
import routes from "./settings/routes";

function LandingPage() {
    const  dispatch = useDispatch();
    const { client } = useAppState();

    const {activePatient, listOfPatients} = useSelector(state => state.patients);
    useEffect(() => {
        if (client && (!activePatient || !listOfPatients)) {
            dispatch(setClient(client));
            dispatch(setListOfPatients(false, true));
        }
    }, [client, activePatient, dispatch, listOfPatients]);
    return (<div className="App">
        <MenuAppBar logo={logo} themeMaterialUI={themeMaterialUI} routes={routes} isReturnToShop={true}/>
        <MuiThemeProvider theme={themeMaterialUI}>
            <PageController/>
        </MuiThemeProvider>
    </div>);
}

export default LandingPage;
