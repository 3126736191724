import React, {useEffect, useState} from 'react';
import {makeStyles, Menu, MenuItem, TextField} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import uaLocale from 'date-fns/locale/uk';
import {useDispatch, useSelector} from "react-redux";
import {setRegions} from "@cabinet-packages/redux/actions/actionsCreators";
import Button from "@material-ui/core/Button";
import {Loading} from "../components";
import moment from 'moment';
import 'moment/locale/uk';
import {sendRefund} from "@cabinet-packages/api/api";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        height: '100%',
        padding: '8px',
    },
    innerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
    successRefund: {
        textAlign: 'center'
    },
    formWrapper: {
        maxWidth: '500px',
        minWidth: '300px',
        padding: '8px',
        height: 'max-content',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    textField: {
        marginTop: '8px',
        width: '100%',
    },
    helperText: {
        width: '100%',
        color: theme.palette.error.light,
        textAlign: 'right',
        fontSize: '10px',
    },
}));

moment.locale('uk');

const RefundPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {regions} = useSelector(store => store.collectionPoints);
    const [isSendRefund, setIsSendRefund] = useState(false);
    const [state, setState] = useState({
        lastname: '',
        firstname: '',
        fathername: '',
        city: null,
        orderId: '',
        date: new Date(),
        reason: '',
        phone: '',
    });
    const [anchorEl, setAnchorEl] = useState(null);

    const disabledButton = (state) => {
        const {lastname, firstname, fathername, city, orderId, reason, phone} = state;

        return !(lastname.length && firstname.length && fathername.length && city && orderId.length && reason.length && phone.length);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, reason,region) => {
        event.preventDefault();
        event.stopPropagation();

        setAnchorEl(null);
        if (region) {
            updateState('city', region);
        }
    };

    const updateState = (field, value) => {
        setState(prev => ({
                ...prev,
                [field]: value,
            }
        ));
    };

    const onDateChange = (date, inputDate) => {
        setState(prev => ({
                ...prev,
                date,
                inputDate,
            }
        ));
    };

    const handleSendRefund = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const { lastname, firstname, fathername, city, orderId, date, reason, phone} = state;

        const result = await sendRefund(lastname, firstname, fathername, orderId, phone, city.name, moment(date).format('YYYY-MM-DD'), reason);

        if (result) setIsSendRefund(!!result);
    }

    useEffect(() => {
        if (!regions) dispatch(setRegions());
    }, [regions, dispatch]);

    return (<div className={classes.root}>
        {isSendRefund
            ? <div className={classes.innerWrapper}>
                <p className={classes.successRefund}>Ваша заявка прийнята, та буде розглянута найближчим часом!</p>
            </div>
            : <div className={classes.innerWrapper}>
            {state && regions
                ? <div className={classes.formWrapper}>
                    <h4>Дані пацієнта:</h4>
                    <TextField
                        className={classes.textField}
                        value={state.lastname}
                        onChange={(event) => updateState('lastname', event.target.value)}
                        label="Прізвище"
                        variant="outlined"
                        size="small"
                    />
                    <TextField
                        className={classes.textField}
                        value={state.firstname}
                        onChange={(event) => updateState('firstname', event.target.value)}
                        label="Ім'я"
                        variant="outlined"
                        size="small"
                    />
                    <TextField
                        className={classes.textField}
                        value={state.fathername}
                        onChange={(event) => updateState('fathername', event.target.value)}
                        label="По-батькові"
                        variant="outlined"
                        size="small"
                    />
                    <TextField
                        className={classes.textField}
                        value={state.orderId}
                        onChange={(event) => updateState('orderId', event.target.value)}
                        label="Код веб замовлення:"
                        variant="outlined"
                        size="small"
                        type="number"
                    />
                    <TextField
                        className={classes.textField}
                        value={state.phone}
                        onChange={(event) => updateState('phone', event.target.value)}
                        label="Номер телефону:"
                        variant="outlined"
                        size="small"
                        type="number"
                    />
                    <div>
                        {state.city && state.city.name && <p>
                            Вибране місто:
                        </p>}
                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                            variant="contained"
                            className={classes.textField}
                            color={state.city && state.city.name ? 'secondary' : 'default'}
                        >
                            {
                                state.city && state.city.name
                                    ? state.city.name
                                    : 'Натисніть для вибору міста'
                            }
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {
                                regions.map(region => (
                                    <MenuItem
                                        key={region.idReg}
                                        onClick={(event, reason) => handleClose(event, reason, region)}
                                    >
                                        {region.name}
                                    </MenuItem>))
                            }
                        </Menu>
                    </div>
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={uaLocale}
                    >
                        <KeyboardDatePicker
                            autoOk={true}
                            showTodayButton={true}
                            value={state.date}
                            format="yyyy-MM-dd"
                            onChange={onDateChange}
                            cancelLabel="Назад"
                            okLabel="Ок"
                            todayLabel="Сьогодні"
                            invalidDateMessage="Неправильний формат дати."
                            label="Оберіть дату замовлення"
                            className={classes.textField}
                        />
                    </MuiPickersUtilsProvider>
                    <TextField
                        className={classes.textField}
                        value={state.reason}
                        onChange={(event) => updateState('reason', event.target.value)}
                        label="Опишіть причину скасування"
                        variant="outlined"
                        rows={3}
                    />
                    <Button
                        variant="outlined"
                        className={classes.textField}
                        color="primary"
                        disabled={disabledButton(state)}
                        onClick={handleSendRefund}
                    >
                        надіслати
                    </Button>
                    <p className={classes.helperText}>
                        всі поля є обов'язковими для заповнення
                    </p>
                </div>
                : <Loading/>}
        </div>}
    </div>);
};

export default RefundPage;
