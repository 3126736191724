import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    rootSiteAuth: {
        width: '100vw',
        height: '100vh',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    error: {
        color: "red",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    logo: {
        height: '70px',
        margin: theme.spacing(1),
    },
}));

export default useStyles;
