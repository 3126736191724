import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import PrepareToBloodDonation from '../assets/howToPrepareImages/10.svg';
import PrepareToPharyngealSwab from '../assets/howToPrepareImages/5.png';
import PrepareToCovid from '../assets/howToPrepareImages/1.png';
import PrepareToUrogenital from '../assets/howToPrepareImages/2.png';
import PrepareToFemale from '../assets/howToPrepareImages/7.png';
import PrepareToMale from '../assets/howToPrepareImages/8.png';
import PrepareToUrine from '../assets/howToPrepareImages/3.png';
import PrepareToFecal from '../assets/howToPrepareImages/4.png';
import PrepareToSkin from '../assets/howToPrepareImages/6.png';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        height: 'max-content',
        minHeight: '100vh',
        padding: '0 8px 8px 8px',
    },
    innerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: 'max-content',
        minHeight: '100%',
    },
    article: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '8px',
        borderBottom: `1px dashed ${theme.palette.primary.main}`,

        '@media (max-width: 900px)': {
            flexDirection: 'column',
        }
    },
    articleImageWrapper: {
        width: '15%',
        height: '100%',
        padding: '8px',

        '@media (max-width: 900px)': {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        }
    },
    articleImage: {
        margin: '0 auto',
        width: '100px',
    },
    articleTextWrapper: {
        width: '85%',

        '@media (max-width: 900px)': {
            width: '100%',
        }
    },
    articleTextHeader: {
        color: theme.palette.primary.main,
    },
    articleTextRed: {
        color: 'red',
    },
    articleTextMain: {
        color: theme.palette.primary.main,
    },
    articleTextList: {
        listStyleType: 'square',
        listStylePosition: 'inside',
    },
    articleTextListRound: {
        listStyleType: 'disc',
        listStylePosition: 'inside',
    },
    articleTextListNoMarks: {
        listStyleType: 'none',
        paddingLeft: '24px'
    },
    articleTextLink: {
        textDecoration: 'none',
        color: theme.palette.primary.main,

        '&:hover': {
            textDecoration: 'underline',
            color: 'black'
        },
    },
    articleTextInnerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '8px',

        '@media (max-width: 900px)': {
            flexDirection: 'column',
        },
    },
}));

const HowToPrepare = () => {
    const classes = useStyles();

    return (<div className={classes.root}>
            <div className={classes.innerWrapper}>
                <div>
                    <h1>Підготовка до аналізів</h1>
                    <p><strong>Як підготуватись до здачі аналізів? </strong>Звичайно, здача аналізів - це не здача екзаменів, але підготуватись все ж треба.</p>
                </div>

                <div className={classes.article}>
                    <div className={classes.articleImageWrapper}>
                        <img
                            src={PrepareToBloodDonation}
                            alt="Пробірка для забору крові."
                            className={classes.articleImage}
                        />
                    </div>
                    <div className={classes.articleTextWrapper}>
                        <h3 className={classes.articleTextHeader}>
                            Підготовка до здачі аналізу венозної
                            та капілярної <strong className={classes.articleTextRed}>крові</strong>
                        </h3>
                        <ul className={classes.articleTextList}>
                            <li><strong className={classes.articleTextRed}>Кров</strong> на дослідження приймається тільки <strong>натщесерце;</strong></li>
                            <li>Напередодні здачі венозної <strong className={classes.articleTextRed}>крові</strong> для досліджень слід виключити з раціону жирну їжу;</li>
                            <li>Стреси, емоційне збудження, фізичне навантаження (навіть підйом по сходах)
                                впливають на проведення тестів і спотворюють результати досліджень. Перед процедурою
                                взяття <strong className={classes.articleTextRed}>крові</strong> рекомендується
                                посидіти в спокійному стані 15-30 хв.
                            </li>
                            <li>На результати досліджень можуть впливати лікарські препарати.</li>
                        </ul>
                        <p>Сиворотка <strong className={classes.articleTextRed}>крові</strong> пацієнта зберігається
                            у <strong className={classes.articleTextMain}>Медичній лабораторії "МеДіС"</strong> протягом
                            3-ох днів для можливості проведення додаткових або повторних
                            досліджень при достатній кількості біоматеріалу, таким чином Вам
                            не обов'язково повторно проходити процедуру
                            забору <strong className={classes.articleTextRed}>крові</strong> при такій необхідності.
                        </p>
                    </div>
                </div>

                <div className={classes.article}>
                    <div className={classes.articleImageWrapper}>
                        <img
                            src={PrepareToPharyngealSwab}
                            alt="Забір мазка з ротоглотки."
                            className={classes.articleImage}
                        />
                    </div>
                    <div className={classes.articleTextWrapper}>
                        <h3 className={classes.articleTextHeader}>
                            Підготовка до забору мазка з ротоглотки
                        </h3>
                        <p>Проводиться натщесерце або не раніше, ніж через 2-4 год після прийому їжі.</p>
                        <a
                            target="_blank"
                            href="https://www.medis.com.ua/UserFiles/Image/%D0%86%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D1%96%D1%8F%20%D0%BF%D0%BE%20%D0%B7%D0%B0%D0%B1%D0%BE%D1%80%D1%83%20%D0%BC%D0%B0%D0%B7%D0%BA%D0%B0%20%D0%B7%20%D1%80%D0%BE%D1%82%D0%BE%D0%B3%D0%BB%D0%BE%D1%82%D0%BA%D0%B8.pdf"
                            className={classes.articleTextLink}
                        >
                            Інструкція по забору мазка з ротоглотки
                        </a>
                    </div>
                </div>

                <div className={classes.article}>
                    <div className={classes.articleImageWrapper}>
                        <img
                            src={PrepareToCovid}
                            alt="Ковід."
                            className={classes.articleImage}
                        />
                    </div>
                    <div className={classes.articleTextWrapper}>
                        <h3 className={classes.articleTextHeader}>
                            Підготовка до забору матеріалу на Коронавірус 2019-nCoV
                        </h3>
                        <p>Забір мазка проводиться натще або не раніше ніж через 2 години після прийому їжі. Також, напередодні</p>
                        <p>Забір дітям на ПЛР-тест проводимо після досягнення 5-ти річного віку.</p>
                        <p>Матеріал для дослідження відбирають медичні працівники, які одягнені в засоби індивідуального захисту.</p>
                    </div>
                </div>

                <div className={classes.article}>
                    <div className={classes.articleImageWrapper}>
                        <img
                            src={PrepareToUrogenital}
                            alt="Урогенітальні інфекції."
                            className={classes.articleImage}
                        />
                    </div>
                    <div className={classes.articleTextWrapper}>
                        <h3 className={classes.articleTextHeader}>
                            Підготовка до забору матеріалу для діагностики урогенітальних інфекцій
                        </h3>
                        <p>Напередодні проведення забору матеріалу для ПЛР (діагностика yрогенітальних інфекцій) необхідно:</p>
                        <ul className={classes.articleTextList}>
                            <li>виключити алкогольні напої та статеві контакти протягом 3-ох днів;</li>
                            <li>не приймати антибактеріальні препарати протягом 2-ох тижнів.</li>
                        </ul>
                    </div>
                </div>

                <div className={classes.article}>
                    <div className={classes.articleImageWrapper}>
                        <img
                            src={PrepareToFemale}
                            alt="Зеркальце Венери."
                            className={classes.articleImage}
                        />
                    </div>
                    <div className={classes.articleTextWrapper}>
                        <h3 className={classes.articleTextHeader}>
                            Підготовка до здачі аналізів для жінок
                        </h3>
                        <p>Жінки перед аналізом мають вказати день менструального циклу, при вагітності - її строк, при менопаузі - час її початку.</p>
                        <p><strong>При вагітності слід звернути увагу на те, що:</strong></p>
                        <div className={classes.articleTextInnerWrapper}>
                            <div>
                                <p>1. призначають у <strong>1-му триместрі (10-12 тижні вагітності):</strong></p>
                                <ul className={classes.articleTextListNoMarks}>
                                    <li>вільний ХГ</li>
                                    <li>PPAP-A білок</li>
                                </ul>
                            </div>
                            <div>
                                <p>2. призначають у <strong>2-му триместрі (16-18 тижні вагітності):</strong></p>
                                <ul className={classes.articleTextListNoMarks}>
                                    <li>загальний ХГ</li>
                                    <li>альфа-фетопротеїн</li>
                                    <li>вільний естріол</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.article}>
                    <div className={classes.articleImageWrapper}>
                        <img
                            src={PrepareToMale}
                            alt="Щит і спис Марсу."
                            className={classes.articleImage}
                        />
                    </div>
                    <div className={classes.articleTextWrapper}>
                        <h3 className={classes.articleTextHeader}>
                            Підготовка до здачі аналізів для чоловіків
                        </h3>
                        <p>Після будь-яких маніпуляцій на простаті аналізи на ПСА можуть проводитись лише <strong>через 7 днів.</strong></p>
                    </div>
                </div>

                <div className={classes.article}>
                    <div className={classes.articleImageWrapper}>
                        <img
                            src={PrepareToUrine}
                            alt="Посудина для здачі сечі."
                            className={classes.articleImage}
                        />
                    </div>
                    <div className={classes.articleTextWrapper}>
                        <h3 className={classes.articleTextHeader}>
                            Підготовка до здачі аналізу сечі (порція сечі 100мл)
                        </h3>
                        <p>Після будь-яких маніпуляцій на простаті аналізи на ПСА можуть проводитись лише <strong>через 7 днів.</strong></p>
                        <ul className={classes.articleTextList}>
                            <li>Перед забором сечі слід провести гігієнічний туалет зовнішніх статевих органів;</li>
                            <li>У лабораторію здається 100 мл сечі у спеціальному стерильному контейнері для сечі;</li>
                            <li><strong>Ранішня порція сечі</strong> забирається відразу після сну;</li>
                            <li><strong>Аналіз сечі по Нечипоренко:</strong> проводиться туалет зовнішніх статевих органів, після чого забирається середня порція першої ранішньої сечі.</li>
                        </ul>
                    </div>
                </div>

                <div className={classes.article}>
                    <div className={classes.articleImageWrapper}>
                        <img
                            src={PrepareToFecal}
                            alt="Посудина для здачі калу."
                            className={classes.articleImage}
                        />
                    </div>
                    <div className={classes.articleTextWrapper}>
                        <h3 className={classes.articleTextHeader}>
                            Підготовка до здачі аналізу калу
                        </h3>
                        <ul className={classes.articleTextList}>
                            <li>Напередодні здачі аналізу звернутися на відділення лабораторного центру «Медіс» та взяти
                                спеціальну пробірку PARASEPT;</li>
                            <li>Необхідно спорожнити сечовий міхур, провести туалет зовнішніх статевих органів і анальної
                                ділянки водою із застосуванням нейтрального мила без ароматичних домішок;</li>
                            <li>Матеріал для аналізу збирається з 4-5 різних місць спеціальною лопаткою з прозорим
                                ковпачком;</li>
                            <li>Загальна кількість зібраного матеріалу повинна бути 0,5г (розміром з горошину);</li>
                            <li>Вкрутити лопатку з прозорим ковпачком в пробірку;</li>
                            <li>Пробірку із зібраним матеріалом необхідно доставити в лабораторію Медіс не пізніше,
                                як за 24 години після забору;</li>
                            <li>За 2-3 дні до забору калу для дослідження рекомендується відмінити прийом лікарських
                                засобів, що змінюють характер калу та функціонування шлунково – кишкового тракту,
                                антибіотики, ректальні свічки;</li>
                            <li>За 2 доби виключити із раціону помідори, томатний сік, пасту, буряк та інші овочі та фрукти,
                                що містять в собі барвники;</li>
                            <li>Вкрутити лопатку з прозорим ковпачком в пробірку;</li>
                            <li>Для дослідження не підходить кал після проведення клізми, використання ректальних свічок,
                                прийому проносних засобів, касторової і вазелінової олії, препаратів заліза, барію,
                                вісмуту та ін.;</li>
                            <li>Жінкам не рекомендується здавати аналіз калу під час менструаціі;</li>
                            <li>У маленьких дітей дозволяється забір калу з підгузка.</li>
                        </ul>
                    </div>
                </div>

                <div className={classes.article}>
                    <div className={classes.articleImageWrapper}>
                        <img
                            src={PrepareToSkin}
                            alt="Клітини під мікроскопом."
                            className={classes.articleImage}
                        />
                    </div>
                    <div className={classes.articleTextWrapper}>
                        <h3 className={classes.articleTextHeader}>
                            Підготовка до здачі матеріалу перед дослідженням на демодекоз
                        </h3>
                        <ul className={classes.articleTextListRound}>
                            <li>Впродовж 3-х днів перед дослідженням на демодекоз не можна застосовувати будь-яких
                                косметичних або гігієнічних засобів по догляду за обличчям (крем, мило, тонік і тд.);
                            </li>
                            <li>У день взяття біоматеріалу не можна вмиватися.</li>
                        </ul>
                    </div>
                </div>
            </div>
    </div>);
};

export default HowToPrepare;
