import React from 'react';
import {InputAdornment, makeStyles, TextField} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import cn from 'classnames';
import {useDispatch, useSelector} from "react-redux";
import {Autocomplete} from "@material-ui/lab";
import {setSelectedTags, setToggleChecked} from "@cabinet-packages/redux";

const useStyles = makeStyles(({
  textField: {

  },
    input: {

    },
}));

function SearchTextField({ value, onChange, className, letters, ...other }) {
    const classes = useStyles();
    const {tags, selectedTags, toggleChecked}  = useSelector(state => state.assaysCatalog);
    const dispatch = useDispatch();
    const activeLetter = letters;
    // const [defaultValue] = useState(selectedTags);

    return (
      <Autocomplete
          className={cn(className)}
          multiple
          id="tags-standard"
          // defaultValue={defaultValue}
          value={selectedTags}
          onChange={(event, value,reason) => {
              dispatch(setSelectedTags(value));
              if (!toggleChecked) dispatch(setToggleChecked(!toggleChecked));
              if (activeLetter !== 'Всі') onChange({target: {value: ''}})
          }}
          onInputChange={(event, value,reason) => {
              if (reason !== 'reset') onChange(event);
              if (!toggleChecked) dispatch(setToggleChecked(!toggleChecked))
          }}
          inputValue={value && value}
          disableClearable
          options={tags || []}
          getOptionLabel={(option) => option.name}
          noOptionsText={"Тегів не знайдено."}
          renderInput={(params) => (
            <TextField
                fullWidth
                inputProps={{
                    }}
              label="Пошук"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon/>
                  </InputAdornment>
                ),
              }}
              {...other}
                {...params}
                rowsMax={1}
            />)}
          />
  );
}


export default SearchTextField;