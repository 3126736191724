import React, {useEffect} from "react";
import {Tab, Tabs, withStyles} from "@material-ui/core";
import {tabItemStyles, tabsStyles} from "../helpers/styledTabs";
import TabLabel from "./TabLabel";
import {useDispatch, useSelector} from "react-redux";
import {TabContext} from "@material-ui/lab";
import useStyles from "../styles";
import {CustomDialog, NewPatientDialog} from "@cabinet-packages/components";
import {selectActivePatient} from "@cabinet-packages/redux";

const TabsWithStyles = withStyles(tabsStyles)(Tabs);
const TabWithStyles = withStyles(tabItemStyles)(Tab);

const StyledTabs = ({selectedTab}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = selectedTab;
    const { activePatient, listOfPatients } = useSelector(state => state.patients);
    const { themeMaterialUI } = useSelector(state => state.theme);

    const handleChangeTab = (e, index) => {
        e.persist();
        e.preventDefault();
        e.stopPropagation();

        if (index === 'newPatient') {
            CustomDialog(NewPatientDialog, {}, false, themeMaterialUI);
        } else {
            dispatch(selectActivePatient(listOfPatients[index]));
            setTabIndex(index);
        }
    };

    useEffect(() => {
        if (listOfPatients && activePatient) {
            const indexOfActivePatient = listOfPatients.reduce((acu, cur, idx) => {
                if (+cur.id === +activePatient.id) {
                    return idx;
                }

                return acu;
            }, tabIndex);

            if (+tabIndex !== +indexOfActivePatient) {
                setTabIndex(indexOfActivePatient+'');
            }
        }
    }, [listOfPatients]);

    return (<TabContext value={tabIndex}>
        {listOfPatients
        && typeof listOfPatients !== 'string'
        && <TabsWithStyles
            value={tabIndex}
            onChange={handleChangeTab}
            variant="scrollable"
            orientation="horizontal"
            scrollButtons='on'
        >
            {listOfPatients
                .map((item, idx) => {
                    return (<TabWithStyles
                            key={item.id + 'tab' + idx}
                            value={idx + ''}
                            wrapped={true}
                            label={<TabLabel
                                isSelected={+idx === +tabIndex}
                                patientName={`${item.lastname} ${item.firstname}`}
                                role={item.main
                                    ? 'Ваш профіль'
                                    : item.temp ? 'Тимчасовий пацієнт' : 'Інший пацієнт'
                                }
                            />}
                        />
                    )}
                )
            }
            <TabWithStyles
                value={'newPatient'}
                wrapped={true}
                label={<div className={cl.tabLabel}>
                    <p className={cl.tabLabelNewPatient}>+ додати пацієнта</p>
                </div>}
            />
        </TabsWithStyles>}
    </TabContext>);
};

export default StyledTabs;
