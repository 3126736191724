import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import patientsReducer, {patientInitialState} from './reducers/patientsReducer';
import orderAssaysReducer, {orderInitialState} from './reducers/orderAssaysReducer';
import assaysCatalogReducer, {assayCatalogOldInitialState} from "./reducers/assaysCatalogReducer";
import collectionPointsReducer, {collectionPointsReducerInitialState} from "./reducers/collectionPointsReducer";
import scheduleReducer, {scheduleReducerInitialState} from "./reducers/scheduleReducer";
import materialThemeReducer, {materialThemeReducerInitialState} from './reducers/materialThemeReducer';
import {createLogger} from "redux-logger/src";
import tentReducer, {tentReducerInitialState} from "./reducers/tentReducer";
import indicatorsReducer, {indicatorsReducerInitialState} from "./reducers/indicatorsReducer";
import {productCatalogReducer} from "@cabinet-packages/pages/ProductCatalog/productCatalogReducer";
import {wizardStepperReducer} from "./moduleReducers/wizardStepperReducer/wizardStepperReducer";
import menuReducer, {menuReducerInitialState} from "./reducers/medisReducer";
import calculatorOrderAssaysReducer, {calculatorOrderInitialState} from "./reducers/calculatorReducer";
import {cabinetInitialState, cabinetReducer} from "./reducers/cabinetTabsReducer";

const logger = createLogger({
  collapsed: true,
});

export const hooksInMiddleware = {
  snackbar: {},
};

const initialState = {
    patients : patientInitialState,
    orderAssays : orderInitialState,
    assaysCatalog : assayCatalogOldInitialState,
    collectionPoints : collectionPointsReducerInitialState,
    scheduleOfOrders : scheduleReducerInitialState,
    theme : materialThemeReducerInitialState,
    jazz : tentReducerInitialState,
    indicators : indicatorsReducerInitialState,
    menuReducer : menuReducerInitialState,
    calculator : calculatorOrderInitialState,
    cabinet: cabinetInitialState
}

export function commonReducer(state = initialState, action) {
    state.patients = patientsReducer(state.patients, action);
    state.orderAssays = orderAssaysReducer(state.orderAssays, action);
    state.assaysCatalog = assaysCatalogReducer(state.assaysCatalog, action);
    state.collectionPoints = collectionPointsReducer(state.collectionPoints, action);
    state.scheduleOfOrders = scheduleReducer(state.scheduleOfOrders, action);
    state.theme = materialThemeReducer(state.theme, action);
    state.jazz = tentReducer(state.jazz, action);
    state.indicators = indicatorsReducer(state.indicators, action);
    state.menuReducer = menuReducer(state.menuReducer, action);
    state.calculator = calculatorOrderAssaysReducer(state.calculator, action);
    state.cabinet = cabinetReducer(state.cabinet, action);
    return state;
}

const store = createStore(
    (state, action) => {
      return {
        ...commonReducer(state, action),
        CATALOG: productCatalogReducer(state.CATALOG, action),
        WIZARD: wizardStepperReducer(state.WIZARD, action)
      };
    },
    initialState,
    composeWithDevTools(
        applyMiddleware(
            thunk.withExtraArgument(hooksInMiddleware),
             // logger,
        )),
);


export default store;
