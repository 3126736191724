import {Provider, useDispatch} from "react-redux";
import {setComplexId, setTheme, store} from "@cabinet-packages/redux";
import {ConfirmDialog, CustomDialog} from "@cabinet-packages/components";
import {ThemeProvider} from "@material-ui/core";
import themeMaterialUI from "./settings/themeMaterialUI";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import uk_utils from "date-fns/locale/uk";
import React, {useEffect} from "react";
import LandingPage from "./LandingPage";
import {isLogin} from "@cabinet-packages/helpers"
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import routes from "./settings/routes";
import {AppStateProvider} from "@cabinet-packages/hooks";
import {setActionImg, setTakeId} from "@cabinet-packages/redux/actions/actionsCreators";
import withClearCache from "./ClearCache";
import actionIMG from './assets/images/promotionImages/action.jpg'
import PageNotFound from "./pages/PageNotFound";

const ClearCacheComponent = withClearCache(AppWrapper);

function ClearCacheApp() {
    return <ClearCacheComponent />;
}

function App () {
    const dispatch = useDispatch();

    useEffect( ()=> {
        dispatch(setTakeId(51));
        dispatch(setComplexId(77));
        dispatch(setTheme(themeMaterialUI));
        dispatch(setActionImg(actionIMG));
    }, [dispatch]);

    return <ThemeProvider theme={themeMaterialUI}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={uk_utils}>
            <Switch>
                <Route path={"/"} exact>
                    {isLogin()
                        ? <Redirect to={"/catalog"}/>
                        : <Redirect to={"/auth"}/>}
                </Route>
                <Route path={"/account-settings"} exact>
                    {isLogin()
                        ? <Redirect to={"/catalog"}/>
                        : <Redirect to={"/auth"}/>}
                </Route>
                {isLogin() && <Route path={"/auth"} exact>
                    <Redirect to={"/catalog"}/>
                </Route>}
                {routes.map((route, index) => {
                    return route.underAuth
                        ? <Route
                            key={index}
                            path={route.path}
                            render={props => (isLogin()
                                ? <LandingPage {...props} />
                                : <Redirect to={"/auth"}/>)
                            }
                        />
                        : <Route
                            key={index}
                            path={route.path}
                            render={props => (<route.component {...props} />)}
                        />})
                }
                <Route>
                    <PageNotFound />
                </Route>
            </Switch>
        </MuiPickersUtilsProvider>
    </ThemeProvider>
}

function AppWrapper () {
    return (<ThemeProvider theme={themeMaterialUI}>
            <Provider store={store}>
                <BrowserRouter getUserConfirmation={(message, callback) => {
                    CustomDialog(ConfirmDialog, {
                        onConfirm: () => callback(true),
                        onCancel: () => callback(false),
                        text: message,
                    }, false, themeMaterialUI);
                }}>
                    <AppStateProvider>
                        <App/>
                    </AppStateProvider>
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    )
}

export default ClearCacheApp;
