import React, {forwardRef} from 'react';
import {
    IconButton,
    ListItem as MListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Tooltip,
} from '@material-ui/core';
import {Link, useHistory} from 'react-router-dom';
import {useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {selectActivePatient, setTurnBack} from "@cabinet-packages/redux";
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles(theme =>({
    item: {
        paddingLeft: '10px'
    },
    primaryText: {
        fontWeight: 600
    },
    secondaryText: {
        fontSize: '12px',
        fontWeight: 600
    },
    selectedItem: {
        backgroundColor: `${theme.palette.primary.newItem} !important`,
        borderRight: `3px ${theme.palette.primary.main} solid`,
    }
}));

const ListItem = forwardRef(({ patient, isSelected, inConfirmOrder }, ref) => {
    const history = useHistory();
    const { turnBack } = useSelector(state => state.orderAssays);
    const { id, birthday } = patient;
    const location = useLocation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleSelectPatient = () => {
        dispatch(selectActivePatient(patient))
        // history.push(`/patient-card/${id}`)
        if (location.pathname.endsWith('/confirm-order') || location.pathname.endsWith('/shop-confirm-order')) return;
        // if (!location.pathname.endsWith('/account-settings')) history.push('/account-settings');
        if (turnBack) {
            history.push('/confirm-order');
            dispatch(setTurnBack(false));
        }
    };

    return (
        <MListItem divider button selected={isSelected} onClick={event => handleSelectPatient()} ref={ref} classes={{selected: classes.selectedItem}}>
          <ListItemText
            primary={patient.lastname + " " + patient.firstname}
            secondary={patient.main ? "Ваш кабінет" : birthday}
            classes={{primary: classes.primaryText, secondary: classes.secondaryText}}
            className={classes.item}
          />
          <ListItemSecondaryAction>
            <Link to={{
              pathname: `/patient-card/${id}`,
              state: { id },
            }}>
                {!inConfirmOrder && <Tooltip title="Натисніть щоб переглянути дані пацієнта." arrow>
                    <IconButton edge="end">
                        <ArrowForwardIcon style={{fontSize: '12px', color: isSelected ? '#2cb8ad' : 'inherit'}}/>
                    </IconButton>
                </Tooltip>}
            </Link>
          </ListItemSecondaryAction>
        </MListItem>
    );
});

export default ListItem;
