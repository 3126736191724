import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/images/logo.png';
import uuidV4 from 'uuid/v4';
import useStyles from "./styles";
import {AccessKey} from "@cabinet-packages/helpers";
import {useDispatch} from "react-redux";
import {selectActivePatient, setListOfPatients} from "@cabinet-packages/redux";
import {useHistory} from "react-router";
import {CircularProgress} from "@material-ui/core";
import {acceptPartner, authorisePartner} from "@cabinet-packages/api";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'IT підтримка © '}
            <Link color="inherit" href="https://mz-group.com.ua/">
                MZ-group
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Authentication() {
    const cl = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [error, setError] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAuthorise, setIsAuthorise] = useState(false);
    const [{uuid, username, password, code}, setCredentials] = useState({uuid: uuidV4(), username: '', password: '', code: ''});

    const handleChange = (e) => {
        e.persist();
        if (e.target.name === 'code' && e.target.value.length > 4) return;
        setCredentials(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    };

    const handleAuthorise = async () => {
        setLoading(true);
        setError(false);
        setErrorPassword(false);

        try {
            await authorisePartner(uuid, username, password);
            setIsAuthorise(true);
        } catch (e) {
            if (e.response && e.response.data && e.response.data.message === 'Password did not match') {
                setErrorPassword('Невірний пароль.');
            } else if (e.response && e.response.data && e.response.data.message === 'User not found')  {
                setErrorPassword('Невірний логін.')
            } else {
                setErrorPassword('Вибачте, виникла помилка.');
            }
        }

        setLoading(false);
    };

    const handleAccept = async () => {
        setLoading(true);
        setError(false);
        try {
            const {data} = await acceptPartner(uuid, code);
            AccessKey.set(data.accessToken);
            dispatch(setListOfPatients(false, false, [data.user]));
            dispatch(selectActivePatient(data.user));
            setCredentials(prevState => ({...prevState, code: ''}));
            setLoading(false);
            history.push('/catalog');
        } catch (e) {
            console.error(e);
            setIsAuthorise(false);
            setError('Невірно введений код.')
            setCredentials(prevState => ({...prevState, code: ''}));
            setLoading(false);
        }
    }

    return (
        <Grid container component="main" className={cl.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={cl.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={cl.paper}>
                    <img src={logo} className={cl.avatar}/>
                    <Typography component="h1" variant="h5">
                        Вхід для партнерів
                    </Typography>
                    {isAuthorise
                        ? <div className={cl.form}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="code"
                                label="Код"
                                name="code"
                                autoComplete="code"
                                autoFocus
                                value={code}
                                onChange={handleChange}
                                disabled={loading}
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={cl.submit}
                                disabled={loading || !code.length}
                                onClick={handleAccept}
                            >
                                Підтвердити код
                            </Button>
                            <div className={cl.loading}>
                                {loading && <CircularProgress/>}
                            </div>
                        </div>
                        : <div className={cl.form}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Логін"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                value={username}
                                onChange={handleChange}
                                disabled={loading}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Пароль"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={handleChange}
                                disabled={loading}
                            />
                            {error && <p style={{color: 'red'}}>{error}</p>}
                            {errorPassword && <p style={{color: 'red'}}>{errorPassword}</p>}
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={cl.submit}
                                disabled={loading || !username.length || !password.length}
                                onClick={handleAuthorise}
                            >
                                Авторизуватись
                            </Button>
                            <Box mt={3}>
                                <Copyright/>
                            </Box>
                            <div className={cl.loading}>
                                {loading && <CircularProgress/>}
                            </div>
                        </div>}
                </div>
            </Grid>
        </Grid>
    );
};