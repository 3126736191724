import {
    ADD_ASSAY_TIME_CALCULATOR,
    ADD_ASSAY_TIME_LINE_CALCULATOR,
    ADD_ASSAY_TO_ORDER_LIST_CALCULATOR,
    ADD_ORDER_NOTE_CALCULATOR,
    ADD_PAYMENT,
    ADD_SELECTED_PUNKT_CALCULATOR,
    CLEAR_ORDERED_ASSAYS_CALCULATOR,
    CLEAR_TAKES_FROM_BASKET_CALCULATOR,
    INIT_CALCULATOR,
    REMOVE_ASSAY_FROM_ORDER_LIST_CALCULATOR,
    SET_ACTIVE_NURSE,
    SET_ADDITIONAL_DATA_CALCULATOR,
    SET_BASKET_CALCULATOR,
    SET_FIND_PARAM,
    SET_FULL_ORDER_DATA_CALCULATOR,
    SET_LOADING_ORDER_CREATE,
    SET_NURSES_CALCULATOR,
    SET_ORDER_ID_CALCULATOR,
    SET_ORDER_VIALS,
    SET_ORDERS,
    SET_PROMO_CODE_CALCULATOR,
    SET_TOGGLE_TAKE_IN_ORDER_CALCULATOR,
    SET_WEB_ORDERS,
    TOGGLE_AUTOMATIC_PRINT_BARCODES,
} from '../actions/actionsTypes';
import moment from 'moment';
import 'moment/locale/uk';

moment.locale('uk');

export const calculatorOrderInitialState = {
    findParam: {
        dateFrom: moment(),
        dateTo: moment(),
        punkt: null,
        findstr:''
    },
    orders: {
        online: null,
        order: null
    },
    orderingList: [],
    amountOfItems: 0,
    totalPrice: 0,
    selectedPunkt: null,
    time: null,
    timeLine: null,
    note: '',
    id: '',
    turnBack: false,
    selectedComplex: null,
    orderId: null,
    goToCollectionPoints: false,
    doctorOrderedAssays: null,
    promoCode: null,
    additionalData: {
        note: '',
        doctor: 0,
        odm: moment().format('yyyy-MM-DD'),
        menopause: 'Не вказано',
        fetus: 0,
        weekP: 0,
        rh_blood: 'Не вказано',
        urina: 0,
        localiz: '',
        observ: 'Не вказано',
        observDate: moment().format('yyyy-MM-DD'),
        observAddress: '',
        observPhone: '',
        lastname: '',
        firstname: '',
        formFilling: {
            basic: true,
            odm: false,
            fetus: false,
            rh_blood: false,
            urina: false,
            localiz: false,
            observ: false,
            translate: false,
            weight: false,
        }
    },
        preparationRules: false,
        personalData: false,
        takeInOrder: false,
        patientTabsRef: null,
        orderDiscount: null,
        fullOrderData: null,
        nurses: null,
        activeNurse: null,
        vials: null,
        automaticPrintBarcodes: false,
        loading: false,
        payment: {
            cash: {checked: false, price: 0},
            card: {checked: false, price: 0},
            bank: {checked: false, price: 0},
            liqp: {checked: false, price: 0}
        }

    }
;

export default function calculatorOrderAssaysReducer(state = calculatorOrderInitialState, {type, payload}) {
    switch (type) {
        case ADD_ASSAY_TO_ORDER_LIST_CALCULATOR:
            return {
                ...state,
                totalPrice: (+state.totalPrice + +payload.assayPrice).toFixed(2),
                orderingList: payload.newOrderingList,
                amountOfItems: state.amountOfItems + 1,
            };
        case ADD_PAYMENT:
            Object.keys(state.payment).map(el => {
                return {
                    ...state,
                    payment: {
                        [el]: {checked: false, price: 0},
                        [payload.name]: payload.data
                    }
                };
            })
        case REMOVE_ASSAY_FROM_ORDER_LIST_CALCULATOR:
            return {
                ...state,
                totalPrice: +(+state.totalPrice - +payload.assayPrice).toFixed(2),
                orderingList: payload.orderingList,
                amountOfItems: state.amountOfItems - 1,
            };
        case CLEAR_ORDERED_ASSAYS_CALCULATOR:
            return {
                ...state,
                orderingList: [],
                totalPrice: 0,
                amountOfItems: 0,
            };
        case ADD_SELECTED_PUNKT_CALCULATOR:
            return {
                ...state,
                selectedPunkt: payload.newSelectedPunkt,
            };
        case ADD_ASSAY_TIME_CALCULATOR:
            return {
                ...state,
                time: payload.time,
                timeLine: null,
            };
        case  ADD_ASSAY_TIME_LINE_CALCULATOR:
            return {
                ...state,
                timeLine: payload.timeLine,
                time: null,
            };
        case ADD_ORDER_NOTE_CALCULATOR:
            return {
                ...state,
                note: payload.note,
            };
        case SET_ORDER_ID_CALCULATOR:
            return {
                ...state,
                orderId: payload.orderId,
            };
        case CLEAR_TAKES_FROM_BASKET_CALCULATOR:
            return {
                ...state,
                orderingList: payload.newOrderingList,
                amountOfItems: payload.newOrderingList.length,
                totalPrice: (state.totalPrice - payload.takesPrice).toFixed(2),
            };
        case SET_PROMO_CODE_CALCULATOR:
            return {
                ...state,
                promoCode: payload.value,
            };
        case SET_ADDITIONAL_DATA_CALCULATOR:
            return {
                ...state,
                additionalData: payload,
            };
        case SET_BASKET_CALCULATOR:
            return {
                ...state,
                orderingList: payload.orderingList,
                amountOfItems: payload.amountOfItems,
                totalPrice: payload.totalPrice,
            };
        case SET_TOGGLE_TAKE_IN_ORDER_CALCULATOR:
            return {
                ...state,
                takeInOrder: !state.takeInOrder,
            };
        case SET_FULL_ORDER_DATA_CALCULATOR:
            return {
                ...state,
                fullOrderData: payload,
            };
        case SET_NURSES_CALCULATOR:
            return {
                ...state,
                nurses: payload,
            };
        case SET_ACTIVE_NURSE:
            return {
                ...state,
                activeNurse: payload,
            };
        case SET_ORDER_VIALS:
            return {
                ...state,
                vials: payload,
            };
        case INIT_CALCULATOR:
            return {
                ...calculatorOrderInitialState,
                findParam: state.findParam,
                automaticPrintBarcodes: state.automaticPrintBarcodes,
                nurses: state.nurses,

            };
        case TOGGLE_AUTOMATIC_PRINT_BARCODES:
            return {
                ...state,
                automaticPrintBarcodes: !state.automaticPrintBarcodes,
            };
        case SET_LOADING_ORDER_CREATE:
            return {
                ...state,
                loading: !state.loading,
            };
        case SET_FIND_PARAM:
            return {
                ...state,
                findParam: payload,
            };
        case SET_WEB_ORDERS:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    online: payload
                },
            };
        case SET_ORDERS:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    order: payload
                },
            };

        default:
            return state;
    }
};
