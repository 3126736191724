import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        minHeight: 'calc(100vh - 393px)',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: theme.spacing(1),

        '@media (max-width: 1180px)': {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center'
        }
    },
    monthWrapper: {
        width: '100%',
        maxWidth: '580px',
        height: 'max-content',
        boxShadow: '2px 0px 10px 0px #70707088',
        borderRadius: '10px',
        paddingBottom: theme.spacing(5),
        minHeight: '510px',
        background: 'white',

        '@media (max-width: 1180px)': {
            minHeight: '400px',
            marginBottom: theme.spacing(3),
        },
    },
    monthHeading: {
        background: theme.palette.primary.dark,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(7.5),
        paddingRight: theme.spacing(5.5),
        paddingTop: '10px',
        paddingBottom: '10px',
        borderRadius: '10px 10px 0px 0px',
        minHeight: '50px',

        '@media (max-width: 1180px)': {
            padding: theme.spacing(2),
        }
    },
    monthHeadingText: {
        fontSize: '16px',
        fontWeight: 600,
        textTransform: 'uppercase',
        lineHeight: '21px',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

        '@media (max-width: 1180px)': {
            fontSize: '14px',
        }
    },
    monthSelectionWrapper: {
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        '@media (max-width: 1180px)': {
            marginTop: 0
        }
    },
    shortNamesWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),

        '@media (max-width: 1180px)': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
        }
    },
    dayShortName: {
        fontSize: '16px',
        color: theme.palette.secondary.main,
        width: '50px',
        textAlign: 'center',

        '@media (max-width: 1180px)': {
            fontSize: '14px',
        }
    },
    monthSelectionButton: {
        color: theme.palette.primary.dark
    },
    monthSelectionTextWrapper: {
        textAlign: 'center',
        textTransform: 'capitalize',
        color: theme.palette.primary.dark,
        fontWeight: 500,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '120px',

        '@media (max-width: 1180px)': {
            fontSize: '14px',
        }
    },
    daysWrapper: {
        width: '100%',
        maxWidth: '340px',
        height: 'max-content',
        boxShadow: '2px 0px 10px 0px #70707088',
        borderRadius: '10px',
        marginLeft: theme.spacing(2.5),
        minHeight: '510px',
        background: 'white',
        transition: '0.3s all',

        '@media (max-width: 1180px)': {
            marginLeft: 0,
            minHeight: '300px',
            marginBottom: theme.spacing(3),
            maxWidth: '500px'
        },
    },
    weekWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingTop: theme.spacing(1.5),

        '@media (max-width: 1180px)': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(1),
        }
    },
    dayWrapper: {
        cursor: 'pointer',
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        width: '50px',
        height: '50px',
        borderRadius: '25px',
        background: theme.palette.secondary.background,
        border: `2px solid ${theme.palette.colors.hoverBackground}`,
        color: theme.palette.primary.dark,
        transition: '.3s all',

        '&:hover': {
            background: theme.palette.primary.lighter,
            transition: '.3s all',
        },

        '@media (max-width: 1180px)': {
            width: '36px',
            height: '36px',
            borderRadius: '18px',
            fontSize: '14px'
        },
    },
    dayWrapperSelected: {
        cursor: 'default',
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        width: '50px',
        height: '50px',
        borderRadius: '25px',
        background: theme.palette.primary.main,
        color: 'white',
        transition: '.3s all',

        '@media (max-width: 1180px)': {
            width: '36px',
            height: '36px',
            borderRadius: '18px',
            fontSize: '14px'
        },
    },
    dayWrapperNotFree: {
        cursor: 'default',
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        width: '50px',
        height: '50px',
        borderRadius: '25px',
        background: theme.palette.blue.calendar,
        color: theme.palette.green.calendar,
        transition: '.3s all',

        '@media (max-width: 1180px)': {
            width: '36px',
            height: '36px',
            borderRadius: '18px',
            fontSize: '14px'
        },
    },
    hoursWrapper: {
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridRowGap: theme.spacing(3),
        alignItems: 'center',
        justifyItems: 'center',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    timeWrapper: {
        cursor: 'pointer',
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        width: '82px',
        height: '29px',
        borderRadius: '15px',
        background: theme.palette.secondary.background,
        border: `2px solid ${theme.palette.colors.hoverBackground}`,
        color: theme.palette.primary.dark,
        transition: '.3s all',

        '&:hover': {
            background: theme.palette.primary.lighter,
            transition: '.3s all',
        },

        '@media (max-width: 1180px)': {
            fontSize: '14px',
        },
    },
    timeWrapperSelected: {
        cursor: 'default',
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        width: '82px',
        height: '29px',
        borderRadius: '15px',
        background: theme.palette.primary.main,
        color: 'white',
        transition: '.3s all',

        '@media (max-width: 1180px)': {
            fontSize: '14px',
        },
    },
    timeWrapperNotFree: {
        cursor: 'default',
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        width: '82px',
        height: '29px',
        borderRadius: '15px',
        background: theme.palette.blue.calendar,
        color: theme.palette.green.calendar,
        transition: '.3s all',

        '@media (max-width: 1180px)': {
            fontSize: '14px',
        },
    },
    timeHeaderWrapperSelected: {
        cursor: 'default',
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        width: 'max-content',
        height: '29px',
        borderRadius: '15px',
        padding: '0 14px',
        background: theme.palette.primary.main,
        color: 'white',
        transition: '.3s all',

        '@media (max-width: 1180px)': {
            fontSize: '14px',
        }
    },
    calendarExplanationWrapper: {
        height: '86px',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        marginTop: '8px',

        '@media (max-width: 1180px)': {
            gridTemplateColumns: '1fr 1fr 1fr',
            gridRowGap: '10px',
            height: 'max-content'
        }
    },
    explanationItemWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    explanationItemWrapperCheckbox: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',

        '@media (max-width: 1180px)': {
            gridColumn: '1 / -1',
            padding: theme.spacing(1),
        }
    },
    explanationItem: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',

        '@media (max-width: 1180px)': {
            flexDirection: 'column-reverse',
            justifyContent: 'flex-start',
        }
    },
    explanationMark: {
        width: '100px',
        height: '30px',
        borderRadius: '15px',

        '@media (max-width: 1180px)': {
            margin: '0 auto',
            width: '90px',
        }
    },
    explanationText: {
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.grey.price,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        marginLeft: theme.spacing(2.5),

        '@media (max-width: 1180px)': {
            margin: '0 auto',
            marginBottom: theme.spacing(1),
            fontSize: '14px',
        }
    },
    formControl: {
        alignItems: 'flex-start',
        marginTop: '-8px',
    },
    checkboxLabel: {
        paddingTop: '8px',
        fontSize: '14px',
        color: theme.palette.grey.price,
        margin: 0,

        '& span': {
            fontSize: '12px',
            color: theme.palette.green.dark,
        },
    },
    loadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        textAlign: 'center',
        height: '70vh',
        '@media(max-width: 900px)': {
            margin: '50px',
        }
    },
}));

export default useStyles;