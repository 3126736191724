import React, {createContext, useCallback, useEffect, useRef, useState} from "react";
import useStyles from "./styles";
import {setActiveStep} from "@cabinet-packages/redux/moduleReducers/wizardStepperReducer/serviceMidleware";
import {getPunkts, setClient, setCreatePatient, setListOfPatients} from "@cabinet-packages/redux";
import {
    clearOrderedAssaysCalculator,
    getCatalogWizard,
    getNursesList,
    setActiveNurse,
    setBasketCalculator,
    setFullOrderData,
    setOrderVials
} from "@cabinet-packages/redux/actions/actionsCreators";
import {useDispatch, useSelector} from "react-redux";
import SearchOrder from "./components/SearchOrder";
import SelectNurse from "./components/SelectNurse";
import PrintOrderPdf from "./components/PrintOrderPdf";
import FullOrderView from "./components/FullOrderView";
import {getOrderData} from "@cabinet-packages/api/api";

export const FindOrderCalculatorContext = createContext(null);

const FindOrderCalculator = ({search = true}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const {takeId} = useSelector(state => state.assaysCatalog);
    const {fullOrderData, totalPrice} = useSelector(state => state.calculator);
    const {punkts} = useSelector(store => store.collectionPoints);
    const [order, setOrder] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [orderFromServer, setOrderFromServer] = useState(null);
    const orderIdRef = useRef(null);
    const nurseIdRef = useRef(null);
    const [nurseId, setNurseId] = useState('');

    const convertWebOrder = async (order) => {
        setLoading(true);
        try {
            dispatch(setFullOrderData(null));
            dispatch(setActiveNurse(null));
            dispatch(setOrderVials(null));
            setError(false);
            dispatch(setFullOrderData(null));
            dispatch(setActiveStep(1));
            dispatch(setCreatePatient());

            const res = await getOrderData(+order);

            const childAnalyzes = res.order ? res.order.analyseList : res.onlineOrder.analyseList;

            dispatch(setFullOrderData({
                ...res,
                childAnalyzes: childAnalyzes.map(assay => ({
                    ...assay,
                    editable: assay.test ? assay.test.reduce((acu, cur) => {
                        if (cur.editable) {
                            return cur.editable;
                        } else {
                            return acu;
                        }
                    }, false) : null
                })),
                punkt: res.order
                    ? punkts.find(punkt => +punkt.id === +res.order.idPunkt)
                    : punkts.find(punkt => +punkt.id === +res.onlineOrder.idPunkt)
            }));
            setOrderFromServer(null);
            dispatch(setClient(res.patient));
            dispatch(setListOfPatients(false, false, [res.patient]));
            dispatch(clearOrderedAssaysCalculator());

            dispatch(setBasketCalculator({
                orderingList: childAnalyzes,
                totalPrice: childAnalyzes.reduce((acu, cur) => {
                    return +acu + cur.price;
                },0),
                amountOfItems: childAnalyzes.filter(item => +item.idParent !== takeId).length,
            }));

            setOrderFromServer(res);
            setOrder('');
        } catch (e) {
            console.error(e);
            if (e.message.includes('400')) {
                setError(`Замовлення з ID ${order} не знайдено.`);
                setOrder('');
                orderIdRef.current && orderIdRef.current.focus();
            } else {
                setError(`Вибачте виникла помилка. Невірний номер замовлення: ${order}.`);
                setOrder('');
                orderIdRef.current && orderIdRef.current.focus();
            }
        }
        setLoading(false);
    };

    const handleInsertionOfResult = () => {
        convertWebOrder(fullOrderData.id+'');
    }

    const getCatalogAndPunktsCallback = useCallback(async () => {
        try {
            setLoading(true);
            await dispatch(getCatalogWizard());
            await dispatch(getPunkts());
            await dispatch(getNursesList())
            setLoading(false);
        } catch (e) {
            console.error(e);
            setError('Виникла помилка. Спробуйте перезавантажити сторінку.');
        }
    }, []);

    useEffect(() => {
        getCatalogAndPunktsCallback();
    }, [getCatalogAndPunktsCallback]);

    return (<FindOrderCalculatorContext.Provider value={{
        convertWebOrder,
        useOrder: [order, setOrder],
        useLoading: [loading, setLoading],
        useOrderFromServer: [orderFromServer, setOrderFromServer],
        orderIdRef,
        nurseIdRef,
        useNurseId: [nurseId, setNurseId],
        handleInsertionOfResult
    }}>
        <div className={cl.rootFindOrder}>
            <div className={cl.fieldsWrapper}>
                {search &&
                <div className={cl.searchOrderWrapper}>
                    <SearchOrder/>
                    {error && <p className={cl.error}>{error}</p>}
                </div>
                }
                {fullOrderData && search && <SelectNurse/>}
                {fullOrderData && !!fullOrderData.ready && <PrintOrderPdf/>}
            </div>
            <FullOrderView/>
        </div>
    </FindOrderCalculatorContext.Provider>);
}

export default FindOrderCalculator;
