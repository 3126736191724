import React, {useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch, useSelector} from "react-redux";
import {getCatalog, selectActivePatientOrders} from "@cabinet-packages/redux/actions/actionsCreators";
import {Card, CardActions, CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {Current, Loading, Scroll} from "../components";
import {useHistory} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {getPayment} from "@cabinet-packages/api/api";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '@media (max-width: 900px)': {
            height: 'calc(100% - 61px)',
        }
    },
    rootWrapper: {
        width: '100%',
        display: 'grid',
        gap: '8px',
        gridTemplateColumns: '1fr 1fr',
        justifyContent: 'center',
        padding: '8px',
        '@media (max-width: 800px)': {
            gridTemplateColumns: '1fr',
        },
    },
    rootCard: {
        minWidth: 275,
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    orderedAssays: {
        color: theme.palette.primary.dark,
    },
    loading: {
        gridColumnStart: "1",
        gridColumnEnd: "3",
        textAlign: 'center'
    },
    refund: {
        fontSize: '10px',
        textAlign: 'right',
        color: 'blue',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingRight: '8px',
    },
    refundLink: {
        cursor: 'pointer',
    },
    error: {
        color: 'red',
    },
    cardActions: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 'auto'
    },
}));

const OrdersHistory = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {activePatientsOrders, activePatient} = useSelector(state => state.patients);
    const {catalog, takeId, complexId, complexResearch, actions} = useSelector(state => state.assaysCatalog);
    // const fullCatalog = catalog && complexResearch && actions && [...catalog, ...complexResearch, ...actions];
    // const createAssaysData = (ids) => catalog && complexResearch && actions && ids.map(currId => (
    //     fullCatalog.find(currAssay => +currAssay.id === +currId.id && currAssay.type === currId.type && !currAssay.superGroupName)));
    // const fullPrice = catalog && complexResearch && actions && fullCatalog && fullCatalog.reduce((acu, cur) => {
    //     return acu + cur.price;
    // },0);

    const OrderCard = ({order}) => {
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(false);

        const handleDownloadPDF = async (event, orderID) => {
            setLoading(true);
            setError(false);

            // const data = {
            //     fullname: `${activePatient && activePatient.lastname && activePatient.lastname} ${activePatient && activePatient.firstname && activePatient.firstname} ${activePatient && activePatient.fathername && activePatient.fathername}`,
            //     idClient: activePatient ? activePatient.id : 0,
            //     dt: new Date().toISOString().slice(0, 10),
            //     price: fullPrice,
            //     priceDiscount: fullPrice,
            //     services: createAssaysData(order.services).map((item, idx) => ({
            //         idPacket: item.id,
            //         idConf: item.idConf,
            //         price: item.price,
            //         packetName: item.name
            //             ? takeId === 14 ? item.name : item.name.split(')')[0] + (item.name.indexOf('(') >= 0 ? ') ' : ' ')
            //             : item.testNames.split(',')[0].split('\\\\').join(''),
            //         priceDiscount: item.discount
            //             ? (item.price - item.price * item.discount / 100)
            //             : item.price,
            //         discount: item.discount,
            //         amount: 1,
            //         debt: 0,
            //         analises: item.type === "complex"
            //             ? item.filling.map(assay => assay.name)
            //             : [],
            //         durationDay: item.duration_day,
            //     })),
            // }

            try {
                const res = await getPayment(orderID);

                const url = window.URL.createObjectURL(new Blob([res], {type: 'application/pdf'}));

                const link = document.createElement('a');
                link.href = url;
                link.download = `${activePatient && activePatient.lastname && activePatient.lastname}_${activePatient && activePatient.firstname && activePatient.firstname}_${activePatient && activePatient.fathername && activePatient.fathername}_замовлення_${orderID}.pdf`;
                link.click();
                link.remove()
            } catch (e) {
                setError(true);
            }

            setLoading(false);
        }

        return (<Card className={classes.rootCard}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {`ID замовлення: ${order.id}`}
                </Typography>
                <Typography variant="h6" component="h6">
                    {activePatient && `ПІБ: ${activePatient.lastname} ${activePatient.firstname} ${activePatient.fathername}`}
                </Typography>
                <Typography variant="h6" component="h4">
                    {`Час прийому: ${order.dt.slice(0, 16)}`}
                </Typography>
                {order.crDate && <Typography variant="h6" component="h4">
                    {`Час створення замовлення: ${order.crDate.slice(0, 16)}`}
                </Typography>}
                <Typography variant="h6" component="h3">
                    Статус оплати: {order.paymentStatus === 'PAYED'
                    ? "ОПЛАЧЕНО"
                    : order.paymentStatus === "NOT_PAYED"
                        ? "НЕОПЛАЧЕНО"
                        : order.paymentStatus === "REJECTED"
                            ? "ОПЛАТА ВІДХИЛЕНА"
                            : "ОЧІКУЄ ПІДТВЕРДЖЕННЯ"}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    {`Ціна: ${order.price}грн`}
                </Typography>
                <Typography variant="body2" component="div">
                    Замовлені дослідження:
                    {order.services.map((assay, idx) => {
                        return (
                            <p
                                key={assay.id + ' ' + idx}
                                className={classes.orderedAssays}
                            >
                                {++idx}. {assay.name}
                            </p>
                        )
                    })}
                </Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
                {loading
                    ? <Loading/>
                    : <Button
                        color="primary"
                        variant={order.paymentStatus === 'PAYED' ? "contained" : "outlined"}
                        fullWidth
                        onClick={(event) => handleDownloadPDF(event, order.id)}
                        disabled={order.paymentStatus !== 'PAYED'}
                    >
                        Скачати PDF
                    </Button>}
                {error && <p className={classes.error}>Вибачте сталась помилка при завантаженні.</p>}
            </CardActions>
        </Card>)
    }

    useEffect(() => {
        if (!catalog && takeId && complexId) dispatch(getCatalog());
        if (catalog && !activePatientsOrders && activePatient) dispatch(selectActivePatientOrders());
    }, [dispatch, activePatientsOrders, catalog, activePatient, takeId, complexId]);

    return (catalog && catalog !== 'error' &&
        <div className={classes.root}>
            <div className={classes.refund}>
                {takeId === 216 &&
                <Button
                    className={classes.refundLink}
                    onClick={() => history.push('/refund')}
                    variant="outlined"
                    size="small"
                    color="default"
                >
                    повернення
                </Button>}
            </div>
            <Scroll>
            <div className={classes.rootWrapper}>
                {activePatientsOrders && catalog
                    ? activePatientsOrders.length === 0
                        ? <div className={classes.loading}>Замовлені дослідження відсутні.</div>
                        : activePatientsOrders.sort((a,b) => b.id - a.id).map(order => (<OrderCard key={order.id} order={order}/>))
                    : <div className={classes.loading}>
                        Завантаження даних, будь ласка, зачекайте.
                        <Loading/>
                    </div>}
            </div>
            </Scroll>
            <Current/>
        </div>
    );
};

export default OrdersHistory
