import {
    ADD_ASSAY_TIME,
    ADD_ASSAY_TIME_LINE,
    ADD_ASSAY_TO_ORDER_LIST,
    ADD_ORDER_NOTE,
    ADD_SELECTED_PUNKT,
    CATALOG_TABLE_ID,
    CLEAR_ORDERED_ASSAYS,
    CLEAR_TAKES_FROM_BASKET,
    GO_TO_COLLECTION_POINTS,
    REMOVE_ASSAY_FROM_ORDER_LIST,
    SET_ADDITIONAL_DATA,
    SET_BASKET,
    SET_COMPLEX_RESEARCH_TRANSITION,
    SET_DOCTOR_ORDERED_ASSAYS,
    SET_ORDER_ID,
    SET_PROMO_CODE,
    SET_REF_FOR_PATIENT_TABS,
    SET_TOGGLE_PERSONAL_DATA,
    SET_TOGGLE_PREPARATION_RULES,
    SET_TOGGLE_TAKE_IN_ORDER,
    SET_TOGGLE_USE_ACTION,
    SET_TURN_BACK,
} from '../actions/actionsTypes';
import moment from 'moment';
import 'moment/locale/uk';

moment.locale('uk');

export const orderInitialState = {
  orderingList: [],
  amountOfItems: 0,
  totalPrice: 0,
  selectedPunkt: null,
  time: null,
  timeLine: null,
  note: '',
  id: '',
  turnBack: false,
  selectedComplex: null,
  orderId: null,
  goToCollectionPoints: false,
  doctorOrderedAssays: null,
  promoCode: null,
  additionalData: {
    odm: moment().format('yyyy-MM-DD'),
    menopause: 'Не вказано',
    fetus: 0,
    weekP: 0,
    rh_blood: 'Не вказано',
    urina: 0,
    localiz: '',
    observ: 'Не вказано',
    observDate: moment().format('yyyy-MM-DD'),
    observAddress: '',
    observPhone: '',
    lastname: '',
    firstname: '',
    formFilling: {
      odm: false,
      fetus: false,
      rh_blood: false,
      urina: false,
      localiz: false,
      observ: false,
      translate: false,
      weight: false,
    }
  },
  preparationRules: false,
  personalData: false,
  takeInOrder: false,
  patientTabsRef: null,
  useAction: false,
};

export default function orderAssaysReducer(state = orderInitialState, { type, payload }) {
  switch (type) {
    case ADD_ASSAY_TO_ORDER_LIST:
      return {
        ...state,
        totalPrice: (+state.totalPrice + +payload.assayPrice).toFixed(2),
        orderingList: payload.orderingList,
        amountOfItems: state.amountOfItems + 1,
      };
    case REMOVE_ASSAY_FROM_ORDER_LIST:
      return {
        ...state,
        totalPrice: +(+state.totalPrice - +payload.assayPrice).toFixed(2),
        orderingList: payload.orderingList,
        amountOfItems: state.amountOfItems - 1,
      };
    case CLEAR_ORDERED_ASSAYS:
      return {
        ...state,
        orderingList: [],
        totalPrice: 0,
        amountOfItems: 0,
      };
    case ADD_SELECTED_PUNKT:
      return {
        ...state,
        selectedPunkt: payload.newSelectedPunkt,
      };
    case ADD_ASSAY_TIME:
      return {
        ...state,
        time: payload.time,
        timeLine: null,
      };
    case  ADD_ASSAY_TIME_LINE:
      return {
        ...state,
        timeLine: payload.timeLine,
        time: null,
      };
    case ADD_ORDER_NOTE:
      return {
        ...state,
        note: payload.note,
      };
    case CATALOG_TABLE_ID:
      return {
        ...state,
        id: payload.id,
      };
    case SET_TURN_BACK:
      return {
        ...state,
        turnBack: payload.value,
      };
    case SET_COMPLEX_RESEARCH_TRANSITION:
      return {
        ...state,
        selectedComplex: payload.complex,
      };
    case SET_ORDER_ID:
      return {
        ...state,
        orderId: payload.orderId,
      };
    case CLEAR_TAKES_FROM_BASKET:
      return {
        ...state,
        orderingList: payload.newOrderingList,
        amountOfItems: payload.newOrderingList.length,
        totalPrice: (state.totalPrice - payload.takesPrice).toFixed(2),
      };
    case GO_TO_COLLECTION_POINTS:
      return {
        ...state,
        goToCollectionPoints: payload.goToCollectionPoints,
      };
    case SET_DOCTOR_ORDERED_ASSAYS:
      return {
        ...state,
        doctorOrderedAssays: payload.orderedAssays,
      };
    case SET_PROMO_CODE:
      return {
        ...state,
        promoCode: payload.value,
      };
    case SET_ADDITIONAL_DATA:
      return {
        ...state,
        additionalData: payload,
      };
    case SET_BASKET:
      return {
        ...state,
        orderingList: payload.orderingList,
        amountOfItems: payload.amountOfItems,
        totalPrice: payload.totalPrice,
        promoCode: payload.promoCode,
      };
    case SET_TOGGLE_PREPARATION_RULES:
      return {
        ...state,
        preparationRules: !state.preparationRules,
      };
    case SET_TOGGLE_PERSONAL_DATA:
      return {
        ...state,
        personalData: !state.personalData,
      };
    case SET_TOGGLE_TAKE_IN_ORDER:
      return {
        ...state,
        takeInOrder: !state.takeInOrder,
      };
    case SET_REF_FOR_PATIENT_TABS:
      return {
        ...state,
        patientTabsRef: payload,
      };
    case SET_TOGGLE_USE_ACTION:
      return {
        ...state,
        useAction: !state.useAction,
      };
    default:
      return state;
  }
};
