import React, {useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import {getDoctorOrderedAssayPdf, getDoctorOrderSchedule} from "@cabinet-packages/api/api";
import {addAssayToOrderList, getCatalog, setDoctorOrderedAssays} from "@cabinet-packages/redux";
import {Loading, Scroll} from "../components";
import {Avatar, Card, CardActions, CardContent, CardHeader} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    innerWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    dialogErrorText :{
        color: theme.palette.error.light,
        maxWidth: '284px',
        textAlign: 'center',
        alignSelf: 'center',
    },
    orderedAssaysWrapper: {
        width: '100%',
        height: 'auto',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridGap: 8,
        justifyItems: 'center',
        padding: '8px 0px',

        '@media (max-width: 1200px)': {
            gridTemplateColumns: '1fr 1fr',
        },
        '@media (max-width: 640px)': {
            gridTemplateColumns: '1fr',
        },
    },
    cardRoot: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    cardActions: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 'auto',
    },
    buttonPdfDownload: {
        marginTop: '4px',
        width: '100%',
    },
    phoneConfirmationButton: {
        width: '100%',
    },
}));

const DoctorOrderedAssays = ({}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { activePatient } = useSelector(state => state.patients);
    const { doctorOrderedAssays } = useSelector(state => state.orderAssays);
    const { catalog, complexResearch, actions, takeId }  = useSelector(state => state.assaysCatalog);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingPdf, setLoadingPdf] = useState(false);
    const createAssaysData = (curAssay) => [...catalog, ...complexResearch, ...actions]
        .find(item => +item.id === +curAssay.idPacket && +item.idConf === +curAssay.idConf);

    const handleSetDoctorOrders = async (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        setLoading(true);

        if (error.length > 0) setError(false);

        try {
            const result = await getDoctorOrderSchedule(activePatient?.id)
                .catch(onerror => setError("Вибачте, сталась помилка при завантаженні."));
            dispatch(setDoctorOrderedAssays(result));
        } catch (e) {
            console.error(e)
        }
        setLoading(false);
    };

    const handleSendSameOrder = (e, assay) => {
        e.stopPropagation();
        e.preventDefault();

        assay.services.forEach(item => {
            const findAssay = createAssaysData(item);
            if (findAssay && findAssay.idParent !== takeId) {
                dispatch(addAssayToOrderList(
                    findAssay.id,
                    findAssay.discount
                        ? findAssay.price - findAssay.price * findAssay.discount / 100
                        : findAssay.price,
                    ()=>{},
                    findAssay.type,
                ));
            }
        });
        history.push('/order-manager');
    };

    const handleDownloadPdf = async (e, assay) => {
        e.stopPropagation();
        e.preventDefault();

        setLoadingPdf(prevState => ({...prevState, [assay.id]: true}));
        if (error.length > 0) setError('');
        try {
            const todayDate = new Date();
            const createAssays = (ids) => ids.map(currId => (
                [...catalog, ...complexResearch, ...actions]
                    .find(currAssay => +currAssay.id === +currId.idPacket && +currAssay.idConf === +currId.idConf)));
            const res = await getDoctorOrderedAssayPdf(
                assay.fullname,
                assay.idClient,
                assay.id,
                activePatient.id,
                todayDate.toISOString().slice(0, 10),
                createAssays(assay.services).reduce((acu, cur) => {
                    return acu + cur.price;
                }, 0),
                +assay.num,
                createAssays(assay.services).map(item => ({
                    idPacket: item.id,
                    idConf: item.idConf,
                    price: item.price,
                    packetName: item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join(''),
                    priceDiscount: item.discount ? item.price - item.price * item.discount / 100 : item.price,
                    discount: item.discount ? item.discount : 0,
                    amount: 1,
                    debt: 0,
                    durationDay: item.duration_day ? item.duration_day : 1,
                }))
            );
            const url = window.URL.createObjectURL(new Blob([res], {type: 'application/pdf'}));
            const link = document.createElement('a');
            link.href = url;
            link.download = `${assay.fullname}.pdf`;
            link.click();
            link.remove();
        } catch (e) {
            setError("Вибачте, сталась помилка при завантаженні PDF.")
        }

        setLoadingPdf(prevState => ({...prevState, [assay.id]: false}));
    };

    useEffect(() => {
        if (!doctorOrderedAssays) handleSetDoctorOrders();
        if (!catalog) dispatch(getCatalog());
    }, [doctorOrderedAssays, catalog, dispatch])

    return (<div className={classes.root}>
        <div className={classes.innerWrapper}>
            {error.length !== 0 && <h5 className={classes.dialogErrorText}>{error}</h5>}
            {loading
                ? <Loading/>
                : <Scroll>
                    <div className={classes.orderedAssaysWrapper}>
                        {doctorOrderedAssays && doctorOrderedAssays.map(assay => {
                            const fullname = (assay?.fullname || ' ')?.split(' ');

                            return (<Card className={classes.cardRoot} key={assay.id} raised>
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label="recipe" className={classes.avatar}>
                                            {fullname[0][0] + fullname[1][0]}
                                        </Avatar>
                                    }
                                    title={`${assay.fullname}`}
                                    subheader={`ID: ${assay?.id}`}
                                />
                                <CardContent>
                                    <h5>Замовлені дослідження:</h5>
                                    {catalog && complexResearch && actions
                                        ? assay.services.map((item, idx) => {
                                            const orderedAssay = createAssaysData(item);

                                            return (orderedAssay
                                                ? <p
                                                    key={item.idPacket}
                                                >
                                                        <strong>{idx+1}.</strong> {orderedAssay.name
                                                        ? orderedAssay.name
                                                        : orderedAssay.testNames
                                                            .split(',')[0]
                                                            .split('\\\\')
                                                            .join('')}
                                                </p>
                                                : <p key={item.idPacket}/>
                                            )})
                                        : <Loading/>
                                    }
                                </CardContent>
                                <CardActions disableSpacing className={classes.cardActions}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className={classes.phoneConfirmationButton}
                                        onClick={e => handleSendSameOrder(e, assay)}
                                    >
                                        Повторити призначення
                                    </Button>
                                    {loadingPdf[assay.id]
                                        ? <Loading/>
                                        : <Button
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            className={classes.buttonPdfDownload}
                                            onClick={e => handleDownloadPdf(e, assay)}
                                        >
                                            Скачати PDF бланк замовлення
                                        </Button>}
                                </CardActions>
                            </Card>)})
                        }
                    </div>
            </Scroll>}
        </div>
    </div>);
};

export default DoctorOrderedAssays;
