import React, {useEffect} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,} from '@material-ui/core';

function TextAlertDialogCalculator({
                             onConfirm = () => null,
                             handleClose,
                             text = 'Ви впевнені, що хочете це зробити?',
                         }) {
    const handleConfirm = () => {
        onConfirm();
        handleClose();
    };

    useEffect(() => {
        setTimeout(handleClose, 3000);
    }, []);

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle>
                Попередження
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} color="primary" autoFocus>
                    Зрозуміло
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default TextAlertDialogCalculator;
