import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";


const ButtonWithLoader = (props) => {
    return (
        <Button
            {...{...props, loading: props.loading.toString()}}
            startIcon={props.loading && <CircularProgress size={24}/>}
            disabled={props.loading || props.disabled}

        />
    );
};

export default ButtonWithLoader;