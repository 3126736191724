import React, {createContext, Fragment, useContext, useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
    addSelectedPunkt,
    getPunkts,
    getSchedule,
    loginClient,
    setAssayTime,
    setTurnBack
} from "@cabinet-packages/redux";
import {findExtremums, getTableHours, isEnabledHour, isLogin} from "@cabinet-packages/helpers";
import deLocale from "date-fns/locale/uk";
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {AssaySchedulerWeek, Loading} from '@cabinet-packages/components';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {createFilterOptions} from "@material-ui/lab";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router";
import CustomDialog from "../components/Dialogs/CustomDialog";
import ConfirmDialog from "../components/Dialogs/ConfirmDialog";

const useStyles = makeStyles({
    root: {
        width: '100%',
        border: 'none',
    },
    tableAssayScheduler: {
        width: '100%',
        borderCollapse: 'collapse',
        minWidth: '900px',
        '@media (min-width: 300px) and (max-width:991px)': {
            overflowX: 'auto',
        },
    },
    containerScheduler: {
        overflow: 'auto',
        position: 'relative',
        width: '100%',
        height: '100%',
    },

    containerTable: {
        position: 'absolute',
        width: '95%',
        padding: '5px',
    },
    detailsHeader: {
        color: '#3c4043',
        fontWeight: '400',
        position: 'sticky',
        top: '0',
        zIndex: '5',
        background: 'white',
        borderBottom: '1px solid',
    },
    horseAssay: {
        color: '#70757a',
        fontSize: '10px',
        height: '48px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    daysWeek: {
        fontSize: '25px',
        height: '46px',
        marginBottom: '0.7rem',
        marginTop: '0.5rem',
    },
    horseAssaySpan: {
        left: '20px',
        position: 'relative',
        '&::before': {
            content: '""',
            display: 'block',
            background: '#DADCE0',
            width: '80%',
            height: '1px',
            position: 'absolute',
            left: '1.7rem',
            bottom: '-1px',
        }
    },
    punks: {
        paddingLeft: '18px',
    },
    details: {
        border: '1px solid rgba(0, 0, 0, 0.7)',
        width: '10.44444%',
        textAlign: 'center',
        height: '30px',
        padding: '2px',
        transition: '.3s',
        '&:not(:first-child):hover': {
            border: '1px solid rgba(0, 0, 0, 0.5)',
            cursor: 'pointer',
            background: 'rgba(0, 0, 0, 0.1)',
        },
        '&:first-child': {
            position: 'sticky',
            left: '0',
            zIndex: '4',
            background: 'white',
        },
    },
    detailsDisabled: {
        border: '1px solid rgba(0, 0, 0, 0.7)',
        width: '10.44444%',
        textAlign: 'center',
        height: '30px',
        padding: '2px',
        transition: '.3s',
        background: 'grey',
        cursor: 'default',
    },
    detailsFirst: {
        border: 'none',
        width: '5.44444%',
        background: 'white',
        textAlign: 'center',
    },
    formControl: {
        boxSizing: 'content-box',
        width: '100%',
        maxWidth: '250px',
        margin: '10px 16px',
        '@media (max-width: 900px)': {
            margin: '5px auto',
        },
    },
    autoComplete: {
        boxSizing: 'content-box',
        width: '100%',
        maxWidth: '250px',
        margin: '5px 16px',
        paddingTop: '5px',
        '@media (max-width: 900px)': {
            margin: '5px auto',
        },
    },
    formControlWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        '@media (max-width: 900px)': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        }
    },
    formButton: {
        margin: '10px',
        width: 288,
        '@media (max-width: 900px)': {
            margin: '5px auto',
        },
    },
    lineButton: {
        margin: '10px',
    }
});

const CellContext = createContext(null);

function AssaySchedulerDay({isComponentInShop}) {
    const ref = useRef(null);
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [cellsState, setCellsState] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [arrayLaboratory, setArrayLaboratory] = useState(null);
    const [selected, setSelected] = useState(null);
    const [open, setOpen] = useState(false);
    const {selectedPunkt, time, turnBack, orderingList} = useSelector(store => store.orderAssays);
    const {catalog, complexResearch, actions} = useSelector(state => state.assaysCatalog);
    const {activePatient, stage} = useSelector(store => store.patients);
    const {punkts} = useSelector(store => store.collectionPoints);
    const {schedule} = useSelector(state => state.scheduleOfOrders);
    const {themeMaterialUI} = useSelector(store => store.theme);
    const [minMaxTime, setMinMaxTime] = useState(null);
    const [range, setRange] = useState(null);
    const [handleTransition, setHandleTransition] = useState(false);

    const isOrderedAssaysHasPunkts = () => {
        const orderedAssays = orderingList.map(currId =>
            [...catalog, ...complexResearch, ...actions].find(currAssay => +currAssay.id === +currId.id && currAssay.type === currId.type)
        ).reduce((acu, cur) => {
            if (cur.idPunkts && schedule) {
                return [...acu, ...cur.idPunkts.map(idPunkt => punkts.find(punkt => +punkt.idPunkt === +idPunkt)).reduce((acu, cur) => {
                    return [...acu, {
                        ...cur,
                        schedule: schedule.filter(assay => assay.idPunkt === cur.idPunkt),
                    }]
                }, [])];
            }
            return acu;
        }, []);

        return orderedAssays.length ? orderedAssays : false;
    };

    const filteredOptions = createFilterOptions({
        limit: 20
    });

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleDateChange = (date) => {
        setCellsState(false);
        setArrayLaboratory(null);
        setSelectedDate(date);
    };
    const handleTransitionToConfirmOrderPage = () => {
        if (activePatient && isLogin()) {
            dispatch(setAssayTime(cellsState));
            dispatch(addSelectedPunkt(cellsState.punkt));
        }
        isComponentInShop
            ? activePatient && isLogin() ? history.push('/shop-confirm-order') : dispatch(loginClient(themeMaterialUI))
            : history.push('/confirm-order');
        if (turnBack) dispatch(setTurnBack(false));
    };

    useEffect(() => {
        if (selectedPunkt && !selected) setSelected(selectedPunkt);
        if (!punkts) dispatch(getPunkts());
        if (!schedule) dispatch(getSchedule());
        if (arrayLaboratory && !minMaxTime) setMinMaxTime(findExtremums(arrayLaboratory));
        if (!range && minMaxTime) setRange(getTableHours(minMaxTime.min, minMaxTime.max))
        if (stage === 6 && cellsState) {
            history.push('/shop-confirm-order');
            dispatch(setAssayTime(cellsState));
            dispatch(addSelectedPunkt(cellsState.punkt));
        }

        if (punkts && punkts.length > 0 && schedule && !arrayLaboratory) {
            setArrayLaboratory(punkts.filter(punkt => punkt.workingHours.length > 0).reduce((acu, cur) => {
                return [...acu, {
                    ...cur,
                    schedule: schedule.filter(assay => assay.idPunkt === cur.idPunkt),
                }]
            }, []));
        }
    }, [history, punkts, schedule, minMaxTime, arrayLaboratory, range, selectedPunkt, selected, dispatch, stage, cellsState]);

    useEffect(() => {
        if (handleTransition && time) {
            CustomDialog(ConfirmDialog,
                {text: `Ви обрали запис на ${time} за адресою: ${cellsState.punkt.address}. Підтвердити вибраний час?`,
                    onConfirm:  () => {
                        handleTransitionToConfirmOrderPage();
                    }}, false,
                themeMaterialUI);
            setHandleTransition(false);
        }
    }, [handleTransition, time]);

    function Cell({enabled, punkt, time, selectedDate, useRef}) {
        const dispatch = useDispatch();
        const [cellProps, setCellProps] = useContext(CellContext);
        const classes = useStyles();
        const [clicked, setClicked] = useState(null);
        const {takeId} = useSelector(state => state.assaysCatalog);

        const countOfPatient = punkt.schedule.reduce((acu, cur) => {
            if (cur && cur.dt) {
                const scheduledDate = cur.dt.split('.');
                let correctDate = new Date([scheduledDate[2], scheduledDate[1], scheduledDate[0]].join('-')).toISOString().slice(0, 10);
                if (selectedDate.toISOString().slice(0, 10) === correctDate) {
                    const timeToFloat = time.split('-').map(curTime => parseFloat(curTime.split(':').join('.')));
                    const curAssayTime = parseFloat(scheduledDate[2].split(' ')[1].split(':').join('.'));

                    if (curAssayTime > timeToFloat[0] && curAssayTime < timeToFloat[1]) return acu + 1;
                }
            }
            return acu;
        }, 0);

        const handleSendHour = (count) => {
            if (count > 2) return;
            if (cellProps.punkt && cellProps.punkt.idPunkt === punkt.idPunkt && cellProps.time === time) {
                setCellProps(false);
                return;
            }

            if (enabled) {
                const [from, to] = time.split('-');

                setCellProps((prev) => ({...prev, from, to, time, date: selectedDate.toISOString().slice(0, 10), punkt}));
                dispatch(setAssayTime({from, to, time, date: selectedDate.toISOString().slice(0, 10)}));
                useRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }

            setHandleTransition(true);
        };

        useEffect(()=>{
            if (cellProps && cellProps.punkt && +cellProps.punkt.idPunkt === +punkt.idPunkt && cellProps.time === time) {
                setClicked(takeId === takeId ? '#2CB8AC' : '#0077c899')

            } else {
                setClicked('')
            }

        }, [cellProps, clicked, selectedDate, dispatch, punkt, time]);

        return (
            <TableCell
                className={enabled && countOfPatient < 3 ? classes.details : classes.detailsDisabled}
                style={{background: clicked}}
                onClick={(event) => handleSendHour(countOfPatient, event)}
            />
        );
    }

    if (!punkts) {
        return <Loading/>
    }

    function filterWeekends(date) {
        return date.getDay() === 0;
    }

    return (
        <div className={classes.root}>
            <div className={classes.containerScheduler}>
                <div className={classes.formControlWrapper}>
                    <Autocomplete
                        className={classes.autoComplete}
                        id="combo-box"
                        open={open}
                        onOpen={handleOpen}
                        onClose={handleClose}
                        value={selected}
                        freeSolo
                        options={punkts
                            ? isOrderedAssaysHasPunkts()
                                ? isOrderedAssaysHasPunkts()
                                : punkts
                            : []}
                        filterOptions={filteredOptions}
                        getOptionLabel={(option) => option.address}
                        onChange={(event, value) => {
                            dispatch(addSelectedPunkt(value));
                            setSelected(value);
                            if (time) dispatch(setAssayTime(null))
                            setCellsState('')
                        }}
                        style={{width: 300}}
                        renderInput={(params) => <TextField
                            {...params} label="Введіть назву пункту"
                            variant="outlined"/>
                        }
                    />
                    {!selected &&
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                            <KeyboardDatePicker
                                label="Календар"
                                value={selectedDate}
                                onChange={handleDateChange}
                                className={classes.formControl}
                                shouldDisableDate={filterWeekends}
                                disablePast={true}
                            />
                        </MuiPickersUtilsProvider>
                    }
                    {!selected && <Fragment>
                        <Button
                            className={classes.formButton}
                            color="primary"
                            variant="outlined"
                            onClick={handleTransitionToConfirmOrderPage}
                            disabled={!cellsState}
                            ref={ref}
                        >
                            Підтвердити вибраний час
                        </Button>
                    </Fragment>}
                </div>
                {!selected
                        ? <div className={classes.containerTable}>
                    <table className={classes.tableAssayScheduler}>
                        <TableHead>
                            <TableRow>
                                {schedule
                                    ? <TableCell className={classes.detailsFirst}>Година</TableCell>
                                    : <TableCell className={classes.detailsFirst}><Loading/></TableCell>}
                            {isOrderedAssaysHasPunkts()
                                ? isOrderedAssaysHasPunkts().filter(punkt => punkt.workingHours.length > 0).map((punkt) => (
                                    <TableCell className={classes.detailsHeader} key={punkt.idPunkt}>
                                        <div className={classes.punks}> {punkt.address}</div>
                                    </TableCell>))
                                : arrayLaboratory && arrayLaboratory.slice(0, 20).map((punkt) => (
                                    <TableCell className={classes.detailsHeader} key={punkt.idPunkt}>
                                        <div className={classes.punks}> {punkt.address}</div>
                                    </TableCell>))}
                        </TableRow>
                        </TableHead>
                        <tbody>
                            <CellContext.Provider value={[cellsState, setCellsState]}>
                                {range && range.map((el, i) => {
                                    return (
                                        <TableRow key={i}>{
                                            <Fragment>
                                                <TableCell className={classes.details}>{el} </TableCell>
                                                {isOrderedAssaysHasPunkts()
                                                    ? isOrderedAssaysHasPunkts().filter(punkt => punkt.workingHours.length > 0).reduce((acu, cur) => {
                                                        return [...acu, {
                                                            ...cur,
                                                            schedule: schedule.filter(assay => assay.idPunkt === cur.idPunkt),
                                                        }]
                                                    },[]).slice(0, 7).map((punkt) => {
                                                        let n = selectedDate.getDay();
                                                        const enabled = isEnabledHour(punkt, n, el);
                                                        return (
                                                            <Cell key={punkt.idPunkt} enabled={enabled} punkt={punkt}
                                                                  time={el} selectedDate={selectedDate} useRef={ref}/>)
                                                    })
                                                    : arrayLaboratory && arrayLaboratory.slice(0, 20).map((punkt) => {
                                                    let n = selectedDate.getDay();
                                                    const enabled = isEnabledHour(punkt, n, el);
                                                    return (<Cell key={punkt.idPunkt} enabled={enabled} punkt={punkt}
                                                                  time={el} selectedDate={selectedDate} useRef={ref}/>)
                                                })
                                                }
                                            </Fragment>
                                        }</TableRow>
                                    )
                                })}
                            </CellContext.Provider>
                        </tbody>
                    </table>
                </div>
            : <AssaySchedulerWeek isComponentInShop={isComponentInShop} themeMaterialUI={themeMaterialUI}/>}
            </div>
        </div>
    );
}

export default AssaySchedulerDay;
