import React from 'react';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

export default function NotificationIcon({ type }) {
  switch (type) {
    case 'a':
      return <WhatshotIcon/>;
    case 'b':
      return <AccessibilityIcon/>;
    case 'c':
      return <AllInclusiveIcon/>;
    case 'd':
      return <AccessAlarmIcon/>;
    default:
      return <CheckBoxOutlineBlankIcon/>;
  }
}
