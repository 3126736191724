import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 'max-content',
        minHeight: 'calc(100vh)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',

        '@media (max-width: 1180px)': {
            minHeight: 'calc(100vh)',
        }
    },
    activeComponentWrapper: {
        width: '100%',
        height: 'calc(100vh - 228px)',
        maxWidth: '1205px',
        overflow: 'auto',
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',

        '&::-webkit-scrollbar': {
            display: 'none',
        },

        '@media (max-width: 1180px)': {
            height: 'calc(100vh - 254px)',
        },
    },
    headerWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // '@media (max-width: 900px)': {
        //     background: '#ffffff',
        //     position: 'sticky',
        //     zIndex: '100',
        //     top: 0,
        // }
    },
    stepperWrapper: {
        width: '100%',
        maxWidth: '1180px',
        height: 61,
    },
    logoHeaderWrapper: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
        maxWidth: '1180px',
        padding: '8px',
        margin: '0 auto',
        height: '66px',

        '@media (max-width: 1180px)': {
            height: '88px',
        }
    },
    logoHeaderImg: {
        height: '50px',

        '@media (max-width: 1180px)': {
            height: '40px',
        }
    },
    logoHeaderText: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '14px',
        marginLeft: '16px',
        padding: '0px 8px',

        '@media (max-width: 1180px)': {
            fontSize: '12px',
            marginLeft: '8px',
        }
    },
    logoHeaderLink: {
        textDecoration: 'none',
        color: 'rgb(233, 75, 67)',
    },
    stepper: {
        padding: '6px 0px',
        background: 'transparent',

    },
    step: {
        flexDirection: 'column-reverse',
        '& span': {
            flexDirection: 'column-reverse'
        },
    },
    mediaLabel: {
        '@media (max-width: 1180px)': {
            display: 'none'
        }
    },
    stepIconRoot: {
        color: 'white',
        borderRadius: '50%',
        border: '2px solid #F0F0F0',

        '& text': {
            fontWeight: 800,
            fill: theme.palette.grey.price,
        },
    },
    stepIconActive: {
        color: `${theme.palette.secondary.main} !important`,
        borderRadius: '50%',
        border: 'none',

        '& text': {
            fontWeight: 800,
            fill: theme.palette.grey.price,
        },
    },
    stepIconCompleted: {
        borderRadius: '50%',
        border: 'none',

        '& text': {
            fontWeight: 800,
            fill: 'white',
        },
    },
    connectorLine: {
        borderTopWidth: '2px',
        position: 'absolute',
        bottom: '10px',
        left: '-16px',
        right: '-16px',
        borderRadius: '4px',
        borderColor: theme.palette.grey.border,

        '@media (max-width: 1180px)': {
            bottom: '20px',
            left: '-6px',
            right: '-6px',
        }
    },
    connectorCompleted: {
        position: 'relative',
        height: '48px',

        '& span': {
            borderColor: theme.palette.primary.main
        },
    },
    connectorLineRoot: {
        position: 'relative',
        height: '48px',
    },
    connectorLineActive: {
        position: 'relative',
        height: '48px',

        '& span': {
            background: 'linear-gradient(90deg, rgba(255,193,1,1) 0%, rgba(240,240,240,1) 80%, rgba(240,240,240,1) 100%)',
            height: '2px',
            border: 'none',
            borderRadius: '4px'
        },
    },
    actionsWrapper: {
        position: 'sticky',
        width: '100%',
        bottom: 0,
        left: 0,
        height: '101px',
        backgroundColor: theme.palette.green.dark,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 'auto',

        '@media (max-height: 400px)': {
            display: 'none',
        },
        '@media (max-width: 1180px)': {
            height: '105px',
        }
    },
    actions: {
        width: '100%',
        maxWidth: '1180px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        '@media (max-width: 1180px)': {
            padding: '0px 30px',
            height: '55%',
            backgroundColor: theme.palette.green.actions,
        },
        '@media (max-width: 360px)': {
            padding: '0px 20px',
            height: '55%',
            backgroundColor: theme.palette.green.actions,
        }
    },
    orderDataWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',

        '@media (max-width: 1180px)': {
            display: 'none'
        },
    },
    orderDataWrapperMobile: {
        display: 'none',

        '@media (max-width: 1180px)': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            width: '100%',
            backgroundColor: theme.palette.green.dark,
            paddingBottom: theme.spacing(1),
            paddingTop: theme.spacing(1),
            height: '45%',
        },
    },
    actionsAmountPriceWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: 'max-content',
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    actionsNextTextWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    actionsNextText: {
        fontSize: '14px',
        color: theme.palette.grey.main,
        marginTop: 'auto'
    },
    actionsNumberWrapper: {
        color: 'white',
        fontSize: '30px',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),

        '@media (max-width: 1180px)': {
            fontSize: '25px',
        }
    },
    actionsTextWrapper: {
        color: theme.palette.secondary.main,
        fontSize: '14px',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',

        '@media (max-width: 1180px)': {
            fontSize: '12px',
        }
    },
    snackbar: {
        padding: 0,
        background: 'transparent !important',
        borderRadius: '10px',

        '@media (min-width: 1180px)': {
            padding: '0 !important',
            background: 'transparent !important',
            borderRadius: '10px',
        },

        '@media (max-width: 1180px)': {
            width: 'max-content',
            boxShadow: 'none',
        }
    },
    snackbarWrapper: {
        padding: 0,
        background: 'transparent !important',
        borderRadius: '10px',
        marginBottom: '80px',

        '@media (min-width: 1180px)': {
            padding: 0,
            background: 'transparent !important',
            borderRadius: '10px',
            marginBottom: '80px',
        },

        '@media (max-width: 1180px)': {
            width: 'max-content',
            boxShadow: 'none',
            marginBottom: '105px',
            left: 'auto !important',
            right: '8px !important',
        }
    },
    snackbarContentWrapper: {
        width: '340px',
        height: 'max-content',
        background: 'white',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        boxShadow: '2px 0px 10px 0px #70707088',

        '@media (max-width: 1180px)': {
            width: '200px',
        }
    },
    snackbarContentHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        height: '50px',
        width: '100%',
        fontSize: '20px',
        fontWeight: 600,
        color: 'white',
        background: theme.palette.primary.dark,
        borderRadius: '10px 10px 0px 0px',

        '@media (max-width: 1180px)': {
            fontSize: '14px',
        }
    },
    snackbarContentText: {
        width: '100%',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.primary.dark,
        lineHeight: 1.43,

        '@media (max-width: 1180px)': {
            fontSize: '14px',
        }
    },
    explanationItemWrapperCheckbox: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(3),

        '@media (max-width: 1180px)': {
            gridColumn: '1 / -1',
        }
    },
    explanationItem: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',

        '@media (max-width: 1180px)': {
            flexDirection: 'column-reverse',
            justifyContent: 'flex-start',
        }
    },
    explanationMark: {
        width: '100px',
        height: '30px',
        borderRadius: '15px',

        '@media (max-width: 1180px)': {
            margin: '0 auto',
            width: '90px',
        }
    },
    explanationText: {
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.grey.price,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        marginLeft: theme.spacing(2.5),

        '@media (max-width: 1180px)': {
            margin: '0 auto',
            marginBottom: theme.spacing(1),
            fontSize: '14px',
        }
    },
    formControl: {
        alignItems: 'flex-start',
        marginTop: '-8px',
    },
    checkboxLabel: {
        paddingTop: '8px',
        fontSize: '14px',
        color: theme.palette.grey.price,

        '& span': {
            fontSize: '12px',
            color: theme.palette.green.dark,
        },
    },
}));

export default useStyles;
