import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {getCatalog, getPunkts, setCabinetActiveTab, setSelectedAction} from "@cabinet-packages/redux";
import {useHistory, useLocation} from "react-router";
import {Button} from "@material-ui/core";
import {AddRemoveIconButtons, ExpansionPanel, Loading, Scroll,} from "@cabinet-packages/components";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme)=> ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    rootWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        maxHeight: '100%',

        '@media (max-width: 1040px)': {
            flexDirection: 'column',
        }
    },
    orderButtonContainer: {
        padding: '5px 0',
        position: 'sticky',
        bottom: 0,
        left: 0,
        background: 'white',

        '@media (max-width: 1040px)': {
            padding: '5px',
        },
    },
    orderButton: {
        width: '100%',
    },
    expansionPanel: {
        padding: '8px',
    },
    details: {
        padding: '8px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '@media (max-width: 1040px)': {
            flexDirection: 'column',
        },
    },
    detailsImgWrapper: {
        padding: '8px',

        '@media (max-width: 1040px)': {
            padding: '0px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        }
    },
    detailsImg: {
        border: '1px solid rgba(0,0,0,0.1)',
        // minWidth: '300px',
        maxWidth: '300px',
        // maxHeight: 200,
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
        backgroundSize: 'contain',
        '@media(max-width: 900px)': {
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
            position: 'relative',
            minWidth: '100%',
            maxWidth: '100%',
            backgroundSize: 'contain',
        }
    },
    detailsText: {
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'right',

        '@media (max-width: 1040px)': {
            display: 'none',
        },
    },
    detailsTextNearImg: {
        padding: '8px',
        display: 'none',
        flexDirection: 'column',
        justifyContent: 'space-between',

        '@media (max-width: 1040px)': {
            display: 'flex',
        },
    },
    detailsTextPriceContainer: {
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingTop: '8px',
    },
    detailsTextPrice: {
        alignSelf: 'center',
        color: theme.palette.error.light,
        paddingRight: '8px',
    },
}))

const ActionsDetails = ({isComponentInShop}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { selectedActionForView, catalog, actions, complexResearch, takeId, complexId } = useSelector(state => state.assaysCatalog);
    const { orderingList, amountOfItems } = useSelector(state => state.orderAssays);
    const [sendTag, setSendTag] = useState(false);
    const { punkts } = useSelector(store => store.collectionPoints);
    const location = useLocation();
    const history = useHistory();
    const locationActionID = [...location.pathname.split('/')].pop();
    const {imgUrl} = useSelector(store => store.theme);
    const hrefLocation = window.location.href;
    const productList = catalog && selectedActionForView && catalog.filter(assay =>selectedActionForView.idGrTest.includes(assay.id))

    const isIncludedInOrderingList = selectedActionForView && orderingList.find(assay => assay.id === selectedActionForView.id && assay.type === selectedActionForView.type);
    const actionInOrderList = orderingList.filter(actionID => actions.find(item => item.id === actionID.id && actionID.type === item.type));

    useEffect(() => {
        dispatch(setCabinetActiveTab("/patient-actions"))
    }, [])

    const handleTransitionToOrderManager = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if(isComponentInShop) history.push('/shop-order-manager')
        else {
            //dispatch(setActiveStep(1))
            // history.replace("https://shop.medis.com.ua/wizard");
            history.push('/order-manager')
        }
    };

    // useEffect(() => {
    //     if (selectedActionForView && !sendTag && window.dataLayer) {
    //         window.dataLayer.push({
    //             event: 'view_item',
    //             eventProps: {
    //                 items: [{
    //                     id: selectedActionForView.id,
    //                     name: selectedActionForView.name,
    //                     variant: selectedActionForView.type,
    //                     price: selectedActionForView.discount
    //                         ? selectedActionForView.price - selectedActionForView.price * selectedActionForView.discount / 100
    //                         : selectedActionForView.price,
    //                     quantity: 1,
    //                 }],
    //             },
    //             coupon: '',
    //         });
    //         setSendTag(true);
    //     }
    // }, [selectedActionForView, sendTag]);

    useEffect(() => {
        dispatch(getPunkts());

        if ((!selectedActionForView || +selectedActionForView?.id !== +locationActionID) && locationActionID && actions) {
            dispatch(setSelectedAction(actions.find(action => +action.id === +locationActionID)))
        }
    }, [dispatch, catalog, punkts, locationActionID, actions, selectedActionForView]);

    useEffect(() => {
        if (!catalog && takeId && complexId) dispatch(getCatalog());
    }, [catalog, dispatch, takeId, complexId]);

    console.log(productList)
    return (
        <div className={classes.root} key={locationActionID}>
            {!selectedActionForView
                ? <Loading/>
                : <Scroll><div className={classes.rootWrapper}>
                    <div className={classes.details}>
                        <div className={classes.detailsImgWrapper}>
                            <img
                                className={classes.detailsImg}
                                src={!!selectedActionForView.fileName
                                        ? `https://biokurs-billing.medclinic.in.ua//modules/bi_action/files/${selectedActionForView.fileName}`
                                        : imgUrl
                                }
                                alt="Action img."
                                 />
                        </div>
                        <div className={classes.detailsTextNearImg}>
                            {selectedActionForView.note && <div className="holovna" dangerouslySetInnerHTML={{__html: selectedActionForView.note}}/>}
                            <div className={classes.detailsTextPriceContainer}>
                                <p className={classes.detailsTextPrice}>{selectedActionForView.price} грн</p>
                                <div className={classes.addRemoveButton}>
                                    {selectedActionForView.discount === 0 && <AddRemoveIconButtons
                                        item={selectedActionForView}
                                        isIncludedInOrderingList={isIncludedInOrderingList}
                                        actionInOrderList={actionInOrderList}
                                        assayInfo={true}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.expansionPanel}>
                        <div className={classes.detailsText}>
                            {selectedActionForView.note && <div className="holovna" dangerouslySetInnerHTML={{__html: selectedActionForView.note}}/>}
                            <div className={classes.detailsTextPriceContainer}>
                                <p className={classes.detailsTextPrice}>{selectedActionForView.price} грн</p>
                                <div className={classes.addRemoveButton}>
                                    {selectedActionForView.discount === 0 &&<AddRemoveIconButtons
                                        item={selectedActionForView}
                                        isIncludedInOrderingList={isIncludedInOrderingList}
                                        actionInOrderList={actionInOrderList}
                                        assayInfo={true}
                                    />}
                                </div>
                            </div>
                        </div>
                        {productList && <ExpansionPanel title="Акційні дослідження:" defaultExpanded={!productList.find(i=>i?.opt?.includes('rolled'))}>
                            {productList.map(product =><div key={product.name}>
                                <a href={"/assay-info/"+product.id}>{product.name}</a>
                                {product.testNamesArr.length > 0 && product.testNamesArr.map(testName =>
                                        <p key={testName} style={{color: 'grey', marginLeft: '8px', fontSize: '12px'}}>
                                        {testName}
                                    </p>)}
                            </div>)}
                        </ExpansionPanel>}
                            {<ExpansionPanel
                                title="Адреси відділень, у яких діє акційна пропозиція:"
                                classNameDetails={classes.details}
                            >
                                <div>
                                    {punkts && selectedActionForView.idPunkts
                                        .map(idpunkt => punkts.find(punkt => +punkt.idPunkt === +idpunkt))
                                            .filter(item => item)
                                            .sort((a,b) => a.address.localeCompare(b.address))
                                            .map((punkt, idx) => (
                                                    <p key={punkt.idPunkt}>
                                                        {punkt.address}
                                                    </p>
                                            ))}
                                </div>
                            </ExpansionPanel>}
                    </div>
            </div></Scroll>}
            <div className={classes.orderButtonContainer}>
                <Button
                    className={classes.orderButton}
                    variant="outlined"
                    color="primary"
                    disabled={amountOfItems < 1}
                    onClick={handleTransitionToOrderManager}>
                    Перейти до замовлення
                </Button>
            </div>
            {selectedActionForView && selectedActionForView.name && <Helmet>
                <meta name="og:description" content={selectedActionForView.note}/>
                <meta property="og:url" content={hrefLocation}/>
                <meta name="og:image" content={'imgUrl'}/>
                {/*<meta property="og:site_name" content={takeId === 216 ? 'Medis' : 'Esculab'}/>*/}
                <meta property="og:type" content="article" />
                <meta property="og:title" content={selectedActionForView.name ? selectedActionForView.name : selectedActionForView.testNames.split(',')[0].split('\\\\').join('')} />
            </Helmet>}
        </div>
    );
};

export default ActionsDetails;
