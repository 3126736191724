import Cookies from 'js-cookie';
import useAuth from "esculab-delivery/src/pages/LoginDashboard/useAuth";

const isDoctor = localStorage.getItem("isDoctor");

export const accessKey = isDoctor === "true" ? 'accessKeyForDoctor' :'accessKey';

export const AccessKey = {
    get: () => Cookies.get(accessKey),
    set: (key) => Cookies.set(accessKey, key, {expires: 30}),
    remove: () => Cookies.remove(accessKey)
};

export const isLogin = () => {
    const accessKey = AccessKey.get();
    if (localStorage.getItem('unregisteredCabinet')) return false;
    if (accessKey === 'undefined') return false;
    if (typeof accessKey === 'undefined') return false;
    return !!accessKey;
};

export const handleAuthorization = (unauthorized) => {
    const accessKey = AccessKey.get();
    const activePatient = localStorage.getItem('activePatient');
    const activePatientPhone = localStorage.getItem('activePatientPhone');
    const isAdmin = localStorage.getItem('isAdmin');
    const unregisteredCabinet = localStorage.getItem('unregisteredCabinet');
    const uuid = localStorage.getItem('uuid');

    if (accessKey && activePatient && activePatientPhone && uuid && !unauthorized) {
        return true;
    } else {
        if (!isAdmin) {
            localStorage.removeItem('activePatientPhone');
            localStorage.removeItem('unregisteredCabinet');
        }
        AccessKey.remove();
        localStorage.removeItem('activePatient');
        localStorage.removeItem('uuid');
        return false;
    }
};
