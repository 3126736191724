import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    headingText: {
        fontSize: '20px',
        lineHeight: '26px',
        color: 'white',
        padding: theme.spacing(1.5),
        paddingLeft: theme.spacing(3)
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    fieldsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',

        '@media (max-width: 1180px)': {
            alignItems: 'center',
            width: '100%',
            maxWidth: '320px',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
    },
    dateGenderWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: 'flex-start',
        width: '50%',

        '@media (max-width: 1180px)': {
            width: '100%',
        },
    },
    genderGroup: {

    },
    actionContainerButton: {
        maxHeight: '70px',
        '@media (max-width: 900px)': {
            margin: '5px',
        },
    },
    error: {
        color: "red",
        textAlign: 'center'
    },
    snackbar: {
        padding: 0,
        background: 'transparent',
        borderRadius: '10px',

        '@media (max-width: 1180px)': {
            width: '200px',
            boxShadow: 'none',
        }
    },
    snackbarContentWrapper: {
        width: '340px',
        height: 'max-content',
        minHeight: '170px',
        background: 'white',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        boxShadow: '2px 0px 10px 0px #70707088',

        '@media (max-width: 1180px)': {
            width: '200px',
        }
    },
    snackbarContentHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        height: '50px',
        width: '100%',
        fontSize: '20px',
        fontWeight: 600,
        color: 'white',
        background: theme.palette.primary.dark,
        borderRadius: '10px 10px 0px 0px',

        '@media (max-width: 1180px)': {
            fontSize: '14px',
        }
    },
    snackbarContentText: {
        width: '100%',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        marginTop: theme.spacing(2.5),
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.grey.price,

        '@media (max-width: 1180px)': {
            fontSize: '12px',
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    newPatientWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        position: "relative",
        width: '100%',
        height: '100%'
    },
    catalogSwitchControl: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: theme.spacing(2),
        alignItems: 'center',

        '@media (max-width: 768px)': {
            padding: 0,
        },
    },
    catalogSwitchControlLabel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: theme.palette.grey.text,
        whiteSpace: 'nowrap',
        fontWeight: 600,

        '@media (max-width: 1180px)': {
            fontSize: '12px'
        }
    },
    newPatientFieldsGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(1),

        '@media (max-width: 900px)': {
            flexDirection: 'column',
        },
    },
    temporaryPatientInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    orderTermsSmallBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: theme.spacing(2),

        '@media (max-width: 1180px)': {
            width: '100%',
            alignItems: 'initial'
        }
    },
    phoneDescriptionText: {
        fontSize: '16px',
        color: theme.palette.grey.price,
        maxWidth: '340px',
        textAlign: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    newPatientField: {
        paddingRight: theme.spacing(1),
        width: '50%',

        '@media (max-width: 900px)': {
            width: '100%',
        },
    },
    newPatientBirthdayGender: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '260px',

        '@media (max-width: 900px)': {
            width: '100%',
            paddingTop: theme.spacing(1),
        },
    },
    newPatientBirthdayContainer: {
        paddingRight: theme.spacing(1),
        width: '150px',
        display: 'grid',
    },
    newPatientFormComtrol: {
        width: '46%',
        justifySelf: 'center',
        justifyContent: 'space-between',
    },
    newPatientActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        marginTop: theme.spacing(3)
    },
    rootAutocomplete: {
        border: '2px solid #F0F0F0',
        borderRadius: '5px',
        height: '42px',
        transition: 'all .4s',
        fontSize: '14px',
        width: '260px',

        '&&& $input': {
            transition: 'all .4s',
            paddingTop: 0,
            paddingBottom: 0,

            '@media (max-width: 1180px)': {
                paddingRight: '0px !important',
            }
        },
    },
    rootAutocompleteDate: {
        border: '2px solid #F0F0F0',
        borderRadius: '5px',
        height: '42px',
        transition: 'all .4s',
        fontSize: '14px',
        width: '150px',
        paddingRight: 0,

        '&&& $input': {
            transition: 'all .4s',
            paddingTop: 0,
            paddingBottom: 0,

            '@media (max-width: 1180px)': {
                paddingRight: '0px !important',
            }
        },
    },
    notchedOutline: {
        border: "none",
    },
    fieldLabel: {
        height: '16px',
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '12px',
        paddingLeft: theme.spacing(2),
        marginBottom: theme.spacing(0.5),
    },
    muiPhoneNumber: {
        width: '260px',
        height: '42px',
        fontSize: '14px',

        '& fieldset': {
            border: '2px solid #F0F0F0',
        },
        '& input': {
            borderWidth: '2px',
            paddingTop: theme.spacing(1.25),
            paddingBottom: theme.spacing(1.25),
            height: '22px',
        }
    },
    dialogAction: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '8px 24px',

        '@media (max-width: 1180px)': {
            padding: '8px',
        }
    },
    orderAndPatientsRoot: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        height: 'max-content'
    },
    orderDatePunktWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: 'max-content',
        paddingLeft: theme.spacing(7.5),
        paddingRight: theme.spacing(8.5),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        borderBottom: `1px solid ${theme.palette.grey.border}`,

        '& :first-child': {
            marginLeft: 0
        },
        '& :last-child': {
            marginRight: 0
        },

        '@media (max-width: 1180px)': {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            padding: theme.spacing(2),

        }
    },
    infoFieldWrapper: {
        padding: '0px 8px',
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginRight: theme.spacing(1.25),
        marginLeft: theme.spacing(1.25),

        '@media (max-width: 1180px)': {
            margin: 0,
            marginTop: theme.spacing(1)
        }
    },
    noteWrapper: {
        padding: '8px 0px 8px 18px',

        '@media (max-width: 1180px)': {
            padding: '8px',
        }
    },
    infoFieldHeading: {
        color: theme.palette.grey.text,
        paddingLeft: theme.spacing(2.25),
        fontSize: '12px',
    },
    infoField: {
        borderRadius: '5px',
        border: `2px solid ${theme.palette.grey.border}`,
        width: '100%',
        height: '42px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `0px 16px`,
        marginTop: theme.spacing(1),
    },
    infoFieldText: {
        fontSize: '16px',
        fontWeight: 600,
    },
    textFieldNote: {
        height: '80px',
        width: '100%',
        borderWidth: '2px',
        marginTop: '8px'
    },
    confirmOrderHeading: {
        padding: '16px'
    },
}));

export default useStyles;
