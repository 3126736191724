import React from "react";

const LocationSVG = () => (
    <svg height={"24px"} viewBox={"0 0 12 12"}>
        <g id="Слой_x0020_1">
            <path style={{fill:"#009F9A"}}
                  d="M3.74 0c2.06,0 3.73,1.67 3.73,3.74 0,0.75 -0.22,1.45 -0.6,2.04 -1.09,1.65 -2.1,3.26 -3.13,4.96 -1.04,-1.7 -2.19,-3.39 -3.21,-5.08 -0.33,-0.56 -0.53,-1.22 -0.53,-1.92 0,-2.07 1.67,-3.74 3.74,-3.74z"/>
            <circle  style={{fill:"#FEFEFE"}} cx="3.74" cy="3.66" r="2.57"/>
            <g id="_1527678772256">
                <path style={{fill:"#009F9A", fillRule:"nonzero"}}
                      d="M3.2 3.47c-0.24,0 -0.27,-0.19 -0.54,-0.19 -0.22,0 -0.4,0.11 -0.4,0.25 0,0.14 0.18,0.25 0.4,0.25 0.27,0 0.3,-0.19 0.54,-0.19 0.24,0 0.27,0.19 0.54,0.19 0.26,0 0.3,-0.19 0.53,-0.19 0.24,0 0.28,0.19 0.54,0.19 0.22,0 0.41,-0.11 0.41,-0.25 0,-0.14 -0.19,-0.25 -0.41,-0.25 -0.26,0 -0.3,0.19 -0.54,0.19 -0.23,0 -0.27,-0.19 -0.53,-0.19 -0.27,0 -0.3,0.19 -0.54,0.19zm0.54 0.87c-0.15,0 -0.26,0.09 -0.26,0.2 0,0.12 0.11,0.21 0.26,0.21 0.14,0 0.26,-0.09 0.26,-0.21 0,-0.11 -0.12,-0.2 -0.26,-0.2zm0 -0.31c0.23,0 0.27,-0.19 0.53,-0.19 0.23,0 0.41,0.11 0.41,0.25 0,0.14 -0.18,0.25 -0.41,0.25 -0.26,0 -0.3,-0.19 -0.53,-0.19 -0.24,0 -0.28,0.19 -0.54,0.19 -0.22,0 -0.4,-0.11 -0.4,-0.25 0,-0.14 0.18,-0.25 0.4,-0.25 0.26,0 0.3,0.19 0.54,0.19z"/>
                <path style={{fill:"#E42D24", fillRule:"nonzero"}}
                      d="M3.74 2.91c-0.24,0 -0.28,-0.19 -0.54,-0.19 -0.22,0 -0.4,0.11 -0.4,0.25 0,0.14 0.18,0.25 0.4,0.25 0.26,0 0.3,-0.19 0.54,-0.19 0.23,0 0.27,0.19 0.53,0.19 0.23,0 0.41,-0.11 0.41,-0.25 0,-0.14 -0.18,-0.25 -0.41,-0.25 -0.26,0 -0.3,0.19 -0.53,0.19zm0 -0.23c0.14,0 0.26,-0.09 0.26,-0.21 0,-0.11 -0.12,-0.2 -0.26,-0.2 -0.15,0 -0.26,0.09 -0.26,0.2 0,0.12 0.11,0.21 0.26,0.21z"/>
            </g>
        </g>
    </svg>
);

export default LocationSVG;