import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addSelectedPunkt, loginClient, setAssayLine, setAssayTime} from "@cabinet-packages/redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/uk";
import DialogActions from "@material-ui/core/DialogActions";
import {Button, makeStyles, MuiThemeProvider} from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'grid',
        gridTemplateRows: '120px 1fr 46px 1fr 100px',
        gridTemplateColumns: '1fr',
        width: '100%',
        maxWidth: '900px',
        maxHeight: '100%',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        '@media (max-width: 900px)': {
            gridTemplateRows: '64px 1fr 46px 1fr 120px',
        },
    },
    searchButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: '5px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    },
    noConnection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    bottomContainer: {
        background: 'white',
        borderTop: '2px solid rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    totalPriceContainer: {
        padding: '5px',
        fontSize: '18px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '@media (max-width: 900px)': {
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
        },
    },
    actionButton: {
        margin: '10px',
        '@media (max-width: 900px)': {
            margin: '0 5px',
            fontSize: '12px',
            lineHeight: '24.5px',
        },
    },
    container: {
        width: '100%',
        padding: '10px',
        '@media (max-width: 900px)': {
            padding: '6px 2px 2px 2px',
        },
    },
    showPunktsButton: {
        display: 'block',
        minWidth: '100%',
        fontWeight: 600,
        marginBottom: '7px',
        '@media (max-width: 900px)': {
            display: 'none',
        },
    },
    autoComplete: {
        boxSizing: 'content-box',
        width: '100%',
        minWidth: '100%',
    },
    dialogTitle: {
        margin: '10px 0px',
    },
    DialogContentWrapper: {
        paddingLeft: 12
    },
    dialogButtonConfirmOrderPage: {
        border: `1px solid ${theme.palette.primary.main}`,
    },
    dialogButtonBack: {
        border: `1px solid ${theme.palette.secondary.main}`,
    },
}));

function LineDialog({handleClose, point, isComponentInShop, history, themeMaterialUI, selectTime}) {
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const classes = useStyles();
    const {activePatient} = useSelector(store => store.patients);

    const Close = () => {
        handleClose();
    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    function filterWeekends(date) {
        return date.getDay() === 0;
    }

    useEffect(() => {
        if (!activePatient) {
            dispatch(loginClient(themeMaterialUI));
        } else if (!selectTime) {
            dispatch(setAssayTime({date: moment().format('YYYY-MM-DD') + ' 00:00'}))
            dispatch(addSelectedPunkt(point));
            isComponentInShop ? history.push('/shop-confirm-order') : history.push('/confirm-order');
            handleClose();
        }
    }, [dispatch, activePatient]);

    const handleTransitionToConfirmOrderPage = (isComponentInShop) => {

        if (activePatient) {
            dispatch(setAssayLine({date: selectedDate.toISOString().slice(0, 10)}))
            dispatch(addSelectedPunkt(point));
        }

        isComponentInShop
            ? history.push('/shop-confirm-order')
            : history.push('/confirm-order');

        handleClose();
    }
    return (<MuiThemeProvider theme={themeMaterialUI}>
        <Dialog open={true} onClose={handleClose}>
            {selectTime && <>
                {activePatient && <DialogContent className={classes.DialogContentWrapper}>
                    <h4 className={classes.dialogTitle}>Ваш пункт:</h4>
                    <p>{point && point.address}</p>
                    <h4 className={classes.dialogTitle}>Ваша дата:</h4>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                        <KeyboardDatePicker
                            label="Календар"
                            value={selectedDate}
                            onChange={handleDateChange}
                            className={classes.formControl}
                            shouldDisableDate={filterWeekends}
                            disablePast={true}
                        />
                    </MuiPickersUtilsProvider>
                </DialogContent>}
                {activePatient && <DialogActions>
                    <Button
                        onClick={() => Close()}
                        color="secondary"
                        className={classes.dialogButtonBack}
                    >
                        Повернутися назад
                    </Button>
                    <Button
                        onClick={() => handleTransitionToConfirmOrderPage(isComponentInShop)}
                        color="primary"
                        className={classes.dialogButtonConfirmOrderPage}
                    >
                        Запланувати візит
                    </Button>
                </DialogActions>}
            </>}
        </Dialog>
        </MuiThemeProvider>);
}

export default LineDialog;
