import {
    SET_ALL_COLLECTION_POINTS,
    SET_CLIENT_LOCATION,
    SET_COLLECTION_POINTS,
    SET_REGION_CONFIRMATION,
    SET_REGIONS,
    SET_SELECT_PUNKT
} from "../actions/actionsTypes";

export const collectionPointsReducerInitialState = {
    punkts: null,
    clientLocation: null,
    regions: null,
    regionConfirmation: false,
    selectPunkt: null,
    allPunkts: null
};

export default function collectionPointsReducer(state = collectionPointsReducerInitialState, { type, payload }){
    switch (type) {
        case SET_COLLECTION_POINTS:
            return {
                ...state,
                punkts: payload.data,
            };
        case SET_ALL_COLLECTION_POINTS:
            return {
                ...state,
                allPunkts: payload.data,
            };
        case SET_CLIENT_LOCATION:
            return {
                ...state,
                clientLocation: payload.data,
            };
        case SET_REGIONS:
            return {
                ...state,
                regions: payload.data,
            };
        case SET_REGION_CONFIRMATION:
            return {
                ...state,
                regionConfirmation: payload.value,
            };
        case SET_SELECT_PUNKT:
            return {
                ...state,
                selectPunkt: payload.selectPunkt,
            };

        default:
            return state;
    }
};
