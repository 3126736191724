import React, {useState} from "react";
import useStyles from "../styles";
import {
    addAssayToOrderListCalculator,
    clearOrderedAssaysCalculator,
    removeAssayFromOrderListCalculator,
    setGoToCollectionPoints,
    toggleTakeInOrder
} from "@cabinet-packages/redux/actions/actionsCreators";
import {CustomDialog, TextAlertDialog} from "../../../components";
import {useDispatch, useSelector} from "react-redux";
import {convertPrice} from "../../PatientTabs/helpers/convertPrice";
import {AddShoppingCart, ShoppingCart} from "@material-ui/icons";

const AddRemoveButton = ({item, isIncludedInOrderingList, actionInOrderList, handleChecked, assayInfo, isInBasket,handleClose=null}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const {themeMaterialUI} = useSelector(state => state.theme);
    const {takeId, catalog, complexResearch, actions}  = useSelector(state => state.assaysCatalog);
    const fullCatalog = catalog && complexResearch && actions && [...catalog, ...complexResearch, ...actions];
    const { orderingList, goToCollectionPoints, promoCode, takeInOrder } = useSelector(state => state.calculator);
    const isDoctor = localStorage.getItem('isDoctor') || process.env.REACT_APP_PROJECT_NAME === 'CALCULATOR';
    const [showStartImg, setShowStartImg] = useState(true);
    const prevOrderingList = orderingList;

    const handleAdd = (event) => {
        if (handleClose!=null){
            handleClose(event)
        }
        event.stopPropagation();
        event.preventDefault();
        if (+item.idParent === +takeId) {
            return;
        }
        if (takeInOrder) {
            dispatch(toggleTakeInOrder());
            dispatch(clearOrderedAssaysCalculator());

            prevOrderingList.forEach(assay => {
                const findAssay = [...catalog, ...complexResearch, ...actions].find(item => item.type === assay.type && +item.id === +assay.id);

                dispatch(addAssayToOrderListCalculator(findAssay,
                    findAssay.discount
                        ? findAssay.price - findAssay.price * findAssay.discount / 100
                        : findAssay.price
                ));
            });
        }

        if (takeId === 14) {
            window.dataLayer.push({
                event: 'add_to_cart',
                eventProps: {
                    items: [{
                        id: item.id,
                        name: item.name,
                        variant: item.type,
                        price: item.discount
                            ? item.price - item.price * item.discount / 100
                            : item.price,
                        quantity: 1,
                    }],
                },
                coupon: '',
            });
        }

        if (goToCollectionPoints) {
            const prevOrderingList = orderingList;

            dispatch(clearOrderedAssaysCalculator());
            prevOrderingList.forEach(assay => {
                const findAssay = fullCatalog.find(item => item.type === assay.type && +item.id === +assay.id && !item.superGroupName);

                dispatch(addAssayToOrderListCalculator(findAssay,
                    findAssay.discount && !isDoctor
                        ? findAssay.price - findAssay.price * findAssay.discount / 100
                        : findAssay.price
                ));
            });

            dispatch(setGoToCollectionPoints(false));
        }

        if (actionInOrderList && actionInOrderList.length > 0) {
            CustomDialog(TextAlertDialog,{
                text: 'Акційні пропозиції можна замовити тільки в певних пунктах. ' +
                    'Замовте вибрану акцію, та повторіть процедуру для наступної.'},false, themeMaterialUI);
            return;
        }

        const assay = item;
        if (!promoCode || (promoCode && promoCode.type === 'none')) {
            dispatch(addAssayToOrderListCalculator(
                assay,
                assay.discount && !isDoctor
                    ? assay.price - assay.price * assay.discount / 100
                    : assay.price
            ));
        } else if (promoCode && promoCode.type === 'specified') {
            const foundDiscount = promoCode.grtests.find(item => +item[0] === +assay.id);

            if (foundDiscount) {
                dispatch(addAssayToOrderListCalculator(
                    assay,
                    +foundDiscount[1] === 0 ? +assay.price - (+assay.price * (+foundDiscount[2]/100)) : +foundDiscount[1]))
            } else {
                dispatch(addAssayToOrderListCalculator(
                    assay,
                    +assay.price - (+assay.price * (+assay.discount/100))));
            }
        } else if (promoCode && promoCode.type === 'notdisc') {
            const discount = assay.opt && assay.opt.includes(promoCode.type) ? assay.discount : promoCode.discount;
            if (assay.idParent !== takeId) {
                dispatch(addAssayToOrderListCalculator(assay, +assay.price - (+assay.price * (+discount/100))))
            }
        } else if (promoCode && promoCode.type === 'all') {
            if (assay.idParent !== takeId) {
                dispatch(addAssayToOrderListCalculator(assay, +assay.price - (+assay.price * (+promoCode.discount/100))))
            }
        }
    };

    const handleRemove = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (+item.idParent === +takeId) {
            return;
        }

        if (handleChecked) handleChecked({target: {checked: false, name: 'checkedA'}});

        if (goToCollectionPoints) {
            const prevOrderingList = orderingList;

            dispatch(clearOrderedAssaysCalculator());
            prevOrderingList.forEach(assay => {
                const findAssay = fullCatalog.find(item => item.type === assay.type && +item.id === +assay.id && !item.superGroupName);

                dispatch(addAssayToOrderListCalculator(findAssay,
                    findAssay.discount && !isDoctor
                        ? findAssay.price - findAssay.price * findAssay.discount / 100
                        : findAssay.price
                ));
            })
            dispatch(setGoToCollectionPoints(false));
        }

        const assay = item;
        if (!promoCode || (promoCode && promoCode.type === 'none')) {
            dispatch(removeAssayFromOrderListCalculator(
                assay,
                assay.discount && !isDoctor
                    ? assay.price - assay.price * assay.discount / 100
                    : assay.price
            ));
        } else if (promoCode && promoCode.type === 'specified') {
            const foundDiscount = promoCode.grtests.find(item => +item[0] === +assay.id);

            if (foundDiscount) {
                dispatch(removeAssayFromOrderListCalculator(
                    assay,
                    +foundDiscount[1] === 0 ? +assay.price - (+assay.price * (+foundDiscount[2]/100)) : +foundDiscount[1]));
            } else {
                dispatch(removeAssayFromOrderListCalculator(
                    assay,
                    +assay.price - (+assay.price * (+assay.discount/100))));
            }
        } else if (promoCode && promoCode.type === 'notdisc') {
            const discount = assay.opt && assay.opt.includes(promoCode.type) ? assay.discount : promoCode.discount;
            if (assay.idParent !== takeId) {
                dispatch(removeAssayFromOrderListCalculator(assay, +assay.price - (+assay.price * (+discount/100))))
            }
        } else if (promoCode && promoCode.type === 'all') {
            if (assay.idParent !== takeId) {
                dispatch(removeAssayFromOrderListCalculator(assay, +assay.price - (+assay.price * (+promoCode.discount/100))))
            }
        }

        if (handleChecked) handleChecked({target: {checked: true, name: 'checkedA'}});

        if (isInBasket) {
            window.dataLayer.push({
                event: 'remove_from_cart',
                eventProps: {
                    items: [{
                        id: item.id,
                        name: item.name,
                        variant: item.type,
                        price: item.discount
                            ? item.price - item.price * item.discount / 100
                            : item.price,
                        quantity: 1,
                    }],
                },
                coupon: '',
            });
        }
    };

    return (
        <div
            className={isIncludedInOrderingList ? cl.listItemPriceInBasket : cl.listItemPrice}
            onClick={(e) => isIncludedInOrderingList ? handleRemove(e) : handleAdd(e)}
            style={+item.idParent === +takeId ? ({opacity:  0.5, border: '2px solid #00B3A1', cursor: 'default'}) : ({opacity: 1})}
        >
            {isIncludedInOrderingList && +item.idParent !== +takeId
                ? <ShoppingCart color="inherit" fontSize="small" style={{color: 'white'}}/>
                : <AddShoppingCart color="primary" fontSize="small"/>
            }
            {(!isIncludedInOrderingList || +item.idParent === +takeId) && convertPrice(item.price, item.discount, cl.listItemDiscountPriceDiscount)}
        </div>
    );
};

export default AddRemoveButton;
