import React from "react";
import useStyles from "../styles";
import WarningIcon from "../assets/icn_info_yellow.svg";
import {Tooltip} from "@material-ui/core";

const CheckboxLabel = ({text, redText, link, warningIcon, toolTipText}) => {
    const cl = useStyles();

    return (<div className={cl.orderTermsCheckboxLabel}>
        <p className={cl.checkboxLabelText}>
            {text && text} {redText && link && <a href={link} target="_blank" className={cl.checkboxLabelLink}>{redText}</a>}
        </p>
        {warningIcon && <Tooltip title={toolTipText} arrow placement="top" leaveDelay={1000} classes={{tooltip: cl.tooltip, arrow: cl.arrow}}>
            <img src={WarningIcon} alt="Icon." className={cl.warningExpandedIcon}/>
        </Tooltip>}
    </div>)
};

export default CheckboxLabel;
