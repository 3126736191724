import React, {useState} from "react";
import useStyles from "../styles";
import {MenuItem, Select} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {getCatalogWizard} from "@cabinet-packages/redux/actions/actionsCreators";

const hardcorePunkts = [
    218,
    476,
    306,
    111,
    169,
    28,
    79,
    194,
    122
]

const RegionPick = ({setCatalogForView}) => {
    const cl = useStyles();
    const {punkts} = useSelector(state => state.collectionPoints);
    const dispatch = useDispatch();
    const idPunkt = localStorage.getItem('idPunkt');
    const [open, setOpen] = useState(false);

    const handleOpenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setOpen(true);
    }

    const handleClose = (e, tag) => {
        setOpen(false);

        if (+tag.idPunkt !== +idPunkt) {
            localStorage.setItem('idPunkt', tag.idPunkt);
            setCatalogForView(null);
            dispatch(getCatalogWizard(true));
        }
    };
    return (punkts && idPunkt && <Select
        value={punkts ? punkts.find(item => item.idPunkt === +idPunkt)?.idPunkt : []}
        // anchorEl={anchorEl}
        style={{width: '200px', height: '38px'}}
        classes={{
            outlined: cl.selectRoot
        }}
        variant="outlined"
        open={open}
        onOpen={handleOpenMenu}
    >
        {punkts.filter(el=>hardcorePunkts.includes(el.idPunkt)).map(item => (
            <MenuItem classes={{root: cl.endAdornmentMenuItemRegion}} key={'tags' + item.address + item.id}
                      value={item.idPunkt}
                      onClick={(e) => handleClose(e, item)}>{item.address}</MenuItem>))
        }
    </Select>);
};

export default RegionPick;


