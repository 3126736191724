import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { SwipeStyledButton } from "../index";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  generalDialog: {
    borderRadius: "15px",
  },
  dialogHeader: {
    background: theme.palette?.colors?.main,
    color: "white",
    textAlign: "center",
    fontSize: "1.5rem",
  },
  dialogContent: {
    textAlign: "center",
    color: theme.palette?.colors?.text,
    fontSize: "20px",
  },
  dialogActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function TextAlertDialog({
  onConfirm = () => null,
  handleClose,
  text = "Ви впевнені, що хочете це зробити?",
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={true}
      onClose={handleClose}
      fullWidth
      classes={{ paper: classes.generalDialog }}
    >
      <DialogTitle classes={{ root: classes.dialogHeader }}>
        Попередження
      </DialogTitle>
      <DialogContent>
        <DialogContentText classes={{ root: classes.dialogContent }}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <SwipeStyledButton border={'2px solid red'} color={'red'} animationStart={"right"} onClick={handleClose}>
          Зрозуміло
        </SwipeStyledButton>
      </DialogActions>
    </Dialog>
  );
}

export default TextAlertDialog;
