import React, {useEffect} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,} from '@material-ui/core';

function TextAlertDialogCalculatorWithReload({
                             onConfirm = () => null,
                             handleClose,
                             text = 'Ви впевнені, що хочете це зробити?',
                         }) {
    const handleConfirm = () => {
        onConfirm();
        handleClose();
    };

    const handleCloseWithReload = () => {
        localStorage.removeItem('_expiredTime');
        window.location.reload();
        handleClose();
    };

    useEffect(() => {
        setTimeout(handleCloseWithReload, 5000);
    }, []);

    return (
        <Dialog
            open={true}
            onClose={handleCloseWithReload}
            fullWidth
        >
            <DialogTitle>
                Попередження
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} color="primary" autoFocus>
                    Зрозуміло
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default TextAlertDialogCalculatorWithReload;
