import React, {createContext, useEffect} from "react";
import useStyles from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {getPunkts} from "@cabinet-packages/redux";
import {setRegions} from "@cabinet-packages/redux/actions/actionsCreators";
import Heading from "./components/Heading";

export const OrderPunktsContext = createContext(null);
const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const OrderPunkts = () => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const {regions} = useSelector(state => state.collectionPoints);

    useEffect(() => {
        dispatch(getPunkts());
    }, []);

    useEffect(() => {
        if (!regions) {
            dispatch(setRegions());
        }
    }, [regions])

    return (<OrderPunktsContext.Provider value={{

    }}>
        <div className={cl.rootOrderPunkts}>
            <div className={cl.innerWrapperOrderPunkts}>
                <Heading/>

            </div>
        </div>
    </OrderPunktsContext.Provider>);
};

export default OrderPunkts;
