import React, {useState} from "react";
import useStyles from "../styles";
import MuiPhoneNumber from "material-ui-phone-number";
import {useDispatch} from "react-redux";
import {START_PHONE_SEND} from "@cabinet-packages/redux";
import {authorise} from "@cabinet-packages/api";
import {SwipeStyledButton} from "@cabinet-packages/components";
import {Slide, Snackbar} from "@material-ui/core";

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

const FirstStage = ({usePhone, isChangedState, setValue, uuid}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const [phone, setPhone] = usePhone;
    const [open, setOpen] = useState(false);
    const [snackBarText, setSnackbarText] = useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setSnackbarText('');
    };

    const handlePressEnter = (e) => {
        if (e.key === 'Enter' && !isChangedState) {
            handleConfirm(e, phone);
        }
    };

    const onSendPhone = async (uuidPhone, phone) => {
        dispatch({
            type: START_PHONE_SEND,
            payload: {
                stage : 2,
                error : null,
            },
        });

        const result = await authorise(uuid, phone).catch(onerror => {
            if (onerror) {
                dispatch({
                    type: START_PHONE_SEND,
                    payload: {
                        stage: 1,
                        error: 'errorWhenSendPhone',
                    },
                });
            }
        });

        if (result) {
            dispatch({
                type: START_PHONE_SEND,
                payload: {
                    stage: 3,
                },
            });
        }
    };

    const handleConfirm = (e, formData) => {
        if (!isChangedState) {
            onSendPhone(uuid, formData);
            setValue('');
        } else {
            setOpen(true);
            setSnackbarText('Введіть номер телефону, щоб отримати код.');
        }
    };

    return (<div className={cl.phoneEnterForm}>
        <div className={cl.wrapper}>
            <p className={cl.phoneLabel}>Номер телефону</p>
            <MuiPhoneNumber
                className={cl.muiPhoneNumber}
                autoFocus={true}
                defaultCountry='ua'
                countryCodeEditable={false}
                regions='europe'
                value={phone}
                onChange={(value, e) => {
                    const validPhone = "+" + e.dialCode + value.slice(4, value.length)
                    setPhone(validPhone)
                }}
                variant="outlined"
                onKeyPress={handlePressEnter}
            />
        </div>
        <div className={cl.phoneDescriptionWrapper}>
            <p className={cl.phoneDescriptionText}>
                Верифікаційний код буде надісланий Вам у вигляді SMS повідомлення або у Viber
            </p>
        </div>
        <SwipeStyledButton
            className={cl.swipeStyledButton}
            animationStart={'left'}
            onClick={(e) => handleConfirm(e, phone)}
        >
            Отримати код
        </SwipeStyledButton>
        <Snackbar
            ContentProps={{
                classes: {
                    root: cl.snackbar,
                    message: cl.snackbar
                }
            }}
            TransitionComponent={TransitionUp}
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            message={<div className={cl.snackbarContentWrapper}>
                <div className={cl.snackbarContentHeader}>
                    Увага!
                </div>
                <div className={cl.snackbarContentText}>
                    {snackBarText && snackBarText}
                </div>
            </div >}
        >
        </Snackbar>
    </div>);
}

export default FirstStage;
