import {Button, Checkbox, Dialog, FormControlLabel, FormGroup, Grid,} from "@material-ui/core";
import React, {createContext, useEffect, useState} from "react";
import Loading from "../../Shared/Loading";
import {useDispatch, useSelector} from "react-redux";
import 'moment/locale/uk';
import moment from "moment";
import {
    editPatientInfoSite,
    getCheckForAuthorizedSite,
    getPatientsSite,
    setCreateClient,
    setExistingPatientsSite,
    setRegisterChildSite
} from "@cabinet-packages/api/api";
import {setClient, START_PHONE_SEND} from "@cabinet-packages/redux";
import {setListOfPatientsSite} from "@cabinet-packages/redux/actions/actionsCreators";
import useStyles from "./styles";
import closeImg from "./img/close.svg"
import InputWrapperAuthorization from "./components/InputWrapperAuthorization";
import Birthday from "./components/Birthday";
import {CustomDialog, TooltipError} from "../../index";
import {AccessKeyMonth} from "./helpers/getSetAccessKey";
import SelectedPatients from "./components/SelectedPatients";
import uuidV4 from 'uuid/v4';
import PublicOfferDialog from "./components/dialogs/PublicOfferDialog";


export const UserContext = createContext(null);
const UserProvider = UserContext.Provider

const AuthDialogSite = ({handleClose, handlePatient, phone, setValueProps, editPatient,themeMaterialUI }) => {
    moment.locale('uk');
    const cl = useStyles();
    const dispatch = useDispatch();
    const [uuid] = useState(uuidV4());
    const {activePatient, listOfPatients, stage} = useSelector(state => state.patients);
    const [checkboxState, setCheckboxState] = useState(false);
    const [values, setValues] = useState({
        lastName: '',
        firstName: '',
        fatherName: '',
        phone: '',
        email: '',
        gender: 'F',
        firstNameEng: '',
        lastNameEng: '',
        note: '',
        error: false,
        birthdayYear: '1990',
        birthdayMonth: '',
        birthdayDay: '',
        handlePatient: false,
        editPatient: false,
        mainPatient: null,
        checked: null,
        mainPatientAuthorization: null,
        iAgreeChecked: false,
    })
    const value = {
        values: {values, setValues},
    }
    const [openError, setOpenError] = useState(false)
    const idReg =  localStorage.getItem("idReg");



    const handleChangeNote = (e) => {
        const value = e.target.value;
        setValues(prev => ({
            ...prev,
            ['note']: value,
        }));
    };

    const handleChange = (event) => {
        setCheckboxState(event.target.checked)
    }

    const handleActivePatient = (name, patientValue) => {
        setValues(prev => ({
            ...prev,
            [name]: patientValue,
        }));
    }

    const findMonths = () => {
        const monthsArray = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];
        const findMonthsArray = monthsArray.indexOf(values.birthdayMonth) + 1
        if (findMonthsArray < 10) {
            return `0${findMonthsArray}`
        } else {
            return findMonthsArray
        }
    }

    const resListOfPatients = async () => {
        const res = await getPatientsSite()
        if (res !== 'Unauthorized') {
            // setChecked(res.map(it => it.id))
            // setValueActivePatient(res[0])
            return res[0]
        }
    }

    const handleSignIn = async (valueActivePatient) => {
        dispatch(setListOfPatientsSite());
        const birthday = typeof valueActivePatient.birthday === 'string'
            ? valueActivePatient.birthday
            : new Date(valueActivePatient.birthday.toString()).toLocaleDateString().split('.').reverse().join('-');
        dispatch(setClient({...valueActivePatient, birthday}));
        close()
    }


    const lastStep = async () => {
        const res = await resListOfPatients()
        await handleSignIn(res)
    }

    const close = () => {
        dispatch({
            type: START_PHONE_SEND,
            payload: {
                stage: 1,
            },
        });
        handleClose()
    }
    const findRequiredValue = () => {
        if (values.editPatient) {
            if (!values.lastName || !values.firstName || !values.email || !values.birthdayDay || values.error || !checkboxState) {
                return true
            }
            return false
        }
        if (!handlePatient) {
            if ((stage === 8) && !checkboxState) {
                return true
            }
            if (stage === 5) {
                if (!values.lastName || !values.firstName || !values.email || !values.birthdayDay || values.error || !checkboxState) {
                    return true
                }
            }
        }
        return false
    }
    const savePatients = async () => {
        const birthdayDate = `${values.birthdayYear}-${findMonths()}-${values.birthdayDay}`
        await setExistingPatientsSite(
            values.mainPatientAuthorization.id,
            values.checked.map(patient => patient.id).filter(id => +id !== +values.mainPatientAuthorization.id),
            values.phone.replace(/[[()\s]/g, ''),
            checkboxState,
            checkboxState,
        )
        await editPatientInfoSite(values.lastName,
            values.firstName,
            values.fatherName,
            birthdayDate,
            values.gender,
            values.mainPatientAuthorization.id,
        );
        dispatch(setClient({...values.mainPatientAuthorization, birthdayDate}));
        dispatch({
            type: START_PHONE_SEND,
            payload: {
                stage: 1,
            },
        });
        handleClose()
        window.scrollTo(0, 0)
    }


    const authDialog = async () => {
        const birthdayDate = `${values.birthdayYear}-${findMonths()}-${values.birthdayDay}`
        if (stage === 5) {
            const result = await setCreateClient(uuid, values.lastName.trim(), values.firstName.trim(), values.fatherName.trim(), birthdayDate, values.gender, values.phone.replace(/[[()\s]/g, ''), values.email)
            if (result) {
                AccessKeyMonth.set(result.token)
                dispatch({
                    type: START_PHONE_SEND,
                    payload: {
                        stage: 7,
                    },
                });
                dispatch(setListOfPatientsSite());
            }
        }
        if (stage === 7) {
            const result = listOfPatients.filter(patient =>
                values.checked.includes(patient.id)
            )
            const activeResult = result[0]
            const birthdayDate = `${activeResult.birthday}`
            await setExistingPatientsSite(
                activeResult.id,
                listOfPatients.map(patient => patient.id).filter(id => +id !== +activeResult.id),
                activeResult.phone.replace(/[[()\s]/g, ''),
                true,
                true,
            )
            await editPatientInfoSite(activeResult.lastname,
                activeResult.firstname ? activeResult.firstname : '',
                activeResult.fathername ? activeResult.fathername : '',
                birthdayDate,
                activeResult.sex,
                activeResult.id,
            );
            dispatch(setClient(activeResult));
            dispatch({
                type: START_PHONE_SEND,
                payload: {
                    stage: 1,
                },
            });
            handleClose()
            window.scrollTo(0, 0)
            // handleActivePatient('checked', result)
        }
        if (stage === 8) {
            await savePatients()
        }
    }

    const savePatient = async () => {
        if (findRequiredValue()) {
            return setOpenError(true)
        }
        if (stage !== 1) {
            return authDialog()
        }
        if (listOfPatients) {
            if (values.mainPatient) {
                handleActivePatient('editPatient', false)
                if (listOfPatients?.length > 0) {
                    dispatch(setClient(values.mainPatient))
                    dispatch(setListOfPatientsSite())
                    return handleActivePatient('mainPatient', null)
                }
            }
            if (values.editPatient) {
                try {
                    const orderDate = `${values.birthdayYear}-${findMonths()}-${values.birthdayDay} 00:00`
                    let createPatient
                    const findMainPatients = listOfPatients.find(el => el.main)
                    const id = findMainPatients ? findMainPatients.id : activePatient.id
                    createPatient = await setRegisterChildSite(id, values.lastName, values.firstName, values.fatherName, orderDate, values.gender, values.email)

                    dispatch(setClient(createPatient[0]))
                    await getPatientsSite();
                    await getCheckForAuthorizedSite();
                    await dispatch(setListOfPatientsSite(true));
                    handleActivePatient('editPatient', false)
                    window.scrollTo(0, 0)
                } catch (e) {
                    console.log('savePatient error !!!', e.message)
                }
            }
        }
        close()
    }

    const openOffer = (e) => {
        e.stopPropagation();
        e.preventDefault();
        CustomDialog(PublicOfferDialog, {themeMaterialUI, idReg}, false,)
    }

    useEffect(() => {
        if (stage === 5) {
            handleActivePatient('iAgreeChecked', true)
        } else {
            if (values.iAgreeChecked) {
                handleActivePatient('iAgreeChecked', false)
            }
        }
        if (stage === 7) {
            lastStep()
        }
    }, [stage])

    useEffect(() => {
        if (phone) {
            //phone: "+380689815677"
            const phonePatient = '+380' + phone
            handleActivePatient('phone', phonePatient)
        }
    }, [phone])

    useEffect(() => {
        if (editPatient) {
            handleActivePatient('editPatient', true)
        } else {
            handleActivePatient('editPatient', false)
        }
    }, [editPatient])

    useEffect(() => {
        if (handlePatient) {
            handleActivePatient('handlePatient', true)
        } else {
            handleActivePatient('handlePatient', false)
        }
    }, [handlePatient])

    useEffect(() => {
        if (activePatient && handlePatient) {
            if (activePatient.sex) {
                handleActivePatient('gender', activePatient.sex)
            }
            if (activePatient?.lastname) {
                handleActivePatient('lastName', activePatient.lastname)
            }
            if (activePatient?.firstname) {
                handleActivePatient('firstName', activePatient.firstname)
            }
            if (activePatient?.fathername) {
                handleActivePatient('fatherName', activePatient.fathername)
            }
            if (activePatient?.email) {
                handleActivePatient('email', activePatient.email)
            }
            if (activePatient?.phone) {
                handleActivePatient('phone', activePatient.phone)
            }
        }

    }, [activePatient])



    return (
        <Dialog
            open={true}
            onClose={close}
            className={cl.root}
        >
            {(stage === 7 || stage === 8)
                ? <Loading/>
                : <UserProvider value={value}>
                    {handlePatient && <>
                        <Grid container spacing={2} className={cl.wrapperTitle}>
                            <Grid item xs={10} md={11}>
                                <h3 className={cl.titleMain}>Авторизація</h3></Grid>
                            <div className={cl.wrapperCloseImg}>
                                <Grid item xs={1} md={1}>
                                    <img src={closeImg} alt="close" onClick={close}/></Grid>
                            </div>
                        </Grid>
                        <SelectedPatients/>
                    </>}
                    <div>
                        {!handlePatient && <>
                            <Grid container spacing={2} className={cl.wrapperTitle}>
                                <Grid item xs={10} md={11}>
                                    <h3 className={cl.titleMain}>Реєстрація</h3></Grid>
                                <div className={cl.wrapperCloseImg}>
                                    <Grid item xs={1} md={1}>
                                        <img src={closeImg} alt="close" onClick={close}/></Grid>
                                </div>
                            </Grid>
                            <p className={cl.warningTitle}><span className={cl.warningTitleMain}>Увага!</span>
                                <br/>
                                Аналізи зможе здати тільки особа, дані якої Ви вносите нижче. Додати членів
                                сім'ї можна
                                в особистому кабінеті.</p>
                        </>}
                        {stage !== 7 && <>
                            <InputWrapperAuthorization/>
                            <Birthday/>
                        </>}

                        {(stage === 5 || values.editPatient) && <FormGroup className={cl.wrapperCheckbox}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkboxState}
                                        onChange={handleChange}
                                        name="checkedA"
                                        color="primary"
                                    />
                                }
                                label={<p className={cl.checkedDisableText}>
                                    Я згоден(на) на обробку персональних данних та
                                    <span style={{color: '#0077C8', cursor: 'pointer' }}  onClick={openOffer}>   ознайомлений(на) з умовами користувацької угоди*</span>
                                </p>}
                            />
                        </FormGroup>}
                        <TooltipError
                            placement={"bottom"}
                            open={openError}
                            setOpen={setOpenError}
                            width={410}
                            value={[
                                {visible: !values.firstName, text: 'Щоб продовжити, введіть ваше ім\'я!'},
                                {visible: !values.lastName, text: 'Щоб продовжити, введіть прізвище!'},
                                {visible: !values.email, text: 'Щоб продовжити, введіть пошту!'},
                                {
                                    visible: !values.birthdayDay,
                                    text: 'Щоб продовжити, введіть дату народження!'
                                },
                                {
                                    visible: !checkboxState,
                                    text: 'Щоб продовжити, ознайомтесь  з умовами користувацької угоди та дайте згоду на використання персональних даних!'
                                }
                            ]}
                            component={<Button
                                className={cl.mainButton}
                                variant="outlined"
                                color="primary"
                                onClick={() => savePatient()}
                                // disabled={findRequiredValue()}
                            >
                                Зберегти
                            </Button>}
                        />

                    </div>
                </UserProvider>
            }
        </Dialog>
    )
}

export default AuthDialogSite