export const isIncludedInComplexResearch = (item, complexResearch) => {

    return complexResearch && complexResearch.reduce((acu, cur) => {
        if (cur && cur.filling && item.filling && !cur.notSale && cur.filling.find(fill => !!item.filling.find(itemFill => itemFill.id === fill.id))) {
            return true;
        }
        if (item.id && cur.idGrTest && cur.idGrTest.find(grTest => +grTest.id === +item.id)) {
            return true;
        }

        return acu;
    }, false)
};
