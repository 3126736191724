import React from 'react';
import MZ from "./img/bw_logo.svg";

export default function MZgroup ({left}) {
    return (
        <a
            href="https://mz-group.com.ua/"
            style={{
                position: 'absolute',
                bottom: 4,
                [left ? 'left' : 'right']: 4,
                fontSize: '12px',
                textDecoration: 'none',
                color: 'rgba(0, 0, 0, 0.54)',
            }}
        >
            IT партнер
            <img src={MZ} alt="MZ-group logo." style={{height: '25px', marginLeft: '4px', marginBottom: '-7px'}}/>
        </a>);
};