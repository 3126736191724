import {
    AddNewPatientDialog,
    AuthDialog,
    AuthDialogSite,
    ClientRegionDialog,
    ClientRegionDialogVdoma,
    ConfirmCabinetDialog,
    ConfirmDialog,
    ConfirmDialogMedis,
    ConfirmPayment,
    CustomDialog,
    ErrorIndicatorDialog,
    GetClientRegion,
    InsertResultDialog,
    LineDialog,
    LoadingIndicatorDialog,
    LoadOrderResultDialog,
    NewPatientDialog,
    PhoneEnterDialog,
    PointsPopupsDialog,
    QRCodeDialog,
    RefundDialog,
    RespondVisitDialog,
    SelectDoctorPatient,
    SelectPartnerPatient,
    SignalMarksDialog,
    TextAlertDialog,
    TextAlertDialogCalculator,
    TextAlertDialogCalculatorWithReload,
    TextAlertDialogMedis,
    WarningDialog,
    ConfirmRegionDialog
} from "./Dialogs/Dialogs";


import MainNavigation from "./MainNavigation"
import PublicOfferDialog from "./Dialogs/AuthDialogSite/components/dialogs/PublicOfferDialog"
import AssaySchedulerWeek from "./Order";
import {Current, ListItem} from "./Patient"
import {
    AddImage,
    AddRemoveIconButtons,
    ButtonGroupPunkts,
    ButtonWithLoader,
    ExpansionPanel,
    Loading,
    NotificationIcon,
    PageTitle,
    RemoveImage,
    Scroll,
    SearchTextField,
    StartImage,
    TabPanel
} from "./Shared";
import MenuAppBar from "./MenuAppBar";
import MediaCard from "./MediaCard";
import TooltipError from "./TooltipError"
import {ResultFlora, ResultHTML, ResultJSON, ResultStringAndDigital,} from './PatientResults';
import MZgroup from './marking/MZgroup'
import {PreparationRules} from './QuickPurchase';
import SwipeStyledButton from './Buttons/SwipeStyledButton';

export {
    CustomDialog,
    ConfirmDialog,
    ConfirmCabinetDialog,
    PointsPopupsDialog,
    QRCodeDialog,
    RefundDialog,
    RespondVisitDialog,
    TextAlertDialog,
    WarningDialog,
    LineDialog,
    LoadOrderResultDialog,
    PhoneEnterDialog,
    MainNavigation,
    ConfirmPayment,
    SelectDoctorPatient,
    GetClientRegion,
    AddNewPatientDialog,
    SignalMarksDialog,
    SelectPartnerPatient,
    LoadingIndicatorDialog,
    ErrorIndicatorDialog,
    NewPatientDialog,
    ClientRegionDialog,
    ConfirmDialogMedis,
    TextAlertDialogMedis,
    TextAlertDialogCalculator,
    InsertResultDialog,
    ClientRegionDialogVdoma,
    TextAlertDialogCalculatorWithReload,
    AuthDialog,
    AuthDialogSite,
    AssaySchedulerWeek,
    ConfirmRegionDialog,

    Current,
    ListItem,

    ButtonWithLoader,
    ExpansionPanel,
    Loading,
    NotificationIcon,
    PageTitle,
    Scroll,
    SearchTextField,
    TabPanel,
    AddRemoveIconButtons,
    AddImage,
    RemoveImage,
    StartImage,
    ButtonGroupPunkts,

    MenuAppBar,
    MediaCard,
    TooltipError,
    ResultFlora,
    ResultHTML,
    ResultJSON,
    ResultStringAndDigital,

    MZgroup,
    PreparationRules,

    SwipeStyledButton,
    PublicOfferDialog,
};
