import {setSelectedAction} from "@cabinet-packages/redux";
import React, {useState} from "react";
import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Popover,
    Snackbar,
    Tooltip
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import {Alert} from "@material-ui/lab";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton,
} from "react-share";
import {Email, Facebook, Instagram, LinkedIn, Telegram, Twitter, WhatsApp} from "@material-ui/icons";
import Viber from '../assets/viber.svg'
import {AddRemoveIconButtons} from "./index";

const useStyles = makeStyles({
    root: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'max-content',
        minHeight: '100%'
    },
    media: {
        height: 200,
        width: '100%',
    },
    addRemoveButton: {
        marginLeft: 'auto',
    },
    popoverInnerWrapper: {
        width: '100%',
    },
    socialMediaShareButtons: {
        width: 48,
        height: 48,
        position: 'relative',
        outline: 'none',
        border: 'none',
        backgroundColor: 'white',
    },
    socialMediaShareButtonsImage: {
        width: 24,
        height: 24,
    },
    socialMediaShareButtonsInnerTouch: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 48,
        height: 48,
        borderRadius: 24,
        zIndex: 0,
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
    cardActionArea: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        wordBreak: 'break-word',
    },
});

const MediaCard = ({item, isComponentInShop}) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { orderingList } = useSelector(state => state.orderAssays);
    const { actions, takeId } = useSelector(state => state.assaysCatalog);
    const {imgUrl} = useSelector(store => store.theme);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const hrefLocation = window.location.origin;
    const [anchorEl, setAnchorEl] = useState(null);

    const url = () => {
        switch (item.type) {
            case "action": {
                return isComponentInShop ? `${hrefLocation}/shop-actions-details/${item.id}` : `${hrefLocation}/actions-details/${item.id}`
            }
            case "complex": {
                return isComponentInShop ? `${hrefLocation}/shop-complex-info/${item.id}` : `${hrefLocation}/complex-info/${item.id}`
            }
            default: {
                return;
            }
        }
    }

    const isIncludedInOrderingList = orderingList?.find(assay => assay.id === item.id && assay.type === item.type);
    const actionInOrderList = orderingList?.filter(actionID => (typeof actions === "string" ? [] : actions)?.find(item => item.id === actionID.id && actionID.type === item.type));

    const handleTransitionToActionDetails = (event) => {
        event.stopPropagation();
        event.preventDefault();
        dispatch(setSelectedAction(item));

        switch (item.type) {
            case "action": {
                isComponentInShop
                    ? history.push(`/shop-actions-details/${item.id}`)
                    : history.push(`/actions-details/${item.id}`)
                break;
            }
            case "complex": {
                isComponentInShop
                    ? history.push(`/shop-complex-info/${item.id}`)
                    : history.push(`/complex-info/${item.id}`)
                break;
            }
            default: {
                return;
            }
        }
    };

    const handleCopyHrefToAction = (event) => {
        event.stopPropagation();
        event.preventDefault();
        navigator.clipboard.writeText(url())

        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleClickOnShareButton = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseShareButton = () => {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Card className={classes.root} raised={true}>
            <CardActionArea
                className={classes.cardActionArea}
                onClick={event => takeId === 14
                    ? item.active === "Y" && handleTransitionToActionDetails(event)
                    : handleTransitionToActionDetails(event)
                }
            >
                <CardHeader
                    style={{minHeight: 120, display: 'block'}}
                    component="div"
                    title={item.name && item.name.length > 0 ? item.name : item.testNames.split(',\\\\').join('')}
                    subheader={takeId === 14
                        ? item.active === "Y" ? `${item.price} грн` : 'Акція завершена.'
                        : `${item.price} грн`
                    }
                />
                <CardMedia
                    className={classes.media}
                    image={
                        !!item.fileName
                            ? `https://mlis.medis.com.ua/modules/bi_action/files/${item.fileName}`
                            : imgUrl
                    }
                    title="Натисніть на акцію щоб переглянути детальнішу інформацію."
                />
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="div">
                        <div className="holovna" dangerouslySetInnerHTML={{__html: item.note}}/>
                    </Typography>
                </CardContent>
            </CardActionArea>
            {takeId === 14
                ? item.active === "Y" && <CardActions disableSpacing>
                {/*<IconButton>*/}
                {/*    <FavoriteIcon/>*/}
                {/*</IconButton>*/}

                {/*<Tooltip title="Натисніть щоб поширити акцію." arrow>*/}
                {/*    <IconButton*/}
                {/*        onClick={event => handleClickOnShareButton(event)}*/}
                {/*        aria-describedby={id}*/}
                {/*    >*/}
                {/*        <ShareIcon/>*/}
                {/*    </IconButton>*/}
                {/*</Tooltip>*/}
                <Popover
                    container={document.getElementById("root")}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleCloseShareButton}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div className={classes.popoverInnerWrapper}>
                        <Tooltip title="Натисніть щоб скопіювати посилання на акцію." arrow>
                            <button
                                onClick={event => handleCopyHrefToAction(event)}
                                className={classes.socialMediaShareButtons}

                            >
                                <FileCopyOutlinedIcon className={classes.socialMediaShareButtonsImage}/>
                                <span className={classes.socialMediaShareButtonsInnerTouch}/>
                            </button>
                        </Tooltip>

                        <Tooltip title="Натисніть щоб надіслати акцію на e-mail." arrow>
                            <EmailShareButton
                                className={classes.socialMediaShareButtons}
                                url={url()}
                            >
                                <Email className={classes.socialMediaShareButtonsImage}/>
                                <span className={classes.socialMediaShareButtonsInnerTouch}/>
                            </EmailShareButton>
                        </Tooltip>

                        <Tooltip title="Натисніть щоб надіслати акцію в Facebook." arrow>
                            <FacebookShareButton
                                className={classes.socialMediaShareButtons}
                                url={url()}
                            >
                                <Facebook className={classes.socialMediaShareButtonsImage}/>
                                <span className={classes.socialMediaShareButtonsInnerTouch}/>
                            </FacebookShareButton>
                        </Tooltip>

                        <Tooltip title="Натисніть щоб надіслати акцію в Instagram." arrow>
                            <InstapaperShareButton
                                className={classes.socialMediaShareButtons}
                                url={url()}
                            >
                                <Instagram className={classes.socialMediaShareButtonsImage}/>
                                <span className={classes.socialMediaShareButtonsInnerTouch}/>
                            </InstapaperShareButton>
                        </Tooltip>

                        <Tooltip title="Натисніть щоб надіслати акцію в LinkedIn." arrow>
                            <LinkedinShareButton
                                className={classes.socialMediaShareButtons}
                                url={url()}
                            >
                                <LinkedIn className={classes.socialMediaShareButtonsImage}/>
                                <span className={classes.socialMediaShareButtonsInnerTouch}/>
                            </LinkedinShareButton>
                        </Tooltip>

                        <Tooltip title="Натисніть щоб надіслати акцію в Telegram." arrow>
                            <TelegramShareButton
                                className={classes.socialMediaShareButtons}
                                url={url()}
                            >
                                <Telegram className={classes.socialMediaShareButtonsImage}/>
                                <span className={classes.socialMediaShareButtonsInnerTouch}/>
                            </TelegramShareButton>
                        </Tooltip>

                        <Tooltip title="Натисніть щоб надіслати акцію в Twitter." arrow>
                            <TwitterShareButton
                                className={classes.socialMediaShareButtons}
                                url={url()}
                            >
                                <Twitter className={classes.socialMediaShareButtonsImage}/>
                                <span className={classes.socialMediaShareButtonsInnerTouch}/>
                            </TwitterShareButton>
                        </Tooltip>

                        <Tooltip title="Натисніть щоб надіслати акцію в Viber." arrow>
                            <ViberShareButton
                                className={classes.socialMediaShareButtons}
                                url={url()}
                            >
                                <img src={Viber} className={classes.socialMediaShareButtonsImage} alt="Viber icon."/>
                                <span className={classes.socialMediaShareButtonsInnerTouch}/>
                            </ViberShareButton>
                        </Tooltip>

                        <Tooltip title="Натисніть щоб надіслати акцію в WhatsApp." arrow>
                            <WhatsappShareButton
                                className={classes.socialMediaShareButtons}
                                url={url()}
                            >
                                <WhatsApp className={classes.socialMediaShareButtonsImage}/>
                                <span className={classes.socialMediaShareButtonsInnerTouch}/>
                            </WhatsappShareButton>
                        </Tooltip>
                    </div>
                </Popover>
                <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="success">
                        Посилання
                        {' ' + url() + ' '}
                        успішно скопійовано.
                    </Alert>
                </Snackbar>
                <div className={classes.addRemoveButton}>
                    <AddRemoveIconButtons
                        item={item}
                        isIncludedInOrderingList={isIncludedInOrderingList}
                        actionInOrderList={actionInOrderList}
                    />
                </div>
            </CardActions>
            : <CardActions disableSpacing>
                    {/*<IconButton>*/}
                    {/*    <FavoriteIcon/>*/}
                    {/*</IconButton>*/}

                    {/*<Tooltip title="Натисніть щоб поширити акцію." arrow>*/}
                    {/*    <IconButton*/}
                    {/*        onClick={event => handleClickOnShareButton(event)}*/}
                    {/*        aria-describedby={id}*/}
                    {/*    >*/}
                    {/*        <ShareIcon/>*/}
                    {/*    </IconButton>*/}
                    {/*</Tooltip>*/}
                    <Popover
                        container={document.getElementById("root")}
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleCloseShareButton}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div className={classes.popoverInnerWrapper}>
                            <Tooltip title="Натисніть щоб скопіювати посилання на акцію." arrow>
                                <button
                                    onClick={event => handleCopyHrefToAction(event)}
                                    className={classes.socialMediaShareButtons}

                                >
                                    <FileCopyOutlinedIcon className={classes.socialMediaShareButtonsImage}/>
                                    <span className={classes.socialMediaShareButtonsInnerTouch}/>
                                </button>
                            </Tooltip>

                            <Tooltip title="Натисніть щоб надіслати акцію на e-mail." arrow>
                                <EmailShareButton
                                    className={classes.socialMediaShareButtons}
                                    url={url()}
                                >
                                    <Email className={classes.socialMediaShareButtonsImage}/>
                                    <span className={classes.socialMediaShareButtonsInnerTouch}/>
                                </EmailShareButton>
                            </Tooltip>

                            <Tooltip title="Натисніть щоб надіслати акцію в Facebook." arrow>
                                <FacebookShareButton
                                    className={classes.socialMediaShareButtons}
                                    url={url()}
                                >
                                    <Facebook className={classes.socialMediaShareButtonsImage}/>
                                    <span className={classes.socialMediaShareButtonsInnerTouch}/>
                                </FacebookShareButton>
                            </Tooltip>

                            <Tooltip title="Натисніть щоб надіслати акцію в Instagram." arrow>
                                <InstapaperShareButton
                                    className={classes.socialMediaShareButtons}
                                    url={url()}
                                >
                                    <Instagram className={classes.socialMediaShareButtonsImage}/>
                                    <span className={classes.socialMediaShareButtonsInnerTouch}/>
                                </InstapaperShareButton>
                            </Tooltip>

                            <Tooltip title="Натисніть щоб надіслати акцію в LinkedIn." arrow>
                                <LinkedinShareButton
                                    className={classes.socialMediaShareButtons}
                                    url={url()}
                                >
                                    <LinkedIn className={classes.socialMediaShareButtonsImage}/>
                                    <span className={classes.socialMediaShareButtonsInnerTouch}/>
                                </LinkedinShareButton>
                            </Tooltip>

                            <Tooltip title="Натисніть щоб надіслати акцію в Telegram." arrow>
                                <TelegramShareButton
                                    className={classes.socialMediaShareButtons}
                                    url={url()}
                                >
                                    <Telegram className={classes.socialMediaShareButtonsImage}/>
                                    <span className={classes.socialMediaShareButtonsInnerTouch}/>
                                </TelegramShareButton>
                            </Tooltip>

                            <Tooltip title="Натисніть щоб надіслати акцію в Twitter." arrow>
                                <TwitterShareButton
                                    className={classes.socialMediaShareButtons}
                                    url={url()}
                                >
                                    <Twitter className={classes.socialMediaShareButtonsImage}/>
                                    <span className={classes.socialMediaShareButtonsInnerTouch}/>
                                </TwitterShareButton>
                            </Tooltip>

                            <Tooltip title="Натисніть щоб надіслати акцію в Viber." arrow>
                                <ViberShareButton
                                    className={classes.socialMediaShareButtons}
                                    url={url()}
                                >
                                    <img src={Viber} className={classes.socialMediaShareButtonsImage} alt="Viber icon."/>
                                    <span className={classes.socialMediaShareButtonsInnerTouch}/>
                                </ViberShareButton>
                            </Tooltip>

                            <Tooltip title="Натисніть щоб надіслати акцію в WhatsApp." arrow>
                                <WhatsappShareButton
                                    className={classes.socialMediaShareButtons}
                                    url={url()}
                                >
                                    <WhatsApp className={classes.socialMediaShareButtonsImage}/>
                                    <span className={classes.socialMediaShareButtonsInnerTouch}/>
                                </WhatsappShareButton>
                            </Tooltip>
                        </div>
                    </Popover>
                    <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                        <Alert onClose={handleCloseSnackbar} severity="success">
                            Посилання
                            {' ' + url() + ' '}
                            успішно скопійовано.
                        </Alert>
                    </Snackbar>
                    <div className={classes.addRemoveButton}>
                        {<AddRemoveIconButtons
                            item={item}
                            isIncludedInOrderingList={isIncludedInOrderingList}
                            actionInOrderList={actionInOrderList}
                        />}
                    </div>
                </CardActions>}
        </Card>
    );
};

export default MediaCard;
