import React, {createContext, useCallback, useEffect, useState} from 'react';
import useStyles from "./styles";
import Loading from "../../components/Shared/Loading";
import {setCreatePatient, setListOfPatients, START_PHONE_SEND} from "@cabinet-packages/redux";
import uuidV4 from 'uuid/v4';
import {useDispatch, useSelector} from "react-redux";
import FirstStage from "./stages/FirstStage";
import ThirdStage from "./stages/ThirdStage";
import FifthStage from "./stages/FifthStage";
import EighthStage from "./stages/EighthStage";
import {Switch} from "@material-ui/core";
import NewPatientInOneStep from "./components/NewPatientInOneStep";
import {AccessKey, handleAuthorization} from "@cabinet-packages/helpers/getSetAccessKey";
import Button from "@material-ui/core/Button";
import PatientTabs from "../PatientTabs";
import {checkPatient} from "@cabinet-packages/api/api";
import DiaAuthorization from "./components/DiaAuthorization";
import DiaAuthorizationPatientsView from "./components/DiaAuthorizationPatientsView";
import {FormattedMessage} from "react-intl";

export const AuthorizationContext = createContext(null);

const PhoneEnterPage = () => {
    const cl = useStyles();
    const isDoctor = false;
    const dispatch = useDispatch();
    const [isChangedState, setIsChangedState] = useState(true);
    const [disableConfirmSMS, setDisableConfirmSMS] = useState(true);
    const [phone, setPhone] = useState('');
    const [value, setValue] = useState('');
    const [uuid] = useState(uuidV4());
    const {stage, error, createPatient, activePatient, listOfPatients} = useSelector(state => state.patients); //here
    const [state, setState] = useState(null);
    const [isSetPhone, setIsSetPhone] = useState(true);
    const [isChangedStateSignUp, setIsChangedStateSignUp] = useState(true);
    const [isSetActivePatient, setIsSetActivePatient] = useState(false);
    const [checkboxState, setCheckboxState] = useState({
        checkedA: false,
        checkedB: false,
    });
    const [valueActivePatient, setValueActivePatient] = useState(null);
    const [valueActivePatientFormControl, setValueActivePatientFormControl] = useState(null);
    const [checked, setChecked] = useState(false);
    const [authorized, setAuthorized] = useState(null);
    const activePatientLocalStorage = localStorage.getItem('activePatient');
    const activePatientPhoneLocalStorage = localStorage.getItem('activePatientPhone');
    const uuidLocalStorage = localStorage.getItem('uuid');
    const [patientDataFromDia, setPatientDataFromDia] = useState(null);

    const toggleChecked = () => {
        setChecked((prev) => !prev);
    };

    const handleClose = () => {
        setAuthorized(true);
    };

    const handleUnauthorized = () => {
        handleAuthorization(true);
        setAuthorized(false);
    }

    const handleGetPatientsIfAuthorize = useCallback(async () => {
        if (handleAuthorization()) {
            const listOfPatients = await checkPatient('CHECK', uuidLocalStorage, null, activePatientPhoneLocalStorage, AccessKey.get());

            if (listOfPatients && listOfPatients !== 'error') {
                dispatch(setListOfPatients(false, false, listOfPatients));
            }
        }
    }, []);

    const updateState = useCallback((field, value) => {
        setState(prev => ({
            ...prev,
            [field]: value,
        }));
        dispatch(setCreatePatient(state));
    },[dispatch, state]);

    const handleCloseAfterEnter = useCallback(() => {
        if (stage === 6 && !checked) {
            handleClose();
            dispatch({
                type: START_PHONE_SEND,
                payload: {
                    stage : 1,
                },
            });
        }
    },[stage, checked]);

    //effect for stages
    useEffect(() => {
        if (!checked) {
            if (uuid !== localStorage.getItem('uuid')) {
                localStorage.setItem('uuid', `${uuid}`);
            }
            if (state) {
                const {firstname, lastname, fathername, birthday, email} = state;
                if (firstname.length && lastname.length && fathername.length && email.length && email.includes('@') && email.includes('.')) {
                    setIsChangedStateSignUp(false)
                } else {
                    setIsChangedStateSignUp(true)
                }
            }
            if (!valueActivePatientFormControl && listOfPatients && listOfPatients.length > 0) {
                setValueActivePatientFormControl(listOfPatients[0].id);
            }
            if (valueActivePatient) {
                const {firstname, lastname, fathername, birthday} = valueActivePatient;

                if (firstname && fathername && lastname && firstname.length && fathername.length && lastname.length) {
                    setIsChangedStateSignUp(false)
                } else {
                    setIsChangedStateSignUp(true)
                }
            }
            if (phone && +phone.length === 19) {
                setIsChangedState(false)
            } else {
                setIsChangedState(true)
            }
            if (value && +value.length === 4 && value === value.replace(/\D/g, '').trim()) {
                setDisableConfirmSMS(false)
            } else {
                setDisableConfirmSMS(true)
            }
            if (activePatient && !isSetActivePatient) {
                dispatch(setCreatePatient(activePatient));
                setState(activePatient);
                setIsSetActivePatient(true);
            }
            if (!activePatient && !createPatient) dispatch(setCreatePatient());
            if (!state && createPatient) setState({
                ...createPatient,
                dt: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
                birthday: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
            });
            if (phone && stage === 5 && isSetPhone) {
                updateState('phone', phone);
                setIsSetPhone(false);
            }
            if (listOfPatients && listOfPatients.length > 0 && !valueActivePatient) {
                setValueActivePatient({...listOfPatients[0], birthday: new Date(listOfPatients[0].birthday)});
            }
        }
    }, [phone, value, dispatch, createPatient, isSetPhone, error, stage, state, valueActivePatient,
        isChangedStateSignUp, listOfPatients, updateState, checked]);

    useEffect(() => {
        handleCloseAfterEnter();
    }, [handleCloseAfterEnter]);

    //effect for NewPatientInOneStep
    useEffect(() => {
        if (state && checked) {
            const {firstname, lastname, fathername, email} = state;
            if (firstname.length && lastname.length && fathername.length && email.length && email.includes('@')
                && email.includes('.') && phone && +phone.length === 19) {
                setIsChangedStateSignUp(false)
            } else {
                setIsChangedStateSignUp(true)
            }
            if (value && +value.length === 4 && value === value.replace(/\D/g, '').trim()) {
                setDisableConfirmSMS(false)
            } else {
                setDisableConfirmSMS(true)
            }
        }
    }, [state, checked, phone]);

    useEffect(() => {
        setAuthorized(handleAuthorization());
    }, [activePatientLocalStorage]);

    useEffect(() => {
        if (handleAuthorization() && !listOfPatients) {
            handleAuthorization(true);
        }
    }, []);

    useEffect(() => {
        handleGetPatientsIfAuthorize();
    }, [handleGetPatientsIfAuthorize]);

    if (stage === 2 || stage === 4) {
        return (<div>
            <div className={cl.DialogContent}>
                <div style={{marginBottom: '10px'}}>
                    <Loading/>
                </div>
            </div>
        </div>);
    }

    return (<AuthorizationContext.Provider value={{usePatientDataFromDia: [patientDataFromDia, setPatientDataFromDia]}}>
        {patientDataFromDia
            ? <DiaAuthorizationPatientsView/>
            : authorized
                ? <div className={cl.patientTabsWrapper}>
                    <PatientTabs/>
                    <div className={cl.phoneConfirmationButton}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleUnauthorized}
                        >
                            <FormattedMessage id="formatted.message.phoneEnterPage.exitAndChange.text"
                                              defaultMessage="вийти та змінити користувача"/>
                        </Button>
                    </div>
                </div>
                : (authorized === false && <div className={cl.DialogContent}>
                    <div className={cl.catalogSwitchControl}>
                        <label htmlFor="toggleChecked" className={cl.catalogSwitchControlLabel}>
                            <FormattedMessage id="formatted.message.phoneEnterPage.constantPatient.text"
                                              defaultMessage="Я ПОСТІЙНИЙ ПАЦІЄНТ"/>
                        </label>
                        <Switch id="toggleChecked" color="primary" checked={checked} onChange={toggleChecked}/>
                        <label htmlFor="toggleChecked" className={cl.catalogSwitchControlLabel}>
                            <FormattedMessage id="formatted.message.phoneEnterPage.newPatient.text"
                                              defaultMessage="НОВИЙ ПАЦІЄНТ"/>
                        </label>
                    </div>
                    {checked
                        ? <NewPatientInOneStep state={state} updateState={updateState} usePhone={[phone, setPhone]}
                                               handleCheckboxState={[checkboxState, setCheckboxState]}
                                               isChangedStateSignUp={isChangedStateSignUp} uuid={uuid}
                                               handleValue={[value, setValue]} isDoctor={isDoctor} handleClose={handleClose}

                        />
                        : <div className={cl.phoneConfirmation}>
                            {error && (!stage || stage === 1) && <>{error === 'errorWhenSendPhone'
                                ? <div className={cl.errorCodeTitle}><h4>
                                    <FormattedMessage id="formatted.message.phoneEnterPage.repeat.text"
                                                      defaultMessage="Повторіть спробу"/>

                                </h4></div>
                                : error === 'User not found'
                                    ? <div className={cl.errorCodeTitle}><h4>
                                        <FormattedMessage id="formatted.message.phoneEnterPage.userNotFound.text"
                                                          defaultMessage="Користувача не знайдено."/>

                                    </h4></div>
                                    : <div className={cl.errorCodeTitle}><h4>
                                        <FormattedMessage id="formatted.message.phoneEnterPage.badCode.text"
                                                          defaultMessage="Код введено неправильно."/>
                                    </h4></div>}</>
                            }

                            {((stage && stage === 1) || !stage) &&
                                <FirstStage usePhone={[phone, setPhone]} isChangedState={isChangedState}
                                            setValue={setValue} uuid={uuid}
                                />
                            }

                            {stage && stage === 3 &&
                                <ThirdStage handleValue={[value, setValue]} handlePhone={[phone, setPhone]}
                                            isDoctor={isDoctor} disableConfirmSMS={disableConfirmSMS}
                                            uuid={uuid}
                                />
                            }
                            {stage && state && stage === 5 &&
                                <FifthStage isDoctor={isDoctor} state={state} uuid={uuid}
                                            isChangedStateSignUp={isChangedStateSignUp} updateState={updateState}
                                />
                            }
                            {stage && state && stage === 8 &&
                                <EighthStage isDoctor={isDoctor} listOfPatients={listOfPatients}
                                             handleValueActivePatient={[valueActivePatient, setValueActivePatient]}
                                             handleValueActivePatientFormControl={[valueActivePatientFormControl, setValueActivePatientFormControl]}
                                             handleCheckboxState={[checkboxState, setCheckboxState]} uuid={uuid}
                                             isChangedStateSignUp={isChangedStateSignUp} phone={phone}
                                />
                            }
                        </div>}
                    <DiaAuthorization/>
                </div>)
        }
    </AuthorizationContext.Provider>);
}

export default PhoneEnterPage;
