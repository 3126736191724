import getAmountOfLines from './getAmountOfLines';

export default function getAssayRowHeight(index, width, filteredState) {
  const name = filteredState[index].name;
  width = width - 35;
  const symbolWidth = 11;
  const lineHeight = 22;
  let bottomLineHeight = 74;
  if (filteredState[index].sitename) bottomLineHeight = 20;
  return getAmountOfLines(width, name, symbolWidth) * lineHeight + bottomLineHeight;
}
