import React, {useCallback, useEffect, useState} from 'react';
import useStyles from "./styles";
import Loading from "../../components/Shared/Loading";
import {setClient, setCreatePatient, setListOfPatients, START_PHONE_SEND} from "@cabinet-packages/redux";
import uuidV4 from 'uuid/v4';
import {useDispatch, useSelector} from "react-redux";
import FirstStage from "./stages/FirstStage";
import ThirdStage from "./stages/ThirdStage";
import FifthStage from "./stages/FifthStage";
import EighthStage from "./stages/EighthStage";
import {Avatar, Switch, Typography, withStyles} from "@material-ui/core";
import NewPatientInOneStep from "./components/NewPatientInOneStep";
import {AccessKey, handleAuthorization} from "@cabinet-packages/helpers/getSetAccessKey";
import Button from "@material-ui/core/Button";
import {getPatients} from "@cabinet-packages/api";
import {useHistory, useLocation} from "react-router";

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,

            '& + $track': {
                backgroundColor: theme.palette.grey[50],
            },
        },
    },
    thumb: {
        margin: '1px',
        width: 22,
        height: 22,
        color: theme.palette.primary.main,
    },
    track: {
        borderRadius: 26 / 2,
        border: `2px solid ${theme.palette.green.dark}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 0.5,
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const PhoneEnterPageMedis = () => {
    const cl = useStyles();
    const isDoctor = false;
    const dispatch = useDispatch();
    const history = useHistory()
    const location = useLocation()
    const [isChangedState, setIsChangedState] = useState(true);
    const [disableConfirmSMS, setDisableConfirmSMS] = useState(true);
    const [phone, setPhone] = useState('');
    const [value, setValue] = useState('');
    const [uuid] = useState(uuidV4());
    const {stage, error, createPatient, activePatient, listOfPatients} = useSelector(state => state.patients); //here
    const [state, setState] = useState(null);
    const [isSetPhone, setIsSetPhone] = useState(true);
    const [isChangedStateSignUp, setIsChangedStateSignUp] = useState(true);
    const [isSetActivePatient, setIsSetActivePatient] = useState(false);
    const [checkboxState, setCheckboxState] = useState({
        checkedA: false,
        checkedB: false,
    });
    const [valueActivePatient, setValueActivePatient] = useState(null);
    const [valueActivePatientFormControl, setValueActivePatientFormControl] = useState(null);
    const [checked, setChecked] = useState(false);
    const [authorized, setAuthorized] = useState(null);
    const disableAdminSwitch = new URLSearchParams(location.search).get("newPatient")
    const isMedis = process.env.REACT_APP_CURRENT_CLINIC === "MEDIS"

    useEffect(() => {
        if(disableAdminSwitch){
            setChecked((prev) => !prev);
        }
    }, [])

    const toggleChecked = () => {
        setChecked((prev) => !prev);
    };

    const handleClose = () => {
        setAuthorized(true);
        history.push('wizard')
    };

    const handleUnauthorized = () => {
        handleAuthorization(true);
        setAuthorized(false);
        dispatch(setCreatePatient());
        dispatch(setClient(null));
        setValueActivePatient(null);
        setValueActivePatientFormControl(null);
        setCheckboxState({checkedA: false, checkedB: false,});
        setState(null);
        setPhone('')
    }

    const updateState = useCallback((field, value) => {
        setState(prev => ({
            ...prev,
            [field]: value,
        }));
        dispatch(setCreatePatient(state));
    },[dispatch, state]);

    const handleCloseAfterEnter = useCallback(() => {
        if (stage === 6 && !checked) {
            handleClose();
            setPhone('');
            setValue('');
            dispatch({
                type: START_PHONE_SEND,
                payload: {
                    stage : 1,
                },
            });
        }
    },[stage, checked]);

    //effect for stages
    useEffect(() => {
        if (!checked) {
            if (uuid !== localStorage.getItem('uuid')) {
                localStorage.setItem('uuid', `${uuid}`);
            }
            if (state) {
                const {firstname, lastname, fathername, birthday, email} = state;
                if (firstname.length && lastname.length && fathername.length && email.length && email.includes('@') && email.includes('.')) {
                    setIsChangedStateSignUp(false)
                } else {
                    setIsChangedStateSignUp(true)
                }
            }
            if (!valueActivePatientFormControl && listOfPatients && listOfPatients.length > 0) {
                setValueActivePatientFormControl(listOfPatients[0].id);
            }
            if (valueActivePatient) {
                const {firstname, lastname, fathername, birthday} = valueActivePatient;

                if (firstname && fathername && lastname && firstname.length && fathername.length && lastname.length) {
                    setIsChangedStateSignUp(false)
                } else {
                    setIsChangedStateSignUp(true)
                }
            }
            if (phone && +phone.length === 19) {
                setIsChangedState(false)
            } else {
                setIsChangedState(true)
            }
            if (value && +value.length === 4 && value === value.replace(/\D/g, '').trim()) {
                setDisableConfirmSMS(false)
            } else {
                setDisableConfirmSMS(true)
            }
            if (activePatient && !isSetActivePatient) {
                dispatch(setCreatePatient(activePatient));
                setState(activePatient);
                setIsSetActivePatient(true);
            }
            if (!activePatient && !createPatient) dispatch(setCreatePatient());
            if (!state && createPatient) setState({
                ...createPatient,
                dt: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
                birthday: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
            });
            if (phone && stage === 5 && isSetPhone) {
                updateState('phone', phone);
                setIsSetPhone(false);
            }
            if (listOfPatients && listOfPatients.length > 0 && !valueActivePatient) {
                setValueActivePatient({...listOfPatients[0], birthday: new Date(listOfPatients[0].birthday)});
            }
        }
    }, [phone, value, dispatch, createPatient, isSetPhone, error, stage, state, valueActivePatient,
        isChangedStateSignUp, listOfPatients, updateState, checked]);

    useEffect(() => {
        handleCloseAfterEnter();
    }, [handleCloseAfterEnter]);

    //effect for NewPatientInOneStep
    useEffect(() => {
        if (state && checked) {
            const {firstname, lastname, fathername, email} = state;
            if (firstname.length && lastname.length && fathername.length && email.length && email.includes('@')
                && email.includes('.') && phone && +phone.length === 19) {
                setIsChangedStateSignUp(false)
            } else {
                setIsChangedStateSignUp(true)
            }
            if (value && +value.length === 4 && value === value.replace(/\D/g, '').trim()) {
                setDisableConfirmSMS(false)
            } else {
                setDisableConfirmSMS(true)
            }
        } else if (!state && createPatient) {
            setState({
                ...createPatient,
                dt: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
                birthday: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
            });
        }
    }, [state, checked, phone]);

    useEffect(() => {
        setAuthorized(handleAuthorization());
    }, []);

    const handleGetPatients = useCallback(async () => {
        if (!listOfPatients && AccessKey.get()) {
            const newListOfPatients = await getPatients();

            dispatch(setListOfPatients(false, false, newListOfPatients));
        }
    }, [dispatch, listOfPatients]);

    useEffect(() => {
        handleGetPatients();
    }, [handleGetPatients]);

    if (stage === 2 || stage === 4) {
        return <div>
            <div className={cl.DialogContent}>
                <div style={{marginBottom: '10px'}}>
                    <Loading/>
                </div>
            </div>
        </div>
    }

    return (authorized
        ? <div>
            <div className={cl.phoneConfirmationButton}>
                {activePatient && <div className={cl.avatarWrapper}>
                    <Avatar className={cl.avatar} children={`${activePatient?.lastname[0]} ${activePatient?.firstname[0]}`}/>
                    <Typography variant={"h4"} color="inherit" className={cl.avatarText}>
                        {`${activePatient.lastname} ${activePatient.firstname}`}
                    </Typography>
                </div>}
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleUnauthorized}
                >
                    вийти та змінити користувача
                </Button>
            </div>
        </div>
        : (authorized === false && <div className={cl.DialogContent}>
            <div className={cl.catalogSwitchControl}>
                <label htmlFor="toggleChecked" className={cl.catalogSwitchControlLabel}
                       style={checked ? {transition: '.3s all'} : {color: isMedis ? '#00B3A1' : '#0077C8', transition: '.3s all'}}>
                    Я ПОСТІЙНИЙ ПАЦІЄНТ
                </label>
                <IOSSwitch id="toggleChecked" color="primary" checked={checked} disabled={!!disableAdminSwitch} onChange={toggleChecked}/>
                <label htmlFor="toggleChecked" className={cl.catalogSwitchControlLabel}
                       style={checked ? {color: isMedis ? '#00B3A1' : '#0077C8', transition: '.3s all'} : {transition: '.3s all'}}>
                    НОВИЙ ПАЦІЄНТ
                </label>
            </div>
            {checked
                ? <NewPatientInOneStep state={state} updateState={updateState} usePhone={[phone, setPhone]}
                                       handleCheckboxState={[checkboxState, setCheckboxState]}
                                       isChangedStateSignUp={isChangedStateSignUp} uuid={uuid}
                                       handleValue={[value, setValue]} isDoctor={isDoctor} handleClose={handleClose}
                                       isChangedState={isChangedState}

                />
                : <div className={cl.phoneConfirmation}>
                    {error && (!stage || stage === 1) && <>{error === 'errorWhenSendPhone'
                        ? <div className={cl.errorCodeTitle}><h4>Повторіть спробу.</h4></div>
                        : error === 'User not found'
                            ? <div className={cl.errorCodeTitle}><h4>Користувача не знайдено.</h4></div>
                            : <div className={cl.errorCodeTitle}><h4>Код введено неправильно.</h4></div>}</>
                    }

                    {((stage && stage === 1) || !stage) &&
                    <FirstStage usePhone={[phone, setPhone]} isChangedState={isChangedState}
                                setValue={setValue} uuid={uuid}
                    />
                    }

                    {stage && stage === 3 &&
                    <ThirdStage handleValue={[value, setValue]} handlePhone={[phone, setPhone]}
                                isDoctor={isDoctor} disableConfirmSMS={disableConfirmSMS}
                                uuid={uuid} isChangedState={isChangedState}
                    />
                    }
                    {stage && state && stage === 5 &&
                    <FifthStage isDoctor={isDoctor} state={state} uuid={uuid}
                                isChangedStateSignUp={isChangedStateSignUp} updateState={updateState}
                    />
                    }
                    {stage && state && stage === 8 &&
                    <EighthStage isDoctor={isDoctor} listOfPatients={listOfPatients}
                                 handleValueActivePatient={[valueActivePatient, setValueActivePatient]}
                                 handleValueActivePatientFormControl={[valueActivePatientFormControl, setValueActivePatientFormControl]}
                                 handleCheckboxState={[checkboxState, setCheckboxState]} uuid={uuid}
                                 isChangedStateSignUp={isChangedStateSignUp} phone={phone}
                    />
                    }
                </div>}
        </div>)
    );
}

export default PhoneEnterPageMedis;
