import React from "react";
import {LinearProgress, makeStyles} from "@material-ui/core";
import {useSelector} from "react-redux";
import {Scroll} from '@cabinet-packages/components';
import CircularMenu from "../components/Shop/CircularMenu";
import MenuMobile from "../components/Shop/MenuMobile";

const useStyles = makeStyles(theme => ({
    loader: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        textAlign: 'center',
    },
    circularMenuWrapper: {
        width: '100%',
        height: '100%',
        minHeight: '520px',
        position: 'relative',
        margin: '0 auto',
        overflow: 'visible',
        '@media (max-width: 1150px)': {
            display: 'none',
        },
    },
    circularMenuWrapperMobile: {
        display: 'none',
        '@media (max-width: 1150px)': {
            display: 'block',
            width: '100%',
            height: '100%',
        },
    },
}));

const CatalogTable = () => {
    const classes = useStyles();
    const {catalog} = useSelector(state => state.assaysCatalog);

    return (<Scroll>
        <div className={classes.circularMenuWrapper}>
            <CircularMenu/>
        </div>
        <div className={classes.circularMenuWrapperMobile}>
            <MenuMobile/>
        </div>
        {!catalog
            ? <div className={classes.loader}>
                <LinearProgress/>
            </div>
            : typeof catalog === "string" && <div className={classes.loader}>Вибачте, сталась помилка під час завантаження.</div>
        }
    </Scroll>);
};

export default CatalogTable;
