import React, {useEffect, useState} from 'react';
import {Prompt, useHistory} from 'react-router-dom';
import {
    Avatar,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import EditIcon from '@material-ui/icons/Edit';
import {setClient, setListOfPatients} from '@cabinet-packages/redux';
import {ConfirmDialog, CustomDialog, Loading, QRCodeDialog, Scroll} from '@cabinet-packages/components';
import {editPatientInfo} from "@cabinet-packages/api";
import DateFnsUtils from "@date-io/date-fns";
import uaLocale from 'date-fns/locale/uk';
import TextAlertDialog from "../components/Dialogs/TextAlertDialog";

const useStyles = makeStyles(theme =>({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    width: '100%',
    maxWidth: '900px',
  },
  scrollWrapper: {
    position: 'relative',
    maxHeight: '100%',
    width: '100%',
    height: '100%',
  },
  bodyContainer: {
    maxHeight: '100%',
    padding: '20px 10px',
    display: 'grid',
    width: '100%',
    gridGap: '15px',
  },
  avatarQRCodeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width:700px)': {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '0 10px',
    },
  },
  QRCodeContainer: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    backgroundColor: theme.palette.primary[100],
    width: '160px',
    height: '160px',
    fontSize: '3rem',
    '@media (max-width:700px)': {
      marginBottom: '20px',
    },
  },
  genderGroup: {
    flexDirection: 'row',
  },
  actionContainer: {
    padding: '20px 10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  birthdayContainer: {
    padding: '4px 0',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr',
    gridGap: '5px',
  },
  editBtnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width:700px)': {
      padding: '0px 8px'
    },
  },
  noData: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'center',
  },
  mainActionButton: {
    width: '100%',
    maxWidth: '300px',
    margin: '8px',
    '@media (max-width:700px)': {
      margin: '8px auto'
    },
  }
}));

function PatientCard() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { listOfPatients } = useSelector(state => state.patients);
  const {themeMaterialUI} = useSelector(store => store.theme);
  const idFromPathname = +history.location.pathname.split('/').pop();
  const foundPatient = listOfPatients && typeof listOfPatients !== 'string' && listOfPatients.find(currPatient => (+currPatient.id === idFromPathname));
  const [isChangedState, setIsChangedState] = useState(false);
  const [state, setState] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const handleEditing = (e) => {
    e.persist();
    // CustomDialog(TextAlertDialog, {
    //   text: 'На жаль, редагування даних пацієнта тимчасово недоступне. ' +
    //       'Якщо Вам потрібно замовити дослідження для іншої особи - додайте нового пацієнта, ' +
    //       'або зверніться на гарячу лінію.'
    // }, e, themeMaterialUI);
    setIsEditing(value => !value)
  };

  const updateState = (field, value) => {
    if (!isChangedState) setIsChangedState(true);
    setState(prev => ({
          ...prev,
          [field]: value,
        }
    ));
  };

  const handleCancel = (event) => {
    if (event) event.preventDefault();
    if (isChangedState) {
      CustomDialog(ConfirmDialog, {
        text: 'Відмінити редагування?',
        onConfirm: () => {
          setIsChangedState(false);
          setState(foundPatient);
        },
      }, event, themeMaterialUI);
    } else {
      setIsEditing(false);
    }
  };

  const handleConfirm = (event) => {
    CustomDialog(ConfirmDialog, {
      text: 'Зберегти дані?',
      onConfirm: async () => {
        setIsChangedState(false);
        const {lastname, firstname, fathername, birthday, sex, id} = state;
        const birthdayDate = typeof birthday === 'string'
            ? birthday
            : new Date(birthday.toString()).toLocaleDateString().split('.').reverse().join('-');
        await editPatientInfo(lastname, firstname, fathername, birthdayDate, sex, id);
        dispatch(setClient({...state, birthday: birthdayDate}));
        dispatch(setListOfPatients(true));
        setIsEditing(false);
        history.push('/account-settings');
      },
      onCancel: () => setIsEditing(true),
    }, event, themeMaterialUI);
  };

  const showQRCode = (event) => {
    CustomDialog(QRCodeDialog, {
      QRCodeValue: `1234567809012345678901235${state.id}`,
    }, event, themeMaterialUI);
  };

  const handleClickOnRegisterNewPatient = (event) => {
    event.stopPropagation();
    event.preventDefault();

    history.push('/add-new-patient');
  };

  useEffect(() => {
    if (!listOfPatients) dispatch(setListOfPatients());

    if (foundPatient && !state) setState({...foundPatient, dt: foundPatient.birthday});

  },[dispatch, listOfPatients, state, foundPatient]);

  return (!listOfPatients
      ? <div className={classes.noData}><Loading/></div>
      : !foundPatient
          ? <div className={classes.noData}>Дані не доступні</div>
          : <div className={classes.root}>
            <div className={classes.editBtnContainer}>
              {/*<Button*/}
              {/*    className={classes.mainActionButton}*/}
              {/*    color="primary"*/}
              {/*    variant="outlined"*/}
              {/*    size="small"*/}
              {/*    onClick={event => handleClickOnRegisterNewPatient(event)}*/}
              {/*>*/}
              {/*  Додати нового пацієнта*/}
              {/*</Button>*/}
              <Tooltip title="Натисніть, щоб редагувати дані пацієнта." arrow>
                <IconButton onClick={handleEditing}><EditIcon/></IconButton>
              </Tooltip>
            </div>
            <Scroll>
              <div className={classes.bodyContainer}>
                <div className={classes.avatarQRCodeContainer}>
                  <Avatar
                      className={classes.avatar}
                  >
                    {foundPatient && foundPatient.lastname[0]+foundPatient.firstname[0]}
                  </Avatar>
                  {/*<div className={classes.QRCodeContainer}>*/}
                  {/*  <Button onClick={showQRCode} variant="outlined">Показати QRCode пацієнта</Button>*/}
                  {/*</div>*/}
                </div>
                {isEditing
                    ? state && <>
                  <TextField
                      value={state.lastname}
                      onChange={(event) => updateState('lastname', event.target.value)}
                      label="Прізвище"
                  />
                  <TextField
                      value={state.firstname}
                      onChange={(event) => updateState('firstname', event.target.value)}
                      label="Ім'я"
                  />
                  <TextField
                      value={state.fathername}
                      onChange={(event) => updateState('fathername', event.target.value)}
                      label="По-батькові"
                  />
                  <div className={classes.birthdayContainer}>
                    <p style={{color: 'rgba(0, 0, 0, 0.54)'}}>Дата народження</p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={uaLocale}>
                      <KeyboardDatePicker
                          value={state.birthday}
                          onChange={(value) => updateState('birthday', value)}
                          invalidDateMessage="Неправильний формат дати."
                          maxDateMessage="Дата народження не може бути в майбутньому."
                          format="yyyy-MM-dd"
                          disableFuture
                          cancelLabel="Назад"
                          okLabel="Ок"
                          openTo="year"
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Стать</FormLabel>
                    <RadioGroup
                        value={state.sex}
                        onChange={(event) => updateState('sex', event.target.value)}
                        className={classes.genderGroup}>
                      <FormControlLabel
                          value="F"
                          control={<Radio color="primary"/>}
                          label="Жіноча"
                      />
                      <FormControlLabel
                          value="M"
                          control={<Radio color="primary"/>}
                          label="Чоловіча"
                      />
                    </RadioGroup>
                  </FormControl>
                </>
                    : state && <List disablePadding>
                  <ListItem divider>
                    <ListItemText
                        primary="Номер телефону"
                        secondary={state.phone}
                    />
                  </ListItem>
                  <ListItem divider>
                    <ListItemText
                        primary="Прізвище"
                        secondary={state.lastname}
                    />
                  </ListItem>
                  <ListItem divider>
                    <ListItemText
                        primary="Ім'я"
                        secondary={state.firstname}
                    />
                  </ListItem>
                  <ListItem divider>
                    <ListItemText
                        primary="По-батькові"
                        secondary={state.fathername}
                    />
                  </ListItem>
                  <ListItem divider>
                    <ListItemText
                        primary="Дата народження"
                        secondary={state.dt}
                    />
                  </ListItem>
                  <ListItem divider>
                    <ListItemText
                        primary="Стать"
                        secondary={state.sex === 'M' ? 'Чоловіча' : 'Жіноча'}
                    />
                  </ListItem>
                  <ListItem divider>
                    <ListItemText
                        primary="Електронна пошта"
                        secondary={state.email}
                    />
                  </ListItem>
                </List>}
              </div>
            </Scroll>

            {isEditing &&
            <div className={classes.actionContainer}>
              <Button variant="outlined" color="secondary" onClick={(event) => handleCancel(event)}>
                {isChangedState ? 'Відмінити' : 'Повернутися'}
              </Button>
              <Button
                  variant="outlined"
                  color="primary"
                  onClick={(event) => handleConfirm(event)}
                  disabled={!isChangedState
                    || state.lastname.length === 0
                    || state.firstname.length === 0
                    || !state.birthday
                    || !(state.birthday && (state.birthday.length === 10 || !isNaN(state.birthday.valueOf())))
                  }
              >
                Зберегти
              </Button>
            </div>
            }
            <Prompt when={isChangedState} message={() => "Заврешити редагування без збереження?"}/>
          </div>);
}

export default PatientCard;
