import React from "react";
import {ReactComponent as Btt_radio} from "../../PatientTabs/assets/bttn_radio.svg";
import {ReactComponent as Btt_complex} from "../images/test-tube.svg";
import {ReactComponent as Btt_delete} from "../images/bttn_delete.svg";
import icn_info from "../images/icn_info_red.svg";
import {isIncludedInComplexResearch, isIncludedInOrderingList} from "@cabinet-packages/helpers";
import {convertPrice} from "../../PatientTabs/helpers/convertPrice";
import AddRemoveButton from "./AddRemoveButton";
import AssayPopover from "../components/AssayPopover";
import {IconButton} from "@material-ui/core";
import {setSelectedTags} from "@cabinet-packages/redux";
import ComplexFillings from "./ComplexFillings";

const RowRenderer = (props, filteredState, cl, complexResearch, orderingList, useSelectedTag, useCatalogForView,
                     dispatch, usePrevState, selectedTags, fullOrderData) => {
    const { key, index, style } = props;
    const element = filteredState[index];
    const [catalogForView, setCatalogForView] = useCatalogForView;
    const [selectedTag, setSelectedTag] = useSelectedTag;
    const [prevStateOfCatalog, setPrevStateOfCatalog] = usePrevState;

    const handleGoToIncludedComplexes = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!prevStateOfCatalog) {
            setPrevStateOfCatalog(({
                selectedTags,
                selectedTag,
                catalogForView
            }));

            const includedComplexes = complexResearch.reduce((acu, cur) => {
                if (cur && cur.filling && element.filling && !cur.notSale && cur.filling.find(fill => !!element.filling.find(itemFill => itemFill.id === fill.id))) {
                    return [...acu, cur];
                }
                if (element.id && cur.idGrTest && cur.idGrTest.find(grTest => +grTest.id === +element.id)) {
                    return [...acu, cur];
                }

                return acu;
            }, []);

            setCatalogForView([element, ...includedComplexes]);

            setSelectedTag('Усі');
            dispatch(setSelectedTags([]));
        } else {
            setSelectedTag(prevStateOfCatalog.selectedTag);
            dispatch(setSelectedTags(prevStateOfCatalog.selectedTags));
            setCatalogForView(prevStateOfCatalog.catalogForView);
            setPrevStateOfCatalog(null);
        }
    };

    return (<div className={cl.listItemWrapper} style={style} key={key+''+element.name}>
        <div className={cl.listItem} style={element.note || element.type === 'complex' ? {justifyContent: 'space-between'} : {}}>
            <div className={cl.listItemInnerWrapper} style={element.note || element.type === 'complex' ? {height: 'max-content', alignItems: 'center'} : {}}>
                <p className={cl.listItemName}>
                    <AssayPopover assay={element}
                                  orderingList={orderingList}/>
                </p>
                <div className={cl.listItemActionsWrapper}>
                    <div className={cl.listItemActions}>
                        <div className={cl.listItemActionsItem}>
                            {element.duration_day}
                        </div>
                        <div className={cl.listItemActionsItem}>
                            {complexResearch && isIncludedInComplexResearch(element, complexResearch)
                                ? <p className={cl.assayTerms}>
                                    <IconButton
                                        onClick={handleGoToIncludedComplexes}
                                        disabled={element.type === 'complex'}
                                    >
                                        {element.type === 'complex'
                                            ? <Btt_complex className={cl.btnComplex}/>
                                            : prevStateOfCatalog ? <Btt_delete className={cl.btnDelete}/> : <Btt_radio/>}
                                    </IconButton>
                                </p>
                                : <p className={cl.assayTerms}>-</p>
                            }
                        </div>
                        <div className={cl.listItemActionsItem}>
                            {convertPrice(element.price, 0, cl.listItemDiscountPrice)}
                        </div>
                        <div className={cl.listItemActionsItem}>
                            {!(fullOrderData && fullOrderData.order)
                                ? <AddRemoveButton item={element}
                                                   isIncludedInOrderingList={isIncludedInOrderingList(element, orderingList)}/>
                                : convertPrice(element.price, element.discount, cl.listItemDiscountPriceDiscount)
                            }
                        </div>
                    </div>
                </div>
            </div>
            {element.note && <div className={cl.listItemNote}>
                <img src={icn_info} alt="info icon." className={cl.listItemNoteImg}/>
                <div dangerouslySetInnerHTML={{__html: element.note}}/>
            </div>}
            <ComplexFillings element={element}/>
        </div>
    </div>);
}

export default RowRenderer;
