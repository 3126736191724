import React, {useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import logo from '../../assets/medis/medisLogoWithoutText.png';
import {AccessKey} from "@cabinet-packages/helpers";
import {clearOrderedAssays, setClient, START_PHONE_SEND} from "@cabinet-packages/redux";
import {useDispatch} from "react-redux";
import {validateOrder} from "@cabinet-packages/api/api";
import {useHistory} from "react-router";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
    },
    rootWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '50%',
        height: '100%',
        textAlign: 'center',
    },
    rootWrapperDatePicker: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '50%',
        height: '100%',
        textAlign: 'center',
    },
    actionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',

        '@media (max-width: 900px)': {
            flexDirection: 'column',
        },
    },
}));

const PortmoneSuccess = ({dataPickers, orderIdProp, address, date}) => {
    const classes = useStyles();
    const hrefLocation = window.location.origin;
    const dispatch = useDispatch();
    const history = useHistory();
    const [[idOrder, time]] = useState(history.location.search.split('&').map(item => {
        const index = item.indexOf('=');
        return item.slice(index+1).replace('%20', ' ');
    }));
    const [isSendValidation, setIsSendValidation] = useState(true);

    const handleClickOnButton = (event, href) => {
        event.stopPropagation();
        event.preventDefault();

        window.top.location.href = `${hrefLocation}${href}`
    };

    useEffect(()=>{
        localStorage.removeItem("basket")
    }, []);


    useEffect(() => {
        if (idOrder && time && isSendValidation) {
            (async ()=> await validateOrder(time, +idOrder).then(res=> {
                window.top.location.href = `${hrefLocation}/shop-portmone-success`
            }))()
            dispatch(clearOrderedAssays());
            setIsSendValidation(false);
        }
    },[idOrder, time])

    useEffect( () => {
        if (dataPickers && AccessKey.get()) {
            localStorage.removeItem('activePatient');
            localStorage.removeItem('accessKey');
            localStorage.removeItem('activePatientPhone');
            localStorage.removeItem('uuid');
            AccessKey.remove();
            dispatch(setClient(null));
            dispatch({
                type: START_PHONE_SEND,
                payload: {
                    stage : 1,
                },
            });
        }
    }, []);

    return (
        <div className={classes.root}>
            <div className={dataPickers ? classes.rootWrapperDatePicker : classes.rootWrapper}>
                {dataPickers
                    ?  <div>
                        <h2>Вітаємо! Запис створено: {`${address}, ${date}`}</h2>
                        <img src={logo} alt="Logo medis." style={{width: '100px', margin: '0 auto'}}/>
                        <h3> Ваш запис № <span>{orderIdProp && orderIdProp}</span></h3>
                        <p>
                            <br/>
                            До зустрічі у нашому відділенні!
                        </p>
                    </div>
                    : <div>
                        <h2> Дякуємо за замовлення!</h2>
                    <img src={logo} alt="Logo medis." style={{width: '100px', margin: '0 auto'}}/>
                    <p>
                    Інформація у Вас на електронній пошті.
                    Також деталі замовлення можна завантажити у Особистому кабінеті.
                    <br/>  До зустрічі у нашому відділенні!
                    </p></div>}

                {dataPickers
                    ? <div
                        className={classes.actionWrapper}
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={event => handleClickOnButton(event, '/')}
                        >
                            Повернутись на головну
                        </Button>
                    </div>
                    : <div className={classes.actionWrapper}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={event => handleClickOnButton(event, '/shop-about')}
                    >
                        Повернутись на головну
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={event => handleClickOnButton(event, '/account-settings')}
                    >
                        Особистий кабінет
                    </Button>
                </div>}
            </div>
        </div>)
};

export default PortmoneSuccess;
