import React, {useContext} from 'react';
import useStyles from "../styles";
import {AuthorizationContext} from "../index";
import {Avatar, List, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import Scroll from "esculab-leopolis-jazz-fest/src/components/Scroll";

const DiaAuthorizationPatientsView = () => {
    const cl = useStyles();
    const state = useContext(AuthorizationContext);
    const [patientDataFromDia, setPatientDataFromDia] = state.usePatientDataFromDia;

    return (<div className={cl.diaAuthorizationPatientView}>
        <List>
            <ListItem>
                <ListItemAvatar>
                    <Avatar
                        className={cl.avatar}
                    >
                        {`${patientDataFromDia.original.lastNameUA[0]} ${patientDataFromDia.original.firstNameUA[0]}`}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={<div className={cl.diaPatientView}>
                        <h3>Дані пацієта з Дії</h3>
                        <p><b>ПІБ:</b> {`${patientDataFromDia.original.lastNameUA} ${patientDataFromDia.original.firstNameUA} ${patientDataFromDia.original.middleNameUA}`}</p>
                        <p><b>English name:</b> {`${patientDataFromDia.original.lastNameEN} ${patientDataFromDia.original.firstNameEN}`}</p>
                        <p><b>Дата народження:</b> {`${patientDataFromDia.original.birthday}`}</p>
                        <p><b>Стать:</b> {`${patientDataFromDia.original.genderUA}`}</p>
                    </div>}
                />
                {/*<ListItemSecondaryAction>*/}
                {/*    <Link to={{*/}
                {/*        pathname: `/patient-card/${id}`,*/}
                {/*        state: { id },*/}
                {/*    }}>*/}
                {/*        {!inConfirmOrder && <Tooltip title="Натисніть щоб переглянути дані пацієнта." arrow>*/}
                {/*            <IconButton edge="end">*/}
                {/*                <VisibilityIcon/>*/}
                {/*            </IconButton>*/}
                {/*        </Tooltip>}*/}
                {/*    </Link>*/}
                {/*</ListItemSecondaryAction>*/}
            </ListItem>
            <Scroll>
                {patientDataFromDia.data && patientDataFromDia.data.length && patientDataFromDia.data.map((patient, idx) => {
                    return (<ListItem key={patient.id+'ourPatient'+idx}>
                        <ListItemAvatar>
                            <Avatar
                                className={cl.avatar}
                            >
                                {`${patient.lastname[0]} ${patient.firstname[0]}`}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<div className={cl.diaOurPatientView}>
                                <p><b>ПІБ:</b> {`${patient.lastname} ${patient.firstname} ${patient.fathername}`}</p>
                                <p><b>Дата народження:</b> {`${patient.dt}`}</p>
                                <p><b>ID пацієнта:</b> {`${patient.id}`}</p>
                            </div>}
                            // secondary={
                            //     <React.Fragment>
                            //         <Typography
                            //             component="span"
                            //             variant="body2"
                            //             className={classes.inline}
                            //             color="textPrimary"
                            //         >
                            //             Ali Connors
                            //         </Typography>
                            //         {" — I'll be in your neighborhood doing errands this…"}
                            //     </React.Fragment>
                            // }
                        />
                        {/*<ListItemSecondaryAction>*/}
                        {/*    <Link to={{*/}
                        {/*        pathname: `/patient-card/${id}`,*/}
                        {/*        state: { id },*/}
                        {/*    }}>*/}
                        {/*        {!inConfirmOrder && <Tooltip title="Натисніть щоб переглянути дані пацієнта." arrow>*/}
                        {/*            <IconButton edge="end">*/}
                        {/*                <VisibilityIcon/>*/}
                        {/*            </IconButton>*/}
                        {/*        </Tooltip>}*/}
                        {/*    </Link>*/}
                        {/*</ListItemSecondaryAction>*/}
                    </ListItem>);
                })}
            </Scroll>
        </List>
    </div>);
};

export default DiaAuthorizationPatientsView;
