import React from 'react';
import {Link} from 'react-router-dom';
import {List, ListItem, ListItemText, makeStyles} from '@material-ui/core';
import PatientListItem from './ListItem';
import {useSelector} from 'react-redux';

const useStyles = makeStyles({
  list: {
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    width: '100%',
  },
});

function Current() {
  const activePatient = useSelector(state => state.patients.activePatient);
  const classes = useStyles();
  return (
    <List dense disablePadding className={classes.list}>
      {activePatient ? (
        <PatientListItem patient={activePatient} isSelected={false}/>
      ) : (
        <Link to="/account-settings" style={{
          textDecoration: 'none',
          color: 'inherit',
        }}>
          <ListItem button>
            <ListItemText>Вибрати пацієнта</ListItemText>
          </ListItem>
        </Link>
      )}
    </List>
  );
}

export default Current;