import {
    accept,
    acceptDoctor,
    acceptPartner,
    authorise,
    authorisePartner,
    createOrderAgent,
    createOrderVisit,
    createQuickOrder,
    editDoctorInfo,
    editPatientInfo,
    getActionsFromServer,
    getAssayInfo,
    getCheckForAuthorized,
    getClientLocationAPI,
    getComplexOfAssays,
    getDoctor,
    getFeedbacksForShop,
    getOpenSchedule,
    getOrdersResult,
    getOrdersResultByFullName,
    getOrdersResultByOrderID,
    getOrdersResultForDoctor,
    getPatientOrders,
    getPatientOrdersByPage,
    getPatients,
    getPhrases,
    getPrice,
    getPunktsAPI,
    logout,
    postIdOrderPdv,
    printDirection,
    setCreateClient,
    setRegisterChild
} from './api'

export {
    getAssayInfo,
    editPatientInfo,
    setCreateClient,
    getOpenSchedule,
    getClientLocationAPI,
    getActionsFromServer,
    getComplexOfAssays,
    getPrice,
    getPatients,
    getPunktsAPI,
    getOrdersResult,
    getPatientOrdersByPage,
    getOrdersResultByOrderID,
    getPatientOrders,
    logout,
    accept,
    acceptDoctor,
    authorise,
    setRegisterChild,
    postIdOrderPdv,
    getFeedbacksForShop,
    getDoctor,
    editDoctorInfo,
    getPhrases,
    getOrdersResultByFullName,
    getCheckForAuthorized,
    getOrdersResultForDoctor,
    printDirection,
    createQuickOrder,
    createOrderVisit,
    createOrderAgent,
    acceptPartner,
    authorisePartner
};
