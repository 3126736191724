import React, {useCallback, useEffect} from 'react';
import {List, ListSubheader, makeStyles} from '@material-ui/core';
import {ListItem, Loading, Scroll,} from "@cabinet-packages/components";
import {useDispatch, useSelector} from 'react-redux';
import {selectActivePatient, setBasket} from "@cabinet-packages/redux";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router";
import {setNotAddedPatients} from "@cabinet-packages/redux/actions/actionsCreators";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto auto 1fr',
    width: '100%',
    height: '100%',
    maxWidth: '900px',
    paddingTop: '10px',
  },
  activeUserList: {
    width: '100%',
  },
  listSubheader: {
    backgroundColor: theme.palette.background.paper,
  },
  mainActionContainer: {
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',

    '@media (max-width:900px)': {
      alignItems: 'flex-end',
    },
  },
  filterTextField: {
    margin: 0,
    width: '100%',
  },
  noListItem: {
    textAlign: 'center',
  },
  mainActionButton: {
    width: '100%',
    margin: '8px 0'
  }
}));

function AccountSettings() {
  const { listOfPatients, activePatient, notAddedPatients } = useSelector(state => state.patients);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const measuredRef = useCallback(node => {
    let id = null;
    if (node) id = +node.lastChild.lastChild.href.match(/[0-9]*$/g).join('');
    if (activePatient && id === activePatient.id) {
      node.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [activePatient]);

  useEffect(() => {
    if (localStorage.getItem("basket") != null) {
      dispatch(setBasket(JSON.parse(localStorage.getItem("basket"))))
      localStorage.removeItem("basket");
    }
  }, []);

  const handleClickOnRegisterNewPatient = (event) => {
    event.stopPropagation();
    event.preventDefault();

    history.push('/add-new-patient');
  };

  const handleClickOnUnregisterPatients = (event) => {
    event.preventDefault();
    event.stopPropagation();
    history.push('/add-unregister-patient');
  }

  useEffect(() => {
    if (!activePatient && listOfPatients && typeof listOfPatients !== "string" && listOfPatients.length) {
      dispatch(selectActivePatient(listOfPatients.find(patient => patient.main === true)));
    }
    if (!notAddedPatients && listOfPatients && typeof listOfPatients !== "string" && listOfPatients.length) {
      dispatch(setNotAddedPatients());
    }
  }, [dispatch, listOfPatients, notAddedPatients]);

  return (
      <div className={classes.root}>
        <div className={classes.mainActionContainer}>
          <Button
              className={classes.mainActionButton}
              color="primary"
              variant="outlined"
              onClick={event => handleClickOnRegisterNewPatient(event)}
          >
            Додати нового пацієнта
          </Button>
          {notAddedPatients && notAddedPatients.length > 0 && <Button
              className={classes.mainActionButton}
              color="primary"
              variant="outlined"
              onClick={handleClickOnUnregisterPatients}
          >
            Переглянути пацієнтів не доданих в кабінет
          </Button>}
        </div>
        <List
            dense
            disablePadding
            subheader={<ListSubheader disableSticky>Активний пацієнт</ListSubheader>}
            className={classes.activeUserList}
        >
          {activePatient &&
          <ListItem
              patient={activePatient}
              isSelected={false}
          />
          }
        </List>
        <Scroll>
          <List
              dense
              disablePadding
              subheader={<ListSubheader className={classes.listSubheader}>
                Список пацієнтів
              </ListSubheader>}
          >
            {listOfPatients
                ? listOfPatients.length > 0 && typeof listOfPatients === "object"
                    ? listOfPatients.map((patient) => (
                    <ListItem
                        patient={patient}
                        key={patient.id}
                        classes={classes}
                        isSelected={activePatient && activePatient.id === patient.id}
                        ref={measuredRef}
                    />))
                    : <div className={classes.noListItem}>Немає пацієнтів.</div>
                : <Loading/>}
          </List>
        </Scroll>
      </div>
  );
}

export default AccountSettings;
