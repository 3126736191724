import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Typography,
} from '@material-ui/core';
import Pdf from "medis/src/assets/pdf/Договір публічної оферти_МеДіС.pdf";
import {useSelector} from "react-redux";

function ConfirmPayment({
                           onConfirm = () => null,
                           onCancel = () => null,
                           handleClose,
                           text = '',
                           textButton = 'Оплатити',
                            dataPickers
                       }) {
    const handleCancel = () => {
        onCancel();
        handleClose();
    };

    const handleConfirm = () => {
        onConfirm();
        handleClose();
    }
    const {takeId}  = useSelector(state => state.assaysCatalog);
    const [checked, setChecked] = useState(true);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        if (checked) {
            handleConfirm();
        }
    }, [checked])

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle>
                Підтвердження
            </DialogTitle>
            {dataPickers && <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
            </DialogContent>}
            {!dataPickers && <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
                <DialogContentText>
                    <FormControlLabel
                        style={{zIndex: 9999}}
                        control={<Checkbox
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />}
                        label="Я погоджуюсь з"/>
                    <a style={{color: process.env.REACT_APP_PROJECT_NAME === 'MEDIS' ? '#2cb8ad' : '#0077c888'}} href={takeId === 14 ? Pdf : undefined} target="_blank">
                        умовами надання послуг
                    </a>
                </DialogContentText>
                <Typography variant="caption" color="error">Увага! Діти до 18 років здають аналізи тільки в присутності батьків.</Typography>
            </DialogContent>}
            <DialogActions>
                <Button
                    onClick={handleCancel}
                    color="primary"
                    variant="outlined"
                >
                    Скасувати
                </Button>
                <Button
                    onClick={handleConfirm}
                    color="primary"
                    variant="outlined"
                    autoFocus
                    disabled={!checked}
                >
                    {textButton}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmPayment;
