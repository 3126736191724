import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '30%',
        height: '100%',
        padding: '8px 8px 0px 8px',
        borderRight: '1px dotted grey',

        '@media (max-width: 1180px)': {
            height: '50vh',
            width: '100%',
        },
    },
    complexCardWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '8px',
        margin: '8px',
    },
    sameFilling: {
        color: 'green',
        borderBottom: '1px solid green',
        fontSize: '12px',
        width: '100%',
    },
    filling: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        fontSize: '12px',
        width: '100%',
    },
    list: {
        width: '100%'
    },
    error: {
        fontSize: '12px',
        textAlign: "center",
        color: "red",
        marginTop: '8px',
    },
    searchWrapper: {
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    searchOrderButton: {
        marginLeft: theme.spacing(0.5),
        height: '40px',
        width: '100px'
    },
}));

export default useStyles;
