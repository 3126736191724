import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    rootWelcomePage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: theme.spacing(1),
    },
    cardsWrapper: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center'
    },
    rootCard: {
        maxWidth: 345,
        margin: '8px'
    },
    mediaCard: {
        height: '140px',
    },
    cardImg: {
        backgroundSize: 'contain !important',
    },
}));

export default useStyles;
