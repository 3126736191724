import React, {useContext, useEffect, useState} from "react";
import {makeStyles, MenuItem, NoSsr, Select} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import moment from "moment";
import {UserContext} from "../index";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    birthdayTitle: {
        color: '#0077C8',
        'font-family': "'Museo Sans Cyrl 700'",
        margin: 0,
        fontSize: '1.13rem',
        marginTop: 10,
        // '@media (max-width: 900px)': {
        //     marginTop: 63,
        //     marginBottom: 46,
        // }
    },
    star: {
        color: 'red'
    },
    birthdayWrapper: {
        display: 'flex',
        width: '100%',
        maxWidth: 494,
        justifyContent: 'space-between',
        '@media (max-width: 900px)': {
            display: 'block',
            maxWidth: '100%',
        },
        '& .MuiInputBase-root.Mui-disabled': {
            '& div': {
                color: 'rgba(0, 0, 0, 0.38)',
            }
        }
    },
    formLabel: {
        color: theme.palette.primary.mainSite,
        'font-family': "'Museo Sans Cyrl 700'",
        fontSize: '0.938rem',
        marginTop: 28,
        marginBottom: 9,
        marginLeft: 10,
        '@media (max-width: 900px)': {
            marginTop: 15,
            marginLeft: 0,
        }
    },
    formControlDateSelect: {
        '@media (max-width: 900px)': {
            width: '100%',
        },
        '&:focus': {
            backgroundColor: 'none',
        },
        '& .MuiSelect-select': {
            color: theme.palette.primary.mainTitle,
            'font-family': "'Museo Sans Cyrl 700'",
            width: 81,
            paddingTop: 12.5,
            paddingBottom: 12.5,
            fontSize: '0.938rem',
            '@media (max-width: 900px)': {
                width: '100%',
            },
        },

        '& *::-webkit-scrollbar': {
            display: 'none',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#C9E9FF !important',
        },
        '& svg': {
            color: '#1177B5',
        },

    },


}))

const Birthday = () => {
    moment.locale('uk');
    const cl = useStyles()
    const monthsArray = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];
    const [birthdayDaysArray, setBirthdayDaysArray] = useState(null);
    const currentYearNumber = moment().year();
    const currentMonthNumber = moment().month();
    const currentDayNumber = moment().date();
    const birthdayYearArray = Array.from({length: 100}, (_, i) => i + (currentYearNumber - 100)+ 1);
    const context = useContext(UserContext);
    const {activePatient} = useSelector(state => state.patients);



    const handleChangeGender = (event, type, remove) => {
        if (remove) {
            return context.values.setValues(
                prev => ({
                    ...prev,
                    [type]: '',
                })
            )
        }
        const val = event.target.value
        context.values.setValues(
            prev => ({
                ...prev,
                [type]: val,
            })
        )
    }


    const handleChangeBirthdayYear = (event) => {
        handleChangeGender(event, 'birthdayYear')
        handleChangeGender(event, 'birthdayMonth', true)
        handleChangeGender(event, 'birthdayDay', true)
        setBirthdayDaysArray(null)
    };

    const handleChangeBirthdayMonth = (event) => {
        const val = event.target.value
        handleChangeGender(event, 'birthdayMonth')
        handleChangeGender(event, 'birthdayDay', true)
        setBirthdayDaysArray(Array.from({length: moment().year(+context.values.values.birthdayYear).month(val).endOf('month').date()}, (_, i) => i + 1));

    };

    const handleChangeBirthdayDay = (event) => {
        const val = event.target.value
        const res = val < 10 ? `0${val}` : val
        context.values.setValues(
            prev => ({
                ...prev,
                birthdayDay: res,
            })
        )

    };



    const handleActivePatient = (name, patientValue) => {
        context.values.setValues(prev => ({
            ...prev,
            [name]: patientValue,
        }));
    }


    useEffect(() => {
        if (activePatient) {

            if (activePatient?.birthday && context?.values.values.handlePatient) {
                handleActivePatient('birthdayYear', activePatient.birthday.split(' ')[0].split('-')[0])
                const patientBirthdayMonth = moment(activePatient?.birthday.split('-')[1], 'MM').format('MMMM')
                handleChangeBirthdayMonth({
                    target: {
                        value: patientBirthdayMonth[0].toUpperCase() + patientBirthdayMonth.slice(1)
                    }
                })
                handleActivePatient('birthdayDay', activePatient.birthday.split(' ')[0].split('-')[2])
            }
        }
    }, [activePatient])


    return (
        <NoSsr>
            <p className={cl.birthdayTitle}><FormattedMessage id="shopping_cart_date_birth.link"
                                                              defaultMessage={"Дата народження"}/>
                <span className={cl.star}> *</span>
            </p>
            <div className={cl.birthdayWrapper}>
                <div>
                    <p className={cl.formLabel}><FormattedMessage id="shopping_cart_year_format_date.link"
                                                                  defaultMessage={"Рік"}/></p>
                    <Select
                        labelId="demo-simple-select-label"
                        variant="outlined"
                        value={context.values.values.birthdayYear}
                        onChange={handleChangeBirthdayYear}
                        className={cl.formControlDateSelect}
                        disabled={context.values.values.handlePatient && !context.values.values.editPatient}
                    >
                        {
                            birthdayYearArray.map((year, idx) => {
                                return (
                                    <MenuItem
                                        key={idx + 'year' + year}
                                        value={year}
                                    >
                                        {year}
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                </div>


                <div>
                    <p className={cl.formLabel}><FormattedMessage id="shopping_cart_month.link"
                                                                  defaultMessage={"Місяць"}/>
                    </p>
                    <Select
                        labelId="demo-simple-select-label"
                        variant="outlined"
                        value={context.values.values.birthdayMonth}
                        onChange={handleChangeBirthdayMonth}
                        className={cl.formControlDateSelect}
                        disabled={context.values.values.handlePatient && !context.values.values.editPatient}
                    >
                        {
                            monthsArray.map((month, idx) => {
                                return (
                                    <MenuItem
                                        key={idx + 'month' + month}
                                        value={month}
                                        disabled={
                                            +currentYearNumber === +context.values.values.birthdayYear
                                            && moment().month(month).month() > currentMonthNumber
                                        }
                                    >
                                        {month}
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                </div>

                <div>
                    <p className={cl.formLabel}><FormattedMessage id="shopping_cart_day_switch.link"
                                                                  defaultMessage={"День"}/></p>
                    <Select
                        labelId="demo-simple-select-label"
                        variant="outlined"
                        value={+context.values.values.birthdayDay}
                        onChange={handleChangeBirthdayDay}
                        className={cl.formControlDateSelect}
                        disabled={(!birthdayDaysArray) || (context.values.values.handlePatient && !context.values.values.editPatient)}
                    >
                        {
                            birthdayDaysArray && birthdayDaysArray.map((day, idx) => {
                                return (
                                    <MenuItem
                                        key={day + 'birthdayDay' + idx}
                                        value={day}
                                        disabled={
                                            +currentYearNumber === +context.values.values.birthdayYear
                                            && moment().month(context.values.values.birthdayMonth).month() === currentMonthNumber
                                            && +day > +currentDayNumber
                                        }
                                    >
                                        {day}
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                </div>
            </div>

        </NoSsr>
    )
}

export default Birthday