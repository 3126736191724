import React from "react";
import {
  makeStyles,
  TableCell,
  TableRow,
  useMediaQuery,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import {RenderNormArr} from "./Results";

const useStyles = makeStyles((theme) => ({
  assayTableTitle: {
    marginBottom: "10px",
    fontWeight: "600",
    cursor: "pointer",
    transition: "color 0.5s",

    "&:hover": {
      transition: "color 0.5s",
      color: theme.palette.primary.main,
    },
  },
  assayTableTitleTime: {
    fontWeight: "500",
  },
  table: {
    wordBreak: "break-all",
    tableLayout: "fixed",
    width: "100%",
  },
  tableForInnerHTML: {
    maxWidth: "95vw",
  },
  tableCell: {
    width: "25%",
    border: "1px solid rgb(197, 197, 197)",
    padding: "0px 2px",
    textAlign: "center",
    color: 'rgba(33, 69, 114, 0.87)',
  },
  tableCellHead: {
    width: "25%",
    border: "1px solid rgba(33, 69, 114, 0.87)",
    padding: "0px 2px",
    textAlign: "center",
    color: 'rgba(33, 69, 114, 0.87)',
  },
  tableCellResult: {
    width: "25%",
    border: "1px solid rgb(197, 197, 197)",
    padding: "0px 2px",
    textAlign: "center",
    color: 'rgba(33, 69, 114, 0.87)',
  },
  result: {
    textDecoration: "underline",
  },
  columnLabel: {
    fontSize: "12px",
    color: 'rgba(33, 69, 114, 0.87)',
    fontWeight: 600,
    "@media(max-width: 900px)": {
      fontSize: "9px",
    },
  },
  assayNameContainer: {
    color: 'rgba(33, 69, 114, 0.87)',
  },
  researchDescription: {
    display: "flex",
    rowGap: "10px",
    padding: "10px 10px",
    backgroundColor: "#F8F9F9",
    margin: "10px 0px",
    width: "100%",
  },
  resultLabel: {
    fontSize: "14px",
    color: 'rgba(33, 69, 114, 0.87)',
    fontWeight: 600,
    background: theme.palette.primary.background
  },
}));

const ResultStringAndDigital = ({ assay }) => {
  const classes = useStyles();
  const mdUp = useMediaQuery('@media (minWidth: 900px)');
  const { takeId } = useSelector((state) => state.assaysCatalog);

  const getAssayState = (state) => {
    if (state === "normal") {
      return <DoneIcon htmlColor="#6ED966" />;
    } else {
      return <PriorityHighIcon htmlColor="#FFDA33" />;
    }
  };

  if ((assay.packet.toLowerCase().includes("коронавірус") &&
      assay.result.length === 0)) {
    return <div key={assay.id}/>;
  }

  return (
    <React.Fragment key={assay.id}>
      <div className={classes.assayTableTitle}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 10px",
            }}
          >
            <Typography className={classes.columnLabel}>
              Назва дослідження
            </Typography>
            {+assay.ready === 0 ? <Typography variant="caption" color="error">
                  Результати ще не готові. Очікуйте сповіщення про готовність.
                </Typography>
                : <Typography className={classes.columnLabel}>
                  Результат готовий
                </Typography>}
          </div>
          <div className={classes.researchDescription}>
            <Typography
              style={mdUp ? { flex: "3 1 0" } : { flex: "2 1 0" }}
              className={classes.resultLabel}
              color={"textPrimary"}
            >
              {`${assay.packet.length > 0 ? assay.packet : assay.test}`}
            </Typography>
            <Typography
              style={mdUp ? {} : { flex: "2 1 0", textAlign: "end" }}
              className={classes.resultLabel}
              color={"textPrimary"}
              component={"div"}
            >
              <em className={classes.assayTableTitleTime}>
                {+assay.ready !== 0 && assay.utime.slice(0, 16).split("-").join(".")}
              </em>
            </Typography>
          </div>
        </div>
      </div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellHead}>
              <strong>Вид дослідження</strong>
            </TableCell>
            <TableCell className={classes.tableCellHead}>
              <strong>Результат</strong>
            </TableCell>
            <TableCell className={classes.tableCellHead}>
              <strong>Референтні значення</strong>
            </TableCell>
            {takeId === 14 && (
              <TableCell className={classes.tableCellHead}>
                <strong>Стан</strong>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {assay &&
            assay.arrOfResults &&
            assay.arrOfResults
              .map((itemAssay, idx) => {
                return (
                  <TableRow
                    key={`${itemAssay.id} ${idx}`}
                    style={
                      itemAssay.state === "normal"
                        ? {}
                        : { backgroundColor: "#FFEAEA" }
                    }
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableCell}
                    >
                      <div className={classes.assayNameContainer}>
                        {itemAssay.test}
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableCellResult}>
                      {itemAssay.state !== "normal" ? (
                        <strong className={classes.result}>
                          {itemAssay.result + " "}
                        </strong>
                      ) : (
                        <span>{itemAssay.result + " "}</span>
                      )}
                      {itemAssay.units && itemAssay.units !== "-" && (
                        <span
                          dangerouslySetInnerHTML={{ __html: itemAssay.units }}
                        />
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {itemAssay?.normArr && itemAssay?.normArr.length > 0
                          ? <RenderNormArr normArr={itemAssay.normArr} classes={classes}/>
                          : itemAssay.norm &&
                          itemAssay.norm !== "{NULL}" &&
                          itemAssay.norm
                              .split('"')
                              .join("")
                              .split("#%")
                              .join("")
                              .split("{")
                              .join("")
                              .split("}")
                              .map((norm, i) => {
                                if (norm.length !== 0) {
                                  return (
                                      <React.Fragment key={`${norm} ${idx} ${i}`}>
                                        {norm.indexOf(",") === 0 ? (
                                            <span
                                                dangerouslySetInnerHTML={{
                                                  __html: norm.slice(1) + " ",
                                                }}
                                            />
                                        ) : (
                                            <span
                                                dangerouslySetInnerHTML={{
                                                  __html: norm + " ",
                                                }}
                                            />
                                        )}
                                        {itemAssay.units &&
                                            itemAssay.units !== "-" && (
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                      __html: itemAssay.units,
                                                    }}
                                                />
                                            )}
                                      </React.Fragment>
                                  );
                                } else return null;
                              })}
                    </TableCell>
                    {takeId === 14 && (
                      <TableCell className={classes.tableCell}>
                        {getAssayState(itemAssay.state)}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};



export default ResultStringAndDigital;
