import React from 'react';
import useStyles from "../styles";
import StyledAutocomplete from "./StyledAutocomplete";
import {useDispatch, useSelector} from "react-redux";
import paletteImg from '../images/bttn_tile.svg';
import listImg from '../images/bttn_list.svg';
import {setSelectedTags} from "@cabinet-packages/redux";
import {toggleComplexTransition} from "@cabinet-packages/redux/actions/actionsCreators";

const SearchHeader = ({useCatalogForView, useSelectedTag, handleValue, usePrevState, setLoading,allFunc}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const {catalog, complexResearch, complexTransition} = useSelector(state => state.assaysCatalog);
    const [catalogForView, setCatalogForView] = useCatalogForView;
    const [selectedTag, setSelectedTag] = useSelectedTag;
    const [prevStateOfCatalog, setPrevStateOfCatalog] = usePrevState;
    const [filteredValue, handleChangeFilterValue] = handleValue;

    const handleClickOnPalette = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (catalogForView) {
            setCatalogForView(null);
            setSelectedTag(null);
            dispatch(setSelectedTags([]))
        }
        if (complexTransition) {
            dispatch(toggleComplexTransition());
        }
        setPrevStateOfCatalog(null);

        if (filteredValue) {
            handleChangeFilterValue({target: {value: ''}});
        }
    };

    const handleClickOnList = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!catalogForView && catalog && complexResearch) {
            setCatalogForView([...catalog, ...complexResearch].filter(item => !item.superGroupName));
            setSelectedTag('Усі');
        }
        setPrevStateOfCatalog(null);
    };

    return (<div className={cl.searchHeaderWrapper}>
        <div className={cl.searchHeader}>
            <StyledAutocomplete useSelectedTag={useSelectedTag} useCatalogForView={useCatalogForView}
                                handleValue={handleValue} usePrevState={usePrevState} setLoading={setLoading} allFunc={allFunc}/>

            <div className={cl.switchWrapper}>
                <img
                    onClick={handleClickOnPalette}
                    src={paletteImg} alt="palette."
                    className={catalogForView ? cl.switchPaletteImg : cl.switchPaletteImgSelected}/>
                <img
                    onClick={handleClickOnList}
                    src={listImg} alt="list."
                    className={catalogForView ? cl.switchListImgSelected : cl.switchListImg}/>
            </div>
        </div>
    </div>);
};

export default SearchHeader;
