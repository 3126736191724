import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router";
import {getOrderSum} from "@cabinet-packages/api/api";
import {CircularProgress} from "@material-ui/core";

const LiqPayPage = () => {
    const history = useHistory()
    const [href, setHref] = useState({href: null, loading: false, error: false, paid: false})

    const handleLiqPayHref = useCallback(async () => {
        const id = history.location.pathname.split('/').pop()

        if (id && !href.href && !href.loading && !href.error && !href.paid) {
            setHref(prevState => ({
                ...prevState,
                loading: true
            }))

            try {
                const dataFromApi = await getOrderSum(id)

                if (dataFromApi) {
                    const json_string = {
                        "version": "3",
                        "public_key": "i84475320624",
                        "action": "pay",
                        "paytypes": "gpay, apay, card, liqpay, privat24, masterpass, invoice, qr",
                        "amount": dataFromApi,
                        "order_id": id,
                        "currency": "UAH",
                        "description": "payment_from_cabinet_partner",
                        language: 'uk',
                    }

                    const data = Buffer.from(JSON.stringify(json_string)).toString('base64')
                    const res = 'AYs0YPBxFrIFf3AK46Lctk2KJQnWqflI78ibbPr7' + data + 'AYs0YPBxFrIFf3AK46Lctk2KJQnWqflI78ibbPr7'
                    const crypto = require('crypto');
                    const sha = crypto.createHash('sha1');
                    sha.update(res);
                    const ret = sha.digest('base64');
                    const urlIframe = `https://www.liqpay.ua/api/3/checkout?data=${data}&signature=${ret}`;

                    window.location.href = urlIframe

                    setHref(prevState => ({
                        ...prevState,
                        href: urlIframe,
                        loading: false
                    }))
                } else {
                    setHref(prevState => ({
                        ...prevState,
                        loading: false,
                        paid: true
                    }))
                }
            } catch (e) {
                setHref(prevState => ({
                    ...prevState,
                    error: e,
                    loading: false
                }))
            }
        }
    }, [href])

    useEffect(() => {
        handleLiqPayHref()
    }, [handleLiqPayHref])

    return <div style={{width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        {href.paid && <h2>Дякуємо, ваше замовлення оплачено.</h2>}
        {href.loading && <CircularProgress/>}
    </div>
}

export default LiqPayPage
