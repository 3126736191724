import React, {useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    makeStyles,
    Menu,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
} from '@material-ui/core';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    textField: {
        marginTop: '8px',
        width: '100%',
    },
    bloodTypeButton: {
        width: '50%',

        '@media (max-width:900px)': {
            width: '100%',
        },
    },
    bloodTypeWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',

        '@media (max-width:900px)': {
            flexDirection: 'column',
        },
    },
    bloodTypeRhesus: {
        width: '50%',
        padding: '8px',

        '@media (max-width:900px)': {
            width: '100%',
        },
    },
    selectedBloodText: {
        padding: '4px',
    }
}));

function SignalMarksDialog({onConfirm = () => null, onCancel = () => null, handleClose}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [state, setState] = useState({
        bloodTransfusion: '',
        diabetes: '',
        infectiousDiseases: '',
        surgicalInterventions: '',
        allergicHistory: '',
        drugIntolerance: '',
        riskFactors: '',
        bloodType: null,
        rhesus: 'Rh+',
    });

    const updateState = (field, value) => {
        console.log('update', value)
        setState(prev => ({
                ...prev,
                [field]: value,
            }
        ));
    };

    const handleCloseMenu = (event, reason, group) => {
        event.preventDefault();
        event.stopPropagation();

        setAnchorEl(null);

        if (group) updateState('bloodType', group);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCancel = () => {
        onCancel();
        handleClose();
    };

    const handleConfirm = () => {
        onConfirm();
        handleClose();
    };

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
        >
            <DialogContent>
                <DialogTitle id="scroll-dialog-title">Сигнальні позначки:</DialogTitle>
                {state.bloodType && <p className={classes.selectedBloodText}>
                    Вибрана група крові:
                </p>}
                <div className={classes.bloodTypeWrapper}>
                    <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        variant="contained"
                        className={classes.bloodTypeButton}
                        color={state.bloodType ? 'secondary' : 'default'}
                    >
                        {
                            state.bloodType
                                ? state.bloodType
                                : 'Натисніть для вибору групи крові'
                        }
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                        style={{width: '100%'}}
                    >

                        <MenuItem
                            onClick={(event, reason) => handleCloseMenu(event, reason, 'I (0)')}

                        >
                            I (0)
                        </MenuItem>
                        <MenuItem
                            onClick={(event, reason) => handleCloseMenu(event, reason, 'II (A)')}
                        >
                            II (A)
                        </MenuItem>
                        <MenuItem
                            onClick={(event, reason) => handleCloseMenu(event, reason, 'III (B)')}
                        >
                            III (B)
                        </MenuItem>
                        <MenuItem
                            onClick={(event, reason) => handleCloseMenu(event, reason, 'IV (AB)')}
                        >
                            IV (AB)
                        </MenuItem>
                    </Menu>
                    <div className={classes.bloodTypeRhesus}>
                        <FormControl marginDense fullWidth>
                            <FormLabel component="legend">Резус фактор:</FormLabel>
                            <RadioGroup
                                row
                                aria-label="rhesus"
                                name="rhesus"
                                value={state.rhesus}
                                onChange={(event) =>
                                    updateState('rhesus', event.target.value)
                                }
                            >
                                <FormControlLabel value="Rh+" control={<Radio disableRipple/>} label="Rh+" />
                                <FormControlLabel value="Rh-" control={<Radio disableRipple/>} label="Rh-" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <TextField
                    className={classes.textField}
                    value={state.lastname}
                    onChange={(event) =>
                        updateState('bloodTransfusion', event.target.value)}
                    label="Переливання крові"
                    variant="outlined"
                    size="small"
                />
                <TextField
                    className={classes.textField}
                    value={state.lastname}
                    onChange={(event) =>
                        updateState('diabetes', event.target.value)}
                    label="Цукровий діабет"
                    variant="outlined"
                    size="small"
                />
                <TextField
                    className={classes.textField}
                    value={state.lastname}
                    onChange={(event) =>
                        updateState('infectiousDiseases', event.target.value)}
                    label="Інфекційні захворювання"
                    variant="outlined"
                    size="small"
                />
                <TextField
                    className={classes.textField}
                    value={state.lastname}
                    onChange={(event) =>
                        updateState('surgicalInterventions', event.target.value)}
                    label="Хірургічні втручання"
                    variant="outlined"
                    size="small"
                />
                <TextField
                    className={classes.textField}
                    value={state.lastname}
                    onChange={(event) =>
                        updateState('allergicHistory', event.target.value)}
                    label="Алергологічний анамнез"
                    variant="outlined"
                    size="small"
                />
                <TextField
                    className={classes.textField}
                    value={state.lastname}
                    onChange={(event) =>
                        updateState('drugIntolerance', event.target.value)}
                    label="Непереносимість лікарських препаратів"
                    variant="outlined"
                />
                <TextField
                    className={classes.textField}
                    value={state.lastname}
                    onChange={(event) =>
                        updateState('riskFactors', event.target.value)}
                    label="Фактори ризику"
                    variant="outlined"
                    size="small"
                />
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    );
}

export default SignalMarksDialog;
