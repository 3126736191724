import React, {useContext} from "react";
import {FormControlLabel, makeStyles, Radio, RadioGroup} from "@material-ui/core";
import InputAuthorization from "./InputAuthorization";
import {FormattedMessage} from "react-intl";
import {UserContext} from "../index";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    InputWrapperRoot: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        '@media (max-width: 900px)': {
            gridTemplateColumns: '1fr',
        }

    },
    star: {
        color: 'red',
        '@media (max-width: 900px)': {
            position: 'relative',
            bottom: 5,
        }
    },
    label: {
        marginTop: 0,
        marginBottom: 12,
        fontSize: '1.125rem',
        color: '#0077C8',
        fontFamily: "'Museo Sans Cyrl 500'",
        '& span': {
            fontFamily: "'Museo Sans Cyrl 500' !important",
        },
    },
    gender: {
        '& .MuiRadio-colorPrimary.Mui-checked': {
            color: '#0077C8',
        },
        '& .MuiIconButton-label': {
            color: '#0077C8',
            '@media (max-width: 900px)': {
                width: 18,
                height: 18,
            },
        },
        '& p': {
            fontSize: '0.938rem',
            color: '#0077C8',
        },

    },
    wrapperGender: {
        justifyContent: 'space-between',
        maxWidth: 230,
        '& label': {
            marginRight: 0,
        },
        '& .Mui-disabled': {
            '& .MuiIconButton-label': {
                color: 'rgba(0, 0, 0, 0.26)',
            }
        },
    },
}))


const InputWrapperAuthorization = () => {
    const cl = useStyles()
    const context = useContext(UserContext);
    const {stage} = useSelector(state => state.patients);

    const handleChangeGender = (event) => {
        const val = event.target.value
        context.values.setValues(
            prev => ({
                ...prev,
                ['gender'] : val,
            })
        )
    }



    return (
        <div className={cl.InputWrapperRoot} key={context}>
            <InputAuthorization
                label={<FormattedMessage
                    id="shopping_cart_last_name.label"
                    defaultMessage={"Прізвище"}
                />}
                type={'lastName'}
                value={context?.values.values.lastName}
            />
            <InputAuthorization
                label={<FormattedMessage id="shopping_cart_name_patient.label"
                                         defaultMessage={"Ім’я"}/>}
                type={'firstName'}
                value={context?.values.values.firstName}
            />
            <InputAuthorization
                label={<FormattedMessage id="shopping_cart_last_surname.label"
                                         defaultMessage={"По-батькові"}/>}
                type={'fatherName'}
                value={context?.values.values.fatherName}
                required={true}
            />
            {(!context.values.values.editPatient && stage < 5) && <InputAuthorization
                label={<FormattedMessage
                    id="shopping_cart_phone_number.label"
                    defaultMessage={"Номер телефону"}/>}
                type={'phone'}
                value={context?.values.values.phone}
            />}
            <InputAuthorization
                label={<FormattedMessage
                    id="shopping_cart_you_email.link"
                    defaultMessage={"E-mail"}/>}
                type={'email'}
                value={context?.values.values.email}
            />

            <div>
                <div>
                    <p className={cl.label}><FormattedMessage
                        id="shopping_cart_point.link" defaultMessage={"Стать"}/>
                        <span className={cl.star}>*</span></p>
                </div>
                <RadioGroup
                    className={cl.wrapperGender}
                    value={context.values.values.gender}
                    onChange={handleChangeGender}
                    row
                >
                    <FormControlLabel
                        disabled={context.values.values.handlePatient && !context.values.values.editPatient}
                        value="F"
                        control={<Radio color="primary"/>}
                        className={cl.gender}
                        label={<p>
                            <FormattedMessage
                                id="shopping_cart_point_women.link" defaultMessage={"Жіноча"}/>
                        </p>}
                    />
                    <FormControlLabel
                        disabled={context.values.values.handlePatient && !context.values.values.editPatient}
                        value="M"
                        control={<Radio color="primary"/>}
                        className={cl.gender}
                        label={<p>
                            <FormattedMessage
                                id="shopping_cart_point_men.link" defaultMessage={"Чоловіча"}/>
                        </p>}
                    />
                </RadioGroup>

            </div>

        </div>

    )
}

export default InputWrapperAuthorization