import AccountSettings from "./AccountSettings";
import ActionsDetails from "./ActionsDetails";
import AssayInfo from "./AssayInfo";
import AssaySchedulerDay from "./AssaySchedulerDay";
import AssayTable from "./AssayTable";
import Catalog from "./Catalog";
import CatalogTable from "./CatalogTable";
import CollectionPoints from "./CollectionPoints";
import ComplexResearch from "./ComplexResearch";
import ConfirmOrder from "./ConfirmOrder";
import FeedbackOfAssays from "./FeedbackOfAssays";
import NotificationManager from "./NotificationManager"
import OrderCollectionPoints from "./OrderCollectionPoints";
import OrderManager from "./OrderManager";
import PatientAssaysHistory from "./PatientAssaysHistory";
import PatientCard from "./PatientCard";
import PatientResults from "./PatientResults";
import ResearchStatus from "./ResearchStatus";
import Support from "./Support";
import PortmonePaymentPage from "./portmone/PortmonePaymentPage";
import PortmoneFailure from "./portmone/PortmoneFailure";
import PortmoneSuccess from "./portmone/PortmoneSuccess";
import ComplexDetails from "./ComplexDetails";
import HowToPrepare from "./HowToPrepare";
import OrdersHistory from "./OrdersHistory";
import AddNewPatient from "./AddNewPatient";
import DoctorOrderedAssays from "./DoctorOrderedAssays";
import ComplexInfo from "./ComplexInfo";
import RefundPage from "./RefundPage";
import AddUnregisterPatients from './AddUnregisterPatients';
import CatalogEsculab from "./CatalogEsculab";
import PartnerOrderedAssays from "./PartnerOrderedAssays";
import PatientTabs from "./PatientTabs";
import WizardStepper from "./WizardStepper";
import PhoneEnterPage from "./PhoneEnterPage";
import OrderVialsPage from "./OrderVialsPage";
import PhoneEnterPageMedis from "./PhoneEnterPageMedis";
import PatientTabsMedis from "./PatientTabsMedis";
import CalendarMedis from "./CalendarMedis";
import EsculabPaymentPage from "./EsculabPaymentPage";
import ProductCatalogEsculab from './ProductCatalogEsculab';
import PreBill from "./PreBill";
import WelcomePageCalculator from "./WelcomePageCalculator";
import FindOrderCalculator from "./FindOrderCalculator";
import SiteAuth from "./SiteAuth";
import WizardStepperVdoma from "./WizardStepperVdoma";
import ProductCatalogVdoma from "./ProductCatalogVdoma";
import CollectionPointsVdoma from "./CollectionPointsVdoma/CollectionPointsAssay";
import CalendarVdoma from "./CalendarVdoma";
import PatientTabsVdoma from "./PatientTabsVdoma";
import OrderPunkts from "./OrderPunkts";
import PrintStickersCalculator from "./PrintStickersCalculator";
import CollectionPointsKids from "./CollectionPointsKids/CollectionPointsAssay";
import CalendarKids from './CalendarKids';
import CalculatorCatalog from "./CalculatorCatalog";

export {
    AccountSettings,
    ActionsDetails,
    AssayInfo,
    AssaySchedulerDay,
    AssayTable,
    Catalog,
    CatalogTable,
    CollectionPoints,
    ComplexResearch,
    ConfirmOrder,
    FeedbackOfAssays,
    NotificationManager,
    OrderCollectionPoints,
    OrderManager,
    PatientAssaysHistory,
    PatientCard,
    PatientResults,
    ResearchStatus,
    Support,
    PortmonePaymentPage,
    PortmoneFailure,
    PortmoneSuccess,
    ComplexDetails,
    HowToPrepare,
    OrdersHistory,
    AddNewPatient,
    DoctorOrderedAssays,
    ComplexInfo,
    RefundPage,
    AddUnregisterPatients,
    CatalogEsculab,
    PartnerOrderedAssays,
    PatientTabs,
    WizardStepper,
    PhoneEnterPage,
    OrderVialsPage,
    PhoneEnterPageMedis,
    PatientTabsMedis,
    CalendarMedis,
    EsculabPaymentPage,
    ProductCatalogEsculab,
    PreBill,
    WelcomePageCalculator,
    FindOrderCalculator,
    SiteAuth,
    WizardStepperVdoma,
    ProductCatalogVdoma,
    CollectionPointsVdoma,
    CalendarVdoma,
    PatientTabsVdoma,
    OrderPunkts,
    PrintStickersCalculator,
    CollectionPointsKids,
    CalendarKids,
    CalculatorCatalog,
};
