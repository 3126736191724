import {SET_ACTION_IMG, SET_MATERIAL_THEME} from "../actions/actionsTypes";

export const materialThemeReducerInitialState = {
    themeMaterialUI: null,
    imgUrl: null,
};

export default function materialThemeReducer(state = materialThemeReducerInitialState, { type, payload }){
    switch (type){
        case SET_MATERIAL_THEME:
            return {
                ...state,
                themeMaterialUI: payload.theme,
            };
        case SET_ACTION_IMG:
            return {
                ...state,
                imgUrl: payload.imgUrl,
            };
        default:
            return state;
    }
};
