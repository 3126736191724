import React, {useEffect} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
    Collapse,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MuiThemeProvider,
    Tooltip
} from "@material-ui/core";
import {Close, PinDrop} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {Loading} from '@cabinet-packages/components';
import {getClientLocation} from "@cabinet-packages/redux";
import calculateDistanceByHaversineFormula from "@cabinet-packages/helpers/calculateDistanceByHaversineFormula";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        width: '100%',
        height: '100%',
        minWidth: '300px',
        minHeight: '300px',
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '@media (max-width: 900px)': {
            minWidth: 'auto',
        },
    },
    closeButton: {
        position: 'absolute',
        top: '4px',
        right: '4px',
        zIndex: 100,
    },
    root: {
        width: '100%',
        maxWidth: 360,
        paddingTop: '32px',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    dialog: {
        margin: 0,
        paddingTop: 0,
    },
    listSubheader: {
        position: 'absolute',
        top: 0,
        padding: '8px 50px 8px 8px',
        color: 'rgba(0, 0, 0, 0.4)',
        backgroundColor: 'white',
        zIndex: 2,
    },
}));

const GetClientRegion = ({ themeMaterialUI, handleClose }) => {
    const classes = useStyles();
    const {regions, clientLocation} = useSelector(store => store.collectionPoints);
    const {takeId}  = useSelector(state => state.assaysCatalog);
    const dispatch = useDispatch();

    const handleCloseIcon = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (takeId === 14) {
            localStorage.setItem('idReg', "2");
        } else {
            localStorage.setItem('idReg', "3201");
        }
        handleClose();
    };

    useEffect(() => {
        if (!clientLocation || (clientLocation && (!clientLocation.lat || !clientLocation.lng))) {
            dispatch(getClientLocation());
        }
    }, [dispatch]);

    useEffect(() => {
        if (clientLocation && clientLocation.lat && clientLocation.lng && regions) {
            const cities = regions.reduce((acu, cur) => {
                return [...acu, cur];
            }, []).map((point) => {
                return ({
                    distance: calculateDistanceByHaversineFormula(clientLocation.lat, clientLocation.lng, point.lat, point.lng),
                    point
                })
            }).sort((a, b) => a.distance - b.distance).map(punkt => punkt.point);

            localStorage.setItem('idReg', `${cities[0].idReg}`);
            handleClose();
        }
    }, [dispatch, clientLocation, regions]);

    const Region = ({region}) => {
        const handleClickOnCity = (event, city) => {
            event.stopPropagation();
            event.preventDefault();

            localStorage.setItem('idReg', `${city.idReg}`);
            handleClose();
        }

        return (<React.Fragment >
            <Collapse in={true} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {region.cities.sort((a,b) => a.name.localeCompare(b.name)).map(city => (
                        <ListItem
                            button
                            className={classes.nested}
                            key={city.idReg}
                            onClick={(event) => handleClickOnCity(event, city)}
                        >
                            <ListItemIcon>
                                <PinDrop />
                            </ListItemIcon>
                            <ListItemText primary={city.name} />
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </React.Fragment>);
    };

    return (<MuiThemeProvider theme={themeMaterialUI}>
            <Dialog open={true} className={classes.dialog} scroll="body">
                <DialogContent className={classes.dialogContent}>
                    <IconButton onClick={handleCloseIcon} className={classes.closeButton}>
                        <Tooltip title="Натисніть, щоб закрити діалогове вікно." arrow>
                            <Close/>
                        </Tooltip>
                    </IconButton>
                    <p className={classes.listSubheader}>Будь ласка, виберіть ваше місто:</p>
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={classes.root}
                    >
                        {regions
                            ? regions.reduce((acu, cur) => {

                                return [...acu, {...cur, cities: [cur]}]
                            }, []).map((region, idx) => <Region region={region} key={region.idReg + ' ' + idx}/>)
                            : <Loading />
                        }
                    </List>
                </DialogContent>
            </Dialog>
        </MuiThemeProvider>);
};

export default GetClientRegion;
