import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, IconButton, InputAdornment, makeStyles, Switch, Tooltip} from '@material-ui/core';
import {AutoSizer, List} from 'react-virtualized';
import {useDispatch, useSelector} from 'react-redux';
import {useAssaysFilter} from "@cabinet-packages/hooks";
import {
    clearOrderedAssays,
    getCatalog,
    getPunkts,
    setCatalogFilteredValue,
    setSelectedComplex,
    setToggleChecked
} from '@cabinet-packages/redux';
import {AddRemoveIconButtons, CustomDialog, Loading} from '@cabinet-packages/components';
import {Link, useHistory} from "react-router-dom";
import {AddShoppingCart} from "@material-ui/icons";
import {isIncludedInComplexResearch, isIncludedInOrderingList,} from "@cabinet-packages/helpers";
import PiggyBank from "../../projects/medis/src/assets/images/catalogTableImages/piggy_bank.svg";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {createFilterOptions} from "@material-ui/lab";
import LocationSVG from "../assets/LocationSVG";
import ConfirmRegionDialog from "../components/Dialogs/ConfirmRegionDialog";
import {setRegions} from "@cabinet-packages/redux/actions/actionsCreators";
import InfoIcon from '@material-ui/icons/Info';

const normIndexLocalStorageKey = 'normsPage-indexOfNorm';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '100%',
        minHeight: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 50px auto 1fr auto',

        '@media (max-width: 900px)': {
            gridTemplateRows: 'auto auto 1fr auto',
        },
    },
    textFieldContainer: {
        marginBottom: '10px',
        overflow: 'hidden',
        maxWidth: '900px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: '8px',

        '@media (max-width: 900px)': {
            flexDirection: 'column-reverse',
            justifyContent: 'flex-start',
        }
    },
    listHeadersContainer: {
        background: theme.palette.primary.main,
        display: 'grid',
        paddingRight: '17px',
        gridTemplateColumns: '55% 10% 10% 10% 15%',
        border: '1px solid transparent',
        boxShadow: '0 0 1px 1px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box',
        overflow: 'hidden',
        minHeight: "50px",

        '@media (max-width: 1140px)': {
            gridTemplateColumns: '55% 15% 30%',
            paddingRight: 0,

            '& :nth-child(2)': {
                display: 'none',
            },
            '& :nth-child(4)': {
                display: 'none',
            },
        },
    },
    listHeader: {
        display: 'flex',
        justifyContent: 'space-around',
        textAlign: 'center',
        alignItems: 'center',
        position: 'relative',
        fontSize: '15px',

        '@media (max-width: 1140px)': {
            fontSize: '12px',
        },
    },
    listHeaderAssayName: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        textAlign: 'center',
        alignItems: 'center',
        position: 'relative',
        fontSize: '15px',
        paddingLeft: '8px',

        '@media (max-width: 1140px)': {
            fontSize: '12px',
        },
    },
    listHeaderRedDiscount: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '24px',
        fontWeight: '600',
        color: 'white',
        background: theme.palette.error.light,
        border: `2px solid ${theme.palette.error.main}`,
        borderRadius: '16px',
        width: '32px',
        height: '32px',
        position: 'absolute',
        top: 3,
        right: 0,
        '@media (max-width: 1140px)': {
            top: 0,
            zIndex: 100,
            width: '30px',
            height: '30px',
        },
    },
    listHeaderTinyText: {
        fontSize: '10px',
    },
    list: {
        scrollBehavior: 'smooth',
        boxShadow: '0 0 1px 1px rgba(0, 0, 0, 0.1)',
        '&:focus': {
            outline: 'none',
        },
    },
    listItem: {
        display: 'grid',
        gridTemplateColumns: '55% 10% 10% 10% 15%',
        textAlign: 'center',
        '@media (max-width: 1140px)': {
            gridTemplateColumns: '55% 15% 30%',
        },
    },
    listItemTitle: {
        height: 'inherit',
        fontSize: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '5px',
        textAlign: 'start',
        transition: '.5s',

        '&:hover': {
            color: theme.palette.primary.main,
            transition: '.5s',
        },

        '@media (max-width: 1140px)': {
            fontSize: '14px',
            wordBreak: 'break-all',
        },
    },
    listItemTitleForComplex: {
        height: 'inherit',
        fontSize: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '5px',
        textAlign: 'start',
        transition: '.5s',

        '&:hover': {
            color: theme.palette.primary.main,
            transition: '.5s',
        },

        '@media (max-width: 1140px)': {
            fontSize: '14px',
            wordBreak: 'break-all',
        },
    },
    listItemGroupTitle: {
        fontSize: '16px',
        letterSpacing: '1px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        height: '100%',
        padding: '5px',
        '@media (max-width: 1140px)': {
            padding: '5px 5px 5px 5%',
        },
    },
    listItemTime: {
        height: 'inherit',
        borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        '@media (max-width: 1140px)': {
            display: 'none',
        },
    },
    listItemComplex: {
        height: 'inherit',
        borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    listItemPrice: {
        height: 'inherit',
        borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        userSelect: 'none',
        padding: '5px',
        flexDirection: 'column',
        justifyContent: 'space-around',
        '@media (max-width: 1140px)': {
            display: 'none',
        },
    },
    listItemPriceWithDiscount: {
        height: 'inherit',
        borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
        padding: '5px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        lineHeight: '400%',
        '@media (max-width: 1140px)': {
            lineHeight: '100%',
        },
    },
    listItemHeaderOfGroup: {
        background: theme.palette.primary.main,
        color: 'rgb(70, 70, 70)',
    },
    addRemoveButton: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    complexIconButton: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    orderButtonContainer: {
        padding: '5px 0',
        '@media (max-width: 1140px)': {
            padding: '5px',
        },
    },
    orderButton: {
        width: '100%',
    },
    listImg: {
        height: '25px',
        marginLeft: ' -20px',
        marginTop: '-18px',
    },
    listItemPriceNumber: {
        alignSelf: 'center',
        color: theme.palette.error.main,
    },
    catalogSwitchControl: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '10px 0 0 0',
        position: 'sticky',
        top: 0,
        alignItems: 'baseline',

        '@media (max-width: 768px)': {
            padding: 0,
        },
        '@media (max-width: 900px)': {
            display: 'none',
        }
    },
    catalogSwitchControlLabel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: theme.palette.primary.dark,
        whiteSpace: 'nowrap',
    },
    autoCompleteWrapper: {
        width: '30%',
        position: 'relative',

        '@media (max-width: 900px)': {
            display: 'none',
        },
    },
    autoComplete: {
        width: '100%',
    },
    textFieldSearch: {
        width: '30%',

        '@media (max-width: 900px)': {
            width: '95%',
        }
    },
    textFieldSearchFullWidth: {
        width: '95%',
    },
    locationSVGButton: {
        position: 'absolute',
        top: '20px',
        right: '5px',
        alignItems: 'center',
        cursor: 'pointer',
    },
    locationSVG: {
        margin: '0 auto',
    },
    errorMessage: {
        textAlign: 'center',
    },
    listForComplexFillings: {
        listStylePosition: 'inside',
    },
    assaysNamesInComplex: {
        fontSize: '13px',
        color: 'rgba(0, 0, 0, 0.6)'
    },
    alphabetWrapper: {
        width: '100%',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '8px',
        textAlign: 'center',

        '@media (max-width: 900px)': {
            display: 'none',
        }
    },
    activeLetter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: theme.palette.secondary.main,
        fontWeight: 600,
        cursor: 'default',
        transition: '.5s',
        padding: '0 8px',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
    },
    letter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: theme.palette.primary.main,
        fontWeight: 600,
        cursor: 'pointer',
        transition: '.5s',
        '&:hover': {
            transition: '.5s',
            opacity: '.5'
        },
    },
    notActiveLetter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: 'rgba(0, 0, 0, 0.3)',
        fontWeight: 600,
        cursor: 'default',
    },
    autocomplete: {
        width: '280px',

        '@media (max-width: 1180px)': {
            width: '170px',
        },
    },
    rootAutocomplete: {
        borderRadius: '18px',
        height: '38px',
        transition: 'all .4s',

        '&&& $input': {
            transition: 'all .4s',
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: '61px',

            '@media (max-width: 1180px)': {
                paddingRight: '0px !important',
            }
        },
    },
    regionsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(1),

        '@media (max-width: 900px)': {
            height: 'max-content',
        }
    },
}));

function SearchIcon() {
    return null;
}

export default function CatalogEsculab({isComponentInShop, isCalculator, idDoctorMedis}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { orderingList, amountOfItems } = useSelector(state => state.orderAssays);
    const { themeMaterialUI } = useSelector(state => state.theme);
    const {catalog, complexResearch, takeId, complexId, toggleChecked, filteredValue, actions}  = useSelector(state => state.assaysCatalog);
    const [catalogWithoutTakes, setCatalogWithoutTakes] = useState(null);
    const [initialCatalogWithoutTakes, setInitialCatalogWithoutTakes] = useState(false);
    const listRef = useRef();
    const itemRef = useRef();
    const history = useHistory();
    const {punkts, regions, regionConfirmation} = useSelector(store => store.collectionPoints);
    const [isUserType, setIsUserType] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [isUserSelectPoint, setIsUserSelectPoint] = useState(false);
    const [activeLetter, setActiveLetter] = useState('Всі');
    const filteredOptions = createFilterOptions({
        limit: 100
    });
    const isDoctor = localStorage.getItem('isDoctor');
    const isPartner = !!process.env.REACT_APP_API_PATH;
    const [value, setValue] = useState(null);
    const idReg = localStorage.getItem('idReg');
    const insurance = process.env.REACT_APP_INSURANCE === 'true'

    const ukAlphabet = ['Всі','А','Б','В','Г','Ґ','Д',
        'Е','Є', 'Ж','З','И','І','Ї','Й','К','Л','М','Н',
        'О','П','Р','С', 'Т','У','Ф','Х','Ц','Ч','Ш','Щ','Ь','Ю','Я'];

    const handleChangeRegion = async (e, value) => {
        e.persist();

        if (value) {
            if (amountOfItems) {
                dispatch(clearOrderedAssays());
            }
            localStorage.setItem('idReg', value.idReg + '');
            setValue(value);
            setCatalogWithoutTakes(null);
            await dispatch(getCatalog(true, true));
            dispatch(setCatalogFilteredValue(''));
            handleChangeFilterValue({target: {value: ''}});
        }
    };

    const handleClose = (reason) => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClickOnPunkt = (event, point) => {
        if (point && point.idReg && event.type !== 'blur') {
            localStorage.setItem('idReg', `${point.idReg}`);
            dispatch(getCatalog(true));
            setSelectedPoint(point);
            setIsUserSelectPoint(true);
            dispatch(clearOrderedAssays());
        }
    };

    const measuredRef = useCallback(node => {
        if (toggleChecked && node) {
            node.scrollIntoView({
                block: 'start',
            });
        }
    }, [toggleChecked]);

    const {
        filteredState,
        filterValue,
        handleChangeFilterValue,
    } = useAssaysFilter(catalogWithoutTakes);

    const handleSearchTextFieldValue = (event) => {
        if (activeLetter !== "Всі") {
            setCatalogWithoutTakes(initialCatalogWithoutTakes);
            setActiveLetter("Всі");
        }
        handleChangeFilterValue(event);
        dispatch(setCatalogFilteredValue(event.target.value));
        if (!isUserType) setIsUserType(true);
    }

    const scrollIndex = filterValue === '' ? +localStorage.getItem(normIndexLocalStorageKey) : 0;

    const handleTransitionToOrderManager = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (takeId && takeId === 216) {
            isComponentInShop
                ? history.push('/shop-order-manager')
                : history.push('/order-manager')
        } else {
            isDoctor
                ? history.push('/order-manager')
                : isComponentInShop
                    ? history.push('/shop-order-manager')
                    : history.push('/order-manager')
        }
    };

    const handleToggleChecked = () => {
        dispatch(setToggleChecked(!toggleChecked));
    };

    useEffect(() => {
        if (regions && regions.length > 0 && !selectedPoint && !isUserSelectPoint) {
            const idReg = localStorage.getItem('idReg');
            setSelectedPoint(regions.find(item => +item.idReg === +idReg));
        }

        if (catalog && complexResearch && typeof catalog !== "string" && typeof complexResearch !== "string" && !catalogWithoutTakes) {
            setCatalogWithoutTakes([...catalog,
                {
                    idParent: 0,
                    superGroupName: 'Комплексні дослідження.Комплексні дослідження',
                    name: 'Комплексні дослідження'
                },
                ...complexResearch]
                .filter(item => !item.notSale || isPartner)
                .map((item, idx) => ({
                    ...item,
                    normIndexLocalStorageKey: idx,
                })));
        }

        if (!initialCatalogWithoutTakes && catalogWithoutTakes) {
            setInitialCatalogWithoutTakes(catalogWithoutTakes);
        }
    },[catalog, dispatch, catalogWithoutTakes, takeId, complexId, punkts, selectedPoint, isUserSelectPoint,
        regions, initialCatalogWithoutTakes]);

    useEffect(() => {
        if (filteredValue && !isUserType && catalog && filteredState) {
            handleChangeFilterValue({target: {value: filteredValue}})
            setIsUserType(true);
        }
    }, [filteredValue, isUserType, catalog, filteredState]);

    useEffect(() => {
        if (!punkts) dispatch(getPunkts());
    }, [dispatch, punkts]);

    useEffect(() => {
        if (!catalog && takeId && complexId) dispatch(getCatalog());
    }, [catalog, takeId, dispatch, complexId]);

    useEffect(() => {
        if (amountOfItems === 1 && !regionConfirmation && !idDoctorMedis && takeId === 216 && themeMaterialUI) {
            CustomDialog(ConfirmRegionDialog, {
                themeMaterialUI,
            }, false, themeMaterialUI);
        }
    }, [amountOfItems, regionConfirmation]);

    useEffect(() => {
        if (!regions) {
            dispatch(setRegions());
        } else if (regions && !value) {
            setValue(regions.find((item, idx) => idReg ? +item.idReg === +idReg : idx === 0));
        }
    }, [regions]);

    if (!catalogWithoutTakes && typeof catalog !== "string") {
        return <Loading/>
    } else if (typeof catalog === "string") {
        return <div className={classes.errorMessage}>Вибачте, сталась помилка під час завантаження.</div>
    }

    function rowRenderer(props) {
        const { key, index, style } = props;
        const item = filteredState[index];
        const scrollIndex = filterValue === '' ? +localStorage.getItem(normIndexLocalStorageKey) : 0;

        const handleTransitionToComplexResearch = (event, item) => {

            event.stopPropagation();
            event.preventDefault();

            const foundedComplex = complexResearch.filter(com => com
                && com.filling
                && com.filling.find(fill => item
                    && item.filling
                    && !!item.filling.find(itemFill => itemFill.id === fill.id))
                        || (com.idGrTest && com.idGrTest.find(grTest => grTest.id === item.id)));

            dispatch(setSelectedComplex({
                assayName: item.name
                    ? item.name
                    : item.testNames.split(',')[0].split('\\\\').join(''),
                foundedComplex
            }));

            isComponentInShop
                ? history.push('/shop-complex')
                : history.push('/comprehensive-research')
        };

        const newStyles = {
            ...style,
            background: isIncludedInOrderingList(item, orderingList)
                ? 'rgba(0, 0, 0, 0.2)'
                : takeId === 216
                    ? index%2 === 0 ? 'none': 'rgba(0, 0, 0, 0.05)'
                    : 'none',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        };

        if (scrollIndex === index) newStyles.borderBottom = takeId === 216 ? '3px solid #2cb8ad' : '3px solid #e4002b88';

        const saveIndexToLocalStore = () => {
            if (item.type === 'complex') dispatch(setSelectedComplex(item.name));
            localStorage.setItem(normIndexLocalStorageKey, item.normIndexLocalStorageKey);
        };

        const unavailablePunkts = item && item.filling && (item.filling.filter(fill => fill.unavailable && fill.unavailable.length > 0).length > 0)

        const tooltipTitle = (unavailablePunkts
            ? 'Дослідження тимчасово не виконується'
            : 'Натисніть щоб переглянути інформацію про дослідження')
        return (
            +item.idParent !== 0
                ? <Link
                    key={key}
                    ref={itemRef}
                    onClick={saveIndexToLocalStore}
                    style={{
                        marginLeft: '4px',
                        textDecoration: 'none',
                        color: 'inherit',
                    }}
                    to={{
                        pathname: isComponentInShop
                            ? item.type === 'complex' ? `/shop-complex-info/${item.id}` : `/shop-assay-info/${item.id}`
                            : (item.type === 'complex' && !insurance) ? `/complex-info/${item.id}` :`/assay-info/${item.id}`,
                    }}>
                    <div style={newStyles} className={classes.listItem}>
                        <div
                            className={item.type === 'complex'
                                ? classes.listItemTitleForComplex
                                : classes.listItemTitle
                            }
                        >
                            <p style={{overflow: 'hidden'}}>
                                {item.name
                                    // ? takeId === 14 ? item.name : item.name.split(')')[0] + (item.name.indexOf('(') >= 0 ? ') ' : ' ')
                                    // : item.testNames.split(',')[0].split('\\\\').join('')
                                }
                            </p>
                            {item.type === 'complex' && <ol className={classes.listForComplexFillings}>
                                {[...item.filling, ...item.idGrTest].map((fill, idx) => (<li key={fill.id + ' ' + idx} className={classes.assaysNamesInComplex}>{fill.name}</li>))}
                            </ol>
                            }
                        </div>
                        <div className={classes.listItemTime}>
                            {item.duration_day}
                        </div>
                        <div className={classes.listItemComplex}>
                            {isIncludedInComplexResearch(item, complexResearch)
                                ? isCalculator
                                    ? '+'
                                    : <Tooltip title={process.env.REACT_APP_INSURANCE !== 'true'
                                        ? "Натисніть щоб переглянути комплекси з цим дослідженням."
                                        : tooltipTitle} arrow>
                                        {process.env.REACT_APP_INSURANCE !== 'true' ? <IconButton
                                            size="small"
                                            onClick={(event) => handleTransitionToComplexResearch(event, item)}
                                            className={classes.addRemoveButton}
                                            classes={{
                                                root: classes.complexIconButton
                                            }}
                                        >
                                            {takeId === 216
                                                ? <img src={PiggyBank} style={{width: '40px'}} alt="Piggy bank."/>
                                                : <AddShoppingCart style={{width: '40px'}}/>}
                                        </IconButton> : <IconButton
                                            size="small"
                                            // onClick={(event) => history.push(item.type === 'complex' ? `/complex-info/${item.id}` :`/assay-info/${item.id}`)}
                                            className={classes.addRemoveButton}
                                            classes={{
                                                root: classes.complexIconButton
                                            }}
                                        >
                                            <InfoIcon size="small" color={unavailablePunkts ? 'secondary' : "primary"}/>
                                        </IconButton>}
                                </Tooltip>
                                : process.env.REACT_APP_INSURANCE !== 'true' ? '-' : <Tooltip title={process.env.REACT_APP_INSURANCE !== 'true'
                                    ? "Натисніть щоб переглянути комплекси з цим дослідженням."
                                    : tooltipTitle} arrow>
                                    <IconButton
                                        size="small"
                                        // onClick={(event) => history.push(item.type === 'complex' ? `/complex-info/${item.id}` :`/assay-info/${item.id}`)}
                                        className={classes.addRemoveButton}
                                        classes={{
                                            root: classes.complexIconButton
                                        }}
                                    >
                                        <InfoIcon size="small" color={unavailablePunkts ? 'secondary' : "primary"}/>
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                        <div className={classes.listItemPrice}>
                            {process.env.REACT_APP_INSURANCE !== 'true' ? +item.price : ''}
                        </div>
                        <div className={classes.listItemPriceWithDiscount}>
                            <div className={classes.listItemPriceNumber}>{isDoctor ? item.price : item.price - item.price * item.discount / 100}</div>
                            <div className={classes.addRemoveButton}>
                                {+item.idParent !== +takeId && !!item.price && <AddRemoveIconButtons
                                    item={item}
                                    isIncludedInOrderingList={isIncludedInOrderingList(item, orderingList)}
                                />}
                            </div>
                        </div>
                    </div>
                </Link>
                : <div
                    key={key}
                    style={{...newStyles, background: takeId === 216 ? '#FFC100' : '#0077c85e'}}
                >
                    <div className={classes.listItemGroupTitle}>
                        <p  style={{overflow: 'hidden'}}>{item.superGroupName.split('.')[1] ? item.superGroupName.split('.')[1] : item.superGroupName.split('.')[0]}</p>
                    </div>
                </div>
        );
    }

    const noRowRenderer = () => (<div>
        <div style={{ background: takeId === 216 ? '#FFC100' : '#0077c85e'}}>
            <div className={classes.listItemGroupTitle}>
                <p>Вибачте, нічого не знайдено.</p>
            </div>
        </div>
        <div className={classes.orderButtonContainer}>
            <Button
                className={classes.orderButton}
                variant="outlined"
                color="primary"
                disabled={amountOfItems < 1}
                onClick={handleTransitionToOrderManager}>
                {amountOfItems > 0 ? 'Перейти до замовлення' : 'Чомусь у Вашому кошику ще нічого немає'}
            </Button>
        </div>
    </div>);

    const getAssayRowHeight = (index, width) => {
        const name = filteredState[index].name
            ? takeId === 216 ? filteredState[index].name : (filteredState[index].name.split(')')[0] + (filteredState[index].name.indexOf('(') >= 0 ? ') ' : ' '))
            : (filteredState[index].testNames.split(',')[0].split('\\\\').join(''));

        if (filteredState[index].superGroupName) return 50;

        if (!filteredState[index].filling) filteredState[index].filling = []
        
        const symbolWidth = 16;
        const lineHeight = 14;
        const result = Math.ceil((name.length*symbolWidth)/(width/2)) * lineHeight;
        const fillingNameLength = width > 800 ? 60 : width > 600 ? 40  : width > 500 ? 35 : width > 400 ? 30 : 25;

        return result > 50
            ? filteredState[index].type === "complex"
                ? +result + ([...filteredState[index].filling, ...filteredState[index].idGrTest].reduce((acu, cur) => {
                if (cur?.name?.length > fillingNameLength) return acu + Math.ceil(cur?.name?.length/fillingNameLength);
                return acu + 1;
            }, 0) * 17)
                : result
            : filteredState[index].type === "complex"
                ? 50 + ([...filteredState[index].filling, ...filteredState[index].idGrTest].reduce((acu, cur) => {
                if (cur?.name?.length > fillingNameLength) return acu + Math.ceil(cur?.name?.length/fillingNameLength);
                return acu + 1;
            }, 0) * 17)
                : 50
    };

    const Letter = ({letter}) => {
        const handleClick = () => {
            if (filterValue && filterValue.length > 0) {
                handleChangeFilterValue({target: {value: ''}});
                dispatch(setCatalogFilteredValue({target: {value: ''}}));
            }
            setActiveLetter(letter);
            if (+localStorage.getItem(normIndexLocalStorageKey) !== 0) localStorage.setItem(normIndexLocalStorageKey, '0');
            letter === 'Всі'
                ? setCatalogWithoutTakes(initialCatalogWithoutTakes)
                : setCatalogWithoutTakes(initialCatalogWithoutTakes.filter(item => item.name.indexOf(letter) === 0 && !item.superGroupName))
        }

        const isAssaysStartsWithLetter = letter === 'Всі' ? initialCatalogWithoutTakes : initialCatalogWithoutTakes.filter(item => item.name.indexOf(letter) === 0);

        return (isAssaysStartsWithLetter.length > 0
                ? <div
                    className={activeLetter === letter ? classes.activeLetter : classes.letter}
                    onClick={() => activeLetter !== letter && handleClick()}
                >
                    {letter}
                </div>
                : <div
                    className={classes.notActiveLetter}
                >
                    {letter}
                </div>
        )
    };

    return (
        <div className={classes.root} ref={measuredRef}>
            <div className={classes.textFieldContainer}>
                <TextField
                    fullWidth
                    label="Пошук"
                    value={filterValue}
                    onChange={handleSearchTextFieldValue}
                    variant="outlined"
                    className={takeId === 216 ? classes.textFieldSearch : classes.textFieldSearchFullWidth}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }}
                />
                {/*<SearchTextField*/}
                {/*    value={filterValue}*/}
                {/*    onChange={(event) => handleSearchTextFieldValue(event)}*/}
                {/*    className={takeId === 14 ? classes.textFieldSearch : classes.textFieldSearchFullWidth}*/}
                {/*/>*/}
                {!isPartner && !idDoctorMedis && <div className={classes.regionsWrapper}>
                    {regions
                        ? <Autocomplete
                            className={classes.autocomplete}
                            options={regions}
                            value={value}
                            onChange={handleChangeRegion}
                            noOptionsText={'Міста не знайдено.'}
                            renderInput={(params) => (
                                <TextField {...params} label="Вибір міста" variant="outlined" InputProps={{
                                    ...params.InputProps,
                                    classes: {
                                        root: classes.rootAutocomplete,
                                        notchedOutline: classes.notchedOutline,
                                    }
                                }}
                                />
                            )}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(item) => item?.idReg === value?.idReg}
                        />
                        : <Loading/>
                    }
                </div>}
                {takeId === 216 && !idDoctorMedis && <div className={classes.catalogSwitchControl}>
                    <label htmlFor="toggleChecked" className={classes.catalogSwitchControlLabel}>Групи
                        Аналізів</label>
                    <Switch id="toggleChecked" color="primary" checked={toggleChecked} onChange={handleToggleChecked}/>
                    <label htmlFor="toggleChecked" className={classes.catalogSwitchControlLabel}>Список Аналізів</label>
                </div>}
                {takeId === 216 && !idDoctorMedis && <div className={classes.autoCompleteWrapper}>
                    <Autocomplete
                        key={'assayCatalog'+idReg}
                        className={classes.autoComplete}
                        autoSelect={true}
                        id="combo-box"
                        open={open}
                        disableClearable={true}
                        onOpen={handleOpen}
                        onClose={(event, reason) => handleClose(reason)}
                        freeSolo
                        value={selectedPoint}
                        options={
                            regions
                                ? regions
                                : []
                        }
                        filterOptions={filteredOptions}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => handleClickOnPunkt(event, value)}
                        renderInput={(params) => (<TextField
                            {...params}
                            label="Виберіть місто зі списку"
                            variant="outlined"
                        />)}
                    />
                    <div
                        onClick={() => isComponentInShop
                            ? history.push('/shop-collection-points')
                            : history.push('/collection-points')
                        }
                        className={classes.locationSVGButton}
                    >
                        <LocationSVG className={classes.locationSVG}/>
                    </div>
                </div>}
            </div>
            {initialCatalogWithoutTakes && <div className={classes.alphabetWrapper}>
                {ukAlphabet.map((letter, idx) => (<Letter letter={letter} key={letter+idx}/>))}
            </div>}
            <div className={classes.listHeadersContainer} style={filteredState.length <= 10 ? {paddingRight: '0px',} : {}}>
                <div className={classes.listHeaderAssayName}>
                    <p>
                        Назва дослідження
                    </p>
                </div>
                <div className={classes.listHeader}>
                    <p>
                        Термін виконання,
                        <br/>
                        <span className={classes.listHeaderTinyText}> робочих днів</span>
                    </p>
                </div>
                <div className={classes.listHeader}>
                    <p>
                        {process.env.REACT_APP_INSURANCE !== 'true' ? 'Дослідження у комплексі' : 'Детальніше про дослідження'}
                    </p>
                </div>
                <div className={classes.listHeader}>
                    <p>
                        {process.env.REACT_APP_INSURANCE !== 'true' && 'Ціна, грн'}
                    </p>
                </div>
                <div className={classes.listHeader}>
                    <p>
                        Ціна {process.env.REACT_APP_INSURANCE !== 'true' && <><br/> он-лайн, <br/></>} грн
                    </p>
                    {takeId === 216 && <span className={classes.listHeaderRedDiscount} style={{display: 'flex'}}>%</span>}
                </div>
            </div>
            <div>
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            subheader={<div />}
                            className={classes.list}
                            width={width}
                            height={height}
                            ref={listRef}
                            scrollToIndex={scrollIndex}
                            noRowsRenderer={noRowRenderer}
                            scrollToAlignment="start"
                            rowCount={filteredState.length}
                            rowHeight={({ index }) => getAssayRowHeight(index, width)}
                            rowRenderer={rowRenderer}
                        />)}
                </AutoSizer>
            </div>
            <div className={classes.orderButtonContainer}>
                <Button
                    className={classes.orderButton}
                    variant="outlined"
                    color="primary"
                    disabled={amountOfItems < 1}
                    onClick={handleTransitionToOrderManager}>
                    {amountOfItems > 0 ? 'Перейти до замовлення' : 'Чомусь у Вашому кошику ще нічого немає'}
                </Button>
            </div>
        </div>
    );
}
