import React, {useState} from "react";
import {Dialog, Slide} from "@material-ui/core";
import useStyles from "./styles";
import PortmonePaymentPage from "@cabinet-packages/pages/portmone/PortmonePaymentPage";
import {setOrderId} from "@cabinet-packages/redux/actions/actionsCreators";
import {useDispatch} from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PortmoneDialog = ({handleClose}) => {
    const cl = useStyles();
    const [open, setOpen] = useState(true);
    const dispatch = useDispatch();

    const handleOnClose =  () => {
        setOpen(false);
        setTimeout(handleClose,500);
        dispatch(setOrderId(null));
    };

    return (<Dialog fullScreen open={open} onClose={handleOnClose} TransitionComponent={Transition} id="exception-dialog">
        {/*<div className={cl.appBar}>*/}
        {/*    <p className={cl.headingText}>Оплата замовлення</p>*/}
        {/*    <IconButton onClick={handleOnClose}>*/}
        {/*        <CloseIcon/>*/}
        {/*    </IconButton>*/}
        {/*</div>*/}
        <PortmonePaymentPage isNewShop={true}/>
    </Dialog>);
};

export default PortmoneDialog;
