import React, {createContext, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    Fade,
    InputAdornment,
    Menu,
    Paper,
    useMediaQuery,
} from '@material-ui/core';
import {GoogleMap, withGoogleMap, withScriptjs,} from 'react-google-maps';
import {CustomDialog, Loading, SwipeStyledButton, TextAlertDialog,} from '@cabinet-packages/components';
import {
    addAssayToOrderList,
    clearOrderedAssays,
    getCatalog,
    getClientLocation,
    setPromoCode
} from "@cabinet-packages/redux";
import {useDispatch, useSelector} from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {createFilterOptions} from "@material-ui/lab";
import {useHistory} from "react-router-dom";
import location from './img/CollectionPointsAssay/Location_kids.png';
import OverlayView from "react-google-maps/lib/components/OverlayView";
import arrow from './img/CollectionPointsAssay/arrow.png';
import icnMap from './img/CollectionPointsAssay/icn_map.png';
import icnList from './img/CollectionPointsAssay/list.png';
import Departure from "./components/Departure";
import {getActionsFromServer, getComplexOfAssays, getPriceForRegion, getRegions} from "@cabinet-packages/api/api";
import TablePoints from "./components/TablePoints";
import AccordionPoints from "./components/AccordionPoints";
import IconButton from "@material-ui/core/IconButton";
import arrowFilterImg from "./img/arrow-dropdown-filter.png"
import UnlockImg from "./img/icn_unlock.png";
import SyringeImg from "./img/icn_syringe.png";
import LockImg from "./img/icn_lock.png";
import locationActive from './img/CollectionPointsAssay/location_active_kids.png'
import {Close} from "@material-ui/icons";
import closeImg from './img/close.png';
import optionsImg from './img/options.png';
import {getAllPunkts, toggleTakeInOrder} from "@cabinet-packages/redux/actions/actionsCreators";
import {createAssaysData} from "./helpers/createAssaysData";
import useStyles from "./styles";

const MapContext = createContext(null);
const MapProvider = MapContext.Provider;
const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -height,
})

const filters = [
    {
        key: "paymentcar",
        label: "Оплата картою",
    },
    {
        key: "fence1year",
        label: "Забір дітям до 1 року",
    },
    {
        key: "covid19",
        label: "COVID-19",
    },
    {
        key: "urogenitalfence",
        label: "Урогенітальний забір",
    },
    {
        key: "ramp",
        label: "Пандус",
    },
    {
        key: "worksonsun",
        label: "Працює в неділю",

    },
    {
        key: "worksonsat",
        label: "Працює в суботу",

    },
]

function CollectionPointsKids({isComponentInShop, inFrame, showAllPunkts}) {
    const classes = useStyles();
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [isUserSelectPoint, setIsUserSelectPoint] = useState(false);
    const isMountedRef = useRef(null);
    const dispatch = useDispatch();
    const {clientLocation, punkts, selectPunkt} = useSelector(store => store.collectionPoints);
    const {amountOfItems, orderingList, takeInOrder} = useSelector(store => store.orderAssays);
    const {takeId, complexId} = useSelector(store => store.assaysCatalog);
    const {themeMaterialUI} = useSelector(store => store.theme);
    const [open, setOpen] = useState(false);
    const [openRegion, setOpenRegion] = useState(false);
    const history = useHistory();
    const [switcher, setSwitcher] = useState(false);
    const [points, setPoints] = useState([]);
    const [selectRegion, setSelectRegion] = useState('');
    const idReg = localStorage.getItem('idReg');
    const [expanded, setExpanded] = useState(false);
    const [checkboxState, setCheckboxState] = useState({});
    const googleMapStringURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${googleApiKey}&language=uk`;
    const [checkNearest, setCheckNearest] = useState({});
    const [selectedItem, setSelectedItem] = useState(false);
    const isMobileVersion = useMediaQuery('(max-width: 900px)');
    const [anchorFilter, setAnchorFilter] = useState(null);
    const [valueCity, setValueCity] = useState('');
    const [valueStreet, setValueStreet] = useState('');
    const [state, setState] = useState({checkedC: showAllPunkts});

    const filteredOptions = createFilterOptions({
        limit: state.checkedC ? 100 : 20
    });

    useEffect(() => {
        if (isMobileVersion) {
            setExpanded('panel1');
        }
    }, [isMobileVersion])


    const handleClickOnPunkt = (point, option, mobile) => {
        setSelectedPoint(point);
        setIsUserSelectPoint(true);
        dispatch(getClientLocation(point));
        setCheckNearest({...point, active: true});
        if (option) {
            setCheckboxState({})
        }
        if (mobile) {
            setAnchorFilter(null)
        }
    };

    const handleChangeRegionPrice = async () => {
        await dispatch(getCatalog(false, true));
        const isPartner = !!process.env.REACT_APP_API_PATH;

        const catalog = await getPriceForRegion(takeId, complexId).catch(onerror => {
            console.error(onerror)
            return "error"
        });
        const complexResearch = await getComplexOfAssays().catch(onerror => {
            console.error(onerror)
            return "error"
        });
        const actions = await getActionsFromServer().catch(onerror => {
            console.error(onerror)
            return "error"
        });

        if (amountOfItems > 0) {
            const prevOrderingList = orderingList;
            dispatch(clearOrderedAssays());
            dispatch(setPromoCode(null));
            if (takeInOrder) {
                dispatch(toggleTakeInOrder());
            }

            createAssaysData(
                prevOrderingList,
                catalog !== 'error'
                    ? catalog.filter(item => (!item.notSale && (item.opt ? item.opt.includes('public') : true)))
                    : catalog,
                complexResearch.map(item => ({...item, type: 'complex'})),
                typeof actions === "string"
                    ? actions
                    : takeId === 14
                        ? actions.map(action => {
                            const foundComplex = [...complexResearch, ...catalog].find(com => com.id === action.idGrTest);

                            return ({...foundComplex, ...action, name: action.title, type: 'action'});
                        })
                        : actions.map(action => ({...action, name: action.title,  type: 'action'}))
            ).forEach(assay => {
                if (assay && assay.id && +assay.idParent !== +takeId) {
                    dispatch(addAssayToOrderList(
                        assay.id,
                        assay.discount
                            ? assay.price - assay.price * assay.discount / 100
                            : assay.price,
                        () => {
                        },
                        assay.type,
                    ));
                }
            });
        }
    }

    const handleClickOnPunktRegion = (event, value, dontCallAllPunkts) => {

        if (value && value.name === 'Усі пункти' && !dontCallAllPunkts) {
            handleChangeAllPunkts({
                persist: () => {},
                target: {
                    checked: true,
                    name: 'checkedC'
                }
            })
        } else if (value && value.idReg) {
            // setCheckNearest({})
            setValueCity(value.name);
            localStorage.setItem('idReg', value.idReg);
            dispatch(getClientLocation(null, true))
            setCheckboxState({});
            handleChangeRegionPrice();
            setSelectedPoint(null);
            if (state.checkedC && !dontCallAllPunkts) {
                handleChangeAllPunkts({
                    persist: () => {
                    },
                    target: {
                        checked: false,
                        name: 'checkedC'
                    }
                })
            }
        }
    };

    const handleClose = (reason) => {
        setOpen(false);
        if (reason === 'blur') setIsUserSelectPoint(false);
    };

    const handleCloserRegion = (reason) => {
        setOpenRegion(false)
    }


    const getClosestPunkt = useCallback(() => {
        if (!clientLocation) CustomDialog(TextAlertDialog, {
            text: `Будь ласка, дозвольте використання геолокації на вашому пристрої.`,
            onConfirm: () => {
            },
        }, false, themeMaterialUI);
        // if (punkts) setSelectedPoint(punkts[0]);
    }, [clientLocation, punkts]);

    useEffect(() => {
        isMountedRef.current = true;
        dispatch(getAllPunkts(false, false, false, true));

        if (clientLocation && punkts && punkts.length > 0 && !isUserSelectPoint) getClosestPunkt();
        // if (punkts && punkts.length > 0 && !selectedPoint) setSelectedPoint(punkts[0]);
        return () => isMountedRef.current = false;
    }, [clientLocation, punkts, selectedPoint, dispatch, getClosestPunkt, isUserSelectPoint]);


    const resultRegion = async () => {
        const res = await getRegions()
        const regionIds = punkts.filter(item => item.active && item.opt.includes('kids')).map(item => item.idCity+'');

        if (res) {
            setPoints(res
                .filter(item => regionIds.includes(item.idReg+''))
                .sort((a,b) => a.name.localeCompare(b.name)))
        }
    }

    useEffect(() => {
        if (points.length > 0 && points.find(el => el.idReg == idReg)?.name !== selectRegion) {

            setSelectRegion(points.find(el => el.idReg == idReg)?.name)
        }
    }, [points, idReg]);

    useEffect(() => {
        if (punkts) {
            resultRegion()
            setExpanded('panel1')
        }
    }, [punkts]);

    function cutWord(text, limit) {
        if (text === undefined) return;
        text = text.trim();
        if (text.length <= limit) return text;
        text = text.slice(0, limit);
        let lastSpace;
        lastSpace = text.lastIndexOf(" ");
        if (lastSpace > 0) {
            text = text.substr(0, lastSpace);
        }
        return text.includes('(') ? text + "...)" : text + "...";
    }

    const setOption = (el) => {
        if (selectRegion && el) {
            if (el.includes(selectRegion) && !state.checkedC) {
                return cutWord(el?.split(',').slice(1).join().trim(), 50)
            } else {
                return cutWord(el.trim(), 50)
            }
        }
    }

    const filterByOpt = () => {
        const arrayFiltered = Object.keys(checkboxState).filter((key) => checkboxState[key]);
        if (punkts) {
            const arrayCopy = state.checkedC ? [...punkts] : [...punkts].filter(el => +el.idCity === +idReg);
            if (arrayFiltered.length === 0) {
                return arrayCopy;
            }
            return arrayCopy.filter(punkt => arrayFiltered.filter(el => punkt.opt.includes(el)).length === arrayFiltered.length)
        }
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleCheckbox = (event) => {
        const checked = event.target.checked;
        const name = event.target.name;
        setCheckboxState(prev => ({...prev, [name]: checked}));
        if(isMobileVersion){
            setAnchorFilter(null)
        }
    }

    const handleCheckNearest = (event, el) => {
        setCheckNearest(prev => ({...el, [el.id]: !prev[el.id]}))
        dispatch(getClientLocation(el))
    }

    const handleRegionEndAdornment = (item) => {
        if (item === 'openRegion') {
            setOpenRegion(true)
        }
        if (item === 'openStreet') {
            setOpen(true)
        }
    }

    const handleChangeAllPunkts = (event) => {
        event.persist();

        // if (event.target.checked) {
        //     handleClickOnPunktRegion(false, {idReg: 2, name: 'Львів'}, true);
        //     setSelectRegion('Львів');
        //     setValueStreet('')
        // } else {
        //     handleClickOnPunktRegion(false, {idReg, name: points.find(el => el.idReg == idReg)?.name}, true);
        //     setSelectRegion(points.find(el => el.idReg == idReg)?.name);
        //     setSelectedPoint(null);
        //     setValueStreet('')
        // }

        setState(prev => ({...prev, [event.target.name]: event.target.checked}));
    }

    useEffect(() => {
        if (points) {
            setValueCity(points.find(el => +el.idReg === +idReg)?.name)
        }
        if (punkts) {
            const findSelektPunkt = filterByOpt().filter(el => +el.idCity === +idReg)[0]
            dispatch(getClientLocation(findSelektPunkt))
            setCheckNearest(findSelektPunkt);
            // setValueStreet(findSelektPunkt?.address)
        }
    }, [points, punkts])

    useEffect(() => {
        if(punkts && !selectPunkt){
            const  findStreetActive  = filterByOpt().find(el => +el.idCity === +idReg)
            setValueStreet(findStreetActive?.address)
            dispatch(getClientLocation(findStreetActive));
            setCheckNearest(findStreetActive);
        }
    },[valueStreet, punkts, idReg, selectPunkt]);

    useEffect(() => {
        if (selectPunkt?.address !== valueStreet?.address) {
            setValueStreet(selectPunkt?.address)
        }
    }, [selectPunkt]);

    return (punkts &&
        <>
            <div className={inFrame ? classes.containerWrapperFrame : classes.containerWrapper}>
                <div className={inFrame ? classes.rootFrame : classes.root}>
                    <div>
                        <div className={classes.mobileFilters}>
                            <div className={classes.filterPopupMobile}>
                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={(event) => setAnchorFilter(event.currentTarget)}
                                    className={classes.selectFilterPopup}
                                >

                                    {anchorFilter ? <img src={closeImg} className={classes.imgFilter} alt='closeImg'/> :
                                        <img src={optionsImg} className={classes.imgFilter} alt="optionsImg"/>}
                                    Фільтри
                                </Button>
                                <Menu
                                    keepMounted
                                    anchorEl={anchorFilter}
                                    open={Boolean(anchorFilter)}
                                    onClose={() => setAnchorFilter(null)}
                                    TransitionComponent={Fade}

                                    classes={{
                                        paper: classes.selectFilterWrapper
                                    }}
                                >
                                    <div>


                                        <Accordion className={classes.accordionItemWrapper}
                                                   expanded={expanded === 'panel1'}
                                                   onChange={handleChange('panel1')}>
                                            <AccordionSummary
                                                className={classes.accordionItem}
                                                classes={{
                                                    root: classes.accordionItemRoot,
                                                    content: classes.accordionItemContent,
                                                    expanded: classes.accordionItemExpanded,
                                                }}
                                                expandIcon={<IconButton className={classes.accordionItemDropImg}>{<img
                                                    src={arrowFilterImg}
                                                    alt="icon Arrow Filter"/>}</IconButton>}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <p className={classes.accordionItemTitle}>Популярні</p>
                                            </AccordionSummary>

                                            <AccordionDetails className={classes.accordionDetailsWrapper}>
                                                {filters.map((filter, index) => <div className={classes.checkboxWrapper}
                                                                                     key={index}>
                                                    <Checkbox
                                                        className={classes.checkboxItem}
                                                        checked={!!checkboxState[filter.key]}
                                                        onChange={(e) => handleCheckbox(e)}
                                                        name={filter.key}
                                                        color="primary"
                                                        size="small"
                                                    />
                                                    <label form={filter.key} onClick={(e) => handleCheckbox({target: {name: filter.key, checked: !checkboxState[filter.key]}})} style={{color: checkboxState[filter.key] ? '#00B3A1' : '#363D4D'}}>{filter.label}</label>
                                                </div>)}
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </Menu>
                            </div>
                            <div className={classes.wrapperSwitchMobile}>
                                <div onClick={() => setSwitcher(false)}
                                     style={{background: switcher ? '#ffffff' : '#F0F0F0'}}><img src={icnMap}
                                                                                                 alt="icnMap"/>
                                </div>
                                <div onClick={() => setSwitcher(true)}
                                     style={{background: !switcher ? '#ffffff' : '#F0F0F0'}}><img src={icnList}
                                                                                                  alt="icnList"/></div>
                            </div>
                        </div>
                        <Autocomplete
                            autoComplete={false}
                            autoSelect={true}
                            key={'autocompleteShow' + state.checkedC}
                            className={classes.inputWrapperRegionRoot}
                            open={openRegion}
                            onOpen={() => setOpenRegion(true)}
                            onClose={(event, reason) => handleCloserRegion(reason)}
                            freeSolo
                            options={points
                                ? showAllPunkts ? [{name: 'Усі пункти'}, ...points] : points
                                : []
                            }
                            renderOption={(props) => <p className={classes.autoCompleteRegionOpthion}>{props.name}
                                <span className={classes.autoCompleteRegionImgSelected}/></p>}
                            getOptionLabel={(option) => {

                                return (option.name || option);
                            }}
                            onChange={(event, value) => handleClickOnPunktRegion(event, value)}
                            renderInput={(params) => <TextField {...params} InputProps={{
                                ...params.InputProps,
                                // endAdornment: ()
                                classes: {
                                    root: classes.autoCompleteRegion,
                                    input: classes.autoCompleteInput
                                },
                                autoComplete: 'off',
                                endAdornment: (<InputAdornment

                                    position="end">
                                        <span onClick={() => handleRegionEndAdornment('openRegion')}
                                              className={classes.endAdornmentInput}>
                                        <img
                                            src={arrow} alt="arrow" className={classes.imgSelectPoint}/>
                                        </span>
                                </InputAdornment>),

                            }} name="none" placeholder={state.checkedC ? 'Усі пункти' : valueCity}
                                                                variant="outlined"/>}
                        />
                        <div className={classes.accordionWrapper}>
                            <p className={classes.accordionWrapperTitle}>Фільтри</p>
                            <Accordion className={classes.accordionItemWrapper} expanded={expanded === 'panel1'}
                                       onChange={handleChange('panel1')}>
                                <AccordionSummary
                                    className={classes.accordionItem}
                                    expandIcon={<IconButton className={classes.accordionItemDropImg}>{<img
                                        src={arrowFilterImg}
                                        alt="icon Arrow Filter"/>}</IconButton>}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <p className={classes.accordionItemTitle}>Популярні</p>
                                </AccordionSummary>

                                <AccordionDetails className={classes.accordionDetailsWrapper}>
                                    {filters.map((filter, index) => <div className={classes.checkboxWrapper}
                                                                         key={index}>
                                        <Checkbox
                                            className={classes.checkboxItem}
                                            checked={checkboxState[filter.key] ? checkboxState[filter.key] : false}
                                            onChange={(e) => handleCheckbox(e)}
                                            name={filter.key}
                                            color="primary"
                                            size="small"
                                        />
                                        <p style={{color: checkboxState[filter.key] ? '#00B3A1' : '#363D4D'}}>{filter.label}</p>
                                    </div>)}
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                    <div>
                        <div className={classes.wrapperSearch}>
                            <div className={classes.wrapperStreetDesktop}>
                                <Autocomplete
                                    autoComplete={false}
                                    open={open}
                                    key={valueStreet}
                                    onOpen={() => handleRegionEndAdornment('openStreet')}
                                    onClose={(event, reason) => handleClose(reason)}
                                    freeSolo
                                    options={punkts
                                        ? state.checkedC ? punkts : punkts.filter(el => +el.idCity === +idReg)
                                        : []
                                    }
                                    // value={}
                                    filterOptions={filteredOptions}
                                    getOptionLabel={(option) => option.address}
                                    // getOptionLabel={(option) => {
                                    //     return valueStreet
                                    // }}
                                    onChange={(event, value) => handleClickOnPunkt(value, true)}
                                    // style={{width: 300}}
                                    renderInput={(params) => <TextField {...params} InputProps={{
                                        classes: {
                                            root: classes.autoComplete,
                                            input: classes.autoCompleteInput
                                        },
                                        ...params.InputProps,
                                        autoComplete: 'off',
                                        endAdornment: <InputAdornment
                                            position="end">
                                            <span onClick={() => handleRegionEndAdornment('openStreet')}
                                                  className={classes.endAdornmentInput}>
                                            <img src={arrow} alt="arrow" className={classes.imgSelectPoint}/>
                                        </span>
                                        </InputAdornment>,

                                    }} name="none" placeholder={valueStreet} variant="outlined"/>}
                                />
                            </div>
                            <div className={classes.wrapperSwitch}>
                                <div onClick={() => setSwitcher(false)}
                                     style={{background: switcher ? '#ffffff' : '#F0F0F0'}}><img src={icnMap}
                                                                                                 alt="icnMap"/>
                                </div>
                                <div onClick={() => setSwitcher(true)}
                                     style={{background: !switcher ? '#ffffff' : '#F0F0F0'}}><img src={icnList}
                                                                                                  alt="icnList"/></div>
                            </div>
                        </div>

                        <div className={classes.wrapperMobileFilter}>
                            <div className={classes.mobileCityAvtoComplete}>
                                <Autocomplete
                                    autoComplete={false}
                                    key={'autocompleteShowSecond' + state.checkedC}
                                    autoSelect={true}
                                    open={openRegion}
                                    onOpen={() => setOpenRegion(true)}
                                    onClose={(event, reason) => handleCloserRegion(reason)}
                                    freeSolo
                                    options={points
                                        ? showAllPunkts ? [{name: 'Усі пункти'}, ...points] : points
                                        : []
                                    }
                                    renderOption={(props) => <p
                                        className={classes.autoCompleteRegionOpthion}>{props.name}
                                        <span className={classes.autoCompleteRegionImgSelected}/></p>}
                                    // getOptionLabel={(option) => setOption(option.name)}
                                    getOptionLabel={(option) => {

                                        return (option.name || option);
                                    }}
                                    onChange={(event, value) => handleClickOnPunktRegion(event, value)}
                                    renderInput={(params) => <TextField {...params} InputProps={{
                                        ...params.InputProps,
                                        classes: {
                                            root: classes.autoCompleteRegion,
                                            input: classes.autoCompleteInput
                                        },
                                        autoComplete: 'off',
                                        endAdornment: (<InputAdornment
                                            position="end">
                                                         <span onClick={() => handleRegionEndAdornment('openRegion')}
                                                               className={classes.endAdornmentInput}>
                                                        <img src={arrow} alt="arrow"
                                                             className={classes.imgSelectPoint}/>
                                                         </span>
                                        </InputAdornment>),
                                    }} name="none" placeholder={state.checkedC ? 'Усі пункти' : valueCity}
                                                                        variant="outlined"/>}
                                />
                            </div>
                            <div className={classes.inputWrapper}>
                                <Autocomplete
                                    autoComplete={false}
                                    open={open}
                                    key={valueStreet}
                                    onOpen={() => handleRegionEndAdornment('openStreet')}
                                    onClose={(event, reason) => handleClose(reason)}
                                    freeSolo
                                    options={punkts
                                        ? state.checkedC ? punkts : punkts.filter(el => +el.idCity === +idReg)
                                        : []
                                    }
                                    filterOptions={filteredOptions}
                                    getOptionLabel={(option) => option.address}
                                    onChange={(event, value) => handleClickOnPunkt(value, true, true)}
                                    renderInput={(params) => <TextField {...params} InputProps={{
                                        classes: {
                                            root: classes.autoComplete,
                                            input: classes.autoCompleteInput
                                        },
                                        ...params.InputProps,
                                        autoComplete: 'off',
                                        endAdornment: <InputAdornment
                                            position="end">
                                                        <span onClick={() => handleRegionEndAdornment('openStreet')}
                                                              className={classes.endAdornmentInput}>
                                                        <img src={arrow} alt="arrow"
                                                             className={classes.imgSelectPoint}/>
                                                            </span>
                                        </InputAdornment>,

                                    }} name="none" placeholder={valueStreet} variant="outlined"/>}
                                />

                            </div>
                        </div>
                        <MapProvider
                            value={{
                                punkt: selectedPoint,
                                lat: selectedPoint ? +selectedPoint.lat : punkts ? filterByOpt().length > 0 ? filterByOpt()[0].lat : punkts : 0,
                                lng: selectedPoint ? +selectedPoint.lng : punkts ? filterByOpt().length > 0 ? filterByOpt()[0].lng : punkts : 0,
                                markers: punkts ? filterByOpt() : [],
                                checkNearest: checkNearest,
                                selectedPoint: selectedPoint ? selectedPoint : {},
                                selectPunkt: selectPunkt,
                                isUserSelectPoint,
                                handleClickOnPunkt,
                                setCheckNearest,
                                inFrame: inFrame || showAllPunkts,
                                showAllPunkts: state.checkedC,
                            }}>
                            {checkNearest ? <div className={classes.rootPoints}>
                                {!switcher ? <div className={classes.mapWrapper}
                                                  style={history.location.pathname === '/wizard' || !isMobileVersion ? {borderRadius: '10px'} : {}}>
                                        {navigator.onLine ?
                                            <MapWrapper
                                                googleMapURL={googleMapStringURL}
                                                loadingElement={<div style={{height: `100%`}}/>}
                                                containerElement={<div style={{height: `100%`}}/>}
                                                mapElement={<div style={{height: `100%`}}/>}
                                            /> : <div className={classes.noConnection}>Немає мережі</div>
                                        }
                                    </div> :
                                    <div className={classes.collectionPointsWrapperMap}>
                                        {filterByOpt().length === 0 ?
                                            <p className={classes.filterText}>з даним фільтром нічого не найдено
                                            </p> : <>
                                                <div className={classes.accordionRoot}>
                                                    <div className={classes.accordionWrapperHeader}>
                                                        <p className={classes.accordionItemTitle}/>
                                                        <p className={classes.mainCell}/>
                                                        <p className={classes.cell}><img src={UnlockImg}
                                                                                         alt="Unlock Img"/>
                                                        </p>
                                                        <p className={classes.cell}><img src={SyringeImg}
                                                                                         alt="Syringe Img"/></p>
                                                        <p className={classes.cell}><img src={LockImg} alt="Lock Img"/>
                                                        </p>
                                                    </div>
                                                    {filterByOpt().map((el) => <AccordionPoints punkt={el}
                                                                                                selectPunkt={selectPunkt}
                                                                                                selectedItem={selectedItem}
                                                                                                setSelectedItem={setSelectedItem}
                                                                                                key={el.id}/>)}
                                                </div>
                                            </>}
                                    </div>}

                                <div className={classes.searchButtonContainer}>
                                    {typeof punkts === "string"
                                        ? <div className={classes.errorMessage}>Вибачте, сталась помилка під час
                                            завантаження.</div>
                                        : <Departure border={true}/>}
                                </div>

                                {!punkts && <div><Loading/></div>}
                                {punkts && punkts.length === 0 &&
                                    <div className={classes.noPunkts}>Вибачте, немає пунктів для відображення.</div>}
                                {punkts && !isMobileVersion &&
                                    <div className={classes.punkWrapper}>
                                        {
                                            filterByOpt().slice(0, 3).length > 2
                                                ? filterByOpt().slice(0, 3).map((el) => (
                                                    <Paper key={el.id}
                                                           className={el.id === selectPunkt?.id ? classes.punkWrapperItemSelected : classes.punkWrapperItem}
                                                           onClick={(e) => handleCheckNearest(e, el)}>
                                                        <h3 className={classes.punkWrapperTitle}>{el.address}</h3>
                                                        <TablePoints cl={classes} punkt={el}/>
                                                    </Paper>
                                                ))
                                                : filterByOpt().slice(0, 3).map((el) => (
                                                    <Paper key={el.id}
                                                           className={el.id === selectPunkt?.id ? classes.punkWrapperItemSelected : classes.punkWrapperItem}
                                                           onClick={(e) => handleCheckNearest(e, el)}>
                                                        <h3 className={classes.punkWrapperTitle}>{el.address}</h3>
                                                        <TablePoints cl={classes} punkt={el}/>
                                                    </Paper>
                                                ))

                                        }
                                    </div>}

                            </div> : <p className={classes.errorMessage}>Вибачте, в даному регіоні відсутні пункти.</p>}
                        </MapProvider>
                    </div>
                </div>

            </div>
        </>
    );
}

function Map() {
    const coordinates = useContext(MapContext);
    const cl = useStyles();
    const dispatch = useDispatch();
    const isDesktopVersion = useMediaQuery('(min-width: 900px)') ? 0.2 : 0.7;
    const [open, setOpen] = useState(false);
    const zoomRef = useRef(null)
    const {selectPunkt} = useSelector(store => store.collectionPoints);
    const [changeZoom, setChangeZoom] = useState(false);
    const [zoom, setZoom] = useState(coordinates.showAllPunkts && !changeZoom ? 6 : 12);
    const [zomLat, setZomLat] = useState(zoom === 6 ? (zoom/12) + isDesktopVersion : ('0.0') + `${zoom / 12}`.replace('.', ''));

    function isEmptyObject(obj) {
        for (let i in obj) {
            if (obj.hasOwnProperty(i)) {
                return false;
            }
        }
        return true;
    }

    const MarkerWithPopper = ({punkt, coordinates}) => {
        const [selectedPunkt, setSelectedPunkt] = useState(false);
        const history = useHistory();

        const handleClick = (event) => {
            coordinates.setCheckNearest(punkt)
            event.stopPropagation();
            event.preventDefault();
            setOpen(punkt.id)
            setSelectedPunkt((prev) => !prev)
            setChangeZoom(true);
        };

        const handleSelectPunkt = (e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(false)
            dispatch(getClientLocation(punkt))
            coordinates.setCheckNearest({})
            setChangeZoom(true);
            history.push('/kids/time-picker');
        }
        //setCheckNearest

        useEffect(() => {
            if (coordinates.checkNearest && coordinates.checkNearest.id === punkt?.id) {
                setOpen(punkt?.id);
            } else {
                // setOpen(false)
            }
        }, [punkt, coordinates.checkNearest]);

        const handleClose = () => {
            coordinates.setCheckNearest({...coordinates.setCheckNearest, active: false})
            setOpen(false);
            setChangeZoom(false)
        }

        return (punkt && <OverlayView
            position={{lat: punkt.lat, lng: punkt.lng}}
            getPixelPositionOffset={getPixelPositionOffset}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            key={punkt.id+'marker'+(coordinates.checkNearest?.id || '')}
        >
            <div className={cl.markerWrapper}>
                {open === punkt.id && <div className={cl.popper}>
                    <div className={cl.popperAddressWrapper}>
                        <p className={cl.popperAddress}>{punkt.address && punkt.address}</p>
                        <IconButton onClick={handleClose} className={cl.popperMarkerCloseButton}><Close/></IconButton>
                    </div>
                    <TablePoints cl={cl} punkt={punkt}/>

                    {!coordinates.inFrame && <div className={cl.SwipeStyledButton}>
                        <SwipeStyledButton
                            animationStart="left"
                            animationMaterialColor="#00B3A1"
                            buttonMaterialColor="#FFC101"
                            onClick={handleSelectPunkt}
                        >
                            Обрати
                        </SwipeStyledButton>
                    </div>}
                </div>}
                {(open === punkt.id || coordinates.checkNearest[punkt.id] || selectedPunkt || punkt?.id === coordinates.selectPunkt?.id)
                    ? <img src={locationActive} alt="locationActive" className={cl.markerImage}
                           onClick={(e) => handleClick(e)}/>
                    : <img src={location} alt="location" className={cl.markerImage} onClick={(e) => handleClick(e)}/>
                }
            </div>
        </OverlayView>)
    }

    useEffect(() => {
        if (coordinates.showAllPunkts && !changeZoom) {
            setZoom(6);
            setZomLat((6 / 12)+ isDesktopVersion)
        } else {
            setZoom(12);
            setZomLat('0.0' + `${12 / 12}`.replace('.', ''));
        }
    }, [coordinates.showAllPunkts, changeZoom]);

    return (
        <GoogleMap
            defaultZoom={coordinates.showAllPunkts && !changeZoom ? 6 : 12}
            key={'centeredMap'+coordinates.showAllPunkts+coordinates.lat+coordinates.lon+changeZoom}
            ref={zoomRef}
            defaultCenter={{lat: +coordinates.lat, lon: coordinates.lon}}
            options={{
                styles: [
                    {
                        elementType: "labels.icon",
                        stylers: [{visibility: "off"}],
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [{color: "#616161"}],
                    },
                    {
                        elementType: "labels.text.stroke",
                        stylers: [{color: "#f5f5f5"}],
                    },
                    {
                        featureType: "poi",
                        elementType: "geometry",
                        stylers: [{color: "#eeeeee"}],
                    },
                    {
                        featureType: "poi",
                        elementType: "labels.text.fill",
                        stylers: [{color: "#757575"}],
                    },
                    {
                        featureType: "poi.park",
                        elementType: "geometry",
                        stylers: [{color: "#B9E4E4"}],
                    },
                    {
                        featureType: "landscape.natural",
                        elementType: "geometry",
                        stylers: [{color: "#e5e4e5"}],
                    },
                    {
                        featureType: "poi.park",
                        elementType: "labels.text.fill",
                        stylers: [{color: "#9e9e9e"}],
                    },
                    {
                        featureType: "road",
                        elementType: "geometry",
                        stylers: [{color: "#ffffff"}],
                    },
                    {
                        featureType: "road.arterial",
                        elementType: "labels.text.fill",
                        stylers: [{color: "#9e9e9e"}],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [{color: "#FFFFFF"}],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "labels.text.fill",
                        stylers: [{color: "#9e9e9e"}],
                    },
                    {
                        featureType: "road.local",
                        elementType: "labels.text.fill",
                        stylers: [{color: "#9e9e9e"}],
                    },
                    {
                        featureType: "transit.line",
                        elementType: "geometry",
                        stylers: [{color: "#ffffff"}],
                    },
                    {
                        featureType: "transit.station",
                        elementType: "geometry",
                        stylers: [{color: "#eeeeee"}],
                    },
                    {
                        featureType: "transit.station.rail",
                        elementType: "geometry",
                        stylers: [{color: "#ffffff"}],
                    },
                    {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [{color: "#c9c9c9"}],
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.fill",
                        stylers: [{color: "#9e9e9e"}],
                    },
                    {
                        featureType: "poi.attraction",
                        elementType: "geometry",
                        stylers: [{color: "#FEF6DD"}],
                    },
                    {
                        featureType: "poi.business",
                        elementType: "geometry",
                        stylers: [{color: "#FEF6DD"}],
                    },
                    {
                        featureType: "poi.government",
                        elementType: "geometry",
                        stylers: [{color: "#FEF6DD"}],
                    },
                    {
                        featureType: "poi.medical",
                        elementType: "geometry",
                        stylers: [{color: "#FEF6DD"}],
                    },
                    {
                        featureType: "poi.place_of_worship",
                        elementType: "geometry",
                        stylers: [{color: "#FEF6DD"}],
                    },
                    {
                        featureType: "poi.school",
                        elementType: "geometry",
                        stylers: [{color: "#FEF6DD"}],
                    },
                    {
                        featureType: "poi.sports_complex",
                        elementType: "geometry",
                        stylers: [{color: "#FEF6DD"}],
                    },
                ]
            }}
            onZoomChanged={() => {
                const currentZoom = zoomRef.current.getZoom()
                setZoom(currentZoom);
            }}
            // center={coordinates}
            center={!isEmptyObject(coordinates.checkNearest) && coordinates.checkNearest.active ?
                {
                    //  lat: +coordinates.checkNearest.lat + coordinates.checkNearest.lat / 100 * 0.063 * (zoom / 12) + 0.0100000 ,
                    // lat: +coordinates.checkNearest.lat + +zomLat  +   0.0200000,
                    lat: +coordinates.checkNearest.lat + +zomLat + 0.0200000,
                    lng: +coordinates.checkNearest.lng,
                }
                : {
                    lat: +(coordinates.checkNearest ? coordinates.checkNearest.lat : 0) + +zomLat + 0.0200000,
                    lng: +coordinates.lng
                }
            }
        >
            {/*<MarkerWithPopper punkt={coordinates.punkt}/>*/}
            {coordinates.markers.length > 0 && typeof coordinates.markers !== "string" && coordinates.markers.map(punkt => (
                <MarkerWithPopper coordinates={coordinates} punkt={punkt} key={punkt.id}/>
            ))}
        </GoogleMap>
    );
}

const MapWrapper = withScriptjs(withGoogleMap(Map));

export default CollectionPointsKids;
