import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import daVinci from '../../assets/shopHome/davinci.png';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
    },
    mainWrapper: {
        maxWidth: '1056px',
        width: '100%',
        height: '100%',
        margin: '0 auto',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr',
        gap: '8px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    daVinciImg: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundImage: `url(${daVinci})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        opacity: .3,
        zIndex: -100,
    },
    circularMenuWrapper: {
        width: '100%',
        height: '100%',
        position: 'relative',
        margin: '0 auto',
        overflow: 'visible',
    },
    itemFirstRow: {
        padding: '2px 10px',
        color: theme.palette.primary[50],
        transition: '.5s',
        cursor: 'pointer',
        textAlign: 'end',
        background: 'rgba(0,0,0,.05)',
        borderRadius: '20px',
        margin: '0 5px',
        '&:hover': {
            color: theme.palette.secondary.main,
            transition: '.5s',
        },
    },
    itemSecondRow: {
        padding: '2px 10px',
        color: theme.palette.primary[50],
        transition: '.5s',
        cursor: 'pointer',
        textAlign: 'start',
        background: 'rgba(0,0,0,.05)',
        borderRadius: '20px',
        margin: '0 5px',
        '&:hover': {
            color: theme.palette.secondary.main,
            transition: '.5s',
        },
    },
}));

const normIndexLocalStorageKey = 'normsPage-indexOfNorm';

const MenuMobile = () => {
    const classes = useStyles();
    const {catalog} = useSelector(state => state.assaysCatalog);
    const dispatch = useDispatch();
    const history = useHistory();
    const [filteredPanels, setFilteredPanels] = useState(null);

    const handleTransitionToCatalog = (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        localStorage.setItem(normIndexLocalStorageKey, id);
            history.push('/catalog');
    };

    useEffect(()=> {
        if(catalog && typeof catalog !== "string" && !filteredPanels) setFilteredPanels(catalog.filter(item => item.id !== 52 && item.idParent !== 51).map((assay, idx) => ({
                    ...assay,
                    normIndex: idx,
                })).filter(assay => +assay.idParent === 0 && +assay.id !== 14 && +assay.id !== 13 && +assay.id !== 52));
    },[catalog, dispatch, filteredPanels]);

    return (<div className={classes.root}>
        <div className={classes.daVinciImg}/>
        <div className={classes.mainWrapper}>
            {filteredPanels && filteredPanels.slice(0,12).map((assay, idx) => (
                    <div
                        className={idx%2 ? classes.itemFirstRow : classes.itemSecondRow}
                        key={assay.id}
                        onClick={(event) => handleTransitionToCatalog(event, assay.normIndex)}
                    >
                        {assay.superGroupName
                            ? assay.superGroupName.split('.')[1]
                                ? assay.superGroupName.split('.')[1]
                                : assay.superGroupName.split('.')[0]
                            : assay.name}
                    </div>))}
        </div>
    </div>);
}
export default MenuMobile;