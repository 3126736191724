import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {setListOfPatients, setNotAddedPatients} from "@cabinet-packages/redux/actions/actionsCreators";
import {Avatar} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {addToCabinet} from "@cabinet-packages/api/api";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        minHeight: 'max-content',
        padding: '0 8px 8px 8px',
    },
    listWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '50%',
        height: '100%',
        minWidth: '560px',
        minHeight: 'max-content',
        padding: '0 8px 8px 8px',

        '@media (max-width:900px)': {
            width: '100%',
            minWidth: '250px'
        },
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0 8px 8px 8px',
        alignItems: 'flex-start',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',

        '@media (max-width:500px)': {
            flexDirection: 'column',
        },
    },
    listItemDataWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    listItemAvatar:{
        margin: '4px 8px 0px 0px',
    },
    listItemFullname: {
        alignItems: 'flex-start',
    },
    listItemBirthday: {
        alignItems: 'flex-end',
        fontSize: '14px',
        color: 'grey'
    },
    listItemButton: {
        alignSelf: 'center',
        marginLeft: 'auto',

        '@media (max-width:500px)': {
            width: '100%',
            margin: '8px 0px 0px 0px'
        },
    },
}));

const AddUnregisterPatients = ({}) => {
    const { listOfPatients, activePatient, notAddedPatients } = useSelector(state => state.patients);
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const mainPatient = listOfPatients
        && typeof listOfPatients !== "string"
        && listOfPatients.length
        && listOfPatients.find(item => item.main);

    useEffect(() => {
        if (!notAddedPatients && listOfPatients && typeof listOfPatients !== "string" && listOfPatients.length) {
            dispatch(setNotAddedPatients());
        }
    }, [dispatch, listOfPatients, notAddedPatients]);

    return (<div className={classes.root}>
        <div className={classes.listWrapper}>
            {notAddedPatients && notAddedPatients.map(patient => {

                return (<Patinet
                    patient={patient}
                    mainId={mainPatient.id}
                    key={patient.id}
                />);
            })}
            {notAddedPatients && notAddedPatients.length === 0 && <div>Неактивних пацієнтів немає.</div>}
        </div>
    </div>)
};

const Patinet = ({patient, mainId}) => {
    const [isSend, setIsSend] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleClick = async (event, childId) => {
        event.preventDefault();
        event.stopPropagation();

        try {
            await addToCabinet(mainId, childId);
            setIsSend(true);
            dispatch(setListOfPatients(true));
            dispatch(setNotAddedPatients());
        } catch (e) {
            console.error(e);
        }

    };

    return (<div
        className={classes.listItem}
    >
        <div className={classes.listItemDataWrapper}>
            <Avatar
                className={classes.listItemAvatar}
            >
                {patient.lastname[0]+patient.firstname[0]}
            </Avatar>
            <div>
                <div className={classes.listItemFullname}>{`${patient.lastname} ${patient.firstname} ${patient.fathername}`}</div>
                <div className={classes.listItemBirthday}>{patient.birthday}</div>
            </div>
        </div>
        <Button
            className={classes.listItemButton}
            onClick={e => {
                handleClick(e, patient.id);
            }}
            color="primary"
            variant="outlined"
            disabled={isSend}
        >
            {isSend
                ? 'Пацієнта додано'
                : 'Додати до кабінету'
            }
        </Button>
    </div>);
};

export default AddUnregisterPatients;
