import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        margin: '0 auto',
        maxWidth: 500,
        padding: 8,
    },
    rootTitle: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: '1.25rem',
    },
}));

export default useStyles;
