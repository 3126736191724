import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 'calc(100vh - 96px)',
        display: 'flex',
        minHeight: 'max-content',
        flexDirection: 'column',
        padding: '0px 8px 8px 8px',
        paddingBottom: 0,
    },
    superGroupCardsWrapper: {
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '8px'
    },
    superGroupCards: {
        height: 'max-content',
        display: 'grid',
        grid: 'auto-flow / repeat(4, 158px)',
        rowGap: '4px',
        columnGap: '4px',
        padding: '12px 0px 0px 0px',
        margin: '0px 8px',

        '@media (max-width: 1500px)': {
            grid: 'auto-flow / repeat(3, 158px)',
        },
        '@media (max-width: 1180px)': {
            grid: 'auto-flow / repeat(2, 158px)',
            columnGap: '4px',
        },
        '@media (max-width: 900px)': {
            grid: 'auto-flow / repeat(3, 158px)',
            columnGap: '4px',
        },
        '@media (max-width: 500px)': {
            grid: 'auto-flow / repeat(2, 158px)',
            columnGap: '4px',
        },
        '@media (max-width: 350px)': {
            grid: 'auto-flow / repeat(1, 158px)',
        },
    },
    cardForSuperGroup: {
        width: '158px',
        height: '108px',
        boxShadow: '2px 0px 10px 0px #70707088',
        borderRadius: '10px',
        backgroundColor: 'white',
        transition: 'all 0.3s',
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.spacing(2),
        cursor: "pointer",

        '&:hover': {
            boxShadow: `2px 0px 15px 0px ${theme.palette.primary.main}`,
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            transition: 'all 0.3s',

            '& .cls-1': {
                fill: '#ffffff',
                transition: 'all 0.3s',
            },
            '& svg': {
                transform: 'scale(1.1)',
            },
        },
    },
    superGroupCardName: {
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'center',
    },
    superGroupNameAccent: {
        fontSize: '8px',
        fontWeight: 600,
        textAlign: 'center',
    },
    searchHeaderWrapper: {
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        '@media (max-height: 400px)': {
            background: 'white',
            borderRadius: '18px 0px 18px 18px'
        }
    },
    list: {
        scrollBehavior: 'smooth',
        outlineStyle: 'none'
    },
    listItemWrapper: {
        padding: '5px'
    },
    listItem: {
        background: theme.palette.grey.accordion,
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        height: '100%',
    },
    listItemInnerWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    listItemName: {
        width: 'calc(50% - 16px)',
        margin: '8px 0px 8px 16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '16px',
        fontWeight: 600,

        '@media (max-width: 1180px)': {
            fontSize: '12px',
        },
    },
    complexNameAccent: {
        color: theme.palette.primary.main
    },
    listItemNameImg: {
        cursor: 'pointer',
        '@media (max-width: 1180px)': {
            wordBreak: 'break-word',
        },
    },
    listItemImg: {
        width: '15px',
        height: '15px',
        cursor: 'pointer'
    },
    listItemActionsWrapper: {
        width: 'calc(50% - 16px)',
        margin: '7px 16px 6px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '16px',
        fontWeight: 600,

        '@media (max-width: 1180px)': {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    listItemActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
    },
    listItemActionsItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        width: '30%',

        '&:nth-of-type(1)': {
            display: 'none',
        },
        '&:nth-of-type(3)': {
            display: 'none',
        },
        '&:nth-of-type(4)': {
            width: '120px',
        },
    },
    listItemDiscountPrice: {
        color: theme.palette.green.dark,
        fontSize: '16px',
        textAlign: 'center'
    },
    listItemDiscountPriceDiscount: {
        color: theme.palette.grey.price,
        fontSize: '16px',
        textAlign: 'center',
        marginLeft: theme.spacing(1.5),

        '@media (max-width: 1180px)': {
            fontSize: '14px',
            marginLeft: theme.spacing(0.5),
        },
    },
    listItemPrice: {
        cursor: 'pointer',
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '14px',
        height: '30px',
        transition: 'background 0.3s, border 0.3s',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),

        '&:hover': {
            transition: 'background 0.3s, border 0.3s',
            background: theme.palette.primary.light,
            border: "2px solid transparent",
        },

        '@media (max-width: 1180px)': {
            paddingLeft: theme.spacing(0.75),
            justifyContent: 'center',
            paddingRight: theme.spacing(0.75),
        },
    },
    listItemPriceInBasket: {
        cursor: 'pointer',
        border: "2px solid transparent",
        borderRadius: '14px',
        height: '30px',
        transition: 'background 0.3s, border 0.3s',
        background: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    listItemNote: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: 'calc(50% - 16px)',
        color: "red",
        fontSize: '14px',
        marginLeft: theme.spacing(2),

        '@media (max-width: 1180px)': {
            fontSize: '14px',
            width: 'calc(100% - 16px)',
            paddingRight: '8px',
        },
    },
    listItemNoteImg: {
        width: '15px',
        height: '15px',
        color: 'red',
        marginRight: '4px'
    },
    assayTerms: {
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.green.dark,
        textAlign: 'center',
        width: '100%',
    },
    svgImg: {
        fill: theme.palette.primary.main,
        transition: 'all 0.3s',
        height: '65px',

        '@media (max-width: 1180px)': {
            height: '36px',
        }
    },
    searchHeader: {
        height: 'max-content',
        width: '100%',
        maxWidth: '1180px',
        padding: '0px',
        margin: '0px 8px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    rootAutocompleteBase: {
        width: '400px',
        borderRadius: '18px',

        '@media (max-width: 1180px)': {
            width: '100%',
            maxWidth: '216px'
        }
    },
    rootAutocomplete: {
        border: '2px solid #F0F0F0',
        borderRadius: '18px',
        height: '38px',
        transition: 'all .4s cubic-bezier(1,0,1,0)',
        alignItems: 'center',
        padding: '0px 39px 0px 9px !important',
        flexWrap: 'nowrap',

        '&&& $input': {
            transition: 'all .4s cubic-bezier(1,0,1,0)',
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: '61px',

            '@media (max-width: 1180px)': {
                paddingRight: '0px !important',
            }
        },
    },
    rootAutocompleteNotExpanded: {
        border: '2px solid #F0F0F0',
        borderRadius: '18px',
        height: '38px',
        transition: 'all .4s cubic-bezier(1,0,1,0)',
        padding: '0px 9px !important',
        alignItems: 'center',

        '&&& $input': {
            transition: 'all .4s cubic-bezier(1,0,1,0)',
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: '61px',
            height: 0,
            width: 0,
            display: 'none',

            '@media (max-width: 1180px)': {
                paddingRight: '0px !important',
            }
        },
    },
    notchedOutline: {
        border: "none",
    },
    inputRoot: {
        padding: 0
    },
    endAdornment: {
        display: 'none',
        // background: theme.palette.grey.accordion,
        // borderRadius: '0px 18px 18px 0px',
        // position: 'absolute',
        // right: 0,
        // top: 0,
        // zIndex: 1,
        // height: '100%',
        // cursor: 'pointer',
        // width: '100px',
        // display: "flex",
        // flexDirection: 'row',
        // justifyContent: 'space-between',
        // alignItems: "center",
        // transition: 'all .4s',
        //
        // '@media (max-width: 1180px)': {
        //     display: 'none',
        // }
    },
    endAdornmentOpen: {
        borderRadius: '0px 18px 18px 0px',
        cursor: 'pointer',
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: "center",
        transition: 'all .4s',

        '@media (max-width: 1180px)': {
            display: 'none',
        }
    },
    endAdornmentText: {
        fontSize: '14px',
        fontWeight: 700,
        paddingLeft: theme.spacing(2),
    },
    endAdornmentMenu: {
        marginTop: theme.spacing(1),
        width: '220px',
        borderRadius: theme.spacing(2)
    },
    endAdornmentMenuItem: {
        width: '220px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        fontSize: '14px',
        fontWeight: 700,
        color: theme.palette.blue.light,

        '&:hover': {
            color: 'white',
            background: theme.palette.primary.main
        },
    },
    endAdornmentMenuItemRegion: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        fontSize: '14px',
        fontWeight: 700,
        color: theme.palette.blue.light,

        '&:hover': {
            color: 'white',
            background: theme.palette.primary.main
        },
    },
    selectRoot: {
        paddingBottom: '8px',
        paddingTop: '8px',
    },
    autoSizerWrapper: {
        height: 'calc(100% - 104px)',
        borderRadius: '10px 10px 0px 0px',
        boxShadow: '0px -2px 10px 0px #363D4D88',
        backgroundColor: 'white',
        padding: '0 8px',
    },
    switchWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '88px',

        '@media (max-width: 1180px)': {
            marginLeft: theme.spacing(1)
        }
    },
    switchPaletteImg: {
        height: '36px',
        cursor: 'pointer',
        transition: 'all .5s',
    },
    switchPaletteImgSelected: {
        height: '36px',
        background: theme.palette.grey.border,
        borderRadius: '7px',
        transition: 'all .5s',
    },
    switchListImg: {
        height: '36px',
        cursor: 'pointer',
        transition: 'all .5s',
    },
    switchListImgSelected: {
        height: '36px',
        background: theme.palette.grey.border,
        borderRadius: '7px',
        transition: 'all .5s'
    },
    tabsCatalogWrapper: {

    },
    tabsLabelWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',

        '@media (max-width: 1180px)': {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            maxWidth: '74px'
        }
    },
    tabImage: {
        height: '24px',
        width: 'fit-content',
        fill: theme.palette.primary.main,
        marginRight: theme.spacing(1.5),
        transition: 'all 0.5s',

        '@media (max-width: 1180px)': {
            marginRight: 0,
        }
    },
    btnComplex: {
        height: '15px',
        width: '15px',
        fill: theme.palette.primary.main,
    },
    btnDelete: {
        height: '15px',
        width: '15px',
    },
    tabLabelNameSelected: {
        fontSize: '12px',
        fontWeight: 600,
        transition: 'all 0.5s',

        '@media (max-width: 1180px)': {
            fontSize: '10px',
            maxWidth: '58px'
        }
    },
    tabLabelName: {
        fontWeight: 600,
        transition: 'all 0.5s',
        fontSize: '12px',

        '@media (max-width: 1180px)': {
            fontSize: '10px',
        }
    },
    catalogHeaderWrapper: {
        display: 'grid',
        marginLeft: '5px',
        justifyContent: 'center',
        marginRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        gridTemplateColumns: '45% 1fr 1fr',
    },
    catalogHeaderText: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontWeight: 600,
        color: theme.palette.grey.label,
        height: '28px',
        fontSize: '8px',
        '&:nth-of-type(2n)': {
            display: 'none',
        },

        '&:first-child': {
            textAlign: 'left',
        },
    },
    catalogHeaderTextSmall: {
        fontSize: '9px',
        fontWeight: 600,
        color: theme.palette.primary.dark,
    },
    popoverButtons: {
        alignItems:'center',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 16
    },
    popoverNavigateButton:{
        margin:4
    },
    popoverContent: {
        maxWidth: '700px',
        borderRadius: '10px',
        paddingTop: theme.spacing(5.25),
        paddingBottom: theme.spacing(3.75),
        paddingRight: theme.spacing(7.5),
        paddingLeft: theme.spacing(7.5),

        '@media (max-width: 1180px)': {
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingTop: theme.spacing(2),
        }
    },
    error: {
        color: 'red'
    },
    heading: {
        background: theme.palette.primary.dark,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(7.5),
        paddingLeft: theme.spacing(7.5),
        borderRadius: '10px 10px 0px 0px',
        alignItems: 'center',
        position:"sticky",
        top: 0,

        '@media (max-width: 1180px)': {
            padding: theme.spacing(2),
            justifyContent: 'flex-start',
        }
    },
    headingText: {
        fontSize: '16px',
        fontWeight: 600,
        textTransform: 'uppercase',
        lineHeight: '21px',
        color: 'white',
        textAlign: 'center',

        '@media (max-width: 1180px)': {
            fontSize: '12px',
        }
    },
    iconButton: {
        position: "absolute",
        top: 10,
        right: 10,
        color: 'white'
    },
    noScroll: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.1)',
        position: "relative",

        '&& div': {
            scrollbarWidth: 'none',

            '-ms-overflow-style': 'none',

            '&::-webkit-scrollbar': {
                display: 'none',
            },
        }
    },
    complexFillingsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: 'calc(50% - 16px)',
        color: "red",
        fontSize: '14px',
        marginLeft: theme.spacing(2),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),

        '@media (max-width: 1180px)': {
            fontSize: '12px',
            width: 'calc(100% - 16px)',
            paddingRight: '8px',
        },
    },
    warningExpandedWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    warningExpandedIcon: {
        height: '15px',
        marginRight: theme.spacing(1),

        '@media (max-width: 1180px)': {
            height: '12px',
        },
    },
    warningExpandedText: {
        color: theme.palette.error.light,
        fontSize: '15px',

        '@media (max-width: 1180px)': {
            fontSize: '12px',
        },
    },
    warningExpandedTextGreen: {
        color: theme.palette.green.dark,
        fontSize: '15px',

        '@media (max-width: 1180px)': {
            fontSize: '12px',
        },
    },
    input: {

    },
}));

export default useStyles;
