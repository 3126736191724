import {SET_SCHEDULE} from '../actions/actionsTypes';

export const scheduleReducerInitialState = {
    schedule: null,
};

export default function scheduleReducer(state = scheduleReducerInitialState, {type, payload}) {
    switch (type) {
        case SET_SCHEDULE:
            return {
                ...state,
                schedule: payload.schedule,
            };
        default:
            return state;
    }
};
