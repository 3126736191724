import React, {useEffect, useState} from "react";
import useStyles from "../styles";
import {Checkbox, FormControlLabel, FormGroup, Input, withStyles} from "@material-ui/core";
import CheckboxLabel from "./CheckboxLabel";
import {useSelector} from "react-redux";
import {convertPrice} from "../helpers/convertPrice";

const StyledTextField = withStyles((theme) => ({
    root: {
        height: '48px',
    },
    input: {
        border: `2px solid ${theme.palette.grey.border}`,
        borderRadius: '5px',
        paddingLeft: theme.spacing(1),
        fontSize: '16px',
        minWidth: '240px',
        height: '31px',
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    }
}))(Input);

const StyledFormControlLabel = withStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(1),
        alignItems: 'flex-start'
    },
    label: {
        marginTop: theme.spacing(1.5)
    },
}))(FormControlLabel);

const OrderTerms = ({checkboxes, promo}) => {
    const cl = useStyles();
    const [state, setState] = checkboxes;
    const [promoCode, setPromoCode] = promo;
    const {catalog, complexResearch, actions, takeId}  = useSelector(state => state.assaysCatalog);
    const {orderingList, totalPrice} = useSelector(state => state.calculator);
    const [completeOrderingListPrice, setCompleteOrderingListPrice] = useState(0);
    const [sum, setSum] = useState(convertPrice(0,0,cl.onlinePriceOverview));
    const [discount, setDiscount] = useState(convertPrice(0,0,cl.onlinePriceOverview));
    const { activePatient } = useSelector(state => state.patients);

    const handleChangePromoCode = (event) => {
        event.persist();

        setPromoCode(event.target.value);
    };

    const handleChange = (event) => {
        event.persist();

        setState({ ...state, [event.target.name]: event.target.checked });
    };

    useEffect(() => {
        if (catalog && complexResearch && actions && orderingList && activePatient) {
            const newCompleteOrderingListPrice = orderingList
                .reduce((acu, cur) => {
                    if (cur.notSuitable) {
                        return acu
                    } else {
                        return acu + cur.price;
                    }
                },0);

            setCompleteOrderingListPrice(newCompleteOrderingListPrice);

            setSum(convertPrice(+newCompleteOrderingListPrice,0,cl.onlinePriceOverview));
            setDiscount(convertPrice((+newCompleteOrderingListPrice)-(+totalPrice),0,cl.onlinePriceOverview));
        }
    }, [catalog, complexResearch, actions, takeId, completeOrderingListPrice, orderingList, activePatient]);

    return(<div className={cl.orderTermsWrapper}>
        <div className={cl.orderTerms}>
            <div className={cl.orderTermsSmallBox}>
                <FormGroup>
                    <StyledFormControlLabel control={<Checkbox checked={state.checkedA} onChange={handleChange}
                                                         name="checkedA" color="primary"/>}
                                      label={<CheckboxLabel text={'Я ознайомлений(на) з'}
                                                            redText={'правилами підготовки до аналізів'}
                                                            link={'/shop-how-to-prepare'}/>}

                    />
                    <StyledFormControlLabel control={<Checkbox checked={state.checkedB} onChange={handleChange}
                                                         name="checkedB" color="primary"/>}
                                      label={<CheckboxLabel text={'Я погоджуюсь на використання'}
                                                            redText={'персональних даних'}
                                                            link={'#'}/>}
                    />
                </FormGroup>
            </div>
            <div className={cl.orderTermsSmallBox}>
                {/*<FormGroup>*/}
                {/*    <StyledFormControlLabel control={<Checkbox checked={state.checkedC} onChange={handleChange}*/}
                {/*                                         name="checkedC" color="primary"/>}*/}
                {/*                      label={<CheckboxLabel text={'Забір біоматеріалу не потрібен'}/>}*/}
                {/*    />*/}

                {/*</FormGroup>*/}
                {/*<p className={cl.orderTermsTextFieldLabel}>Я маю промокод</p>*/}
                {/*<StyledTextField*/}
                {/*    placeholder="Введіть промокод"*/}
                {/*    value={promoCode}*/}
                {/*    onChange={handleChangePromoCode}*/}
                {/*/>*/}
            </div>
            <div className={cl.orderTermsSmallBox}>
                <div className={cl.orderTermsOverview}>
                    <p>Сума:</p>
                    {sum}
                </div>
                <div className={cl.orderTermsOverview}>
                    {/*<p>Знижка при сплаті онлайн:</p>*/}
                    {/*{discount}*/}
                </div>
                <div className={cl.orderTermsOverview}>
                    {/*<p>Знижка за промокодом:</p>*/}
                    {/*<p className={cl.onlinePriceOverview}> 0<sup>00</sup>&#8372;</p>*/}
                </div>
            </div>
            </div>
    </div>);
};

export default OrderTerms;
