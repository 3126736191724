import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 'calc(100vh - 96px)',
        position: 'relative',
        overflow: 'auto',

        '@media (max-width: 1180px)': {
            height: 'max-content',
        },
    },
    mainWrapper: {
        width: '100%',
        height: '100%',
        minHeight: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        '@media (max-width: 1180px)': {
            height: 'max-content',
        },
    },
    headerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        '@media (max-width: 1180px)': {
            flexDirection: 'row',
        },
    },
    catalog: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',

        '@media (max-width: 1180px)': {
            height: 'max-content',
            minHeight: '200vh',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
    },
    catalogWrapper: {
        height: '100%',
        width: '50%',
        transition: 'width 0.3s',

        '@media (max-width: 1180px)': {
            height: 'max-content',
            minHeight: '100vh',
            width: '100%',
        },
    },
    catalogWrapperToggle: {
        height: '100%',
        width: '65%',
        transition: 'width 0.3s',

        '@media (max-width: 1180px)': {
            height: 'max-content',
            minHeight: '100vh',
        },
    },
    rootFullOrderView: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'max-content',
        maxWidth: "600px",
        textAlign: 'left',
        marginTop: '8px',
        border: `1px solid #31ff39`,
        borderRadius: '8px',
        padding: '8px',
        backgroundColor: 'rgba(49,255,57,0.1)'
    },
    orderDataText: {
        textAlign: 'left',
        width: '100%'
    },
}));

export default useStyles;