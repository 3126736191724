import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputBase,
    InputLabel, Link,
    makeStyles,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import {SwipeStyledButton} from "../index";
import {sendRefund} from "@cabinet-packages/api/api";
import {setRegions} from "@cabinet-packages/redux/actions/actionsCreators";
import {useDispatch, useSelector} from "react-redux";
import MuiPhoneNumber from "material-ui-phone-number";
import AdmitIcon from "@material-ui/icons/CheckCircle";
import {getDateLabelPattern} from "medis-cabinet/src/utils/InputValuePatterns";
import {getPriceContainer} from "medis-cabinet/src/utils/PricePattern";
import moment from "moment";
import {SwipeButton} from "medis_v2.0/src/components/Cabinet/SwipeStyledButton";
import {Facebook, Instagram, Phone, PhoneInTalk, Telegram} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    inputRoot: {
        fontWeight: 600,
        fontSize: 14,
        padding: '10px',
        borderBottom: 'none',
        backgroundColor: '#f6f5f5',
    },
    describeInput: {
        padding: '10px',
        border: `1px solid #e8e8e8`,
        backgroundColor: "white",
    },
    inputLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '12px',
        fontWeight: 500,
        margin: '10px'
    },
    itemContainer: {
        backgroundColor: '#f6f5f5',
        margin: theme.spacing(1)
    },
    orderContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 4fr 4fr',
        height: '40px',
    },
    serviceContainer: {
        display: 'grid',
        gridTemplateColumns: '6fr 1.5fr',
        margin: '10px 0px',
        position: 'relative'
    },
    columnLabelsContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 4fr 4fr',
        height: '15px',
    },
    serviceLabelsContainer: {
        display: 'grid',
        gridTemplateColumns: '6fr 1.5fr',
        height: '15px',
    },
    columnLabel: {
        fontSize: '12px',
        fontWeight: 500,
        color: theme.palette.grey.text
    },
    orderDetailLabel: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 14
    },
    dialogContainer: {
        '& .MuiPaper-rounded': {
            borderRadius: '20px'
        },
    },
    dialogContent: {
        margin: '30px',
        '@media(max-width: 900px)': {
            margin: '10px'
        }
    },
    codeBlock: {
        padding: '30px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        borderRadius: '20px',
        width: 'min-content',
        margin: 'auto'
    },
    dialogTitle: {
        backgroundColor: theme.palette.primary.dark,
        textAlign: 'center',
        color: 'white',
        textTransform: 'uppercase'
    },
    dialogText: {
        fontWeight: '500',
        fontSize: '15px',
        //width: '40%',
        '@media(max-width: 600px': {
            width: '80%'
        },
        textAlign: 'center',
        marginTop: '30px'
    }
}));

const RefundDialog = ({ handleClose, order, service, punkt, headerText}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {regions} = useSelector(store => store.collectionPoints);
    const {activePatient} = useSelector(state => state.patients);
    const mdUp = useMediaQuery(theme => theme.breakpoints.up('md'))
    const [reason, setReason] = useState(null)
    const [error, setError] = useState(false)

    const handleSendRefund = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const { lastname, firstname, fathername, phone } = activePatient;

        if(!reason || reason.trim() === "") {
            setError(true)

            return
        }

        sendRefund(lastname, firstname, fathername, order.id, phone, punkt.address.split(',')[0], moment(order.dt).format('YYYY-MM-DD'), reason).then(() => {
            handleClose()
        })
    }

    useEffect(() => {
        if (!regions) dispatch(setRegions());
    }, [regions, dispatch]);

    return (
        <Dialog
            open={true}
            onClose={() => handleClose()}
            PaperProps={{
                style: { borderRadius: 10, maxWidth: '700px'}
            }}
        >
            <DialogTitle className={classes.dialogTitle}>
                {headerText}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography className={classes.extraLabel}>
                    Якщо Ви хочете повернути кошти, зателефонуйте на гарячу лінію
                </Typography>
                <br/>
                <Link href="tel:0800501750"><Phone size="small"/> 0 800 50 17 50</Link>
                <br/>
                <br/>
                <br/>
                <Link href="https://t.me/mediscallcenter"><Telegram size="small"/> Telegram</Link>
                <br/>
                <Link href="viber://add?number=380675719793"><PhoneInTalk size="small"/> Viber</Link>
                <br/>
                <Link href="https://www.facebook.com/medis.ua"><Facebook size="small"/> Facebook</Link>
                <br/>
                <Link href="https://www.instagram.com/medis_lab/"><Instagram size="small"/> Instagram</Link>
                <br/>
                {/*<Grid container direction={'row'} spacing={mdUp ? 2 : 1}>*/}
                {/*    <Grid item xs={mdUp ? 6 : 12}>*/}
                {/*        <InputLabel className={classes.inputLabel}>Ім'я</InputLabel>*/}
                {/*        <InputBase*/}
                {/*            variant="standard"*/}
                {/*            value={activePatient.firstname}*/}
                {/*            fullWidth*/}
                {/*            inputProps={{*/}
                {/*                readOnly: true,*/}
                {/*                className: classes.inputRoot*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={mdUp ? 6 : 12}>*/}
                {/*        <InputLabel className={classes.inputLabel}>Прізвище</InputLabel>*/}
                {/*        <InputBase*/}
                {/*            variant="standard"*/}
                {/*            value={activePatient.lastname}*/}
                {/*            fullWidth*/}
                {/*            inputProps={{*/}
                {/*                readOnly: true,*/}
                {/*                className: classes.inputRoot*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={mdUp ? 6 : 12}>*/}
                {/*        <InputLabel className={classes.inputLabel}>Електронна пошта</InputLabel>*/}
                {/*        <InputBase*/}
                {/*            variant="standard"*/}
                {/*            value={activePatient.email}*/}
                {/*            fullWidth*/}
                {/*            inputProps={{*/}
                {/*                readOnly: true,*/}
                {/*                className: classes.inputRoot*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={mdUp ? 6 : 12}>*/}
                {/*        <InputLabel className={classes.inputLabel}>Контактний телефон</InputLabel>*/}
                {/*        <MuiPhoneNumber*/}
                {/*            defaultCountry='ua'*/}
                {/*            countryCodeEditable={false}*/}
                {/*            fullWidth={true}*/}
                {/*            value={activePatient.phone.includes("380") ? activePatient.phone : "+380" + activePatient.phone}*/}
                {/*            disableDropdown={true}*/}
                {/*            InputProps={{*/}
                {/*                disableUnderline: true,*/}
                {/*                readOnly: true,*/}
                {/*                className: classes.inputRoot,*/}
                {/*                style: {height: '40px'},*/}
                {/*                endAdornment: <AdmitIcon style={{fontSize: '20px', margin: '5px'}} color={"primary"}/>*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={12} className={classes.itemContainer}>*/}
                {/*        {service && (*/}
                {/*            <>*/}
                {/*                <Grid container spacing={1}>*/}
                {/*                    <Grid item xs={mdUp ? 8 : 12}>*/}
                {/*                        <Typography className={classes.columnLabel}>*/}
                {/*                            Замовлені дослідження*/}
                {/*                        </Typography>*/}
                {/*                    </Grid>*/}
                {/*                    <Grid item xs={mdUp ? 4 : 12} style={{order: mdUp ? 0 : 2}}>*/}
                {/*                        <Typography className={classes.columnLabel}>*/}
                {/*                            Ціна*/}
                {/*                        </Typography>*/}
                {/*                    </Grid>*/}
                {/*                    <Grid item xs={mdUp ? 8 : 12} style={{order: mdUp ? 0 : 1}}>*/}
                {/*                        <Typography style={{fontWeight: 600, fontSize: 14}}>*/}
                {/*                            {service.name}*/}
                {/*                        </Typography>*/}
                {/*                    </Grid>*/}
                {/*                    <Grid item xs={mdUp ? 4 : 12} style={{order: mdUp ? 0 : 3}}>*/}
                {/*                        {getPriceContainer(service.price)}*/}
                {/*                    </Grid>*/}
                {/*                </Grid>*/}
                {/*            </>*/}
                {/*        )}*/}
                {/*        {order && (*/}
                {/*            <>*/}
                {/*                <Grid container spacing={1}>*/}
                {/*                    <Grid item xs={mdUp ? 2 : 12}>*/}
                {/*                        <Typography className={classes.columnLabel}>*/}
                {/*                            ID*/}
                {/*                        </Typography>*/}
                {/*                    </Grid>*/}
                {/*                    <Grid item xs={mdUp ? 5 : 12} style={{order: mdUp ? 0 : 2}}>*/}
                {/*                        <Typography className={classes.columnLabel}>*/}
                {/*                            Час здачі*/}
                {/*                        </Typography>*/}
                {/*                    </Grid>*/}
                {/*                    <Grid item xs={mdUp ? 5 : 12} style={{order: mdUp ? 0 : 4}}>*/}
                {/*                        <Typography className={classes.columnLabel}>*/}
                {/*                            Адреса*/}
                {/*                        </Typography>*/}
                {/*                    </Grid>*/}
                {/*                    <Grid item xs={mdUp ? 2 : 12} style={{order: mdUp ? 0 : 1}}>*/}
                {/*                        <Typography className={classes.orderDetailLabel} color={'textPrimary'} component={"div"}>*/}
                {/*                            {order.id}*/}
                {/*                        </Typography>*/}
                {/*                    </Grid>*/}
                {/*                    <Grid item xs={mdUp ? 5 : 12} style={{order: mdUp ? 0 : 3}}>*/}
                {/*                        <Typography className={classes.orderDetailLabel} color={'textPrimary'} component={"div"}>*/}
                {/*                            {getDateLabelPattern(order.dt) + " " + order.dt.slice(10, order.dt.length)}*/}
                {/*                        </Typography>*/}
                {/*                    </Grid>*/}
                {/*                    <Grid item xs={mdUp ? 5 : 12} style={{order: mdUp ? 0 : 5}}>*/}
                {/*                        <Typography className={classes.orderDetailLabel} color={'textPrimary'} component={"div"}>*/}
                {/*                            {punkt.address}*/}
                {/*                        </Typography>*/}
                {/*                    </Grid>*/}
                {/*                </Grid>*/}
                {/*            </>*/}
                {/*        )}*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={12}>*/}
                {/*        <InputLabel className={classes.inputLabel}>Вкажіть причину повернення коштів</InputLabel>*/}
                {/*        <InputBase*/}
                {/*            variant="standard"*/}
                {/*            className={classes.describeInput}*/}
                {/*            inputProps={{*/}
                {/*                placeholder: "Коротко опишіть причину повернення коштів"*/}
                {/*            }}*/}
                {/*            style={{height: '100px'}}*/}
                {/*            fullWidth*/}
                {/*            multiline*/}
                {/*            rows={4}*/}
                {/*            onChange={(event) => {*/}
                {/*                if(error) {*/}
                {/*                    setError(false)*/}
                {/*                }*/}
                {/*                setReason(event.target.value)*/}
                {/*            }}*/}
                {/*        />*/}
                {/*        {error && <InputLabel className={classes.inputLabel} style={{color: 'red'}}>Введіть причину повернення коштів.</InputLabel>}*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </DialogContent>
            <DialogActions style={{display: 'flex', justifyContent: 'center', margin: '20px 0'}}>
                <SwipeStyledButton
                    animationStart={'right'}
                    animationMaterialColor={'#ff2121'}
                    buttonMaterialColor={'#ffffff'}
                    hoverTextColor={'#ffffff'}
                    onClick={handleClose}
                >
                    {/*СКАСУВАТИ*/}
                    ЗАКРИТИ
                </SwipeStyledButton>
                {/*<SwipeButton onClick={(e) => handleSendRefund(e)} text={"ПІДТВЕРДИТИ"}/>*/}
            </DialogActions>
        </Dialog>
    );
}

export default RefundDialog;