import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: 1175,
            padding: '25px 81px 30px 103px',
            position: 'relative',
            overflowX: 'hidden',
            '@media (min-width: 900px)': {
                maxHeight: 'calc(100% - 20px)',
            },
            '@media (min-width: 400px) and (max-width: 900px)': {
                padding: '20px 26px 30px 17px',
                margin: '50px 32px 50px 40px',
            },
            '@media (max-width: 400px)': {
                padding: '20px 26px 30px 17px',
                margin: '50px 12px 50px 20px',
            },

        },
    },
    closeIcon: {
        cursor: 'pointer',
        '@media (max-width: 900px)': {
            width: 14,
        },
    },
    wrapperTitle: {
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '1.9rem',
    },
    wrapperCloseImg: {
        textAlign: 'end',
        '& img': {
            width: 30,
            cursor: 'pointer',
            '@media (max-width: 900px)': {
                width: 17,
            }
        },
    },
    warningTitleMain: {
        color: '#FF0000'
    },
    warningTitle: {
        color: '#0077C8',
        fontSize: 12,
        fontFamily: "'Museo Sans Cyrl 500'",
        position: 'relative',
        marginBottom: 0,
        bottom: 28,
        '@media (min-width: 900px)': {
            fontSize: '1.125rem',
        }
    },
    titleMain: {
        fontFamily: "'Museo Sans Cyrl 500'",
        color: theme.palette.primary.mainTitle,
        fontSize: '1.875rem',
        '@media (max-width: 900px)': {
            fontSize: '1.25rem',
        }
    },
    textUnderTitle: {
        color: theme.palette.primary.newMain,
        fontFamily: "'Museo Sans Cyrl 500'",
        marginTop: 10,
        marginBottom: 40,
        '@media (max-width: 900px)': {
            marginTop: 15,
            marginBottom: 20,
        },
    },
    wrapperNameEngTitle: {
        marginTop: 30,
        color: theme.palette.primary.newMain,
        fontSize: '1.25rem',
        fontFamily: "'Museo Sans Cyrl 700'",
        marginBottom: 15,
        '@media (max-width: 900px)': {
            fontSize: '0.938rem',
        },
    },
    wrapperEngName: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        '& p': {
            marginBottom: 14,
            '@media (min-width: 900px) and (max-width: 1100px)': {
                fontSize: 14,
            },
        },
        '& div': {
            marginBottom: 0,
        },
        '@media (max-width: 900px)': {
            gridTemplateColumns: '1fr',
        },
    },
    wrapperInput: {
        marginBottom: '29px !important',
    },
    notes: {
        width: '100%',
        '& .MuiInputBase-root': {
            color: theme.palette.primary.mainTitle,
            'font-family': "'Museo Sans Cyrl 500'",
            backgroundColor: '#ffffff',
            borderRadius: '12px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#C9E9FF',
        },
    },
    notesTitle: {
        fontSize: '0.938rem',
        color: theme.palette.primary.newMain,
        fontFamily: "'Museo Sans Cyrl 700'",
        marginBottom: 12,
    },
    wrapperCheckbox: {
        '& .MuiIconButton-colorPrimary': {
            position: 'relative',
            top: 7,
            marginRight: 5,
            color: '#0077C8',
        },
        marginTop: 20,
        marginBottom: 40,
        '@media (max-width: 900px)': {
            marginTop: 15,
            marginBottom: 26,
        },
        '& .MuiFormControlLabel-root': {
            alignItems: 'baseline',
        },

        '& .MuiIconButton-root': {
            padding: 0,
        },
        '& .MuiSvgIcon-root': {
            color: '#0077C8',
        },
    },
    checkedDisableText: {
        color: '#214572',
        '& a': {
            color: '#0077C8',
        },
        fontSize: '0.938rem',
        '@media (max-width: 900px)': {
            margin: 0,
        },
    },
    mainButton: {
        width: 190,
        fontSize: 13,
        display: 'block',
        borderRadius: 30,
        'font-family': "'Museo Sans Cyrl 700'",
        paddingTop: 9.7,
        paddingBottom: 9.6,
        background: '#0077C8',
        color: '#ffffff',
        marginTop: 50,
        '&:hover': {
            background: '#ffffff',
            color: '#0077C8',
        },
        '@media (max-width: 900px)': {
            margin: '0 auto',
            marginTop: 26,
        },
    },
    regionWrapper: {}
}));

export default useStyles;
