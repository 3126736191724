import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, withStyles,} from '@material-ui/core';
import {Close} from "@material-ui/icons";
import useStyles from "./styles";
import DialogForm from "./components/DialogForm";

const StyledDialog = withStyles(theme => ({
    paper: {
        borderRadius: '10px',
        width: '700px',

        '@media (max-width: 1180px)': {
            marginLeft: 0,
            marginRight: 0
        },
    },
}))(Dialog);

const StyledDialogTitle = withStyles(theme => ({
    root: {
        background: theme.palette.primary.dark,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        padding: 0,
        position: "relative",
    },
}))(DialogTitle);

const StyledDialogContent = withStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',

        '@media (max-width: 1180px)': {
            padding: 0
        },
    },
}))(DialogContent);

const NewPatientDialog = ({ handleClose}) => {
    const cl = useStyles();

    const [patientData, setPatientData] = useState({sex: 'F', birthday: '2000-12-12'});

    return (<StyledDialog open={true}>
        <StyledDialogTitle disableTypography>
            <span className={cl.headingText}>ДОДАТИ ПАЦІЄНТА</span>
            <IconButton onClick={handleClose} className={cl.closeButton}>
                <Close htmlColor="#FFFFFF80"/>
            </IconButton>
        </StyledDialogTitle>
        <StyledDialogContent>
            <DialogForm patientDataState={[patientData, setPatientData]} handleClose={handleClose}/>
        </StyledDialogContent>
        <DialogActions>
            <div/>
        </DialogActions>
    </StyledDialog>);
};

export default NewPatientDialog;
