import {SET_ERROR, SET_LOADING, UNSET_ERROR, UNSET_LOADING} from '../actions/actionsTypes';

export const indicatorsReducerInitialState = {
    error: false,
    errorText: null,
    loading: false,
    loadingText: null
};

export default function indicatorsReducer(state = indicatorsReducerInitialState, {type, payload}) {
    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: true,
                loadingText: payload
            };
        case UNSET_LOADING:
            return {
                ...state,
                loading: false,
                loadingText: null
            };
        case SET_ERROR:
            return {
                ...state,
                error: true,
                errorText: payload
            };
        case UNSET_ERROR:
            return {
                ...state,
                error: false,
                errorText: null
            };
        default:
            return state;
    }
};