import {makeStyles} from "@material-ui/core";
import radioHoverImg from "medis_v2.0/src/pages/shop/CollectionPoints/img/bttn_radio_hover.png";
import radioDefImg from "medis_v2.0/src/pages/shop/CollectionPoints/img/bttn_radio_def.png";

const useStyles = makeStyles((theme) => ({
    rootOrderPunkts: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100vw',
        height: 'max-content',
        minHeight: '726px'
    },
    innerWrapperOrderPunkts: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        maxWidth: '1180px',
        padding: '8px'
    },
    heading: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '1180px',
    },
    inputWrapperRegionRoot: {
        minWidth: '280px'
    },
    autoCompleteRegionOpthion: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '&:hover': {
            '& $autoCompleteRegionImgSelected': {
                background: `url(${radioHoverImg})`,
            },
        }
    },
    endAdornmentInput: {
        height: 33,
        display: 'flex',
        alignItems: 'center',
        zIndex: '0'
    },
    imgSelectPoint: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 25,
    },
    autoCompleteRegionImgSelected: {
        background: `url(${radioDefImg})`,
        height: 15,
        width: 15,
        display: 'block',
    },
    regionImgSelected: {
        background: `url(${radioHoverImg})`,
        height: 15,
        width: 15,
        display: 'block',
    },
    autoCompleteRegion: {
        boxSizing: 'content-box',
        width: '100%',
        padding: '0px !important',
        backgroundColor: 'white',
        borderRadius: 18,
        color: theme.palette.primary.main,

        '& div': {
            borderRadius: 18
        },
    },
    autoCompleteFloatLabel: {
        color: theme.palette.primary.main
    },
    autoCompleteInput: {
        paddingTop: '8.5px !important',
        paddingBottom: '8.5px !important',
        paddingLeft: '25px !important',
        color: theme.palette.colors.text,
        fontSize: 14,
    },
}));

export default useStyles;
