export const tabsStyles = ({palette, spacing, breakpoints,}) =>({
    root: {
        boxShadow: 'inset 0px -8px 10px -10px #363D4D88',
        width: '100%',
        maxWidth: '1180px',
    },
    indicator: {
        backgroundColor: 'transparent',
    },
    scrollButtons: {
        backgroundColor: 'transparent',

        '@media (max-width: 1180px)': {
            width: '20px',
        }
    },
});

export const tabItemStyles = ({palette, spacing, breakpoints,}) => {
    const defaultBgColor = '#F0F0F0';
    const defaultSelectedBgColor = '#ffffff';
    const defaultMinWidth = {
        md: 160,
    };

    return {
        root: ({bgColor = defaultBgColor, minWidth = defaultMinWidth,}) => ({
            opacity: 1,
            overflow: 'initial',
            paddingLeft: spacing(2),
            paddingRight: spacing(3),
            borderTopLeftRadius: spacing(1),
            borderTopRightRadius: spacing(1),
            backgroundColor: bgColor,
            transition: 'all 0.5s',
            minWidth: minWidth.md,

            // [breakpoints.up('md')]: {
            //     minWidth: minWidth.md,
            // },
            '& + $selected:before': {
                opacity: 0,
            },
            '&:first-of-type': {
                marginLeft: spacing(2.5),
                '@media (max-width: 1180px)': {
                    marginLeft: spacing(2.5),
                }
            },
            '&:last-of-type': {
                marginRight: spacing(2.5),
            },
            '&:hover': {
                '&:not($selected)': {
                    backgroundColor: palette.primary.main,
                },
                '&::before': {
                    opacity: 0,
                },
                '& + $root:before': {
                    opacity: 0,
                },
            },
        }),
        wrapped: {
            marginRight: spacing(1.25),
            marginLeft: spacing(1.25),
            marginTop: spacing(2),
            backgroundColor: 'black',

        },
        selected: ({selectedBgColor = defaultSelectedBgColor}) => ({
            boxShadow: '0px 0px 10px 0px #363D4D88',
            transition: 'all 0.5s',
            backgroundColor: selectedBgColor,
            '& + $root': {
                zIndex: 1,
            },
            '& + $root:before': {
                opacity: 0,
            },
        }),
        wrapper: {
            zIndex: 2,
            marginTop: spacing(0.5),
            textTransform: 'initial',
        },
    };
};
