import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {

    },
    appBar: {
        height: theme.spacing(8),
        backgroundColor: theme.palette.primary.dark,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    headingText: {
        fontSize: '32px',
        color: theme.palette.getContrastText(theme.palette.primary.dark),

        '@media (max-width: 1180px)': {
            fontSize: '16px',
        },
    },
}));

export default useStyles;
