import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {getCatalog, getPunkts, setSelectedAction} from "@cabinet-packages/redux";
import {useHistory, useLocation} from "react-router";
import {Button} from "@material-ui/core";
import {AddRemoveIconButtons, ExpansionPanel, Loading,} from "@cabinet-packages/components";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme)=> ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    rootWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    orderButtonContainer: {
        padding: '5px 0',
        '@media (max-width: 1040px)': {
            padding: '5px',
        },
    },
    orderButton: {
        width: '100%',
    },
    expansionPanel: {
        padding: '8px',
    },
    details: {
        padding: '8px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '@media (max-width: 1040px)': {
            flexDirection: 'column',
        },
    },
    detailsImgWrapper: {
        padding: '8px',
    },
    detailsImg: {
        border: '1px solid rgba(0,0,0,0.1)',
        maxWidth: '410px',
    },
    detailsText: {
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'right',
    },
    detailsTextPriceContainer: {
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingTop: '8px',
    },
    detailsTextPrice: {
        alignSelf: 'center',
    },
    expansionPanelInnerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
}))

const ComplexDetails = ({isComponentInShop}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { selectedActionForView, catalog, actions, complexResearch, takeId } = useSelector(state => state.assaysCatalog);
    const { orderingList, amountOfItems } = useSelector(state => state.orderAssays);
    const { punkts } = useSelector(store => store.collectionPoints);
    const location = useLocation();
    const history = useHistory();
    const locationActionID = [...location.pathname.split('/')].pop();
    const {imgUrl} = useSelector(store => store.theme);
    const hrefLocation = window.location.href;
    const insurance = process.env.REACT_APP_INSURANCE === 'true'
    const isIncludedInOrderingList = selectedActionForView && orderingList?.find(assay => assay.id === selectedActionForView.id && assay.type === selectedActionForView.type);
    const actionInOrderList = orderingList?.filter(actionID => (typeof actions === "string" ? [] : actions)?.find(item => item.id === actionID.id && actionID.type === item.type));

    const handleTransitionToOrderManager = (event) => {
        event.stopPropagation();
        event.preventDefault();
        isComponentInShop ? history.push('/shop-order-manager') : history.push('/order-manager');
    };

    useEffect(() => {
        if (!selectedActionForView && locationActionID && complexResearch) dispatch(setSelectedAction(complexResearch.find(action => +action.id === +locationActionID)))
    }, [dispatch, catalog, punkts, locationActionID, selectedActionForView, complexResearch]);

    useEffect(() => {
        if (!punkts) dispatch(getPunkts());
    }, [dispatch, takeId, punkts]);

    useEffect(() => {
        if (!catalog && takeId) dispatch(getCatalog());
    },[takeId, catalog]);

    return (
        <div className={classes.root} key={locationActionID}>
            {!selectedActionForView
                ? <Loading/>
                : <div className={classes.rootWrapper}>
                    <div className={classes.details}>
                        <div className={classes.detailsImgWrapper}>
                            <img
                                className={classes.detailsImg}
                                src={!!selectedActionForView.fileName
                                    ? `https://mlis.medis.com.ua/modules/bi_action/files/${selectedActionForView.fileName}`
                                    : imgUrl
                                }
                                alt="Action img."
                                width={"100%"}/>
                        </div>
                        <div className={classes.detailsText}>
                            <p>{selectedActionForView.name && selectedActionForView.name}</p>
                            <div className={classes.detailsTextPriceContainer}>
                                <p className={classes.detailsTextPrice}>{selectedActionForView.discount
                                    ? (selectedActionForView.price - (selectedActionForView.price*(selectedActionForView.discount/100))).toFixed(2)
                                    : selectedActionForView.price} грн</p>
                                <div className={classes.addRemoveButton}>
                                    {!!selectedActionForView.price && <AddRemoveIconButtons
                                        item={selectedActionForView}
                                        isIncludedInOrderingList={isIncludedInOrderingList}
                                        actionInOrderList={actionInOrderList}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.expansionPanel}>
                        {<ExpansionPanel title="Дослідження що входять в комплекс:">
                            <div className={classes.expansionPanelInnerWrapper}>
                                {selectedActionForView && selectedActionForView.filling && [...selectedActionForView.filling, ...selectedActionForView.idGrTest].map(item => (<div key={item.id}>{item.name}</div>))}
                            </div>
                        </ExpansionPanel>}
                    </div>
                </div>}
            <div className={classes.orderButtonContainer}>
                <Button
                    className={classes.orderButton}
                    variant="outlined"
                    color="primary"
                    disabled={amountOfItems < 1}
                    onClick={handleTransitionToOrderManager}>
                    Перейти до замовлення
                </Button>
            </div>
            {selectedActionForView && selectedActionForView.name && <Helmet>
                <meta name="og:description" content={selectedActionForView.note}/>
                <meta property="og:url" content={hrefLocation}/>
                <meta name="og:image" content={'imgUrl'}/>
                <meta property="og:site_name" content={takeId === 216 ? 'Medis' : 'Esculab'}/>
                <meta property="og:type" content="article" />
                <meta property="og:title" content={selectedActionForView.name ? selectedActionForView.name : selectedActionForView.testNames.split(',')[0].split('\\\\').join('')} />
            </Helmet>}
        </div>
    );
};

export default ComplexDetails;
