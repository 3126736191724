import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {CustomDialog, LineDialog} from "../Dialogs";
import {addSelectedPunkt} from "@cabinet-packages/redux";

const options = [
    'Купити',
    'Купити та записатись на візит',
];

const useStyle = makeStyles((theme) => ({
    root: {

    },
    pooper: {
        zIndex: 1000,
    },
}));

export default function ButtonGroupPunkts({className, buttonProps}) {
    const cl = useStyle();
    const {amountOfItems} = useSelector(state => state.orderAssays);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [selectedPoint, isComponentInShop, history, themeMaterialUI, dispatch] = buttonProps;

    const handleClick = () => {
        switch (selectedIndex) {
            case 0:
                CustomDialog(LineDialog,
                    {point: selectedPoint, isComponentInShop, history, themeMaterialUI},
                    false,
                    themeMaterialUI);
                break;
            case 1:
                if (!selectedPoint) {
                    isComponentInShop ? history.push('/shop-assay-scheduler-time') : history.push('/assay-scheduler-time');
                    return;
                }
                dispatch(addSelectedPunkt(selectedPoint));
                isComponentInShop ? history.push('/shop-assay-scheduler-time') : history.push('/assay-scheduler-time');
                break;
            case 2:
                CustomDialog(LineDialog,
                    {point:selectedPoint, isComponentInShop, history, themeMaterialUI, selectTime: true},
                    false,
                    themeMaterialUI);
                break;
            default:
                CustomDialog(LineDialog,
                    {point: selectedPoint, isComponentInShop, history, themeMaterialUI},
                    false,
                    themeMaterialUI);
                break;
        }
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <ButtonGroup
                // disabled={amountOfItems === 0}
                className={className} variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                <Button onClick={handleClick}>{options[selectedIndex]}</Button>
                <Button
                    color="primary"
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
                    className={cl.pooper}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
