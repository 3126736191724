import React from "react";
import {SET_ACTIVE_STEP, SET_NEXT_STEP, SET_PREVIOUS_STEP, SET_STEPS} from "./wizardStepperReducer";

export const setSteps = (steps) => async (dispatch, getState, { snackbar }) => {
  dispatch(SET_STEPS(steps));
};

export const setActiveStep = (stepNumber) => async (dispatch, getState, { snackbar }) => {
  dispatch(SET_ACTIVE_STEP(stepNumber));
};

export const setNextStep = () => async (dispatch, getState, { snackbar }) => {
  const {WIZARD: {activeStep, steps}} = getState();
  const nextStep = activeStep + 1;

  if (nextStep < steps.filter(item => item.showStep()).length) {
    dispatch(SET_NEXT_STEP(nextStep));
  }
};

export const setPreviousStep = () => async (dispatch, getState, { snackbar }) => {
  const {WIZARD: {activeStep, steps}} = getState();
  const previousStep = activeStep - 1;

  if (previousStep >= 0) {
    dispatch(SET_PREVIOUS_STEP(previousStep));
  }
};
