import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    rootFindOrder: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: '100%',
        padding: theme.spacing(1),
    },
    searchWrapper: {
        width: '100%',
        maxWidth: '280px',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(1),
    },
    searchOrderButton: {
        marginTop: theme.spacing(1),
        height: '40px',
        width: '200px'
    },
    rootFullOrderView: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: 'max-content',
        maxWidth: "600px",
        textAlign: 'left',
        marginTop: '8px',
        border: `1px solid #31ff39`,
        borderRadius: '8px',
        padding: '8px',
        backgroundColor: 'rgba(49,255,57,0.1)'
    },
    error: {
        textAlign: "center",
        color: "red",
        marginTop: '8px',
    },
    orderDataText: {
        textAlign: 'left',
        width: '70%'
    },
    resultWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: 'max-content',
        width: '30%'
    },
    orderDataButton: {
        width: '100%',
        maxHeight: '30px',
        margin: 'auto'
    },
    resultDataText: {
        fontStyle: 'italic',
        fontSize: '14px',
    },
    orderDataAssay: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        padding: theme.spacing(0.25),
        paddingLeft: theme.spacing(1),
        margin: theme.spacing(0.25),
        borderRadius: '8px',
        border: `1px solid #c3c15c`,
        backgroundColor: 'rgba(255,252,49,0.1)',
        width: '100%',
        alignItems: 'center',
    },
    fieldsWrapper: {
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    searchOrderWrapper: {
        width: '270px'
    },
    printOrderPdfRoot: {
        width: '100%',
        maxWidth: '280px',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(1),
    },
    printOrderPdfButton: {
        width: '200px'
    },
}));

export default useStyles;
