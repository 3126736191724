import React, {useState} from 'react';
import useStyles from '../styles';
import {AutoSizer, List} from 'react-virtualized';
import {useDispatch, useSelector} from "react-redux";
import getAssayRowHeight from "../helpers/getAssayRowHeight";
import RowRenderer from "../helpers/RowRenderer";
import TabsCatalog from "./TabsCatalog";
import CatalogHeader from "./CatalogHeader";
import {useMediaQuery} from "@material-ui/core";

const noRowRenderer = () => {
    return (<p style={{textAlign: 'center'}}>Вибачте, за даними пошуку нічого не знайдено</p>)
}

const AutoSizerCatalog = ({useCatalogForView, useSelectedTag, usePrevState, handleValue}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const [catalogForView, setCatalogForView] = useCatalogForView;
    const {complexResearch, selectedTags}  = useSelector(state => state.assaysCatalog);
    const { orderingList, fullOrderData } = useSelector(state => state.calculator);
    const scrollIndex = 0;
    const [scrollIndexActive, setScrollIndexActive] = useState(null);
    const isDesktop = useMediaQuery('(min-width: 1180px)');


    return (catalogForView && <>
        <TabsCatalog useCatalogForView={useCatalogForView} useSelectedTag={useSelectedTag} usePrevState={usePrevState} handleValue={handleValue}/>
        <div className={cl.autoSizerWrapper}>
            <CatalogHeader/>
            {catalogForView && <AutoSizer key={!useSelectedTag[0] && !selectedTags[0]
                ? catalogForView && catalogForView.length > 0 ? catalogForView[0].idParent : 'list'
                : useSelectedTag[0] + (selectedTags[0] ? selectedTags[0].name : 'anotherList')
            }>
                {({height, width}) => (
                    <List
                        className={cl.list}
                        width={width}
                        height={height - 48}
                        scrollToIndex={scrollIndexActive ? scrollIndexActive : scrollIndex}
                        noRowsRenderer={noRowRenderer}
                        scrollToAlignment="start"
                        rowCount={catalogForView.length}
                        rowHeight={({index}) => getAssayRowHeight(catalogForView[index], width, isDesktop)}
                        rowRenderer={(props) => RowRenderer(props, catalogForView, cl, complexResearch, orderingList,
                            useSelectedTag, useCatalogForView, dispatch,
                            usePrevState, selectedTags, fullOrderData)}
                    />)}
            </AutoSizer>}
        </div>
    </>);
};

export default AutoSizerCatalog;
