import React from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  assayTableTitle: {
    marginBottom: "10px",
    fontWeight: "600",
    cursor: "pointer",
    transition: "color 0.5s",

    "&:hover": {
      transition: "color 0.5s",
      color: theme.palette.primary.main,
    },
  },
  assayTableTitleTime: {
    fontWeight: "500",
  },
  table: {
    wordBreak: "break-all",
    tableLayout: "fixed",
    width: "100%",
  },
  tableForInnerHTML: {
    maxWidth: "95vw",
    color: 'rgba(33, 69, 114, 0.87)',
  },
  columnLabel: {
    fontSize: "12px",
    color: 'rgba(33, 69, 114, 0.87)',
    fontWeight: 600,
    "@media(max-width: 900px)": {
      fontSize: "9px",
    },
  },
  researchDescription: {
    display: "flex",
    rowGap: "10px",
    padding: "10px 10px",
    backgroundColor: "#F8F9F9",
    margin: "10px 0px",
    width: "100%",
  },
  resultLabel: {
    fontSize: "14px",
    color: 'rgba(33, 69, 114, 0.87)',
    fontWeight: 600,
    background: theme.palette.primary.background
  },
}));

const ResultHTML = ({ assay }) => {
  const classes = useStyles();
  const mdUp = useMediaQuery('@media (maxWidth: 950px)');

  return (
    <>
      <div className={classes.assayTableTitle}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 10px",
            }}
          >
            <Typography className={classes.columnLabel}>
              Назва дослідження
            </Typography>
            {+assay.ready === 0 ? <Typography variant="caption" color="error">
                  Результати ще не готові. Очікуйте сповіщення про готовність.
                </Typography>
                : <Typography className={classes.columnLabel}>
                  Результат готовий
                </Typography>}
          </div>
          <div className={classes.researchDescription}>
            <Typography
              style={mdUp ? { flex: "3 1 0" } : { flex: "2 1 0" }}
              className={classes.resultLabel}
              color={"textPrimary"}
            >
              {`${assay.packet.length > 0 ? assay.packet : assay.test}`}
            </Typography>
            <Typography
              style={mdUp ? {} : { flex: "2 1 0", textAlign: "end" }}
              className={classes.resultLabel}
              color={"textPrimary"}
              component={"div"}
            >
              <em className={classes.assayTableTitleTime}>
                {+assay.ready !== 0 && assay.utime.slice(0, 16).split("-").join(".")}
              </em>
            </Typography>
          </div>
        </div>
      </div>
      <Table className={classes.table} size="small" padding="none">
        <TableBody className={classes.tableForInnerHTML}>
          <TableRow>
            <TableCell colSpan="4">
              <div
                dangerouslySetInnerHTML={{
                  __html: assay.result
                    ? assay.result.split("width").join("arr")
                    : assay.resulttmlt,
                }}
                className={classes.tableForInnerHTML}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default ResultHTML;
