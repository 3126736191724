import React, {useContext} from "react";
import useStyles from "../styles";
import {InsertResultDialogContext} from "../index";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";

const TestResultInput = ({test, idx}) => {
    const cl = useStyles();
    const {useTestData: [testData, setTestData], resultInputRef, handleInsertResult} = useContext(InsertResultDialogContext);

    const handleChangeResult = (event) => {
        event.persist();

        setTestData(prev => prev.map(item => {
            if (item.idResult === test.idResult) {
                return ({
                    ...item,
                    result: event.target.value,
                });
            } else {
                return item;
            }
        }));
    };

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            handleInsertResult(e);
        }
    };

    return (test.possibleResult && typeof test.possibleResult === 'object' && test.possibleResult.length
            ? <FormControl className={cl.formControl}>
                <InputLabel id="simple-select-label">{test.name}</InputLabel>
                <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={testData.find(item => +item.idResult === +test.idResult).result}
                    onChange={handleChangeResult}
                >
                    {test.possibleResult.map((testResult, idx) => (
                        <MenuItem value={testResult} key={test.idResult+'testResult'+testResult+idx}>
                            {testResult}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            : <TextField
                inputRef={+idx === 0 ? resultInputRef : null}
                variant="outlined"
                placeholder="Введіть результат"
                label={test.name}
                color="secondary"
                value={testData.find(item => +item.idResult === +test.idResult).result}
                onChange={handleChangeResult}
                onKeyDown={handleKeyPress}
            />
        );
};

export default TestResultInput;
