import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@material-ui/core';

function ConfirmDialog({
  onConfirm = () => null,
  onCancel = () => null,
  handleClose,
  text = 'Ви впевнені, що хочете це зробити?',
  helperText
}) {
  const handleCancel = () => {
    onCancel();
    handleClose();
  };
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };
  return (
    <Dialog
      open={true}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>
        Підтвердження
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
          {helperText && <><br/><Typography variant="caption">{helperText}</Typography></>}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Ні
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          Так
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;