import React, {useContext, useEffect, useState} from "react";
import useStyles from "../styles";
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import {setActiveNurse, setOrderVials} from "@cabinet-packages/redux/actions/actionsCreators";
import {InsertResultDialogContext} from "../index";

const SelectNurse = () => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const {nurses, activeNurse, fullOrderData, vials} = useSelector(state => state.calculator);
    const {nurseIdRef, useNurseId: [nurseId, setNurseId], resultInputRef} = useContext(InsertResultDialogContext);
    const [foundedActiveNurse, setFoundedActiveNurse] = useState(null);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        setNurseId(e.target.value)
    };

    const handleKeyPress = (e) => {
        if (e.keyCode === 13 && nurseId.length) {
            const nurseIdFormated = (nurseId+'').length === 10 && +(nurseId+'')[0] === 3 ? +nurseId.slice(-5) : +nurseId;
            dispatch(setActiveNurse(+nurseIdFormated));

            if (nurses.find(nurse => +nurse.id === +nurseIdFormated)) {
                setNurseId('');
                if (vials && vials.length) {
                    dispatch(setOrderVials(vials.map(item => {
                        return ({
                            ...item,
                            selectedNurseId: +nurseIdFormated,
                        });
                    })))
                }
            }
            if (resultInputRef.current) {
                setTimeout(()=>resultInputRef.current.focus(), 250);
            }
        }
    };

    useEffect(() => {
        if (activeNurse) {
            const nurse = nurses
                .map(nurse => ({...nurse, fullname: `${nurse.lastname} ${nurse.firstname} ${nurse.fathername}`}))
                .find(item => +item.id === +activeNurse)?.fullname;
            if (nurse) {
                setFoundedActiveNurse(nurse);
            } else {
                setError(`Медсестру з ID ${activeNurse} не знайдено.`);
                setNurseId('');
                nurseIdRef.current && nurseIdRef.current.focus();
                dispatch(setActiveNurse(null));
            }
        }
    }, [activeNurse]);

    return (<div className={cl.searchWrapper}>
        <TextField
            inputRef={nurseIdRef}
            key={'textFieldActiveNurse'+activeNurse}
            variant="outlined"
            placeholder="Введіть ID медсестри"
            label="Вибір медсестри"
            color="secondary"
            value={nurseId}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            autoFocus
        />
        <Button
            variant="contained"
            color="secondary"
            onClick={() => handleKeyPress({keyCode: 13})}
            className={cl.searchOrderButton}
        >
            обрати медсестру
        </Button>
        {activeNurse && nurses && <>
            <p style={{marginTop: '8px'}}><b>Вибрана медсестра:</b></p>
            <p>{foundedActiveNurse}</p>
        </>}
        {error && <p className={cl.error}>{error}</p>}
    </div>);
}

export default SelectNurse;
