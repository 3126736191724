import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AddRemoveIconButtons, Loading, SearchTextField} from "@cabinet-packages/components";
import {clearOrderedAssays, getCatalog, setSelectedComplex} from "@cabinet-packages/redux";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {Button, makeStyles, TableBody} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import {useFilter} from '@cabinet-packages/hooks';
import Table from "@material-ui/core/Table";
import {useHistory} from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import LocationSVG from "../assets/LocationSVG";
import {createFilterOptions} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            height: '100%',
            color: 'rgb(70, 70, 70)',
            padding: '10px',
            '@media (max-width: 900px)': {
                padding: '0px',
                paddingBottom: '5px',
                height: '98%',
            },
        },
        tableComprehensiveResearch: {
            width: '100%',
            '@media (min-width: 300px) and (max-width:991px)': {
                overflowX: 'auto',
                maxWidth: '100%'
            },
        },
        containerTable: {
            position: 'absolute',
            width: '100%',
            padding: '5px',
            '@media (min-width: 300px) and (max-width:991px)': {
                padding: '5px',
            },
        },
        containerScheduler: {
            overflow: 'auto',
            position: 'relative',
            width: '100%',
            height: 'calc(100% - 51px)',
        },
        tableTitle: {
            height: '50px',
            background: theme.palette.secondary.main,
        },
        tableTitleSelected: {
            background: 'rgba(0, 0, 0, 0.1)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        },
        tableTd: {
            padding: '5px 10px',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            '&:hover': {
                color: theme.palette.secondary.main,
            },
        },
        tableTitleTdHeader: {
            backgroundColor: theme.palette.primary.main,
            height: '60px',
            textAlign: 'center',
            padding: '6px 16px',
            zIndex: '5',
            fontSize: '15px',
            width: 'calc(100% - 1px)',
            '&:first-child': {
                padding: '7px',

            },

            '&:last-child': {
                lineHeight: '15px',
                alignItems: 'center',
                justifyContent: 'center',
            },

            '@media (max-width: 900px)': {
                fontSize: '13px',
            },
            },
        tableTitleTdHeaderAssayName: {
            backgroundColor: theme.palette.primary.main,
            height: '60px',
            textAlign: 'left',
            padding: '8px 16px',
            zIndex: '5',
            fontSize: '15px',
            width: 'calc(100% - 1px)',
            '&:first-child': {
                padding: '7px',

            },

            '&:last-child': {
                lineHeight: '15px',
                alignItems: 'center',
                justifyContent: 'center',
            },

            '@media (max-width: 900px)': {
                fontSize: '13px',
            },
        },
        listHeaderRedDiscount: {
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontSize: '24px',
            fontWeight: '600',
            color: 'white',
            background: theme.palette.error.light,
            border: `2px solid ${theme.palette.error.main}`,
            borderRadius: '16px',
            width: '32px',
            height: '32px',
            position: 'absolute',
            top: 3,
            right: 0,

            '@media (max-width: 900px)': {
                display: 'none',
            },
        },
        tableHeaderSpan: {
            fontSize: '10px',
        },
        tableTdCenter: {
            textAlign: 'center',
        },
        tableDiscount: {
            border: 'none',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            '@media ((min-width: 400px) and (max-width: 900px))': {
                padding: '7px 5px',
                display: 'grid',
            },
            '@media (max-width: 399px)': {
                padding: '7px 0px',
                display: 'block',
                fontSize: '12px',
            },
        },
        textFieldContainer: {
            marginBottom: '10px',
        },
        filterHeader: {
            marginLeft: '1px',
            marginBottom: '10px',
            marginRight: 0,
            width: '100%'
        },
        tableTdHeader: {
            padding: '0 8px',
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
            borderRight: '1px solid rgba(224, 224, 224, 1)',
            borderBottom: 'none',
            '@media (max-width: 900px)': {
                padding: '3px',
                maxWidth: '170px'
            },
        },
        tableTdHeaderComplexName: {
            padding: '0 8px',
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
            borderRight: '1px solid rgba(224, 224, 224, 1)',
            borderBottom: 'none',
            fontWeight: 600,
            fontSize: '16px',
            cursor: 'pointer',
            transition: '.5s',

            '&:hover': {
                color: 'white',
                transition: '.5s',
            },
            '@media (max-width: 900px)': {
                padding: '3px',
                maxWidth: '170px'
            },
        },
        tableTdHeaderPrice: {
            padding: '7px 16px',
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
            borderRight: '1px solid rgba(224, 224, 224, 1)',
            borderBottom: 'none',
            textAlign: 'center',
            '@media (max-width: 900px)': {
                padding: '3px',
            },
        },
        complexAddRemoveButton: {
            padding: '0px',
        },
        headerBlock: {
            background: 'red',
        },
        buttonContainer: {
            margin: '4px',
        },
        orderButtonResearch: {
            width: '100%',
        },
        listItemNoRowRenderer: {
            background: theme.palette.primary.item,
            width: 'calc(100% - 5px)',
        },
    tableTitleTdImg: {
            height: '25px',
            marginLeft: '5px',
        },
    listItemPriceNumber: {
        fontWeight: 700,
        alignSelf: 'center',
    },
    autoCompleteWrapper: {
        marginLeft: '16px',
        width: '30%',
        position: 'relative',

        '@media (max-width: 900px)': {
            display: 'none',
        },
    },
    autoComplete: {
        width: '100%',
    },
    locationSVGButton: {
        position: 'absolute',
        top: '20px',
        right: '5px',
        alignItems: 'center',
        cursor: 'pointer',
    },
    locationSVG: {
        margin: '0 auto',
    },
    autocomplitsWrapper: {
        '@media (min-width: 900px)': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
    }
}));

const ComplexResearch = ({isComponentInShop}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isUserTypeInSearch, setIsUserTypeInSearch] = useState(false);
    const {complexResearch, takeId, catalog} = useSelector(state => state.assaysCatalog);
    const {orderingList, selectedComplex, amountOfItems} = useSelector(state => state.orderAssays);
    const {regions} = useSelector(store => store.collectionPoints);
    const [open, setOpen] = useState(false);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [isUserSelectPoint, setIsUserSelectPoint] = useState(false);
    const filteredOptions = createFilterOptions({
        limit: 100
    });

    const handleClose = (reason) => {
        setOpen(false);
    };


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClickOnPunkt = (event, point) => {
        if (point && point.idReg && event.type !== 'blur') {
            localStorage.setItem('idReg', `${point.idReg}`);
            dispatch(getCatalog(true));
            setSelectedPoint(point);
            setIsUserSelectPoint(true);
            dispatch(clearOrderedAssays());
        }
    };

    useEffect(() => {
        if (regions && regions.length > 0 && !selectedPoint && !isUserSelectPoint) {
            const idReg = localStorage.getItem('idReg');
            setSelectedPoint(regions.find(item => +item.idReg === +idReg));
        }

    }, [selectedPoint, isUserSelectPoint, regions]);

    const {
        filteredState,
        filterValue,
        handleChangeFilterValue,
    } = useFilter(complexResearch, filterComplex);

    function filterComplex(item, value) {
        if (!item || !value) {
            return true;
        }
        if (selectedComplex && selectedComplex.foundedComplex && !isUserTypeInSearch) {
            return !!selectedComplex.foundedComplex.find(com => com.id === item.id);
        }
        if ((item.name && item.name.toLowerCase().includes(value.trim().toLowerCase()))
            || (item.testNames && item.testNames.trim().toLowerCase().includes(value.trim().toLowerCase()))
            || (!!item.filling.find(fill => !!fill.name.trim().toLowerCase().includes(value.trim().toLowerCase())))
        ) {

            return true;
        } else return !!item.filling.some(element => (value && element.test && element.test.trim().toLowerCase().includes(value.trim().toLowerCase())));
    }

    useEffect(() => {
        if (selectedComplex && selectedComplex.assayName !== filterValue && !isUserTypeInSearch) {
            handleChangeFilterValue({target: {value: selectedComplex.assayName}});
        }

        return () => {
            if (selectedComplex) dispatch(setSelectedComplex(null));
        }
    }, []);

    useEffect(() => {
        if (!catalog && takeId) {
            dispatch(getCatalog());
        }
    }, [catalog, takeId, dispatch])

    if (!complexResearch) {
        return <Loading/>;
    }

    const handleSearchType = (event) => {
        handleChangeFilterValue(event);
        if (!isUserTypeInSearch) setIsUserTypeInSearch(true)
        if (selectedComplex) dispatch(setSelectedComplex(null))
    };

    const handleTransitionToOrderManager = (event) => {
        event.stopPropagation();
        event.preventDefault();
        isComponentInShop ? history.push('/shop-order-manager') : history.push('/order-manager');
    };

    const handleTransitionToAssayInfo = (fil) => {
        let findAssay = catalog.find(item => item.filling && item.filling.find(fill => fill && fill.id === fil.id));

        if (fil.type === 'assay') findAssay = catalog.find(item => item.id === fil.id);

        if (findAssay) {
            isComponentInShop
                ? history.push(`/shop-assay-info/${findAssay.id}`)
                : history.push(`/assay-info/${findAssay.id}`)
        }
    };

    const handleTransitionToComplexInfo = (event, id) => {
        event.stopPropagation();
        event.preventDefault();

        isComponentInShop
            ? history.push(`/shop-complex-info/${id}`)
            : history.push(`/complex-info/${id}`);
    };

    const NoRowRenderer = () => (
        <TableRow className={classes.listItemNoRowRenderer}>
            <TableCell>Вибачте, нічого не знайдено.</TableCell>
            <TableCell/>
            <TableCell/>
        </TableRow>
    );

    return (
        <div className={classes.root}>
            <div className={classes.containerScheduler}>
                <div className={classes.containerTable}>
                    <div className={classes.autocomplitsWrapper}>
                        <SearchTextField
                            className={classes.filterHeader}
                            value={filterValue === undefined ? '' : filterValue}
                            onChange={(event) => handleSearchType(event)}
                        />
                        {takeId === 216 && <div className={classes.autoCompleteWrapper}>
                            <Autocomplete
                                className={classes.autoComplete}
                                autoSelect={true}
                                id="combo-box"
                                open={open}
                                disableClearable={true}
                                onOpen={handleOpen}
                                onClose={(event, reason) => handleClose(reason)}
                                freeSolo
                                value={selectedPoint}
                                options={
                                    regions
                                        ? regions
                                        : []
                                }
                                filterOptions={filteredOptions}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => handleClickOnPunkt(event, value)}
                                renderInput={(params) => (<TextField
                                    {...params}
                                    label="Виберіть місто зі списку"
                                    variant="outlined"
                                />)}
                            />
                            <div
                                onClick={() => isComponentInShop
                                    ? history.push('/shop-collection-points')
                                    : history.push('/collection-points')
                                }
                                className={classes.locationSVGButton}
                            >
                                <LocationSVG className={classes.locationSVG}/>
                            </div>
                        </div>}
                    </div>
                    <Table className={classes.tableComprehensiveResearch} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableTitleTdHeaderAssayName}>Назва дослідження</TableCell>
                                <TableCell className={classes.tableTitleTdHeader}>Ціна, грн</TableCell>
                                <TableCell className={classes.tableTitleTdHeader}>
                                    Ціна <br/>он-лайн, грн
                                    {takeId === 216 && <span className={classes.listHeaderRedDiscount}>%</span>}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {complexResearch
                                && typeof complexResearch !== 'string'
                                && filteredState
                                && typeof filteredState !== 'string'
                                && filteredState.length > 0
                                    ? filteredState.map((com) => {
                                    const isIncludedInOrderingList = orderingList.find(assay => assay.id === com.id && assay.type === com.type);
                                    return (
                                        <Fragment key={com.id}>
                                            <TableRow className={orderingList.includes(com.id) ? classes.tableTitleSelected : classes.tableTitle} style={{background: isIncludedInOrderingList ? 'rgba(0, 0, 0, 0.2)' : '#FFC100'}}>
                                                <TableCell
                                                    className={classes.tableTdHeaderComplexName}
                                                    onClick={(e) => handleTransitionToComplexInfo(e, com.id)}
                                                >
                                                    {com.name}
                                                </TableCell>
                                                <TableCell className={classes.tableTdHeaderPrice}> {+com.price}</TableCell>
                                                <TableCell className={classes.tableTdHeader}>
                                                    <span className={classes.tableDiscount}>
                                                        <span className={classes.listItemPriceNumber}>{com.price - (com.price / 100 * com.discount)}</span>
                                                        <span className={classes.complexAddRemoveButton}>
                                                            <AddRemoveIconButtons
                                                                item={com}
                                                                isIncludedInOrderingList={isIncludedInOrderingList}
                                                            />
                                                        </span>
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                            {com.filling && [...com.filling, ...com.idGrTest].map((fil, i) => {
                                                return (
                                                    <TableRow
                                                        key={i}
                                                        style={{background: isIncludedInOrderingList
                                                            ? i%2 === 0 ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.2)'
                                                            : takeId === 216
                                                                ? i%2 === 0 ? 'none': 'rgba(0, 0, 0, 0.05)'
                                                                : 'none'}
                                                        }
                                                    >
                                                        <TableCell
                                                            className={classes.tableTd}
                                                            style={{cursor: 'pointer'}}
                                                            onClick={() => handleTransitionToAssayInfo(fil)}
                                                        >
                                                            {fil.name}
                                                        </TableCell>
                                                        <TableCell className={classes.tableTd}/>
                                                        <TableCell className={classes.tableTd}/>
                                                    </TableRow>)
                                            })}
                                        </Fragment>)
                            })
                            : <NoRowRenderer/>}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    className={classes.orderButtonResearch}
                    variant="contained"
                    color="primary"
                    disabled={amountOfItems < 1}
                    onClick={handleTransitionToOrderManager}
                >
                    {amountOfItems > 0 ? 'Перейти до замовлення' : 'Чомусь у Вашому кошику ще нічого немає'}
                </Button>
            </div>
        </div>
    )
}

export default ComplexResearch;
