export default function getAmountOfLines(containerWidth, sentence, symbolWidth) {
  const splitSentence = sentence.split(' ');
  let lines = 1;
  let sentenceWidth = 0;
  splitSentence.forEach(word => {
    const wordWidth = word.length * symbolWidth;
    const newWidth = sentenceWidth + wordWidth;
    if (newWidth > containerWidth) {
      lines += 1;
      sentenceWidth = wordWidth;
    } else {
      sentenceWidth += wordWidth;
    }
  });
  return lines;
}
