import React from "react";
import {Dialog, makeStyles} from "@material-ui/core";
import PublicOfferCenter from "./PublicOfferCenter";
import PublicOffer from "./PublicOffer";
import closeImg from "../../img/close.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDialog-paper': {
            width: '100%',
            padding: '25px 81px 30px 103px',
            position: 'relative',
            overflowX: 'hidden',
            '@media (min-width: 900px)': {
                maxWidth: '90vw',
                // minHeight: 90vh
            },
            '@media (min-width: 400px) and (max-width: 900px)': {
                padding: '20px 26px 30px 17px',
                margin: '50px 32px 50px 40px',
            },
            '@media (max-width: 400px)': {
                padding: '20px 26px 30px 17px',
                margin: '50px 12px 50px 20px',
            },

        },
    },
    wrapperImg: {
        marginLeft: 'auto',
        marginBottom: -36,
        cursor: 'pointer',
        position: 'relative',
    }
}))

const PublicOfferDialog = ({handleClose , idReg}) => {
    const cl = useStyles()


    return(<Dialog
        open={true}
        onClose={handleClose}
        className={cl.root}
    >
       <div className={cl.wrapperImg}><img src={closeImg} alt="close" onClick={handleClose}/></div>
        {+idReg === 4400  ? <PublicOfferCenter/> :  <PublicOffer />}
    </Dialog>)
}

export default PublicOfferDialog