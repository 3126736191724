import React, {useEffect, useRef, useState} from "react";
import useStyles from "../styles";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {IconButton, Menu, MenuItem} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedTags} from "@cabinet-packages/redux";
import {toggleComplexTransition} from "@cabinet-packages/redux/actions/actionsCreators";


const EndAdornment = ({useSelectedTag, useCatalogForView, handleValue, usePrevState, expand}) => {
    const cl = useStyles();
    const ref = useRef();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [selectedTag,setSelectedTag] = useSelectedTag;
    const [anchorEl, setAnchorEl] = useState(null);
    const {tags, selectedTags, catalog, complexResearch, complexTransition} = useSelector(state => state.assaysCatalog);
    const [catalogForView, setCatalogForView] = useCatalogForView;
    const [filteredValue, handleChangeFilterValue] = handleValue;
    const [prevStateOfCatalog, setPrevStateOfCatalog] = usePrevState;


    const handleClick = (e) => {
        e.persist();

        setAnchorEl(ref.current);
        setOpen(prevState => !prevState);
        // const prevValue = filteredValue;
        // handleChangeFilterValue({target: { value: prevValue+ ' '}});
        // handleChangeFilterValue({target: { value: prevValue}});
    };

    const handleClose = (e, tag) => {
        setAnchorEl(null);
        setOpen(false);


        if (typeof tag === 'object' && tag.name !== 'Усі') {
            setSelectedTag(tag.name);
            dispatch(setSelectedTags([tag]));
            setCatalogForView(null);
            setCatalogForView([...catalog, ...complexResearch].filter(item => !item.superGroupName));
            setPrevStateOfCatalog(null);
        } else if (typeof tag === 'object' && tag.name === 'Усі') {
            setSelectedTag(tag.name);
            dispatch(setSelectedTags([]));
            setCatalogForView(null);
            setCatalogForView([...catalog, ...complexResearch].filter(item => !item.superGroupName));
            setPrevStateOfCatalog(null);
        }

        if (complexTransition) {
            dispatch(toggleComplexTransition());
        }

        if (filteredValue) {
            handleChangeFilterValue({target: {value: ''}});
        }
    };

    useEffect(() => {
        if (ref.current) {
            if (open) {
                ref.current.previousSibling.style.paddingRight = '181px'
            } else {
                ref.current.previousSibling.style.paddingRight = '61px'
            }
        }
    }, [open, ref]);

    return (<>
        <div className={open ? cl.endAdornmentOpen : cl.endAdornment} ref={ref}
             style={expand ? {transition: 'width .5s'} : {width: '0px', height: '0', transition: 'width .5s'}}
        >
            <p className={cl.endAdornmentText}
               style={expand ? {transition: 'all .5s'} : {display: 'none', transition: 'all .5s'}}>
                {selectedTag === 'Усі' ? selectedTag : selectedTags && selectedTags[0] && selectedTags[0].name}
            </p>
            <IconButton size='small' onClick={handleClick}
                        style={expand ? {transition: 'all .5s'} : {display: 'none', transition: 'all .5s'}}>
                {open ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
            </IconButton>
        </div>
        <Menu
            classes={{
                paper: cl.endAdornmentMenu
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            getContentAnchorEl={null}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <MenuItem classes={{root: cl.endAdornmentMenuItem}}
                      onClick={(e) => handleClose(e, {name: 'Усі'})}>Усі</MenuItem>
            {tags && tags.map(item => (
                <MenuItem classes={{root: cl.endAdornmentMenuItem}} key={'tags'+item.name+item.id}
                          onClick={(e) => handleClose(e, item)}>{item.name}</MenuItem>))
            }
        </Menu>
    </>);
}

export default EndAdornment;
