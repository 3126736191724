import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    rootWrapper: {
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '8px 0px 8px 0px',
        backgroundColor: 'white'
    },
    root: {
        width: '100%',
        height: '100%',
        maxWidth: '1180px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        boxShadow: '0px 0px 10px -2px #363D4D',
        borderRadius: '10px',
        paddingBottom: theme.spacing(1),
    },
    heading: {
        background: theme.palette.primary.dark,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingLeft: theme.spacing(7.5),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderRadius: '10px 10px 0px 0px',

        '@media (max-width: 1180px)': {
            padding: theme.spacing(2),
        }
    },
    headingText: {
        fontSize: '16px',
        fontWeight: 600,
        textTransform: 'uppercase',
        lineHeight: '21px',
        color: 'white',
    },
    selectedTabContentWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    tabLabel: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        textAlign: 'left'
    },
    tabLabelNewPatient: {
        fontWeight: 600,
        fontSize: '14px',
        color: theme.palette.green.dark,
        opacity: 0.5
    },
    tabLabelNameSelected: {
        fontWeight: 600,
        transition: 'all 0.5s',
    },
    tabLabelName: {
        fontWeight: 600,
        transition: 'all 0.5s',
    },
    tabLabelRole: {
        color: theme.palette.grey.text,
        fontWeight: 500,
        fontSize: '10px'
    },
    orderDatePunktWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: 'max-content',
        paddingLeft: theme.spacing(7.5),
        paddingRight: theme.spacing(8.5),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        borderBottom: `1px solid ${theme.palette.grey.border}`,

        '& :first-child': {
            marginLeft: 0
        },
        '& :last-child': {
            marginRight: 0
        },

        '@media (max-width: 1180px)': {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            padding: theme.spacing(2),

        }
    },
    infoFieldWrapper: {
        width: '100%',
        height: 'max-content',
        maxWidth: '360px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginRight: theme.spacing(1.25),
        marginLeft: theme.spacing(1.25),

        '@media (max-width: 1180px)': {
            margin: 0,
            marginTop: theme.spacing(1)
        }
    },
    infoFieldHeading: {
        color: theme.palette.grey.text,
        paddingLeft: theme.spacing(2.25),
        fontSize: '12px',
    },
    infoField: {
        borderRadius: '5px',
        border: `2px solid ${theme.palette.grey.border}`,
        width: '100%',
        height: '42px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `0px 16px`,
        marginTop: theme.spacing(1),
    },
    infoFieldText: {
        fontSize: '14px',
        fontWeight: 600,
    },
    orderAssaysWrapper: {
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    orderAssaysHeadingWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingLeft: theme.spacing(7.5),
        paddingRight: theme.spacing(8.5),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),

        '@media (max-width: 1180px)': {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(6),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        }
    },
    orderAssaysHeading: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
        height: '100%',
    },
    orderAssaysHeadingBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '50%',
        height: '40px'
    },
    orderAssayHeadingText: {
        color: theme.palette.grey.text,
        fontSize: '12px',
    },
    orderAssayHeadingTextName: {
        color: theme.palette.grey.text,
        fontSize: '12px',
        paddingLeft: theme.spacing(2),
    },
    orderAssayHeadingTextSmall: {
        color: theme.palette.grey.text,
        fontSize: '9px',
    },
    orderAssaysHeadingSmallBox: {
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        '@media (max-width: 1180px)': {
            width: '50%',
        }
    },
    orderAssaysHeadingSmallBoxDesc: {
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        '@media (max-width: 1180px)': {
            display: 'none'
        }
    },
    assayAccordion: {
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
        transition: 'height 0.5s',
        marginBottom: theme.spacing(1),

        '@media (max-width: 1180px)': {
            padding: 0
        }
    },
    assayAccordionDataWrapper: {
        width: '100%',
        height: 'max-content',
        minHeight: '48px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        transition: 'height 0.5s',
        backgroundColor: theme.palette.grey.accordion,
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        borderRadius: '5px',

        '@media (max-width: 1180px)': {
            margin: 0
        }
    },
    assayAccordionData: {
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    assaySmallBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '50%',
    },
    assayName: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '21px',
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        wordWrap: 'break-word',

        '@media (max-width: 1180px)': {
            fontSize: '12px',
        }
    },
    assayAdditionalData: {
        width: '25%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',

        '@media (max-width: 1180px)': {
            width: '50%',
        }
    },
    assayAdditionalDataDesc: {
        width: '25%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',

        '@media (max-width: 1180px)': {
            display: 'none'
        }
    },
    assayTerms: {
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.green.dark
    },
    offlinePrice: {
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.green.lighter
    },
    onlinePrice: {
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.blue.dark,
    },
    onlinePriceOverview: {
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.blue.dark,
        width: '30%',
        textAlign: 'center',

        '@media (max-width: 1180px)': {
            textAlign: 'end',
        }
    },
    onlinePriceOverviewSummary: {
        fontSize: '30px',
        fontWeight: 600,
        color: theme.palette.blue.dark,
        width: '30%',
        textAlign: 'center',

        '@media (max-width: 1180px)': {
            width: 'max-content',
        }
    },
    warningExpanded: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        width: '50%'
    },
    warningExpandedWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    warningExpandedIcon: {
        height: '15px',
        marginRight: theme.spacing(1),
        fill: 'red'
    },
    warningExpandedText: {
        color: theme.palette.error.light,
        fontSize: '15px',
    },
    warningExpandedTextGreen: {
        color: theme.palette.green.dark,
        fontSize: '15px'
    },
    addNewAssayLinkWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.grey.border}`
    },
    addNewAssayLink: {
        fontSize: '14px',
        fontWeight: 600
    },
    orderTermsWrapper: {
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey.border}`,
    },
    orderTerms: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
        height: 'max-content',
        paddingLeft: theme.spacing(7.5),
        paddingRight: theme.spacing(8.5),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),

        '@media (max-width: 1180px)': {
            flexDirection: 'column',
            padding: theme.spacing(2),
        }
    },
    orderTermsSmallBox: {
        width: 'calc(100% / 3)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',

        '@media (max-width: 1180px)': {
            width: '100%',
            alignItems: 'initial'
        }
    },
    orderTermsFormControl: {
        marginBottom: theme.spacing(1),

    },
    orderTermsCheckboxLabel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%'
    },
    checkboxLabelText: {
        display: 'block',
        fontSize: '14px',
        marginRight: theme.spacing(0.5),
    },
    checkboxLabelLink: {
        textDecoration: 'none',
        color: theme.palette.error.light,
        fontSize: '14px',
        fontWeight: 600,
        transition: 'color 0.5s',

        '&:hover': {
            color: theme.palette.primary.main,
            transition: 'color 0.5s',
        },
    },
    orderTermsTextFieldLabel: {
        fontSize: '12px',
        color: theme.palette.grey.text,
        margin: theme.spacing(1),
        width: '100%',
        textAlign: 'left',
        maxWidth: '240px',
    },
    orderTermsOverview: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: '42px',
        alignItems: 'center',
        color: theme.palette.grey.text,
    },
    orderSummaryWrapper: {
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingLeft: theme.spacing(7.5),
        paddingRight: theme.spacing(8.5),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),

        '@media (max-width: 1180px)': {
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
            justifyContent: 'center',
        }
    },
    orderSummary: {
        width: 'calc(100% / 3)',

        '@media (max-width: 1180px)': {
            width: '100%'
        }
    },
    orderSummaryText: {
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.blue.dark,
    },
    zeroAmountOfOrders: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: theme.spacing(2),
        color: theme.palette.error.main,
        fontWeight: 600
    },
}));

export default useStyles;
