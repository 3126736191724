import React from 'react';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    departure: {
        background: theme.palette.colors.secondary,
        fontWeight: 'bold',
        fontSize: '1.25rem',
        color: theme.palette.colors.text,
        height: 75,
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        '@media (max-width: 900px)': {
            padding: '20px 17px',
            borderRadius: '0px !important',
        },
    },
    departureWrapper: {
        maxWidth: 699,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        '@media (max-width: 900px)': {
            '& p': {
                fontSize: '0.75rem',
                textAlign: 'left',
            },
        },
    },
    imgWrapper: {
        display: 'flex',
        height: 24.69,
        '& img': {
            display: 'block',
            marginRight: 13,
        },
    },
    departureButton: {
        transition: '.5s',
        display: 'block',
        height: 45,
        width: 195,
        justifyContent: 'center',
        border: '2px solid' + theme.palette.colors.text,
        borderRadius: 50,
        fontFamily: "'OpenSans-Regular'",
        color: theme.palette.colors.text,
        marginLeft: 'auto',
        paddingTop: 10,
        "&:hover": {
            boxShadow: 'inset 195px 0 0 0' + theme.palette.colors.main,
            color: '#ffffff',
            border: '2px solid' + theme.palette.colors.main,
        },
        '@media (min-width: 351px) and  (max-width: 900px)': {
            width: 128,
            marginLeft: 25,
            height: 35,
            fontWeight: 'bold',
            paddingTop: 5,
        },
        '@media (max-width: 350px)': {
            width: 197,
            marginLeft: 25,
            height: 35,
            fontWeight: 'bold',
            paddingTop: 5,
        },
    },
}))

const Departure = ({border}) => {
    const cl = useStyles()
    return (<></>
        // <div className={cl.departure} style={{borderRadius: border ? '0px 0px 10px 10px' : 0}}>
        //     <div className={cl.departureWrapper}>
        //         <div className={cl.imgWrapper}>
        //             <img src={metroAmbulance} alt="metro-ambulance"/>
        //             <p>Виїзний пункт забору аналізів МеДіС</p>
        //         </div>
        //         <Button className={cl.departureButton}>
        //             Замовити
        //         </Button>
        //     </div>
        // </div>
    )
}

export default Departure