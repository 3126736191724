import CustomDialog from './CustomDialog';
import ConfirmDialog from './ConfirmDialog';
import ConfirmCabinetDialog from './ConfirmCabinetDialog';
import PointsPopupsDialog from './PointsPopupsDialog'
import QRCodeDialog from './QRCodeDialog';
import RefundDialog from "./RefundDialog";
import RespondVisitDialog from "./RespondVisitDialog";
import TextAlertDialog from "./TextAlertDialog";
import LineDialog from "./LineDialog";
import LoadOrderResultDialog from "./LoadOrderResultDialog";
import PhoneEnterDialog from "./PhoneEnterDialog";
import ConfirmPayment from "./ConfirmPayment";
import SelectDoctorPatient from "./SelectDoctorPatient";
import GetClientRegion from "./GetClientRegion";
import AddNewPatientDialog from "./AddNewPatientDialog";
import SignalMarksDialog from "./SignalMarksDialog";
import SelectPartnerPatient from "./SelectPartnerPatient";
import LoadingIndicatorDialog from "./Indicators/LoadingIndicatorDialog";
import ErrorIndicatorDialog from "./Indicators/ErrorIndicatorDialog";
import NewPatientDialog from "./NewPatientDialog";
import WarningDialog from "./WarningDialog/WarningDialog"
import PortmoneDialog from "./PortmoneDialog";
import ClientRegionDialog from "./ClientRegionDialog";
import ConfirmDialogMedis from './ConfirmDialogMedis';
import TextAlertDialogMedis from './TextAlertDialogMedis';
import TextAlertDialogCalculator from "./TextAlertDialogCalculator";
import InsertResultDialog from "./InsertResultDialog";
import ClientRegionDialogVdoma from "./ClientRegionDialogVdoma";
import TextAlertDialogCalculatorWithReload from "./TextAlertDialogCalculatorWithReload";
import AuthDialog from "./AuthDialog";
import AuthDialogSite from "./AuthDialogSite/index"
import ConfirmRegionDialog from "./ConfirmRegionDialog";

export {
  CustomDialog,
  ConfirmDialog,
  ConfirmCabinetDialog,
  PointsPopupsDialog,
  QRCodeDialog,
  RefundDialog,
  RespondVisitDialog,
  TextAlertDialog,
  WarningDialog,
  LineDialog,
  LoadOrderResultDialog,
  PhoneEnterDialog,
  ConfirmPayment,
  SelectDoctorPatient,
  GetClientRegion,
  AddNewPatientDialog,
  SignalMarksDialog,
  SelectPartnerPatient,
  LoadingIndicatorDialog,
  ErrorIndicatorDialog,
  NewPatientDialog,
  PortmoneDialog,
  ClientRegionDialog,
  ConfirmDialogMedis,
  TextAlertDialogMedis,
  TextAlertDialogCalculator,
  InsertResultDialog,
  ClientRegionDialogVdoma,
  TextAlertDialogCalculatorWithReload,
  AuthDialog,
  AuthDialogSite,
  ConfirmRegionDialog,
};
