import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {unsetError} from "@cabinet-packages/redux/actions/actionsCreators";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'rgba(255,0,0,0.1)',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    linearProgress: {
        width: '100%'
    },
}));

const ErrorIndicatorDialog = () => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const {error, errorText} = useSelector(state => state.indicators);

    const onClose = (e) => {
        e.persist();

        dispatch(unsetError());
    }
    return (error && <div className={cl.root} onClick={onClose}>
        {errorText && <p>{errorText}</p>}
    </div>);
};

export default ErrorIndicatorDialog;
