import React, {useCallback, useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import daVinci from '../../assets/shopHome/davinci.png';
import './menu.css';
import {useDispatch, useSelector} from "react-redux";
import {getCatalog} from "@cabinet-packages/redux";
import {useHistory} from "react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        position: 'absolute',
        top: '10%',
        left: 0,
        color: 'rgb(70, 70, 70)',
    },
    mainWrapper: {
        maxWidth: '1056px',
        minHeight: '30rem',
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    circleMenu: {
        height: '30rem',
        width: '30rem',
        borderRadius: '15rem',
        backgroundImage: `url(${daVinci})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'relative',
        margin: '0 auto',
    },
    circleMenuClicker: {
        cursor: 'pointer',
        height: '30rem',
        width: '30rem',
        borderRadius: '15rem',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1000,
        transition: '.5s',
    },
}));

const normIndexLocalStorageKey = 'normsPage-indexOfNorm';

const CircularMenu = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {catalog} = useSelector(state => state.assaysCatalog);
    const [active, setActive] = useState(false);
    const [isUserClick, setIsUserClick] = useState(true);
    const [hoveredItem, setHoveredItem] = useState(-1);
    const [filteredPanels, setFilteredPanels] = useState(null);

    const arrayOfCirclePieces = new Array(12).fill('');

    const handleClickOnMenu = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (typeof event.target.className === 'string') setActive(prev => !prev);
        setIsUserClick(false);
    };

    const handleHighlightCirclePieceOnHover = (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        setHoveredItem(id);
    };

    const handleTransitionToCatalog = (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        localStorage.setItem(normIndexLocalStorageKey, id);
        history.push('/catalog');
    };

    const useTimeout = () => {
        const timeoutIdRef = useRef();
        const cancel = useCallback(() => {
            const timeoutId = timeoutIdRef.current;
            if (timeoutId) {
                timeoutIdRef.current = undefined;
                clearTimeout(timeoutId);
            }
        }, [timeoutIdRef]);

        useEffect(() => {
            if (isUserClick && filteredPanels) {
                timeoutIdRef.current = setTimeout(setActive(true),50);
            }
            return cancel;
        });

        return cancel;
    };

    useTimeout();

    useEffect(() => {
        if (!catalog) dispatch(getCatalog());
        if (filteredPanels) setIsUserClick(false);
        if(catalog && typeof catalog !== "string" && !filteredPanels) {
            setFilteredPanels(catalog.filter(item => item.id !== 52 && item.idParent !== 51).map((assay, idx) => ({
                ...assay,
                normIndex: idx,
            })).filter(assay => +assay.idParent === 0 && +assay.id !== 14 && +assay.id !== 13 && +assay.id !== 52));
        }
    }, [isUserClick, filteredPanels, dispatch, catalog]);

    return (<div className={classes.root}>
        <div className={classes.mainWrapper}>
            <div
                className={classes.circleMenu}
                onClick={event => handleClickOnMenu(event)}
            >
                {filteredPanels && <div className={`menu ${active && 'active'}`}>
                    <div className="icons">
                        {filteredPanels.slice(0,12).map((assay, idx) => (
                                <div className="rotater" key={assay.id}>
                                    <div className="btn btn-icon">
                                        <div className="fa">
                                            <div
                                                className="text-wrapper"
                                                onMouseEnter={(event) =>
                                                    handleHighlightCirclePieceOnHover(event, idx)}
                                                onMouseLeave={() => setHoveredItem(-1)}
                                                onClick={(event) => handleTransitionToCatalog(event, assay.normIndex)}
                                            >
                                                <p className="text_ua">{assay.superGroupName
                                                    ? assay.superGroupName.split('.')[1]
                                                        ? assay.superGroupName.split('.')[1]
                                                        : assay.superGroupName.split('.')[0]
                                                    : assay.name}</p>
                                                <p className="text_en">Infection panel</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>))}
                    </div>
                </div>}
                <svg width="30rem" viewBox="0 0 42 42" className="circle">
                    {arrayOfCirclePieces.map((piece, idx) => {
                            if (idx === 0 || idx === 5 || idx === 6 || idx === 11) {
                                return (
                                    <circle className="circle-piece" cx="21" cy="21" r="20.6" fill="transparent"
                                            key={idx}
                                            stroke={
                                                 idx%2 ? '#e4002b' : '#0077c8'
                                            }
                                            strokeWidth={hoveredItem === idx
                                                ? '0.8'
                                                : hoveredItem === 13 ? '0.3' :'0.15'
                                            }
                                            strokeDasharray="18 136"
                                            strokeDashoffset={idx < 6
                                                ? 57-(((idx)*14.31))
                                                : idx === 11
                                                    ? 45-(((idx)*11.275))
                                                    : 45-(((idx+1)*11.075))}
                                    />
                                );
                            }
                            return (
                                <circle className="circle-piece" cx="21" cy="21" r="20.6" fill="transparent"
                                        key={idx}
                                        stroke={
                                            idx%2 ? '#e4002b' : '#0077c8'
                                        }
                                        strokeWidth={hoveredItem === idx
                                            ? '0.8'
                                            : hoveredItem === 13 ? '0.3' :'0.15'
                                        }
                                        strokeDasharray={idx === 4
                                            ? "7.55 124"
                                            : idx > 6
                                                ? '7.12 124'
                                                :"8 124"}
                                        strokeDashoffset={idx>6
                                            ? -(idx*7.12)-0.7
                                            : 25-idx*8}/>);
                    })}
                </svg>
                <div
                    className={classes.circleMenuClicker}
                    onClick={event => handleClickOnMenu(event)}
                    onMouseEnter={(event) =>
                        handleHighlightCirclePieceOnHover(event, 13)}
                    onMouseLeave={() => setHoveredItem(-1)}
                >
                </div>
            </div>
        </div>
    </div>);
}

export default CircularMenu;
