import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Button, IconButton, makeStyles, Tooltip} from '@material-ui/core';
import {AddRemoveIconButtons, ExpansionPanel, Loading} from '@cabinet-packages/components';
import {useDispatch, useSelector} from "react-redux";
import {getCatalog, getPunkts, setSelectedComplex} from "@cabinet-packages/redux";
import Typography from "@material-ui/core/Typography";
import {AddShoppingCart} from "@material-ui/icons";
import {isIncludedInComplexResearch} from "@cabinet-packages/helpers";
import {getAssayInfo} from "@cabinet-packages/api";
import PiggyBank from "medis/src/assets/images/catalogTableImages/piggy_bank.svg";
import {Helmet} from "react-helmet";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    padding: '10px',
    display: 'grid',
    minHeight: '100vh',
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: '1fr',
    gridGap: '10px',
  },
  containerInfoAssay: {
    background: 'rgba(0, 0, 0, 0.05)',
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 10px',
    '@media (max-width: 900px)': {
      flexDirection: 'column',
    }
  },
  priceTextWrapper: {
    color: 'rgba(0, 0, 0, 0.3)',
    display: 'flex',
    userSelect: 'none',
    justifyContent: 'space-between',
    verticalAlign: 'center',
    width: '30%',
    '@media (max-width: 900px)': {
      width: '100%',
    }
  },
  priceText: {
    lineHeight: '56px',
    color: theme.palette.error.main,
    fontWeight: 600
  },
  nameOfAssay: {
    alignItems: 'center',
    paddingRight: '8px',
    fontWeight: '600',
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '70%',
    '@media (max-width: 900px)': {
      width: '100%',
    }
  },
  details: {
    display : "block",
  },
  scrollWrapper: {
    position: 'relative',
    width: '100%',
  },
  scrollContainer: {
    position: 'absolute',
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    padding: '8px',
    listStylePosition: 'inside',
  },
  buttonForReturnWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  addRemoveButton: {
    padding: '8px',
  },
  accordionDetails: {
    display: 'block',
  },
  accordionDetailsHeader: {
    padding: '8px 8px 8px 0',
    color: theme.palette.primary.main,
  },
}));

function AssayInfo({isComponentInShop}) {
  const location = useLocation();
  const classes = useStyles();
  const {catalog, complexResearch, takeId} = useSelector(state => state.assaysCatalog);
  const { orderingList } = useSelector(state => state.orderAssays);
  const [item, setItem] = useState(null);
  const [assayInfo, setAssayInfo] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const hrefLocation = window.location.href;
  const insurance = process.env.REACT_APP_INSURANCE === 'true'
  const {punkts} = useSelector(store => store.collectionPoints);
  const isIncludedInOrderingList = item && orderingList.find(assay => assay.id === item.id && assay.type === item.type);
  const unavailablePunkts = item && item.filling && (item.filling.filter(fill => fill.unavailable && fill.unavailable.length > 0).length > 0)

  const tooltipTitle = (unavailablePunkts
      ? <h3 className={classes.accordionDetailsHeader} style={{color: 'red'}}>Дослідження тимчасово не виконується</h3>
      : '')
  const handleTransitionToComplexResearch = (event, item) => {
    event.stopPropagation();
    event.preventDefault();

    const foundedComplex = complexResearch.filter(com => com.filling.find(fill => !!item.filling.find(itemFill => itemFill.id === fill.id)));

    dispatch(setSelectedComplex({
      assayName: item.name
          ? item.name
          : item.testNames.split(',')[0].split('\\\\').join(''),
      foundedComplex
    }));

    isComponentInShop
        ? history.push('/shop-complex')
        : history.push('/comprehensive-research')
  };

  const handleTransitionToCatalog = (event) => {
    event.stopPropagation();
    event.preventDefault();
    isComponentInShop
        ? history.push('/shop')
        : takeId === 216 ? history.push('/catalog-table') : history.push('/catalog');
  };

  const handleAssayInfo = useCallback(async () => {
    if(!assayInfo && item) {
      setAssayInfo(await getAssayInfo(item.id));
    }
  }, [assayInfo, item]);

  useEffect(() => {
    if (!catalog && takeId) dispatch(getCatalog());

    if (catalog && complexResearch) {
      handleAssayInfo();
      const id = location.pathname.split('/');
      const item = insurance
          ?  [...catalog, ...complexResearch].find((item)=> +item.id === +id[id.length-1] && !item.superGroupName)
          : catalog.find((item)=> +item.id === +id[id.length-1] && !item.superGroupName)
      if (item) {
        setItem(item);
      } else {
        setItem(false)
      }
    }
  }, [catalog, complexResearch, dispatch, location, handleAssayInfo, takeId]);

  useEffect(() => {
    if (!punkts) dispatch(getPunkts());
  }, [dispatch, punkts]);

  if (!catalog || item === null) {
    return <Loading/>;
  }


  return (
      item !== false
          ? <div className={classes.root} key={item.id}>
            <div className={classes.containerInfoAssay}>
              <div className={classes.nameOfAssay}>
                {item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join('')}
              </div>
              <div className={classes.priceTextWrapper}>
                {isIncludedInComplexResearch(item, complexResearch)
                    ? <Tooltip title="Натисніть щоб переглянути комплекси з цим дослідженням." arrow>
                      <IconButton
                          size="small"
                          onClick={(event)=>handleTransitionToComplexResearch(event, item)}
                          className={classes.addRemoveButton}
                      >
                        {takeId === takeId
                            ? <img src={PiggyBank} style={{width: '40px'}} alt="Piggy bank."/>
                            :<AddShoppingCart style={{width: '40px'}}/>}
                      </IconButton>
                    </Tooltip>
                    : ' '
                }
                <div className={classes.priceText}>{item.discount
                    ? (item.price - (item.price*(item.discount/100))).toFixed(2)
                    : item.price} грн</div>
                <div className={classes.addRemoveButton}>
                  {item.idParent !== takeId && !!item.price && <AddRemoveIconButtons
                      item={item}
                      isIncludedInOrderingList={isIncludedInOrderingList}
                  />}
                </div>
              </div>
            </div>

            <div className={classes.scrollWrapper}>
              <div className={classes.scrollContainer}>
                {assayInfo && <ExpansionPanel title="Інформація про дослідження:" defaultExpanded={true} classNameDetails={classes.accordionDetails}>
                  {assayInfo && assayInfo.filling && [...(assayInfo.filling || []), ...(assayInfo?.idGrTest || [])].map((fil, i) => {
                    return (
                        <TableRow
                            key={i}
                            style={{background: isIncludedInOrderingList
                                  ? i%2 === 0 ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.2)'
                                  : takeId === takeId
                                      ? i%2 === 0 ? 'none': 'rgba(0, 0, 0, 0.05)'
                                      : 'none'}
                            }
                        >
                          <TableCell
                              className={classes.tableTd}
                              style={{cursor: 'pointer'}}
                          >
                            {fil.name}
                          </TableCell>
                          <TableCell className={classes.tableTd}/>
                          <TableCell className={classes.tableTd}/>
                        </TableRow>)
                  })}
                  {assayInfo.ua_definition && <div>
                    <h3 className={classes.accordionDetailsHeader}>Визначення:</h3>
                    <div className="content" dangerouslySetInnerHTML={{__html: assayInfo.ua_definition}}/>
                  </div>}
                  {item && item.test && !!item.test.length && <div>
                    <h3 className={classes.accordionDetailsHeader}>Дослідження:</h3>
                    {item.test.map((test, index) => <Typography key={index}>{test}</Typography>)}
                  </div>}
                  {assayInfo.ua_deviation && <div>
                    <h3 className={classes.accordionDetailsHeader}>Відхилення:</h3>
                    <div className="content" dangerouslySetInnerHTML={{ __html: assayInfo.ua_deviation}}/>
                  </div>}
                  {assayInfo.ua_recommendations && <div>
                    <h3 className={classes.accordionDetailsHeader}>Рекомендації:</h3>
                    <div className="content" dangerouslySetInnerHTML={{ __html: assayInfo.ua_recommendations}}/>
                  </div>}
                  {assayInfo.ua_synonyms && <div>
                    <h3 className={classes.accordionDetailsHeader}>Синоніми:</h3>
                    <div className="content" dangerouslySetInnerHTML={{ __html: assayInfo.ua_synonyms}}/>
                  </div>}
                  {assayInfo.ua_when && <div>
                    <h3 className={classes.accordionDetailsHeader}>Застосування:</h3>
                    <div className="content" dangerouslySetInnerHTML={{ __html: assayInfo.ua_when}}/>
                  </div>}
                  {assayInfo.ua_rules && <div>
                    <h3 className={classes.accordionDetailsHeader}>Правила підготовки:</h3>
                    <div className="content" dangerouslySetInnerHTML={{ __html: assayInfo.ua_rules}}/>
                  </div>}
                  {item && item.material && !!item.material.length && <div>
                    <h3 className={classes.accordionDetailsHeader}>Матеріал:</h3>
                    {item.material.map((material, index) => <Typography key={index}>{material}</Typography>)}
                  </div>}
                  {assayInfo.ua_punkt_restriction && <div>
                    <h3 className={classes.accordionDetailsHeader}>Обмеження по пунктах забору:</h3>
                    <div className="content" dangerouslySetInnerHTML={{ __html: assayInfo.ua_punkt_restriction}}/>
                  </div>}
                  {tooltipTitle}
                </ExpansionPanel>}
              </div>
            </div>
            <div className={classes.buttonForReturnWrapper}>
              <Button
                  onClick={handleTransitionToCatalog}
                  variant="outlined"
                  color="primary"
                  className={classes.buttonForReturn}
              >
                Назад до переліку досліджень
              </Button>
            </div>
            {item && item.testNames && <Helmet>
              <meta name="og:description" content={item.testNames}/>
              <meta property="og:url" content={hrefLocation}/>
              <meta name="og:image" content={'imgUrl'}/>
              <meta property="og:site_name" content={takeId === 216 ? 'Medis' : 'Esculab'}/>
              <meta property="og:type" content="article" />
              <meta property="og:title" content={item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join('')} />
            </Helmet>}
          </div>
          : <div>Вибачте, це дослідження не доступне в даний момент.</div>
  );
}

export default AssayInfo;
