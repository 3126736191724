import React, {createContext, useCallback, useEffect, useState} from "react";
import useStyles from './styles.js';
import {Button} from "@material-ui/core";
import moment from 'moment';
import 'moment/locale/uk';
import {useDispatch, useSelector} from "react-redux";
import {createTubesWizard, printOrderVials} from "@cabinet-packages/api/api";
import OrderVialsWithImages from "./components/OrderVialsWithImages";
import {Loading} from "@cabinet-packages/components";
import {setOrderVials} from "@cabinet-packages/redux/actions/actionsCreators";
import {FormattedMessage} from "react-intl";

moment.locale('uk');

export const OrderVialsContext = createContext(null);

const OrderVialsPage = () => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const {fullOrderData, vials, activeNurse, automaticPrintBarcodes, nurses, loading} = useSelector(state => state.calculator);

    const handleClickOnPrint = async (e, vialsArr) => {
        e.persist();

        await printOrderVials(vialsArr, {
            ...fullOrderData,
            dateSend: moment().format('kk:mm:ss'),
            dateToday: moment().format('YYYY-MM-DD'),
            nurse: nurses.find(item => +item.id === +activeNurse)
        });
    };

    const handleTubes = useCallback(async () => {
        setError(false);

        try {
            if (!vials) {
                const result = await createTubesWizard(fullOrderData);

                const orderVials = result.tubeInfo.map(item => ({
                    ...item,
                    selectedNurseId: activeNurse || activeNurse,
                    selectedDate: new Date()
                }))
                dispatch(setOrderVials(orderVials));
                if (automaticPrintBarcodes) {
                    handleClickOnPrint({
                        persist: () => {
                        }
                    }, orderVials);
                }
            }
        } catch (e) {
            console.error(e)
            setError(true);
        }
    }, [fullOrderData, vials]);

    useEffect(() => {
        handleTubes();
    }, [handleTubes]);




    return (<OrderVialsContext.Provider value={{vials, handleClickOnPrint}}>
        {loading && <Loading/>}
        {!loading && fullOrderData && <div className={cl.root}>
            <div className={cl.appBar}>
                <p className={cl.headingText}>
                    <FormattedMessage id="formatted.message.vials.vials.text"
                                      defaultMessage='Пробірки'/>
                </p>
                <div>
                    <p className={cl.orderDataText}><strong>
                        <FormattedMessage id="formatted.message.vials.orderId.text"
                                          defaultMessage='ID замовлення'/>:
                    </strong>{fullOrderData.id}</p>
                    <p className={cl.orderDataText}>
                        <strong> <FormattedMessage id="formatted.message.vials.patient.text"
                                                   defaultMessage='Пацієнт'/>: </strong>
                        {`${fullOrderData.patient.lastname} ${fullOrderData.patient.firstname} ${fullOrderData.patient.fathername}`}
                    </p>
                    <p className={cl.orderDataText}><strong>
                        <FormattedMessage id="formatted.message.vials.dateOfBirth.text"
                                          defaultMessage='Дата народження'/>:
                    </strong>{`${fullOrderData.patient.birthday.slice(0, 10)}`}</p>
                </div>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={!vials}
                    onClick={(e) => handleClickOnPrint(e, vials)}
                >
                    <FormattedMessage id="formatted.message.vials.printBarcodes.text"
                                      defaultMessage='Друкувати штрихкоди'/>
                </Button>
            </div>
            <OrderVialsWithImages/>
            {!vials && !error && <Loading/>}
            {vials && vials.length === 0 && <p className={cl.error}>
                <FormattedMessage id="formatted.message.vials.emptyTubes.text"
                                  defaultMessage='Пробірки відсутні'/>.
            </p>}
            {error && <p className={cl.error}>
                <FormattedMessage id="formatted.message.vials.errorHappend.text"
                                  defaultMessage='Вибачте, виникла помилка'/>.
            </p>}
        </div>}

    </OrderVialsContext.Provider>);
};

export default OrderVialsPage;
