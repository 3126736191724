export const createAssaysData = (ids, catalog, complexResearch, actions, activePatient, takeId) => {
    return ids.map(currId => (
            [...catalog, ...complexResearch, ...actions]
                .find(currAssay => +currAssay.id === +currId.id  && !currAssay.superGroupName)))
            .map(assay => {
                const notSuitable = false;
                // ((activePatient.sex === 'M' && assay.opt && !assay.opt.includes('forman'))
                //     || (activePatient.sex === 'F' && assay.opt && !assay.opt.includes('forwoman')))
                // && assay.idParent !== takeId;

                return ({
                    ...assay,
                    notSuitable
                });
            })

};
