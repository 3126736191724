import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {Buffer} from "buffer";
import pako from 'pako';

const useStyles = makeStyles(theme => ({
    root: {
        width: '99%',
        height: '99%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        '@media (max-width:600px)': {
            width: '100%',
        },
    },
    iframe: {
        width: '100%',
        height: '100%',
        minHeight: '100vh',
    },
    noData: {
        alignSelf: 'center',
        justifySelf: 'center',
    }
}));

const PortmonePaymentPage = ({isNewShop}) => {
    const classes = useStyles();
    const { activePatient } = useSelector(state => state.patients);
    const { totalPrice, orderId, time, timeLine, orderingList, amountOfItems, promoCode } = useSelector(state => state.orderAssays);
    const url = "https://www.portmone.com.ua/gateway/";
    const method = "post";
    const dispatch = useDispatch();
    const hrefLocation = window.location.origin;
    const [price, setPrice] = useState(false);
    const [takeTime, setTakeTime] = useState(false);
    const [isSafari, setIsSafari] = useState(/^((?!chrome|android).)*safari/i.test(navigator.userAgent))

    useEffect(() => {
        if (totalPrice && totalPrice !== 0 && !price) {
            setPrice(totalPrice);
            // dispatch(clearOrderedAssays());
        }
        if ((time || timeLine) && !takeTime) {
            const timeForSend = timeLine ? `${timeLine} 00:00:00.0000` : `${time}:00.0000`;
            setTakeTime(timeForSend);
        }
    }, [dispatch, totalPrice, price]);

    useEffect(() => {
        if (amountOfItems > 0) localStorage.setItem("basket", JSON.stringify({orderingList, amountOfItems, totalPrice, promoCode}));
    }, []);

    const data = {
        bodyRequest: {
            payee: {
                payeeId: 27734,
            },
            order: {
                description: "платіж new shop",
                shopOrderNumber: orderId,
                billAmount: price,
                billCurrency: "UAH",
                expTime: 900,
                successUrl: isNewShop
                    ? `${hrefLocation}/portmone-success?id=${orderId}`
                    : `${hrefLocation}/portmone-success?id=${orderId}&time=${takeTime}`,
                failureUrl: `${hrefLocation}/portmone-failure`,
            },
            paymentTypes: {
                card: "Y",
                portmone: "Y",
                applePay: "Y"
            },
            payer: {
                lang: "uk",
                emailAddress: activePatient ? activePatient.email : "",
            },
            style: {
                type: "light",
            },
        },
        typeRequest: "json",
    };

    function compressData(data) {
        const bytes = Buffer.from(data, 'utf8').toString()
        const gzip = pako.gzip(bytes);
        return Buffer.from(gzip).toString('base64');
    }

    useEffect(() => {
        if (data && isSafari) {
            const newJson = {
                "v": "2",
                "payeeId": "27734",
                "lang": "uk",
                "amount": price,
                "edit": "N",
                "description": `замовлення з shop ${orderId}`,
                "shopOrderNumber": orderId,
                "attribute2": activePatient ? `${activePatient.lastname} ${activePatient.firstname} ${activePatient.fathername}` : '',
                "attribute3": activePatient ? activePatient.id : '',
                "billNumber": orderId,
                "emailAddress": activePatient ? activePatient.email : "",
                "timeToLive": "10",
                "contractDate": moment().format('DD.MM.YYYY'),
                "limit": moment().add(28,'day').format('DD.MM.YYYY'),
                "successUrl": isNewShop
                    ? `${hrefLocation}/portmone-success?id=${orderId}`
                    : `${hrefLocation}/portmone-success?id=${orderId}&time=${takeTime}`,
            }
            const i = compressData(JSON.stringify(newJson))

            window.location.href = `https://www.portmone.com.ua/r3/uk/autoinsurance?i=${i}`
        }
    },[data, isSafari])

    const Form = React.memo(({ config, name }) => {
        const [ isSent, setIsSent ] = useState(false);

        const submitOnMount = useCallback((form) => {
            if (!form) {
                return;
            }

            form.submit();
            setIsSent(true);
        }, [ setIsSent ]);

        return (<>
                <iframe allowpaymentrequest={"true"} name={name} className={classes.iframe}/>
              {
                    !isSafari && !isSent && (<form target={name} action={config.url} method={config.method} ref={submitOnMount}>
                        {Object.keys(config.data).map((key) => (
                            <input
                                type={"hidden"}
                                name={key}
                                key={key}
                                value={
                                    ("string" === typeof config.data[ key ])
                                        ? config.data[ key ]
                                        : JSON.stringify(config.data[ key ])
                                }
                            />
                        ))}
                    </form>)
              }
            </>)
    });

    return (
        <div className={classes.root}>
            {activePatient && orderId && price && takeTime && !isSafari
                ? <Form name="paymentForm" config={{url, method, data}}/>
                : isSafari ?  <div className={classes.noData}>Loading...</div> : <div className={classes.noData}>Вам потрібно спочатку створити замовлення.</div>
            }
        </div>
    );
};

export default PortmonePaymentPage;
