import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  DialogContent: {
    height: "max-content",
    minHeight: "100%",
    maxWidth: "1180px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: theme.spacing(1),

    "@media (max-width: 900px)": {
      paddingTop: theme.spacing(2),
    },
  },
  rootWrapper: {
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "1056px",
    height: "90%",
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    "@media (max-width: 900px)": {
      flexDirection: "row",
    },
  },
  confirmPhone: {
    margin: "12px 0px",
  },
  innerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    margin: "0 auto",
    padding: "10px 0",
  },
  phoneConfirmation: {
    height: "max-content",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  phoneConfirmationInput: {
    maxWidth: "300px",
    width: "100%",
  },
  phoneConfirmationButton: {
    margin: "20px 0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  phoneCancellationButton: {
    marginTop: "4px",
  },
  errorCodeTitle: {
    marginBottom: "10px",
    color: "red",
  },
  resultTitleBlock: {
    display: "flex",
    paddingBottom: "10px",
  },
  resultTitleBlockTitle: {
    padding: "20px 0",

    "@media (max-width: 900px)": {
      display: "flex",
      alignItems: "center",
    },
  },
  wrapperCode: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(4),
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  phoneLabel: {
    fontSize: "12px",
    color: theme.palette.grey.text,
    margin: theme.spacing(1),
    width: "100%",
    textAlign: "left",
    maxWidth: "240px",
  },
  muiPhoneNumber: {
    width: "100%",
    "& fieldset": {
      borderWidth: "2px",
    },
  },
  phoneDescriptionWrapper: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(4.5),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    height: "max-content",

    "@media (max-width: 1180px)": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  phoneDescriptionText: {
    fontSize: "16px",
    color: theme.palette.grey.price,
  },
  phoneEnterForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    maxWidth: "500px",
    margin: "0 auto",
    padding: theme.spacing(7.5),
    paddingTop: theme.spacing(11),
    boxShadow: "0px 0px 10px -2px #363D4D",
    position: "relative",
    borderRadius: theme.spacing(1),
    minWidth: "460px",
    background: "white",

    "&::before": {
      height: "50px",
      content: '"ВВЕДІТЬ НОМЕР ТЕЛЕФОНУ"',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(7.5),
      backgroundColor: theme.palette.colors.background,
      color: "white",
      fontWeight: 600,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      borderRadius: theme.spacing(1),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",

      "@media (max-width: 1180px)": {
        height: "30px",
      },
    },

    "@media (max-width: 1180px)": {
      minWidth: "300px",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(6),
    },
  },
  codeEnterForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    maxWidth: "500px",
    margin: "0 auto",
    padding: theme.spacing(7.5),
    paddingTop: theme.spacing(11),
    boxShadow: "0px 0px 10px -2px #363D4D",
    position: "relative",
    borderRadius: theme.spacing(1),
    minWidth: "460px",
    background: "white",

    "&::before": {
      height: "50px",
      content: '"ВВЕДІТЬ ОТРИМАНИЙ КОД"',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(7.5),
      backgroundColor: theme.palette.colors.background,
      color: "white",
      fontWeight: 600,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      borderRadius: theme.spacing(1),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",

      "@media (max-width: 1180px)": {
        height: "30px",
      },
    },

    "@media (max-width: 1180px)": {
      minWidth: "300px",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(6),
    },
  },
  confirmTitle: {
    paddingTop: "20px",
    color: theme.palette.primary.dark,
    fontSize: "18px",
  },
  listItem: {
    background: "rgba(0, 0, 0, 0.05)",
  },
  phoneConfirmationCloseButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  createClientWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    maxWidth: "1180px",
    margin: "0 auto",
    padding: theme.spacing(7.5),
    paddingTop: theme.spacing(11),
    boxShadow: "0px 0px 10px -2px #363D4D",
    position: "relative",
    borderRadius: theme.spacing(1),
    minWidth: "900px",
    background: "white",

    "&::before": {
      height: "50px",
      content: '"ДАНІ ПАЦІЄНТА"',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(7.5),
      backgroundColor: theme.palette.colors.background,
      color: "white",
      fontWeight: 600,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      borderRadius: theme.spacing(1),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",

      "@media (max-width: 1180px)": {
        height: "30px",
      },
    },

    "@media (max-width: 1180px)": {
      maxWidth: "100vw",
      minWidth: "300px",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(6),
    },
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  textField: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  selectActivePatientWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "24px",
  },
  selectActivePatient: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    background: "rgba(0, 0, 0, 0.05)",
    marginBottom: "2px",
    padding: "5px",
  },
  selectActivePatientHeader: {
    padding: "4px",
    fontWeight: 500,
    color: theme.palette.primary.dark,
  },
  selectActivePatientText: {
    fontWeight: 500,
  },
  selectActivePatientButtonLink: {
    padding: "0",
  },
  selectActivePatientListWrapper: {
    listStyle: "none",
    width: "100%",
  },
  actionContainer: {
    margin: "10px",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 900px)": {
      margin: "5px",
    },
  },
  swipeStyledButton: {
    width: "215px",
    margin: "0 auto",
  },
  birthdayContainer: {
    padding: "4px 0",
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto 1fr",
    gridGap: "5px",
  },
  actionContainerButton: {
    maxHeight: "70px",
    "@media (max-width: 900px)": {
      margin: "5px",
    },
  },
  formAnnotation: {
    fontSize: "11px",
    color: theme.palette.secondary.light,
  },
  formCheckboxes: {
    height: "120px",
    padding: theme.spacing(1),
    display: "flex",
    "@media (max-width: 900px)": {
      height: "200px",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
  catalogSwitchControl: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: theme.spacing(2),
    alignItems: "center",

    "@media (max-width: 768px)": {
      padding: 0,
    },
    "@media (max-width: 900px)": {
      display: "none",
    },
  },
  catalogSwitchControlLabel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: theme.palette.grey.text,
    whiteSpace: "nowrap",
    fontWeight: 600,
  },
  newPatientWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    margin: "0 auto",
    padding: theme.spacing(5),
    paddingTop: theme.spacing(11),
    boxShadow: "0px 0px 10px -2px #363D4D",
    position: "relative",
    borderRadius: theme.spacing(1),
    background: "white",

    "&::before": {
      content: '"ВВЕДІТЬ ДАНІ ПАЦІЄНТА"',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      backgroundColor: theme.palette.colors.background,
      color: "white",
      fontWeight: 600,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      borderRadius: theme.spacing(1),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      height: "50px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  newPatientFieldsGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
  newPatientField: {
    paddingRight: theme.spacing(1),
    width: "50%",
  },
  newPatientBirthdayGender: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "50%",
  },
  newPatientBirthdayContainer: {
    paddingRight: theme.spacing(1),
    width: "100%",
    display: "grid",
  },
  newPatientFormComtrol: {
    width: "max-content",
    justifySelf: "center",
  },
  newPatientActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  error: {
    color: "red",
  },
  authorizeWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    maxWidth: "500px",
    margin: "0 auto",
    padding: theme.spacing(5),
    paddingTop: theme.spacing(6),
    boxShadow: "0px 0px 10px -2px #363D4D",
    position: "relative",
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(5),

    "&::before": {
      content: '"ВІТАЄМО!"',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      backgroundColor: theme.palette.colors.background,
      color: "white",
      fontWeight: 600,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      borderRadius: theme.spacing(1),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  avatarWrapper: {
    width: "300px",
    height: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
  },
  avatar: {
    width: "100px",
    height: "100px",
    background: theme.palette.primary.dark,
    color: theme.palette.secondary.main,
    fontSize: "32px",
    fontWeight: 600,
    marginTop: theme.spacing(4),
  },
  avatarText: {
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(4),
  },
  snackbar: {
    padding: 0,
    background: "transparent",
    borderRadius: "10px",
  },
  snackbarContentWrapper: {
    width: "340px",
    height: "170px",
    background: "white",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    boxShadow: "2px 0px 10px 0px #70707088",

    "@media (max-width: 1180px)": {
      width: "100%",
    },
  },
  snackbarContentHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    height: "50px",
    width: "100%",
    fontSize: "20px",
    fontWeight: 600,
    color: "white",
    background: theme.palette.colors.background,
    borderRadius: "10px 10px 0px 0px",
  },
  snackbarContentText: {
    width: "100%",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    marginTop: theme.spacing(2.5),
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 600,
    color: theme.palette.grey.price,
  },
}));

export default useStyles;
