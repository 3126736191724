import useFilter from './useFilter';

export default function useAssaysFilter(initialState, stopRerender) {
  return useFilter(
      initialState,
      (item, filterValue) => (item.name && item.name.toLowerCase().trim().includes(filterValue.toLowerCase()) && +item.idParent !== 0)
          || (item.ua_synonyms && item.ua_synonyms.toLowerCase().includes(filterValue.toLowerCase()))
          || (item.testNames && item.testNames.toLowerCase().includes(filterValue.toLowerCase()))
          || (item.assaysName && item.assaysName.toLowerCase().includes(filterValue.toLowerCase()))
          || (item.synonyms && item.synonyms.toLowerCase().includes(filterValue.toLowerCase()))
          || (item.code && item.code.toLowerCase().includes(filterValue.toLowerCase())),
      stopRerender
  );
}
