import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    checkbox: {
        '& span': {
            color: theme.palette.primary.main,
            fontSize: 14,
            paddingTop: 0,
            fontFamily: "'Roboto'",
        },
    },
    checkboxLabelWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
          marginLeft: 5,
        },
        // '& svg': {
        //     color: theme.palette.primary.main,
        // },
    },
    customerImg: {
        transform: 'rotate(180deg)',
    },

}))

export default useStyles