const rangeDelay = 20;

export const getTableHours = (from, to, minutesFrom, minutesTo) => {
    const sceduler = [];
        for (let i = from; i < to; i++) {
            for (let j = 0; j < 60 / rangeDelay; j++) {
                let startHour, startMinutes, endHour, endMinutes;
                startHour = +i < 10 ? `0${+i}` : i
                endHour = startHour
                startMinutes = j * rangeDelay
                endMinutes = (j + 1) * rangeDelay
                if ((+i===+from && +startMinutes < +minutesFrom)) {
                    continue
                }
                if (+j === 0) {
                    startMinutes = +i === +from ? minutesFrom : '00'
                } else if (+j === ((60 / rangeDelay) - 1)) {
                    endHour = +endHour < 10 ? `0${+endHour}` : endHour
                    startMinutes = ((60 / rangeDelay) - 1) * rangeDelay;
                    endMinutes = +i === +to ? minutesTo : '00'
                }

                let scedule = `${startHour}:${startMinutes}` //-${endHour}:${endMinutes}`
                sceduler.push(scedule)

            }
        }


    return sceduler;
}

export const getTableHoursVdoma = (from, to) => {
    const rangeDelay = 60;
    const sceduler = [];
    for(let i = from; i < to; i++) {
        for(let j = 0; j < 60/rangeDelay; j++) {
            let startHour, startMinutes, endHour, endMinutes;
            startHour = i < 10 ? `0${+i}` : i
            endHour = startHour
            startMinutes = j*rangeDelay
            endMinutes = (j+1)*rangeDelay
            if (+j === 0){
                startMinutes = '00'
            } else if (+j === ((60/rangeDelay)-1)) {
                endHour = ++endHour < 10 ? `0${+endHour}` : endHour
                startMinutes = ((60/rangeDelay)-1)*rangeDelay;
                endMinutes = '00'
            }
            let scedule = `${startHour}:${startMinutes}` //-${endHour}:${endMinutes}`
            sceduler.push(scedule)

        }
    }

    return sceduler;
}
