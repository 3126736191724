import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {Button, makeStyles, Tooltip, useMediaQuery} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import GroupIcon from '@material-ui/icons/Group';
import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {useDispatch, useSelector} from 'react-redux';
import {ExitToAppRounded} from "@material-ui/icons";
import {AccessKey, handleClickOnLogOut, isLogin} from "@cabinet-packages/helpers";
import {getCheckForAuthorized, logout} from "@cabinet-packages/api";
import {setClient, START_PHONE_SEND} from "@cabinet-packages/redux";
import {CustomDialog, PageTitle, TextAlertDialog} from "@cabinet-packages/components";
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  appBar: {
    height: 47,
    boxShadow: 'none',
    background: theme.palette.header.main,
    borderRadius: '0 0 25px 0',
    '@media (max-width:900px)': {
      borderRadius: '0 0 25px 25px',
    },
  },
  title: {
    flexGrow: 1,
    userSelect: 'none',
    display: 'none',
    color: '#66666',
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
    paddingLeft: 10,

    '@media (min-width:900px)': {
      display: 'block',
    },
  },
  rightMenuActionContainer: {
    display: 'flex',
  },
  toolBar: {
    height: 45,
    minHeight: 45,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  companyLogo: {
    fontSize: '30px',
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    marginRight: '20px',
    cursor: 'pointer',
  },
  backButton: {
    display: 'none',
    '@media (max-width:900px)': {
      display: 'block',
      fontSize: '.5rem',
    },
  },
  orderManagerBtn: {
    marginLeft: 10,
  },
  headerNameActivePatientWrapper: {
    color: theme.palette.primary.dark,
    fontSize: '12px',
    marginLeft: '8px',
    marginBottom: '8px',
    '@media (max-width:900px)': {
      fontSize: '10px',
      marginTop: '4px',
    },
  },
}));

export default function MenuAppBar({logo, themeMaterialUI, routes, isReturnToShop}) {
  const { user, orderAssays } = useSelector(state => state);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isLandPage = history.location.pathname === '/';
  const isDesktop = useMediaQuery('(min-width: 900px)');
  const { activePatient } = useSelector(state => state.patients);
  const { takeId } = useSelector(state => state.assaysCatalog);
  const [stateForUnauthorized, setStateForUnauthorized] = useState(true);
  const [historyPushWhenLogOut, setHistoryPushWhenLogOut] = useState(false);
  const hourNow = moment().hour();
  const isAgent = !!process.env.REACT_APP_API_PATH;
  const idDoctor = JSON.parse(localStorage.getItem('activePatient'));

  const handleBack = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if(!history.location.pathname.endsWith('/main-navigation')) {
      history.push('/main-navigation')
    } else {
      isReturnToShop
          ? !history.location.pathname.endsWith('/account-settings') && history.push('/account-settings')
          : history.push('/')
          // takeId === 14
          //   ? history.push('/')
          //   : window.location.href = 'https://esculab.com'
    }
  };

  const handleBackOnLogo = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if(!history.location.pathname.endsWith('/account-settings')) {
      history.push('/account-settings')
    } else {
      isReturnToShop
          ? !history.location.pathname.endsWith('/account-settings') && history.push('/')
          : history.push('/')
          // takeId === 14
          //   ? history.push('/')
          //   : window.location.href = 'https://esculab.com'
    }
  };

  const handleClickOnAccountSettings = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if(!history.location.pathname.endsWith('/account-settings')) history.push('/account-settings')
  }

  const handleClickOnBasket = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if(!history.location.pathname.endsWith('/order-manager')) history.push('/order-manager')
  };

  const handleLogOutWhenUnauthorized = async () => {
    if (!(history.location.pathname.endsWith('/account-settings')
        || history.location.pathname.endsWith('/patient-results')
        || history.location.pathname.endsWith('/patient-assays-history')
        || history.location.pathname.endsWith('patient-orders-history')
        || history.location.pathname.includes('/patient-card')
        || history.location.pathname.includes('/add-new-patient'))
    ) return;

    const result = await getCheckForAuthorized();

    if (result === 'Unauthorized' && AccessKey.get()) {
      CustomDialog(TextAlertDialog, {text: 'Ваша сесія не актуальна, будь ласка повторіть вхід.'}, false, themeMaterialUI)
      try {
        logout();
      } catch (e) {}
      localStorage.removeItem('activePatient');
      localStorage.removeItem('activePatientPhone');
      localStorage.removeItem('uuid');
      AccessKey.remove();
      window.location.reload();
      dispatch(setClient(null));
      dispatch({
        type: START_PHONE_SEND,
        payload: {
          stage : 1,
        },
      });
      history.push("/")
    }
  };

  useEffect(() => {
    if (stateForUnauthorized) {
      handleLogOutWhenUnauthorized();
      setStateForUnauthorized(false);
    }
    if (historyPushWhenLogOut) {
      setHistoryPushWhenLogOut(false)
      history.push('/')
    }
  });

  return (
    <AppBar position="fixed" className={classes.appBar} color="inherit">
      <Toolbar className={classes.toolBar}>
        {(isLandPage || isDesktop) ? (
          <img src={logo} alt="cabinet" height="40px" className={classes.companyLogo}
               onClick={(event) => handleBackOnLogo(event)}/>
        ) : (
          <Button variant="outlined" size="small" onClick={event => handleBack(event)}
                  className={classes.backButton}>
            {
              history.location.pathname.endsWith('/main-navigation')
                ? isReturnToShop ? 'повернутись до кабінету': 'мої результати'
                : 'повернутись до меню'
            }
          </Button>
        )}
        <Typography variant="h6" className={classes.title}>
          <PageTitle routes={routes}/>
        </Typography>
        {activePatient && !isAgent && isLogin() && <div className={classes.headerNameActivePatientWrapper}>
          {hourNow > 17 ? 'Доброго вечора' : hourNow > 11 ? 'Доброго дня' : hourNow > 5 ? 'Доброго ранку' : 'Доброї ночі'}, <br/> <strong>{activePatient.firstname && activePatient.firstname} {activePatient.fathername && activePatient.fathername}</strong>
        </div>}

        <div className={classes.rightMenuActionContainer}>
          {(idDoctor && !idDoctor.iddoctor) && !isAgent && <Tooltip title="Натисніть щоб перейти до списку пацієнтів." arrow>
            <IconButton onClick={(event) => handleClickOnAccountSettings(event)}>
              <GroupIcon htmlColor="#5f6368"/>
            </IconButton>
          </Tooltip>}
          {/*<IconButton onClick={() => {if(!history.location.pathname.endsWith('/notification-manager')) history.push('/notification-manager')}}>*/}
          {/*  <Badge badgeContent={amountOfMessages} color="secondary">*/}
          {/*    <NotificationsIcon htmlColor="#5f6368"/>*/}
          {/*  </Badge>*/}
          {/*</IconButton>*/}
          {orderAssays.amountOfItems > 0 &&
          <Tooltip title="Натисніть щоб перейти до корзини." arrow>
            <IconButton
                className={classes.orderManagerBtn}
                size="small"
                onClick={(event) => handleClickOnBasket(event)}
            >
              <Badge badgeContent={orderAssays.amountOfItems} color="secondary">
                <ShoppingCartIcon htmlColor="#5f6368"/>
              </Badge>
            </IconButton>
          </Tooltip>
          }
          <Tooltip title="Натисніть щоб здійснити ВИХІД з особистого кабінету." arrow>
            <IconButton onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              handleClickOnLogOut(event, history, dispatch, themeMaterialUI, setHistoryPushWhenLogOut);
            }}>
              <ExitToAppRounded/>
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>
    </AppBar>
  );
}
