import React from 'react';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles({
  scrollWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
  },
  scrollContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    scrollbarWidth: 'none',
    padding: '16px',
  },
});

export default function Scroll({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.scrollWrapper}>
      <div className={classes.scrollContainer}>{children}</div>
    </div>
  );
}
