export const getCorrectDay = (curDate) => {
    const date = curDate.split('.');
    let day = new Date([date[1], date[0], date[2]].join('.')).getDay();

    if (day === 0) {
        day = 6;
    } else {
        day -= 1;
    }

    return day;
};
