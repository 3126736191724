import React, {useRef} from 'react';
import {IconButton, makeStyles} from '@material-ui/core';
import {AutoSizer, List} from 'react-virtualized';
import {isIncludedInOrderingList} from '@cabinet-packages/helpers';
import {useSelector} from 'react-redux';
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AddRemoveButton from "../../../ProductCatalogEsculab/helpers/AddRemoveButton";
import getAmountOfLines from "@cabinet-packages/helpers/getAmountOfLines";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '20%',
        maxWidth: '900px',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '70px 1fr',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        transition: 'width 0.3s',

        '@media (max-width: 1180px)': {
            gridTemplateRows: '70px 1fr',
            width: '100%',
            height: '50vh'
        },
    },
    rootToggle: {
        height: '100%',
        width: '5%',
        maxWidth: '900px',
        display: 'grid',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        transition: 'width 0.3s',

        '@media (max-width: 1180px)': {
            width: '100%',
        },
    },
    textFieldContainer: {
        margin: '10px',
        display: 'flex',
        flexDirection: 'row'
    },
    list: {
        '&:focus': {
            outline: 'none',
        },
    },
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        padding: '10px',
    },
    listItemTitle: {
        fontSize: '14px',
        letterSpacing: '1px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    listItemBottom: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '40px',
        padding: '12px 0',
    },
    listItemPrice: {
        color: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        userSelect: 'none',
    },
    bottomContainer: {
        background: 'white',
        borderTop: '2px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    totalPriceContainer: {
        padding: '5px',
        fontSize: '18px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between',

        '@media (max-width: 1180px)': {
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            marginBottom: '5px',
        },
    },
    noAssays: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    actionButton: {
        margin: '10px',
        '@media (max-width: 1180px)': {
            margin: '5px 5px 0 5px',
        },
    },
}));

function getAssayRowHeight(index, width, filteredState) {
    const name = filteredState[index].name;
    width = width - 35;
    const symbolWidth = 7;
    const lineHeight = 19;
    let bottomLineHeight = 74;
    if (filteredState[index].sitename) bottomLineHeight = 20;
    return getAmountOfLines(width, name, symbolWidth) * lineHeight + bottomLineHeight;
}

export default function OrderManager({data}) {
    const classes = useStyles();
    const {toggle, setToggle} = data;
    const {catalog, takeId}  = useSelector(state => state.assaysCatalog);
    const { orderingList, totalPrice, amountOfItems, fullOrderData } = useSelector(state => state.calculator);

    const listRef = useRef();

    const handleToggle = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setToggle(prev => !prev);
    }

    function rowRenderer(props) {
        const { key, index, style } = props;
        const item = orderingList[index];

        const newStyles = {
            ...style,
            borderBottom: '1px solid' +
                ' rgba(0, 0, 0, 0.1)',
        };
        return (
            <div key={key} style={newStyles} className={classes.listItem}>
                {item && <div className={classes.listItemTitle}>
                    {item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join('')}
                    {' '}
                    {item.type === 'action' && catalog.find(assay => assay.id === item.idGrTest)
                        ? catalog.find(assay => assay.id === item.idGrTest).name
                            ? catalog.find(assay => assay.id === item.idGrTest).name
                            : catalog.find(assay => assay.id === item.idGrTest).testNames.split(',')[0].split('\\\\').join('')
                        : ''}
                </div>}
                {item && <div className={classes.listItemBottom}>
                    <div className={classes.listItemPrice}>
                        {item.price} грн
                    </div>
                    {+item.idParent !== takeId && !(fullOrderData && fullOrderData.order) && <AddRemoveButton item={item} isIncludedInOrderingList={isIncludedInOrderingList(item, orderingList)}/>}
                </div>}
            </div>
        );
    }

    return (
        <div className={toggle ? classes.rootToggle : classes.root}>
            <div className={classes.textFieldContainer} style={toggle ? {margin: 0} : {}}>
                <Typography variant="h6" color="primary">
                    {!toggle && 'Корзина:'}
                    <IconButton color="primary" onClick={handleToggle}>
                        {toggle ? <ArrowBackIcon/> : <ArrowForwardIcon/>}
                    </IconButton>
                </Typography>
            </div>
            {(amountOfItems > 0) && !toggle
                ? (<div style={{
                        height: '100%',
                        width: '100%',
                        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                    }}>
                        {orderingList && <AutoSizer>
                            {({height, width}) => (
                                <List
                                    className={classes.list}
                                    width={width}
                                    height={height}
                                    ref={listRef}
                                    rowCount={orderingList.length}
                                    rowHeight={({index}) => getAssayRowHeight(index, width, orderingList)}
                                    rowRenderer={rowRenderer}
                                />
                            )}
                        </AutoSizer>}
                    </div>)
                : (<div className={classes.noAssays}>
                </div>)}
            <div className={classes.bottomContainer}>
                {!toggle && <div className={classes.totalPriceContainer}>
                    <FormattedMessage id="formatted.message.orderManager.fullPrice.text"
                                      defaultMessage='Повна вартість'/>:
                    <strong>{totalPrice}</strong>
                    <FormattedMessage id="formatted.message.currency.text"
                                      defaultMessage='грн'/>
                </div>}
            </div>
        </div>
    );
};
