import React, {useEffect, useRef, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Menu, MenuItem, MuiThemeProvider,} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {clearOrderedAssays, getCatalog, getClientLocation, setRegionConfirmation} from "@cabinet-packages/redux";
import medisLogoWithoutText from '../../assets/medis/medisLogoWithoutText.png';
import LocationSVG from "../../assets/LocationSVG";

const useStyles = makeStyles((theme) => ({
    dialog: {
        margin: 0,
        paddingTop: 0,
        minWidth: '300px',
        minHeight: '200px',
        width: '100%',
        height: '100%',
    },
    dialogContent: {
        width: '100%',
        height: '100%',
        minWidth: '300px',
        minHeight: '200px',
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',

        '@media (max-width: 900px)': {
            maxWidth: '250px',
            minWidth: '249px',
            minHeight: '250px',
        },
    },
    contentWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    image: {
        width: '50px',
    },
    regionHeader: {
        fontSize: '1.17rem',
        color: 'rgba(0, 0, 0, 0.4)',
    },
    regionName: {
        fontSize: '1.17rem',
        color: 'rgba(0, 0, 0, 0.7)',
    },
    selectedRegionWrapper: {
        maxWidth: '160px',
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.3)',
        height: '2rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transition: '.5s',

        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            transition: '.5s',
        },
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignSelf: 'flex-end',

        '@media (max-width: 900px)': {
            flexDirection: 'column',
        },
    },
    confirmButton: {
        '@media (max-width: 900px)': {
            marginTop: '8px',
        },
    },
}));

const ConfirmRegionDialog = ({ themeMaterialUI, handleClose }) => {
    const classes = useStyles();
    const {regions, clientLocation} = useSelector(store => store.collectionPoints);
    const dispatch = useDispatch();
    const idReg = localStorage.getItem('idReg');
    const ref = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenMenu = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(ref.current)
    };

    const handleCloseMenu = (event, backdrop, region) => {
        event.stopPropagation();
        event.preventDefault();

        setAnchorEl(null);

        if (!!region) {
            localStorage.setItem('idReg', region.idReg);
            dispatch(getCatalog(true));
            dispatch(setRegionConfirmation(true));
            handleClose();
            dispatch(clearOrderedAssays());
        }
    };

    useEffect(() => {
        if (!clientLocation || (clientLocation && (!clientLocation.lat || !clientLocation.lng))) {
            dispatch(getClientLocation());
        }
    }, [dispatch]);

    return (<MuiThemeProvider theme={themeMaterialUI}>
        <Dialog open={true} className={classes.dialog} scroll="body">
            <DialogContent className={classes.dialogContent}>
                <div className={classes.contentWrapper}>
                    <img
                        src={medisLogoWithoutText}
                        alt="Medis logo."
                        className={classes.image}
                    />
                    <p className={classes.regionHeader}>Ви обрали місто:</p>
                    <div
                        className={classes.selectedRegionWrapper}
                        onClick={handleOpenMenu}
                        ref={ref}
                    >
                        {regions && idReg && <p className={classes.regionName}>м.{regions.find(region => +region.idReg === +idReg)?.name}</p>}
                        <LocationSVG />
                    </div>
                    <Menu
                        id="simple-menu"
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                    >
                        {regions && regions.map(region => {
                            return (<MenuItem key={region.id + ' ' + region.lat} onClick={e => handleCloseMenu(e, false, region)}>м.{region.name}</MenuItem>);
                        })}
                    </Menu>
                </div>
                <div className={classes.actionContainer}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleOpenMenu}
                    >
                        змінити
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.confirmButton}
                        onClick={(e) => {
                            handleClose(e);
                            dispatch(setRegionConfirmation(true));
                        }}
                    >
                        підтвердити
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    </MuiThemeProvider>);
};

export default ConfirmRegionDialog;
