import {
    SET_CATALOG,
    SET_CATALOG_FILTERED_VALUE,
    SET_COMPLEX_ID,
    SET_SELECTED_ACTION,
    SET_SELECTED_AGENTS,
    SET_SELECTED_TAGS,
    SET_TAGS,
    SET_TAKE_ID,
    SET_TOGGLE_CHECKED,
    SET_TOGGLE_COMPLEX_TRANSITION
} from "../actions/actionsTypes";

export const assayCatalogOldInitialState = {
    catalog: null,
    complexResearch: null,
    actions: null,
    selectedActionForView: null,
    toggleChecked: false,
    complexId: null,
    takeId: null,
    filteredValue: null,
    tags: null,
    selectedTags: [],
    complexTransition: false,
    selectedAgent: null,
};

export default function assaysCatalogReducer(state = assayCatalogOldInitialState, { type, payload }) {
    switch (type) {
        case SET_CATALOG:
            return {
                ...state,
                catalog: payload.catalog,
                complexResearch: payload.complex,
                actions: payload.actions,
            };
        case SET_COMPLEX_ID:
            return {
                ...state,
                complexId: payload.complexId,
            };
        case SET_TAKE_ID:
            return {
                ...state,
                takeId: payload.takeId,
            };
        case SET_TOGGLE_CHECKED:
            return {
                ...state,
                toggleChecked: payload.toggleChecked
            };
        case SET_SELECTED_ACTION:
            return {
                ...state,
                selectedActionForView: payload.selectedAction,
            };
        case SET_CATALOG_FILTERED_VALUE:
            return {
                ...state,
                filteredValue: payload.value,
            };
        case SET_TAGS:
            return {
                ...state,
                tags: payload,
            };
        case SET_SELECTED_TAGS:
            return {
                ...state,
                selectedTags: payload,
            };
        case SET_TOGGLE_COMPLEX_TRANSITION:
            return {
                ...state,
                complexTransition: !state.complexTransition,
            };
        case  SET_SELECTED_AGENTS:
            return {
                ...state,
                selectedAgent: payload,
            };
        default:
            return state;
    }
};
