import React from 'react';
import {Route, Switch} from 'react-router-dom';

export default function PageTitle(props) {
    const { routes } = props;
  return (
    <span {...props}>
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            children={route.label}
          />
        ))}
      </Switch>
    </span>
  );
}
