import React, {useEffect, useState} from "react";
import useStyles from "../styles";
import TextField from "@material-ui/core/TextField";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import uaLocale from 'date-fns/locale/uk';
import DateFnsUtils from "@date-io/date-fns";
import {
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Radio,
    RadioGroup,
    Slide,
    Snackbar
} from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import Pdf from "../pdf/Договір публічної оферти_МеДіС.pdf";
import {useDispatch, useSelector} from "react-redux";
import {accept, authorise, getDoctor, getPatients, setCreateClient, setRegisterChild} from "@cabinet-packages/api";
import {AccessKey} from "@cabinet-packages/helpers";
import {setClient, setListOfPatients, START_PHONE_SEND} from "@cabinet-packages/redux";
import moment from "moment";
import {setExistingPatients} from "@cabinet-packages/api/api";
import {SwipeStyledButton} from "@cabinet-packages/components";
import {useHistory} from "react-router";

function TransitionUp(props) {
    return <Slide {...props} direction="up"/>;
}

const NewPatientInOneStep = ({
                                 state,
                                 updateState,
                                 usePhone,
                                 isChangedStateSignUp,
                                 handleCheckboxState,
                                 uuid,
                                 disableConfirmSMS,
                                 handleValue,
                                 isDoctor,
                                 handleClose,
                                 isChangedState
                             }) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const history = useHistory()
    const {takeId} = useSelector(state => state.assaysCatalog);
    const {accessKeyForLogin} = useSelector(state => state.patients)
    const {isAdmin} = useSelector(state => state.cabinet)
    const [phone, setPhone] = usePhone;
    const [checkboxState, setCheckboxState] = handleCheckboxState;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [stage, setStage] = useState(0);
    const [value, setValue] = handleValue;
    const [open, setOpen] = useState(false);
    const [snackBarText, setSnackbarText] = useState('');

    useEffect(() => {
        if (isAdmin) {
            localStorage.setItem("uuid", `${uuid}`)
        }
    }, [])

    useEffect(() => {
        if (localStorage.getItem("isAdmin") && !AccessKey.get() && !isAdmin && !accessKeyForLogin) {
            localStorage.removeItem("isAdmin")
        }
    }, [])

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setSnackbarText('');
    };

    const handleChange = (event) => {
        setCheckboxState({...checkboxState, [event.target.name]: event.target.checked});
    };

    const handleSignUp = async (e) => {
        e.persist();
        e.stopPropagation();
        e.preventDefault();

        if(phone.length !== 19){
            setOpen(true);
            setSnackbarText('Перевірте введений номер телефону');
            return;
        }

        if (!(isChangedStateSignUp || !checkboxState.checkedA || !checkboxState.checkedB)) {
            setLoading(true);
            setError(false);

            try {
                const result = await authorise(uuid, phone);

                if (result) {
                    setStage(1);
                }
            } catch (e) {
                console.error(e);
                setError('Вибачте, сталася помилка при надсиланні номеру.');
                setOpen(true);
                setSnackbarText('Вибачте, сталася помилка при надсиланні номеру.');
            }

            setLoading(false);
        } else {
            setOpen(true);
            setSnackbarText('Введіть ваші дані та підтвердьте згоду на використання персональних даних щоб продовжити реєстрацію.');
        }
    };

    const goBack = () => {
        setStage(0);
        setValue('');
    };

    const handleConfirmSMS = async () => {

        if (!disableConfirmSMS) {
            setLoading(true);
            localStorage.setItem("activePatientPhone", phone.replace(/[[()\s]/g, ''));

            try {
                const result = await accept(uuid, value)

                if ((result.data && result.data.token)) {
                    AccessKey.set(result.data.token);

                    if (result.data.exist) {
                        const patients = isDoctor
                            ? await getDoctor()
                            : await getPatients()
                        // : await checkPatient('CHECK', uuid, null, phone, result.data.token);

                        dispatch(setListOfPatients(false, isDoctor, patients));

                        const isUserChooseMainPatient = patients.reduce((acu, cur) => {
                            if (cur.main) return cur.main;

                            return acu;
                        }, false);

                        if (isUserChooseMainPatient) {
                            const patients = isDoctor
                                ? await getDoctor()
                                : await getPatients()
                            //: await checkPatient('CHECK', uuid, null, fuckingPhone, result.data.token);
                            const {lastname, firstname, fathername, birthday, sex, phone, email} = state;
                            const birthdayDate = typeof birthday === 'string' ? birthday : moment(birthday).format('YYYY-MM-DD');
                            const mainPatient = patients.find(patient => patient.main === true);
                            await setRegisterChild(
                                mainPatient.id,
                                lastname,
                                firstname,
                                fathername,
                                birthdayDate,
                                sex,
                                email
                            );

                            const patientsSecond = isDoctor
                                ? await getDoctor()
                                : await getPatients()

                            dispatch(setListOfPatients(true, isDoctor, patientsSecond));
                            dispatch(setClient(mainPatient));
                        } else {
                            const patients = isDoctor
                                ? await getDoctor()
                                : await getPatients()

                            await setExistingPatients(
                                patients[0].id,
                                patients.map(patient => patient.id).filter(id => +id !== +patients[0].id),
                                phone.replace(/[[()\s]/g, ''),
                                checkboxState.checkedA,
                                checkboxState.checkedB,
                                false,
                                ''
                            );

                            dispatch(setListOfPatients(false, isDoctor, patients));
                        }

                    } else { // not exist
                        const {lastname, firstname, fathername, birthday, sex, email} = state;
                        const birthdayDate = typeof birthday === 'string' ? birthday : moment(birthday).format('YYYY-MM-DD');
                        const result = await setCreateClient(uuid, lastname.trim(), firstname.trim(), fathername.trim(), birthdayDate, sex, phone.replace(/[[()\s]/g, ''), email);

                        AccessKey.set(result.token);

                        const patients = isDoctor
                            ? await getDoctor()
                            : await getPatients()

                        const newToken = await setExistingPatients(
                            patients[0].id,
                            patients.map(patient => patient.id).filter(id => +id !== +patients[0].id),
                            phone.replace(/[[()\s]/g, ''),
                            checkboxState.checkedA,
                            checkboxState.checkedB,
                            false,
                            ''
                        );

                        AccessKey.set(newToken.token)

                        const finalPatients = isDoctor
                            ? await getDoctor()
                            : await getPatients()

                        dispatch(setListOfPatients(true, isDoctor, finalPatients));
                        dispatch(setClient(patients[0]));

                    }
                }
            } catch (e) {
                setError('Вибачте, сталась помилка при реєстрації.')
                setOpen(true);
                setSnackbarText('Вибачте, сталась помилка при реєстрації. Невірно введений код.');
            }

            setLoading(false);
        } else {
            setOpen(true);
            setSnackbarText('Введіть чотирьохзначний код, що прийшов у повідомленні.')
        }
    };

    const onSendPhone = async (uuidPhone, phone) => {
        dispatch({
            type: START_PHONE_SEND,
            payload: {
                stage : 2,
                error : null,
            },
        });

        const result = await authorise(uuid, phone).catch(onerror => {
            if (onerror) {
                dispatch({
                    type: START_PHONE_SEND,
                    payload: {
                        stage: 3,
                        error: 'errorWhenSendPhone',
                    },
                });
            }
        });

        if (result) {
            dispatch({
                type: START_PHONE_SEND,
                payload: {
                    stage: 3,
                },
            });
        }
    };

    const handlePressEnterCode = (e) => {
        if (e.key === 'Enter' && !isChangedState) {
            handleConfirmSMS(e);
        }
    };

    const handleConfirm = (e, formData) => {
        if (!isChangedState) {

            onSendPhone(uuid, formData);
            setValue('');
        } else {
            setOpen(true);
            setSnackbarText('Введіть номер телефону, щоб отримати код.');
        }
    };

    return (<>
        {stage === 0 && state && <div className={cl.newPatientWrapper}>
            <div className={cl.newPatientFieldsGroup}>
                <TextField
                    variant="outlined"
                    autoFocus={true}
                    className={cl.newPatientField}
                    value={state.firstname}
                    onChange={(event) => updateState('firstname', event.target.value)}
                    label="Ім'я"
                />
                <TextField
                    variant="outlined"
                    className={cl.newPatientField}
                    value={state.lastname}
                    onChange={(event) => updateState('lastname', event.target.value)}
                    label="Прізвище"
                />
            </div>
            <div className={cl.newPatientFieldsGroup}>
                <TextField
                    variant="outlined"
                    className={cl.newPatientField}
                    value={state.fathername}
                    onChange={(event) => updateState('fathername', event.target.value)}
                    label="По-батькові"
                />
                <div className={cl.newPatientBirthdayGender}>
                    <div className={cl.newPatientBirthdayContainer}>
                        <p style={{height: '10px', color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px'}}>Дата народження*</p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={uaLocale}>
                            <KeyboardDatePicker
                                value={state.birthday}
                                onChange={(value) => {
                                    updateState('birthday', value)
                                }}
                                invalidDateMessage="Неправильний формат дати."
                                maxDateMessage="Дата народження не може бути в майбутньому."
                                format="yyyy-MM-dd"
                                disableFuture
                                cancelLabel="Назад"
                                okLabel="Ок"
                                openTo="year"
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <FormControl component="fieldset" className={cl.newPatientFormComtrol}>
                        <FormLabel component="legend"
                                   style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px'}}>Стать</FormLabel>
                        <RadioGroup
                            row
                            value={state.sex}
                            onChange={(event) => updateState('sex', event.target.value)}
                            className={cl.genderGroup}>
                            <FormControlLabel
                                value="F"
                                control={<Radio color="primary"/>}
                                label="Ж"
                            />
                            <FormControlLabel
                                value="M"
                                control={<Radio color="primary"/>}
                                label="Ч"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            <div className={cl.newPatientFieldsGroup}>
                <TextField
                    variant="outlined"
                    className={cl.newPatientField}
                    value={state.email}
                    onChange={(event) => updateState('email', event.target.value)}
                    label="Електронна пошта*"
                    type="email"
                />
                <MuiPhoneNumber
                    variant="outlined"
                    className={cl.newPatientField}
                    defaultCountry='ua'
                    countryCodeEditable={false}
                    regions='europe'
                    label="Номер телефону*"
                    value={phone}
                    onChange={(value) => setPhone(value)}
                />
            </div>
            <FormGroup className={cl.formCheckboxes}>
                <FormControlLabel
                    control={<Checkbox
                        checked={checkboxState.checkedA}
                        onChange={handleChange}
                        name="checkedA"
                        color="primary"
                    />}
                    label={<p>
                        Я погоджуюсь на використання <a href={takeId === 14 ? Pdf : undefined} target="_blank">
                        персональних даних.
                    </a>
                    </p>}
                />
                <FormControlLabel
                    control={<Checkbox
                        checked={checkboxState.checkedB}
                        onChange={handleChange}
                        name="checkedB"
                        color="primary"
                    />}
                    label="Я погоджуюсь на створення персонального кабінету."
                />
            </FormGroup>
            <div className={cl.newPatientActions}>
                <SwipeStyledButton
                    className={cl.swipeStyledButton}
                    animationStart={'left'}
                    // animationMaterialColor={teal[500]}
                    // buttonMaterialColor={amber[500]}
                    onClick={handleSignUp}
                >
                    Зареєструватись
                </SwipeStyledButton>
                {loading && <CircularProgress size={24} className={cl.buttonProgress}/>}
            </div>
            {error && <p className={cl.error}>{error}</p>}
        </div>}
        {stage === 1 && <div className={cl.codeEnterForm}>
            <div className={cl.wrapperCode}>
                <p className={cl.phoneLabel}>Номер телефону</p>
                <MuiPhoneNumber
                    className={cl.muiPhoneNumber}
                    defaultCountry='ua'
                    countryCodeEditable={false}
                    regions='europe'
                    value={phone}
                    onChange={(value) => setPhone(value)}
                    variant="outlined"
                />
                <p className={cl.phoneLabel}>Код</p>
                <TextField
                    label="Ведіть код"
                    variant="outlined"
                    value={value}
                    autoFocus={true}
                    inputProps={{
                        maxLength: 4,
                    }}
                    onKeyPress={handlePressEnterCode}
                    onChange={e => setValue(e.target.value.replace(/[^0-9]/g, ''))}
                />
            </div>
            <SwipeStyledButton
                variant="contained"
                color="primary"
                onClick={handleConfirmSMS}
                className={cl.swipeStyledButton}
                animationStart={'left'}
            >
                підтвердити код
                {loading && <CircularProgress size={24} className={cl.buttonProgress}/>}
            </SwipeStyledButton>
            <div className={cl.phoneDescriptionWrapper}>
                <p className={cl.phoneDescriptionText}>
                    Верифікаційний код буде надісланий Вам у вигляді SMS повідомлення або у Viber
                </p>
            </div>
            <SwipeStyledButton
                color="secondary"
                onClick={goBack}
                className={cl.swipeStyledButton}
                variant="outlined"
                animationStart={'right'}
                animationMaterialColor={'#E41E15'}
                buttonMaterialColor={'#0E4E4E'}
            >
                Повернутися назад
            </SwipeStyledButton>
        </div>}
        {stage === 2 && <div>
            новий пацієнт успішно створений
        </div>}
        <Snackbar
            ContentProps={{
                classes: {
                    root: cl.snackbar, message: cl.snackbar
                }
            }}
            TransitionComponent={TransitionUp}
            open={open}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            message={<div className={cl.snackbarContentWrapper}>
                <div className={cl.snackbarContentHeader}>
                    Увага!
                </div>
                <div className={cl.snackbarContentText}>
                    {snackBarText && snackBarText}
                </div>
            </div>}
        >
        </Snackbar>
    </>)
}

export default NewPatientInOneStep;
