import {SET_BARCODE, SET_TENTS} from '../actions/actionsTypes';

export const tentReducerInitialState = {
    tents: null,
    barCode: null,
};

export default function tentReducer(state = tentReducerInitialState, {type, payload}) {
    switch (type) {
        case SET_TENTS:
            return {
                ...state,
                tents: payload,
            };
        case SET_BARCODE:
            return {
                ...state,
                barCode: payload,
            };
        default:
            return state;
    }
};