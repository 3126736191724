export const SELECT_ACTIVE_PATIENT = 'SELECT_ACTIVE_PATIENT';
export const SELECT_ACTIVE_PATIENT_RESULTS = 'SELECT_ACTIVE_PATIENT_RESULTS';
export const SELECT_ACTIVE_PATIENT_ORDERS = 'SELECT_ACTIVE_PATIENT_ORDERS';
export const SET_LIST_OF_PATIENTS = 'SET_LIST_OF_PATIENTS';
export const ADD_NEW_LIST_OF_PATIENTS = 'ADD_NEW_LIST_OF_PATIENTS';
export const UPDATE_ACTIVE_PATIENT = 'UPDATE_ACTIVE_PATIENT';

export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION';

export const ADD_ASSAY_TO_ORDER_LIST = 'ADD_ASSAY_TO_ORDER_LIST';
export const REMOVE_ASSAY_FROM_ORDER_LIST = 'REMOVE_ASSAY_FROM_ORDER_LIST';
export const CLEAR_ORDERED_ASSAYS = 'CLEAR_ORDERED_ASSAYS';

export const SET_COLLECTION_POINTS = 'SET_COLLECTION_POINTS';
export const ADD_SELECTED_PUNKT = 'ADD_SELECTED_PUNKT';
export const UPDATE_ACTIVE_ORDER_PUNKT = 'UPDATE_ACTIVE_ORDER_PUNKT'
export const UPDATE_ACTIVE_ORDER_DATE = 'UPDATE_ACTIVE_ORDER_DATE'

export const SET_CATALOG = 'SET_CATALOG';
export const SET_COMPLEXES = 'SET_COMPLEXES';
export const SET_ACTIONS = 'SET_ACTIONS';
export const SET_TOGGLE_CHECKED = 'SET_TOGGLE_CHECKED';
export const SET_CLIENT_LOCATION = 'SET_CLIENT_LOCATION';
export const SET_SCHEDULE = 'SET_SCHEDULE';
export const ADD_ASSAY_TIME = 'ADD_ASSAY_TIME';
export const ADD_ASSAY_TIME_LINE = 'ADD_ASSAY_TIME_LINE';
export const ADD_ORDER_NOTE = 'ADD_ORDER_NOTE';
export const CATALOG_TABLE_ID = 'CATALOG_TABLE_ID';
export const SET_TURN_BACK = 'SET_TURN_BACK';
export const SET_CREATE_PATIENT = 'SET_CREATE_PATIENT';
export const SET_COMPLEX_RESEARCH_TRANSITION = 'SET_COMPLEX_RESEARCH_TRANSITION';
export const SET_SELECTED_ACTION = 'SET_SELECTED_ACTION';
export const START_PHONE_SEND = 'START_PHONE_SEND';

export const SET_ACCESS_KEY = 'SET_ACCESS_KEY';
export const SET_COMPLEX_ID = 'SET_COMPLEX_ID';
export const SET_TAKE_ID = 'SET_TAKE_ID';

export const SET_MATERIAL_THEME = 'SET_MATERIAL_THEME';
export const SET_ACTION_IMG = 'SET_ACTION_IMG';

export const SET_ORDER_ID = 'SET_ORDER_ID';

export const SET_RESULTS_BY_ORDER_ID = 'SET_RESULTS_BY_ORDER_ID';

export const CLEAR_TAKES_FROM_BASKET = 'CLEAR_TAKES_FROM_BASKET';

export const GO_TO_COLLECTION_POINTS = 'GO_TO_COLLECTION_POINTS';

export const SET_DOCTOR_ORDERED_ASSAYS = 'SET_DOCTOR_ORDERED_ASSAYS';

export const SET_REGIONS = 'SET_REGIONS';
export const SET_SELECT_PUNKT = 'SET_SELECT_PUNKT';
export const SET_ALL_COLLECTION_POINTS = 'SET_ALL_COLLECTION_POINTS';


export const SET_CATALOG_FILTERED_VALUE = 'SET_CATALOG_FILTERED_VALUE';

export const SET_PROMO_CODE = 'SET_PROMO_CODE';

export const SET_REGION_CONFIRMATION = 'SET_REGION_CONFIRMATION';

export const SET_NOT_ADDED_PATIENTS = 'SET_NOT_ADDED_PATIENTS';

export const SET_BASKET = 'SET_BASKET';

export const SET_TAGS = 'SET_TAGS';

export const SET_SELECTED_TAGS = 'SET_SELECTED_TAGS';

export const SET_TENTS = 'SET_TENTS';
export const SET_BARCODE = 'SET_BARCODE';

export const SET_PRODUCT_CATALOG = 'SET_PRODUCT_CATALOG';

export const SET_LOADING = 'SET_LOADING';
export const UNSET_LOADING = 'UNSET_LOADING';

export const SET_ERROR = 'SET_ERROR';
export const UNSET_ERROR = 'UNSET_ERROR';

export const SET_ADDITIONAL_DATA = 'SET_ADDITIONAL_DATA';

export const SET_MENU_BURGER = 'SET_MENU_BURGER';

export const SET_COMPLEX_WIZARD = 'SET_COMPLEX_WIZARD';

export const SET_TOGGLE_PREPARATION_RULES = 'SET_TOGGLE_PREPARATION_RULES';

export const SET_TOGGLE_PERSONAL_DATA = 'SET_TOGGLE_PERSONAL_DATA';

export const SET_TOGGLE_COMPLEX_TRANSITION = 'SET_TOGGLE_COMPLEX_TRANSITION';

export const SET_TOGGLE_TAKE_IN_ORDER = "SET_TOGGLE_TAKE_IN_ORDER";

export const SET_REF_FOR_PATIENT_TABS = "SET_REF_FOR_PATIENT_TABS";

export const SET_TOGGLE_USE_ACTION = "SET_TOGGLE_USE_ACTION";

//calculator
export const ADD_ASSAY_TO_ORDER_LIST_CALCULATOR = 'ADD_ASSAY_TO_ORDER_LIST_CALCULATOR';
export const ADD_PAYMENT = 'ADD_PAYMENT';
export const REMOVE_ASSAY_FROM_ORDER_LIST_CALCULATOR = 'REMOVE_ASSAY_FROM_ORDER_LIST_CALCULATOR';
export const CLEAR_ORDERED_ASSAYS_CALCULATOR = 'CLEAR_ORDERED_ASSAYS_CALCULATOR';
export const ADD_SELECTED_PUNKT_CALCULATOR = 'ADD_SELECTED_PUNKT_CALCULATOR';
export const ADD_ASSAY_TIME_CALCULATOR = 'ADD_ASSAY_TIME_CALCULATOR';
export const ADD_ASSAY_TIME_LINE_CALCULATOR = 'ADD_ASSAY_TIME_LINE_CALCULATOR';
export const ADD_ORDER_NOTE_CALCULATOR = 'ADD_ORDER_NOTE_CALCULATOR';
export const SET_ORDER_ID_CALCULATOR = 'SET_ORDER_ID_CALCULATOR';
export const CLEAR_TAKES_FROM_BASKET_CALCULATOR = 'CLEAR_TAKES_FROM_BASKET_CALCULATOR';
export const SET_PROMO_CODE_CALCULATOR = 'SET_PROMO_CODE_CALCULATOR';
export const SET_ADDITIONAL_DATA_CALCULATOR = 'SET_ADDITIONAL_DATA_CALCULATOR';
export const SET_BASKET_CALCULATOR = 'SET_BASKET_CALCULATOR';
export const SET_TOGGLE_TAKE_IN_ORDER_CALCULATOR = "SET_TOGGLE_TAKE_IN_ORDER_CALCULATOR";
export const SET_ORDER_DISCOUNT_CALCULATOR = "SET_ORDER_DISCOUNT_CALCULATOR";
export const SET_FULL_ORDER_DATA_CALCULATOR = "SET_FULL_ORDER_DATA_CALCULATOR";
export const SET_NURSES_CALCULATOR = "SET_NURSES_CALCULATOR";
export const SET_ACTIVE_NURSE = "SET_ACTIVE_NURSE";
export const SET_ORDER_VIALS = "SET_ORDER_VIALS";
export const INIT_CALCULATOR = "INIT_CALCULATOR";
export const UPDATE_TUBES_ON_SERVER = "UPDATE_TUBES_ON_SERVER";
export const TOGGLE_AUTOMATIC_PRINT_BARCODES = "TOGGLE_AUTOMATIC_PRINT_BARCODES";
//end of calculator actions
export const SET_SELECTED_AGENTS = "SET_SELECTED_AGENTS";
export const SET_LOADING_ORDER_CREATE = "SET_LOADING_ORDER_CREATE";
export const SET_ORDERS = "SET_ORDERS";
export const SET_FIND_PARAM = "SET_FIND_PARAM";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB"
export const SET_IS_ADMIN = "SET_IS_ADMIN"
export const SET_WEB_ORDERS = "SET_WEB_ORDERS";
