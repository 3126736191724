import React, {useEffect, useRef, useState} from 'react';
import {IconButton, makeStyles} from '@material-ui/core';
import {AutoSizer, List} from 'react-virtualized';
import {getAssayRowHeight} from '@cabinet-packages/helpers';
import {Loading, SearchTextField} from '@cabinet-packages/components';
import {useDispatch, useSelector} from 'react-redux';
import {useAssaysFilter} from '@cabinet-packages/hooks';
import {Link} from "react-router-dom";
import HistoryIcon from "@material-ui/icons/History";
import {addAssayToOrderList, getCatalog, removeAssayFromOrderList} from "@cabinet-packages/redux";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

const normIndexLocalStorageKey = 'normsPage-indexOfNorm';

const useStyles = makeStyles({
    root: {
        width: '100%',
        maxWidth: '900px',
        paddingTop: '10px',
        maxHeight: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        '@media (max-width: 900px)': {
            paddingBottom: '50px',
        },
    },
    textFieldContainer: {
        marginBottom: '10px',
    },
    list: {
        boxShadow: '0 0 1px 1px rgba(0, 0, 0, 0.1)',
        '&:focus': {
            outline: 'none',
        },
    },
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItem: 'stretch',
        justifyContent: 'space-between',
        padding: '10px 10px 0 10px',
    },
    listItemTitle: {
        fontSize: '18px',
        letterSpacing: '1px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItem: 'center',
    },
    listItemBottom: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '40px',
        paddingBottom: '5px',
        marginTop: '-4px',
    },
    listItemPrice: {
        color: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        userSelect: 'none',
    },
    addRemoveButton: {
        padding: '5px',
    },
    listItemShow: {
        color: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        userSelect: 'none',
        cursor: 'pointer',
        alignItems: 'center',
    },
    orderButtonContainer: {
        padding: '5px 0',
        '@media (max-width: 900px)': {
            padding: '5px',
        },
    },
    orderButton: {
        width: '100%',
    },
    tableTd: {
        borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    }
});

export default function ResearchStatus() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { complexResearch } = useSelector(state => state.assaysCatalog);
    const { orderingList, amountOfItems } = useSelector(state => state.orderAssays);
    const [ setIsUserSelectAssay] = useState(false);
    const listRef = useRef();

    const {
        filteredState,
        filterValue,
        handleChangeFilterValue,
    } = useAssaysFilter(complexResearch);

    useEffect( ()=> {
        dispatch(getCatalog());
    } );

    if(!complexResearch) {
        return <Loading/>;
    }

    function rowRenderer(props) {
        const { key, index, style } = props;
        const item = filteredState[index];
        const scrollIndex = +localStorage.getItem(normIndexLocalStorageKey);
        const isIncludedInOrderingList = orderingList.includes(item.id)

        const newStyles = {
            ...style,
            background: isIncludedInOrderingList ? 'rgba(0, 0, 0, 0.1)' : 'none',
            borderBottom: '1px solid' +
                ' rgba(0, 0, 0, 0.1',
        };

        if (scrollIndex === index) newStyles.borderBottom = '3px solid #2cb8ad';

        const saveIndexToLocalStore = () => {
            localStorage.setItem(normIndexLocalStorageKey, index);
        };

        const handleAdd = (event) => {
            setIsUserSelectAssay(true);
            event.stopPropagation();
            event.preventDefault();
            dispatch(addAssayToOrderList(
                item.id,
                item.onsite_dsct ? item.price - item.price * item.onsite_dsct / 100 : item.price,
                () => listRef.current.forceUpdateGrid(),
                item.type,
            ));
        };

        const handleRemove = (event) => {
            if (amountOfItems === 1) setIsUserSelectAssay(false);
            event.stopPropagation();
            event.preventDefault();
            dispatch(removeAssayFromOrderList(
                item.id,
                item.onsite_dsct ? item.price - item.price * item.onsite_dsct / 100 : item.price,
                () => listRef.current.forceUpdateGrid(),
                item.type,
            ));
        };

        return (
            <Link
                key={key}
                onClick={saveIndexToLocalStore}
                style={{
                    marginLeft: '4px',
                    textDecoration: 'none',
                    color: 'inherit',
                }}
                to={{
                    pathname: `/research-info/${item.id}`,
                    state: { id: item.id },
                }}>
                <div key={key} style={newStyles} className={classes.listItem}>
                    <div className={classes.listItemTitle}>
                        {item.name}
                    </div>
                    <div className={classes.listItemBottom}>
                        <div className={classes.listItemShow}>
                            {scrollIndex === index && <HistoryIcon htmlColor="#2cb8ad"/>}
                            Переглянути
                        </div>
                        <div className={classes.listItemPrice}>
                            {item.price} грн
                            {isIncludedInOrderingList ? (
                                <IconButton size="small" onClick={(event)=>handleRemove(event)} className={classes.addRemoveButton}>
                                    <RemoveIcon/>
                                </IconButton>
                            ) : (
                                <IconButton size="small" onClick={(event)=>handleAdd(event)} className={classes.addRemoveButton}>
                                    <AddIcon/>
                                </IconButton>
                            )}
                        </div>
                    </div>
                </div>

            </Link>
        );
    }

    return (
        <div className={classes.root}>
            <div className={classes.textFieldContainer}>
                <SearchTextField
                    value={filterValue}
                    onChange={handleChangeFilterValue}
                />
            </div>
            <div>
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            className={classes.list}
                            width={width}
                            height={height}
                            ref={listRef}
                            rowCount={ filteredState.length  }
                            rowHeight={({ index }) => getAssayRowHeight(index, width, filteredState)}
                            rowRenderer={rowRenderer}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    );
}
