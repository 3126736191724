import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    MuiThemeProvider,
    Radio,
    RadioGroup,
    Tooltip
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Close} from "@material-ui/icons";
import MuiPhoneNumber from "material-ui-phone-number";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
    confirmPatientCode,
    createOrderDoctors,
    getDoctorOrderedAssayPdf,
    getPatientsForDoctorOrdering,
    sendCodeToPatient,
    setCreatePatientByDoctor
} from "@cabinet-packages/api/api";
import {AddRemoveIconButtons, Loading} from "../Shared";
import {useDispatch, useSelector} from "react-redux";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import uaLocale from 'date-fns/locale/uk';
import {clearOrderedAssays, setDoctorOrderedAssays} from "@cabinet-packages/redux";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        width: '100%',
        height: '100%',
        minWidth: '300px',
        minHeight: '300px',
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '@media (max-width: 900px)': {
            minWidth: 'auto',
        },
    },
    phoneConfirmationCloseButton: {
        position: 'absolute',
        top: '4px',
        right: '4px',
        zIndex: 100,
    },
    dialogAlertText: {
        color: theme.palette.error.main,
        maxWidth: '284px',
        paddingTop: '30px',
        textAlign: 'center',
    },
    dialogErrorText :{
        color: theme.palette.error.light,
        maxWidth: '284px',
        textAlign: 'center',
        alignSelf: 'center',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    phoneConfirmationButton: {
        marginTop: '20px',
    },
    confirmPhone: {
        margin: '4px',
        fontWeight: 600,
    },
    confirmPhoneNumber: {
        justifySelf: 'flex-start',
        margin: '4px',
    },
    wrapperCode: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
    },
    formControlLabel: {
        marginLeft: '0px',
    },
    assaysWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        padding: '10px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        marginTop: '4px',
        ...theme.shape,
    },
    listItemTitle: {
        fontSize: '18px',
        letterSpacing: '1px',
        display: 'flex',
        alignItems: 'center',
    },
    listItemBottom: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '40px',
        padding: '12px 0',
    },
    listItemPrice: {
        color: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        userSelect: 'none',
    },
    createClientWrapper: {
        height: '100%',
        display: 'grid',
        width: '100%',
        maxWidth: '100%',
    },
    bodyContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    birthdayContainer: {
        padding: '4px 0',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 1fr',
        gridGap: '5px',
    },
    formAnnotation: {
        fontSize: '11px',
        color: theme.palette.secondary.light,
    },
    actionContainer: {
        margin: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '@media (max-width: 900px)': {
            margin: '5px',
        },
    },
    actionContainerButton: {
        maxHeight: '70px',
        '@media (max-width: 900px)': {
            margin: '5px',
        },
    },
}));

const SelectDoctorPatient = ({ themeMaterialUI, handleClose, text }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { orderingList, totalPrice, amountOfItems, selectedPunkt } = useSelector(state => state.orderAssays);
    const { catalog, complexResearch, actions, takeId }  = useSelector(state => state.assaysCatalog);
    const { activePatient } = useSelector(state => state.patients);
    const [phone, setPhone] = useState('');
    const [isChangedState, setIsChangedState] = useState(true);
    const [disableConfirmSMS, setDisableConfirmSMS] = useState(true);
    const [stage, setStage] = useState(0);
    const [error, setError] = useState('');
    const [code, setCode] = useState('');
    const [patients, setPatients] = useState(null);
    const [valueRadioButton, setValueRadioButton] = useState(null);
    const [loading, setLoading] = useState(false);
    const [uuid, setUuid] = useState(null);
    const [patient, setPatient] = useState(null);
    const [orderID, setOrderID] = useState(null);
    const todayDate = new Date();
    const initialCreatePatient = {
        firstname: '',
        lastname: '',
        fathername: '',
        dt: todayDate,
        birthday: todayDate,
        sex: 'F',
        phone: '',
        email: '',
        regDate: todayDate,
    }
    const [state, setState] = useState(initialCreatePatient);
    const [isChangedStateSignUp, setIsChangedStateSignUp] = useState(true);

    const createAssaysData = (ids) => ids.map(currId => (
        [...catalog, ...complexResearch, ...actions].find(currAssay => +currAssay.id === +currId.id && currAssay.type === currId.type)));

    const handleConfirm = async (e, phone) => {
        e.stopPropagation();
        e.preventDefault();
        setLoading(true);
        if (error.length > 0) setError('');

        const result = await getPatientsForDoctorOrdering(phone.replace(/[[()\s]/g, ''))
            .catch(onerror => setError("Вибачте, сталась помилка при надсиланні."));

        if (result && result.length > 0) {
            setPatients(result);
            setStage(1);
        } else if (result && result.length === 0) {
            setStage(5)
        }
        setLoading(false);
    }

    const handleChangeRadioButtons = (event) => {
        setValueRadioButton(+event.target.value);
    };

    const backAuth = () => {
        setStage(stage - 1);
    }

    const handleSendCode = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        setLoading(true);
        if (error.length > 0) setError('');

        // const result = await sendCodeToPatient(phone.replace(/[[()\s]/g, ''))
        //     .catch(onerror => setError("Вибачте, сталась помилка при надсиланні."));
        //
        // // if (result) {
        // //     setUuid(result.token);
        // //     setStage(2);
        // // }
        setStage(3)
        setLoading(false);
    }

    const handleConfirmSMS = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        setLoading(true);
        if (error.length > 0) setError('');

        const result = await confirmPatientCode(uuid, code)
            .catch(onerror => setError("Вибачте, сталась помилка при надсиланні."));

        if (result) {
            setStage(3);
        } else {
            setError('Неправильний код.');
            setStage(stage - 1);
            setCode('');
        }
        setLoading(false);
    };

    const handleCreateOrder = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        setLoading(true);
        if (error.length > 0) setError('');

        const result = await createOrderDoctors(
            totalPrice,
            patient.id,
            activePatient.id,
            createAssaysData(orderingList).map(item => ({
                idPacket: item.id,
                idConf: item.idConf,
                price: item.price,
                discount: 0,//item.discount,
                amount: 1,
                debt: 0,
            })),
            selectedPunkt?.idPunkt
        ).catch(onerror => setError("Вибачте, сталась помилка при надсиланні."));

        if (result) {
            setStage(4);
            setOrderID(result);
            dispatch(setDoctorOrderedAssays(null))
            dispatch(clearOrderedAssays())
        }
        setLoading(false);
    };

    const updateState = (field, value) => {
        setState(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleSignUp = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        setLoading(true);
        if (error.length > 0) setError('');

        const result = await setCreatePatientByDoctor(
            state.lastname.trim(),
            state.firstname.trim(),
            state.fathername.trim(),
            state.birthday.toISOString().slice(0, 10),
            state.sex,
            phone.replace(/[[()\s]/g, ''),
            state.email).catch(onerror => setError("Вибачте, сталась помилка при надсиланні."));

        if (result) {
            setPatients([result]);
            setStage(1);
        }

        setLoading(false);
    }

    useEffect(() => {
        if (phone && +phone.length === 19) {
            setIsChangedState(false)
        } else {
            setIsChangedState(true)
        }
        if (code && code.length === 4) {
            setDisableConfirmSMS(false)
        } else {
            setDisableConfirmSMS(true)
        }
        if (!valueRadioButton && patients && patients.length > 0) {
            setValueRadioButton(+patients[0].id)
        }
        if ((valueRadioButton && patients) && (!patient || valueRadioButton !== patient.id)) {
            setPatient(patients.find(item => item.id === valueRadioButton));
        }
        if (state) {
            const {firstname, lastname} = state;

            if (firstname.length && lastname.length) {
                setIsChangedStateSignUp(false)
            } else {
                setIsChangedStateSignUp(true)
            }
        }
    }, [phone, code, valueRadioButton, patients, patient, state, isChangedStateSignUp]);

    return (<MuiThemeProvider theme={themeMaterialUI}>
        <Dialog open={true}>
            {stage === 0 && <DialogContent className={classes.dialogContent}>
                <IconButton onClick={handleClose} className={classes.phoneConfirmationCloseButton}>
                    <Tooltip title="Натисніть, щоб закрити діалогове вікно." arrow>
                        <Close/>
                    </Tooltip>
                </IconButton>
                <h6 className={classes.dialogAlertText}>{text}</h6>
                {error.length !== 0 && <h5 className={classes.dialogErrorText}>{error}</h5>}
                <div className={classes.wrapper}>
                    <MuiPhoneNumber
                        autoFocus={true}
                        defaultCountry='ua'
                        countryCodeEditable={false}
                        regions='europe'
                        label="Номер телефону*"
                        value={phone}
                        onChange={(value) => setPhone(value)}
                    />
                </div>
                {loading
                    ? <Loading/>
                    : <Button
                        variant="outlined"
                        color="primary"
                        className={classes.phoneConfirmationButton}
                        onClick={(e) => handleConfirm(e, phone)}
                        disabled={isChangedState}
                    >
                        Отримати дані пацієнта
                    </Button>}
            </DialogContent>}
            {stage === 1 && <DialogContent className={classes.dialogContent}>
                <IconButton onClick={handleClose} className={classes.phoneConfirmationCloseButton}>
                    <Tooltip title="Натисніть, щоб закрити діалогове вікно." arrow>
                        <Close/>
                    </Tooltip>
                </IconButton>
                <p className={classes.confirmPhone}>Телефон пацієнта:</p>
                <p className={classes.confirmPhoneNumber}>{phone}</p>
                {patients && valueRadioButton && <FormControl component="fieldset">
                    <FormLabel component="legend">Виберіть пацієнта:</FormLabel>
                    <RadioGroup aria-label="patients" name="patients" value={valueRadioButton} onChange={handleChangeRadioButtons}>
                        {patients.map((item) => (<FormControlLabel
                            key={item.id}
                            value={item.id}
                            control={<Radio/>} label={`${item.lastname} ${item.firstname} ${item.fathername}`}
                            className={classes.formControlLabel}
                        />))}
                    </RadioGroup>
                </FormControl>}
                {error.length !== 0 && <h5 className={classes.dialogErrorText}>{error}</h5>}
                {loading
                    ? <></>
                    : <Button
                        variant="outlined"
                        color="primary"
                        className={classes.phoneConfirmationButton}
                        onClick={() => setStage(5)}
                    >
                        додати нового пацієнта
                    </Button>}
                {loading
                    ? <Loading/>
                    : <Button
                        variant="contained"
                        color="primary"
                        className={classes.phoneConfirmationButton}
                        onClick={handleSendCode}
                    >
                        Вибрати пацієнта
                    </Button>}
            </DialogContent>}
            {stage === 2 && <DialogContent className={classes.dialogContent}>
                <IconButton onClick={handleClose} className={classes.phoneConfirmationCloseButton}>
                    <Tooltip title="Натисніть, щоб закрити діалогове вікно." arrow>
                        <Close/>
                    </Tooltip>
                </IconButton>
                <p className={classes.confirmPhone}>Телефон пацієнта:</p>
                <p className={classes.confirmPhoneNumber}>{phone}</p>
                <p className={classes.confirmPhone}>ПІБ пацієнта:</p>
                {patient && <p className={classes.confirmPhoneNumber}>{`${patient.lastname} ${patient.firstname} ${patient.fathername}`}</p>}
                <div className={classes.wrapperCode}>
                    <TextField
                        label="Ведіть код"
                        variant="outlined"
                        autoFocus
                        value={code}
                        inputProps={{
                            maxLength: 4,
                        }}
                        onChange={(event) => (
                            setCode(event.target.value.replace(/[^0-9]/g, '')))
                        }
                    />
                </div>
                {error.length !== 0 && <h5 className={classes.dialogErrorText}>{error}</h5>}
                {loading
                    ? <Loading/>
                    : <Button
                        variant="outlined"
                        color="primary"
                        className={classes.phoneConfirmationButton}
                        onClick={handleConfirmSMS}
                        disabled={disableConfirmSMS}>
                        Відправити код
                    </Button>}
            </DialogContent>}
            {stage === 3 && <DialogContent className={classes.dialogContent}>
                <IconButton onClick={handleClose} className={classes.phoneConfirmationCloseButton}>
                    <Tooltip title="Натисніть, щоб закрити діалогове вікно." arrow>
                        <Close/>
                    </Tooltip>
                </IconButton>
                <p className={classes.confirmPhone}>Телефон пацієнта:</p>
                <p className={classes.confirmPhoneNumber}>{phone}</p>
                <p className={classes.confirmPhone}>ПІБ пацієнта:</p>
                {patient && <p className={classes.confirmPhoneNumber}>{`${patient.lastname} ${patient.firstname} ${patient.fathername}`}</p>}
                <div className={classes.assaysWrapper}>
                    <p className={classes.confirmPhone}>Кількість замовлених досліджень: {amountOfItems}</p>
                    <p className={classes.confirmPhone}>Вартість замовленних досліджень: {totalPrice} грн</p>
                    <p className={classes.confirmPhone}>Список замовлених досліджень:</p>
                    {createAssaysData(orderingList).map(item => (<div key={item.id} className={classes.listItem}>
                        {item && <div className={classes.listItemTitle}>
                            {item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join('')}
                            {' '}
                            {item.type === 'action' && catalog.find(assay => assay.id === item.idGrTest)
                                ? catalog.find(assay => assay.id === item.idGrTest).name
                                    ? catalog.find(assay => assay.id === item.idGrTest).name
                                    : catalog.find(assay => assay.id === item.idGrTest).testNames.split(',')[0].split('\\\\').join('')
                                : ''}
                        </div>}
                        {item && <div className={classes.listItemBottom}>
                            <div className={classes.listItemPrice}>
                                {item.price} грн
                            </div>
                            {+item.idParent !== takeId && <AddRemoveIconButtons item={item} isIncludedInOrderingList={true}/>}
                        </div>}
                    </div>))}
                </div>
                {error.length !== 0 && <h5 className={classes.dialogErrorText}>{error}</h5>}
                {loading
                    ? <Loading/>
                    : <Button
                        variant="outlined"
                        color="primary"
                        className={classes.phoneConfirmationButton}
                        onClick={handleCreateOrder}
                    >
                        Створити замовлення
                    </Button>}
            </DialogContent>}
            {stage === 4 && <DialogContent className={classes.dialogContent}>
                <IconButton onClick={handleClose} className={classes.phoneConfirmationCloseButton}>
                    <Tooltip title="Натисніть, щоб закрити діалогове вікно." arrow>
                        <Close/>
                    </Tooltip>
                </IconButton>
                <p className={classes.confirmPhone}>Телефон пацієнта:</p>
                <p className={classes.confirmPhoneNumber}>{phone}</p>
                <p className={classes.confirmPhone}>ПІБ пацієнта:</p>
                {patient && <p className={classes.confirmPhoneNumber}>{`${patient.lastname} ${patient.firstname} ${patient.fathername}`}</p>}
                <p className={classes.confirmPhone}>ID замовлення:</p>
                <p className={classes.confirmPhoneNumber}>{orderID}</p>
                <p className={classes.dialogAlertText}>Замовлення успішно сформоване.</p>
                {error.length !== 0 && <h5 className={classes.dialogErrorText}>{error}</h5>}
                {loading
                    ? <Loading/>
                    : <></>}
            </DialogContent>}
            {stage === 5 && <DialogContent className={classes.dialogContent}>
                <IconButton onClick={handleClose} className={classes.phoneConfirmationCloseButton}>
                    <Tooltip title="Натисніть, щоб закрити діалогове вікно." arrow>
                        <Close/>
                    </Tooltip>
                </IconButton>
                <div className={classes.createClientWrapper}>
                    {state && <div className={classes.bodyContainer}>
                        <TextField
                            value={state.lastname}
                            onChange={(event) => updateState('lastname', event.target.value)}
                            label="Прізвище*"
                        />
                        <TextField
                            value={state.firstname}
                            onChange={(event) => updateState('firstname', event.target.value)}
                            label="Ім'я*"
                        />
                        <TextField
                            value={state.fathername}
                            onChange={(event) => updateState('fathername', event.target.value)}
                            label="По-батькові"
                        />
                        <div className={classes.birthdayContainer}>
                            <p style={{color: 'rgba(0, 0, 0, 0.54)'}}>Дата народження</p>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={uaLocale}>
                                <KeyboardDatePicker
                                    value={state.birthday}
                                    onChange={(value) => updateState('birthday', value)}
                                    invalidDateMessage="Неправильний формат дати."
                                    maxDateMessage="Дата народження не може бути в майбутньому."
                                    format="yyyy-MM-dd"
                                    disableFuture
                                    cancelLabel="Назад"
                                    okLabel="Ок"
                                    openTo="year"
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Стать</FormLabel>
                            <RadioGroup
                                value={state.sex}
                                onChange={(event) => updateState('sex', event.target.value)}
                                className={classes.genderGroup}>
                                <FormControlLabel
                                    value="F"
                                    control={<Radio color="primary"/>}
                                    label="Жіноча"
                                />
                                <FormControlLabel
                                    value="M"
                                    control={<Radio color="primary"/>}
                                    label="Чоловіча"
                                />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            value={state.email}
                            onChange={(event) => updateState('email', event.target.value)}
                            label="Електронна пошта"
                            type="email"
                        />
                        <p className={classes.formAnnotation}>(*) Поля позначені зірочкою обовязкові для
                            заповнення.</p>
                    </div>}
                    <div className={classes.actionContainer}>
                        {error.length !== 0 && <h5 className={classes.dialogErrorText}>{error}</h5>}
                        {loading
                            ? <Loading/>
                            : <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleSignUp}
                                className={classes.actionContainerButton}
                                disabled={
                                    isChangedStateSignUp
                                }
                            >
                                Додати дані пацієнта
                            </Button>}
                    </div>
                </div>
            </DialogContent>}
        </Dialog>
    </MuiThemeProvider>);
};

export default SelectDoctorPatient;
