import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        height: '100%',
        color: theme.palette.primary.main,
    },
    listOfRules: {
        listStylePosition: 'inside',
        fontSize: '14px',
        paddingTop: '8px'
    },
}));

const PreparationRules = ({className,rules}) => {
    const cl = useStyles();
    const link =
        // window.location.pathname.slice(1)
        window.location.hostname.split('.')[0]

    return (<div className={className}>
        <div className={cl.root}>
            <h3>Правила підготовки</h3>
            <div className={cl.listOfRules}>
                <div dangerouslySetInnerHTML={{__html: rules}}/>
            </div>
          {/*  {link !== 'catering' ?
                <ul className={cl.listOfRules}>
                    <li>За 2 дні до проведення дослідження не використовувати спреї або розчини для полоскання
                        ротоглотки,
                        мазі і спреї для носа, не промивати ніс. Якщо відмінити дані процедури неможливо, проінформуйте
                        про
                        це адміністратора у відділенні, щоб провести відбір біоматеріалу не раніше, ніж через 3-4 год
                        після
                        останнього їх застосування.
                    </li>
                    <li>За 24 год утриматись від вживання алкоголю.</li>
                    <li>За 3-4 год перед дослідженням НЕ МОЖНА: пити, їсти, чистити зуби, курити і жувати гумку.</li>
                    <li>Якщо Ви здаєте ПЛР для виїзду за кордон, для виключення помилки під час оформлення замовлення,
                        бажано мати при собі закордонний паспорт чи фото першої його сторінки.
                    </li>
                    <li>Застереження! Самостійно проводити відбір не рекомендується, для гарантування правильного
                        результату відбір має провести спеціаліст –сестра медична чи лікар.
                    </li>
                </ul> :
                <ul className={cl.listOfRules}>
                    <li>Венозна кров відбирається натщесерце або через 8-12 год після прийому їжі.
                    </li>
                    <li>В день дослідження допускається вживання невеликої кількості води.</li>
                    <li>За 24 годин до дослідження слід виключити стресові ситуації, прийом алкоголю, паління, обмежити
                        фізичну активність, гарячі ванни, сауни, лазню, статеві стосунки.
                    </li>
                    <li>Також слід виключити прийом ліків. Якщо відмінити прийом ліків неможливо, необхідно
                        проінформувати адміністратора на пункті забору біоматеріалу про це перед здачею крові
                    </li>
                    <li>Для оцінки рівня гормонів у жінок важливо врахувати день менструального циклу рекомендовано на
                        2-3 день, якщо лікарем не зазначено інше в скеруванні.
                    </li>
                    <li>Рекомендовано не проводити відбір крові під час будь-яких гострих захворювань.
                    </li>
                    <li>Відбір крові бажано здійснювати до 11 години ранку – в зв’язку з добовим біоритмом. Оптимально
                        здавати вранці: не пізніше, ніж через 2 год після пробудження, перед здачею крові відпочити 30
                        хвилин, заспокоїтись.
                    </li>
                    <li>Максимальний рівень кортизолу – о 8 год ранку. <b>Примітка!!!</b> Час прийому відбору зразка на
                        дослідження кортизолу: 07:00-10:00, 16:00-20:00!
                    </li>
                </ul>
            }*/}
        </div>
    </div>);
};

export default PreparationRules;
