import React, {useEffect, useRef, useState} from "react";
import useStyles from "../styles";
import {Checkbox, FormControlLabel, FormGroup, TextField, withStyles} from "@material-ui/core";
import CheckboxLabel from "./CheckboxLabel";
import {createAssaysData} from "../helpers/createAssaysData";
import {useDispatch, useSelector} from "react-redux";
import {convertPrice} from "../helpers/convertPrice";
import {addAssayToOrderList, clearOrderedAssays, clearTakesFromBasket, setPromoCode} from "@cabinet-packages/redux";
import {getPromo} from "@cabinet-packages/api/api";
import {ReactComponent as Btt_radio} from '../assets/bttn_radio.svg';
import {TooltipMedis} from "../../../components/Shared";
import {setPatientTabsRef, toggleTakeInOrder} from "@cabinet-packages/redux/actions/actionsCreators";
import Pdf from '../assets/pdf/Договір публічної оферти_МеДіС.pdf';

const StyledTextField = withStyles((theme) => ({
    root: {
        height: '48px',
        width: '240px',
        '& input': {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(1.625),
        },
        '& fieldset': {
            border: `2px solid ${theme.palette.grey.border}`,
            borderRadius: '5px',
        }
    },
}))(TextField);

const StyledFormControlLabel = withStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(1),
        alignItems: 'flex-start'
    },
    label: {
        marginTop: theme.spacing(1.5)
    },
}))(FormControlLabel);

const StyledFormControlLabelTake = withStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(1),
        alignItems: 'flex-start',
        opacity: .5
    },
    label: {
        marginTop: theme.spacing(1.5)
    },
}))(FormControlLabel);

const OrderTerms = ({checkboxes, promo, isAdmin}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const [state, setState] = checkboxes;
    const [value, setValue] = promo;
    const {catalog, complexResearch, actions, takeId}  = useSelector(state => state.assaysCatalog);
    const {orderingList, totalPrice, promoCode, patientTabsRef} = useSelector(state => state.orderAssays);
    const [completeOrderingListPrice, setCompleteOrderingListPrice] = useState(0);
    const [sum, setSum] = useState(0);
    const [discount, setDiscount] = useState(convertPrice(0,0,cl.onlinePriceOverview));
    const { activePatient } = useSelector(state => state.patients);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isUsedPromo, setIsUsedPromo] = useState(false);
    const ref = useRef();
    const isMedis = process.env.REACT_APP_CURRENT_CLINIC === "MEDIS";

    const handleChangePromoCode = async (event) => {
        event.persist();

        setValue(event.target.value);
        await handleGetPromo(event, event.target.value)
    };

    const handleDeactivatePromo = () => {
        if (state.checkedC) {
            handleChange({target: {name: 'checkedC', checked: false}})
        }

        dispatch(setPromoCode(null));
        createAssaysData(orderingList, catalog, complexResearch, actions, activePatient, takeId).forEach((assay, idx) => {
            if (idx === 0) dispatch(clearOrderedAssays());
            if (assay.idParent !== takeId) {
                dispatch(addAssayToOrderList(assay.id,
                    assay.discount
                        ? assay.price - assay.price * assay.discount / 100
                        : assay.price,
                    () => {},
                    assay.type));
            }
        });
        setIsUsedPromo(false);

        if (state.checkedC) {
            handleChange({target: {name: 'checkedC', checked: true}});
        }
    };

    const handleChange = (event) => {
        if (event.persist) {
            event.persist();
        }
        setState(prev => ({ ...prev, [event.target.name]: event.target.checked }));
        handleEditOrderingList(event);
        if (event.target.name === 'checkedC') {
            dispatch(toggleTakeInOrder());
        }
    };

    const prevOrderingList = orderingList;

    const handleEditOrderingList = (event) => {
        if (event.target.name === "checkedC" && event.target.checked === true) {
            dispatch(clearTakesFromBasket());
            dispatch(setPromoCode(promoCode));
            setIsUsedPromo(false);
        }
        if (event.target.name === "checkedC" && event.target.checked === false) {
            dispatch(clearOrderedAssays());

            prevOrderingList.forEach(assay => {
                const findAssay = [...catalog, ...complexResearch, ...actions].find(item => item.type === assay.type && +item.id === +assay.id);

                dispatch(addAssayToOrderList(findAssay.id,
                    findAssay.discount
                        ? findAssay.price - findAssay.price * findAssay.discount / 100
                        : findAssay.price,
                    ()=>{},
                    findAssay.type,
                ));
            });
            dispatch(setPromoCode(promoCode));
            setIsUsedPromo(false);
        }
    };

    const handleGetPromo = async (event, value) => {
        event.stopPropagation();
        event.preventDefault();
        if (promoCode) dispatch(setPromoCode(null));
        setLoading(true);
        setError(false);

        try {
            const promoValue = await getPromo(value);
            if (promoValue) dispatch(setPromoCode(promoValue));
        } catch (e) {
            setError('Вибачте, сталась помилка.');
        }

        setLoading(false);
    };


    useEffect(() => {
        if (catalog && complexResearch && actions && orderingList && activePatient) {
            const newCompleteOrderingListPrice = createAssaysData(orderingList, catalog, complexResearch, actions, activePatient, takeId)
                .reduce((acu, cur) => {
                    if (cur.notSuitable) {
                        return acu
                    } else {
                        return acu + cur.price;
                    }
                },0);

            setCompleteOrderingListPrice(newCompleteOrderingListPrice);

            setSum(+newCompleteOrderingListPrice);
            setDiscount(convertPrice((+newCompleteOrderingListPrice)-(+totalPrice),0,cl.onlinePriceOverview));
        }
    }, [catalog, complexResearch, actions, takeId, completeOrderingListPrice, orderingList, activePatient]);



    useEffect(() => {
        if (promoCode && promoCode.type !== 'none' && !isUsedPromo && orderingList && catalog && complexResearch && actions) {
            if (!value || value.length === 0) setValue(promoCode.code)
            if (promoCode.type === 'all') {
                createAssaysData(orderingList, catalog, complexResearch, actions, activePatient, takeId).forEach((assay, idx) => {
                    if (idx === 0) dispatch(clearOrderedAssays());
                    if (assay.idParent !== takeId) {
                        dispatch(addAssayToOrderList(assay.id, +assay.price - (+assay.price * (+promoCode.discount/100)),
                            () => {}, assay.type))
                    }
                });
                if (state.checkedC) handleChange({target: {name: 'checkedC', checked: true}});
                setIsUsedPromo(true);
            }
            if (promoCode.type === 'specified') {
                createAssaysData(orderingList, catalog, complexResearch, actions, activePatient, takeId).forEach((assay, idx) => {
                    if (idx === 0) dispatch(clearOrderedAssays());
                    if (assay.idParent !== takeId) {
                        const foundDiscount = promoCode.grtests.find(item => +item[0] === +assay.id);

                        if (foundDiscount) {
                            dispatch(addAssayToOrderList(
                                assay.id,
                                +foundDiscount[1] === 0 ? +assay.price - (+assay.price * (+foundDiscount[2]/100)) : +foundDiscount[1],
                                () => {},
                                assay.type));
                        } else {
                            dispatch(addAssayToOrderList(
                                assay.id,
                                +assay.price - (+assay.price * (+assay.discount/100)),
                                () => {},
                                assay.type));
                        }
                    }
                });
                if (state.checkedC) handleChange({target: {name: 'checkedC', checked: true}});
                setIsUsedPromo(true);
            }
            if (promoCode.type === 'notdisc') {
                createAssaysData(orderingList, catalog, complexResearch, actions, activePatient, takeId).forEach((assay, idx) => {
                    if (idx === 0) dispatch(clearOrderedAssays());
                    const discount = assay.opt && assay.opt.includes(promoCode.type) ? assay.discount : promoCode.discount;
                    if (assay.idParent !== takeId) {
                        dispatch(addAssayToOrderList(assay.id, +assay.price - (+assay.price * (+discount/100)), () => {
                        }, assay.type))
                    }
                });
                if (state.checkedC) handleChange({target: {name: 'checkedC', checked: true}});
                setIsUsedPromo(true);
            }
        } else if (promoCode && promoCode.type === 'none' && isUsedPromo) {
            handleDeactivatePromo();
        }
    }, [promoCode, isUsedPromo, orderingList, dispatch, state, takeId, value, catalog, complexResearch, actions]);

    useEffect(() => {
        if (ref.current && !patientTabsRef) {
            dispatch(setPatientTabsRef(ref));
        }
        return () => {
            if (patientTabsRef) {
                dispatch(setPatientTabsRef(null));
            }
        }
    }, [ref.current, patientTabsRef])

    return(<div className={cl.orderTermsWrapper}>
        <div className={cl.orderTerms} ref={ref} style={isMedis ? {justifyContent: 'flex-start'} : {justifyContent: 'space-between'}}>
            <div className={cl.orderTermsSmallBox}>
                {isMedis ? (
                    <FormGroup>
                        <StyledFormControlLabel control={<Checkbox checked={state.checkedA} onChange={handleChange}
                                                                   name="checkedA" color="primary"/>}
                                                label={<CheckboxLabel text={'Я ознайомлений(на) з'}
                                                                      redText={'правилами підготовки до аналізів'}
                                                                      link={'https://www.medis.com.ua/pidhotovka_do_analiziv'}/>}

                        />
                        <StyledFormControlLabel control={<Checkbox checked={state.checkedB} onChange={handleChange}
                                                                   name="checkedB" color="primary"/>}
                                                label={<CheckboxLabel text={'Я погоджуюсь на використання'}
                                                                      redText={'персональних даних'}
                                                                      link={Pdf}/>}
                        />
                    </FormGroup>
                ) : (
                    <FormGroup>
                        <StyledFormControlLabel control={<Checkbox checked={state.checkedA} onChange={handleChange}
                                                                   name="checkedA" color="primary"/>}
                                                label={<CheckboxLabel text={'Я згоден(на) на обробку персональних данних та'}
                                                                      redText={'ознайомлений(на) з умовами користувацької угоди'}
                                                                      link={'https://esculab.com/public_contract_esculab.pdf'}/>}
                        />
                    </FormGroup>
                )}
            </div>
            {isMedis && (<div className={cl.orderTermsSmallBox}>
                <FormGroup>
                    <StyledFormControlLabelTake control={<Checkbox checked={state.checkedC} onChange={handleChange}
                                                         name="checkedC" color="primary"/>}
                                      label={<CheckboxLabel text={'Забір біоматеріалу не потрібен'} warningIcon={true}
                                                            toolTipText={'Якщо ви принесете із собою біоматеріал або у випадку дозамовлення'}/>}
                    />

                </FormGroup>
                <p className={cl.orderTermsTextFieldLabel}>Я маю промокод</p>
                    <TooltipMedis text={'Ви активували промокод.'} heading={'ДЯКУЄМО!'}
                                  open={!!promoCode && promoCode.type !== 'none'}>
                        <StyledTextField
                            variant="outlined"
                            placeholder="Введіть промокод"
                            value={value}
                            onChange={handleChangePromoCode}
                            InputProps={{
                                endAdornment: promoCode && promoCode.type !== 'none'
                                    ? <React.Fragment><Btt_radio/></React.Fragment>
                                    : <React.Fragment/>,
                            }}
                        />
                    </TooltipMedis>
            </div>)}
            <div className={cl.orderTermsSmallBox}>
                <div className={cl.orderTermsOverview}>
                    <p>Сума:</p>
                    {sum
                        ? convertPrice(sum, 0, cl.onlinePriceOverview)
                        : <p className={cl.onlinePriceOverview}> 0<sup>00</sup>&#8372;</p>
                    }
                </div>
                <div className={cl.orderTermsOverview}>
                    <p>{isAdmin ? "Знижка при сплаті онлайн (АДМІН):" : "Знижка при сплаті онлайн:"}</p>
                    {promoCode && promoCode.type !== 'none'
                        ? <p className={cl.onlinePriceOverview}> 0<sup>00</sup>&#8372;</p>
                        : discount
                    }
                </div>
                <div className={cl.orderTermsOverview}>
                    <p>Знижка за промокодом:</p>
                    {promoCode && promoCode.type !== 'none'
                        ? convertPrice((+sum) - (+totalPrice), 0, cl.onlinePriceOverview)
                        : <p className={cl.onlinePriceOverview}> 0<sup>00</sup>&#8372;</p>
                    }
                </div>
            </div>
            </div>
    </div>);
};

export default OrderTerms;
