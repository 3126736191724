import React from 'react';
import {Dialog, makeStyles} from "@material-ui/core";
import warningImg from './img/warning.png'
import {SwipeStyledButton} from "@cabinet-packages/components";


const useStyles = makeStyles(theme => ({
    root: {
        // width: '100%',
        // maxWidth: 580,
        // borderRadius: '12px 12px 10px 10px',
        // minHeight: 405,
        // background: 'red',
    '& .MuiDialog-paperWidthSm': {
        borderRadius: '12px 12px 10px 10px',
        minHeight: 405,
        maxWidth: 580,
    },

        // height: '100%',
        // width: '100%',
        // minHeight: '50vh',
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        '& img': {
            height: 92,
            margin: '40px auto 50px auto',
            display: 'block',
        },

    },
    rootWrapper: {
        // width: '100%',
        // maxWidth: 580,
        // borderRadius: '12px 12px 10px 10px',
        minHeight: 405,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    labelWrapper: {
        background: theme.palette.colors.text,
        color: '#ffffff',
        height: 50,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.25rem',
        fontFamily: "'OpenSans-Regular'",
        fontWeight: 'bold',
        width: '100%',
    },
    text: {
        fontFamily: "'OpenSans-Regular'",
        fontWeight: 'bold',
        fontSize: '1rem',
        color: theme.palette.colors.text,
        maxWidth: 343,
        textAlign: 'center',
    },
    wrapperButton: {
        maxWidth: 460,
        marginBottom: '1.875rem',
        marginTop: 'auto',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: '1.25rem',
        '& button': {
            fontWeight: 'bold',
        }
    },
}));

const WarningDialog = ({ handleClose, label, text, handleButton , history , textTwo, setActiveFilterButton}) => {
    const cl = useStyles();

    const handleYes = () => {
        history.push(handleButton)
        handleClose();
    }

    const handleNow = () => {
        handleClose();
        setActiveFilterButton(0)
    }


    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
            classes={{
                root: cl.root
            }}
        >
            <div className={cl.rootWrapper}>
                {/*<Paper elevation={3} classes={{*/}
                {/*    root: cl.rootWrapper*/}
                {/*}}>*/}

                    {label && <div className={cl.labelWrapper}>
                        <p>{label}</p>
                    </div>}
                    <img src={warningImg} alt="warningImg"/>
                    {text && <p className={cl.text}>{text}</p>}
                {textTwo && <p className={cl.text}>{textTwo}</p>}
                    <div className={cl.wrapperButton}>
                        <SwipeStyledButton
                            animationStart={'right'}
                            animationMaterialColor={'#E41E15'}
                            buttonMaterialColor={'#ffffff'}
                            hoverTextColor={'#ffffff'}
                            border={'1px solid #363D4D'}
                            onClick={() => handleNow()}
                        >
                            Скасувати
                        </SwipeStyledButton>
                        <SwipeStyledButton
                            animationStart={'left'}
                            onClick={() => handleYes()}
                        >
                            так
                        </SwipeStyledButton>
                    </div>
                {/*</Paper>*/}
            </div>
        </Dialog>
    )
}

export default WarningDialog