import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    addSelectedPunkt,
    getPunkts,
    getSchedule,
    loginClient,
    setAssayTime,
    setTurnBack
} from "@cabinet-packages/redux";
import {Table, TableContainer, TableHead, TableRow} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import {currentWeek, getCorrectDay, isDateInPresentWeek} from "@cabinet-packages/helpers";
import TextField from "@material-ui/core/TextField";
import {useHistory} from "react-router";
import Loading from "../Shared/Loading";
import CustomDialog from "../Dialogs/CustomDialog";
import ConfirmDialog from "../Dialogs/ConfirmDialog";

const useStyles = makeStyles(theme => ({
    mainContainer: {
        width: '100%',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    mainWrapper: {
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    formWrapper: {
        display: 'block',
        height: '48px',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: 288,
        boxSizing: 'border-box',
        margin: '0 auto',
    },
    formButton: {
        marginTop: '5px',
    },
    tableContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '5px',
    },
    table: {
        maxWidth: 850,
        border: '1px solid rgba(0, 0, 0, 0.3)',
    },
    tableRow: {
        textAlign: 'center',
    },
    tableCellDetails: {
        width: '20%',
        minWidth: 'max-content',
        textAlign: 'center',
        border: '1px solid rgba(0, 0, 0, 0.7)',
        height: '20px',
        lineHeight: '20px',
        whiteSpace: 'nowrap',
    },
    tableHeadCell: {
        textAlign: 'center',
        border: '1px solid rgba(0, 0, 0, 0.7)',
        width: '10%',
        height: '20px',
    },
    tableHeadCellDate: {
        fontSize: '10px',
        display: 'block',
    },
    tableCell: {
        border: '1px solid rgba(0, 0, 0, 0.3)',
        textAlign: 'center',
        transition  : '0.5s',
        height: '30px',
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(0, 0, 0, 0.5)',
            transition: '0.5s',
            cursor: 'pointer',
        },
    },
    tableCellDisabled: {
        border: '1px solid rgba(0, 0, 0, 0.5)',
        textAlign: 'center',
        background: 'rgba(0, 0, 0, 0.3)',
    },
    tableCellHighlighted: {
        cursor: 'pointer',
        border: '1px solid #0077c8',
        textAlign: 'center',
        background: theme.palette.primary.cell,
        transition: '0.5s',
    },
    tableLoaderRow: {
       height: '50px',
    },
    tableLoaderCell: {
        height: '60px',
        textAlign: 'center',
    },
}));

const AssaySchedulerWeek = ({isComponentInShop, themeMaterialUI}) => {
    const classes = useStyles();
    const history = useHistory();
    const { selectedPunkt, turnBack, time } = useSelector(state => state.orderAssays);
    const { punkts } = useSelector(state => state.collectionPoints);
    const { schedule } = useSelector(state => state.scheduleOfOrders);
    const { activePatient, stage } = useSelector(store => store.patients);
    const [selectedPunktSchedule, setSelectedPunktSchedule] = useState(null);
    const [selectedPunktID, setSelectedPunktID] = useState(null);
    const [openPunktSchedule, setOpenPunktSchedule] = useState(null);
    const [refactoredSchedule, setRefactoredSchedule] = useState(null);
    const [calendarPickedDate, setCalendarPickedDate] = useState(null);
    const [isUserSelectDay, setIsUserSelectDay] = useState(false);
    const [selectedTime, setSelectedTime] = useState(null);
    const [handleTransition, setHandleTransition] = useState(false);
    const dispatch = useDispatch();
    const currentDate = new Date();
    const ref = useRef(null);

    const daysOfWeekFromCurrentDay = () => {
        let arrayOfDays = [
            {
                dayUk: 'Пн',
                dayEn: "mon",
            },
            {
                dayUk: 'Вт',
                dayEn: "tue",
            },
            {
                dayUk: 'Ср',
                dayEn: "wen",
            },
            {
                dayUk: 'Чт',
                dayEn: "ths",
            },
            {
                dayUk: 'Пт',
                dayEn: "fry",
            },
            {
                dayUk: 'Сб',
                dayEn: "sat",
            },
            {
                dayUk: 'Нд',
                dayEn: "sun",
            },
        ];

        const currentDayNumber = isUserSelectDay ? 0 : currentDate.getDay()-1;
        const weekDays = currentWeek(calendarPickedDate, isUserSelectDay);

        if (currentDayNumber !== 0) {
            arrayOfDays = [...arrayOfDays.slice(currentDayNumber), ...arrayOfDays.slice(0, currentDayNumber)]
        }

        return arrayOfDays.map((cur, index) => {
            const [, month, day] = weekDays[index].split('-');
            return {
               ...cur,
               date: `${day}/${month}`,
           };
        });
    };

    const getOpenScheduleForSelectedPunkt = useCallback((punkt) => {
        setOpenPunktSchedule(schedule.reduce((acu, cur) => {
                if (isDateInPresentWeek(calendarPickedDate, cur.dt, isUserSelectDay)
                    && (+cur.idPunkt === +punkt.idPunkt)) {
                    return [...acu, cur];
                }
                return acu;
            }, []));
    },[calendarPickedDate, isUserSelectDay, schedule]);

    const handleTransitionToConfirmOrderPage = () => {
        isComponentInShop
            ? activePatient ? history.push('/shop-confirm-order') : dispatch(loginClient(themeMaterialUI))
            : history.push('/confirm-order');
        dispatch(setAssayTime({...selectedTime, from: selectedTime.from.replace(".", ":")}));
        if (turnBack) dispatch(setTurnBack(false));
    };

    const setPickedDateOnChange = (event) => {
        const yearAndWeek = event.target.value.split('-W');
        const date = new Date(+yearAndWeek[0], 0, ((+yearAndWeek[1] -1)*7));
        date.setDate(date.getDate() - date.getDay() + 1);
        setCalendarPickedDate(date);
        setOpenPunktSchedule(null);
        setRefactoredSchedule(null);
        setIsUserSelectDay(true);
        setSelectedTime(null);
    };

    const handleClickOnTable = (cur, day, index) => {
        if (!cur[day.dayEn] || cur[day.dayEn].patients > 2) return;
        const pickedDate = new Date(calendarPickedDate);
        if (isUserSelectDay) {
            pickedDate.setDate(calendarPickedDate.getDate() + index + 1)
        } else {
            pickedDate.setDate(calendarPickedDate.getDate() + index);
        }
        if (selectedTime
            && selectedTime.date === pickedDate.toISOString().slice(0, 10)
            && selectedTime.from === cur[day.dayEn].from)
        {
            setSelectedTime(null);
            return;
        }

        setSelectedTime({
            from: cur[day.dayEn].from,
            to: cur[day.dayEn].to,
            date: pickedDate.toISOString().slice(0, 10),
            day,
        });

        dispatch(setAssayTime({to: cur[day.dayEn].to, from: cur[day.dayEn].from.replace(".", ":"), date: pickedDate.toISOString().slice(0, 10)}));

        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
        setHandleTransition(true);
    };

    const getRefactoredSchedule = useCallback(() => {
        const days = ["mon", "tue", "wen", "ths", "fry", "sat", "sun" ];
        const schedule = days.reduce((acu, cur, idx) => {
            const scheduledArray = openPunktSchedule.reduce((accumulatorScheduledDates, openSchedule) => {
                const scheduledDate = openSchedule.dt;
                const numDay = getCorrectDay(scheduledDate);
                if (numDay === idx) {
                    return [
                        ...accumulatorScheduledDates,
                        parseFloat(scheduledDate.split(' ')[1].replace(":", "."))
                    ];
                }

                return accumulatorScheduledDates;
            }, []);

            if (selectedPunktSchedule.length) {
                selectedPunktSchedule[idx][cur].sceduler.map((schedule, index) => {
                    const refactoredSchedule = {
                        from: schedule.split('-')[0].replace(":", "."),
                        to: schedule.split('-')[1].replace(":", "."),
                        patients: 0,
                    };
                    const from = acu[index] && acu[index].from < refactoredSchedule.from ? acu[index].from : parseFloat(refactoredSchedule.from);
                    const to = acu[index] && acu[index].to < refactoredSchedule.to ? acu[index].to : parseFloat(refactoredSchedule.to);

                    const correctShchedule = (indexInFunc) => {
                        if (acu[indexInFunc].from < refactoredSchedule.from) {
                            return correctShchedule(indexInFunc+1);
                        }
                        acu[indexInFunc] = {
                            ...acu[indexInFunc],
                            [cur]: refactoredSchedule,
                        };
                    };

                    if (acu[index] && acu[index].from < refactoredSchedule.from) {
                        return correctShchedule(index);
                    }

                    if (!scheduledArray.find(time => time >= parseFloat(refactoredSchedule.from)
                        && time <= parseFloat(refactoredSchedule.to))) {
                        return acu[index] = {
                            ...acu[index],
                            [cur]: refactoredSchedule,
                            from,
                            to,
                        };
                    } else {
                        const countOfPatients = scheduledArray.reduce((accumulatorPatients, curTime) => {
                            if (curTime >= parseFloat(refactoredSchedule.from)
                                && curTime <= parseFloat(refactoredSchedule.to)) {

                                return accumulatorPatients + 1;
                            }

                            return accumulatorPatients;
                        }, 0);

                        return acu[index] = {
                            ...acu[index],
                            [cur]: {
                                ...refactoredSchedule,
                                patients: countOfPatients,
                            },
                            from,
                            to,
                        };
                    }
                });
            }
            return acu;
        }, []);

        return schedule.reduce((acu, cur) => {
            selectedPunkt.takingHours.forEach((item, idx) => {
                if (cur[days[idx]] && item[days[idx]].from > cur.from) delete cur[days[idx]]
                if (cur[days[idx]] && item[days[idx]].to < cur.to) delete cur[days[idx]]
            });
            return [...acu, cur];
        }, [])
    }, [openPunktSchedule, selectedPunktSchedule, selectedPunkt.takingHours]);

    useEffect(() => {
        if (!punkts) {
            dispatch(getPunkts());
        }
        if (!selectedPunkt && punkts) {
            dispatch(addSelectedPunkt(punkts[0]));
        }
        if (selectedPunkt) {
            if (selectedPunkt.idPunkt !== selectedPunktID) {
                setSelectedPunktID(selectedPunkt.idPunkt);
                setOpenPunktSchedule(null);
                setRefactoredSchedule(null);
            }
            if (!selectedPunktSchedule || selectedPunkt.idPunkt !== selectedPunktID) {
                setSelectedPunktSchedule(selectedPunkt.workingHours);
            }
            if (!openPunktSchedule && selectedPunkt && schedule && calendarPickedDate) {
                getOpenScheduleForSelectedPunkt(selectedPunkt);
            }
            if (openPunktSchedule && selectedPunktSchedule && !refactoredSchedule) {
                setRefactoredSchedule(getRefactoredSchedule());
            }
        }
        if (!calendarPickedDate) {
            setCalendarPickedDate(currentDate);
        }
        if (stage === 6) {
            history.push('/shop-confirm-order');
        }
    }, [selectedPunktSchedule, selectedPunkt, openPunktSchedule,
            punkts, refactoredSchedule, schedule, calendarPickedDate,
            selectedPunktID, currentDate, dispatch, getOpenScheduleForSelectedPunkt,
            getRefactoredSchedule, stage, history]
    );

    useEffect(() => {
        if (!schedule) {
            dispatch(getSchedule());
        }
    });

    useEffect(() => {
        if (handleTransition && time) {
            CustomDialog(ConfirmDialog,
                {text: `Ви обрали запис на ${time} за адресою: ${selectedPunkt.address}. Підтвердити вибраний час?`,
                    onConfirm:  () => {
                        handleTransitionToConfirmOrderPage();
                    }}, false,
                themeMaterialUI);
            setHandleTransition(false);
        }
    }, [handleTransition, time])

    return (
        <div className={classes.mainContainer}>
            <div className={classes.mainWrapper}>
                <div className={classes.formWrapper}>
                    <form className={classes.formContainer} noValidate ref={ref}>
                        <TextField
                            id="week"
                            label="Виберіть тиждень"
                            type="week"
                            lang="enUS"
                            className={classes.textField}
                            onChange={(event) =>
                                    setPickedDateOnChange(event)
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </form>
                </div>
                <TableContainer className={classes.tableContainer}>
                    <Table className={classes.table} padding="none" stickyHeader>
                        <TableHead>
                            <TableRow className={classes.tableRow}>
                                <TableCell className={classes.tableCellDetails}>
                                    День тижня
                                    <hr/>
                                    Час запису
                                </TableCell>
                                {daysOfWeekFromCurrentDay().map((cur, index) => (
                                    <TableCell key={cur+index} className={classes.tableHeadCell}>
                                        <span className={classes.tableHeadCellDate}>{cur.date}</span><hr/>{cur.dayUk}
                                    </TableCell>)
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {refactoredSchedule && refactoredSchedule.length > 0
                                ? refactoredSchedule.map((cur, index) => {
                                    const days = daysOfWeekFromCurrentDay();

                                    return (
                                        <TableRow key={index} className={classes.tableRow}>
                                            <TableCell className={classes.tableCellDetails}>
                                                {`${cur.from.toFixed(2)} - ${cur.to.toFixed(2)}`}
                                            </TableCell>
                                            {days.map((day, index) => (
                                                <TableCell
                                                    key={day+index}
                                                    className={
                                                        cur[day.dayEn] && cur[day.dayEn].patients < 3
                                                               ?  selectedTime
                                                                    && selectedTime.from && selectedTime.from === cur[day.dayEn].from
                                                                    && selectedTime.day.dayEn === day.dayEn
                                                                        ? classes.tableCellHighlighted
                                                                        : classes.tableCell
                                                               : classes.tableCellDisabled
                                                    }
                                                    onClick={() => {
                                                        handleClickOnTable(cur, day, index)
                                                    }}
                                                />
                                            ))}
                                        </TableRow>)})
                                : !openPunktSchedule
                                    ? <TableRow className={classes.tableLoaderRow}>
                                            <TableCell
                                                className={classes.tableLoaderCell}
                                                colSpan={8}
                                            >
                                                <Loading/>
                                            </TableCell>
                                    </TableRow>
                                    : <TableRow className={classes.tableLoaderRow}>
                                        <TableCell
                                            className={classes.tableLoaderCell}
                                            colSpan={8}
                                        >
                                            Вільні години відсутні
                                        </TableCell>
                                    </TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>);
};

export default AssaySchedulerWeek;
