import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Accordion, AccordionSummary, Avatar, Button, makeStyles} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import AssayTable from './AssayTable';
import {
    ButtonWithLoader,
    Current,
    CustomDialog,
    Loading,
    LoadOrderResultDialog,
    Scroll
} from '@cabinet-packages/components';
import {addAssayToOrderList, getCatalog, selectActivePatientResults} from "@cabinet-packages/redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {LocationOn} from "@material-ui/icons";
import {getAssay} from "@cabinet-packages/api/api";

const useStyles = makeStyles(theme =>({
  summaryContainer: {
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: '2fr 1fr 2fr 1fr 1fr 1fr',
    width: '80%',
    alignItems: 'end',
    '@media (max-width:900px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto auto',
    },
  },
  summaryContainerNotReady: {
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: '2fr 1fr 2fr 1fr 1fr',
    width: '100%',
    alignItems: 'end',
    '@media (max-width:900px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto auto',
    },
  },
  buttonWithLoader: {
    minWidth: '110px',
  },
  buttonWithOpen: {
    minWidth: '75px',
  },
  tablesContainer: {
    margin: '20px 0',
    boxShadow: '0 0 1px 1px rgba(0, 0, 0, 0.1)',
    zIndex: '100',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 6px',
  },
  centerWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  rootContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr auto',
    justifyContent: 'center',
  },
  resultsContainer: {
    padding: '10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    '@media (max-width:900px)': {
      padding: '2px',
    },
  },
  avatarWrapper: {
    display: 'none',

    '@media (max-width: 900px)': {
      display: 'block',
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  noPatient: {
    paddingTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    textAlign: 'center',
  },
  noResults: {
    textAlign: 'center'
  },
  error: {
    color: 'red'
  },
}));

function PatientResults() {
  const history = useHistory();
  const classes = useStyles();
  const {catalog, complexResearch, takeId, complexId}  = useSelector(state => state.assaysCatalog);
  const { activePatientResults, activePatient, listOfPatients } = useSelector(state => state.patients);
  const {themeMaterialUI} = useSelector(store => store.theme);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const isMedis = process.env.REACT_APP_CURRENT_CLINI === 'MEDIS';

  function handleClick(event, bool, idOrder) {
    event.stopPropagation();
    event.preventDefault();

    setExpanded(prevState => ({...prevState, [idOrder]: bool}));
  }

  useEffect(() => {
    if (!activePatientResults && listOfPatients) {
      dispatch(selectActivePatientResults());
    }
    if (activePatientResults && typeof activePatientResults !== "string" && !expanded) {
      setExpanded(activePatientResults.reduce((acu, cur, idx) => {
        if (idx === 0) return ({...acu, [cur.idOrder]: true})
        return {...acu, [cur.idOrder]: false};
      }, {}))
    }
  }, [dispatch, activePatientResults, listOfPatients, expanded]);

  useEffect(() => {
    if (!catalog && takeId && complexId) {
      dispatch(getCatalog())
    }
  }, [catalog, takeId, complexId]);

  const sendOrder = (event, idOrder, idClient, open) => {
    event.preventDefault();
    event.stopPropagation();

    CustomDialog(LoadOrderResultDialog,
        {open,
          activePatient: listOfPatients
              .find(patient => patient.id === idClient),
          idOrder,
          idClient,
          themeMaterialUI
        },
        false,
        themeMaterialUI);
  };

  const handleRepeatOrder = async (e, orderId) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(orderId);
    setError(null);

    try {
      const {data} = await getAssay(orderId);

      const foundedAssays = data.reduce((acu, cur) => {
        let foundedAssay;
        if (cur.complex) {
          foundedAssay = complexResearch.find(item => +item.id === +cur.id && item.code === cur.code);
        } else {
          foundedAssay = catalog.find(item => +item.id === +cur.id && item.code === cur.code);
        }

        if (foundedAssay) {
          return [...acu, foundedAssay];
        } else {
          return acu;
        }
      }, []);

      if (foundedAssays && foundedAssays.length > 0) {
        foundedAssays.forEach(item => {
          dispatch(addAssayToOrderList(
              item.id,
              item.discount
                  ? item.price - item.price * item.discount / 100
                  : item.price,
              () => {
              },
              item.type
          ))
        })
        history.push('/order-manager');
      } else {
        setError(orderId);
      }
    } catch (e) {
      console.error(e);
      setError(orderId);
    }

    setLoading(null);
  };

  return (
    <div className={classes.rootContainer}>
      {activePatientResults && expanded && activePatientResults.length > 0
        ?
          <Scroll>
            <div className={classes.resultsContainer}>
              {listOfPatients && activePatientResults
                  .filter(item => {
                    if (item.idOrder > 2000000 && +item.ready === 0) {
                      return true;
                    } else {
                      return +item.ready !== 0;
                    }
                  }).length === 0 && <p className={classes.noResults}>У вас ще немає готових результатів.</p>
              }
              {listOfPatients && activePatientResults
                  .filter(item => {
                    if (item.idOrder > 2000000 && +item.ready === 0) {
                      return true;
                    } else {
                      return +item.ready !== 0;
                    }
                  })
                  .map(( {birthday, idOrder, address, results, idClient, dt, ready} , index) => {
                const patient = listOfPatients.find(patient => patient.id === idClient);

                return (+ready !== 0
                  ? <Accordion
                      square
                      defaultExpanded={index === 0}
                      key={idOrder}
                      onChange={(event, bool) => handleClick(event, bool, idOrder)}
                      expanded={expanded[idOrder]}
                  >
                    {patient && <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                      <div className={classes.summaryContainer}>
                        <div>
                          <div className={classes.avatarWrapper}>
                            <Avatar className={classes.avatar}>
                              {`${patient.lastname[0]} ${patient.firstname[0]}`}
                            </Avatar>
                          </div>
                          Час здачі: {birthday ? birthday : dt ? dt : ''}
                          <div><strong>{`Пацієнт: ${patient.lastname} ${patient.firstname}`}</strong></div>
                          <div>{`Дата народження: ${patient.birthday}`}</div>
                        </div>
                        <div>ID: {idOrder}</div>
                        <div><LocationOn color={"primary"}/>{address}</div>
                        <ButtonWithLoader
                            onClick={(event) => sendOrder(event, idOrder, idClient, true)}
                            loading={false}
                            variant="contained"
                            size="small"

                            color="primary"
                            className={classes.buttonWithLoader}
                        >
                          Завантажити
                        </ButtonWithLoader>
                        <ButtonWithLoader
                            onClick={(event) => sendOrder(event, idOrder, idClient, false)}
                            loading={false}
                            variant="contained"
                            size="small"
                            color="primary"
                            className={classes.buttonWithOpen}
                        >
                          Відкрити
                        </ButtonWithLoader>
                        {!isMedis && <div>
                          {catalog && complexResearch && (!loading || +loading !== idOrder)
                              ? <Button
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  onClick={(e) => handleRepeatOrder(e, idOrder)}
                              >
                                повторити замовлення
                              </Button>
                              : <Loading/>
                          }
                          {error && +error === +idOrder && <p className={classes.error}>Вибачте, виникла поммилка.</p>}
                        </div>}
                      </div>
                    </AccordionSummary>}
                    <div className={classes.tablesContainer}>
                      {<AssayTable                  /////////////////////////////////////////results here
                          id={idOrder}
                          results={results
                              ? results
                              : null
                          }
                          patientId={idClient}
                          isExpanded={expanded[idOrder]}
                      />}
                    </div>
                  </Accordion>
                  : <Accordion
                      square
                      defaultExpanded={index === 0}
                      key={idOrder}
                      expanded={false}
                  >
                    {patient && <AccordionSummary>
                      <div className={classes.summaryContainerNotReady}>
                        <div>
                          <div className={classes.avatarWrapper}>
                            <Avatar
                                className={classes.avatar}>{`${patient.lastname[0]} ${patient.firstname[0]}`}
                            </Avatar>
                          </div>
                          Час здачі: {birthday ? birthday : dt ? dt : ''}
                          <div><strong>{`Пацієнт: ${patient.lastname} ${patient.firstname}`}</strong></div>
                          <div>{`Дата народження: ${patient.birthday}`}</div>
                        </div>
                        <div>ID: {idOrder}</div>
                        <div><LocationOn color={"primary"}/>{address}</div>
                        <div><strong>Результати по даному замовленню ще не готові.</strong></div>
                        <div>
                          {catalog && complexResearch && (!loading || +loading !== idOrder)
                              ? <Button
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  onClick={(e) => handleRepeatOrder(e, idOrder)}
                              >
                                повторити замовлення
                              </Button>
                              : <Loading/>
                          }
                          {error && +error === +idOrder && <p className={classes.error}>Вибачте, виникла поммилка.</p>}
                        </div>
                      </div>
                    </AccordionSummary>}
                          <div/>
                  </Accordion>
                )
              })}
            </div>
          </Scroll>
        : activePatientResults && activePatientResults.length === 0 && typeof listOfPatients !== 'string'
          ?
            <div className={classes.noPatient}>
              <div>
                Немає поточних досліджень для
                {activePatient && <strong>
                  {` ${activePatient.lastname}
                     ${activePatient.firstname}
                     ${activePatient.fathername}.`}
                </strong>}
              </div>
              <div>
                <Button onClick={() => history.push('/account-settings')} variant="outlined">
                  Налаштування
                </Button>
              </div>
            </div>
          : typeof activePatientResults === "string"
            ? <div className={classes.noPatient}>Вибачте, сталась помилка під час завантаження.</div>
            : <div className={classes.noPatient}>
              <Loading/>
            </div>}
      <Current/>
    </div>
  );
}

export default PatientResults;
