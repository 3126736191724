import React, {useCallback, useEffect, useRef, useState} from "react";
import useStyles from "./styles";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import 'moment/locale/uk';
import {getSchedule, setAssayTime} from "@cabinet-packages/redux";
import MonthView from "./components/MonthView";
import HoursView from "./components/HoursView";
import Explanations from "./components/Explanations";
import {getScheduleByIdPunkt} from "@cabinet-packages/api/api";
import {useHistory} from "react-router";
import {Loading} from "@cabinet-packages/components";

const ukMonths = ["січень", "лютий", "березень", "квітень", "травень", "червень",
    "липень", "серпень", "вересень", "жовтень", "листопад", "грудень"];

moment.locale('uk');

const CalendarKids = () => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const { schedule } = useSelector(state => state.scheduleOfOrders);
    const { selectedPunkt, time, timeLine } = useSelector(state => state.orderAssays);
    const history = useHistory();
    const [scheduleForSelectedPunkt, setScheduleForSelectedPunkt] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(moment().format('MMMM'));
    const [monthDays, setMonthDays] = useState(null);
    const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'));
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [checked, setChecked] = useState(false);
    const ref = useRef();

    const handleChange = (event) => {
        // setChecked(event.target.checked);
        // if (event.target.checked) {
        //     dispatch(setAssayLine({date: 'Ви не обрали час забору.'}));
        //     setSelectedDay(null);
        //     setSelectedTime(null);
        // } else {
        //     dispatch(setAssayLine());
        //
        //     const foundFirstFreeDay = monthDays.flat(1).find(day => day.freeHours && day.current);
        //
        //     setSelectedDay(foundFirstFreeDay || null);
        // }
    };

    const handleChangeMonth = (e, isBack, dontClearDay = true) => {
        e.stopPropagation();
        e.preventDefault();

        const idxOfSelectedMonth = ukMonths.indexOf(selectedMonth);
        const lastMonthNumber = ukMonths.length - 1;

        setSelectedTime(null);
        dispatch(setAssayTime());

        if (!dontClearDay) {
            setSelectedDay(null);
        }

        if (isBack) {
            if (idxOfSelectedMonth === 0) {
                setSelectedMonth(ukMonths[lastMonthNumber]);
                setSelectedYear(''+(+selectedYear-1));
            } else {
                setSelectedMonth(ukMonths[idxOfSelectedMonth-1]);
            }
        } else {
            if (idxOfSelectedMonth === lastMonthNumber) {
                setSelectedMonth(ukMonths[0]);
                setSelectedYear((+selectedYear+1)+'');
            } else {
                setSelectedMonth(ukMonths[idxOfSelectedMonth+1]);
            }
        }
    }

    useEffect(() => {
        dispatch(getSchedule());
    }, []);

    const handleSchedule = useCallback(() => {
        if (schedule && selectedPunkt) {
            setScheduleForSelectedPunkt(schedule.filter(item => item.idPunkt === selectedPunkt.idPunkt));
        }
    }, [schedule, selectedPunkt]);

    const handleMonthDays = useCallback(async () => {
        if (selectedYear && selectedMonth && scheduleForSelectedPunkt && selectedPunkt && selectedPunkt.takingHours && selectedPunkt.takingHours.length > 0) {
            const data = await getScheduleByIdPunkt(selectedPunkt.idPunkt);

            const monthFirstDay = moment().year(+selectedYear).month(selectedMonth).startOf('month').weekday();
            const lengthOfMonth = moment().year(+selectedYear).month(selectedMonth).endOf('month').date();
            const monthLastDay = moment().year(+selectedYear).month(selectedMonth).endOf('month').weekday();

            const arrayOfDays = [];
            for (let i = 0; i < monthFirstDay; i++) {//prev month days
                const year = ukMonths.indexOf(selectedMonth.toLowerCase()) === 0
                    ? +selectedYear - 1
                    : +selectedYear;

                const prevMonth = ukMonths.indexOf(selectedMonth.toLowerCase()) === 0
                    ? ukMonths[11].toLowerCase()
                    : ukMonths[ukMonths.indexOf(selectedMonth.toLowerCase()) - 1].toLowerCase();

                const dayOfPrevMonth = moment()
                    .year(+year)
                    .month(selectedMonth).subtract(1, 'months').endOf('month')
                    .subtract(monthFirstDay - (i + 1), 'days').date();

                const hours = Object.keys(selectedPunkt.takingHours[i])
                    .map(item => selectedPunkt.takingHours[i][item])[0];

                const hoursSchedule = hours.sceduler.map(item => {
                    const free = scheduleForSelectedPunkt.reduce((acu, cur) => {
                        const reservedDate = moment(cur.dt, 'DD.MM.YYYY HH:mm');

                        const isFree = (reservedDate.format('MMMM').toLowerCase() === moment(prevMonth, 'MMMM').format('MMMM').toLowerCase())
                            && +reservedDate.format('YYYY') === +year
                            && +reservedDate.format('D') === +dayOfPrevMonth
                            && reservedDate.format('HH:mm') === item;

                        if (isFree) {
                            return acu + 1;
                        } else {
                            return acu;
                        }

                    }, 0);

                    const splitTime = item.split(':');

                    const isPastDay = moment().add(1, 'h').isBefore(
                        moment()
                            .set('year', +year)
                            .set('month', ukMonths.indexOf(prevMonth))
                            .set("date", +dayOfPrevMonth)
                            .set('hour', +splitTime[0])
                            .set('minute', +splitTime[1])
                    );

                    return ({
                        time: item,
                        free: (free < selectedPunkt.patientCount+1) && isPastDay
                    })
                });

                arrayOfDays.push({dayNum: dayOfPrevMonth,
                    current: false,
                    prev: true,
                    freeHours: hoursSchedule.reduce((acu, cur) => {
                        if (acu) {
                            return acu;
                        } else {
                            return cur.free;
                        }
                    }, false),
                    scheduler: hoursSchedule,
                });
            }
            for (let i = 1; i <= lengthOfMonth; i++) {//current month days
                const hours = Object.keys(selectedPunkt.takingHours[(monthFirstDay+i-1)%7])
                    .map(item => selectedPunkt.takingHours[(monthFirstDay+i-1)%7][item])[0];

                const hoursSchedule = hours.sceduler.map(item => {
                    const free = scheduleForSelectedPunkt.reduce((acu, cur) => {
                        const reservedDate = moment(cur.dt, 'DD.MM.YYYY HH:mm');

                        const isFree = (reservedDate.format('MMMM').toLowerCase() === selectedMonth.toLowerCase())
                            && +reservedDate.format('YYYY') === +selectedYear
                            && +reservedDate.format('D') === +i
                            && reservedDate.format('HH:mm') === item;

                        if (isFree) {
                            return acu + 1;
                        } else {
                            return acu;
                        }
                    }, 0);

                    const splitTime = item.split(':');

                    const isPastDay = moment().add(1, 'h').isBefore(
                        moment()
                            .set('year', +selectedYear)
                            .set('month', ukMonths.indexOf(selectedMonth))
                            .set("date", +i)
                            .set('hour', +splitTime[0])
                            .set('minute', +splitTime[1])
                    );

                    return ({
                        time: item,
                        free: (free < selectedPunkt.patientCount+1) && isPastDay
                    })
                });

                arrayOfDays.push({dayNum: i,
                    current: true,
                    freeHours: hoursSchedule.reduce((acu, cur) => {
                        if (acu) {
                            return acu;
                        } else {
                            return cur.free;
                        }
                    }, false),
                    scheduler: hoursSchedule,
                });
            }
            for (let i = 1; i < 7 - monthLastDay; i++) {//next month days
                const nextMonth = ukMonths.indexOf(selectedMonth.toLowerCase()) === 11
                    ? ukMonths[0].toLowerCase()
                    : ukMonths[ukMonths.indexOf(selectedMonth.toLowerCase()) + 1].toLowerCase();

                const year = ukMonths.indexOf(selectedMonth.toLowerCase()) === 11
                    ? +selectedYear + 1
                    : selectedYear;

                const hours = Object.keys(selectedPunkt.takingHours[monthLastDay + i])
                    .map(item => selectedPunkt.takingHours[monthLastDay + i][item])[0];

                const hoursSchedule = hours.sceduler.map(item => {
                    const free = scheduleForSelectedPunkt.reduce((acu, cur) => {
                        const reservedDate = moment(cur.dt, 'DD.MM.YYYY HH:mm');

                        const isFree = (reservedDate.format('MMMM').toLowerCase() === moment(nextMonth, 'MMMM').format('MMMM').toLowerCase())
                            && +reservedDate.format('YYYY') === +year
                            && +reservedDate.format('D') === +i
                            && reservedDate.format('HH:mm') === item;

                        if (isFree) {
                            return acu + 1;
                        } else {
                            return acu;
                        }

                    }, 0);

                    const splitTime = item.split(':');

                    const isPastDay = moment().isBefore(
                        moment()
                            .set('year', +year)
                            .set('month', ukMonths.indexOf(nextMonth))
                            .set("date", +i)
                            .set('hour', +splitTime[0])
                            .set('minute', +splitTime[1])
                    );

                    return ({
                        time: item,
                        free: (free < selectedPunkt.patientCount+1) && isPastDay
                    })
                });

                arrayOfDays.push({dayNum: i, current: false, next: true,
                    freeHours: hoursSchedule.reduce((acu, cur) => {
                        if (acu) {
                            return acu;
                        } else {
                            return cur.free;
                        }
                    }, false),
                    scheduler: hoursSchedule
                    });
            }

            const workHoursSchedule = (data[0] && JSON.parse(data[0].workHours)) || [];

            const refactoredArrayOfDays = arrayOfDays
                .map(item => {

                    if (item.prev && item.freeHours && workHoursSchedule) { //prev month
                        const prevMonth = ukMonths.indexOf(selectedMonth.toLowerCase()) === 0
                            ? ukMonths[11].toLowerCase()
                            : ukMonths[ukMonths.indexOf(selectedMonth.toLowerCase()) - 1].toLowerCase();

                        const foundSchedule = workHoursSchedule.find(scheduledTime => (
                            moment().year(+ukMonths.indexOf(selectedMonth.toLowerCase()) === 0 ? +selectedYear-1 : +selectedYear).month(prevMonth).date(item.dayNum).format('YYYY-MM-DD')
                            === scheduledTime.dt_start.slice(0,10)
                        ))

                        if (!foundSchedule) {
                            return ({
                                ...item,
                                freeHours: false,
                                scheduler: item.scheduler.map(day => ({...day, free: false}))
                            })
                        } else {
                            const scheduler = item.scheduler.map((day) => {
                                if (+day.time.slice(0, 2) >= +foundSchedule.dt_start.slice(11,13) && +day.time.slice(0, 2) <= +foundSchedule.dt_end.slice(11,13)) {
                                    return day;
                                } else {
                                    return ({...day, free: false});
                                }
                            })

                            return ({
                                ...item,
                                scheduler,
                                freeHours: scheduler.reduce((acu, cur) => {
                                    if (acu) {
                                        return acu;
                                    } else {
                                        return cur.free;
                                    }
                                }, false),
                            });
                        }
                    } else if (item.next && item.freeHours && workHoursSchedule) { //next month
                        const nextMonth = ukMonths.indexOf(selectedMonth.toLowerCase()) === 11
                            ? ukMonths[0].toLowerCase()
                            : ukMonths[ukMonths.indexOf(selectedMonth.toLowerCase()) + 1].toLowerCase();

                        const foundSchedule = workHoursSchedule.find(scheduledTime => (
                            moment().year(ukMonths.indexOf(selectedMonth.toLowerCase()) === 11 ? +selectedYear+1 : +selectedYear).month(nextMonth).date(item.dayNum).format('YYYY-MM-DD')
                            === scheduledTime.dt_start.slice(0,10)
                        ))

                        if (!foundSchedule) {
                            return ({
                                ...item,
                                freeHours: false,
                                scheduler: item.scheduler.map(day => ({...day, free: false}))
                            })
                        } else {
                            const scheduler = item.scheduler.map((day) => {
                                if (+day.time.slice(0, 2) >= +foundSchedule.dt_start.slice(11,13) && +day.time.slice(0, 2) <= +foundSchedule.dt_end.slice(11,13)) {
                                    return day;
                                } else {
                                    return ({...day, free: false});
                                }
                            })
                            return ({
                                ...item,
                                scheduler,
                                freeHours: scheduler.reduce((acu, cur) => {
                                    if (acu) {
                                        return acu;
                                    } else {
                                        return cur.free;
                                    }
                                }, false),
                            });
                        }
                    } else if (item.current && item.freeHours && workHoursSchedule) { //current month
                        const foundSchedule = workHoursSchedule.find(scheduledTime => {

                            return (moment().year(+selectedYear).month(selectedMonth.toLowerCase()).date(+item.dayNum).format('YYYY-MM-DD')
                                === scheduledTime.dt_start.slice(0, 10)
                            )
                        })

                        if (!foundSchedule) {
                            return ({
                                ...item,
                                freeHours: false,
                                scheduler: item.scheduler.map(day => ({...day, free: false}))
                            })
                        } else {
                            const scheduler = item.scheduler.map((day) => {
                                if (+day.time.slice(0, 2) >= +foundSchedule.dt_start.slice(11,13) && +day.time.slice(0, 2) <= +foundSchedule.dt_end.slice(11,13)) {
                                    return day;
                                } else {
                                    return ({...day, free: false});
                                }
                            })

                            return ({
                                ...item,
                                scheduler,
                                freeHours: scheduler.reduce((acu, cur) => {
                                    if (acu) {
                                        return acu;
                                    } else {
                                        return cur.free;
                                    }
                                }, false),
                            });
                        }
                    } else {
                        return item;
                    }
                });

            setMonthDays(refactoredArrayOfDays
                .reduce((acu, cur, idx) => {
                    if (idx === 0) return [[cur]];
                    if ((idx) % 7 === 0) return [...acu, [cur]];
                    acu[Math.ceil(((idx + 1) / 7) - 1)] = [...acu[Math.ceil(((idx + 1) / 7) - 1)], cur]
                    return acu;
                }, []));
            if (!selectedDay) {
                const foundFirstFreeDay = refactoredArrayOfDays.find(day => day.freeHours && day.current);

                if (foundFirstFreeDay) {
                    setSelectedDay(foundFirstFreeDay);
                } else if (!timeLine) {
                    handleChange({target: {checked: true}});
                    // dispatch(setNextStep());
                }
            }
        }
    },[selectedYear, selectedMonth, scheduleForSelectedPunkt, selectedPunkt]);

    useEffect(() => {
        handleSchedule();
    }, [handleSchedule]);

    useEffect(() => {
        handleMonthDays();
    }, [handleMonthDays]);

    useEffect(() => {
        if (selectedYear && selectedDay && selectedMonth && selectedTime) {
            dispatch(setAssayTime({
                date: {
                    time: `${selectedDay.dayNum} ${selectedMonth} ${selectedYear} року, ${selectedTime.time}`,
                    moment: moment(`${selectedDay.dayNum} ${selectedMonth} ${selectedYear} ${selectedTime.time}`, 'DD MMMM YYYY hh:mm'),
                    selectedDay,
                    selectedTime,
                    selectedMonth,
                    selectedYear,
                },
            }, true));
        }
    }, [selectedYear, selectedDay, selectedMonth, selectedTime]);

    useEffect(() => {
        if (time) {
            if (time.moment.isBefore(moment().add(1,'hours'))) {
                dispatch(setAssayTime());
            } else {
                setSelectedYear(time.selectedYear);
                setSelectedMonth(time.selectedMonth);
                setSelectedDay(time.selectedDay);
                setSelectedTime(time.selectedTime);
            }
        }
    }, []);

    useEffect(() => {
        if (timeLine && monthDays) {
            handleChange({target: {checked: true}});
        }
    }, [monthDays]);

    useEffect(() => {
        if (!selectedPunkt) {
            history.push('/kids')
        }
    }, [selectedPunkt])

    return (<>
        <div className={cl.root}>
            {monthDays
                && <MonthView monthDays={monthDays} handleChangeMonth={handleChangeMonth} selectedMonth={selectedMonth}
                              selectedYear={selectedYear} useDay={[selectedDay, setSelectedDay]}
                              setSelectedTime={setSelectedTime} checked={checked} scrollRef={ref}/>}
            <div ref={ref}/>
            {monthDays && <HoursView monthDays={monthDays} selectedDay={selectedDay} useTime={[selectedTime, setSelectedTime]}/>}
            {!monthDays && <Loading/>}
        </div>
        <Explanations checked={checked} handleChange={handleChange}/>
    </>);
};

export default CalendarKids;
