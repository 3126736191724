export default function calculateDistanceByHaversineFormula(lat1, lon1, lat2, lon2 ) {
    function Deg2Rad( deg ) {
        return deg * Math.PI / 180;
    }
    let R = 6372.8; // Earth Radius in Kilometers

    let dLat = Deg2Rad(lat2-lat1);
    let dLon = Deg2Rad(lon2-lon1);

    let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(Deg2Rad(lat1)) * Math.cos(Deg2Rad(lat2)) *
        Math.sin(dLon/2) * Math.sin(dLon/2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    return R * c;
};
