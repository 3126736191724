import React, {useCallback, useEffect, useRef, useState} from "react";
import useStyles from "../styles";
import {IconButton, Popover} from "@material-ui/core";
import InfoIcon from "../images/icn_info.svg";
import {Loading} from "@cabinet-packages/components";
import CloseIcon from "@material-ui/icons/Close";
import {getDetails} from "@cabinet-packages/api/api";
import Button from "@material-ui/core/Button";
import {isIncludedInOrderingList} from "@cabinet-packages/helpers";
import AddRemoveButton from "../helpers/AddRemoveButton";

const AssayPopover = ({assay, orderingList}) => {
    const cl = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [assayInfo, setAssayInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const ref = useRef();

    const handleClickOnInfoIcon = (e) => {
        setAnchorEl(ref.current);
    };

    const handleClose = (e) => {
        e.persist();

        setAnchorEl(null);
    }

    const complexName = () => {
        const indexOfComplex = assay.name.indexOf('Комплекс');
        const indexOfDot = assay.name.indexOf('.');

        if (indexOfComplex < 0 || indexOfDot < 0) {
            return assay.name+' ';
        }

        return (<>
            {assay.name.slice(0, indexOfComplex)}<span className={cl.complexNameAccent}>
                {assay.name.slice(indexOfComplex, indexOfDot+1)}
            </span>{assay.name.slice(indexOfDot+1)+' '}
        </>);
    }

    const loadDetails = async ()=>{
        const data = await getDetails(assay.id);
        if (data){
            setAssayInfo(data[0]);
        }
    }

    const handleAssayInfo = useCallback(async () => {
        if (anchorEl && !assayInfo) {
            setLoading(true);
            setError(false);

            try {
                loadDetails()
            /*    const result = {
                    name: `<p></p><strong>${assay.name}</strong></p>`,
                    def: '<p style="color: red"><strong>Визначення</strong></p>',
                    defHeading: `<p>Дослідження <strong>"${assay.name}"</strong> включає:</p>`,
                    ...assay.filling.reduce((acu, cur, idx) => ({...acu, ['def'+idx]: `<p style="color: rgba(14, 78, 78, 70); font-size: 12px; padding-left: 16px">
                        <svg id="bttn_radio" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                         <defs>
                            <style>
                              .cls-13 {
                                fill: #00b3a1;
                                stroke: #00b3a1;
                                stroke-width: 2px;
                              }
                              .cls-2 {
                                fill: #fff;
                              }

                              .cls-3 {
                                stroke: none;
                              }

                              .cls-4 {
                                fill: none;
                              }
                            </style>
                          </defs>
                          <g id="Ellipse_96" data-name="Ellipse 96" class="cls-13">
                            <circle class="cls-3" cx="7.5" cy="7.5" r="7.5"/>
                            <circle class="cls-4" cx="7.5" cy="7.5" r="6.5"/>
                          </g>
                          <path id="icn_done" class="cls-2" d="M8.005,13.9,5.826,11.72l-.726.726,2.905,2.905,6.225-6.225L13.5,8.4Z" transform="translate(-2.094 -4.306)"/>
                        </svg><span style="padding-left: 8px">${cur.name}</span></p>`
                }), {}),
                    ...data
                }*/
                // setAssayInfo(data[0]);
            } catch (e) {
                console.error(e);
                setError(true);
            }
            setLoading(false);
        }
    }, [anchorEl, assayInfo]);

    useEffect(() => {
        handleAssayInfo();
    }, [handleAssayInfo])

    useEffect(() => {
        if (anchorEl) {
            window.onscroll = () => {
                setAnchorEl(null)
            }
        }
    }, [anchorEl]);

    useEffect(()=>{
        loadDetails()
    },[])

    return (<>
        <span className={cl.listItemNameImg} onClick={handleClickOnInfoIcon}>
            {assay.idParent === 75
                ? complexName()
                : assay.name && assay.name + ' '}
            {assay.info &&
            <img src={InfoIcon} alt="info icon." className={cl.listItemImg} ref={ref}/>
            }
        </span>
        <Popover
            className={cl.noScroll}
            open={!!anchorEl}
            anchorEl={anchorEl}
            anchorReference={"none"}
            disableScrollLock
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <div className={cl.heading}>
                <h1 className={cl.headingText}>
                    ІНФОРМАЦІЯ ПРО ДОСЛІДЖЕННЯ
                </h1>
                <IconButton size="small" onClick={handleClose} className={cl.iconButton} color="inherit">
                    <CloseIcon/>
                </IconButton>
            </div>
            <div className={cl.popoverContent}>
                {assayInfo && assayInfo.rules && !loading && <div dangerouslySetInnerHTML={{__html: assayInfo.rules}}/>}
                {loading && <Loading/>}
                {/*{error && <p className={cl.error}>Вибачте, виникла помилка при завантаженні данних.</p>}*/}
                <div className={cl.popoverButtons}>
                    <Button size="small" onClick={handleClose} className={cl.popoverNavigateButton} variant='contained'
                            color="secondary">Скасувати</Button>
                    <AddRemoveButton item={assay}
                                     handleClose={handleClose}
                                     isIncludedInOrderingList={isIncludedInOrderingList(assay, orderingList)}/>
                </div>
            </div>

        </Popover>
    </>);
};

export default AssayPopover;
