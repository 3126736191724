import React from "react";
import useStyles from "../styles";
import {useSelector} from "react-redux";
import CardForSuperGroup from "./CardForSuperGroup";

const SuperGroupCards = ({setCatalogForView, setSelectedTag, usePrevState}) => {
    const cl = useStyles();
    const {tags, catalog, complexResearch}  = useSelector(state => state.assaysCatalog);

    return (catalog && complexResearch && <div className={cl.superGroupCardsWrapper}>
        <div className={cl.superGroupCards}>
            <CardForSuperGroup idx={15} superGroup={{superGroupName: 'Усі аналізи'}} isAll={true}
                               setCatalogForView={setCatalogForView} setSelectedTag={setSelectedTag} usePrevState={usePrevState}/>
            {[...catalog, {superGroupName: 'Комплексні дослідження', idParent: 77, id: 77}].filter(item => item.superGroupName).map((item, idx) => {
                return (
                    <CardForSuperGroup key={item.superGroupName+idx} idx={idx} superGroup={item}
                                       setCatalogForView={setCatalogForView} setSelectedTag={setSelectedTag} usePrevState={usePrevState}/>
                );
            })}
        </div>
    </div>);
};

export default SuperGroupCards;
