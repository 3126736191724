import React, {createContext, useEffect, useReducer, useState} from 'react';
import jwtDecode from 'jwt-decode';
import uuid from "./uuid";
import axios from "./axios";
import SplashScreen from "./SplashScreen";

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  formType : 0,

  user: null,
  uuid : null
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CREATE_AUTH': {
      const {uuid} = action.payload;

      return {
        ...state,

        uuid: uuid,

      };
    }

    case 'METRIC_AUTH': {
      const {user} = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        error: null,
        user: {
          ...user,
          avatar: ''
        },
      };
    }

    case 'INITIALISE': {
      const {isAuthenticated, user} = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,

        user: {
          ...user,
          avatar:  ''
        },

      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user: { ...user, avatar: '' },
        error : null
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        error : null,
        user: { ...user, avatar: '' },
      };
    }
    case 'UPDATE_AVATAR': {
      const { avatar } = action.payload;

      return {
        ...state,
        user: { ...state.user, avatar },
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  metricUsers: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  updateAvatar: () => {
  }
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [isCheck, setCheck] = useState(false)

  const auth = async (code) => {
    //const response =

  //  await axios.post('/dashboard/auth/authorise', { userName: email, password, uuid : state.uuid });

    const response = await axios.post(process.env.REACT_APP_API_ROOT_CALLS +'/board/auth/accept', { code, uuid : state.uuid });

    const { accessToken, user } = response.data;

    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: { ...user, avatar: '' },
      }
    });
  };

  const login = async (email, password) => {
    //const response =
    const uuidg = uuid();
    dispatch({
      type: 'CREATE_AUTH',
      payload: {
        uuid: uuidg
      }
    });

    await axios.post(process.env.REACT_APP_API_ROOT_CALLS + '/board/auth/authorise', {
      userName: email,
      password,
      uuid: uuidg
    });

    //await axios.post('/dashboard/auth/accept', { code, uuid : uuid });

    /*  const { accessToken, user } = response.data;

      setSession(accessToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });*/
  };

  const metricUsers = async (uuid) => {
    try {
      dispatch({
        type: 'METRIC_AUTH',
        payload: {}
      });

      const response = await axios.post(process.env.REACT_APP_API_ROOT_CALLS + `/board/auth/metricAuth/${uuid}`);
      const {accessToken, user} = response.data;

      setSession(accessToken)

      dispatch({
        type: 'METRIC_AUTH',
        payload: {
          user: {
            ...user,
            avatar:  ''
          },
        }
      });
    }catch (err) {
      logout()
      console.error(err)
    }
  };

  const logout = () => {
    setSession(null);
    dispatch({type: 'LOGOUT'});
  };

  const register = async (email, name, password) => {
    const response = await axios.post(process.env.REACT_APP_API_ROOT_CALLS + '/api/account/register', {
      email,
      name,
      password
    });
    const {accessToken, user} = response.data;

    window.localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user: { ...user, avatar: '' },
      }
    });
  };

  const updateAvatar = () => {
    const prevAvatar = state.user.avatar;

    dispatch({
      type: 'UPDATE_AVATAR',
      payload: {
        avatar: null,
      }
    });

    dispatch({
      type: 'UPDATE_AVATAR',
      payload: {
        avatar: prevAvatar,
      }
    });
  }

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken)

          const response = await axios.get(process.env.REACT_APP_API_ROOT_CALLS + '/board/auth/me')
          const  user = response.data;

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: { ...user, avatar: '' },
            }
          })
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          })
          if (localStorage.getItem('accessToken')) {
            localStorage.removeItem('accessToken')
            window.location.reload()
          }
        }
      } catch (err) {
        console.error(err)

        if (err.status === '401' || (err?.message?.includes('Invalid token specified') && !!localStorage.getItem('accessToken')) || err.data === '{status: false}') {
          localStorage.removeItem('accessToken')
          window.location.reload()
        }

        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    setCheck(false)
    initialise();
  }, [isCheck]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        metricUsers,
        logout,
        register,
        auth,
        updateAvatar,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
