import {
    ADD_NEW_LIST_OF_PATIENTS,
    SELECT_ACTIVE_PATIENT,
    SELECT_ACTIVE_PATIENT_ORDERS,
    SELECT_ACTIVE_PATIENT_RESULTS,
    SET_ACCESS_KEY,
    SET_CREATE_PATIENT,
    SET_LIST_OF_PATIENTS,
    SET_NOT_ADDED_PATIENTS,
    SET_RESULTS_BY_ORDER_ID,
    START_PHONE_SEND,
    UPDATE_ACTIVE_ORDER_DATE,
    UPDATE_ACTIVE_ORDER_PUNKT,
    UPDATE_ACTIVE_PATIENT,
} from '../actions/actionsTypes';

const convertActivePatient = () => {
  try {
    if (localStorage.getItem("activePatient")) {
      return JSON.parse(localStorage.getItem("activePatient"));
    }
  } catch(e) {
    console.log(e.message);
  }
  return null
};

export const patientInitialState = {
  activePatient: convertActivePatient(),
  activePatientResults: null,
  activePatientsOrders: null,
  listOfPatients: null,
  createPatient: null,
  accessKeyForLogin: null,
  stage: 1,
  notAddedPatients: null,
};

export default function patientsReducer(state = patientInitialState, { type, payload }) {
  switch (type) {
    case SELECT_ACTIVE_PATIENT:
      return {
        ...state,
        activePatient: payload.foundPatient,
        activePatientsOrders: null,
      };
    case SELECT_ACTIVE_PATIENT_RESULTS:
      return {
        ...state,
        activePatientResults: payload.results,
      };
    case SET_RESULTS_BY_ORDER_ID:
      return {
        ...state,
        activePatientResults: payload.newActivePatientResults,
      };
    case SELECT_ACTIVE_PATIENT_ORDERS:
      return {
        ...state,
        activePatientsOrders: payload.results,
      };
    case UPDATE_ACTIVE_ORDER_PUNKT:
      return {
        ...state,
        activePatientsOrders: state.activePatientsOrders.map(item => {
          if(item.id === payload.orderId){
            return {...item, idPunkt: payload.punktId}
          }else{
            return item
          }
        })
      };
    case UPDATE_ACTIVE_ORDER_DATE:
      return {
        ...state,
        activePatientsOrders: state.activePatientsOrders.map(item => {
          if(item.id === payload.orderId) {
            return {...item, dt: payload.date}
          }else {
            return item
          }
        })
      };
    case UPDATE_ACTIVE_PATIENT:
      return {
        ...state,
        activePatient: payload.activePatient,
        // listOfPatients: payload.newListOfPatients, //don`t know what a fuck is this
      };
    case ADD_NEW_LIST_OF_PATIENTS:
      return {
        ...state,
        listOfPatients: [...state.listOfPatients, payload.newPatient],
        activePatient: payload.newPatient,
      };
    case SET_LIST_OF_PATIENTS:
      return {
        ...state,
        listOfPatients: typeof payload.newListOfPatients === 'object' ? payload.newListOfPatients.flat() : null,// fucking crunch
      };
    case SET_CREATE_PATIENT:
      return {
        ...state,
        createPatient: payload.value,
      };
    case START_PHONE_SEND:
      return {
        ...state,
        stage: payload.stage,
        error: payload.error
      };
    case SET_ACCESS_KEY:
      return {
        ...state,
        accessKeyForLogin: payload.accessKey,
      };
    case SET_NOT_ADDED_PATIENTS:
      return {
        ...state,
        notAddedPatients: payload.value,
      };
    default:
      return state;
  }
};
