import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Button, makeStyles} from '@material-ui/core';
import {AddRemoveIconButtons, ExpansionPanel, Loading} from '@cabinet-packages/components';
import {useDispatch, useSelector} from "react-redux";
import {getCatalog,} from "@cabinet-packages/redux";
import Typography from "@material-ui/core/Typography";
import {Helmet} from "react-helmet";
import {getPriceDetails} from "@cabinet-packages/api/api";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        padding: '10px',
        display: 'grid',
        minHeight: '100vh',
        gridTemplateRows: 'auto 1fr',
        gridTemplateColumns: '1fr',
        gridGap: '10px',
    },
    containerInfoAssay: {
        background: 'rgba(0, 0, 0, 0.05)',
        display: 'flex',
        flexDirection: 'row',
        padding: '0px 10px',
        '@media (max-width: 900px)': {
            flexDirection: 'column',
        }
    },
    priceTextWrapper: {
        color: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        userSelect: 'none',
        justifyContent: 'space-between',
        verticalAlign: 'center',
        width: '40%',
        '@media (max-width: 900px)': {
            width: '100%',
            flexDirection: 'column',
        }
    },
    priceText: {
        lineHeight: '56px',
        color: theme.palette.error.main,
        fontWeight: 600
    },
    nameOfAssay: {
        alignItems: 'center',
        paddingRight: '8px',
        fontWeight: '600',
        fontSize: '18px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '60%',
        '@media (max-width: 900px)': {
            width: '100%',
        }
    },
    details: {
        display : "block",
    },
    scrollWrapper: {
        position: 'relative',
        width: '100%',
    },
    scrollContainer: {
        position: 'absolute',
        width: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        padding: '8px',
        listStylePosition: 'inside',
    },
    buttonForReturnWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    addRemoveButton: {
        padding: '8px',
    },
    accordionDetails: {
        display: 'block',
    },
    accordionDetailsHeader: {
        padding: '8px 8px 8px 0',
        color: theme.palette.primary.main,
    },
}));

function ComplexInfo({isComponentInShop}) {
    const location = useLocation();
    const classes = useStyles();
    const {catalog, complexResearch, takeId} = useSelector(state => state.assaysCatalog);
    const { orderingList } = useSelector(state => state.orderAssays);
    const [item, setItem] = useState(null);
    const [sendTag, setSendTag] = useState(false);
    const [assayInfo, setAssayInfo] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const hrefLocation = window.location.href;
    const [loading, setLoading] = useState(false);

    const getDetails = useCallback(async () => {
        if (item && (item.definition === null  || item.definition === undefined)) {
            setLoading(true);
            const result = await getPriceDetails(item.id);
            setItem(prevState => ({...prevState, ...result[0]}));
            setAssayInfo(result[0]);
            setLoading(false)
        }
    }, [item]);

    const isIncludedInOrderingList = item && orderingList.find(assay => assay.id === item.id && assay.type === item.type);

    const handleTransitionToCatalog = (event) => {
        event.stopPropagation();
        event.preventDefault();

        isComponentInShop
            ? history.push('/shop-complex')
            : history.push('/comprehensive-research')
    };

    // useEffect(() => {
    //     if (item && !sendTag) {
    //         window.dataLayer.push({
    //             event: 'view_item',
    //             eventProps: {
    //                 items: [{
    //                     id: item.id,
    //                     name: item.name,
    //                     variant: item.type,
    //                     price: item.discount
    //                         ? item.price - item.price * item.discount / 100
    //                         : item.price,
    //                     quantity: 1,
    //                 }],
    //             },
    //             coupon: '',
    //         });
    //         setSendTag(true);
    //     }
    // }, [item, sendTag])

    useEffect(() => {
        if (!complexResearch) dispatch(getCatalog());

        if (complexResearch) {
            const id = location.pathname.split('/');
            const item = complexResearch.find((item)=> +item.id === +id[id.length-1] && !item.superGroupName);
            if (item) {
                setItem(item);
                setAssayInfo(item);
            } else {
                setItem(false);
            }
        }
    }, [catalog, dispatch, location]);

    useEffect(() => {
        getDetails();
    }, [getDetails])

    if (!catalog || item === null) {
        return <Loading/>;
    }

    return (
        item !== false
            ? <div className={classes.root} key={item.id}>
                <div className={classes.containerInfoAssay}>
                    <div className={classes.nameOfAssay}>
                        {item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join('')}
                    </div>
                    <div className={classes.priceTextWrapper}>
                        <div className={classes.priceText}>{item.price - item.price * item.discount / 100} грн</div>
                        <div className={classes.addRemoveButton}>
                            <AddRemoveIconButtons
                                item={item}
                                isIncludedInOrderingList={isIncludedInOrderingList}
                                assayInfo={true}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.scrollWrapper}>
                    <div className={classes.scrollContainer}>
                        {assayInfo && <ExpansionPanel title="Інформація про дослідження:" defaultExpanded={true} classNameDetails={classes.accordionDetails}>
                            {assayInfo.definition && <div>
                                <h3 className={classes.accordionDetailsHeader}>Визначення:</h3>
                                <div className="content" dangerouslySetInnerHTML={{__html: assayInfo.definition}}/>
                            </div>}
                            {item.test && !!item.test.length && <div>
                                <h3 className={classes.accordionDetailsHeader}>Дослідження:</h3>
                                {item.test.map((test, index) => <Typography key={index}>{test}</Typography>)}
                            </div>}
                            {assayInfo.deviation && <div>
                                <h3 className={classes.accordionDetailsHeader}>Відхилення:</h3>
                                <div className="content" dangerouslySetInnerHTML={{ __html: assayInfo.deviation}}/>
                            </div>}
                            {assayInfo.recommendations && <div>
                                <h3 className={classes.accordionDetailsHeader}>Рекомендації:</h3>
                                <div className="content" dangerouslySetInnerHTML={{ __html: assayInfo.recommendations}}/>
                            </div>}
                            {assayInfo.ua_synonyms && <div>
                                <h3 className={classes.accordionDetailsHeader}>Синоніми:</h3>
                                <div className="content" dangerouslySetInnerHTML={{ __html: assayInfo.ua_synonyms}}/>
                            </div>}
                            {assayInfo.when && <div>
                                <h3 className={classes.accordionDetailsHeader}>Застосування:</h3>
                                <div className="content" dangerouslySetInnerHTML={{ __html: assayInfo.when}}/>
                            </div>}
                            {assayInfo.ua_rules && <div>
                                <h3 className={classes.accordionDetailsHeader}>Правила підготовки:</h3>
                                <div className="content" dangerouslySetInnerHTML={{ __html: assayInfo.ua_rules}}/>
                            </div>}
                            {item.material && !!item.material.length && <div>
                                <h3 className={classes.accordionDetailsHeader}>Матеріал:</h3>
                                {item.material.map((material, index) => <Typography key={index}>{material}</Typography>)}
                            </div>}
                            {loading && <Loading/>}
                        </ExpansionPanel>}
                    </div>
                </div>
                <div className={classes.buttonForReturnWrapper}>
                    <Button
                        onClick={handleTransitionToCatalog}
                        variant="contained"
                        color="primary"
                    >
                        Назад до комплексних досліджень
                    </Button>
                </div>
                {item && item.testNames && <Helmet>
                    <meta name="og:description" content={item.testNames}/>
                    <meta property="og:url" content={hrefLocation}/>
                    <meta name="og:image" content={'imgUrl'}/>
                    <meta property="og:site_name" content={takeId === 216 ? 'Medis' : 'Esculab'}/>
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content={item.name ? item.name : item.testNames.split(',')[0].split('\\\\').join('')} />
                </Helmet>}
            </div>
            : <div>Вибачте, це дослідження не доступне в даний момент.</div>
    );
}

export default ComplexInfo;
