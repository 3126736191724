import React, {createContext, useCallback, useEffect, useRef, useState} from 'react';
import {Button, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles,} from '@material-ui/core';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import {CustomDialog, Loading, Scroll, TextAlertDialog} from '@cabinet-packages/components';
import {getPunkts} from "@cabinet-packages/redux";
import {useDispatch, useSelector} from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {createFilterOptions} from "@material-ui/lab";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateRows: '70px 1fr',//'1fr auto 70px 1fr',
    gridTemplateColumns: '1fr',
    marginBottom: '10px',
    width: '100%',
    height: 'calc(100vh - 140px)',
    minHeight: '100%',
  },
  searchButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
  },
  errorMessage: {
    textAlign: 'center',
  },
  noConnection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '100%',
    padding: '10px',
    '@media (max-width: 900px)': {
      padding: '10px',
    },
  },
  autoComplete: {
    boxSizing: 'content-box',
    width: '100%',
    minWidth: '100%',
  },
  noPunkts: {
    textAlign: 'center',
  },
  actionButtonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '8px',

    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  mainPageButton: {
    '@media (max-width: 900px)': {
      marginTop: '8px',
    },
  },
});

const MapContext = createContext(null);

function CollectionPointsAssay({isComponentInShop}) {
  const classes = useStyles();
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [isUserSelectPoint, setIsUserSelectPoint] = useState(false);
  const isMountedRef = useRef(null);
  const dispatch = useDispatch();
  const {clientLocation, punkts} = useSelector(store => store.collectionPoints);
  const { takeId } = useSelector(store => store.assaysCatalog);
  const {themeMaterialUI} = useSelector(store => store.theme);
  const [open, setOpen] = useState(false);
  const history = useHistory();


  const filteredOptions = createFilterOptions({
    limit: 20
  });

  const measuredRef = useCallback(node => {
    if (node && selectedPoint && node.textContent === selectedPoint.address && isMountedRef.current) {
      node.scrollIntoView({
        behavior: 'smooth',
        block: isComponentInShop ? 'end' : 'start',
      });
    }
  }, [selectedPoint, isComponentInShop]);

  const handleClickOnPunkt = (point) => {
    setSelectedPoint(point);
    setIsUserSelectPoint(true);
  };

  const handleClose = (reason) => {
    setOpen(false);
    if (reason === 'blur') setIsUserSelectPoint(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleTransitionToCatalog = (event) => {
    event.stopPropagation()
    event.preventDefault()

    isComponentInShop
        ? history.push('/shop')
        : history.push('/catalog-table')
  };

  const handleTransitionMainPage = (event) => {
    event.stopPropagation()
    event.preventDefault()

    history.push('/shop-about')
  };

  const getClosestPunkt = useCallback(() => {
    if (!clientLocation) CustomDialog(TextAlertDialog, {
      text: `Будь ласка, дозвольте використання геолокації на вашому пристрої.`,
      onConfirm: () => {},
    }, false, themeMaterialUI);
    if (punkts) setSelectedPoint(punkts[0]);
  }, [clientLocation, punkts]);

  useEffect(() => {
    isMountedRef.current = true;

    if (clientLocation && punkts && punkts.length > 0 && !isUserSelectPoint) getClosestPunkt();
    if (punkts && punkts.length > 0 && !selectedPoint) setSelectedPoint(punkts[0]);

    return () => isMountedRef.current = false;
  }, [clientLocation, punkts, selectedPoint, dispatch, getClosestPunkt, isUserSelectPoint]);

  useEffect(() => {
    dispatch(getPunkts());
  }, []);

  return (
        <div className={classes.root}>
          <div className={classes.container}>
            <Autocomplete
                className={classes.autoComplete}
                id="combo-box"
                open={open}
                onOpen={handleOpen}
                onClose={(event, reason) => handleClose(reason)}
                freeSolo
                options={punkts
                    ? punkts
                    : []
                }
                filterOptions={filteredOptions}
                getOptionLabel={(option) => option.address}
                onChange={(event, value) => handleClickOnPunkt(value)}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Пошук по адресі" variant="outlined" />}
            />
          </div>
          {!punkts && <div><Loading/></div>}
          {punkts && punkts.length === 0 && <div className={classes.noPunkts}>Вибачте, немає пунктів для відображення.</div>}
          <Scroll className={classes.list}>
            <List
                dense
                disablePadding
            >
              {punkts && typeof punkts !== "string" && punkts.map(point => {
                return (
                    <ListItem divider key={point.idPunkt} onClick={() => handleClickOnPunkt(point)} ref={measuredRef}>
                      <ListItemIcon>
                        <IconButton size="small" color={ selectedPoint && point.idPunkt === selectedPoint.idPunkt
                            ? 'primary'
                            : 'default' }
                        >
                          <GpsFixedIcon/>
                        </IconButton>
                      </ListItemIcon>
                      <ListItemText
                          primary={point.address}
                          secondary={point?.hWork || point?.zWork || ''}
                      />
                    </ListItem>
                )}
              )}
            </List>
          </Scroll>
          {takeId === 216 && <div className={classes.actionButtonsContainer}>
            <Button
                color="primary"
                variant="contained"
                onClick={handleTransitionToCatalog}
            >
              обрати дослідження
            </Button>
            <Button
                color="primary"
                variant="contained"
                onClick={handleTransitionMainPage}
                className={classes.mainPageButton}
            >
              повернутись на головну
            </Button>
          </div>}
        </div>
  );
}

export default CollectionPointsAssay;
